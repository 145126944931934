import Card from './Card';

class Deck {
  static groupByTypes(deck, isSortedByTypesOrder = true) {
    const data = deck.reduce((prev, current) => {
      const mainType = Card.getMainType(current.card);

      const index = prev.findIndex((item) => item.type === mainType);

      if(index !== -1){
        prev[index].cards.push(current);
      }
      else {
        prev.push({
          type: mainType,
          cards: [current]
        });
      }

      return prev;
    }, []);

    if(!isSortedByTypesOrder) {
      return data;
    }

    return data.sort((a, b) => Card.compareCardsType(a.type, b.type));
  }

  static divideIntoLandsAndNonLands(deck, isSortedByTypesOrder = true) {
    const data = deck.reduce((prev, current) => {
      const mainType = Card.isLand(current.card);

      const index = prev.findIndex((item) => item.type === mainType);

      if(index !== -1){
        prev[index].cards.push(current);
      }
      else {
        prev.push({
          type: mainType,
          cards: [current]
        });
      }

      return prev;
    }, []);

    if(!isSortedByTypesOrder) {
      return data;
    }

    return data.sort((a, b) => Card.compareCardsType(a.type, b.type));
  }
  
  static getCardAmount(cardGroupId, deck) {
    return deck.reduce((prevDeck, deckSection) => {
      return prevDeck + deckSection.reduce((prev, current) => {
        const amount = (cardGroupId === current.card.cardGroup.id) ? current.amount : 0;

        return prev + amount;
      }, 0);
    }, 0);
  }
}

export default Deck;
