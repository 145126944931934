import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TopBar from '../Layout/TopBar';
import { FormattedMessage } from 'react-intl';

import { changePassword } from '../../actions/authActions';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      repeatPassword: '',
      showValidation: false,
    };
  }

  componentDidUpdate(prevProps) {
    if(this.props.change.isError) {
      this.props.history.push('/change-password-fail');

      this.setState({
        password: '',
        repeatPassword: '',
        showValidation: false,
      });
    }
    if(prevProps.change.success !== this.props.change.success && !this.props.change.isError) {
      this.props.history.push('/change-password-success');

      this.setState({
        password: '',
        repeatPassword: '',
        showValidation: false,
      });
    }
  }

  validatePassword(password, repeatPassword) {
    return password.length >= 8 && password.length <= 4096 && password === repeatPassword;
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value
    });
  }

  handleRepeatPasswordChange = (e) => {
    this.setState({
      repeatPassword: e.target.value
    });
  }

  handleSubmitPasswordChange = () => {

    const { token } = this.props;
    const password = this.state.password.trim();
    const repeatPassword = this.state.repeatPassword.trim();

    if (!this.validatePassword(password, repeatPassword)) {
      return this.setState({
          showValidation: true,
      });
    } else {
      const data = {
        password,
        token
      };

     this.props.changePassword(data);
    }
  }

  render() {
    const { password, repeatPassword, showValidation } = this.state;
    return(
      <Fragment>
      <TopBar rightView={null} mobileBreakpoint={null} />
      <div className="container text-center fix-topbar-padding">
        <form className="login-form text-center pt-5">
          <div className="form-row text-center">
            <div className="form-group col-sm-8 col-md-6 col-lg-4 d-flex flex-column mx-auto">
              <label htmlFor="password" className="h5" style={{ letterSpacing: '1px' }}><FormattedMessage id="password.label"/></label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={this.handlePasswordChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-sm-8 col-md-6 col-lg-4 d-flex flex-column mx-auto">
              <label htmlFor="repeatPassword" className="h5" style={{ letterSpacing: '1px' }}><FormattedMessage id="password.repeat.label"/></label>
              <input
                className="form-control"
                name="repeatPassword"
                type="password"
                value={repeatPassword}
                onChange={this.handleRepeatPasswordChange}
              />
              <div style={{height: '20px', paddingTop: '12px'}}>
                {
                  showValidation && !this.validatePassword(password, repeatPassword) && <div className="text-danger"><FormattedMessage id="password.error"/></div>
                }
              </div>
            </div>
          </div>
          <div className="form-row mt-5">
            <div className="form-group col-lg-12 d-flex justify-content-center">
              <button onClick={this.handleSubmitPasswordChange} className="btn btn-primary btn-lg" type="button"><FormattedMessage id="password.reset"/></button>
            </div>
          </div>
        </form>
      </div>
      </Fragment>
    );
  }
}

ChangePassword.propTypes = {
  change: PropTypes.object,
  changePassword: PropTypes.func,
  token: PropTypes.string,
  history: PropTypes.object,
};

export default connect(
  (state, ownProps) => ({
    change: state.change,
    token: ownProps.match.params.token,
  }),
  ({
    changePassword
  })
)(ChangePassword);
