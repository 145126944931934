import {createLogic} from 'redux-logic';
import {push} from 'react-router-redux';

import {LEAVE_TEAM_TOURNAMENT} from '../actions/types';
import {closeActualOpened} from '../actions/modal';
import {getTournamentsPlayer} from '../actions/tournamentActions';

export const start = createLogic({
  type: LEAVE_TEAM_TOURNAMENT.START,
  latest: true,

  processOptions: {
    successType: LEAVE_TEAM_TOURNAMENT.SUCCESS,
    failType: LEAVE_TEAM_TOURNAMENT.ERROR,
  },

  process({httpClient, action}) {
    const {teamId} = action.payload;

    return httpClient.delete(`/api/v1/teams/${teamId}`)
      .then(res => res.data);
  }
});

export const success = createLogic({
  type: LEAVE_TEAM_TOURNAMENT.SUCCESS,

  process({genericActions}, dispatch, done) {
    dispatch(closeActualOpened());

    dispatch(push('/tournaments/1'));
    dispatch(getTournamentsPlayer(1));

    dispatch(genericActions.notification.add({
      type: 'success',
      intlKey: 'notifications.general.success',
    }));

    done();
  }
});

export const error = createLogic({
  type: LEAVE_TEAM_TOURNAMENT.ERROR,

  process({genericActions}, dispatch, done) {
    dispatch(closeActualOpened());
    dispatch(genericActions.notification.add({
      type: 'danger',
      intlKey: 'notifications.general.error',
    }));

    done();
  }
});

export default [
  start,
  success,
  error,
];
