import * as React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {getTournaments} from '../../actions/tournamentActions';
import { FormattedMessage } from 'react-intl';
import DateFormatted from '../DateFormatted/DateFormatted';

import DataTableWithMobileDropdown from '../DataTable/DataTableWithMobileDropdown';
import Pagination from '../Pagination/Pagination';
import Spinner from '../Spinner';

import StatusDot from '../Tournament/StatusDot';

class UserOrganizerList extends React.Component {
  state = {
    isOpen: false,
    deleteId: null
  };

  componentDidMount() {
    this.getUserTournaments(1);
  }

  getUserTournaments = (page) => {
    this.props.getTournaments(page, 4, this.props.userId);
  }

  goToPreview(id) {
    this.props.history.push(`/tournaments/show/${id}/users`);
  }

  renderOrganizerList = () => {
    const tournaments = this.props.tournaments.data;
    if (!tournaments || tournaments.data.length < 1) {
      return <div className="text-center"><FormattedMessage id="tournaments.no_tournaments"/></div>;
    }

    const columns = [
      {name: 'ID', flex: 1, key: 1},
      {name: 'NAME', flex: 2, key: 2},
      {name: 'TYPE', flex: 1, key: 3},
      {name: 'DATE', flex: 1, key: 4},
      {name: 'CITY', flex: 2, key: 5},
      {name: 'STATUS', flex: 1, key: 6},
      {name: 'PREVIEW', flex: 2, key: 7, isVisibleOnMobile: false},
    ];

    const data = tournaments.data.map((item, index) => {
      return [
        `#${item.id.split('-')[0]}`,
        item.name,
        item.type ? <FormattedMessage id={`tournament_type.${item.type}`}/> : '-',
        <DateFormatted key={index + '-date'} value={item.startDate}/>,
        item.city || '-',
        <div key={index + '-status'} className="text-center"><StatusDot status={item.status}/></div>,
        <div key={index + '-edit'} className="tournament-link text-center" onClick={() => this.goToPreview(item.id)}><button className="btn btn-sm btn-primary"><FormattedMessage id="organizer.btn.show"/></button></div>,
      ];
    });

    const dropdownData = (dataRow, rowIndex) => [
      <span key={rowIndex + '-remove'} className="tournament-link tournament-link-mobile" onClick={() => this.goToPreview(tournaments.data[rowIndex].id)}><FormattedMessage id="organizer.btn.show"/></span>,
    ];

    return (
      <DataTableWithMobileDropdown
        columns={columns}
        data={data}
        dropdownData={dropdownData}
      />
    );
  }

  render() {
    const {tournaments} = this.props;
    const total = tournaments.data && tournaments.data.headers && tournaments.data.headers['x-last-page'];
    const current = tournaments.data && tournaments.data.headers && tournaments.data.headers['x-page'];

    if(!tournaments.data || tournaments.isLoad){
      return <Spinner/>;
    }
    
    return (
      <div>
        <h4 className="section-header section-header--secondary mb-5"><FormattedMessage id="organizer.title"/></h4>

        <div className="tournament-list mt-3">
          <div className="tournament-list-table-wrapper">
            {this.renderOrganizerList()}
          </div>
          <div className="pagination-wrapper">
            <Pagination totalPages={total} currentPage={current} onChangePage={this.getUserTournaments}/>
          </div>
        </div>
      </div>
    );
  }
}

UserOrganizerList.propTypes = {
  createTournament: PropTypes.func,
  getTournaments: PropTypes.func.isRequired,
  tournaments: PropTypes.object,
  history: PropTypes.object,
  userId: PropTypes.string,
};

export default connect(
  (state) => ({
    tournaments: state.tournaments,
  }),
  ({
    getTournaments,
  })
)(UserOrganizerList);
