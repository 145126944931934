import FileSaver from 'file-saver';

class File {
  static saveAs(data, type, name) {
    const blob = new Blob([data], {type});

    FileSaver.saveAs(blob, name);
  }

  static saveAsCSV(data, name) {
    this.saveAs(data, 'text/csv', `${name}.csv`);
  }
}

export default File;
