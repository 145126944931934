import { DECK_VALIDATOR } from '../actions/types';

const initialState = {
  deck: [],
  cardAmount: [],
  cardFormat: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DECK_VALIDATOR.SET_CARD_AMOUNT_ERRORS:
      return {
        ...state,
        cardAmount: action.payload,
      };
    case DECK_VALIDATOR.SET_DECK_ERRORS:
      return {
        ...state,
        deck: action.payload,
      };

    case DECK_VALIDATOR.PUSH_CARD_FORMAT_ERROR:
      return {
        ...state,
        cardFormat: action.payload,
      };
    case DECK_VALIDATOR.SET_ALL_ERRORS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
