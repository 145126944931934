import {
  IMPORT_PLAYERS,
} from './types';

export const importPlayers = (tournamentId, file) => ({
  type: IMPORT_PLAYERS.START,
  tournamentId: tournamentId,
  file: file
});
  
  