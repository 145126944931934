import {
  TOURNAMENT_PLAYERS,
  TOURNAMENT_JUDGES,
  TOURNAMENT_ORGANIZERS,
  TOURNAMENT_PLAYERS_SEARCH,
  TOURNAMENT_CASTERS,
  TOURNAMENT_CASTERS_ADD,
  TOURNAMENT_CASTERS_DELETE,
  TOURNAMENT_JUDGES_ADD,
  TOURNAMENT_JUDGES_DELETE,
  TOURNAMENT_PLAYER_DELETE,
  TOURNAMENT_ORGANIZERS_ADD,
  TOURNAMENT_ORGANIZERS_DELETE,
  TOURNAMENT_EXTRAS,
  TOURNAMENT_PLAYERS_UPDATE,
} from './types';

export const getTournamentPlayers = (tournamentId, name, page, sort, order) => ({
  type: TOURNAMENT_PLAYERS.FETCH,
  tournamentId: tournamentId,
  name: name,
  page: page,
  sort: sort,
  order: order
});

export const tournamentPlayerUpdate = (playerId, data) => ({
  type: TOURNAMENT_PLAYERS_UPDATE.START,
  payload: {
    playerId,
    data,
  }
});

export const getTournamentExtras = (tournamentId) => ({
  type: TOURNAMENT_EXTRAS.FETCH,
  payload: tournamentId
});

export const updateTournamentExtras = (tournamentId, data) => ({
  type: TOURNAMENT_EXTRAS.UPDATE,
  payload: {
    tournamentId,
    data,
  }
});

export const getTournamentJudges = (tournamentId) => ({
  type: TOURNAMENT_JUDGES.FETCH,
  tournamentId: tournamentId,
});

export const addTournamentJudges = (tournamentId, judgeId) => ({
  type: TOURNAMENT_JUDGES_ADD.START,
  tournamentId: tournamentId,
  judgeId: judgeId
});

export const deleteTournamentJudge = (tournamentId, judgeId, isJudgeTournaments) => ({
  type: TOURNAMENT_JUDGES_DELETE.START,
  tournamentId: tournamentId,
  judgeId: judgeId,
  isJudgeTournaments: isJudgeTournaments
});

export const deleteTournamentPlayer = (tournamentId, playerId, isRefreshPlayers = true, redirectUrl = false) => ({
  type: TOURNAMENT_PLAYER_DELETE.START,
  tournamentId: tournamentId,
  playerId: playerId,
  isRefreshPlayers: isRefreshPlayers,
  redirectUrl: redirectUrl
});

export const getTournamentSearchPlayers = (data, page) => ({
  type: TOURNAMENT_PLAYERS_SEARCH.START,
  data: data,
  page: page
});

export const getTournamentOrganizers = (tournamentId) => ({
  type: TOURNAMENT_ORGANIZERS.FETCH,
  tournamentId: tournamentId
});

export const addTournamentOrganizer = ({tournamentId, userId}) => ({
  type: TOURNAMENT_ORGANIZERS_ADD.START,
  payload: {
    tournamentId,
    userId,
  }
});

export const deleteTournamentOrganizer = ({tournamentId, userId, nextActions = []}) => ({
  type: TOURNAMENT_ORGANIZERS_DELETE.START,
  payload: {
    tournamentId,
    userId,
    nextActions,
  }
});

export const getTournamentCasters = (tournamentId) => ({
  type: TOURNAMENT_CASTERS.FETCH,
  tournamentId: tournamentId
});

export const addTournamentCaster = (tournamentId, casterDescription) => ({
  type: TOURNAMENT_CASTERS_ADD.START,
  tournamentId: tournamentId,
  casterDescription: casterDescription,
});

export const deleteTournamentCaster = (casterId, tournamentId) => ({
  type: TOURNAMENT_CASTERS_DELETE.START,
  casterId: casterId,
  tournamentId: tournamentId,
});
