import { USERS } from './types';

export const getUsers = (page, name, filter) => ({
  type: USERS.FETCH,
  name: name,
  page: page,
  filter
});

export const clearUsers = () => ({
  type: USERS.CLEAR,
});
