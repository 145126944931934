import {merge} from 'lodash';
import {TOURNAMENT_USER} from '../actions/types';

const initialState = {
  data: null,
  isLoad: false,
  isError: false,
  error: null,
  createDeck: {
    data: null,
    isLoad: false,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_USER.CLEAR:
      return initialState;
    case TOURNAMENT_USER.GET:
      return {
        ...state,
        isLoad: true,
        isError: false,
        error: null,
        createDeck: {
          data: null,
          isLoad: false,
        }
      };
    case TOURNAMENT_USER.SET_ERROR:
      return {
        ...state,
        isLoad: false,
        isError: true,
        error: action.payload
      };
    case TOURNAMENT_USER.SET:
      return {
        ...state,
        isLoad: false,
        isError: false,
        error: null,
        data: action.payload,
      };
    case TOURNAMENT_USER.UPDATE:
      return merge(state, action.payload);
    case TOURNAMENT_USER.CREATE_DECK.START:
      return {
        ...state,
        createDeck: {
          isLoad: true,
          data: null,
        }
      };
    case TOURNAMENT_USER.CREATE_DECK.SUCCESS:
      return {
        ...state,
        createDeck: {
          isLoad: false,
          data: action.payload,
        }
      };
    case TOURNAMENT_USER.CREATE_USER_ERROR:
      return {
        ...state,
        isLoad: false,
        isError: !!action.payload,
        error: action.payload
      };
    default:
      return state;
  }
};
