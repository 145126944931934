import { COUNTRIES, STATES } from './types';

export const getCountries = () => ({
  type: COUNTRIES.FETCH,
});

export const getStates = (country) => ({
  type: STATES.FETCH,
  country: country
});
