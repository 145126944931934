import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import AccessControl from '../../../Utils/AccessControl';
import Judges from '../../Judges';
import Organizers from '../../Organizers';

import {roles} from '../../../const';

class TournamentUsersShow extends React.Component {
  render() {
    const {tournament} = this.props;

    return (
      <React.Fragment>
        <AccessControl roles={[roles.organizer, roles.admin]}>
          <Judges tournamentId={tournament.id}/>
        </AccessControl>
        <AccessControl roles={[roles.organizer, roles.admin]}>
          <Organizers tournamentId={tournament.id} tournamentCreatorId={tournament.creatorId}/>
        </AccessControl>
      </React.Fragment>
    );
  }
}

TournamentUsersShow.propTypes = {
  match: PropTypes.object.isRequired,
  tournament: PropTypes.object,
};

export default withRouter(TournamentUsersShow);
