import { createLogic } from 'redux-logic';
import {push} from 'react-router-redux';

import { LOGIN, GET_CURRENT_USER, LOGOUT, SET_USER_ROLE } from '../actions/types';
import AuthService from '../services/AuthService';
import { setUserRole } from '../actions/userActions';

export const login = createLogic({
  type: LOGIN.START,

  process({ httpClient, action }, dispatch, done) {
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', '1');
    formData.append('client_secret', 'secret');
    formData.append('username', action.payload.email);
    formData.append('password', action.payload.password);

    return httpClient.post('/oauth/v2/token', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      )
      .then(res => res.data)
      .then(data => {
        AuthService.saveAuth(data);
        dispatch({ type: LOGIN.SUCCESS, payload: data});
        dispatch({type: GET_CURRENT_USER.START});
      })
      .catch(err => {
        dispatch({ type: LOGIN.ERROR, payload: err.response});
      })
      .then(() => done());
  }
});


export const getMe = createLogic({
  type: GET_CURRENT_USER.START,

  process({ httpClient }, dispatch, done) {
    return httpClient.get('/api/v1/me')
      .then(res => res.data)
      .then(userData => {
        const userRoles = userData.roles.filter(role => role !== 'USER');
        const storedRole = AuthService.getCurrentRole();
        const currentRole = userRoles.includes(storedRole) ? storedRole : userRoles[0];

        // Workaround for CBL-1405: Main user avatar did't change after update
        //
        // After change avatar, url of this avatar stay the same and browser
        // get this image from cache. We change this url adding timestamp on the end in query string.
        if(userData.avatar) {
          const t = + new Date();
          userData.avatar += `?t=${t}`;
        }

        dispatch(setUserRole(currentRole));
        dispatch({
          type: GET_CURRENT_USER.SUCCESS,
          payload: userData
        });
      })
      .catch(err => {
        dispatch({
          type: GET_CURRENT_USER.ERROR,
          payload: err,
          isError: true
        });
      })
      .then(done);
  }
});

export const logout = createLogic({
  type: LOGOUT.START,

  process(data, dispatch, done) {
    AuthService.logout();
    dispatch(push('/login'));
    dispatch({ type: LOGOUT.END});
    dispatch({type: LOGIN.CANCEL_CURRENT});
    done();
  }
});

export const changeRole = createLogic({
  type: SET_USER_ROLE,

  process({ action }, dispatch, done) {
    AuthService.setCurrentRole(action.payload);
    done();
  }
});

export default {
  login,
  getMe,
  logout,
  changeRole,
};
