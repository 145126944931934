import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {createLogic} from 'redux-logic';
import {TOURNAMENT_TEAMS, UNREGISTERED_TEAM} from '../actions/types';
import { add as addNotification } from '../actions/notificationActions';
import {getTournamentTeams} from '../actions/tournamentTeams';
import {parseArray} from '../services/validationHelpers';

import AuthService from '../services/AuthService';
import ls from '../services/LocalStorage';

function getActionError(err) {
  return Promise.reject({
    ...err.response.data,
    ...{
      extras: parseArray(err.response.data.extras)
    }
  });
}

export const get = createLogic({
  type: TOURNAMENT_TEAMS.GET.START,

  processOptions: {
    successType: TOURNAMENT_TEAMS.GET.SUCCESS,
    failType: TOURNAMENT_TEAMS.GET.ERROR,
  },

  debounce: 300,
  latest: true,

  process({httpClient, action}) {
    const {tournamentId, options} = action.payload;

    return httpClient.get(`/api/v1/team_tournaments/${tournamentId}/teams`, {
      params: {
        page: options.page,
        per_page: options.per_page,
        name: options.filters.name
      }
    })
      .then((res) => ({
        pagination: {
          lastPage: res.headers['x-last-page'],
          page:     res.headers['x-page'],
          perPage:  res.headers['x-per-page'],
          total:    res.headers['x-total'],
        },
        data: res.data,
      }));
  }
});

export const importTeams = createLogic({
  type: TOURNAMENT_TEAMS.IMPORT.START,

  process({httpClient, action}, dispatch, done) {
    const {tournamentId, file} = action.payload;

    const formData = new FormData();
    formData.append('file', file);

    return httpClient.post(`/api/v1/tournaments/${tournamentId}/teams_import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
    .then(() => {
      dispatch(addNotification({
        type: 'success',
        text: <FormattedMessage id="tournament.players.import.players.success"/>
      }));
      dispatch(getTournamentTeams(tournamentId));
      dispatch({type: TOURNAMENT_TEAMS.IMPORT.SUCCESS});
    })
    .catch(err => {
      dispatch(addNotification({
        type: 'danger',
        text: err.response.data.error_description
      }));
      dispatch({type: TOURNAMENT_TEAMS.IMPORT.ERROR});
    })
    .then(() => done());
  }
});

export const add = createLogic({
  type: TOURNAMENT_TEAMS.ADD.START,

  processOptions: {
    successType: TOURNAMENT_TEAMS.ADD.SUCCESS,
    failType: TOURNAMENT_TEAMS.ADD.ERROR,
  },

  process({httpClient, action}) {
    const {tournamentId, team} = action.payload;

    return httpClient.post(`/api/v1/team_tournaments/${tournamentId}/teams`, team)
      .then((res) => res.data)
      .catch(err => getActionError(err));
  }
});

export const addSuccess = createLogic({
  type: TOURNAMENT_TEAMS.ADD.SUCCESS,

  process({getState, genericActions}, dispatch, done) {
    const state = getState();
    const params = state.tournamentTeams.get.params;

    dispatch(getTournamentTeams(params.tournamentId, params.options));
    dispatch(genericActions.notification.add({
      intlKey: 'tournament.teams.notification.add',
      type: 'success'
    }));
    done();
  }
});

export const addUnregister = createLogic({
  type: UNREGISTERED_TEAM.CREATE.START,

  processOptions: {
    successType: UNREGISTERED_TEAM.CREATE.SUCCESS,
    failType: UNREGISTERED_TEAM.CREATE.ERROR,
  },

  process({clientContextHttpClient, action}) {
    const { passCode, team, nextActions} = action.payload;

    return clientContextHttpClient.put(`/api/v1/public_team_register/${passCode}`, team)
      .then(res => {
        return {
          data: res.data,
          nextActions,
        };
      })
      .catch(err => getActionError(err));
  }
});

export const addUnregisterSuccess = createLogic({
  type: UNREGISTERED_TEAM.CREATE.SUCCESS,

  process({genericActions, action}, dispatch, done) {
    const { data, nextActions } = action.payload;
    AuthService.saveAuth(data.token);
    ls.set('teamId', data.team.id);

    dispatch(genericActions.notification.add({
      intlKey: 'tournament.teams.notification.add',
      type: 'success',
    }));

    nextActions.forEach((action) => dispatch(action));

    done();
  }
});

export const getUnregisterSuccess = createLogic({
  type: UNREGISTERED_TEAM.GET.START,

  processOptions: {
    successType: UNREGISTERED_TEAM.GET.SUCCESS,
    failType: UNREGISTERED_TEAM.GET.ERROR,
  },

  process({httpClient, action}) {
    AuthService.saveAuth(ls.get('auth'));
    return httpClient.get(`/api/v1/teams/${action.payload.teamId}`)
      .then(res => res.data)
      .catch(err => getActionError(err));
  }
});

export const unregisterTeamPlayerCreateDeck = createLogic({
  type: UNREGISTERED_TEAM.DECK_CREATE.START,

  processOptions: {
    successType: UNREGISTERED_TEAM.DECK_CREATE.SUCCESS,
    failType: UNREGISTERED_TEAM.DECK_CREATE.ERROR,
  },

  process({httpClient, action}) {
    const payload = action.payload;

    const requests = {
      createMasterDeck: () => httpClient.post(`/api/v1/tournaments/${payload.tournamentId}/players/${payload.userId}/decks`, {
        'name': '',
        'notes': '',
        'customFormat': '',
        'comment': []
      }),
      createDraft: (masterDeckId) => httpClient.post(`/api/v1/decks/${masterDeckId}/draft`),
    };

    return requests.createDraft(payload.masterDeckId)
      .then(res => res.data)
      .catch((err) => err);
  }
});

export const unregisterTeamUpdate = createLogic({
  type: UNREGISTERED_TEAM.UPDATE.START,
  processOptions: {
    successType: UNREGISTERED_TEAM.UPDATE.SUCCESS,
    failType: UNREGISTERED_TEAM.UPDATE.ERROR,
  },

  process({httpClient, action}) {
    const { teamId, nextActions } = action.payload;
    return httpClient.patch(`api/v1/teams/${teamId}`, {
      active: true,
    })
      .then(res => {
        return {
          team: res.data,
          nextActions,
        };
      })
      .catch(err => getActionError(err));
  }
});

export const unregisterTeamUpdateSuccess = createLogic({
  type: UNREGISTERED_TEAM.UPDATE.SUCCESS,

  process({action}, dispatch, done) {
    const { nextActions } = action.payload;

    nextActions.forEach(elem => dispatch(elem));

    done();
  }
});

export const updatePlayer = createLogic({
  type: TOURNAMENT_TEAMS.UPDATE_PLAYER.START,
  processOptions: {
    successType: TOURNAMENT_TEAMS.UPDATE_PLAYER.SUCCESS,
    failType: TOURNAMENT_TEAMS.UPDATE_PLAYER.ERROR,
  },

  process({httpClient, action}) {
    const {playerId, teamId, data} = action.payload;

    return httpClient.patch(`/api/v1/tournament_members/${playerId}`, data)
      .then(res => ({updatedPlayer: res.data, teamId}));
  }
});

export const updatePlayerSuccess = createLogic({
  type: TOURNAMENT_TEAMS.UPDATE_PLAYER.SUCCESS,

  process({genericActions}, dispatch, done) {
    dispatch(genericActions.notification.add({
      type: 'success',
      intlKey: 'tournament.teams.notification.update.player.info',
    }));
    done();
  }
});

export const updatePlayerError = createLogic({
  type: TOURNAMENT_TEAMS.UPDATE_PLAYER.ERROR,

  process({genericActions}, dispatch, done) {
    dispatch(genericActions.notification.add({
      intlKey: 'tournament.teams.notification.remove.error',
      type: 'danger'
    }));

    done();
  }
});

export const edit = createLogic({
  type: TOURNAMENT_TEAMS.EDIT.START,

  processOptions: {
    successType: TOURNAMENT_TEAMS.EDIT.SUCCESS,
    failType: TOURNAMENT_TEAMS.EDIT.ERROR,
  },

  process({httpClient, action}) {
    const {teamId, team} = action.payload;

    return httpClient.put(`/api/v1/teams/${teamId}/players`, team)
      .then((res) => res.data)
      .catch(err => getActionError(err));
  }
});

export const editSuccess = createLogic({
  type: TOURNAMENT_TEAMS.EDIT.SUCCESS,

  process({getState, genericActions}, dispatch, done) {
    const state = getState();
    const params = state.tournamentTeams.get.params;

    dispatch(getTournamentTeams(params.tournamentId, params.options));
    dispatch(genericActions.notification.add({
      intlKey: 'tournament.teams.notification.edit',
      type: 'success'
    }));
    done();
  }
});

export const remove = createLogic({
  type: TOURNAMENT_TEAMS.REMOVE.START,

  processOptions: {
    successType: TOURNAMENT_TEAMS.REMOVE.SUCCESS,
    failType: TOURNAMENT_TEAMS.REMOVE.ERROR,
  },

  process({httpClient, action}) {
    const {teamId} = action.payload;

    return httpClient.delete(`/api/v1/teams/${teamId}`)
      .then((res) => res.data);
  }
});

export const removeSuccess = createLogic({
  type: TOURNAMENT_TEAMS.REMOVE.SUCCESS,

  process({getState, genericActions}, dispatch, done) {
    const state = getState();
    const params = state.tournamentTeams.get.params;

    dispatch(getTournamentTeams(params.tournamentId, params.options));
    dispatch(genericActions.notification.add({
      intlKey: 'tournament.teams.notification.remove',
      type: 'success'
    }));
    done();
  }
});

export const removeError = createLogic({
  type: TOURNAMENT_TEAMS.REMOVE.ERROR,

  process({genericActions}, dispatch, done) {
    dispatch(genericActions.notification.add({
      intlKey: 'tournament.teams.notification.remove.error',
      type: 'danger'
    }));
    done();
  }
});

export default [
  get,
  importTeams,
  add,
  addSuccess,
  addUnregister,
  addUnregisterSuccess,
  getUnregisterSuccess,
  unregisterTeamPlayerCreateDeck,
  unregisterTeamUpdate,
  unregisterTeamUpdateSuccess,
  updatePlayer,
  updatePlayerSuccess,
  updatePlayerError,
  edit,
  editSuccess,
  remove,
  removeSuccess,
  removeError,
];
