import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import ModalEditTeam from '../../../ModifyTeam/ModalEditTeam';

import {editTournamentTeam, clearEditTournamentTeam} from '../../../../actions/tournamentTeams';

class ModalEditTeamContainer extends React.Component {
  componentDidUpdate(prevProps) {
    if(prevProps.editData.isPending === true && this.props.editData.isPending === false) {
      // Edit action was ended
      this.onEditEnd();
    }
    if(prevProps.deleteData.isPending === true && this.props.deleteData.isPending === false) {
      // Edit action was ended
      this.onDeleteEnd();
    }
  }

  componentWillUnmount() {
    this.props.clearEditTournamentTeam();
  }

  onEdit = (data) => {
    const {teamId, editTournamentTeam} = this.props;

    editTournamentTeam(teamId, data);
  };

  onDelete = () => {
    const {teamId} = this.props;

    this.props.onToggleDeleteModal(teamId);
  };

  onEditEnd() {
    const {editData, onClose} = this.props;

    if(editData.isSuccess) {
      // If edit action was end with success, close this modal
      onClose();
    }
  }

  onDeleteEnd() {
    const {deleteData, onClose} = this.props;

    if(deleteData.isSuccess) {
      // If edit action was end with success, close this modal
      onClose();
    }
  }

  render() {
    const {players, editData, onClose} = this.props;
    const errors = editData.error ? editData.error.extras : null;

    return (
      <ModalEditTeam
        players={players}
        errors={errors}
        onSubmit={this.onEdit}
        onDelete={this.onDelete}
        onCancel={onClose}/>
    );
  }
}

ModalEditTeamContainer.propTypes = {
  players: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    dciNumber: PropTypes.string,
    deckType: PropTypes.string.isRequired,
  })).isRequired,
  teamId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onToggleDeleteModal: PropTypes.func.isRequired,

  editData: PropTypes.object.isRequired,
  deleteData: PropTypes.object.isRequired,

  editTournamentTeam: PropTypes.func.isRequired,
  clearEditTournamentTeam: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    editData: state.tournamentTeams.edit,
    deleteData: state.tournamentTeams.remove,
  }),
  {
    editTournamentTeam,
    clearEditTournamentTeam,
  }
)(ModalEditTeamContainer);
