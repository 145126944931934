import * as React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import {hiddenSideboard, customCards} from '../../components/const';
import { TOURNAMENT_TYPES } from '../../services/TournamentType';

import DatePicker from '../DatePickerFormatted/DatePickerFormatted';

// import {types} from '../const';

class TournamentForm extends React.Component {
  static defaultProps = {
    errors: {}
  };

  constructor(props) {
    super(props);

    const {tournament} = this.props;

    this.state = {
      formData: {
        name:         tournament.name || '',
        passCode:     tournament.passCode || '',
        storeId:      tournament.store ? tournament.store.id : '',
        startDate:    tournament.startDate ? tournament.startDate : moment().format(),
        format:       tournament.format || '',
        type:         tournament.type || '',
        bioSchemaId:  tournament.bioSchema ? tournament.bioSchema.id : '',
        city:         tournament.city || '',
        state:        tournament.state || '',
        country:      tournament.country || '',
        customCards:  tournament.customCards || false,
        hiddenSideboard:  tournament.hiddenSideboard || false
      },
    };
  }

  isDisabledStartDate() {
    const {tournament} = this.props;

    return tournament.status === 'in_progress' || tournament.status === 'completed';
  }

  isDisabledStates() {
    const {states} = this.props;

    return !states || states.length === 0;
  }

  onChangeInput = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    this.onChange(fieldValue, fieldName);
  };

  onChangeType = ({value: fieldValue}) => {
    this.onChange(fieldValue, 'type');
    this.onChange('', 'format');

    if(fieldValue === TOURNAMENT_TYPES.DUO_STANDARD) {
      this.onChange('', 'passCode');
    }
  };

  onChangeSelect = ({value: fieldValue}, fieldName) => {
    this.onChange(fieldValue, fieldName);
  };

  onChange = (fieldValue, fieldName) => {
    this.setState((state) => ({
      formData: {
        ...state.formData,
        [fieldName]: fieldValue
      },
    }));
  };

  onChangeCountry = (valueData) => {
    const {updateStates} = this.props;

    this.onChangeSelect(valueData, 'country');
    this.onChangeSelect({value: ''}, 'state');
    updateStates(valueData.value);
  };

  onSave = () => {
    const {onSave, tournament} = this.props;
    const {formData} = this.state;

    const dataMapping = {
      storeId: {
        get: () => {
          return tournament.store ? tournament.store.id : null;
        },
        set: (value) => ({
          store: {
            id: value
          }
        }),
      },
      bioSchemaId: {
        get: () => {
          return tournament.bioSchema ? tournament.bioSchema.id : null;
        },
        set: (value) => ({
          bioSchema: {
            id: value
          }
        }),
      }
    };

    const updatedFields = Object.keys(formData).filter(key => {
      const value = (formData[key] !== '') ? formData[key] : null;
      const compareValue = dataMapping[key] ? dataMapping[key].get() : tournament[key];

      return value !== compareValue;
    });

    const patchData = updatedFields.reduce((prev, current) => {
      if(dataMapping[current]){
        return {
          ...prev,
          ...dataMapping[current].set(formData[current]),
        };
      }

      return {
        ...prev,
        [current]: formData[current],
      };
    }, {});

    onSave(patchData);
  };

  render() {
    const {errors, countries, states, stores, formats, bioSchemas, onCancel, types, isDisabledType, isDisabledFormat, intl} = this.props;
    const {formData} = this.state;

    const storeOptions = stores.map(item => ({
      value: item.id,
      label: item.name,
    }));
    const typesOptions = types.map(item => ({
      value: item,
      label: intl.messages[`tournament_type.${item}`] || item,
    }));

    const formatOptions = formData.type
      ? formats[formData.type].map(item => ({
          value: item,
          label: item,
        }))
      : [];

    const biographyOptions= bioSchemas.map(item => ({
      value: item.id,
      label: intl.formatMessage({id: `tournament.bioSchema.${item.name}`}),
    }));

    const isDisabledStartDate = this.isDisabledStartDate();
    const isDisabledStates = this.isDisabledStates();

    return (
      <form className="tournament container pb-5 mb-5">
        <div className="tournament-form mt-5">
          <h4 className="section-header mb-5">
            <span><FormattedMessage id="tournament.info.text"/></span>
            <button type="button" className="btn btn-success float-right" onClick={this.onSave}>
              <span><FormattedMessage id="tournament.save.btn"/></span>
            </button>

            <button type="button" className="btn btn-secondary float-right mr-2" onClick={onCancel}>
              <span><FormattedMessage id="tournament.cancel.btn"/></span>
            </button>
          </h4>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label><FormattedMessage id="tournament.label.name"/></label>
                    <input
                      type="text"
                      name="name"
                      autoComplete="nope"
                      autofill="off"
                      spellCheck="false"
                      autoCorrect="off"
                      autoCapitalize="off"
                      className={classnames('form-control', {'is-invalid': !!errors.name})}
                      value={formData.name}
                      onChange={this.onChangeInput}
                    />
                    {errors.name && <div className="text-danger">{errors.name}</div>}
                  </div>
                </div>
                  <div className="col-md-4">
                    {formData.type !== TOURNAMENT_TYPES.DUO_STANDARD && //hide passcode type,
                      //since we don't support invitation to the duo standard tournament yet
                      //CBL-2179
                    <div className="form-group">
                      <label><FormattedMessage id="tournament.label.passcode"/></label>
                      <input type="text"
                             name="passCode"
                             value={formData.passCode}
                             onChange={this.onChangeInput}
                             className={classnames('form-control', {'is-invalid': !!errors.passCode})}
                             placeholder="PASSCODE"
                             autoComplete="nope"
                             autofill="off"
                             spellCheck="false"
                             autoCorrect="off"
                             autoCapitalize="off"/>
                      {errors.passCode && <div className="text-danger">{errors.passCode}</div>}
                    </div>
                    }
                  </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label><FormattedMessage id="tournament.label.store"/></label>
                    <Select
                      id="store-select"
                      options={storeOptions}
                      value={formData.storeId}
                      onChange={(value) => this.onChangeSelect(value, 'storeId')}
                      autosize={false}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Date</label>
                    <DatePicker selected={moment(formData.startDate)}
                                disabled={isDisabledStartDate}
                                className={classnames('form-control', {'is-invalid' : errors.startDate})}
                                onChange={(date) => this.onChange(date.format(), 'startDate')}/>
                    {errors.startDate && <div className="text-danger">{errors.startDate}</div>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label><FormattedMessage id="tournament.label.type"/></label>
                    <Select
                      options={typesOptions}
                      value={formData.type}
                      onChange={this.onChangeType}
                      searchable={false}
                      clearable={false}
                      disabled={isDisabledType}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label><FormattedMessage id="tournament.label.format"/></label>
                    <Select
                      className={classnames({'is-invalid' : errors.format})}
                      options={formatOptions}
                      value={formData.format}
                      onChange={(value) => this.onChangeSelect(value, 'format')}
                      searchable={false}
                      clearable={false}
                      disabled={isDisabledFormat}
                    />
                    {errors.format && <div className="text-danger">{errors.format}</div>}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label><FormattedMessage id="tournament.label.bio"/></label>
                    <Select
                      options={biographyOptions}
                      value={formData.bioSchemaId}
                      onChange={(value) => this.onChangeSelect(value, 'bioSchemaId')}
                      searchable={false}
                      clearable={false}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label><FormattedMessage id="tournament.label.city"/></label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      value={formData.city}
                      onChange={this.onChangeInput}
                      autoComplete="nope"
                      autofill="off"
                      spellCheck="false"
                      autoCorrect="off"
                      autoCapitalize="off"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label><FormattedMessage id="tournament.label.state"/></label>
                    <Select
                      onBlurResetsInput={false}
                      onSelectResetsInput={false}
                      options={states}
                      name="selected-state"
                      disabled={isDisabledStates}
                      value={formData.state}
                      onChange={(value) => this.onChangeSelect(value, 'state')}
                      searchable={true}
                      autosize={false}
                      inputProps={{
                        autoComplete: 'off',
                        autofill: 'off',
                        spellCheck: 'false',
                        autoCorrect: 'off',
                        autoCapitalize: 'off'
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label><FormattedMessage id="tournament.label.country"/></label>
                    <Select
                      onBlurResetsInput={false}
                      onSelectResetsInput={false}
                      options={countries}
                      name="selected-state"
                      value={formData.country}
                      onChange={this.onChangeCountry}
                      searchable={true}
                      autosize={false}
                      inputProps={{
                        autoComplete: 'off',
                        autofill: 'off',
                        spellCheck: 'false',
                        autoCorrect: 'off',
                        autoCapitalize: 'off'
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label><FormattedMessage id="tournament.label.customCards"/></label>
                    <Select
                      onBlurResetsInput={false}
                      onSelectResetsInput={false}
                      options={customCards}
                      name="form-control"
                      value={formData.customCards}
                      onChange={(value) => this.onChangeSelect(value, 'customCards')}
                      clearable={false}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label><FormattedMessage id="tournament.label.hiddenSideboard"/></label>
                    <Select
                      onBlurResetsInput={false}
                      onSelectResetsInput={false}
                      options={hiddenSideboard}
                      name="form-control"
                      value={formData.hiddenSideboard}
                      onChange={(value) => this.onChangeSelect(value, 'hiddenSideboard')}
                      clearable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

TournamentForm.propTypes = {
  tournament: PropTypes.shape({
    store: PropTypes.shape({
      name: PropTypes.string,
    }),
    startDate: PropTypes.string,
    format: PropTypes.string,
    type: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  states: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  countries: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    passCode: PropTypes.string,
    format: PropTypes.string,
    startDate: PropTypes.string,
  }),
  stores: PropTypes.arrayOf(PropTypes.object).isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  formats: PropTypes.object.isRequired,
  bioSchemas: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateStates: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDisabledType: PropTypes.bool,
  isDisabledFormat: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(TournamentForm);
