import * as React from 'react';
import PropTypes from 'prop-types';
import TournamentListPlayer from './TournamentListPlayer';
import TournamentListOrganizer from './TournamentListOrganizer';
import TournamentListJudge from './TournamentListJudge';
import TournamentListAdmin from './TournamentListAdmin';
import AccessControl from '../../Utils/AccessControl';
import { roles } from '../../const';
import { FormattedMessage } from 'react-intl';

import './TournamentList.scss';
import StatusDot from '../StatusDot';

class TournamentList extends React.Component {
  render() {
    return (
      <div className="container my-5">
        <AccessControl roles={[roles.player]}>
          <TournamentListPlayer history={this.props.history} currentPage={this.props.match.params.page}/>
        </AccessControl>
        <AccessControl roles={[roles.organizer]}>
          <TournamentListOrganizer history={this.props.history} currentPage={this.props.match.params.page}/>
        </AccessControl>
        <AccessControl roles={[roles.judge]}>
          <TournamentListJudge history={this.props.history} currentPage={this.props.match.params.page}/>
        </AccessControl>
        <AccessControl roles={[roles.admin]}>
          <TournamentListAdmin history={this.props.history} currentPage={this.props.match.params.page}/>
        </AccessControl>
        <hr className="border-info"/>
        <div>
          <span className="mr-4"><StatusDot status="completed" className="mr-1"/> <FormattedMessage id="status_dot.completed"/></span>
          <span className="mr-4"><StatusDot status="in_progress" className="mr-1"/> <FormattedMessage id="status_dot.in_progress"/></span>
          <span className="mr-4"><StatusDot status="not_started" className="mr-1"/> <FormattedMessage id="status_dot.not_started"/></span>
        </div>
      </div>
    );
  }
}

TournamentList.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default TournamentList;
