import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Scrollbars} from 'react-custom-scrollbars';
import {FormattedMessage, injectIntl} from 'react-intl';
import NavigationPrompt from 'react-router-navigation-prompt';
import {withRouter} from 'react-router';
import update from 'immutability-helper';

import AccessControl from '../Utils/AccessControl';
import DataTableWithMobileDropdown from '../DataTable/DataTableWithMobileDropdown';
import Spinner from '../Spinner';
import GenericTooltip from '../GenericTooltip';
import Prompt from '../Generic/Modals/Prompt';
import Pagination from '../Pagination/Pagination';
import RoundNumber from './RoundNumber';
import {getSortingState} from '../../services/Sorting';

import TournamentUserDeleteModal from './Tabs/Users/TournamentUserDeleteModal';

import {importStandings} from '../../actions/standingsActions';
import {open} from '../../actions/modal';
import {
  addTournamentStandingsRow,
  deleteTournamentStandingsRow,
  getTournamentStandings,
  updateTournamentStandings,
  updateTournamentStandingsError,
  deleteTournamentStanding
} from '../../actions/tournamentStandings';

import { roles } from '../const';
import names from '../modals/names';

import './Standings.scss';

let inputCounter = 0;

class Standings extends React.Component {
  state = {
    searchValue: '',
    lastError: null,
    addedNewPlayersData: {},
    updatePlayersData: {},
    updateExtrasData: {},
    isEdited: false,
    sorting: null,
    isDeleteModalOpen: false,
    deleteStandingId: null,
    deleteLocally: false
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentDidUpdate(prevProps) {
    const {match} = this.props;

    if(prevProps.match.params.page !== match.params.page) {
      this.getInitialData();
      this.onReset();
    }
  }

  handleInfoClick = () => {
    this.props.open(names.HOW_TO_IMPORT_STANDINGS);
  }

  onChangeStanding = (value, item) => {
    if(item.isNew) {
      return this.updatePlayersNewData(item.id, {
        standing: value === '' ? null : value,
      });
    }
    this.updatePlayersData(item.id, {
      standing: value === '' ? null : value,
    });
  };

  onChangePoints = (value, item) => {
    if(item.isNew) {
      return this.updatePlayersNewData(item.id, {
        record: value,
      });
    }
    this.updatePlayersData(item.id, {
      record: value,
    });
  };

  onChangePlayerData = (value, item, key) => {
    if(item.isNew) {
      return this.updatePlayersNewData(item.id, {
        [key]: value,
      });
    }
    this.updatePlayersData(item.id, {
      [key]: value,
    });
  };

  onChangeRoundNumber = (value) => {
    this.updateExtrasData({
      round: value,
    });
  };

  onSubmit = () => {
    const data = {
      players: this.state.updatePlayersData,
      extras: this.state.updateExtrasData,
      newData: this.state.addedNewPlayersData
    };

    const {tournamentId, updateTournamentStandings} = this.props;

    updateTournamentStandings({
      tournamentId,
      data
    });

    this.setState({
      isEdited: false,
      updatePlayersData: {},
      updateExtrasData: {},
    });
  };

  onReset = () => {
    const {updateTournamentStandingsError} = this.props;

    updateTournamentStandingsError(null);

    this.setState({
      updatePlayersData: {},
      updateExtrasData: {},
      addedNewPlayersData: {},
      isEdited: false,
    });
  };

  onSearchChange = (e) => {
    const page = e.target.value.length > 0 ? 1 : null;
    this.setState({
      searchValue: e.target.value,
    }, () => this.getInitialData(page));
  };

  onUploadFile = (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    this.props.importStandings(this.props.tournamentId, file);
  };

  onSort = (sortedKey) => {
    const {tournamentId, getTournamentStandings, match} = this.props;
    const {sorting} = this.state;
    const sort = getSortingState(sorting, sortedKey);

    this.setState({
      sorting: sort
    });

    const sortColumn = sort && sort.actualSortedKey;
    const sortType = sort && sort.type;
    const page = match.params.page;

    getTournamentStandings({
      tournamentId,
      page,
      search: null,
      sort: sortColumn,
      order: sortType
    });
  };

  getInitialData(pageValue) {
    const {tournamentId, getTournamentStandings, match} = this.props;
    const {searchValue, sorting} = this.state;
    const page = pageValue ? pageValue : match.params.page;

    const sortColumn = sorting && sorting.actualSortedKey;
    const sortType = sorting && sorting.type;

    getTournamentStandings({
      tournamentId,
      page,
      search: searchValue || null,
      sort: sortColumn,
      order: sortType
    });
  }

  getDataTableData(tournamentMembers) {
    const {type} = this.props;

    if(type === 'team') {
      return tournamentMembers.map((item) => {
        return {
          className: 'hover-disabled',
          data: [
            this.renderStandingField(item, item.id),
            this.renderPointsField(item, item.id),
            this.renderPlayerField(item, item.id, 'lastName'),
            item.createdAt ? moment(item.createdAt).format('M/D/Y') : '-',
            item.updatedAt ? moment(item.updatedAt).format('M/D/Y') : '-',
            this.renderActionsField(item),
          ]
        };
      });
    }

    return tournamentMembers.map((item) => {
      return {
        className: 'hover-disabled',
        data: [
          this.renderStandingField(item, item.id),
          this.renderPointsField(item, item.id),
          this.renderPlayerField(item, item.id, 'dci'),
          this.renderPlayerField(item, item.id, 'lastName'),
          this.renderPlayerField(item, item.id, 'firstName'),
          item.createdAt ? moment(item.createdAt).format('M/D/Y') : '-',
          item.updatedAt ? moment(item.updatedAt).format('M/D/Y') : '-',
          this.renderActionsField(item),
        ]
      };
    });
  }

  renderActionsField(data) {
    return (
      <div className="actions-field-wrapper">
        <i className="icon-delete" onClick={() => this.toggleModal(data)}/>
      </div>
    );
  }

  updatePlayersData(id, data) {
    this.setState((state) => update(state, {
      updatePlayersData: {
        [id]: {
          $apply: (item) => item === undefined ? data : {...item, ...data}
        }
      },
      isEdited: {
        $set: true,
      },
    }));
  }

  updatePlayersNewData(id, data) {
    this.setState((state) => update(state, {
      addedNewPlayersData: {
        [id]: {
          $apply: (item) => item === undefined ? data : {...item, ...data}
        }
      },
      isEdited: {
        $set: true,
      },
    }));
  }

  

  updateExtrasData(data) {
    this.setState((state) => update(state, {
      updateExtrasData: {
        $merge: data,
      },
      isEdited: {
        $set: true,
      },
    }));
  }

  /**
   * Get merged extras data from API with edited extras data written in component state
   */
  getExtrasData() {
    const {initData} = this.props;
    const {updateExtrasData} = this.state;

    return {
      ...initData.data.extras,
      ...updateExtrasData,
    };
  }

  /**
   * Get merged players data from API with edited players data written in component state
   */
  getPlayersData() {
    const {initData} = this.props;
    const {updatePlayersData, addedNewPlayersData} = this.state;

    return initData.data.players.data.map(data => {
      const updatedData = updatePlayersData[data.id] || addedNewPlayersData[data.id];

      return updatedData
        ? {...data, ...updatedData}
        : data;
    });
  }

  addStandingRow = () => {
    this.props.addTournamentStandingsRow({
      id: inputCounter,
      standing: null,
      record: null,
      dci: null,
      lastName: null,
      firstName: null,
      createdAt: null,
      isNew: true
    });

    this.updatePlayersNewData(inputCounter, {
      standing: null,
      record: '',
      dci: '',
      lastName: '',
      firstName: '',
    });

    inputCounter = inputCounter + 1;
  }

  toggleModal = (data) => {

    if(!data) {
      return this.setState(() => ({
        isDeleteModalOpen: false,
        deleteStandingId: null,
        deleteLocally: false
      }));
    }

    this.setState((state) => ({
      isDeleteModalOpen: !state.isDeleteModalOpen,
      deleteStandingId: data.id,
      deleteLocally: data.isNew
    }));
  };

  renderStandingField(item, index) {
    return (
      <input className="standing-input form-control"
             value={item.standing || ''}
             key={`${index}-standing`}
             type="number"
             onChange={(event) => this.onChangeStanding(event.target.value, item)}
             min={1} />
    );
  }

  renderPointsField(item, index) {
    return (
      <React.Fragment>
        <div className="wrapper">
          <input className="standing-input form-control"
                 value={item.record || ''}
                 key={`${index}-points`}
                 onChange={(e) => this.onChangePoints(e.target.value, item)}
                 type="text"/>
          {this.renderPointsFieldIcon(item)}
        </div>
      </React.Fragment>
    );
  }

  renderPointsFieldIcon(item) {
    const {updateErrors, intl} = this.props;

    const error = updateErrors && updateErrors.players && updateErrors.players[item.id];

    if(error) {
      const errorMessage = error.data.status === 422 && error.data.data.extras.matchResults
        ? intl.messages['standings.error.points.validation']
        : intl.messages['standings.error.general'];

      const message = (
        <div>
          <span className="text-danger d-block">{errorMessage}</span>
          <span className="d-block">{intl.messages['standings.format.info']}</span>
        </div>
      );

      return <GenericTooltip id={item.id} variant="iconInfoDanger" placement="right" message={message}/>;
    }

    return <GenericTooltip id={item.id} variant="iconInfo" placement="right" message={intl.messages['standings.format.info']}/>;
  }

  renderPlayerField(item, index, field) {
    return (
      <React.Fragment>
        <div className="wrapper">
          <input className="standing-input form-control"
                 value={item[field] || ''}
                 key={`${index}-${field}`}
                 onChange={(e) => this.onChangePlayerData(e.target.value, item, field)}
                 type="text"/>
        </div>
      </React.Fragment>
    );
  }

  renderTable() {
    const {intl, type, standingsImport} = this.props;
    const {sorting} = this.state;

    const players = this.getPlayersData().filter(item => !item.deleted);
    let columns = [];

    if(type === 'team') {
      columns = [
        {name: intl.messages['standings.column.standing'], className: ['text-center', 'col-standing', 'sortable'], sortedKey: 'standing'},
        {name: intl.messages['standings.column.points'], className: 'col-points'},
        {name: intl.messages['standings.column.team'], sortedKey: 'lastName', className: ['col-standings-teams', 'sortable']},
        {name: intl.messages['standings.column.createdAt'], sortedKey: 'createdAt', className: 'sortable'},
        {name: intl.messages['standings.column.updatedAt'], sortedKey: 'updatedAt', className: 'sortable'},
        {name: 'DELETE', className: 'col-actions', isVisibleOnMobile: false}
      ];
    } else {
      columns = [
        {name: intl.messages['standings.column.standing'], className: ['text-center', 'col-standing', 'sortable'], sortedKey: 'standing'},
        {name: intl.messages['standings.column.points'], className: 'col-points'},
        {name: intl.messages['standings.column.dci']},
        {name: intl.messages['standings.column.lastname'], sortedKey: 'lastName', className: 'sortable'},
        {name: intl.messages['standings.column.firstname'], sortedKey: 'firstName', className: 'sortable'},
        {name: intl.messages['standings.column.createdAt'], sortedKey: 'createdAt', className: 'sortable'},
        {name: intl.messages['standings.column.updatedAt'], sortedKey: 'updatedAt', className: 'sortable'},
        {name: 'DELETE', className: 'col-actions', isVisibleOnMobile: false}
      ];
    }

    
    const data = this.getDataTableData(players);

    const dropdownData = (dataRow, rowIndex) => {
      const standingData = players[rowIndex];

      const buttons = [
        <div key={`${rowIndex}-delete`} onClick={() => this.toggleModal(standingData)}>
          <FormattedMessage id="tournament.players.mobile.delete"/> <i className="icon-delete"/>
        </div>,
      ];

      return buttons;
    };

    if(standingsImport.isLoad) {
      return <React.Fragment>
        <div className="py-3">
          <Spinner/>
          <p className="text-center"><FormattedMessage id="tournament.players.import.loading_message"/></p>
        </div>
      </React.Fragment>;
    }

    return (
      <Scrollbars autoHeight
                  autoHeightMax={2000}
                  className="scrollbars"
                  renderThumbHorizontal={props => <div {...props} className="scrollbar-primary"/>}>
        <DataTableWithMobileDropdown
          className={'data-table--th-center'}
          data={data}
          columns={columns}
          onSort={this.onSort}
          sortedOptions={sorting}
          dropdownData={dropdownData}
        />
      </Scrollbars>
    );
  }

  renderRound() {
    const {updateErrors, intl} = this.props;
    const isError = updateErrors && updateErrors.extras;

    const extraData = this.getExtrasData();
    const roundNumber = parseInt(extraData.round, 10);

    return (
      <div className="col-md-3 mb-4">
        <RoundNumber className=""
                     value={roundNumber}
                     onChange={this.onChangeRoundNumber}>
          {
            isError
              ? <GenericTooltip id="round-update-error"
                                variant="iconInfoDanger"
                                placement="right"
                                message={intl.messages['standings.error.general']}/>
              : null
          }
        </RoundNumber>
      </div>
    );
  }

  renderAddButton() {
    return (
      <div className="col-md-3 mb-4 ml-auto">
        <div className="text-right">
          <button className="btn btn-primary" onClick={this.addStandingRow}>add standing</button>
        </div>
      </div>
    );
  }

  renderPagination() {
    const {tournamentId, initData} = this.props;
    const {searchValue} = this.state;

    const totalPages = initData.data.players.pagination.lastPage;

    if(!totalPages || searchValue){
      return null;
    }

    return <Pagination totalPages={totalPages} url={`/tournaments/show/${tournamentId}/standings/:page`}/>;
  }

  renderControlButtons() {
    return (
      <div className="control-buttons">
        <button type="button" className="btn btn-light btn-lg" onClick={this.onReset}>Reset</button>
        <button type="button" className="btn btn-success btn-lg" onClick={this.onSubmit}>Save</button>
      </div>
    );
  }

  renderMainContent() {
    return (
      <React.Fragment>
        {this.renderTable()}

        <AccessControl roles={[roles.organizer, roles.admin]}>
          <div className="players-links-wrapper">
            <div className="d-flex">
              <GenericTooltip
                id={'player-standings-upload'}
                className={'tooltip-info'}
                placement={'right'}
                message={<FormattedMessage id="tournament.players.import.tooltip.text"/>}
              >
                <div className="players-import-standings players-link">
                  <input type="file" className="file-upload" value="" onChange={this.onUploadFile}/>
                  <FormattedMessage id="tournament.players.import.standings"/>
                </div>
              </GenericTooltip>
              <span className="howto-link" onClick={this.handleInfoClick}><FormattedMessage id="modal.help.link"/></span>
            </div>
          </div>
        </AccessControl>

        {this.renderPagination()}
        {this.renderControlButtons()}
      </React.Fragment>
    );
  }

  submitDelete = () => {
    const {deleteTournamentStanding, tournamentId, deleteTournamentStandingsRow} = this.props;
    const {deleteStandingId, deleteLocally, addedNewPlayersData} = this.state;

    if(deleteLocally) {
      deleteTournamentStandingsRow(deleteStandingId);
      delete addedNewPlayersData[deleteStandingId];
    } else {
      deleteTournamentStanding({tournamentId, standingId: deleteStandingId});
    }

    this.toggleModal(null);
  };

  render() {
    const {initData, intl} = this.props;
    const {isEdited} = this.state;
    const isPending = (initData.isPending || !initData.data.players || !initData.data.extras);

    return (
      <div className="standings-wrapper">
        <div className="d-flex align-items-center players-top">
          <h4 className="section-header mb-4">
            Standings
          </h4>
        </div>
        <div>
          <div className="form-row">
            <div className="mb-3 col-md-6">
              <div className="input-group">
                <input onChange={this.onSearchChange}
                       type="text"
                       className="form-control"
                       placeholder={intl.formatMessage({id: 'tournament.standings.search.placeholder'})}
                       value={this.state.searchValue}
                       autoComplete="nope"
                       autofill="off"
                       spellCheck="false"
                       autoCorrect="off"
                       autoCapitalize="off"/>
                <div className="input-group-append">
                  <button className="btn btn-light" type="button"><i className="icon-search"/></button>
                </div>
              </div>
            </div>
            <div className="col-md-3 offset-md-3 d-none">
              <select className="form-control custom-select">
                <option>Default</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            {!isPending && this.renderRound()}
            {this.renderAddButton()}
          </div>
        </div>

        {
          isPending
            ? <Spinner/>
            : this.renderMainContent()
        }

        <TournamentUserDeleteModal
          isOpen={this.state.isDeleteModalOpen}
          toggle={() => this.toggleModal(null)}
          titleIntl={'tournament.standings.delete'}
          textIntl={'tournament.standings.remove.text'}
          onSubmit={this.submitDelete}
        />

        <NavigationPrompt when={isEdited} disableNative={true}>
          {({ onConfirm, onCancel }) => {
            const onSubmit = () => {
              this.onReset(); // Remove info about table changes
              onConfirm();
            };

            return (
              <Prompt isOpen={true}
                      title={intl.messages['standings.unsavedPrompt.title']}
                      onClose={onCancel}
                      onSubmit={onSubmit}
                      closeOnSubmit={false}>
                <FormattedMessage id="standings.unsavedPrompt.message"/>
              </Prompt>
            );
          }}
        </NavigationPrompt>
      </div>
    );
  }
}

Standings.propTypes = {
  tournamentId: PropTypes.string,

  intl: PropTypes.object.isRequired,
  match: PropTypes.object,

  initData: PropTypes.object.isRequired,
  updateErrors: PropTypes.object,

  importStandings: PropTypes.func.isRequired,
  getTournamentStandings: PropTypes.func.isRequired,
  updateTournamentStandings: PropTypes.func.isRequired,
  updateTournamentStandingsError: PropTypes.func.isRequired,
  addTournamentStandingsRow: PropTypes.func,
  deleteTournamentStandingsRow: PropTypes.func,
  deleteTournamentStanding: PropTypes.func,
  standingsImport: PropTypes.object,
  open: PropTypes.func,
  type: PropTypes.oneOf(['team'])
};

export default injectIntl(withRouter(connect(
  (state) => ({
    initData: state.tournamentStandings.get,
    updateErrors: state.tournamentStandings.update.errors,
    standingsImport: state.standingsImport
  }),
  ({
    importStandings,
    getTournamentStandings,
    updateTournamentStandings,
    updateTournamentStandingsError,
    addTournamentStandingsRow,
    deleteTournamentStandingsRow,
    deleteTournamentStanding,
    open,
  })
)(Standings)));
