import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cardImageShow } from '../../../actions/cardSearchAction';
import {renderManaCost} from './ManaCost';

import './SelectValue.scss';

class SelectValue extends React.Component {

  showImage = () => {
    const data = {
      value: this.props.option.imageUrl,
    };
    this.props.cardImageShow(data);
  }

  handleMouseDown = (event) => {
		event.preventDefault();
		event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  }

	handleMouseEnter = (event) => {
    this.props.onFocus(this.props.option, event);
    this.showImage();
  }

	handleMouseMove = (event) => {
		if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  }

  componentDidUpdate(prevProps) {
    if ((this.props.isFocused !== prevProps.isFocused) && this.props.isFocused)
      this.showImage();
  }

  render() {
    const { option } = this.props;
    return (
    <div
      className={this.props.className}
      onMouseDown={this.handleMouseDown}
      onMouseEnter={this.handleMouseEnter}
      onMouseMove={this.handleMouseMove}
    >
      <p className="m-0 p-0">{option.name}<small className="p-1">{option.set.name}</small></p>
      <div>{option.manaCost && renderManaCost(option)}</div>
    </div>
    );
  }
}

SelectValue.propTypes = {
  children: PropTypes.string,
  option: PropTypes.object,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
  isFocused: PropTypes.bool,
  className: PropTypes.string,
  cardImageShow: PropTypes.func,
};

export default connect(
  null,
  ({
    cardImageShow
  })
)(SelectValue);
