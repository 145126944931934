import { NOTIFICATION } from '../actions/types';

const initialState = {
  messages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION.ADD:
      return {
        messages: [...state.messages, {...action.payload}]
      };
    case NOTIFICATION.REMOVE:
      return {
        messages: state.messages.filter(message => message.id !== action.payload)
      };
    default:
      return state;
  }
};
