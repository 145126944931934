import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {getTournamentByPasscode} from '../../../../actions/tournamentActions';

import SimpleTopBar from '../../../Layout/SimpleTopBar';
import LoginForm from '../../Login/LoginForm';
import DateFormatted from '../../../DateFormatted/DateFormatted';
import JoinTournamentService from '../../../../services/JoinTournamentService';

import logo from '../../../../assets/images/logo.svg';
import './Main.scss';

class Main extends React.Component {
  onClickRegister = () => {
    const {history, match} = this.props;
    JoinTournamentService.setTournamentData({
      url: `/t/${match.params.passcode}`,
      expire: new Date().getTime() + 3600000
    });

    history.push('/register');
  };

  onClickLogin = () => {
    const {history} = this.props;

    history.push('/login');
  };

  renderWithoutRegisterLink() {
    const { tournament: { data: { type } }, location} = this.props;

    const linkUrl = (type === 'Team')
      ? `${location.pathname}/create/team`
      : `${location.pathname}/create/player`;

    return (
      <Link to={linkUrl} className="submit-deck-link">
        <FormattedMessage id="register.tournament.deck.without.account"/>
      </Link>
    );
  }

  render() {
    const {match, tournament} = this.props;

    return (
      <div className="page-join-tournament-unregistered-main">
        <SimpleTopBar />

        <div className="container-fluid fix-topbar-padding">
          <div className="row main-wrapper">
            <div className="col-12 col-lg-6 left">
              <h4>{tournament.data.name}</h4>
              <h5><DateFormatted value={tournament.data.startDate}/> {tournament.data.city}</h5>

              <p><FormattedMessage id="register.tournament.text"/></p>

              <ul className="list-unstyled">
                <li><i className="icon-decklist"/><FormattedMessage id="register.tournament.decklist"/></li>
                <li><i className="icon-tournaments"/><FormattedMessage id="register.tournament.managment"/></li>
                <li><i className="icon-streaming-tools"/><FormattedMessage id="register.tournament.tools"/></li>
              </ul>

              <button className="btn btn-block btn-lg btn-primary" onClick={this.onClickRegister}><FormattedMessage id="register.tournament.btn.register"/></button>

              {this.renderWithoutRegisterLink()}
            </div>

            <div className="col-12 col-lg-6 right">
              <div className="desktop-content">
                <img src={logo} alt="Cardboard.live"/>

                <LoginForm redirectOnSuccess={`/t/${match.params.passcode}`}/>
              </div>

              <div className="mobile-content">
                <ul className="list-unstyled">
                  <li><i className="icon-decklist"/><FormattedMessage id="register.tournament.decklist"/></li>
                  <li><i className="icon-tournaments"/><FormattedMessage id="register.tournament.managment"/></li>
                  <li><i className="icon-streaming-tools"/><FormattedMessage id="register.tournament.tools"/></li>
                </ul>

                <button className="btn btn-block btn-lg btn-primary" onClick={this.onClickLogin}><FormattedMessage id="register.tournament.btn.login"/></button>
                <p><FormattedMessage id="register.tournament.text.or"/></p>
                <button className="btn btn-block btn-lg btn-primary" onClick={this.onClickRegister}><FormattedMessage id="register.tournament.btn.register"/></button>

                {this.renderWithoutRegisterLink()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Main.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  tournament: PropTypes.object.isRequired,
  getTournamentByPasscode: PropTypes.func.isRequired,
};

export default withRouter(connect(
  state => ({
    tournament: state.tournament
  }),
  {
    getTournamentByPasscode,
  }
)(Main));
