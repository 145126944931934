import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import update from 'immutability-helper';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import { add as addNotification } from '../../actions/notificationActions';
import { getSingleUser, updateUser, deleteUser, updateUserToggle } from '../../actions/userActions';
import { addAssociations } from '../../actions/tournamentActions';
import {displayUserRoles} from '../../services/DisplayRoles';

import UserDecks from './UserDecks';
import UserOrganizerList from './UserOrganizerList';
import UserPlayerList from './UserPlayerList';
import UserJudgeList from './UserJudgeList';
import Select from 'react-select';
import {isEqual} from 'lodash';
import { FormattedMessage } from 'react-intl';
import Spinner from '../Spinner';
import DateFormatted from '../DateFormatted/DateFormatted';

import TournamentAssociationModal from './TournamentAssociationModal/TournamentAssociationModal';

import './User.scss';

class User extends React.Component {
  state = {
    isOpen: false,
    userData: {
      firstName: '',
      lastName: '',
      dciNumber: '',
      roles: [],
      judgeLevel: null
    },
    isOpenAssociationModal: false,
  };

  static getDerivedStateFromProps(nextProps) {
    if(nextProps.user.data && !nextProps.user.isError && !nextProps.user.isLoad) {
      return {
        userData: {
          firstName: nextProps.user.data.firstName,
          lastName: nextProps.user.data.lastName,
          roles: nextProps.user.data.roles,
          dciNumber: nextProps.user.data.dciNumber,
          judgeLevel: nextProps.user.data.judgeLevel,
        }
      };
    }

    return null;
  }

  componentDidMount(){
    this.props.getSingleUser(this.props.match.params.userId);
    if(this.props.location.state && this.props.location.state.isEdit) {
      this.props.updateUserToggle();
    }
  }

  componentWillUnmount(){
    if(this.props.user.isEditMode) {
      this.toggleEditMode();
    }
  }

  toggleEditMode = () => {
    this.props.updateUserToggle();
  }

  updatedData = () => {
    const {firstName, lastName, dciNumber, roles, judgeLevel} = this.state.userData;
    const {user} = this.props;
    const updatedValues = {};

    if(user.data.firstName !== firstName) {
      updatedValues.firstName = firstName;
    }
    if(user.data.lastName !== lastName) {
      updatedValues.lastName = lastName;
    }
    if(user.data.dciNumber !== dciNumber) {
      updatedValues.dciNumber = dciNumber;
    }
    if(!isEqual(user.data.roles, roles) ) {
      updatedValues.roles = roles;
    }
    if(user.data.judgeLevel !== judgeLevel) {
      updatedValues.judgeLevel = judgeLevel;
    }

    return updatedValues;
  }

  saveUserData = () => {
    this.props.updateUser(this.props.match.params.userId, this.state.userData);
    this.props.updateUserToggle();
  }

  submit = () => {
    this.props.deleteUser(this.props.match.params.userId);
    this.toggleModal(null, null);
    this.props.history.push('/users');
  }

  addTournamentAssociation = () => {
    this.setState({
      isOpenAssociationModal: true,
    });
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleAssociationModal = () => {
    this.setState((prevState) => ({
      isOpenAssociationModal: !prevState.isOpenAssociationModal,
    }));
  }

  handleConfirmEmailClick = () => {
    const {updateUser} = this.props;

    updateUser(this.props.match.params.userId, {enabled: true});
  };

  handleResolvedAssociation = (data) => {
    this.props.addAssociations(data.map(item => {
      return {
        userId: item.userId,
        tournamentId: item.tournament.id,
        role: item.role,
      };
    }));
  }

  toggleCheckbox = (e, role) => {
    if(e.target.checked) {
      this.setState(prevState => ({
        userData: {
          ...this.state.userData,
          roles: update(prevState.userData.roles, {$push: [role]})
        },
      }));

      if(role === 'JUDGE') {
        this.setState((prevState) => ({
          userData: {
            ...prevState.userData,
            judgeLevel: 1,
          }
        }));
      }
    } else {
      const index = this.state.userData.roles.indexOf(role);

      this.setState(prevState => ({
        userData: {
          ...this.state.userData,
          roles: update(prevState.userData.roles, {$splice: [[index, 1]]})
        }
      }));
    }
  }

  onChange = (e, prop) => {
    this.setState({
      userData: {
        ...this.state.userData,
        [prop]: e.target.value
      }
    });
  }

  onSelect = (value) => {
    this.setState({
      userData: {
        ...this.state.userData,
        judgeLevel: value
      }
    });
  }

  showRole = (role) => {
    if(!this.state.userData){
      return null;
    }

    const checked = this.state.userData.roles && this.state.userData.roles.indexOf(role) > -1;

    return <div className="form-group form-check">
          <input type="checkbox" className="form-check-input" id={role} checked={checked} onChange={(e) => this.toggleCheckbox(e, role)} />
          <label className="form-check-label" htmlFor={role}>{role}</label>
        </div>;
  }

  render() {
    const userId  = this.props.match.params.userId;
    const user    = this.props.user && this.props.user.data;

    const {isOpen, userData} = this.state;
    const {isEditMode} = this.props.user;

    if(!user || user.isLoad){
      return <Spinner/>;
    }

    return (
      <div className="container container-user mt-5 mb-5 pb-2">
        <div className="user-page-options">
          <Link to="/users" className="back-link">{'< List of users'}</Link>
          <div className="mobile-btn-wrapper">
            {!isEditMode && <button className="btn btn-warning" onClick={this.toggleEditMode}><FormattedMessage id="user.btn.edit"/> <i className="icon-edit d-none d-md-inline"/></button>}
            {isEditMode && <button className="btn btn-secondary ml-2" onClick={this.toggleEditMode}><FormattedMessage id="tournaments.btn.cancel"/></button>}
            {isEditMode && <button className="btn btn-success ml-2" onClick={this.saveUserData}><FormattedMessage id="tournaments.btn.save"/></button>}
            {!isEditMode && <button className="btn btn-danger ml-2" onClick={this.toggleModal}><span className="d-md-none"><FormattedMessage id="user.delete"/></span><i className="icon-delete d-none d-md-inline"/></button>}
          </div>
        </div>
        <div className="user-data">
          <div className="row">
            <div className="col-6 col-md-3">
              <label className="text-uppercase"><FormattedMessage id="user.label.last.name"/></label>
              <input disabled={!isEditMode} type="text" className="form-control user-disabled user-name" value={userData.lastName} onChange={(e) => this.onChange(e, 'lastName')}/>
            </div>
            <div className="col-6 col-md-3">
              <label className="text-uppercase"><FormattedMessage id="user.label.first.name"/></label>
              <input disabled={!isEditMode} type="text" className="form-control user-disabled user-name" value={userData.firstName} onChange={(e) => this.onChange(e, 'firstName')}/>
            </div>
            <div className="col-6 col-md-3">
              <label className="text-uppercase"><FormattedMessage id="user.label.dci"/></label>
              <input disabled={!isEditMode} type="text" className="form-control user-disabled user-dci" value={userData.dciNumber} onChange={(e) => this.onChange(e, 'dciNumber')}/>
            </div>
            <div className="col-6 col-md-3">
              <label className="text-uppercase"><FormattedMessage id="user.label.date"/> <span className="d-none d-md-inline"><FormattedMessage id="user.label.date.register"/></span></label>
              <div className="">
                <span className="font-weight-bold"><DateFormatted value={user.createdAt}/></span>
              </div>
            </div>
          </div>
          <hr/>
          <div className="user-data-bottom">
            <span className="user-roles">
              {!isEditMode && user.roles && displayUserRoles(user.roles, ' | ', false)}
              {isEditMode && <div className="user-edit-role">
                {this.showRole('PLAYER')}
                {this.showRole('ORGANIZER')}
                {this.showRole('JUDGE')}
                {this.showRole('ADMIN')}
              </div>
            }
            </span>
            <span className="user-select">
              {!isEditMode && user.judgeLevel && `Judge level ${user.judgeLevel}`}
              {isEditMode && userData.roles.includes('JUDGE') && <Select
                            id="state-select"
                            className="user-select"
                            onBlurResetsInput={false}
                            onSelectResetsInput={false}
                            options={[{
                              label: 'Judge level 1',
                              value: 1
                            },{
                              label: 'Judge level 2',
                              value: 2
                            },{
                              label: 'Judge level 3',
                              value: 3
                            }]}
                            simpleValue
                            value={userData.judgeLevel}
                            onChange={this.onSelect}
                            searchable={false}
                            clearable={false}
                          />}
              <div>
              </div>
            </span>
            <div className="d-flex align-items-center align-content-center justify-content-center flex-wrap">
              <p className="m-0 mr-1 user-email">{user.email}</p>
              {!user.enabled && (
                <React.Fragment>
                  <p className="m-0 mr-1 user-email user-email--nowrap">(not confirmed)</p>
                  <div className="btn btn-warning" onClick={this.handleConfirmEmailClick}>
                    <FormattedMessage id="user.email.confirm" />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <UserDecks userId={userId} history={this.props.history}/>

        <h4 className="section-header section-header--line mb-5 mt-5"><FormattedMessage id="user.title.associations"/>
          <button
            type="button"
            className="btn btn-info ml-3 mr-3"
            onClick={this.addTournamentAssociation}>
            <FormattedMessage id="user.btn.new"/>
          </button>
          <hr/>
        </h4>

        <UserOrganizerList userId={userId} history={this.props.history}/>
        <UserPlayerList userId={userId} history={this.props.history}/>
        <UserJudgeList userId={userId}/>

        <Modal isOpen={isOpen} toggle={(e) => this.toggleModal(e, null)}>
          <ModalHeader toggle={(e) => this.toggleModal(e, null)}><FormattedMessage id="user.delete"/></ModalHeader>
          <ModalBody>
            <FormattedMessage id="user.confirm.delete"/>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-success" onClick={this.submit}><FormattedMessage id="tournament.delete.modal.submit"/></button>
            <button className="btn btn-outline-secondary" onClick={(e) => this.toggleModal(e, null)}><FormattedMessage id="tournament.delete.modal.cancel"/></button>
          </ModalFooter>
        </Modal>
        <TournamentAssociationModal isOpen={this.state.isOpenAssociationModal}
                                    toggle={this.toggleAssociationModal}
                                    onResolve={this.handleResolvedAssociation}
                                    userId={userId}/>
      </div>

    );
  }
}

User.propTypes = {
  getSingleUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  deleteUser: PropTypes.func.isRequired,
  addAssociations: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  addNotification: PropTypes.func,
  updateUserToggle: PropTypes.func,
};

export default connect(
  (state) => ({
    user: state.singleUser,
  }),
  ({
    getSingleUser,
    deleteUser,
    addAssociations,
    updateUser,
    addNotification,
    updateUserToggle
  })
)(User);
