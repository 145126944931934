import { TOURNAMENT_ROLES_JUDGE, TOURNAMENT_ROLES_ORGANIZER } from '../actions/types';

const initialState = {
  judge: {
    data: null,
    isLoad: false,
    isError: false,
    error: null,
  },
  organizer: {
    data: null,
    isLoad: false,
    isError: false,
    error: null,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_ROLES_JUDGE.FETCH:
      return {
        ...state,
        judge: {
          ...state.judge,
          isLoad: true,
          isError: false,
          error: null,
        }
      };
    case TOURNAMENT_ROLES_JUDGE.ERROR:
      return {
        ...state,
        judge: {
          ...state.judge,
          isLoad: false,
          isError: true,
          error: action.payload
        }
      };
    case TOURNAMENT_ROLES_JUDGE.SUCCESS:
      return {
        ...state,
        judge: {
          isLoad: false,
          isError: false,
          error: null,
          data: action.payload,
        }
      };
    case TOURNAMENT_ROLES_ORGANIZER.FETCH:
      return {
        ...state,
        organizer: {
          isLoad: true,
          isError: false,
          error: null,
        }
      };
    case TOURNAMENT_ROLES_ORGANIZER.ERROR:
      return {
        ...state,
        organizer: {
          isLoad: false,
          isError: true,
          error: action.payload
        }
      };
    case TOURNAMENT_ROLES_ORGANIZER.SUCCESS:
      return {
        ...state,
        organizer: {
          isLoad: false,
          isError: false,
          error: null,
          data: action.payload,
        }
      };
    default:
      return state;
  }
};
