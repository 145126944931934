import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import TournamentUser from './TournamentUser';
import Spinner from '../../../Spinner';

import {clear, get, saveDeckType, createDeck, updateShadowUser} from '../../../../actions/tournamentUserActions';
import {deleteTournamentPlayer} from '../../../../actions/tournamentMembers';

class TournamentUserEdit extends React.Component {
  componentDidMount() {
    const {userId, tournamentId} = this.getUrlParams();
    this.props.get(tournamentId, userId);
  }

  componentWillUnmount() {
    this.props.clear();
  }

  getUrlParams() {
    return this.props.match.params;
  }

  deleteUser = () => {
    const {userId, tournamentId} = this.getUrlParams();

    this.props.deleteTournamentPlayer(tournamentId, userId, false, `/tournaments/show/${tournamentId}/users`);
  };

  saveDeckType = (data, isPaperdeck, biography) => {
    const {tournamentId} = this.getUrlParams();

    this.props.saveDeckType({
      membershipId: data.id,
      paperdeck: isPaperdeck,
      biography,
      redirectUrl: `/tournaments/show/${tournamentId}/users`
    });
  };

  createDeck = (masterDeckId) => {
    const {userId, tournamentId} = this.getUrlParams();

    this.props.createDeck({
      masterDeckId,
      userId,
      tournamentId: tournamentId
    });
  };

  render() {
    const {match, tournamentUser, updateShadowUser} = this.props;
    const {params} = match;

    if(tournamentUser.isLoad || !tournamentUser.data) {
      return <Spinner/>;
    }

    const user = tournamentUser.data;
    const draft = tournamentUser.createDeck;
    const tournamentId = params.tournamentId;
    const userId = params.userId;

    return (
      <TournamentUser tournamentId={tournamentId}
                      userId={userId}
                      data={user}
                      draft={draft}
                      onDeleteUser={this.deleteUser}
                      onSaveDeckType={this.saveDeckType}
                      onCreateDeck={this.createDeck}
                      updateShadowUser={updateShadowUser}
      />
    );
  }
}

TournamentUserEdit.propTypes = {
  match: PropTypes.object.isRequired,
  tournamentUser: PropTypes.object.isRequired,
  clear: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  deleteTournamentPlayer: PropTypes.func.isRequired,
  saveDeckType: PropTypes.func.isRequired,
  createDeck: PropTypes.func.isRequired,
  updateShadowUser: PropTypes.func,
};

export default connect(
  (state) => ({
    tournamentUser: state.tournamentUser,
    deckNames: state.deckNames,
  }),
  {
    clear,
    get,
    deleteTournamentPlayer,
    saveDeckType,
    createDeck,
    updateShadowUser,
  }
)(TournamentUserEdit);
