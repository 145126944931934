import React from 'react';
import propTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import DataTable from './DataTable';

import './DataTableWithMobileDropdown.scss';

class DataTableWithMobileDropdown extends React.Component {
  static defaultProps = {
    scrollable: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      dropdownIndexOpen: null
    };
  }

  toggle = (index) => {
    this.setState(prevState => ({
      dropdownIndexOpen: prevState.dropdownIndexOpen === null ? index : null
    }));
  }

  checkIfEmpty = (arr) => {
    let isEmpty = true;
    arr.map(item => {
      if(item){
        isEmpty = false;
      }
    });
    return isEmpty;
  }

  renderDataTable = () => {
    const {scrollable, ...propsDataTable} = this.props;
    if(scrollable) {
      return (
        <Scrollbars autoHeight
                  autoHeightMax={1000}
                  className="scrollbars"
                  renderThumbHorizontal={props => <div {...props} className="scrollbar-primary"/>}>
          <div className={'data-table-wrapper'}>
            <DataTable {...propsDataTable}/>
          </div>
        </Scrollbars>
      );
    }

    return (
      <div className={'data-table-wrapper'}>
        <DataTable {...propsDataTable}/>
      </div>
    );
  }

  render() {
    const {dropdownData, dropdownText, ...propsDataTable} = this.props;

    return (
      <div className={'data-table-with-mobile-dropdown'}>
        {this.renderDataTable()}
        <div className={'dropdown-wrapper'}>
          {
            propsDataTable.data.map((item, index) => {
              const isEmpty = this.checkIfEmpty(dropdownData(item, index));
              return (
                <div className={'dropdown-wrapper-item' + (isEmpty ? ' dropdown-wrapper-item--disabled' : '')} key={index}>
                  <Dropdown isOpen={this.state.dropdownIndexOpen === index}
                            toggle={() => this.toggle(index)}>
                    <DropdownToggle>
                      {
                        dropdownText
                          ? dropdownText
                          : <i className={'icon-options'}/>
                      }
                    </DropdownToggle>
                    <DropdownMenu right={true} flip={false}>
                      {
                        dropdownData(item, index).map((dropdownItemValue, i) => {
                          return <DropdownItem key={i}>{dropdownItemValue}</DropdownItem>;
                        })
                      }
                    </DropdownMenu>
                  </Dropdown>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}

DataTableWithMobileDropdown.propTypes = {
  dropdownData: propTypes.func.isRequired,
  dropdownText: propTypes.string,
  ...DataTable.propTypes
};

export default DataTableWithMobileDropdown;
