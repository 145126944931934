import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { getTournamentCasters, addTournamentCaster, deleteTournamentCaster } from '../../actions/tournamentMembers';
import PropTypes from 'prop-types';
import DataTableWithMobileDropdown from '../DataTable/DataTableWithMobileDropdown';
import { add as addNotification } from '../../actions/notificationActions';
import { FormattedMessage } from 'react-intl';
import Spinner from '../Spinner';

import './Tournament.scss';

class Casters extends React.Component {
  state = {
    isOpen: false,
    caster: '',
    deleteCasterId: null,
    isConfirmOpen: null,
    error: null,
  };

  componentDidMount(){
    this.props.getTournamentCasters(this.props.tournamentId);
  }

  submit = () => {
    this.props.addTournamentCaster(this.props.tournamentId, {description: this.elem.value});
    this.toggleModal();
  }

  submitDelete = () => {
    this.props.deleteTournamentCaster(this.state.deleteCasterId, this.props.tournamentId);
    this.toggleConfirmModal(null);
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      caster: '',
      error: null
    });
  }

  toggleConfirmModal = (id) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      deleteCasterId: id,
    });
  }

  renderTable = () => {
    const {tournamentMembers} = this.props;
    if(!tournamentMembers || !tournamentMembers.casters.data) {
      return null;
    }

    if(tournamentMembers.casters.data.length == 0) {
      return <p className="text-center "><FormattedMessage id="tournament.casters.no.casters"/></p>;
    }

    const data = tournamentMembers.casters.data.filter(item => !item.deleted).map((item,  index) => {
      return [
        item.token,
        <CopyToClipboard text={item.token} onCopy={this.copySuccess} key={`${index}-copy`}><button key={`${index}-copy`} type="button" className="btn btn-sm btn-success"><i className="icon-copying"/></button></CopyToClipboard>,
        item.description,
        <div key={`${index}-delete`} className="tournament-link text-center" onClick={() => this.toggleConfirmModal(item.id)}><i className="icon-delete" /></div>,
      ];
    });

    const columns = [
      {name: 'TOKEN', flex: 2, key: 1, divClassName: 'initial-width'},
      {name: 'COPY', flex: 1, key: 2},
      {name: 'DESCRIPTION', flex: 2, key: 3},
      {name: 'DELETE', flex: 1, key: 4, isVisibleOnMobile: false},
    ];

    const dropdownData = (dataRow, rowIndex) => [
      <span key={rowIndex + '-remove'} className="tournament-link tournament-link-mobile" onClick={() => this.toggleConfirmModal(tournamentMembers.casters.data[rowIndex].user.id)}><FormattedMessage id="btn.delete"/></span>,
    ];


    return (
      <DataTableWithMobileDropdown
        data={data}
        columns={columns}
        dropdownData={dropdownData}
      />);
  };

  copySuccess = () => {
    this.props.addNotification({
      text: <FormattedMessage id="copy.success"/>,
      type: 'success',
      timeout: 4,
    });
  }

  render() {
    const {tournamentMembers} = this.props;

    if(!tournamentMembers.casters.data || tournamentMembers.casters.isLoad){
      return <Spinner/>;
    }

    return (
      <div>
        <h4 className="section-header mb-4"><FormattedMessage id="tournament.casters"/> <button type="button" className="btn btn-primary float-right" onClick={this.toggleModal}><span className="d-none d-md-block">Generate</span> <i className="icon-plus d-md-none"/></button></h4>
          <div className="tournament-table">
            {this.renderTable()}
          </div>
          <Modal isOpen={this.state.isOpen} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}><FormattedMessage id="tournament.casters.modal.add"/></ModalHeader>
            <ModalBody>
              <div>
                <label><FormattedMessage id="tournament.casters.modal.description"/></label>
                <textarea ref={elem => this.elem = elem} className="form-control modal-textarea"/>
                {this.state.error && <div className="text-danger font-weight-bold mt-2">{this.state.error}</div>}
              </div>
            </ModalBody>
            <ModalFooter>
              <button onClick={this.submit} className="btn btn-success"><FormattedMessage id="tournaments.btn.add"/></button>
              <button onClick={this.toggleModal} className="btn btn-outline-secondary"><FormattedMessage id="tournaments.btn.cancel"/></button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.isConfirmOpen} toggle={()=>this.toggleConfirmModal(null)}>
            <ModalHeader toggle={()=>this.toggleConfirmModal(null)}><FormattedMessage id="tournament.casters.modal.delete.title"/></ModalHeader>
            <ModalBody>
              <FormattedMessage id="tournament.casters.modal.delete.text"/>
            </ModalBody>
            <ModalFooter>
              <button className="btn btn-danger" onClick={this.submitDelete}><FormattedMessage id="tournament.delete.modal.submit"/></button>
              <button className="btn btn-outline-secondary" onClick={()=>this.toggleConfirmModal(null)}><FormattedMessage id="tournament.delete.modal.cancel"/></button>
            </ModalFooter>
          </Modal>
      </div>
    );
  }
}

Casters.propTypes = {
  getTournamentCasters: PropTypes.func,
  tournamentMembers: PropTypes.object,
  tournamentId: PropTypes.string,
  addTournamentCaster: PropTypes.func,
  deleteTournamentCaster: PropTypes.func,
  addNotification: PropTypes.func,
};

export default connect(
  (state) => ({
    tournamentMembers: state.tournamentMembers,
  }),
  ({
    getTournamentCasters,
    addTournamentCaster,
    deleteTournamentCaster,
    addNotification,
  })
)(Casters);
