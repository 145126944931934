import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import MetricBox from '../MetricBox';
import Spinner from '../../../Spinner';

import cardImg from '../../../../assets/images/metrics/card.png';

import './PopularCards.scss';

class PopularCards extends React.Component {
  static defaultProps = {
    isLoading: false,
  };

  state = {
    filterDate: {
      since: '',
      until: ''
    }
  };

  static getDerivedStateFromProps(props) {
    const {filterDate} = props;

    return {
      filterDate: {
        since: filterDate.since ? moment.unix(filterDate.since) : '',
        until: filterDate.until ? moment.unix(filterDate.until) : '',
      }
    };
  }

  changeFilterDateSince = (date) => {
    if(!date) {
      return;
    }

    const timestamp = Number(date.startOf('day').format('X'));

    this.changeFilterDate('since', timestamp);
  };

  changeFilterDateUntil = (date) => {
    if(!date) {
      return;
    }

    const timestamp = Number(date.endOf('day').format('X'));

    this.changeFilterDate('until', timestamp);
  };

  changeFilterDate = (type, timestamp) => {
    this.props.onChangeFilterDate({
      [type]: timestamp,
    });
  };

  renderContent() {
    const {data, isLoading} = this.props;

    if(isLoading){
      return this.renderLoading();
    }
    if(data.length > 0){
      return this.renderData();
    }

    return this.renderNoData();
  }

  renderLoading() {
    return (
      <div className="no-data-wrapper">
        <Spinner size={80}/>
      </div>
    );
  }

  renderNoData() {
    return (
      <div className="no-data-wrapper">
        <p>No data</p>
      </div>
    );
  }

  renderData() {
    const {data} = this.props;

    return (
      <ul className={'ul-col'}>
        {data.map(item => {
          return <li key={item.cardGroup} title={item.names}>{item.amount} {item.names}</li>;
        })}
      </ul>
    );
  }

  render() {
    const {formatOptions, format, onChangeFormat, isLoading} = this.props;
    const {filterDate} = this.state;

    return (
      <MetricBox bgImage={cardImg} className={'popular-cards'}>
        <div className="header-select">
          <h4><FormattedMessage id={'metrics.title.formatCards'}/></h4>

          <div className={'dates'}>
            <DatePicker
              className="form-control"
              selected={moment(filterDate.since)}
              disabled={isLoading}
              onChange={this.changeFilterDateSince}/>

            <DatePicker
              className="form-control"
              selected={moment(filterDate.until)}
              disabled={isLoading}
              onChange={this.changeFilterDateUntil}/>
          </div>

          <Select
            options={formatOptions}
            value={format}
            onChange={onChangeFormat}
            searchable={false}
            clearable={false}
          />
        </div>

        {this.renderContent()}
      </MetricBox>
    );
  }
}

PopularCards.propTypes = {
  format: PropTypes.number.isRequired,
  formatOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    names: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    cardGroup: PropTypes.string.isRequired,
  })),
  onChangeFormat: PropTypes.func,
  onChangeFilterDate: PropTypes.func,
  filterDate: PropTypes.shape({
    since: PropTypes.number,
    until: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
};

export default PopularCards;
