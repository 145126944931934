import {TOURNAMENT_TEAMS_STANDINGS} from '../../actions/types';
import update from 'immutability-helper';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_TEAMS_STANDINGS.GET.SUCCESS:
      return action.payload.extras;

    case TOURNAMENT_TEAMS_STANDINGS.UPDATE.SUCCESS: {
        const {extras: updateExtras} = action.payload;
  
        return update(state, {
          $merge: updateExtras ? updateExtras : {}
        });
      }
    default:
      return state;
  }
};
