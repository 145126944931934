import * as React from 'react';
import PropTypes, { object } from 'prop-types';
import {connect} from 'react-redux';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Select from 'react-select';

import {joinTournament, joinTournamentModalAction} from '../../../actions/tournamentActions';
import TournamentListPlayerTable from './TournamentListPlayerTable';
import {fetch} from '../../../actions/deckActions';
import { FormattedMessage, injectIntl } from 'react-intl';
import Tournament from '../../../services/Tournament';
import {getTournamentsPlayer} from '../../../actions/tournamentActions';
import GenericTooltip from '../../GenericTooltip';
import ModalSelectTournamentPlayerBio from '../ModalSelectTournamentPlayerBio';
import { getErrorMessage } from '../../../services/SubmitDeck';

import registerSuccessImg from '../../../assets/images/register-success.png';
import './TournamentList.scss';

class TournamentListPlayer extends React.Component {
  state = {
    passCode: '',
    isOpen: false,
    selectedDeck: null,
    error: null,
    modalSelectBio: {
      isOpen: false,
      data: null,
    },
  };

  componentDidMount() {
    this.props.fetch();
  }

  handleChangePasscode = (e) => {
    this.setState({
      passCode: e.target.value,
    });
  }

  joinTournament = (e) => {
    e.preventDefault();

    const {passCode} = this.state;

    this.props.joinTournament(passCode);
  }

  selectDeck = (value) => {
    this.setState({
      selectedDeck: value
    });
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      error: null,
    });
  }

  submit = () => {
    const {selectedDeck} = this.state;
    const {joinTournamentModal, intl} = this.props;

    const {tournamentId, playerId} = joinTournamentModal;

    Tournament.registerDeck(tournamentId, selectedDeck)
      .then(() => {
        this.props.getTournamentsPlayer(1);
        this.toggleModal();
        this.openModalSelectBio({
          tournamentId: tournamentId,
          playerId: playerId,
        });
      })
      .catch(err => {
        const key = getErrorMessage(err.response);

        this.setState({
          error: intl.messages[key] || err.response.data.error_description
        });
      });
  }

  openSubmitModal = () => {
    const data = this.props.joinTournamentModal;

    this.props.joinTournamentModalAction(false, data.tournamentId, data.playerId);
    this.toggleModal();
  }

  selectDeck = (selected) => {
    this.setState({
      selectedDeck: selected.value
    });
  }

  renderDeckOption(option) {
    return (
      <div>
        {option.label} <small>{option.format}</small>

        {
          !option.valid &&
          <div className="float-right">
            <GenericTooltip id={`option-${option.value}`} placement={'left'} message={'This deck is not valid'}/>
          </div>
        }
      </div>
    );
  }

  renderDeckValue(option) {
    return (
      <div>
        {option.label} <small>{option.format}</small>

        {
          !option.valid &&
          <div className="float-right" style={{paddingRight: 20}}>
            <GenericTooltip id={`value-${option.value}`} placement={'left'} message={'This deck is not valid'}/>
          </div>
        }
      </div>
    );
  }

  closeModalSelectBio = () => {
    this.setState({
      modalSelectBio: {
        isOpen: false,
        data: null,
      }
    });
  };

  openModalSelectBio = ({tournamentId, playerId}) => {
    this.setState({
      modalSelectBio: {
        isOpen: true,
        data: {
          tournamentId,
          playerId,
        },
      }
    });
  };

  render() {
    const {passCode, isOpen, selectedDeck, error, modalSelectBio} = this.state;
    const {joinTournamentModal, decks} = this.props;

    const deckList = decks && decks.data && decks.data.map(item => {
      const format = item.format && item.format.name;

      return {
        label: item.name,
        value: item.id,
        valid: item.valid,
        format,
      };
    });

    return (
        <div>
          <h4 className="section-header mb-5"><FormattedMessage id="tournaments.header.your_tournaments"/></h4>
          <div className="row">
            <div className="mb-3 col-md-8">
              <form onSubmit={this.joinTournament}>
                <div className="row">
                  <div className="col-md-7">
                    <input type="text"
                           className="form-control mb-3"
                           placeholder="Tournament passcode"
                           value={passCode}
                           autoComplete="nope" autofill="off" spellCheck="false" autoCorrect="off" autoCapitalize="off"
                           onChange={this.handleChangePasscode}/>
                  </div>
                  <div className="col-md-5">
                    <button className="btn btn-form btn-success btn-block" disabled={!passCode} type="submit"><FormattedMessage id="tournament.join"/></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <TournamentListPlayerTable history={this.props.history} currentPage={this.props.currentPage}/>
          <Modal
            isOpen={joinTournamentModal && joinTournamentModal.isOpen}
            toggle={() => this.props.joinTournamentModalAction(false)}
          >
            <ModalBody>
              <div className="text-center pt-2">
                <img src={registerSuccessImg} alt="success" />
                <h4 className="tournament-modal-title">You have successfuly joined the tournament</h4>
                <p className="tournament-modal-text">Now please submit your deck</p>
              </div>

            </ModalBody>
            <ModalFooter>
              <button className="btn btn-outline-secondary" onClick={() => this.props.joinTournamentModalAction(false)}>Later</button>
              <button className="btn btn-success" onClick={this.openSubmitModal}>Submit deck</button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={isOpen} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>Select Deck</ModalHeader>
            <ModalBody>
              {/* TODO: Use <DeckSelect/> component */}
              <Select
                id="state-select"
                options={deckList}
                optionRenderer={this.renderDeckOption}
                valueRenderer={this.renderDeckValue}
                value={selectedDeck}
                onChange={this.selectDeck}
                searchable={false}
                clearable={false}
              />
              <div className="text-danger mt-2">{error}</div>
            </ModalBody>
            <ModalFooter>
              <button onClick={this.toggleModal} className="btn btn-outline-secondary">Cancel</button>
              <button disabled={!selectedDeck} onClick={this.submit} className="btn btn-success">Select</button>
            </ModalFooter>
          </Modal>

          {
            modalSelectBio.isOpen &&
            <ModalSelectTournamentPlayerBio isOpen={modalSelectBio.isOpen}
                                            tournamentId={modalSelectBio.data.tournamentId}
                                            playerId={modalSelectBio.data.playerId}
                                            onClose={this.closeModalSelectBio}/>
          }
        </div>
    );
  }
}

TournamentListPlayer.propTypes = {
  history: PropTypes.object,
  joinTournament: PropTypes.func.isRequired,
  joinTournamentModalAction: PropTypes.func,
  joinTournamentModal: object,
  decks: PropTypes.object,
  fetch: PropTypes.func,
  getTournamentsPlayer: PropTypes.func.isRequired,
  currentPage: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(connect(
  (state) => ({
    decks: state.decks,
    joinTournamentModal: state.joinTournamentModal
  }),
  ({
    joinTournament,
    joinTournamentModalAction,
    fetch,
    getTournamentsPlayer
  })
)(TournamentListPlayer));
