import {SELECT_TOURNAMENT_PLAYER_BIO} from './types';

export const getSchema = ({tournamentId, playerId}) => ({
  type: SELECT_TOURNAMENT_PLAYER_BIO.GET_SCHEMA.START,
  payload: {
    tournamentId,
    playerId,
  }
});

export const getSchemaSuccess = (data) => ({
  type: SELECT_TOURNAMENT_PLAYER_BIO.GET_SCHEMA.SUCCESS,
  payload: data,
});

export const getSchemaError = (data) => ({
  type: SELECT_TOURNAMENT_PLAYER_BIO.GET_SCHEMA.ERROR,
  payload: data,
});

export const save = ({bioData, bioAvatar, onClose}) => ({
  type: SELECT_TOURNAMENT_PLAYER_BIO.SAVE.START,
  payload: {
    bioData,
    bioAvatar,
    onClose
  }
});

export const saveSuccess = (data) => ({
  type: SELECT_TOURNAMENT_PLAYER_BIO.SAVE.SUCCESS,
  payload: data,
});

export const saveError = (data) => ({
  type: SELECT_TOURNAMENT_PLAYER_BIO.SAVE.ERROR,
  payload: data,
});

export const clear = () => ({
  type: SELECT_TOURNAMENT_PLAYER_BIO.CLEAR,
});
