import {createLogic} from 'redux-logic';

import {SUBMIT_DECK_FOR_TOURNAMENT} from '../../actions/types';
import {registerDeckSuccess, registerDeckError} from '../../actions/submitDeckForTournament';
import {add as addNotification} from '../../actions/notificationActions';

export const register = createLogic({
  type: SUBMIT_DECK_FOR_TOURNAMENT.REGISTER_DECK.START,

  latest: true,

  process({httpClient, action}, dispatch, done) {
    const {deckId, tournamentId, onSuccess} = action.payload;

    return httpClient.put(`/api/v1/tournaments/${tournamentId}/drafts/${deckId}`)
      .then(res => res.data)
      .then(data => {
        dispatch(registerDeckSuccess(data));

        dispatch(addNotification({
          type: 'success',
          intlKey: 'submitDeckModal.notification.success'
        }));

        if(onSuccess){
          onSuccess();
        }
      })
      .catch(err => {
        dispatch(registerDeckError(err));
      })
      .then(done);
  }
});

export default [
  register,
];
