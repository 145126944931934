import {
  PUBLIC_DECK,
  PUBLIC_CARDS,
  SHORTENER_URL,
  PUBLIC_ARENA_FORMAT,
  RECENT_DECKS,
  STREAMERS_DECKS,
  STREAMERS_DECKS_LOAD_MORE,
  STREAMERS_DECKS_DELETE,
  STREAMERS_INFO,
  STREAMERS_DECKS_EDIT_NAME
} from './types';

export const getPublicDecks = (deckId) => ({
  type: PUBLIC_DECK.FETCH,
  id: deckId
});

export const getPublicCards = (deckId) => ({
  type: PUBLIC_CARDS.FETCH,
  id: deckId
});

export const getShortenerUrl = (deckId, params) => ({
  type: SHORTENER_URL.START,
  payload: {
    id: deckId,
    params,
  }
});

export const getPublicArenaFormat = (deckId) => ({
  type: PUBLIC_ARENA_FORMAT.START,
  payload: {
    deckId,
  }
});

export const getRecentDecks = (channelId) => ({
  type: RECENT_DECKS.START,
  payload: {
    channelId: channelId,
  }
});

export const getStreamerDecks = (channelId, deckId, page) => ({
  type: STREAMERS_DECKS.START,
  payload: {
    channelId: channelId,
    page: page,
    deckId: deckId
  }
});

export const deleteStreamerDeck = (deckId) => ({
  type: STREAMERS_DECKS_DELETE.START,
  payload: {
    deckId: deckId,
  }
});

export const editStreamerDeck = (deckId, displayName) => ({
  type: STREAMERS_DECKS_EDIT_NAME.START,
  payload: {
    deckId: deckId,
    displayName: displayName,
  }
});

export const loadMoreStreamerDecks = (channelId, page) => ({
  type: STREAMERS_DECKS_LOAD_MORE.START,
  payload: {
    channelId: channelId,
    page: page,
  }
});

export const getStreamersInfo = (channelValue) => ({
  type: STREAMERS_INFO.START,
  payload: {
    channelValue: channelValue,
  }
});
