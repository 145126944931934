import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { roles } from '../const';
import { FormattedMessage } from 'react-intl';
import AccessControl from '../Utils/AccessControl';
import StreamersList from './StreamersList';
import {getStreamersList, deleteStreamer} from './../../actions/streamersActions';
import Spinner from '../Spinner';

import * as ModalActions from '../../actions/modal';
import modalNames from '../modals/names';

class StreamersContainer extends React.Component {
  componentDidMount () {
    this.props.getStreamersList();
  }

  openDeleteConfirmationModal = (e, deleteId) => {
    e.stopPropagation();

    const onSubmit = this.submitDelete;

    this.props.openModal(modalNames.DELETE_CONFIRMATION, {
      deleteId,
      onSubmit
    });
  }

  openNewStreamerModal = (e) => {
    e.stopPropagation();

    this.props.openModal(modalNames.ADD_NEW_STREAMER);
  }

  submitDelete = (id) => {
    const {deleteStreamer} = this.props;

    deleteStreamer(id);
  }

  renderStreamersList = () => {
    const {streamers} = this.props;

    if(streamers.isLoading) {
      return (
        <Spinner/>
      );
    }

    return (
      <StreamersList streamers={streamers.data} openDeleteConfirmationModal={this.openDeleteConfirmationModal} />
    );
  }

  render() {
    return (
      <AccessControl roles={[roles.admin]} forbiddenRedirect={'/'}>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-6">
              <h4 className="section-header">
                <FormattedMessage id="streamers.title"/>
              </h4>
            </div>
            <div className="col-md-6">
              <div className="d-md-flex justify-content-end mt-3 mt-md-0">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.openNewStreamerModal }
                >
                  <FormattedMessage id="deck.name.add"/>
                </button>
              </div>
            </div>
          </div>

          {this.renderStreamersList()}
         
        </div>
      </AccessControl>
    );
  }
}

StreamersContainer.propTypes = {
  openModal: PropTypes.func.isRequired,
  getStreamersList: PropTypes.func.isRequired,
  deleteStreamer: PropTypes.func.isRequired,
  streamers: PropTypes.object
};

export default connect(
  (state) => ({
    streamers: state.streamers
  }),
  ({
    openModal: ModalActions.open,
    getStreamersList,
    deleteStreamer
  })
)(StreamersContainer);
