import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';

import TournamentUserDeleteModal from './TournamentUserDeleteModal';
import CreateDeckList from '../../../pages/CreateDeckListContainer';
import TournamentUserEditUserInfo from './TournamentUserEditUserInfo';
import TournamentBiographyContainer from './TournamentBiographyContainer';
import Spinner from '../../../Spinner';

const DECK_TYPE = {
  NONE: '1',
  DECK: '2',
  PAPERDECK: '3',
};

class TournamentUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenDeleteModal: false,
      deckType: this.getDeckType(props.data),
      biography: props.data.biography || props.data.user.biography || '',
      userData: {
        firstName: props.data && props.data.user && props.data.user.firstName,
        lastName: props.data && props.data.user && props.data.user.lastName,
        dciNumber: props.data && props.data.user && props.data.user.dciNumber,
      },
      isBiographyTemplateOpen: false
    };
  }

  componentDidMount() {
    const {deckType} = this.state;
    const {data, onCreateDeck} = this.props;

    if(deckType === DECK_TYPE.DECK) {
      onCreateDeck(data.deck ? data.deck.id : null);
    }
  }

  getDeckType(data) {
    const {paperdeck, deck} = data;

    if(paperdeck) {
      return DECK_TYPE.PAPERDECK;
    }

    if(!deck || !deck.id) {
      return DECK_TYPE.NONE;
    }

    return DECK_TYPE.DECK;
  }

  openDeleteModal = () => {
    this.setState({
      isOpenDeleteModal: true,
    });
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      isOpenDeleteModal: !prevState.isOpenDeleteModal,
    }));
  };

  changeDeckType = (e) => {
    const {data, onCreateDeck} = this.props;
    const type = e.target.id;

    this.setState({
      deckType: type,
    });

    if(type === DECK_TYPE.DECK) {
      onCreateDeck(data.deck ? data.deck.id : null);
    }
  };

  saveDeckType = (isPaperdeck) => {
    const {data, onSaveDeckType, updateShadowUser, tournamentId, userId} = this.props;
    const {biography, userData} = this.state;

    onSaveDeckType(data, isPaperdeck, biography);
    updateShadowUser(tournamentId, userId, userData);
  };

  renderDeck() {
    const {deckType} = this.state;
    const {draft} = this.props;

    if(deckType === DECK_TYPE.PAPERDECK) {
      return (
        <div className="d-flex justify-content-center mobile-btn-wrapper">
          <button type="button"
                  className="btn btn-secondary mr-2 d-flex flex-grow-1 flex-lg-grow-0 justify-content-center"
                  onClick={() => this.props.history.goBack()}>
            <span>Cancel</span>
          </button>

          <button type="submit"
                  className="btn btn-success btn-lg flex-grow-1 flex-lg-grow-0"
                  onClick={() => this.saveDeckType(true)}>
            <span>Save</span>
          </button>
        </div>
      );
    }

    if(draft.isLoad) {
      return <Spinner/>;
    }

    if(draft.data) {
      return (
        <CreateDeckList id={`${draft.data.id}`}
                        organizer
                        disableFormat
                        onSave={() => this.saveDeckType(false)}
                      />
      );
    }

    return null;
  }

  onBiographyChange = (biography) => {
    this.setState({ biography });
  }

  toggleBiographyView(isOpen) {
    this.setState({
      isBiographyTemplateOpen: isOpen
    });
  }

  renderUserData(data) {
    return (
      <div className="user-data mb-3">
        <div className="row">
          <div className="col-6 col-md-4">
            <label className="text-uppercase"><FormattedMessage id="user.label.last.name"/></label>
            <input type="text"
                   className="form-control user-disabled user-name"
                   disabled
                   defaultValue={data.user.lastName}
            />
          </div>
          <div className="col-6 col-md-4">
            <label className="text-uppercase"><FormattedMessage id="user.label.first.name"/></label>
            <input type="text"
                   className="form-control user-disabled user-name"
                   disabled
                   defaultValue={data.user.firstName}
            />
          </div>
          <div className="col-6 col-md-4">
            <label className="text-uppercase"><FormattedMessage id="user.label.dci"/></label>
            <input type="text"
                   className="form-control user-disabled user-dci"
                   disabled
                   defaultValue={data.user.dciNumber}
            />
          </div>
        </div>
      </div>
    );
  }

  changeUserData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      userData: {
        ...prevState.userData,
        [name]: value
      }
    }));
  };

  render() {
    const {tournamentId, data, onDeleteUser} = this.props;
    const {isOpenDeleteModal, deckType, isBiographyTemplateOpen} = this.state;
    const genuine = data && data.user && data.user.genuine;
    const renderUserData = genuine
      ? this.renderUserData(data)
      : <TournamentUserEditUserInfo userData={this.state.userData} changeUserData={this.changeUserData} />;

    if(!data) {
      return null;
    }

    if(isBiographyTemplateOpen) {
      return(
        <div className="container container-user mt-5 mb-5 pb-2">
          <TournamentBiographyContainer
            redirect={() => this.toggleBiographyView(false)}
            userId={this.props.data.user.id}
            membershipId={this.props.data.id}
          />
        </div>
      );
    }

    return (
      <div className="container container-user mt-5 mb-5 pb-2">
        <div className="user-page-options">
          <Link to={`/tournaments/show/${tournamentId}/users`} className="back-link">
            {'< Tournament'}
          </Link>

          <button className="btn btn-danger ml-2" onClick={this.openDeleteModal}>
            <i className="icon-delete"/>
          </button>

          <TournamentUserDeleteModal
            isOpen={isOpenDeleteModal}
            toggle={this.toggleDeleteModal}
            onSubmit={onDeleteUser}
          />
        </div>

        {renderUserData}

        <div className="text-right">
          <span className="btn btn-primary" onClick={() => this.toggleBiographyView(true)}><FormattedMessage id="biography.text"/></span>
        </div>

        <div className="col-12">
          <h4 className="section-header section-header--secondary mb-3 mt-5">Deck type</h4>
          <div className="user-edit-role">
            <div className="form-group form-check">
              <input type="checkbox"
                     className="form-check-input"
                     id={DECK_TYPE.DECK}
                     checked={deckType === DECK_TYPE.DECK}
                     onChange={this.changeDeckType}/>
              <label className="form-check-label" htmlFor={DECK_TYPE.DECK}>Digital deck</label>
            </div>
            <div className="form-group form-check">
              <input type="checkbox"
                     className="form-check-input"
                     id={DECK_TYPE.PAPERDECK}
                     checked={deckType === DECK_TYPE.PAPERDECK}
                     onChange={this.changeDeckType}/>
              <label className="form-check-label" htmlFor={DECK_TYPE.PAPERDECK}>Paper deck</label>
            </div>
          </div>
        </div>

        {this.renderDeck()}
      </div>
    );
  }
}

TournamentUser.propTypes = {
  tournamentId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    biography: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      dciNumber: PropTypes.string.isRequired,
      biography: PropTypes.string,
    }),
    paperdeck: PropTypes.bool.isRequired,
  }).isRequired,
  draft: PropTypes.shape({
    isLoad: PropTypes.bool.isRequired,
    data: PropTypes.object,
  }).isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  onSaveDeckType: PropTypes.func.isRequired,
  onCreateDeck: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  updateShadowUser: PropTypes.func,
};

export default withRouter(TournamentUser);
