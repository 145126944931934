import { createLogic } from 'redux-logic';
import { CHANGE_PASSWORD } from '../actions/types';

export const all = createLogic({
  type: /^DECKS_.*$/,
  process({ action }) {
    /* eslint-disable no-console */
    console.log(action);
    /* eslint-enable no-console */
  }
});

export const put = createLogic({
  type: CHANGE_PASSWORD.START,

  processOptions: {
    successType: CHANGE_PASSWORD.SUCCESS,
    failType: CHANGE_PASSWORD.ERROR,
  },

  process({ clientContextHttpClient, action }) {

    return clientContextHttpClient.put(`/api/v1/password_reset/${action.payload.token}`, { plainPassword: action.payload.password})
      .then(res => res.data);
  }
});

export default {
  all,
  put
};
