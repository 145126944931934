import React from 'react';
import PropTypes from 'prop-types';

import CreateDeckListContainer from '../../../pages/CreateDeckListContainer';

class CreateDeck extends React.Component {
  render() {
    const {deck, onSave, supportCustomCards} = this.props;

    return (
      <div>
        <CreateDeckListContainer id={deck.id} disableFormat unregistered onSave={onSave} supportCustomCards={supportCustomCards}/>
      </div>
    );
  }
}

CreateDeck.propTypes = {
  tournament: PropTypes.object.isRequired,
  player: PropTypes.object.isRequired,
  deck: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  supportCustomCards: PropTypes.bool
};

export default CreateDeck;
