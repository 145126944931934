import React from 'react';
import ModalConnector from '../Generic/Modals/ModalConnector';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import names from './names';

import './Howto.scss';

import exampleXML from '../../assets/sampleFiles/standings.xml';
import exampleHTML from '../../assets/sampleFiles/standings.html';

class HowToStandings extends React.Component {
  render() {
    return (
      <ModalConnector name={names.HOW_TO_IMPORT_STANDINGS}>
        {
          ({isOpen, onClose}) => {
            return (
              <Modal isOpen={isOpen} toggle={onClose} size="lg" centered>
                <ModalHeader toggle={onClose}>
                  <FormattedMessage id="modal.how.to.standings.header" />
                </ModalHeader>
                <ModalBody>
                  <ul className="howto-list">
                    <li>
                      <FormattedMessage id="modal.how.to.standings.p1" />
                      <ul className="howto-nested-list">
                        <li className="howto-list-elem">
                          <span>
                            <span className="howto-nested-list-order">a.</span>
                            <a href={exampleHTML} download="rd1-standings_sample_as_html.html">
                              <FormattedHTMLMessage id="modal.how.to.standings.p2a" />
                            </a>
                          </span>
                          <p>
                            <FormattedMessage id="modal.how.to.standings.p2" />
                          </p>
                        </li>
                        <li>
                          <span>
                            <span className="howto-nested-list-order">b.</span>
                            <a href={exampleXML} download="standings_sample_as_xml.xml">
                              <FormattedHTMLMessage id="modal.how.to.standings.p3a" />
                            </a>
                          </span>
                          <p>
                            <FormattedMessage id="modal.how.to.standings.p3" />
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li className="howto-list-elem">
                      <FormattedMessage id="modal.how.to.standings.p4" />
                    </li>
                    <li className="howto-list-elem">
                      <FormattedMessage id="modal.how.to.standings.p5" />
                    </li>
                  </ul>
                </ModalBody>
                <ModalFooter>
                  <button className="btn btn-success" onClick={onClose}>
                    <FormattedMessage id="modal.how.to.standings.btn" />
                  </button>
                </ModalFooter>
              </Modal>
            );
          }
        }
      </ModalConnector>
    );
  }
}

export default HowToStandings;
