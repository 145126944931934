import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import DataTableWithMobileDropdown from '../../../DataTable/DataTableWithMobileDropdown';
import ChangeableDropdownColor from '../../../Generic/ChangeableDropdown/ChangeableDropdownColor';
import GenericTooltip from '../../../GenericTooltip';
import PrintTournamentPlayer from '../../PrintTournamentPlayer/PrintTournamentPlayer';

import { getPlayerDeckFormat } from '../../../../services/DeckFormat';

import './TournamentTeamTable.scss';

const DECK_STATE_ONLINE = 'online';

class TournamentTeamTable extends React.Component {
  onEdit = (teamData) => {
    this.props.onClickEdit(teamData);
  };

  getDropDownData = (dataRow, rowIndex) => {
    const {data, tournamentId, onEditDeckClick} = this.props;

    const teamIndex   = Math.floor(rowIndex / 3);
    const playerIndex = rowIndex % 3;

    const userData = data[teamIndex].players[playerIndex];
    const id = userData.player.user.id;
    const isOnline = userData.player.deckState === DECK_STATE_ONLINE;

    const buttons = [
      <div key={`${id}-edit`} className="btn-icon">
        <FormattedMessage id="tournament.players.mobile.edit"/> <i className="icon-manage-account"/>
      </div>,

      <PrintTournamentPlayer
        key={`${id}-print`}
        tournamentId={tournamentId}
        userId={id}
        isOnline={isOnline}
        tag="div"
        renderContent={() => (
          <React.Fragment>
            <FormattedMessage id="tournament.players.mobile.print"/> <i className="icon-print"/>
          </React.Fragment>
        )}
      />
    ];

    if (userData.player.deckState === DECK_STATE_ONLINE) {
      buttons.unshift(
        <div key={`${id}-deck`} onClick={() => onEditDeckClick(id)} className="btn-icon">
          <FormattedMessage id="tournament.players.mobile.edit.deck"/>  <i className="icon-decklist"/>
        </div>
      );
    }

    return buttons;
  };

  getColumns() {
    return [
      {name: 'Edit', flex: 2, className: 'col-edit'},
      {name: 'Last Name', flex: 2},
      {name: 'First Name', flex: 2},
      {name: 'DCI', flex: 2},
      {name: 'Bio', flex: 1, className: 'col-bio'},
      {name: 'Format', flex: 2},
      {name: 'Deck', flex: 3, className: 'col-deck'},
    ];
  }

  getTableData() {
    const {data} = this.props;

    const deckFormats = getPlayerDeckFormat(data[0].players.map(elem => elem.format.name));

    return data.reduce((prev, current) => {
      return [
        ...prev,
        ...current.players.map((playerData, index) => this.getTableDataRow(playerData, current, deckFormats, index)),
      ];
    }, []);
  }

  getTableDataRow(playerData, teamData, deckFormats, index) {
    const dciNumber = playerData.player.dciNumber;

    return [
      this.renderEditField(playerData.player, index, teamData),
      playerData.player.lastName,
      playerData.player.firstName,
      dciNumber ? dciNumber : null,
      this.renderBioField(playerData.player),
      deckFormats[index],
      this.renderDeckField(playerData.player, teamData.id),
    ];
  }

  renderEditField(userData, index, teamData) {
    const isButton = index === 1;
    const isOnline = userData.deckState === DECK_STATE_ONLINE;
    const isTooltip = userData.deck && !userData.deck.valid && isOnline;

    const button = (
      <button type="button" className="btn btn-primary btn-edit btn-block" onClick={() => this.onEdit(teamData)}>
        <span>Edit</span>
      </button>
    );
    const tooltip = (
      <GenericTooltip id={`imported-${userData.id}`} placement={'left'} message={'Deck invalid'}/>
    );

    return (
      <React.Fragment>
        {isTooltip && tooltip}
        {isButton && button}
      </React.Fragment>
    );
  }

  renderBioField(userData) {
    const { onClickBio } = this.props;
    return (
      <React.Fragment>
        {userData.hasBio && <i className="icon-check"/>}

        <i className={classnames('icon-edit',{
          'icon-edit--margin': userData.hasBio,
        })} onClick={() => onClickBio({
          userId: userData.user.id,
          tournamentId: userData.tournament.id,
          playerId: userData.id,
        })}/>
      </React.Fragment>
    );
  }

  renderDeckField(userData, teamId) {
    const { onChangeDeckState, onEditDeckClick } = this.props;
    const options = [
      {value: 'paperdeck', name: 'Paper only', color: 'blue'},
      {value: 'online', name: 'Online', color: 'primary'},
      {value: 'none', name: 'None', color: 'gray'},
    ];

    const isNotValid = !userData.deck || (userData.deck && !userData.deck.valid);
    const isOnline = userData.deckState === DECK_STATE_ONLINE;

    const isShowInvalidIndicator = isNotValid && isOnline;

    return (
      <div className={classnames('deck-field-wrapper', {
        valid: !isShowInvalidIndicator
      })}>
        {
          isShowInvalidIndicator &&
          <GenericTooltip id={`deck-${userData.id}`} placement={'left'} message={'Deck invalid'}/>
        }

        <ChangeableDropdownColor options={options}
                                 renderCurrentValue
                                 value={userData.deckState}
                                 onChange={(value) => onChangeDeckState({playerId: userData.id, userId: userData.user.id, teamId: teamId}, userData.deckState, value)} />

        <React.Fragment>
          <button className="btn-icon" disabled={!isOnline} onClick={() => onEditDeckClick(userData.user.id)}>
            <i className="icon-edit" />
          </button>
          {this.renderPrintButton(userData, isOnline)}
        </React.Fragment>
      </div>
    );
  }

  renderPrintButton = (userData, isOnline) => {
    const {tournamentId} = this.props;

    return (
      <PrintTournamentPlayer tournamentId={tournamentId} userId={userData.user.id} isOnline={isOnline} />
    );
  };

  render() {
    const columns = this.getColumns();
    const data    = this.getTableData();

    return (
      <div className="tournament-team-table">
        <DataTableWithMobileDropdown columns={columns} data={data} dropdownData={this.getDropDownData}/>
      </div>
    );
  }
}

TournamentTeamTable.propTypes = {
  tournamentId: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickBio: PropTypes.func.isRequired,
  onChangeDeckState: PropTypes.func.isRequired,
  onEditDeckClick: PropTypes.func.isRequired,
};

export default TournamentTeamTable;
