import {
  GET_CURRENT_USER,
  SET_USER_ROLE,
} from '../../actions/types';

const initialState = {
  isLoad: false,
  isError: false,
  error: null,
  success: false,
  data: null,
  userRole: '',
};

export default (state = initialState, action) => {
  switch(action.type) {
    case GET_CURRENT_USER.START:
      return {
        ...state,
        isLoad: true,
        isError: false,
        error: null,
      };
    case GET_CURRENT_USER.SUCCESS:
      return {
        ...state,
        isLoad: false,
        success: true,
        data: action.payload,
        isError: false,
        error: null,
      };
    case GET_CURRENT_USER.ERROR:
      return {
        ...state,
        isLoad: false,
        isError: true,
        error: action.payload,
      };
    case SET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload
      };
    case GET_CURRENT_USER.CANCEL_CURRENT:
      return initialState;
    default:
      return state;
  }
};
