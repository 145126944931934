import React, {Fragment} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Link, NavLink as RouterNavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import ChannelLive from '../ChannelLive/ChannelLive';

import {getGravatarLink} from '../../services/gravatar';

import AccessControl from '../Utils/AccessControl';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import {navElementsLogged} from './Sidebar';

import logo from '../../assets/images/logo.svg';
import './TopBar.scss';

class TopBar extends React.Component {
  static defaultProps = {
    mobileBreakpoint: 'lg'
  };

  state = {isOpen: false, isRolesOpen: false};

  UNSAFE_componentWillMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleOutsideClick = (e) => {
    const {isMobile} = this.props;
    const node = isMobile ? this.topBarNode : this.userNode;

    if (!node) {
      return;
    }

    if (node.contains(e.target)) {
      return;
    }

    if (!isMobile) {
      return this.state.isRolesOpen ? this.setState({isRolesOpen: false}) : null;
    }

    this.state.isOpen ? this.close() : null;
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    }, this.updateBodyClassName);
  }

  close = () => {
    this.setState({
      isOpen: false,
    }, this.updateBodyClassName);
  }

  updateBodyClassName = () => {
    const {isOpen} = this.state;
    const elem = document.body;

    if (isOpen) {
      elem.classList.add('mobile-navbar-show');
    }
    else {
      elem.classList.remove('mobile-navbar-show');
    }
  }

  renderRegisterBtn() {
    return (
      <Link to="/register" className="btn btn-primary topbar__btn mr-3 mr-lg-0"><FormattedMessage id="top.bar.register"/></Link>
    );
  }

  renderUserRoles(data) {
    if (!data || !data.roles) {
      return;
    }

    const filteredUserRoles = data.roles
      .filter(role => role !== 'USER')
      .filter(role => role !== this.props.userRole);

    return filteredUserRoles.map((role, index) => {
      return <li
        key={index}
        className="topbar__user-nav-item"
        onClick={() => {
          this.props.setUserRole(role);
          this.close();
        }}
      >
        {role}
      </li>;
    });
  }

  toggleRolesNav = () => {
    const {isMobile} = this.props;

    if (isMobile) {
      return;
    }

    this.setState(prevState => ({isRolesOpen: !prevState.isRolesOpen}));
  }

  onLogout = () => {
    document.body.classList.remove('mobile-navbar-show');
    this.props.logout();
  }

  renderUser() {
    const {userData, userRole} = this.props;
    const {isRolesOpen} = this.state;
    const userInnerClass = isRolesOpen ? 'topbar__user-inner topbar__user-inner--active' : 'topbar__user-inner';
    const userNavClass = isRolesOpen ? 'topbar__user-nav topbar__user-nav--open' : 'topbar__user-nav';

    return (
      <div ref={node => this.userNode = node} className={userInnerClass} onClick={this.toggleRolesNav}>
        <div className="topbar__mobile-divider d-lg-none d-xl-none"></div>
        <div className="topbar__user-details">
          <img className="topbar__gravatar" src={getGravatarLink(userData.email)} alt="gravatar"/>
          <div className="topbar__user-text">
            <p className="topbar__user-name">{userData.firstName} {userData.lastName}</p>
            <p className="topbar__user-status">{userRole}</p>
          </div>
        </div>
        <ul className={userNavClass}>
          {this.renderUserRoles(this.props.userData)}
          <li className="topbar__user-nav-item" onClick={this.onLogout}>
            <i className={`icon-logout topbar__logout-icon`}/> <FormattedMessage id="top.bar.login"/>
          </li>
        </ul>
      </div>
    );
  }

  renderMobileNavItems = () => {
    return (
      navElementsLogged.map((item, index) => {
        const {name, icon, link, roles} = item;

        return (
          <AccessControl key={index} roles={roles}>
            <NavItem onClick={() => this.close()}>
              <NavLink
                tag={RouterNavLink}
                to={link}
              >
                <i className={`${icon} topbar__nav-item-icon--mobile`}/> {name}
              </NavLink>
            </NavItem>
          </AccessControl>
        );
      })
    );
  }

  renderUserContextView() {
    const {userData, isEmptyTopBar} = this.props;

    if(userData) {
      return this.renderUser();
    }

    if(!isEmptyTopBar) {
      return this.renderRegisterBtn();
    }
    
    return null;
  }

  renderNavbar() {
    const {mobileBreakpoint, isChannelLive} = this.props;
    const userContextView = this.renderUserContextView();
    const rightView = this.props.rightView !== undefined ? this.props.rightView : userContextView;
    const renderMobileNavItems = this.props.renderMobileNavItems || this.renderMobileNavItems;

    if (mobileBreakpoint) {
      return <Fragment>
        <Navbar light expand={mobileBreakpoint}>
          <div className="topbar-links-wrapper">
            <a href="https://cardboard.live" className="navbar-navbrand">
              <img src={logo} alt="cardboard live"/>
            </a>
            <i className="icon-chevron top-bar-icon"/>
            <div className="pl-2">
              <Link to={'/'} className="back-link"><FormattedMessage id="topbar.portal.link"/></Link>
            </div>
          </div>
          <NavbarToggler onClick={this.toggle}/>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className={`ml-auto d-${mobileBreakpoint}-none`} navbar>
              {renderMobileNavItems()}
              {this.renderUser()}
            </Nav>
          </Collapse>
          <div className={`topbar__user d-none d-${mobileBreakpoint}-flex`}>
            {rightView}
          </div>
        </Navbar>
      </Fragment>;
    }

    return (
      <div>
        <Navbar light>
          <div className={classnames({
            'live-channel-bar': isChannelLive
          })}>
            <div className="topbar-links-wrapper">
              <a href="https://cardboard.live" className="navbar-navbrand">
                <img src={logo} alt="cardboard live"/>
              </a>
              <i className="icon-chevron top-bar-icon"/>
              <div className="pl-2 mr-3">
                <Link to={'/'} className="back-link"><FormattedMessage id="topbar.portal.link"/></Link>
              </div>
            </div>
            {isChannelLive && <ChannelLive/> }
          </div>
          <div className="topbar__user d-flex">
            {rightView}
          </div>
        </Navbar>
      </div>
    );
  }

  render() {
    return (
      <div className="topbar" ref={node => this.topBarNode = node}>
        <div className="container-fluid">
          {this.renderNavbar()}
        </div>
      </div>
    );
  }
}

TopBar.propTypes = {
  userData: PropTypes.object,
  rightView: PropTypes.node,
  renderMobileNavItems: PropTypes.func,
  userRole: PropTypes.string,
  setUserRole: PropTypes.func,
  logout: PropTypes.func,
  isMobile: PropTypes.bool,
  isEmptyTopBar: PropTypes.bool,
  isChannelLive: PropTypes.bool,
  mobileBreakpoint: function (props, propName, componentName) {
    if (!props[propName]) {
      return null;
    }

    if (!/^xs|sm|md|lg|xl$/.test(props[propName])) {
      return new Error(
        'Invalid prop `' + propName + '` supplied to' +
        ' `' + componentName + '`. Correct values: `xs`, `sm`, `md`, `lg`, `xs`. Validation failed.'
      );
    }
  },
};

export default TopBar;
