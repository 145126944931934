import React from 'react';
import {injectIntl} from 'react-intl';
import {omit} from 'lodash';

import ModalModifyTeam from './ModalModifyTeam';

class ModalAddTeam extends React.Component {
  render() {
    const {intl, ...props} = this.props;

    return (
      <ModalModifyTeam
        {...props}
        title={intl.formatMessage({id: 'tournament.teams.addModal.title'})}
        submitText={intl.formatMessage({id: 'tournament.teams.modifyModal.submit'})}
        cancelText={intl.formatMessage({id: 'tournament.teams.modifyModal.cancel'})}
      />
    );
  }
}

ModalAddTeam.propTypes = {
  ...omit(ModalModifyTeam.propTypes, ['title', 'onDelete'])
};

export default injectIntl(ModalAddTeam);
