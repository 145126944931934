import {combineReducers} from 'redux';

import get from './get';
import add from './add';
import edit from './edit';
import remove from './remove';
import byId from './byId';
import allIds from './allIds';
import pagination from './pagination';
import unregisterTeam from './unregisterTeam';

export default combineReducers({
  get,
  add,
  edit,
  remove,
  byId,
  allIds,
  pagination,
  unregisterTeam,
});
