import {createLogic} from 'redux-logic';

import {SUBMIT_DECK_FOR_TOURNAMENT} from '../../actions/types';
import {getTournamentsSuccess, getTournamentsError} from '../../actions/submitDeckForTournament';

export const get = createLogic({
  type: SUBMIT_DECK_FOR_TOURNAMENT.TOURNAMENTS.START,

  latest: true,

  process({httpClient, action}, dispatch, done) {
    const params = {
      status: ['not_started']
    };

    return httpClient.get('/api/v1/me/player_tournaments', {params})
      .then(res => res.data)
      .then(data => {
        const payload = action.payload;

        payload.nextActions.forEach((action) => dispatch(action));

        dispatch(getTournamentsSuccess(data));
      })
      .catch(err => {
        dispatch(getTournamentsError(err));
      })
      .then(done);
  }
});

export default [
  get,
];
