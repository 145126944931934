import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from 'react-intl';

import TeamMembersDecklistTable from './TeamMembersDecklistTable';

class TeamMembersDecklistCreateTable extends React.Component {
  getDropdownData = (rowElems, index) => {
    const {data, onEditDecklist, onCreateDecklist} = this.props;
    const item = data[index];

    if(!item.validDeck) {
      return [
        <div onClick={onCreateDecklist} key={1}>
          <FormattedMessage id="team.member-decklist-table.create.create"/>
        </div>
      ];
    }

    return [
      <div onClick={() => onEditDecklist(item.userId)} key={1}>
        <FormattedMessage id="team.member-decklist-table.create.edit"/>
        <i className="icon-edit ml-2"/>
      </div>
    ];
  };

  renderColDecklist(item) {
    const { onEditDecklist } = this.props;

    if(!item.validDeck) {
      return (
        <div className="btn btn-danger btn-block" onClick={() => onEditDecklist(item.userId)}>
          <FormattedMessage id="team.member-decklist-table.create.create"/>
        </div>
      );
    }

    return (
      <div className="btn btn-success btn-block" onClick={() => onEditDecklist(item.userId)}>
        <FormattedMessage id="team.member-decklist-table.create.edit"/>
        <i className="icon-edit ml-2"/>
      </div>
    );
  }

  render() {
    const {data, onSubmit, intl} = this.props;

    const isDisabled = data.some(item => !item.validDeck);

    return (
      <TeamMembersDecklistTable
        data={data}
        submitDisabled={isDisabled}
        buttonSubmitText={intl.formatMessage({id: 'team.member-decklist-table.create.submit'})}
        dropdownData={this.getDropdownData}
        onSubmit={onSubmit}
      >
        {
          (item) => [
            item.firstName,
            item.lastName,
            item.dciNumber,
            item.format,
            this.renderColDecklist(item),
          ]
        }
      </TeamMembersDecklistTable>
    );
  }
}

TeamMembersDecklistCreateTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    dciNumber: PropTypes.string,
    format: PropTypes.string,
    deckId: PropTypes.string,
    userId: PropTypes.string,
  })).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEditDecklist: PropTypes.func.isRequired,
  onCreateDecklist: PropTypes.func.isRequired,

  intl: PropTypes.object.isRequired,
};

export default injectIntl(TeamMembersDecklistCreateTable);
