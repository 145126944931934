import {
  TOURNAMENT_PLAYERS,
  TOURNAMENT_JUDGES,
  TOURNAMENT_ORGANIZERS,
  TOURNAMENT_PLAYERS_SEARCH,
  TOURNAMENT_CASTERS,
  TOURNAMENT_EXTRAS,
  TOURNAMENT_PLAYERS_UPDATE,
} from '../actions/types';

const initialState = {
  players: {
    data: null,
    created: null,
    isLoad: false,
    isError: false,
    error: null,
  },
  extras: {
    data: null,
    isLoad: false,
    isError: false,
    error: null,
  },
  judges: {
    data: null,
    isLoad: false,
    isError: false,
    error: null,
  },
  organizers: {
    data: null,
    isLoad: false,
    isError: false,
    error: null,
  },
  searchedMembers: {
    data: null,
    created: null,
    isLoad: false,
    isError: false,
    error: null,
  },
  casters: {
    data: null,
    created: null,
    isLoad: false,
    isError: false,
    error: null,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_PLAYERS.FETCH:
      return {
        ...state,
        players: {
          ...state.players,
          isLoad: true,
          isError: false,
          error: null,
        }
      };
    case TOURNAMENT_PLAYERS.ERROR:
      return {
        ...state,
        players: {
          ...state.players,
          isLoad: false,
          isError: true,
          error: action.payload
        }
      };
    case TOURNAMENT_PLAYERS.SUCCESS:
      return {
        ...state,
        players: {
          ...state.players,
          isLoad: false,
          isError: false,
          error: null,
          data: action.payload,
        }
      };
    case TOURNAMENT_EXTRAS.FETCH:
      return {
        ...state,
        extras: {
          ...state.extras,
          isLoad: true,
          isError: false,
          error: null,
        }
      };
    case TOURNAMENT_EXTRAS.ERROR:
      return {
        ...state,
        extras: {
          ...state.extras,
          isLoad: false,
          isError: true,
          error: action.payload
        }
      };
    case TOURNAMENT_EXTRAS.SUCCESS:
      return {
        ...state,
        extras: {
          ...state.extras,
          isLoad: false,
          isError: false,
          error: null,
          data: action.payload,
        }
      };
    case TOURNAMENT_JUDGES.FETCH:
      return {
        ...state,
        judges: {
          ...state.judges,
          isLoad: true,
          isError: false,
          error: null,
        }
      };
    case TOURNAMENT_JUDGES.ERROR:
      return {
        ...state,
        judges: {
          ...state.judges,
          isLoad: false,
          isError: true,
          error: action.payload
        }
      };
    case TOURNAMENT_JUDGES.SUCCESS:
      return {
        ...state,
        judges: {
          ...state.judges,
          isLoad: false,
          isError: false,
          error: null,
          data: action.payload,
        }
      };
    case TOURNAMENT_PLAYERS_SEARCH.START:
      return {
        ...state,
        searchedMembers: {
          ...state.searchedMembers,
          isLoad: true,
          isError: false,
          error: null,
        }
      };
    case TOURNAMENT_ORGANIZERS.FETCH:
      return {
        ...state,
        organizers: {
          ...state.organizers,
          isLoad: true,
          isError: false,
          error: null,
        }
      };
    case TOURNAMENT_ORGANIZERS.SUCCESS:
      return {
        ...state,
        organizers: {
          ...state.organizers,
          isLoad: false,
          isError: false,
          error: null,
          data: action.payload,
        }
      };
    case TOURNAMENT_ORGANIZERS.ERROR:
      return {
        ...state,
        organizers: {
          ...state.organizers,
          isLoad: false,
          isError: true,
          error: action.payload
        }
      };
    case TOURNAMENT_PLAYERS_SEARCH.ERROR:
      return {
        ...state,
        searchedMembers: {
          ...state.searchedMembers,
          isLoad: false,
          isError: true,
          error: action.payload,
        }
      };
    case TOURNAMENT_PLAYERS_SEARCH.SUCCESS:
      return {
        ...state,
        searchedMembers: {
          ...state.searchedMembers,
          isLoad: false,
          isError: false,
          error: null,
          data: action.payload,
        }
      };
    case TOURNAMENT_CASTERS.FETCH:
      return {
        ...state,
        casters: {
          ...state.casters,
          isLoad: true,
          isError: false,
          error: null,
        }
      };
    case TOURNAMENT_CASTERS.ERROR:
      return {
        ...state,
        casters: {
          ...state.casters,
          isLoad: false,
          isError: true,
          error: action.payload
        }
      };
    case TOURNAMENT_CASTERS.SUCCESS:
      return {
        ...state,
        casters: {
          ...state.casters,
          isLoad: false,
          isError: false,
          error: null,
          data: action.payload,
        }
      };
    case TOURNAMENT_PLAYERS_UPDATE.SUCCESS: {
      const {playerId, updatedData} = action.payload;
      return {
        ...state,
        players: {
          ...state.players,
          data: {
            ...state.players.data,
            data: state.players.data.data.map((item) => {
              if (item.id !== playerId) {
                return item;
              }
  
              return {
                ...item,
                ...updatedData,
              };
            })
          }
          
        }
      };
    }
    default:
      return state;
  }
};
