import { METADATA } from './types';

export const getMetaData = (id, deckFormat) => ({
  type: METADATA.GET.FETCH,
  payload: {
    id,
    deckFormat,
  }
});

export const getInitMetaData = (id, tournamentFormat) => ({
  type: METADATA.TEAM_INIT_GET.FETCH,
  payload: {
    id,
    tournamentFormat,
  }
});

export const updateTournamentOverWrite = (tournamentId, data, deckFormat) => ({
  type: METADATA.OVERWRITE_UPDATE,
  payload: {
    tournamentId,
    data,
    deckFormat,
  },
});

export const addNewData = (tournamentId, name, amount, deckFormat) => ({
  type: METADATA.ADD_NEW.START,
  payload: {
    tournamentId,
    name,
    amount,
    deckFormat
  }
});

export const deleteData = (tournamentId, dataId) => ({
  type: METADATA.DELETE.START,
  payload: {
    tournamentId,
    dataId,
  },
});

export const editionIdChange = (editionId) => ({
  type: METADATA.EDITION_ID,
  payload: editionId,
});

export const saveChange = (tournamentId, dataId, name, amount) => ({
  type: METADATA.UPDATE.START,
  payload: {
    tournamentId,
    dataId,
    name,
    amount,
  },
});

export const resetEditionId = () => ({
  type: METADATA.RESET_EDIT_ID,
});

export const resetMetaData = () => ({
  type: METADATA.RESET,
});
