import React from 'react';
import PropTypes from 'prop-types';

class MobileDetector extends React.Component {
    state = { isMobile: false };

    componentDidMount() {
        window.addEventListener('resize', this.checkWidth);
        this.checkWidth();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkWidth);
    }

    checkWidth = () => {
        const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        const isMobile = width < 992;
        if(isMobile !== this.state.isMobile) {
          return this.setState({ isMobile });
        }
    };

    render() {
        return this.props.render(this.state.isMobile);
    }
}

MobileDetector.propTypes = {
    render: PropTypes.func.isRequired,
};

export default MobileDetector;
