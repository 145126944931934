import * as React from 'react';
import PropTypes from 'prop-types';
import TournamentListPlayer from '../Tournament/TournamentList/TournamentListPlayerTable';
import { FormattedMessage } from 'react-intl';

class UserOrganizerList extends React.Component {
  render() {
    return (
      <div>
        <h4 className="section-header section-header--secondary mb-5"><FormattedMessage id="player.title"/></h4>
        <TournamentListPlayer userId={this.props.userId} history={this.props.history} />
      </div>
    );
  }
}

UserOrganizerList.propTypes = {
  history: PropTypes.object,
  userId: PropTypes.string,
};

export default UserOrganizerList;
