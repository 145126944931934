export const getTournamentTeamDeckFormatsSelector = (state) => {
  return {
    isLoading: state.get.isPending || (!state.get.isSuccess && !state.get.isError),
    byTournamentFormat: state.byTournamentFormat,
  };
};

export const getTournamentTeamDeckFormatsToMetaGame = (state, tournamentFormat) => {
  const teamDeckFormats = state.byTournamentFormat[tournamentFormat] || [];
  return [ ...new Set(teamDeckFormats) ];
};

export default {
  getTournamentTeamDeckFormatsSelector,
  getTournamentTeamDeckFormatsToMetaGame,
};
