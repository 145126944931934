import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import TopBar from '../../Layout/TopBar';
import cards from '../../../assets/images/ico-cards.svg';
import manaIcons from '../../DeckBuilder/CardSearch/manaIcons';
import NotifierMain from '../../Notifier/NotifierMain';

import {getStreamerDecks, loadMoreStreamerDecks} from '../../../actions/publicDecksActions';

import './PublicDeck.scss';

class PublicDeck extends React.Component {
  state = {
    page: 1
  }

  componentDidMount() {
    const {channelId} = this.props;

    this.props.getStreamerDecks(channelId);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if(window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
      return;
    }

    this.loadMore();

  };

  loadMore = () => {
    const {channelId, deckData} = this.props;
    const {page} = this.state;

    if(deckData.data.headers['x-last-page'] === deckData.data.headers['x-page']) {
      return;
    }

    if(page >= deckData.data.headers['x-last-page']) {
      return;
    }

    this.props.loadMoreStreamerDecks(channelId, page+1);

    this.setState(state => ({
      page: state.page + 1
    }));
  }

  copySuccess = () => {
    const {intl} = this.props;
    this.props.onCopyToClipboard({
      text: intl.messages['shared.deck.oncopy.label'],
      type: 'success',
      timeout: 4,
    });
  };

  renderManaCost = (item) => {
    return (
      <span>
        <img className={classnames('mana-cost', {
          'visible': item.includes('W')
        })} src={manaIcons['W']} alt="" />

        <img className={classnames('mana-cost', {
          'visible': item.includes('U')
        })} src={manaIcons['U']} alt="" />

        <img className={classnames('mana-cost', {
          'visible': item.includes('B')
        })} src={manaIcons['B']} alt="" />

        <img className={classnames('mana-cost', {
          'visible': item.includes('R')
        })} src={manaIcons['R']} alt="" />

        <img className={classnames('mana-cost', {
          'visible': item.includes('G')
        })} src={manaIcons['G']} alt="" />
      </span>
    );
  }

  renderDecks = () => {
    const {deckData, channelId} = this.props;

    if(!deckData.data || !deckData.data.data) {
      return null;
    }

    return (
      <Fragment>
        {
          deckData.data.data.map((item, index) => {
            return (
              <Link to={`/shared-deck/${item.deck.id}?channel_id=${channelId}`} className="deck-item" key={index}>
                <div className={classnames('deck-content', {
                  'active': index === 0
                })}>
                  <img className="cover-image" src={item.coverUrl} alt=""/>
                  <span className="active-label">active</span>
                  <div className="deck-details">
                    <div className="deck-name">
                      {item.displayName ? item.displayName : item.deck.name}
                    </div>
                    <div className="deck-info">
                      {moment(item.lastPlayedAt).fromNow()}
                    </div>
                    <div className="deck-mana">
                      {this.renderManaCost(item.colors)}
                    </div>
                  </div>
                </div>
              </Link>
            );
          })
        }
      </Fragment>
    );
  }

  render() {
    const {url, name, deckData} = this.props;

    const decks = deckData.data && deckData.data.headers && deckData.data.headers['x-total'];

    return (
      <Fragment>
        <TopBar mobileBreakpoint={null} isEmptyTopBar={true} isChannelLive={false} />
        <NotifierMain className="fix-topbar-padding"/>
        <div className="container">
          <div className="public-deck">
            <div className="deck-item user-data-item">
              <div className="deck-content">
                <div className="deck-content-user-data">
                  <div className="deck-content-user-data-top">
                    <img className="user-img" src={url} alt=""/>
                    <div className="user-name-data">
                      <div className="name">{name}</div>
                      <div className="twitch-name"><a href={`https://twitch.tv/${name}`} target="_blank">{`twitch.tv/${name}`}</a></div>
                    </div>
                  </div>
                  <div className="user-deck">
                    <img src={cards} alt=""/> {`${decks} Decks`}
                  </div>
                </div>
                <div className="copy">
                  <CopyToClipboard text={window.location.href} onCopy={this.copySuccess}>
                    <div className="copy-link">
                      <i className="icon-copying"></i>
                      <FormattedMessage id="public.deck.copy"/>
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            {this.renderDecks()}
            {
              deckData.isLoad &&
              <div className="load-more">
                <span className="dots">...</span>
                <span>Loading</span>
              </div>
            }
          </div>
        </div>
      </Fragment>

    );
  }
}

PublicDeck.propTypes = {
  getStreamerDecks: PropTypes.func.isRequired,
  loadMoreStreamerDecks: PropTypes.func.isRequired,
  channelId: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  deckData: PropTypes.object,
  intl: PropTypes.object,
  onCopyToClipboard: PropTypes.func,
};

export default injectIntl(connect(
  (state) => ({
    deckData: state.streamersDecks,
  }),
  ({
    getStreamerDecks,
    loadMoreStreamerDecks
  })
)(PublicDeck));
