import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SelectImageButton from './SelectImageButton';

import './SelectImageWithPreview.scss';

class SelectImageWithPreview extends React.Component {
  render() {
    const {image, onRemove, onChange, labelText, buttonText, className, defaultImage} = this.props;

    const isRemoveButton = image && onRemove;

    return (
      <div className={classnames('select-image-with-preview', className)}>
        <div className="preview">
          {image && <img src={image}/>}
          {!image && defaultImage && <img src={defaultImage}/>}
          {isRemoveButton && <div className="remove" onClick={onRemove}>x</div>}
        </div>
        <div className="control">
          {labelText && <label>{labelText}</label>}
          <SelectImageButton onChange={onChange} text={buttonText}/>
        </div>
      </div>
    );
  }
}

SelectImageWithPreview.propTypes = {
  image: PropTypes.string,
  defaultImage: PropTypes.string,
  onRemove: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  className: PropTypes.string,
};

export default SelectImageWithPreview;
