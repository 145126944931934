import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

import './DataTable.scss';

class DataTable extends React.Component {
  state = {
    windowWidth: null,
    isMobile: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const windowWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;

    this.setState({
      windowWidth,
      isMobile: windowWidth < 768
    });
  };

  getVisibleColumns() {
    const { columns } = this.props;

    return columns.filter((col, index) => this.isVisibleColumn(index));
  }

  isVisibleColumn(columnIndex) {
    const { isMobile } = this.state;
    const col = this.props.columns[columnIndex];

    if(isMobile) {
      return col.isVisibleOnMobile !== undefined ? col.isVisibleOnMobile : true;
    }
    else {
      return col.isVisibleOnDesktop !== undefined ? col.isVisibleOnDesktop : true;
    }
  }

  isColumnSorted(index){
    const {sortedOptions, columns} = this.props;

    if(!sortedOptions || !sortedOptions.type) {
      return false;
    }

    const colIndex = columns.findIndex(item => {
      return item.sortedKey ===  sortedOptions.actualSortedKey;
    });

    return colIndex === index;
  }

  renderSortIco(key) {
    const {sortedOptions} = this.props;

    if(!key) {
      return null;
    }

    if(!sortedOptions || !sortedOptions.type || (key !== sortedOptions.actualSortedKey)) {
      return (
        <i className="sort-icon icon-arrow_double"/>
      );
    }

    return (
      <i className={classnames('icon-arrow-down', {
        'icon-arrow-up': sortedOptions.type === 'ASC'
      })}/>

    );
  }

  renderHeader() {
    const {onSort} = this.props;

    return (
      <thead className="thead-light">
        <tr>
          {this.getVisibleColumns().map((col, index) => {
            const onSortClick = col.sortedKey ? () => onSort(col.sortedKey) : null;
            const sorted = this.isColumnSorted(index);

            return (
              <th scope="col"
                  onClick={onSortClick}
                  key={col.key || col.name}
                  className={classnames(col.className, {
                    'sorted': sorted
                  })}>
                <div className="th-col-wrapper">
                  {col.name}
                  {this.renderSortIco(col.sortedKey)}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }

  renderBody() {
    const {data, columns, keys} = this.props;

    const onClick = this.props.onRowClick || (() => {});

    return (
      <tbody>
        {data.map((row, i) => {
          const rowData   = row.data ? row.data : row;
          const className = row.className ? row.className : '';
          const rowKey = (keys && keys[i]) || i;
          return (
            <tr key={rowKey} onClick={() => onClick(i)} className={className}>
              {rowData.map((item, j) => {
                return this.isVisibleColumn(j)
                  ? <td key={`${i}${j}`} className={classnames(columns[j].className, {
                    'sorted': this.isColumnSorted(j)
                  })}>
                      <div className={columns[j].divClassName}>{item}</div>
                    </td>
                  : null;
              })}
            </tr>
          );
        })}
      </tbody>
    );
  }

  render() {
    const {disableHover, className} = this.props;

    return (
      <div className={classnames('data-table', className)}>
        <table className={classnames('table table-striped', {
          'table-hover': !disableHover
        })}>
          {this.renderHeader()}
          {this.renderBody()}
        </table>
      </div>
    );
  }
}

DataTable.propTypes = {
  columns: propTypes.arrayOf(propTypes.shape({
    name: propTypes.string.isRequired,
    key: propTypes.any,
    isVisibleOnMobile: propTypes.bool,
    isVisibleOnDesktop: propTypes.bool,
    className: propTypes.oneOfType([
      propTypes.string,
      propTypes.arrayOf(propTypes.string)
    ]),
    sortedKey: propTypes.string,
    divClassName: propTypes.string,
  })),
  onSort: propTypes.func,
  sortedOptions: propTypes.shape({
    actualSortedKey: propTypes.string,
    type: propTypes.oneOf(['ASC', 'DESC'])
  }),
  className: propTypes.string,
  onRowClick: propTypes.func,
  data: propTypes.array.isRequired,
  keys: propTypes.array,
  disableHover: propTypes.bool,
};

export default DataTable;
