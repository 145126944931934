import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default class Name extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lengthValid: false,
      isValid: true,
      isSameAsInitial: true,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      lengthValid: nextProps.value.length >= 2
    };
  }

  changeValidation(value) {
    if (this.state.isValid !== value) {
      this.props.onValidationChanged(value);
    }
    this.setState({
      isValid: value,
    });
  }

  handleChange(value) {
    this.props.onChange(value);
    this.validateName(value);
  }

  renderValidation() {
    const { lengthValid, isSameAsInitial } = this.state;

    if (lengthValid && !isSameAsInitial) {
      return <div className="valid-feedback"><FormattedMessage id="label.edited"/></div>;
    }
    if (!lengthValid) {
      return <div className="invalid-feedback"><FormattedMessage id="account.name.error"/></div>;
    }
  }

  validateName(value) {
    if (value.length >= 2) {
      this.setState({
        lengthValid: true,
        isSameAsInitial: !(value !== this.props.initialValue),
      });
      this.changeValidation(true);
    } else {
      this.setState({
        lengthValid: false,
      });
      this.changeValidation(false);
    }
  }

  render() {
    const { lengthValid, isSameAsInitial } = this.state;

    const valid = lengthValid && !isSameAsInitial;
    const invalid = !lengthValid;

    const inputClass = classNames('form-control', 'register-form__input', {
      'is-valid': valid,
      'is-invalid': invalid,
    });
    return (
      <React.Fragment>
        <input
          type="text"
          className={inputClass}
          value={this.props.value}
          onChange={e => this.handleChange(e.target.value)}
        />
        {this.renderValidation()}
      </React.Fragment>
    );
  }
}

Name.propTypes = {
  onValidationChanged: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
};
