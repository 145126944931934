import {TOURNAMENT_STANDINGS} from './types';

export const getTournamentStandings = ({tournamentId, page = 1, search, sort, order}) => ({
  type: TOURNAMENT_STANDINGS.GET.START,
  payload: {
    tournamentId,
    search,
    page,
    sort: sort,
    order: order
  }
});

export const updateTournamentStandings = ({tournamentId, data}) => ({
  type: TOURNAMENT_STANDINGS.UPDATE.START,
  payload: {
    tournamentId,
    data,
  }
});

export const updateTournamentStandingsSuccess = (data) => ({
  type: TOURNAMENT_STANDINGS.UPDATE.SUCCESS,
  payload: data
});

export const updateTournamentStandingsError = (data) => ({
  type: TOURNAMENT_STANDINGS.UPDATE.ERROR,
  payload: data
});

export const addTournamentStandingsRow = (data) => ({
  type: TOURNAMENT_STANDINGS.ADD,
  payload: data
});

export const deleteTournamentStandingsRow = (id) => ({
  type: TOURNAMENT_STANDINGS.DELETE_ROW,
  payload: id
});

export const deleteTournamentStanding = ({tournamentId, standingId}) => ({
  type: TOURNAMENT_STANDINGS.DELETE.START,
  payload: {
    tournamentId,
    standingId,
  }
});

