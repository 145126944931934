export const getErrorMessage = (errorResponse) => {
  if(!errorResponse.data) {
    return 'submitDeckModal.error.default';
  } 
  
  if(errorResponse.data.error === 'CustomCardsNotAllowed'){
    return 'submitDeckModal.error.custom.cards';
  } 

  return null;
};