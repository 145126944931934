import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {Link, withRouter} from 'react-router-dom';
import {matchPath} from 'react-router';
import { FormattedMessage } from 'react-intl';

import { add as addNotification } from '../../actions/notificationActions';
import { saveTournamentAndUpdate } from '../../actions/tournamentActions';

import Tabs from '../../components/Generic/Tabs/Tabs';
import MobileDetector from '../Utils/MobileDetector';
import classnames from 'classnames';
import TournamentStatusDropdown from './TournamentStatusDropdown';
import { roles } from '../const';

import './Tournament.scss';

class TournamentData extends React.Component {
  onChangeStatus = (value) => {
    const {saveTournamentAndUpdate, data} = this.props;

    saveTournamentAndUpdate(data.id, {
      status: value,
    });
  };

  renderStatus(status) {
    return <TournamentStatusDropdown disabled={this.props.userRole === roles.player || this.props.userRole === roles.judge} value={status} onChange={this.onChangeStatus}/>;
  }

  renderPasscode(isMobile) {
    const {passCode} = this.props.data;
    const domain = window.location && window.location.origin;

    return(
      <div className="tournament-form__passcode">
        <div className="passcode-wrapper">
          <div>
            <label className="text-uppercase passcode-label"><FormattedMessage id="tournament.label.passcode"/></label>
            <div className="passcode">
              {
                passCode
                  ? passCode
                  : '-'
              }
            </div>
          </div>
          <div className="passcode-btn-wrapper">
            <div className={classnames('mx-xl-3', {
                          'd-flex flex-md-column justify-content-center': !isMobile
                        })}>
              <CopyToClipboard
                text={passCode}
                onCopy={() => {
                  this.props.addNotification({
                    text: <FormattedMessage id="tournament.clipboard.message" />,
                    type: 'success',
                    timeout: 4
                  });
                }}
              >
                <button type="button"
                        className={classnames('btn btn-primary', {
                          'btn-success btn-sm': isMobile,
                          'mr-3 mr-md-0': !isMobile
                        })}
                        disabled={!passCode}>
                        {isMobile ? <i className="icon-copying"></i> : <FormattedMessage id="tournament.label.copy"/>}

                </button>
              </CopyToClipboard>
              <CopyToClipboard
                text={`${domain}/t/${passCode}`}
                onCopy={() => {
                  this.props.addNotification({
                    text: <FormattedMessage id="tournament.clipboard.url.message" />,
                    type: 'success',
                    timeout: 4
                  });
                }}
              >
                <button type="button"
                        className={classnames('btn btn-primary', {
                          'btn-success btn-sm d-none': isMobile,
                          'mt-md-3': !isMobile
                        })}
                        disabled={!passCode}>
                        {isMobile ? <i className="icon-share"></i> : <FormattedMessage id="tournament.label.copy.url"/>}
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTabs() {
    const {data, location, userRole, tabs} = this.props;
    const tournamentId = data.id;

    const index = tabs.findIndex((item) => {
      const match = matchPath(location.pathname, {
        path: `/tournaments/show/:tournamentId/${item.linkname}`
      });

      return !!match;
    });

    return (
      <Tabs
        className="tournament-tabs"
        data={tabs}
        index={index}
        renderTabItem={(item) => {
          if(!item.roles.includes(userRole)){
            return false;
          }

          return (
              <Link to={`/tournaments/show/${tournamentId}/${item.link}`}>{item.name}</Link>
          );
        }}/>
    );
  }

  render() {
    const {data} = this.props;
    const {name, id, status} = data;

    return (
      <MobileDetector render={(isMobile) => (
          <div className="tournament-box">
            <div className="tournament-box__data">
              <div className="data-top">
                <label className="text-uppercase tournament-overview__title"><FormattedMessage id="tournament.label.name"/></label>
                <div className="row">
                  <div className="col-lg-7">
                    <h5 className="mb-4 ml-2 tournament-overview__title-text">
                      <span className="font-weight-bold">{name}</span> <small>#{id.split('-')[0]}</small>
                    </h5>
                  </div>
                  <div className="col-sm-6 col-lg-5">
                    <div className="mb-4 ml-2">{this.renderStatus(status)}</div>
                  </div>
                  {
                    isMobile &&
                    <div className="col-sm-6">
                      {this.renderPasscode(isMobile)}
                    </div>
                  }
                </div>
              </div>
              <div className="data-bottom">
                {this.renderTabs()}
              </div>
            </div>

            {!isMobile && this.renderPasscode()}

          </div>
        )}/>
    );
  }
}

TournamentData.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    passCode: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    linkname: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.any).isRequired,
  })),

  location: PropTypes.object.isRequired,

  userRole: PropTypes.string,

  addNotification: PropTypes.func.isRequired,
  saveTournamentAndUpdate: PropTypes.func.isRequired,
};

export default withRouter(connect(
  (state) => ({
    userRole: state.user.userRole
  }),
  ({
    addNotification,
    saveTournamentAndUpdate,
  })
)(TournamentData));
