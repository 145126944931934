import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';

import AdminMetrics from './AdminMetrics';
import Spinner from '../../Spinner';

import {fetchAll} from '../../../actions/adminStatistics';
import {fetchFormats} from '../../../actions/formatsActions';

class AdminMetricsContainer extends React.Component {
  componentDidMount() {
    const {fetchAll, fetchFormats} = this.props;

    fetchAll({
      formats_cards: {formatId: 1},
      deck_names: {
        formatId: 1,
        since: moment().subtract(7, 'days').startOf('day').unix(),
        until: moment().endOf('day').unix(),
      },
    });
    fetchFormats();
  }

  render() {
    const {statistics, formats} = this.props;

    const isLoaded =
      !!statistics.users.data &&
      !!statistics.stores.data &&
      !!statistics.decks.data &&
      !!statistics.tournaments.data &&
      !!statistics.tournament_locations.data &&
      !!statistics.tournament_formats.data &&
      !!statistics.formats_cards.data &&
      !!statistics.deck_names.data &&
      !!formats.data;

    return (
      <div className="container my-5">
        <h4 className="section-header mb-5">
          <FormattedMessage id="metrics.title"/>
        </h4>

        {
          !isLoaded
            ? <Spinner size={40}/>
            : <AdminMetrics statistics={statistics} formats={formats.data}/>
        }
      </div>
    );
  }
}

AdminMetricsContainer.propTypes = {
  fetchAll: PropTypes.func.isRequired,
  fetchFormats: PropTypes.func.isRequired,
  statistics: PropTypes.object.isRequired,
  formats: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    statistics: state.adminStatistics,
    formats: state.formats,
  }),
  {
    fetchAll,
    fetchFormats,
  }
)(AdminMetricsContainer);
