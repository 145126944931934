import React from 'react';
import PropTypes from 'prop-types';

class CardName extends React.Component {
  render() {
    const {name, names} = this.props;

    if(names.length > 1){
      return `${names.join(' // ')} (${name})`;
    }

    return name;
  }
}

CardName.propTypes = {
  name: PropTypes.string.isRequired,
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CardName;
