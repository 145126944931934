import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {FormattedMessage} from 'react-intl';

import './MetricBox.scss';

class MetricBox extends React.Component {
  renderTitle() {
    const {titleKey, title} = this.props;

    if(titleKey){
      return <h4><FormattedMessage id={titleKey}/></h4>;
    }
    if(title){
      return <h4>{title}</h4>;
    }

    return null;
  }

  render() {
    const {children, bgImage, className} = this.props;

    return (
      <div className={classnames('metric-box', className)} style={{backgroundImage: `url(${bgImage})`}}>
        {this.renderTitle()}
        {children}
      </div>
    );
  }
}

MetricBox.propTypes = {
  bgImage: PropTypes.string,
  titleKey: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default MetricBox;
