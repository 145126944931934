import * as React from 'react';
import { createLogic } from 'redux-logic';
import { push } from 'react-router-redux';

import { IMPORT_STANDINGS } from '../actions/types';
import { add as addNotification } from '../actions/notificationActions';
import { open } from '../actions/modal';
// import * as ModalActions from '../../../actions/modal';
import modalNames from '../components/modals/names';
import { FormattedMessage } from 'react-intl';
import {getTournamentStandings} from '../actions/tournamentStandings';
import {getTournamentTeamsStandings} from '../actions/tournamentTeamsStandings';

export const importStandings = createLogic({
  type: IMPORT_STANDINGS.START,

  process({ httpClient, action }, dispatch, done) {
    const {tournamentId} = action;

    const formData = new FormData();
    formData.append('file', action.file);

    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };

    return httpClient.post(`/api/v1/tournaments/${tournamentId}/import_standings`, formData, options)
      .then((res) => {
        if(res.data.unrecognizedRows && res.data.unrecognizedRows.length > 0) {
          dispatch(open(modalNames.IMPORT_STANDINGS_RESULTS, {
            unrecognized: res.data.unrecognizedRows
          }));
        } else {
          dispatch(addNotification({
            type: 'success',
            text: <FormattedMessage id="tournament.players.import.standings.success"/>
          }));
        }
        
        dispatch(getTournamentStandings({
          tournamentId
        }));

        dispatch({type: IMPORT_STANDINGS.SUCCESS});

        dispatch(getTournamentTeamsStandings(tournamentId));
        dispatch(push(`/tournaments/show/${tournamentId}/standings/1`));
      })
      .catch(err => {
        dispatch(addNotification({
          type: 'danger',
          text: err.response.data.error_description || 'Unrecognized network error',
        }));
        dispatch({type: IMPORT_STANDINGS.ERROR});
      })
      .then(() => done());
  }
});

export default {
  importStandings,
};
