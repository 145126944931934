import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';

export default class Password extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lengthValid: false,
      isValid: false,
      isBlurred: false,
    };
  }

  changeValidation(value) {
    if (this.state.isValid !== value) {
      this.props.onValidationChanged(value);
    }
    this.setState({
      isValid: value,
    });
  }

  handleChange(value) {
    this.props.onChange(value);
  }

  validateLength(value) {
    if (value.length === 0) {
      return false;
    }
    return true;
  }

  validatePassword(value) {
    this.setState({
      isBlurred: true,
    });
    if (value.length >= 8) {
      this.setState({
        lengthValid: true,
      });
      this.changeValidation(true);
    } else {
      this.setState({
        lengthValid: false,
      });
      this.changeValidation(false);
    }
  }

  renderValidation() {
    const { lengthValid, isBlurred } = this.state;

    if (lengthValid) {
      return <div className="valid-feedback"><FormattedMessage id="register.password.valid"/></div>;
    }
    if (isBlurred && !lengthValid) {
      return (
        <div className="invalid-feedback">
          <FormattedMessage id="register.password.invalid"/>
        </div>
      );
    }
  }

  render() {
    const { lengthValid, isBlurred } = this.state;

    const valid = lengthValid;
    const invalid = isBlurred && !lengthValid;

    const inputClass = classNames('form-control', 'register-form__input', {
      'is-valid': valid,
      'is-invalid': invalid,
    });

    return (
      <React.Fragment>
        <input
          type="password"
          className={inputClass}
          value={this.props.value}
          onChange={e => this.handleChange(e.target.value)}
          onBlur={e => this.validatePassword(e.target.value)}
        />
        {this.renderValidation()}
      </React.Fragment>
    );
  }
}

Password.propTypes = {
  onValidationChanged: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
