import * as React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { add as addNotification } from '../../../actions/notificationActions';
import DeckToClipboard from './DeckToClipboard';

class DeckToClipboardContainer extends React.Component {

  render() {
    const {addNotification, data, format, isArena, onlySideboard} = this.props;
    return (
      <DeckToClipboard
        onCopyToClipboard={addNotification}
        data={data}
        format={format}
        inModal={this.props.inModal}
        isArena={isArena}
        onlySideboard={onlySideboard}
      />
    );
  }
}

DeckToClipboardContainer.propTypes = {
  addNotification: PropTypes.func,
  data: PropTypes.oneOfType(PropTypes.array, PropTypes.string),
  format: PropTypes.object,
  inModal: PropTypes.bool,
  isArena: PropTypes.bool,
  onlySideboard: PropTypes.bool,
};

export default connect(
  null,
  ({
    addNotification,
  })
)(DeckToClipboardContainer);
