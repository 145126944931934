import React from 'react';
import PropTypes from 'prop-types';
import {withRouter, Route, Switch} from 'react-router-dom';

import TournamentUsersShow from './TournamentUsersShow';
import TournamentUserEdit from './TournamentUserEdit';

class TournamentUsers extends React.Component {
  render() {
    const {tournament, match} = this.props;

    const showUsers = (Component) => () => <Component tournament={tournament}/>;

    return (

      <React.Fragment>
        <Switch>
          <Route path={`${match.path}/:userId`} component={TournamentUserEdit}/>
          <Route path={`${match.path}/`} render={showUsers(TournamentUsersShow)}/>
        </Switch>
      </React.Fragment>

    );
  }
}

TournamentUsers.propTypes = {
  match: PropTypes.object.isRequired,
  tournament: PropTypes.object,
};

export default withRouter(TournamentUsers);
