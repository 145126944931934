import React from 'react';
import manaIcons from './manaIcons';


export const renderManaCost = (option) => {
  const split = option.manaCost.split(/[{}]/)
    .filter(elem => elem !== '')
    .map(elem => elem.replace(/\//g, ''));
  const renderManaCost = split.map((elem, index) => {
    if(/^\d+$/.test(elem)){
      return <span key={elem} className="mana-number">{elem}</span>;
    } else {
      if (/^\d\w$/.test(elem)) {
        elem = elem.split('').reverse().join('');
      }
      return <img key={`${elem + index}`} className="mana-cost" src={manaIcons[elem]} alt="" />;
    }
  });
  return renderManaCost;
};
