import React from 'react';
import classnames from 'classnames';
import {omit} from 'lodash';

import BioForm from './BioForm';

class BioFormDefault extends React.Component {
  render() {
    const fields = [
      [
        {
          type: 'img',
          name: '15',
          label: 'Upload Photo',
          data: {
            buttonText: 'Select file',
          }
        },
      ],
      [
        {
          type: 'textarea',
          name: '6',
          label: 'Biography',
          data: {
            rows: 6,
          }
        },
      ],
    ];

    const {className, ...props} = this.props;

    return (
      <BioForm {...props} fields={fields} className={classnames('bio-form-basic', className)}/>
    );
  }
}

const parentPropTypes = omit(
  BioForm.propTypes,
  ['fields']
);

BioFormDefault.propTypes = {
  ...parentPropTypes,
};

export default BioFormDefault;
