import httpClient from './httpClient';
import moment from 'moment';

class Tournament {
  static createTournament() {
    return httpClient.post('/api/v1/tournaments', {
      'name': 'Type name here',
      'startDate': moment().format(),
      'format': 'modern'
    });
  }
  static deleteTournament(id) {
    return httpClient.delete('/api/v1/tournaments/' + id);
  }
  static registerDeck(tournamentId, deckId) {
    return httpClient.put(`/api/v1/tournaments/${tournamentId}/decks/${deckId}`);
  }
  static deregisterFromTournament(memberId) {
    return httpClient.delete(`/api/v1/tournament_members/${memberId}`);
  }
}
export default Tournament;
