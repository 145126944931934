import React from 'react';
import Select from 'react-select';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import { withRouter } from 'react-router';

import Deck from '../DeckBuilder/Deck';
import Spinner from '../Spinner';
import { FormattedMessage, injectIntl } from 'react-intl';
import GenericTooltip from '../GenericTooltip';
import DeckValidationErrorManager from '../../services/DeckValidationErrorManager';
import { roles } from '../const';
import SubmitDeckModalContainer from '../DeckBuilder/SumbitDeckModal/SubmitDeckModalContainer';
import { Scrollbars } from 'react-custom-scrollbars';

import './CreateDeckList.scss';

class CreateDeckList extends React.Component {
  static defaultProps = {
    organizer: false,
    disableFormat: false,
    supportCustomCards: true
  };

  state = {
    customFormat: '',
    comment: '',
    deckName: '',
    customDeckName: '',
    showForm: true,
    description: '',
    options: [],
    selectedFormat: {},
    isChanged: false,
    isChangeFormat: false,
    forSave: false,
    isModalSubmitDeckOpen: false,
    error: {
      deckName: false,
    }
  };

  componentDidMount() {
    const { formats } = this.props.formats;
    if (formats && formats.data) {
      return this.setFormatOptions(formats.data);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match } = this.props;
    if (this.props.deck.isLoad &&
     !nextProps.deck.isLoad &&
     !nextProps.deck.isError) {
       const formatId = nextProps.deck && nextProps.deck.data.format.id;
       this.setInitialStateValues(nextProps.deck.data);
       !nextProps.deck.data.customFormat && this.props.getDecknames(formatId, 1, null, match.params.tournamentId);
     }

     if (this.props.formats.isLoad &&
       !nextProps.formats.isLoad &&
       !nextProps.formats.isError) {
         this.setFormatOptions(nextProps.formats.data);
     }

     if(nextProps.formats.data && (this.state.options !== nextProps.formats.data)) {
       this.setFormatOptions(nextProps.formats.data);
     }

     if (this.props.deckNames.isLoad &&
       !nextProps.deckNames.isLoad &&
       !nextProps.deckNames.isError) {
         const deckNames = nextProps.deckNames.data && nextProps.deckNames.data.data
          ? nextProps.deckNames.data.data.map(item => item.name)
          : [];
         const isCustom = this.state.deckName && !deckNames.includes(this.state.deckName);
         const containsCustomDeckName = this.state.customDeckName && !deckNames.includes(this.state.customDeckName);

        !containsCustomDeckName && this.state.deckName === 'custom' && this.setState({
          deckName: this.state.customDeckName,
          customDeckName: '',
          isChanged: true
        });

         isCustom && this.state.deckName !== 'custom' && this.setState({
           deckName: 'custom',
           customDeckName: this.state.deckName,
           isChanged: true
         });
     }

     if (this.props.decksPatch.isLoad &&
       !nextProps.decksPatch.isLoad &&
       !nextProps.decksPatch.isError) {
         this.setState({ isChanged: false });
     }

     if (this.props.decksPatch.isLoad &&
       !nextProps.decksPatch.isLoad &&
       !nextProps.decksPatch.isError &&
       this.state.forSave) {
         const id = this.props.id;
         this.props.save(id);
     }

    if (
      this.props.saveDeck.isLoad &&
      !nextProps.saveDeck.isLoad &&
      !nextProps.saveDeck.isError
    ) {
      if (this.props.onSave) {
        return this.props.onSave();
      }

      if (this.props.onClose) {
        return this.props.onClose();
      }

      const tournamentID = this.props.deck.data && this.props.deck.data.tournamentID;

      if (!tournamentID) {
        if (this.props.userRole === roles.admin) {
          return this.props.history.goBack();
        }
        return this.props.history.push('/decks');
      }

      this.props.history.push('/tournaments');
    }
  }

  setInitialStateValues = data => {
    const { customFormat, name, format, notes } = data;

    this.setState({
      customFormat: customFormat,
      customDeckName: name,
      deckName: name,
      selectedFormat: {
        label: format.name,
        value: format.id
      },
      comment: '',
      description: notes === null ? '' : notes,
      isChanged: false,
      forSave: false
    });
  };

  setFormatOptions(data) {
    const options = data.map(format => {
      return {
        label: format.name,
        value: format.id
      };
    });

    this.setState({ options });
  }

  onValueChange = selectedFormat => {
    const { customDeckName, deckName } = this.state;

    this.setState({
      selectedFormat
    });

    const id = this.props.id;

    this.props.getDecknames(selectedFormat.value);
    const name = customDeckName ? customDeckName : deckName;

    this.props.patch({
      id: id,
      data: {
        format: {id: selectedFormat.value},
        name
      },
      isChangeFormat: true
    });
  };

  onFormSubmit = e => {
    e.preventDefault();

    const { selectedFormat, deckName, isChanged } = this.state;
    const { id, organizer, inModal, unregistered, deckValidation } = this.props;

    const validationErrorAmount = DeckValidationErrorManager.getAmount(deckValidation);

    if(!deckName) {
      return (
        this.setState((state) => ({
          error: {
            ...state.error,
            deckName: true
          }
        }), () => {
          this.props.addNotification({
            text: <FormattedMessage id="deck.save.name.error" />,
            type: 'danger',
            timeout: 4,
            target: inModal ? 'modal' : 'main'
          });
        })
      );
    } else {
      this.setState((state) => ({
        error: {
          ...state.error,
          deckName: false
        }
      }));
    }

    if(unregistered && (validationErrorAmount > 0)) {
      return(
        this.props.addNotification({
          text: <FormattedMessage id="deck.save.validation.error" />,
          type: 'danger',
          timeout: 4,
        })
      );
    }

    if (!organizer && (!deckName || !selectedFormat.value)) {
      return;
    }

    if (!isChanged) {
      return this.props.save(id);
    }

    this.onBlurHandle();
    this.setState({ forSave: true });
  };

  handleByCategoryChange = () => {
    this.setState(prevState => ({ byCategory: !prevState.byCategory }));
  };

  handleHiding = () => {
    this.setState((prevState) => ({ showForm: !prevState.showForm }));
  };

  changeDeckName(value) {
    const { deckNames } = this.props;

    if(!deckNames || !deckNames.data || !deckNames.data.data){
      return;
    }

    const select = deckNames.data.data.findIndex(item => item.name === value);
    const isCustom = select === -1;

    this.setState({
      deckName: value,
      customDeckName: isCustom ? value : '',
      isChanged: true,
    });
  }

  onDeckNameChange = (value) => {
    this.changeDeckName(value);
  };

  handleInputChange = (value) => {
    const {selectedFormat} = this.state;
    const {match} = this.props;
    
    this.props.getDecknames(selectedFormat.value, 1, value, match.params.tournamentId);
    
    return value;
  }

  onCustomDeckNameChange = (e) => {
    const value = e.target.value;

    if(!value){
      return;
    }

    this.changeDeckName(value);
  };

  onDescriptionChange = e => {
    this.setState({
      description: e.target.value,
      isChanged: true
    });
  };

  onCustomFormatChange = e => {
    this.setState({
      customFormat: e.target.value,
      isChanged: true
    });
  };

  onCommentChange = e => {
    this.setState({
      comment: e.target.value,
      isChanged: true
    });
  };

  onCancel = () => {
    this.setInitialStateValues(this.props.deck.data);
    if(this.props.onClose){
      return this.props.onClose();
    }
    this.props.history.goBack();
  };

  onBlurHandle = () => {
    const id = this.props.id;

    const {
      selectedFormat,
      deckName,
      customFormat,
      description,
      isChanged,
      customDeckName,
    } = this.state;

    if (!isChanged) {
      return;
    }

    const name = customDeckName ? customDeckName : deckName;

    const data = {
      name,
      notes: description,
      format: {
        id: selectedFormat.value
      },
      customFormat: selectedFormat.value && selectedFormat.label !== 'Custom' ? '' : customFormat
    };

    this.props.patch({
      id,
      data,
      isChangeFormat: false
    });
  };

  showLoader = () => {
    const { isDecksPatchLoad, isCardSearchLoad, isDecksCardLoad, saveDeck } = this.props;
    if(isDecksPatchLoad || isCardSearchLoad || isDecksCardLoad || saveDeck.isLoad) {
      return <Spinner class="d-none d-lg-block" size={20} align={'right'}/>;
    }
    return null;
  }

  onSubmitDeckModalSubmit = () => {
    this.onCloseDeckModalSubmit();
    this.props.history.push('/tournaments');
  };

  onOpenDeckModalSubmit = () => {
    this.setState({
      isModalSubmitDeckOpen: true,
    });
  };

  onCloseDeckModalSubmit = () => {
    this.setState({
      isModalSubmitDeckOpen: false,
    });
  };

  renderDeckErrors() {
    const validation = this.props.deckValidation;

    const deckErrors  = validation.deck.map(item => DeckValidationErrorManager.getDeckErrorMessage(item));
    const restError   = DeckValidationErrorManager.getRestErrorMessage([
      ...validation.cardAmount,
      ...validation.cardFormat
    ]);

    const errorMessages = [
      ...deckErrors,
    ];

    if(restError) {
      errorMessages.push(restError);
    }

    if(errorMessages.length === 0) {
      return null;
    }

    const messagesNode = (
      <ul>
        {errorMessages.map((message, i) => <li key={i}>{message}</li>)}
      </ul>
    );

    return (
      <div className="validation-message">
        <GenericTooltip id={'deck-main-validation'}
                        className={'deck-main-validation'}
                        placement={'left'}
                        message={messagesNode}/>
      </div>
    );
  }
  
  renderDeckNameSelect() {
    const { selectedFormat, deckName, customDeckName } = this.state;
    const { deckNames } = this.props;

    const formatValue = selectedFormat && selectedFormat.value;
    let deckNameValue = deckName || '';
    const deckNamesFields = !!formatValue && deckNames.data && deckNames.data.data &&
      deckNames.data.data.map(deckName => ({ value: deckName.name, label: deckName.name }));
    const isDisabled = (!selectedFormat || !selectedFormat.value) && deckNameOptions.length === 0;
    let deckNameOptions = deckNamesFields ? deckNamesFields : [];

    if(customDeckName) {
      deckNameOptions = deckNamesFields ? [...deckNamesFields, { value: customDeckName, label: customDeckName }] : [];
      deckNameValue = customDeckName;
    }

    return(
      <Select.Creatable
          value={deckNameValue}
          options={deckNameOptions}
          onBlurResetsInput={false}
          onBlur={this.onCustomDeckNameChange}
          onChange={this.onDeckNameChange}
          onInputChange={this.handleInputChange}
          simpleValue
          showNewOptionAtTop={false}
          promptTextCreator={(label) => `${this.props.intl.messages['edit.deck.create_custom_name']} ${label}`}
          disabled={isDisabled}
          autosize={false}
        />

    );
  }

  renderButtons() {
    const {deckValidation, unregistered, tournamentDecklist} = this.props;
    const {forSave} = this.state;
    const validationErrorAmount = DeckValidationErrorManager.getAmount(deckValidation);
    const validationError = validationErrorAmount > 0;
    const disableBtn = validationError || forSave;

    return (
      <div className="d-flex justify-content-center mobile-btn-wrapper">
        <button
          type="button"
          className="btn btn-secondary mr-2 d-flex flex-grow-1 flex-lg-grow-0 justify-content-center"
          onClick={this.onCancel}
          >
            <FormattedMessage id="tournaments.btn.cancel"/>
        </button>
        <button
          type="submit"
          className="btn btn-success mr-2 card-search__btn btn-lg flex-grow-1 flex-lg-grow-0"
          disabled={!unregistered ? forSave : disableBtn}
          onClick={this.onFormSubmit}
          >
            <FormattedMessage id={unregistered ? 'tournaments.btn.save-and-submit': 'tournaments.btn.save'}/>
        </button>

        {
          !unregistered && !tournamentDecklist &&
          <button
            type="submit"
            className="btn btn-primary btn-lg flex-grow-1 flex-lg-grow-0"
            onClick={this.onOpenDeckModalSubmit}
          >
            <FormattedMessage id="tournaments.btn.save-and-submit"/>
          </button>
        }

      </div>
    );
  }

  renderContent() {
    const {btnFixed} = this.props;
    const {
      selectedFormat,
      options,
      showForm,
      customFormat,
      description,
      comment,
      isModalSubmitDeckOpen,
      error
    } = this.state;

    if(!this.props.formats.data || !selectedFormat.value) {
      // Block until we don't prepare format!!!
      return null;
    }

    const formatValue = selectedFormat && selectedFormat.value;
    const iconClass = showForm ? 'fa-chevron-circle-down' : 'fa-chevron-circle-left';
    const format = this.props.formats.data.find(item => item.id === selectedFormat.value);
    const deckId = this.props.id;


    return (
      <div className="create-decklist container my-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            {
              this.props.organizer || this.props.tournamentDecklist
                ? null
                : <h2 className="section-header mb-4"><FormattedMessage id="create.decklist"/></h2>
            }
            <div className="loader-wrapper">
              {this.showLoader()}
            </div>
          </div>
          <i
            className={`fa ${iconClass} d-block d-lg-none d-xl-none`}
            onClick={this.handleHiding}
          />
          <button
            className="btn btn-info d-none"
            >
              <FormattedMessage id="edit.deck.btn"/> <i className="icon-edit" />
          </button>

          {this.renderDeckErrors()}
        </div>
        {showForm && <form className="form add-deck__form" >
          <div className="row">
            <div className="col-lg-7">
              <div className={classnames('form-group', {
                'is-invalid': error && error.deckName
              })}>
                <label className="form create-decklist__form-label"><FormattedMessage id="edit.deck.name"/></label>
                {this.renderDeckNameSelect()}
                {error && error.deckName && <span className="text-danger mt-2"><FormattedMessage id="deck.save.name.error" /></span>}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="form-group">
                <label
                  className="form create-decklist__form-label"
                ><FormattedMessage id="edit.deck.format"/></label>
                <Select
                  value={formatValue}
                  options={options}
                  onChange={this.onValueChange}
                  searchable={false}
                  clearable={false}
                  disabled={this.props.disableFormat}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {selectedFormat && selectedFormat.label === 'Custom' && <div className="col-lg-5">
              <div className="form-group">
                <label
                  className="form create-decklist__form-label"
                ><FormattedMessage id="edit.deck.format.custom"/></label>
                <input
                  className="form-control add-deck__form-input"
                  type="text"
                  value={customFormat}
                  onChange={this.onCustomFormatChange}
                  onBlur={this.onBlurHandle}
                  autoComplete="nope" autofill="off" spellCheck="false" autoCorrect="off" autoCapitalize="off"
                />
              </div>
            </div>}

          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form create-decklist__form-label"><FormattedMessage id="edit.deck.description"/></label>
                <textarea
                  className="form-control add-deck__form-input"
                  value={description}
                  onChange={this.onDescriptionChange}
                  onBlur={this.onBlurHandle}
                  rows="1"
                  maxLength={255}
                />
              </div>
            </div>
          </div>
          <div className="create-decklist__organizer mt-5 d-none">
            <p><FormattedMessage id="edit.deck.comments"/></p>
            <div className="form-group">
              <textarea
                className="form-control"
                value={comment}
                onChange={this.onCommentChange}
                rows="1"
                placeholder="Comment"
              />
            </div>
          </div>
        </form>}

        <Deck id={deckId}
              showSearch
              showSwitch
              deckFormat={format}
              inModal={this.props.inModal}
              supportCustomCards={this.props.supportCustomCards}
              userDeck={this.props.userDeck}
              showCardAmountInNav={!selectedFormat || selectedFormat.label !== 'Custom'} />

        {!btnFixed && this.renderButtons()}

        <SubmitDeckModalContainer isOpen={isModalSubmitDeckOpen}
                                  onSubmit={this.onSubmitDeckModalSubmit}
                                  onClose={this.onCloseDeckModalSubmit}
                                  deckFormat={selectedFormat.label}
                                  deckId={deckId}/>
      </div>
    );
  }

  render() {
    const {btnFixed} = this.props;

    if(btnFixed) {
      return (
        <div>
          <div className="create-decklist-modal">
            <Scrollbars
                autoHeight autoHeightMax={'70vh'}
                renderThumbVertical={props => <div {...props} className="scrollbar-primary"/>}
              >
            {this.renderContent()}
            </Scrollbars>
          </div>

          <div className="btn-decklist-fixed">
            {this.renderButtons()}
          </div>

        </div>
      );
    }

    return (
      this.renderContent()
    );
  }
}

CreateDeckList.propTypes = {
  deck: PropTypes.object,
  id: PropTypes.string.isRequired,
  decksPatch: PropTypes.object,
  patch: PropTypes.func.isRequired,
  formats: PropTypes.object,
  save: PropTypes.func.isRequired,
  saveDeck: PropTypes.object,
  history: PropTypes.object.isRequired,
  isDecksPatchLoad: PropTypes.bool,
  isCardSearchLoad: PropTypes.bool,
  isDecksCardLoad: PropTypes.bool,
  deckValidation: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  organizer: PropTypes.bool,
  unregistered: PropTypes.bool,
  disableFormat: PropTypes.bool,
  tournamentDecklist: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  deckNames: PropTypes.object,
  getDecknames: PropTypes.func,
  intl: PropTypes.object.isRequired,
  addNotification: PropTypes.func,
  btnFixed: PropTypes.bool,
  inModal: PropTypes.bool,
  supportCustomCards: PropTypes.bool,
  userDeck: PropTypes.bool,
  match: PropTypes.object
};

export default withRouter(injectIntl(CreateDeckList));
