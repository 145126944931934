import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import {getCountries, getStates} from '../../actions/politicalActions';
import {getAllStores} from '../../actions/storesActions';
import {getBioSchemas} from '../../actions/biographyActions';
import {createTournament, saveTournamentClear} from '../../actions/tournamentActions';
import {getAllTournamentTypesData} from '../../actions/tournamentTypes';

import TournamentForm from './TournamentForm';
import Spinner from '../Spinner';

class TournamentAdd extends React.Component {
  componentDidMount(){
    const {countries, getCountries, getAllStores, getBioSchemas, saveTournamentClear, getAllTournamentTypesData} = this.props;

    if(!countries.data){
      getCountries();
    }

    saveTournamentClear();
    getAllStores();
    getBioSchemas();
    getAllTournamentTypesData();
  }

  updateStates = (country) => {
    const {getStates} = this.props;

    getStates(country);
  };

  onSave = (data) => {
    const {createTournament} = this.props;

    const nextActions = [
      (data) => replace(`/tournaments/show/${data.id}`),
    ];

    createTournament(data, nextActions);
  };

  onCancel = () => {
    const {replace} = this.props;

    replace('/tournaments');
  };

  render() {
    const {states, countries, stores, bioSchemas, savedTournament, tournamentTypes} = this.props;

    if(
      !countries.data ||
      !stores.data ||
      !stores.data.data ||
      !tournamentTypes.isGettingAllSuccess
    ) {
      return <Spinner/>;
    }

    const tournament = {
      name:       null,
      passCode:   null,
      storeId:    null,
      startDate:  null,
      format:     null,
      type:       tournamentTypes.types[0],
      bioSchema: {
        id:       null,
      },
      city:       null,
      state:      null,
      country:    null,
    };

    const errors = savedTournament.error ? savedTournament.error.response.data.extras : {};

    return (
      <TournamentForm tournament={tournament}
                      states={states.data || []}
                      countries={countries.data}
                      stores={stores.data.data}
                      types={tournamentTypes.types}
                      formats={tournamentTypes.formats.data}
                      bioSchemas={bioSchemas.data}
                      errors={errors}
                      updateStates={this.updateStates}
                      onSave={this.onSave}
                      onCancel={this.onCancel}/>
    );
  }
}

TournamentAdd.propTypes = {
  countries: PropTypes.object.isRequired,
  states: PropTypes.object.isRequired,
  stores: PropTypes.object.isRequired,
  bioSchemas: PropTypes.object.isRequired,
  savedTournament: PropTypes.object.isRequired,
  tournamentTypes: PropTypes.object.isRequired,

  getCountries: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
  getAllStores: PropTypes.func.isRequired,
  getBioSchemas: PropTypes.func.isRequired,
  createTournament: PropTypes.func.isRequired,
  saveTournamentClear: PropTypes.func.isRequired,
  getAllTournamentTypesData: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    countries: state.countries,
    states: state.states,
    stores: state.storesAll,
    bioSchemas: state.bioSchemas,
    savedTournament: state.savedTournament,
    tournamentTypes: state.tournamentTypes,
  }),
  ({
    getCountries,
    getStates,
    getAllStores,
    getBioSchemas,
    createTournament,
    saveTournamentClear,
    getAllTournamentTypesData,
    replace,
  })
)(TournamentAdd);
