import React from 'react';
import PropTypes from 'prop-types';

import TournamentStandings from '../Standings/TournamentStandings';

class TournamentTeamStandings extends React.Component {
  render() {
    const {tournament} = this.props;

    return (
      <TournamentStandings type={'team'} tournament={tournament} />
    );
  }
}

TournamentTeamStandings.propTypes = {
  tournament: PropTypes.object,
};

export default TournamentTeamStandings;