import React from 'react';
import TabsWrapperContext from './TabsWrapperContext';

class TabsContent extends React.Component {
  renderContent(contextData) {
    const {index, data} = contextData;

    const Component = data[index].component;

    return <Component/>;
  }

  render() {
    return (
      <TabsWrapperContext.Consumer>
        {
          contextData => this.renderContent(contextData)
        }
      </TabsWrapperContext.Consumer>
    );
  }
}

export default TabsContent;
