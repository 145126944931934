import {TOURNAMENT_TEAM_DECK_FORMATS, TOURNAMENT_TEAM_DECK_FORMATS_NO_REGISTER} from './types';

export const getTournamentTeamDeckFormats = (format) => ({
  type: TOURNAMENT_TEAM_DECK_FORMATS.GET.START,
  payload: {
    format,
  }
});

export const getTournamentTeamDeckFormatsNoRegister = (format) => ({
  type: TOURNAMENT_TEAM_DECK_FORMATS_NO_REGISTER.GET.START,
  payload: {
    format,
  }
});

export const tournamentTeamDeckFormatReset = () => ({
  type: TOURNAMENT_TEAM_DECK_FORMATS.RESET,
});
