import md5 from 'md5';
import defaultAvatar from '../assets/images/avatar.png';

export const getGravatarLink = email => {
  const emailHashed = md5(email);
  const url = window.location.origin;

  const d = process.env.NODE_ENV === 'development' ? '' : `${url}${defaultAvatar}`;

  return (
    `https://www.gravatar.com/avatar/${emailHashed}?d=${d}`
  );
};
