import React from 'react';
import PropTypes from 'prop-types';
import {ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

import ModalWithNotifier from '../Generic/Modals/ModalWithNotifier';
import extension from '../../assets/images/extension.png';

import './DeckOnLivestreamModal.scss';

class DeckOnLivestreamModal extends React.Component {
  renderModalBody() {
    return (
      <React.Fragment>
        <h6><FormattedMessage id="personalToken.modal.instruction.header"/></h6>

        <ol>
          <li><FormattedHTMLMessage id="personalToken.modal.instruction.p1"/></li>
          <li><FormattedHTMLMessage id="personalToken.modal.instruction.p2"/></li>
          <li>
            <FormattedHTMLMessage id="personalToken.modal.instruction.p3"/>
            <div className="extension-image-container">
              <img src={extension} className="extension-image" alt=""/>
            </div>
          </li>
          <li><FormattedHTMLMessage id="personalToken.modal.instruction.p4"/></li>
        </ol>
      </React.Fragment>
    );
  }

  render() {
    const {isOpen, onClose} = this.props;

    return (
      <ModalWithNotifier isOpen={isOpen} toggle={onClose} className="deck-on-livestream-modal">
        <ModalHeader>
          <FormattedMessage id="personalToken.modal.title"/>
        </ModalHeader>
        <ModalBody>
          {this.renderModalBody()}
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-success" onClick={onClose}><FormattedMessage id="personalToken.modal.submit"/></button>
        </ModalFooter>
      </ModalWithNotifier>
    );
  }
}

DeckOnLivestreamModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeckOnLivestreamModal;
