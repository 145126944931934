import { CHANGE_PASSWORD } from '../../actions/types';

const initialState = {
  error: null,
  isLoading: false,
  isError: false,
  success: false,
};

export default (state=initialState, action) => {
  switch(action.type) {
    case CHANGE_PASSWORD.START: 
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case CHANGE_PASSWORD.ERROR:
      return {
        ...state,
        error: action.payload,
        isError: true,
        isLoading: false,
      };
    default:
      return state;
  }
};