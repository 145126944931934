import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {reduce} from 'lodash';

import {getPlayerDeckFormat} from '../../../../../services/DeckFormat';

import DeckSelect from '../../../../DeckSelect';

import './ModifyTeam.scss';

class ModifyTeam extends React.Component {
  static defaultProps = {
    title: 'Add new team',
    submitText: 'Save',
    cancelText: 'Cancel',
    deleteText: 'Delete',
    render: (title, content, buttons) => {
      return (
        <React.Fragment>
          {ModifyTeam.defaultRenderTitle(title)}

          {content}
          {buttons}
        </React.Fragment>
      );
    }
  };

  static defaultRenderTitle(title) {
    if(title === false) {
      return null;
    }

    return (
      <div className="header">
        <h5 className="header-title">{title}</h5>
      </div>
    );
  }

  state = {
    editedData: {},
  };

  onChangeField = (e, playerIndex) => {
    const fieldName = e.target.name;
    const value = e.target.value;

    const data = {
      [fieldName]: value,
    };

    this.editPlayerData(playerIndex, data);
  };

  onChangeDeck = (value, playerIndex) => {
    const data = {
      deckId: value,
    };

    this.editPlayerData(playerIndex, data);
  };

  onSubmit = (e) => {
    const {onSubmit} = this.props;

    e.preventDefault();
    onSubmit(this.getPlayersDataWithoutEmpty());
  };

  onDelete = () => {
    const {onDelete} = this.props;

    onDelete();
  };

  editPlayerData(playerIndex, data) {
    this.setState(state => update(state, {
      editedData: {
        [playerIndex]: (item) => item ? {...item, ...data} : data
      }
    }));
  }

  getPlayersData() {
    const {players} = this.props;
    const {editedData} = this.state;

    return players.map((item, index) => {
      const editedItem = editedData[index];

      return editedItem
        ? {...item, ...editedItem}
        : item;
    });
  }

  getPlayersDataWithoutEmpty() {
    const data = this.getPlayersData();

    return Object.keys(data).reduce((res, playerIndex) => {
      const item = data[playerIndex];

      return {
        ...res,
        [playerIndex]: reduce(item, (prev, value, key) => {
          if(value === ''){
            return {
              ...prev,
              [key]: null,
            };
          }

          return {
            ...prev,
            [key]: value,
          };
        }, {})
      };
    }, {});
  }

  getItemErrors(index) {
    const {errors} = this.props;

    if(!errors) {
      return null;
    }
    if(!errors[index]) {
      return null;
    }

    return errors[index];
  }

  renderPlayerField(player, index, fieldName) {
    const {intl} = this.props;

    const itemErrors = this.getItemErrors(index);
    const error = itemErrors && itemErrors[fieldName]
      ? itemErrors[fieldName]
      : null;

    return (
      <div className="form-group">
        <label><FormattedMessage id={`tournament.teams.modifyModal.${fieldName}.label`}/></label>
        <input
          type="text"
          className="form-control"
          placeholder={intl.formatMessage({id: `tournament.teams.modifyModal.${fieldName}.placeholder`})}
          value={player[fieldName] || ''}
          name={fieldName}
          onChange={(e) => this.onChangeField(e, index)}
        />
        {
          error &&
          <small className="text-danger">{error}</small>
        }
      </div>
    );
  }

  renderDeckSelect(player, index) {
    const {intl, decks} = this.props;
    const fieldName = 'deckId';

    const itemErrors = this.getItemErrors(index);
    const error = itemErrors && itemErrors[fieldName]
      ? itemErrors[fieldName]
      : null;

    const options2 = decks[player.deckFormat].map(item => ({
      name: item.name,
      value: item.id,
      valid:  item.valid,
    }));

    return (
      <div className="form-group">
        <label><FormattedMessage id={`tournament.teams.modifyModal.${fieldName}.label`}/></label>

        <DeckSelect
          options={options2}
          value={player[fieldName] || ''}
          onChange={(value) => this.onChangeDeck(value, index)}
          placeholder={intl.formatMessage({id: `tournament.teams.modifyModal.${fieldName}.placeholder`})}
        />

        {
          error &&
          <small className="text-danger">{error}</small>
        }
      </div>
    );
  }

  renderPlayerRow = (player, index) => {
    const {decks} = this.props;

    return (
      <div className="team-player" key={index}>
        <div className="inputs">
          {this.renderPlayerField(player, index, 'firstName')}
          {this.renderPlayerField(player, index, 'lastName')}
          {this.renderPlayerField(player, index, 'dciNumber')}

          {
            decks && this.renderDeckSelect(player, index)
          }
        </div>
      </div>
    );
  };

  renderContent() {
    const playersData = this.getPlayersData();

    const deckFormats = getPlayerDeckFormat(playersData.map(elem => elem.deckFormat));
    const data = playersData.map((elem, index) => ({
      ...elem,
      deckFormatName: deckFormats[index],
    }));

    return data.slice(0,1).map(this.renderPlayerRow);
  }

  renderButtons() {
    const {onDelete, onCancel, submitText, deleteText, cancelText} = this.props;

    return (
      <div className="buttons-wrapper">
        {
          onDelete &&
          <button type="button" className="btn btn-danger mr-auto" onClick={onDelete}>
            {deleteText}
          </button>
        }
        <button type="button" className="btn btn-outline-secondary" onClick={onCancel}>
          {cancelText}
        </button>
        <button type="submit" className="btn btn-success">
          {submitText}
        </button>
      </div>
    );
  }

  render() {
    const {render, title} = this.props;

    const content = this.renderContent();
    const buttons = this.renderButtons();

    return (
      <form className="modify-team" onSubmit={this.onSubmit}>
        {render(title, content, buttons)}
      </form>
    );
  }
}

ModifyTeam.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([false]),
  ]),
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  deleteText: PropTypes.string,

  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,

  players: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    dciNumber: PropTypes.string,
    deckFormat: PropTypes.string.isRequired,
  })).isRequired,
  decks: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
  }))),

  errors: PropTypes.object,
  render: PropTypes.func,

  intl: PropTypes.object.isRequired,
};

export const propTypes = ModifyTeam.propTypes;
export const defaultProps = ModifyTeam.defaultProps;

export default injectIntl(ModifyTeam);
