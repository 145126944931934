import { createLogic } from 'redux-logic';
import uuid from 'uuid/v1';

import {NOTIFICATION} from '../actions/types';
import {remove} from '../actions/notificationActions';

export const add = createLogic({
  type: NOTIFICATION.ADD,

  transform({ action }, next) {
    next({
      ...action,
      payload: {
        ...action.payload,
        id: uuid(),
      }
    });
  },

  process({action, getState}, dispatch, done) {
    const data = action.payload;
    const messages = getState().notification.messages;

    if(data.key) {
      messages.forEach(message => {
        if(message.key === data.key && message.id !== data.id){
          dispatch(remove(message.id));
        }
      });
    }

    setTimeout(() => {
      dispatch(remove(data.id));

      done();
    }, data.timeout * 1000);
  }
});

export default {
  add,
};
