import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from 'react-intl';

import ModalConnector from '../Generic/Modals/ModalConnector';
import Prompt from '../Generic/Modals/Prompt';

import names from './names';
import {leaveTeamTournament} from '../../actions/leaveTeamTournament';

class LeaveTeamTournamentModal extends React.Component {
  onSubmit = (data) => {
    const {teamId} = data;

    this.props.leaveTeamTournament(teamId);
  };

  render() {
    const {intl} = this.props;

    return (
      <ModalConnector name={names.LEAVE_TEAM_TOURNAMENT}>
        {
          ({isOpen, data, onClose}) => {
            return (
              <Prompt
                isOpen={isOpen}
                title={intl.formatMessage({id: 'tournament.modal.remove'})}
                submitClassName="btn-danger"
                closeOnSubmit={false}
                onClose={onClose}
                onSubmit={() => this.onSubmit(data)}
              >
                <FormattedMessage id="tournament.modal.remove.confirmationTeam"/>
              </Prompt>
            );
          }
        }
      </ModalConnector>
    );
  }
}

LeaveTeamTournamentModal.propTypes = {
  intl: PropTypes.object.isRequired,

  leaveTeamTournament: PropTypes.func.isRequired,
};

export default injectIntl(connect(
  null,
  {
    leaveTeamTournament,
  }
)(LeaveTeamTournamentModal));
