import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default class DciNumber extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lengthValid: false,
      playerOrJudge: false,
      isValid: true,
      isSameAsInitial: true,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      playerOrJudge: nextProps.roles.player || nextProps.roles.judge,
      lengthValid: nextProps.value.length >= 1,
    };
  }

  componentDidUpdate() {
    const { lengthValid, playerOrJudge, isValid } = this.state;
    const shouldBeValid = !playerOrJudge || (playerOrJudge && lengthValid);
    if (isValid !== shouldBeValid) {
      this.changeValidation(shouldBeValid);
    }
  }

  changeValidation(value) {
    if (this.state.isValid !== value) {
      this.props.onValidationChanged(value);
    }
    this.setState({
      isValid: value
    });
  }

  validateName(value) {
    if (value.length >= 1) {
      return this.setState({
        lengthValid: true,
        isSameAsInitial: !(value !== this.props.initialValue),
      });
    }
    this.setState({
      lengthValid: false
    });
  }

  handleChange(value) {
    this.props.onChange(value);
    this.validateName(value);
  }

  renderValidation() {
    const { lengthValid, isValid, isSameAsInitial } = this.state;
    const { isRequired } = this.props;

    if (isValid && lengthValid && isRequired && !isSameAsInitial) {
      return <div className="valid-feedback"><FormattedMessage id="label.edited"/></div>;
    }
    if(!isRequired && !lengthValid) {
      return <div className="valid-feedback"><FormattedMessage id="input.invalid"/></div>;
    }
    if(!isValid && !lengthValid) {
      return <div className="invalid-feedback"><FormattedMessage id="input.empty.error"/></div>;
    }
  }

  render() {
    const { lengthValid, isValid, isSameAsInitial } = this.state;
    const { isRequired } = this.props;

    const valid = lengthValid && isValid && isRequired && !isSameAsInitial;
    const invalid = !lengthValid && !isValid;

    const inputClass = classNames('form-control', 'register-form__input', {
      'is-valid': valid,
      'is-invalid': invalid,
    });

    return (
      <React.Fragment>
        <input
          type="text"
          className={inputClass}
          value={this.props.value}
          onChange={e => this.handleChange(e.target.value)}
        />
        {this.renderValidation()}
      </React.Fragment>
    );
  }
}

DciNumber.propTypes = {
  onValidationChanged: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  initialValue: PropTypes.string.isRequired,
};
