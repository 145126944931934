import * as React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {connect} from 'react-redux';
import Select from 'react-select';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import {Link} from 'react-router-dom';

import Tournament from '../../../services/Tournament';
import {getTournamentsPlayer} from '../../../actions/tournamentActions';
import {fetch, fetchUserDecks} from '../../../actions/deckActions';
import GenericTooltip from '../../GenericTooltip';
import DataTableWithMobileDropdown from '../../DataTable/DataTableWithMobileDropdown';
import GoToEditDeckButton from '../../Utils/GoToEditDeckButton';
import PaginationComponent from '../../Pagination/Pagination';
import { add as addNotification } from '../../../actions/notificationActions';
import { filterOptions } from '../../const';
import Spinner from '../../Spinner';
import Search from '../../Search';
import DateFormatted from '../../DateFormatted/DateFormatted';
import ModalSelectTournamentPlayerBio from '../ModalSelectTournamentPlayerBio';
import { getErrorMessage } from '../../../services/SubmitDeck';
import './TournamentList.scss';
import StatusDot from '../StatusDot';
import {getSortingState} from '../../../services/Sorting';

import * as ModalActions from '../../../actions/modal';
import modalNames from '../../modals/names';

class TournamentListPlayerTable extends React.Component {
  state = {
    isOpen: false,
    isRemoveModalOpen: false,
    selectedDeck: null,
    selectedTournamentId: null,
    memberId: null,
    passCode: '',
    error: null,
    selectedFilter: null,
    searchQuery: null,
    sorting: null,
    modalSelectBio: {
      isOpen: false,
      data: null,
    },
    playerId: null,
  };

  componentDidMount() {
    this.getTournaments(this.props.currentPage);

    if(this.props.userId){
      this.props.fetchUserDecks(this.props.userId);
    } else {
      this.props.fetch();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {sorting} = this.state;
    const sortColumn = sorting && sorting.actualSortedKey;
    const sortType = sorting && sorting.type;
    if (prevProps.currentPage !== this.props.currentPage && !prevState.selectedFilter) {
      this.props.getTournamentsPlayer(this.props.currentPage, this.props.userId, null, null, null, sortColumn, sortType);
    }

    if (prevProps.currentPage !== this.props.currentPage && prevState.selectedFilter) {
      const typeArray = this.getFiltersByGroup('type', prevState.selectedFilter);
      const statusArray = this.getFiltersByGroup('status', prevState.selectedFilter);
        this.props.getTournamentsPlayer(this.props.currentPage, this.props.userId, this.state.searchQuery, typeArray, statusArray, sortColumn, sortType);
    }
  }

  getTournaments = (page, searchQuery=this.state.searchQuery, sorting=this.state.sorting) => {
    const sortColumn = sorting && sorting.actualSortedKey;
    const sortType = sorting && sorting.type;

    const typeArray = this.getFiltersByGroup('type', this.state.selectedFilter);
    const statusArray = this.getFiltersByGroup('status', this.state.selectedFilter);

    this.props.getTournamentsPlayer(page, this.props.userId, searchQuery, typeArray, statusArray, sortColumn, sortType);
  }


  submit = () => {
    const {selectedDeck, selectedTournamentId, playerId} = this.state;
    const {intl} = this.props;

    Tournament.registerDeck(selectedTournamentId, selectedDeck)
      .then(() => {
        this.getTournaments(1);
        this.toggleModal();
        this.props.addNotification({
          text: <FormattedMessage id="deck.submit.success"/>,
          type: 'success',
          timeout: 4,
        });

        this.openModalSelectBio({
          tournamentId: selectedTournamentId,
          playerId: playerId,
        });
      })
      .catch(err => {

        const key = getErrorMessage(err.response);

        this.setState({
          error: intl.messages[key] || err.response.data.error_description
        });
      });
  }

  toggleModal = (e, selectedTournament, playerId) => {
    e && e.stopPropagation();
    this.setState({
      isOpen: !this.state.isOpen,
      selectedTournamentId: selectedTournament,
      error: null,
      playerId: playerId,
    });
  };

  goToOverview(id) {
    this.props.history.push(`/tournaments/show/${id}/info`);
  }

  openLeaveTeamTournamentModal(e, teamId) {
    e.stopPropagation();

    this.props.openModal(modalNames.LEAVE_TEAM_TOURNAMENT, {
      teamId,
    });
  }

  renderButton = (item, index, isMobile) => {
    if(item.status !== 'not_started' && !this.props.userId){
      return null;
    }

    if(item.type === 'Team') {
      return (
        <Link
          className="btn btn-sm btn-warning"
          to={`/team/edit/${item.teams[0].id}`}
          onClick={(e) => e.stopPropagation()}
        >
          <FormattedMessage id="tournaments.btn.edit"/>
        </Link>
      );
    }

    if(item.members[0].deck) {
      return (
        <GoToEditDeckButton key={index + '-edit'} history={this.props.history} deckId={item.members[0].deck.id}>
          <span className={isMobile ? 'tournament-link tournament-link-mobile' : 'btn btn-sm btn-warning'}><FormattedMessage id="tournaments.btn.edit"/></span>
        </GoToEditDeckButton>
      );
    }

    return (
      <span key={index + '-register'}
            className={isMobile ? 'tournament-link tournament-link-mobile' : 'btn btn-sm btn-primary'}
            onClick={(e) => this.toggleModal(e, item.id, item.members[0].id)}>
        <FormattedMessage id="tournaments.btn.submit"/>
      </span>
    );
  };

  renderDeleteButton = (item, index, isMobile) => {
    if(!this.props.userId && (item.status !== 'not_started')) {
      return null;
    }

    const onLeaveIndividual = (e) => this.toggleRemoveModal(e, item.members[0].id);
    const onLeaveTeam       = (e) => this.openLeaveTeamTournamentModal(e, item.teams[0].id);

    const onClick = (item.type === 'Team') ? onLeaveTeam : onLeaveIndividual;

    if(isMobile){
      return (
        <span key={index + '-remove'} className="tournament-link tournament-link-mobile" onClick={onClick}>
          {this.props.userId ? 'Delete' : 'Leave'}
        </span>
      );
    } else {
      return (
        <div key={index + '-remove'} className={this.props.userId ? 'text-center' : 'btn btn-sm btn-danger' } onClick={onClick}>
          {this.props.userId ? <i className="icon-delete"/> : <i className="icon-x"/>}
        </div>
      );
    }
  }

  toggleRemoveModal = (e, memberId) => {
    e && e.stopPropagation();
    this.setState({
      isRemoveModalOpen: !this.state.isRemoveModalOpen,
      memberId: memberId,
      error: null,
    });
  }

  removeFromTournament = () => {
    Tournament.deregisterFromTournament(this.state.memberId)
      .then(()=>{
        this.getTournaments(1);
        this.toggleRemoveModal(null, null);
        this.props.addNotification({
          text: <FormattedMessage id="deregister.from.tournament.success"/>,
          type: 'success',
          timeout: 4,
        });
      })
      .catch(err => {
        this.setState({
          error: err.response.data && err.response.data.error_description
        },()=>{
          this.props.addNotification({
            text: <FormattedMessage id="deregister.from.tournament.error"/>,
            type: 'danger',
            timeout: 4,
          });
        });
      });
  }

  renderError = (item) => {
    if(item.type === 'Team') {
      return null;
    }

    if(item.members[0].deck) {
      return null;
    }
    return <GenericTooltip id={`option-${item.id}`} placement={'left'} message={'Deck missing'}/>;
  }

  onSort = (sortedKey) => {
    const {sorting, searchQuery} = this.state;
    const sort = getSortingState(sorting, sortedKey);

    this.setState({
      sorting: sort
    });

    this.getTournaments(this.props.currentPage, searchQuery, sort);
  };

  renderTournamentsList = () => {
    const {sorting} = this.state;
    const tournaments = this.props.tournamentsPlayer.data;

    if(!tournaments || this.props.tournamentsPlayer.isLoad || (!this.props.userDecks.data && !this.props.decks.data)){
      return <Spinner/>;
    }

    if (!tournaments || tournaments.data.length < 1) {
      return <div className="text-center"><FormattedMessage id="tournaments.no_tournaments"/></div>;
    }

    const columns = [
      {name: 'ID', flex: 1, className: 'col-id', isVisibleOnMobile: false},
      {name: 'NAME', sortedKey: 'name', flex: 2, className: 'col-name'},
      {name: 'TYPE', flex: 1},
      {name: 'DATE', sortedKey: 'startDate', flex: 1},
      {name: 'CREATED AT', sortedKey: 'createdAt', flex: 2},
      {name: 'STATUS', flex: 1, isVisibleOnMobile: false},
      {name: 'DECKLIST', flex: 2, isVisibleOnMobile: false},
      {name: this.props.userId ? 'DELETE' : 'LEAVE', flex: 2, isVisibleOnMobile: false},
    ];

    const data = tournaments.data.map((item, index) => {
      return [
        <span key={index + '-index'}><span className="tournament-validation-err">{this.renderError(item)}</span><span className="tournamet-row-id">{`#${item.id.split('-')[0]}`}</span></span> ,
        <span key={index + '-name'}>
          <span className="tournament-validation-err d-md-none">{this.renderError(item)}</span>
          <StatusDot status={item.status} className={'d-md-none'}/>
          {item.name}
        </span>,
        item.type ? <FormattedMessage id={`tournament_type.${item.type}`}/> : '-',
        <DateFormatted key={index + '-date'} value={item.startDate}/>,
        <DateFormatted key={index + '-created'} value={item.createdAt}/>,
        <div key={index + '-status'} className="text-center"><StatusDot status={item.status}/></div>,
        this.renderButton(item, index),
        this.renderDeleteButton(item, index)
      ];
    });

    const dropdownData = (dataRow, rowIndex) => [
      this.renderButton(tournaments.data[rowIndex], rowIndex, true),
      this.renderDeleteButton(tournaments.data[rowIndex], rowIndex, true)
    ];

    return (
        <DataTableWithMobileDropdown
          columns={columns}
          data={data}
          onRowClick={(nr) => this.goToOverview(tournaments.data[nr].id)}
          dropdownData={dropdownData}
          onSort={this.onSort}
          sortedOptions={sorting}
        />
    );
  }

  selectDeck = (selected) => {
    this.setState({
      selectedDeck: selected.value
    });
  }

  renderDeckOption(option) {
    return (
      <div>
        {option.label} <small>{option.format}</small>

        {
          !option.valid &&
          <div className="float-right">
            <GenericTooltip id={`option-${option.value}`} placement={'left'} message={'This deck is not valid'}/>
          </div>
        }
      </div>
    );
  }

  renderDeckValue(option) {
    return (
      <div>
        {option.label} <small>{option.format}</small>

        {
          !option.valid &&
          <div className="float-right" style={{paddingRight: 20}}>
            <GenericTooltip id={`value-${option.value}`} placement={'left'} message={'This deck is not valid'}/>
          </div>
        }
      </div>
    );
  }

  renderFilterOption(option) {
    return (
      <div className={classnames('react-select-group__item',
        { 'react-select__group__item--is-disabled': option.disabled }
      )}>
        {option.group === 'status' &&
          <span className={`tournament-status-dot mr-2 tournament-status-dot--${option.status}`}></span>}
        {option.label}
      </div>
    );
  }

  renderFilterValue(option) {
    return (
      <div>
        {option.group === 'status' &&
          <span className={`tournament-status-dot mr-2 tournament-status-dot--${option.status}`}></span>}
        {option.label}
      </div>
    );
  }

  getFiltersByGroup(group, values) {
    if (!values || values.length === 0) {
      return null;
    }

    return values
      .filter(item => item.group === group)
      .map(item => item.value);
  }

  selectFilter = (selected) => {
    this.setState({
      selectedFilter: selected
    });

    const typeArray = this.getFiltersByGroup('type', selected);
    const statusArray = this.getFiltersByGroup('status', selected);

    this.props.history.push('/tournaments/1');
    this.props.getTournamentsPlayer(1, this.props.userId, this.state.searchQuery, typeArray, statusArray);
  }

  search = (searchQuery) => {
    const page = searchQuery && searchQuery.length >= 3 ? 1 : this.props.currentPage;
    if(searchQuery !== this.state.searchQuery){
      this.getTournaments(page, searchQuery);
    }
    this.setState({
      searchQuery
    });
  }

  closeModalSelectBio = () => {
    this.setState({
      modalSelectBio: {
        isOpen: false,
        data: null,
      }
    });
  };

  openModalSelectBio = ({tournamentId, playerId}) => {
    this.setState({
      modalSelectBio: {
        isOpen: true,
        data: {
          tournamentId,
          playerId,
        },
      }
    });
  };

  renderPagination() {
    const {tournamentsPlayer} = this.props;
    const {searchQuery} = this.state;

    if(!tournamentsPlayer.data || searchQuery){
      return null;
    }

    const total = tournamentsPlayer.data && tournamentsPlayer.data.headers && tournamentsPlayer.data.headers['x-last-page'];
    const current = this.props.currentPage ? this.props.currentPage : tournamentsPlayer.data && tournamentsPlayer.data.headers && tournamentsPlayer.data.headers['x-page'];

    return <PaginationComponent totalPages={total} currentPage={current} onChangePage={this.props.currentPage ? null : this.getTournaments} url={'/tournaments/:page'}/>;
  }

  render() {
    const {isOpen, isRemoveModalOpen, selectedDeck, error, selectedFilter, modalSelectBio} = this.state;
    const {tournamentsPlayer} = this.props;
    
    if(!tournamentsPlayer){
      return null;
    }

    const decks = (this.props.userId) ? this.props.userDecks.data : this.props.decks.data;

    const deckList = decks && decks.map(item => {
      const format = item.format && item.format.name;

      return {
        label: item.name,
        value: item.id,
        valid: item.valid,
        format
      };
    });

    return (
        <div>
          <div className={'row' + (this.props.userId ? ' d-none' : '')}>
            <div className="mb-3 col-md-8">
              <Search onUpdate={this.search} />
            </div>

            <div className="col-md-3 offset-md-1">
              <Select
                className="react-select-group"
                value={selectedFilter}
                options={filterOptions}
                onChange={this.selectFilter}
                optionRenderer={this.renderFilterOption}
                valueRenderer={this.renderFilterValue}
                multi
                placeholder="Default"
              />
            </div>
          </div>
          <div className="tournament-list mt-3">
            <div className="tournament-list-table-wrapper">
              {this.renderTournamentsList()}
            </div>
            <div className="pagination-wrapper">
              {this.renderPagination()}
            </div>
          </div>

          <Modal isOpen={isOpen} toggle={(e) => this.toggleModal(e, null)}>
            <ModalHeader toggle={(e) => this.toggleModal(e, null)}><FormattedMessage id="tournament.modal.select.deck"/></ModalHeader>
            <ModalBody>
              <Select
                id="state-select"
                options={deckList}
                optionRenderer={this.renderDeckOption}
                valueRenderer={this.renderDeckValue}
                value={selectedDeck}
                onChange={this.selectDeck}
                searchable={false}
                clearable={false}
              />
              <div className="text-danger mt-2">{error}</div>
            </ModalBody>
            <ModalFooter>
              <button onClick={(e) => this.toggleModal(e, null)} className="btn btn-outline-secondary"><FormattedMessage id="tournament.modal.cancel"/></button>
              <button disabled={!selectedDeck} onClick={this.submit} className="btn btn-success"><FormattedMessage id="tournament.modal.select"/></button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={isRemoveModalOpen} toggle={(e) => this.toggleRemoveModal(e, null)}>
            <ModalHeader toggle={(e) => this.toggleRemoveModal(e, null)}><FormattedMessage id="tournament.modal.remove"/></ModalHeader>
            <ModalBody>
              <FormattedMessage id="tournament.modal.remove.confirmation"/>
              <div className="text-danger mt-2">{error}</div>
            </ModalBody>
            <ModalFooter>
              <button className="btn btn-danger" onClick={this.removeFromTournament}><FormattedMessage id="tournament.delete.modal.submit"/></button>
              <button className="btn btn-outline-secondary" onClick={(e) => this.toggleRemoveModal(e, null)}><FormattedMessage id="tournament.delete.modal.cancel"/></button>
            </ModalFooter>
          </Modal>

          {
            modalSelectBio.isOpen &&
            <ModalSelectTournamentPlayerBio isOpen={modalSelectBio.isOpen}
                                            tournamentId={modalSelectBio.data.tournamentId}
                                            playerId={modalSelectBio.data.playerId}
                                            onClose={this.closeModalSelectBio}/>
          }
        </div>
    );
  }
}

TournamentListPlayerTable.propTypes = {
  history: PropTypes.object,
  getTournamentsPlayer: PropTypes.func.isRequired,
  tournamentsPlayer: PropTypes.object,
  fetch: PropTypes.func,
  fetchUserDecks: PropTypes.func,
  decks: PropTypes.object,
  userDecks: PropTypes.object,
  userId: PropTypes.string,
  currentPage: PropTypes.string,
  addNotification: PropTypes.func,
  openModal: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(connect(
  (state) => ({
    tournamentsPlayer: state.tournamentsPlayer,
    decks: state.decks,
    userDecks: state.userDecks,
  }),
  ({
    getTournamentsPlayer,
    fetch,
    fetchUserDecks,
    addNotification,
    openModal: ModalActions.open
  })
)(TournamentListPlayerTable));
