import React from 'react';
import PropTypes from 'prop-types';

import AccessControl from '../../../Utils/AccessControl';
import Players from '../../Players';

import {roles} from '../../../const';

class TournamentPlayers extends React.Component {
  render() {
    const {tournament} = this.props;

    return (
      <AccessControl roles={[roles.organizer, roles.admin, roles.judge]}>
        <Players tournament={tournament}/>
      </AccessControl>
    );
  }
}

TournamentPlayers.propTypes = {
  tournament: PropTypes.object,
};

export default TournamentPlayers;
