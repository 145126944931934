import {TOURNAMENT_STANDINGS} from '../../actions/types';
import update from 'immutability-helper';

const initialState = {
  isPending: null,
  isError: false,
  data: {
    players: null,
    extras: null,
  },
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_STANDINGS.GET.START:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
      };
    case TOURNAMENT_STANDINGS.GET.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload,
      };
    case TOURNAMENT_STANDINGS.GET.SUCCESS:
      return {
        isPending: false,
        isError: false,
        error: null,
        data: action.payload,
      };
    case TOURNAMENT_STANDINGS.UPDATE.SUCCESS: {
      const {extras: updateExtras, players: updatePlayers} = action.payload;

      return update(state, {
        data: {
          extras: {
            $merge: updateExtras ? updateExtras : {}
          },
          players: {
            data: {
              $apply: (data) => data.map(item => {
                const updatePlayer = updatePlayers[item.id];

                if (updatePlayer) {
                  // API on update member request response different entry then
                  // on get member request.
                  //
                  // UPDATE REQUEST RESPONSE
                  // {(...) user: {id: INT}, tournament: {id: INT} }
                  //
                  // GET REQUEST RESPONSE
                  // {(...) user: {id: INT, avatar: STRING, (...many other fields)} } (No field tournament)
                  //
                  // To keep the same format od data in redux, don't merge this fields

                  // eslint-disable-next-line no-unused-vars
                  const {user, tournament, ...updatePlayerData} = updatePlayer;

                  return {
                    ...item,
                    ...updatePlayerData
                  };
                }

                return item;
              })
            }
          }
        }
      });
    }
    case TOURNAMENT_STANDINGS.ADD:
      return {
        ...state,
        data: {
          ...state.data,
          players: {
            ...state.data.players,
            data: [{...action.payload}, ...state.data.players.data]
          }
        }
      };
    case TOURNAMENT_STANDINGS.DELETE_ROW:
      return {
        ...state,
        data: {
          ...state.data,
          players: {
            ...state.data.players,
            data: state.data.players.data.filter(item => item.id !== action.payload)
          }
        }
      };
    case TOURNAMENT_STANDINGS.DELETE.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          players: {
            ...state.data.players,
            data: state.data.players.data.filter(item => item.id !== action.payload.standingId)
          }
        }
      };
    default:
      return state;
  }
};
