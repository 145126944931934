import {createLogic} from 'redux-logic';

import {JOIN_TEAM_TOURNAMENT} from '../actions/types';
import {closeActualOpened} from '../actions/modal';
import {getTournamentsPlayer} from '../actions/tournamentActions';

import {parseArray} from '../services/validationHelpers';
import {push} from 'react-router-redux';

export const start = createLogic({
  type: JOIN_TEAM_TOURNAMENT.START,
  latest: true,

  processOptions: {
    successType: JOIN_TEAM_TOURNAMENT.SUCCESS,
    failType: JOIN_TEAM_TOURNAMENT.ERROR,
  },

  process({httpClient, action}) {
    const {passCode, team} = action.payload;

    return httpClient.post(`/api/v1/team_register/${passCode}`, team)
      .then(res => res.data)
      .catch(err => Promise.reject(parseArray(err.response.data.extras)));
  }
});

export const success = createLogic({
  type: JOIN_TEAM_TOURNAMENT.SUCCESS,

  process({genericActions}, dispatch, done) {
    dispatch(closeActualOpened());

    dispatch(push('/tournaments/1'));
    dispatch(getTournamentsPlayer(1));

    dispatch(genericActions.notification.add({
      type: 'success',
      intlKey: 'tournament.joined.success',
    }));

    done();
  }
});

export default [
  start,
  success,
];
