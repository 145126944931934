import {ADMIN_STATISTICS} from '../actions/types';

const getBasicState = (params = {}) => {
  return {
    isLoad: false,
    isError: false,
    error: null,
    data: null,
    params,
  };
};

const initialState = {
  users: getBasicState(),
  stores: getBasicState(),
  decks: getBasicState(),
  tournaments: getBasicState(),
  tournament_locations: getBasicState(),
  tournament_formats: getBasicState(),
  formats_cards: getBasicState({
    formatId: null
  }),
  deck_names: getBasicState({
    formatId: null
  }),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_STATISTICS.FETCH:
      return {
        ...state,
        [action.payload.name]: {
          isLoad: true,
          isError: false,
          error: null,
          data: action.payload.isRemove ? null : state[action.payload.name].data,
          params: action.payload.params,
        }
      };
    case ADMIN_STATISTICS.UPDATE:
      return {
        ...state,
        [action.payload.name]: {
          isLoad: false,
          isError: false,
          error: null,
          data: action.payload.data,
          params: action.payload.params,
        }
      };
    case ADMIN_STATISTICS.UPDATE_ERROR:
      return {
        ...state,
        [action.payload.name]: {
          isLoad: false,
          isError: true,
          error: action.payload.data,
          data: null,
          params: action.payload.params,
        }
      };
    default:
      return state;
  }
};
