import React from 'react';
import {Redirect} from 'react-router-dom';

import AccessControl from '../../Utils/AccessControl';
import AdminMetricsContainer from './AdminMetricsContainer';
import OrganizerMetricsContainer from './OrganizerMetricsContainer';

import {roles} from '../../const';

class MetricsContainer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <AccessControl roles={[roles.admin]}>
          <AdminMetricsContainer/>
        </AccessControl>
        <AccessControl roles={[roles.organizer]}>
          <OrganizerMetricsContainer/>
        </AccessControl>
        <AccessControl roles={[roles.judge, roles.player]}>
          <Redirect to={'/'}/>
        </AccessControl>
      </React.Fragment>
    );
  }
}

export default MetricsContainer;
