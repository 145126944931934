import { CARD_PREVIEW } from './types';

export const getCardPreview = (cardId) => ({
  type: CARD_PREVIEW.FETCH,
  id: cardId
});

export const clearCardPreview = () => ({
  type: CARD_PREVIEW.CLEAR
});

