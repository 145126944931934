import { SET_DECKNAME_FORMAT } from '../actions/types';

const initialState = { value: 1, label: 'Standard' };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DECKNAME_FORMAT:
      return action.payload;
    default:
      return state;
  }
};
