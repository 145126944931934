import React from 'react';
import PropTypes from 'prop-types';
import TabsWrapperContext from './TabsWrapperContext';

class TabsWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: props.index,
      data: props.data,
      onChangeIndex: this.changeIndex,
    };
  }

  changeIndex = (index) => {
    const {onChangeIndex} = this.props;

    this.setState({
      index,
    });

    if(onChangeIndex){
      onChangeIndex(index);
    }
  };

  render() {
    return (
      <TabsWrapperContext.Provider value={this.state}>
        {this.props.children}
      </TabsWrapperContext.Provider>
    );
  }
}

TabsWrapper.propTypes = {
  index: PropTypes.number.isRequired,
  onChangeIndex: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        component: PropTypes.node.isRequired,
      }),
      PropTypes.object,
    ])
  ),
  children: PropTypes.node.isRequired,
};

export default TabsWrapper;
