import * as React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Tournament from '../../../services/Tournament';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {getAllTournaments, deleteTournament} from '../../../actions/tournamentActions';
import Pagination from '../../Pagination/Pagination';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from 'react-select';
import Spinner from '../../Spinner';
import {getSortingState} from '../../../services/Sorting';
import { filterOptions } from '../../const';
import DataTableWithMobileDropdown from '../../DataTable/DataTableWithMobileDropdown';
import DateFormatted from '../../DateFormatted/DateFormatted';

import './TournamentList.scss';
import StatusDot from '../StatusDot';

class TournamentListAdmin extends React.Component {
  state = {
    isOpen: false,
    deleteId: null,
    searchValue: '',
    selectedFilter: null,
    sorting: null
  };

  componentDidMount() {
    this.props.getAllTournaments(this.props.currentPage);
  }

  componentDidUpdate(prevProps, prevState) {
    const {sorting} = this.state;

    const sortColumn = sorting && sorting.actualSortedKey;
    const sortType = sorting && sorting.type;

    if (prevProps.currentPage !== this.props.currentPage && !prevState.selectedFilter) {
      this.props.getAllTournaments(this.props.currentPage, null, null, null, sortColumn, sortType);
    }

    if (prevProps.currentPage !== this.props.currentPage && prevState.selectedFilter) {
      const typeArray = this.getFiltersByGroup('type', prevState.selectedFilter);
      const statusArray = this.getFiltersByGroup('status', prevState.selectedFilter);
      const searchValue = prevState.searchValue || null;

      this.props.getAllTournaments(this.props.currentPage, searchValue, typeArray, statusArray, sortColumn, sortType);
    }
  }

  addTournament = () => {
    Tournament.createTournament().then(res => {
      this.props.history.push(`/tournaments/show/${res.data.id}`);
    });
  }

  submit = () => {
    this.props.deleteTournament(this.state.deleteId, 'ADMIN');
    this.toggleModal(null, null);
  }

  toggleModal = (e, id) => {
    e && e.stopPropagation();

    this.setState({
      isOpen: !this.state.isOpen,
      deleteId: id
    });
  }

  goToEdit(e, id) {
    e && e.stopPropagation();
    this.props.history.push(`/tournaments/show/${id}/info/edit`);
  }

  goToOverview(id) {
    this.props.history.push(`/tournaments/show/${id}/info`);
  }

  onSort = (sortedKey) => {
    const {sorting, selectedFilter, searchValue} = this.state;
    const sort = getSortingState(sorting, sortedKey);

    const sortColumn = sort && sort.actualSortedKey;
    const sortType = sort && sort.type;

    const typeArray = this.getFiltersByGroup('type', selectedFilter);
    const statusArray = this.getFiltersByGroup('status', selectedFilter);

    this.setState({
      sorting: sort
    });
    
    this.props.getAllTournaments(this.props.currentPage, searchValue || null, typeArray, statusArray, sortColumn, sortType);
  };

  renderTournamentsList = () => {
    const {sorting} = this.state;
    const {data: tournaments} = this.props.tournaments;

    if(!this.props.tournaments.data || this.props.tournaments.isLoad){
      return <Spinner/>;
    }

    if (!tournaments || tournaments.data.length < 1) {
      return <div className="text-center"><FormattedMessage id="tournaments.no_tournaments"/></div>;
    }

    const columns = [
      {name: 'ID', flex: 1, key: 1},
      {name: 'NAME', sortedKey: 'name', flex: 2, key: 2},
      {name: 'TYPE', flex: 1, key: 3},
      {name: 'DATE', sortedKey: 'startDate', flex: 1, key: 4},
      {name: 'CREATED AT', sortedKey: 'createdAt', flex: 2, key: 5},
      {name: 'STATUS', flex: 1, key: 6},
      {name: 'EDIT', flex: 2, key: 7, isVisibleOnMobile: false},
      {name: 'DELETE', flex: 2, key: 8, isVisibleOnMobile: false}
    ];

    const data = tournaments.data.map((item, index) => {
      return [
        `#${item.id.split('-')[0]}`,
        item.name,
        item.type ? <FormattedMessage id={`tournament_type.${item.type}`}/> : '-',
        <DateFormatted key={index + '-date'} value={item.startDate}/>,
        <DateFormatted key={index + '-created'} value={item.createdAt}/>,
        <div key={index + '-status'} className="text-center">
          <StatusDot status={item.status}/>
        </div>,
        <div
          key={index + '-edit'}
          className="tournament-link text-center"
          onClick={(e) => this.goToEdit(e, item.id)}
        >
          <i className="icon-edit"/>
        </div>,
        <div
          key={index + '-delete'}
          className="tournament-link text-center"
          onClick={(e) => this.toggleModal(e, item.id)}
          >
            <i className="icon-delete"/>
          </div>
      ];
    });

    const dropdownData = (dataRow, rowIndex) => [
      <span
        key={rowIndex + '-remove'}
        className="tournament-link tournament-link-mobile"
        onClick={(e) => this.goToEdit(e, tournaments.data[rowIndex].id)}
      >
        Edit
      </span>,
      <span
        key={rowIndex + '-remove'}
        className="tournament-link tournament-link-mobile"
        onClick={(e) => this.toggleModal(e, tournaments.data[rowIndex].id)}
      >
        Delete
      </span>,
    ];

    return (
        <DataTableWithMobileDropdown
          columns={columns}
          data={data}
          onRowClick={(nr) => this.goToOverview(tournaments.data[nr].id)}
          dropdownData={dropdownData}
          onSort={this.onSort}
          sortedOptions={sorting}
        />
    );
  }

  handleSearchInputChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });

    const typeArray = this.getFiltersByGroup('type', this.state.selectedFilter);
    const statusArray = this.getFiltersByGroup('status', this.state.selectedFilter);

    const page = e.target.value.length > 0 ? 1 : this.props.currentPage;

    this.props.getAllTournaments(page, e.target.value, typeArray, statusArray);
  }

  renderFilterOption(option) {
    return (
      <div className={classnames('react-select-group__item',
        { 'react-select__group__item--is-disabled': option.disabled }
      )}>
        {option.group === 'status' &&
          <span className={`tournament-status-dot mr-2 tournament-status-dot--${option.status}`}></span>}
        {option.label}
      </div>
    );
  }

  renderFilterValue(option) {
    return (
      <div>
        {option.group === 'status' &&
          <span className={`tournament-status-dot mr-2 tournament-status-dot--${option.status}`}></span>}
        {option.label}
      </div>
    );
  }

  getFiltersByGroup(group, values) {
    if (!values || values.length === 0) {
      return null;
    }

    return values
      .filter(item => item.group === group)
      .map(item => item.value);
  }

  selectFilter = (selected) => {
    this.setState({
      selectedFilter: selected
    });

    const typeArray = this.getFiltersByGroup('type', selected);
    const statusArray = this.getFiltersByGroup('status', selected);
      const searchValue = this.state.searchValue || null;

    this.props.history.push('/tournaments/1');
    this.props.getAllTournaments(1, searchValue, typeArray, statusArray);
  }

  renderPagination() {
    const {tournaments} = this.props;
    const {searchValue} = this.state;

    if(!tournaments.data || searchValue){
      return null;
    }

    return <Pagination totalPages={tournaments.data.headers['x-last-page']} url={'/tournaments/:page'}/>;
  }

  render() {
    const { selectedFilter } = this.state;
    const {tournaments, intl} = this.props;

    if(!tournaments){
      return null;
    }

    return (
      <div>
        <h4 className="section-header mb-5">
          <FormattedMessage id="tournaments.header.admin"/>
        </h4>
        <div className="row">
          <div className="mb-3 col-md-6">
            <div className="input-group">
              <input
                onChange={this.handleSearchInputChange}
                type="text"
                className="form-control"
                placeholder={intl.formatMessage({id: 'tournament.list.search.placeholder'})}
                value={this.state.searchValue}
              />
              <div className="input-group-append">
                <button className="btn btn-light" type="button"><i className="icon-search"/></button>
              </div>
            </div>
          </div>
          <div className="col-md-3 offset-md-3">
            <Select
              className="react-select-group"
              value={selectedFilter}
              options={filterOptions}
              onChange={this.selectFilter}
              optionRenderer={this.renderFilterOption}
              valueRenderer={this.renderFilterValue}
              multi
              placeholder="Default"
            />
          </div>
        </div>
        <div className="tournament-list mt-3">
          <div className="tournament-list-table-wrapper">
            {this.renderTournamentsList()}
          </div>
          <div className="pagination-wrapper">
            {this.renderPagination()}
          </div>
        </div>
        <Modal
          isOpen={this.state.isOpen}
          toggle={(e) => this.toggleModal(e, null)}
          backdrop={this.state.backdrop}
        >
          <ModalHeader
            toggle={(e) => this.toggleModal(e, null)}
          >
            <FormattedMessage id="tournament.delete.modal.header"/>
          </ModalHeader>
          <ModalBody>
            <FormattedMessage id="tournament.delete.modal.body"/>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-success" onClick={this.submit}>
              <FormattedMessage id="tournament.delete.modal.submit"/>
            </button>
            <button className="btn btn-outline-secondary" onClick={(e) => this.toggleModal(e, null)}>
              <FormattedMessage id="tournament.delete.modal.cancel"/>
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

TournamentListAdmin.propTypes = {
  createTournament: PropTypes.func,
  getAllTournaments: PropTypes.func.isRequired,
  deleteTournament : PropTypes.func,
  tournaments: PropTypes.object,
  history: PropTypes.object,
  currentPage: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(connect(
  (state) => ({
    tournaments: state.tournamentsAll,
  }),
  ({
    getAllTournaments,
    deleteTournament,
  })
)(TournamentListAdmin));
