import React from 'react';
import { Link } from 'react-router-dom';
import TopBar from '../Layout/TopBar';
import registerSuccessImg from '../../assets/images/register-success.png';
import { FormattedMessage } from 'react-intl';

class SuccessPasswordChange extends React.Component {
  render(){
    return(
      <React.Fragment>
        <TopBar rightView={null} mobileBreakpoint={null} />
        <div className="container fix-topbar-padding">
          <div className="row justify-content-center pt-5">
            <div className="col-lg-6 d-flex align-items-center flex-column pt-5">
              <img src={registerSuccessImg} alt=""/>
              <div className="py-5">
                <p className="text-center h5"><FormattedMessage id="password.change.title"/></p>
                <p className="text-center h6"><FormattedMessage id="password.change.text"/></p>
              </div>
              <div>
                <Link className="py-2 px-5 btn btn-primary" style={{textDecoration: 'none', color: 'white'}} to="/"><FormattedMessage id="password.change.btn"/></Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SuccessPasswordChange;
