import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import AccessControl from '../Utils/AccessControl';
import { roles } from '../const';


import './Sidebar.scss';

export const navElementsLogged = [
  {
    name: 'Decklist',
    icon: 'icon-decklist',
    link: '/decks',
    roles: [roles.player]
  },
  {
    name: 'Users',
    icon: 'icon-users',
    link: '/users',
    roles: [roles.admin],
  },
  {
    name: 'Tournaments',
    icon: 'icon-tournaments',
    link: '/tournaments',
    roles: [roles.player, roles.judge, roles.organizer, roles.admin],
  },
  {
    name: 'Stores',
    icon: 'icon-store',
    link: '/stores',
    roles: [roles.organizer, roles.admin],
  },
  {
    name: 'Metrics',
    icon: 'icon-metrics',
    link: '/metrics',
    roles: [roles.admin, roles.organizer],
  },
  {
    name: 'Deck names',
    icon: 'icon-decklist',
    link: '/deck-names',
    roles: [roles.admin],
  },
  {
    name: 'Streamers',
    icon: 'icon-streamers',
    link: '/streamers',
    roles: [roles.admin],
  },
  {
    name: 'Streamed decks',
    icon: 'icon-streamed_decks',
    link: '/streamed-decks',
    roles: [roles.player],
  },
  {
    name: 'Manage account',
    icon: 'icon-manage-account',
    link: '/manage-account'
  },
];

const navElementsNotLogged = [
  {
    name: 'Create account',
    icon: 'icon-manage-account',
    link: '/register'
  }
];

class Sidebar extends React.Component {
  renderNav() {
    const { userData } = this.props;
    const navElements = userData ? navElementsLogged : navElementsNotLogged;
    const itemClassName = userData ? 'sidebar__nav-item sidebar__nav-item--logged' : 'sidebar__nav-item';

    return (
      <ul className="sidebar__nav">
        {navElements.map((element, index) => {
          const { icon, name, link, roles } = element;

          return (
            <AccessControl key={index} roles={roles}>
              <li
                className={itemClassName}
              >
                <NavLink className="sidebar__navlink" to={link}>
                  <i className={ `${icon} sidebar__nav-item-icon` } /> {name}
                </NavLink>
              </li>
            </AccessControl>
          );
        })}
      </ul>
    );
  }

  render() {
    return (
      <div className="sidebar">
        {this.renderNav()}
      </div>
    );
  }
}

Sidebar.propTypes = {
  userData: PropTypes.object
};

export default Sidebar;
