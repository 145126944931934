import {TOURNAMENT_TEAMS} from '../../actions/types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_TEAMS.GET.SUCCESS:
      return action.payload.data.map(item => item.id);
    default:
      return state;
  }
};
