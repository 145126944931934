import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {injectIntl, FormattedMessage} from 'react-intl';

import TeamMembersDecklistTable from './TeamMembersDecklistTable';

class TeamMembersDecklistEditTable extends React.Component {
  state = {
    data: this.props.data,
  };

  onSubmit = () => {
    const {onSubmit} = this.props;
    const {data} = this.state;

    onSubmit(data);
  };

  onChangeInput = (e, index) => {
    const value = e.target.value;
    const fieldName = e.target.name;

    this.setState(state => update(state, {
      data: {
        [index]: {
          [fieldName]: {$set: value},
        }
      }
    }));
  };

  getDropdownData = (rowElems, index) => {
    const {data, onEditDecklist} = this.props;
    const item = data[index];

    if(!item.deckId) {
      return [];
    }

    return [
      <div onClick={() => onEditDecklist(item.deckId)} key={1}>
        <FormattedMessage id="team.member-decklist-table.edit.edit"/>
      </div>
    ];
  };

  renderItemInput(item, index, fieldName) {
    const {data} = this.state;

    return (
      <input
        className="form-control"
        type="text"
        name={fieldName}
        value={data[index][fieldName] || ''}
        onChange={(e) => this.onChangeInput(e, index)}
      />
    );
  }

  renderColDecklist(item) {
    const {onEditDecklist} = this.props;

    if(!item.deckId) {
      return null;
    }

    return (
      <div className="btn btn-warning btn-block" onClick={() => onEditDecklist(item.deckId)}>Edit</div>
    );
  }

  render() {
    const {data, intl} = this.props;

    return (
      <TeamMembersDecklistTable
        data={data}
        submitDisabled={false}
        buttonSubmitText={intl.formatMessage({id: 'team.member-decklist-table.edit.submit'})}
        dropdownData={this.getDropdownData}
        onSubmit={this.onSubmit}
      >
        {
          (item, index) => [
            this.renderItemInput(item, index, 'firstName'),
            this.renderItemInput(item, index, 'lastName'),
            this.renderItemInput(item, index, 'dciNumber'),
            item.format,
            this.renderColDecklist(item),
          ]
        }
      </TeamMembersDecklistTable>
    );
  }
}

TeamMembersDecklistEditTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    dciNumber: PropTypes.string,
    format: PropTypes.string,
    deckId: PropTypes.string,
  })).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEditDecklist: PropTypes.func.isRequired,

  intl: PropTypes.object.isRequired,
};

export default injectIntl(TeamMembersDecklistEditTable);

