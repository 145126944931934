import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './RoundNumber.scss';

class RoundNumber extends React.Component {
  onChange = (e) => {
    const {onChange} = this.props;

    onChange(e.target.value);
  };

  render() {
    const {className, value, children} = this.props;

    return (
      <div className={classnames('round-number', className)}>
        <span>Round:</span>

        {children}

        <input type="number"
               className="form-control"
               min={0}
               value={value}
               onChange={this.onChange}/>
      </div>
    );
  }
}

RoundNumber.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};

export default RoundNumber;
