import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Select from 'react-select';
import { injectIntl } from 'react-intl';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import Stores from '../../services/Stores';
import DataTableWithMobileDropdown from '../DataTable/DataTableWithMobileDropdown';
import { getStores } from '../../actions/storesActions';
import { FormattedMessage } from 'react-intl';
import Pagination from '../Pagination/Pagination';
import { add as addNotification } from '../../actions/notificationActions';
import Spinner from '../Spinner';

class StoresList extends React.Component {
  state = {
    isOpen: false,
    deleteId: null,
    searchValue: '',
  };

  componentDidMount() {
    this.props.getStores(this.props.match.params.page);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.page !== this.props.match.params.page ) {
      this.props.getStores(this.props.match.params.page, this.state.searchValue);
    }
  }

  addStore = () => {
    this.props.history.push('/stores/create');
  }

  submit = () => {
    Stores.deleteStore(this.state.deleteId).then(() => {
      this.props.addNotification({
        text: <FormattedMessage id="store.delete.success"/>,
        type: 'success',
        timeout: 4,
      });
      this.props.getStores();
      this.toggleModal(null, null);
    })
    .catch(()=>{
      this.props.addNotification({
        text: <FormattedMessage id="store.delete.error"/>,
        type: 'danger',
        timeout: 4,
      });
    });
  }

  toggleModal = (e, id) => {
    e && e.stopPropagation();

    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
      deleteId: id
    }));
  }

  goToEdit(e, id) {
    e && e.stopPropagation();
    this.props.history.push('/stores/show/' + id);
  }

  goToOverview(id) {
    this.props.history.push('/stores/overview/' + id);
  }

  renderStoresList = () => {
    const {data: stores} = this.props.stores;

    if(!stores || this.props.stores.isLoad){
      return <Spinner/>;
    }

    if (!stores || !stores.data || stores.data.length < 1) {
      return <div className="text-center">No stores yet</div>;
    }

    const columns = [
      {name: 'ID', flex: 1},
      {name: 'NAME', flex: 2},
      // {name: 'ORGANIZER', flex: 2},
      {name: 'EDIT', isVisibleOnMobile: false, className: 'stores-list__fixed-width-col'},
      {name: 'DELETE', isVisibleOnMobile: false, className: 'stores-list__fixed-width-col'}
    ];

    const data = stores.data.map((item, index) => {
      return [
        `#${item.id.split('-')[0]}`,
        item.name,
        // item.organizer || '-',
        <div
          key={index + '-edit'}
          className="text-center"
          onClick={(e) => this.goToEdit(e, item.id)}
        >
          <i className="icon-edit"/>
        </div>,
        <div
          key={index + '-delete'}
          className="text-center"
          onClick={(e) => this.toggleModal(e, item.id)}
        >
          <i className="icon-delete"/>
        </div>
      ];
    });

    const dropdownData = (dataRow, rowIndex) => [
      <span key={rowIndex + '-remove'} className="tournament-link tournament-link-mobile" onClick={(e) => this.goToEdit(e, stores.data[rowIndex].id)}><FormattedMessage id="tournaments.btn.edit"/></span>,
      <span key={rowIndex + '-remove'} className="tournament-link tournament-link-mobile" onClick={(e) => this.toggleModal(e, stores.data[rowIndex].id)}><FormattedMessage id="btn.delete"/></span>,
    ];

    return (
      <DataTableWithMobileDropdown
        columns={columns}
        data={data}
        onRowClick={(nr) => this.goToOverview(stores.data[nr].id)}
        dropdownData={dropdownData}
      />
    );
  }

  handleSearchInputChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });

    this.props.getStores(1, e.target.value);
  }

  render() {
    const {stores, intl} = this.props;
    const total = stores.data && stores.data.headers && stores.data.headers['x-last-page'];

    if(!stores){
      return null;
    }

    return (
      <div>
        <h4 className="section-header mb-5">
          <FormattedMessage id="stores.title"/>
          <button type="button"
            className="btn btn-primary float-right"
            onClick={this.addStore}
          >
            <FormattedMessage id="store.btn.add"/>
          </button>
        </h4>
        <div className="row">
          <div className="mb-3 col-md-7">
            <div className="input-group">
              <input
                onChange={this.handleSearchInputChange}
                type="text"
                className="form-control"
                placeholder={intl.formatMessage({id: 'stores.list.search.placeholder'})}
                value={this.state.searchValue}
              />
              <div className="input-group-append">
                <button className="btn btn-light" type="button"><i className="icon-search"/></button>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-none">
          <div className="input-group mb-3 col-md-6">
            <input type="text" className="form-control" placeholder={intl.formatMessage({id: 'stores.list.search.placeholder'})}/>
            <div className="input-group-append">
              <button className="btn btn-light" type="button"><i className="icon-search"/></button>
            </div>
          </div>
          <div className="col-md-3 offset-md-3">
            <Select/>
          </div>
        </div>
        <div className="tournament-list mt-3">
          <div className="tournament-list-table-wrapper">
            {this.renderStoresList()}
          </div>
          <div className="pagination-wrapper">
            {this.props.stores.data && <Pagination totalPages={total} url={'/stores/:page'}/>}
          </div>
        </div>
        <Modal isOpen={this.state.isOpen} toggle={(e) => this.toggleModal(e, null)} backdrop={this.state.backdrop}>
          <ModalHeader toggle={(e) => this.toggleModal(e, null)}><FormattedMessage id="store.delete"/></ModalHeader>
          <ModalBody>
            <FormattedMessage id="store.delete.msg"/>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-success" onClick={this.submit}><FormattedMessage id="tournament.delete.modal.submit"/></button>
            <button className="btn btn-outline-secondary" onClick={(e) => this.toggleModal(e, null)}><FormattedMessage id="tournament.delete.modal.cancel"/></button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

StoresList.propTypes = {
  getStores: PropTypes.func.isRequired,
  stores: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  addNotification: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(withRouter(connect(
  (state) => ({
    stores: state.stores
  }),
  ({
    getStores,
    addNotification,
  })
)(StoresList)));
