import {createLogic} from 'redux-logic';

import {SUBMIT_DECK_FOR_TOURNAMENT} from '../../actions/types';
import {selectTournament, getTournaments} from '../../actions/submitDeckForTournament';

export const join = createLogic({
  type: SUBMIT_DECK_FOR_TOURNAMENT.JOIN_TOURNAMENT.START,

  latest: true,

  processOptions: {
    successType: SUBMIT_DECK_FOR_TOURNAMENT.JOIN_TOURNAMENT.SUCCESS,
    failType: SUBMIT_DECK_FOR_TOURNAMENT.JOIN_TOURNAMENT.ERROR,
  },

  process({httpClient, action}) {
    return httpClient.put(`/api/v1/tournaments_register/${action.payload}`)
      .then(res => res.data);
  }
});

export const joinSuccess = createLogic({
  type: SUBMIT_DECK_FOR_TOURNAMENT.JOIN_TOURNAMENT.SUCCESS,

  process({action}, dispatch, done) {
    const nextActions = [
      selectTournament(action.payload.tournament.id),
    ];

    dispatch(getTournaments(nextActions));
    done();
  }
});

export default [
  join,
  joinSuccess,
];
