import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

class ConfirmModal extends React.Component {
  render() {
    const { isOpen, onClose, onClick, headerText, bodyText } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={onClose}
      >
        <ModalHeader
          toggle={onClose}
        >
          {headerText}
        </ModalHeader>
        <ModalBody>
          {bodyText}
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-success" onClick={onClick}>
            <FormattedMessage id="tournament.delete.modal.submit" />
          </button>
          <button className="btn btn-outline-secondary" onClick={onClose}>
            <FormattedMessage id="tournament.delete.modal.cancel" />
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
};

export default ConfirmModal;
