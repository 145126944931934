import * as React from 'react';
import { createLogic } from 'redux-logic';
import { push } from 'react-router-redux';

import { IMPORT_PLAYERS } from '../actions/types';
import { add as addNotification } from '../actions/notificationActions';
import { FormattedMessage } from 'react-intl';
import {getTournamentPlayers} from '../actions/tournamentMembers';

export const importPlayers = createLogic({
  type: IMPORT_PLAYERS.START,

  process({ httpClient, action }, dispatch, done) {
    const formData = new FormData();
    formData.append('file', action.file);
    return httpClient.post(`/api/v1/tournaments/${action.tournamentId}/players_import`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
    )
    .then(() => {
      dispatch(addNotification({
        type: 'success',
        text: <FormattedMessage id="tournament.players.import.players.success"/>
      }));
      dispatch(getTournamentPlayers(action.tournamentId));
      dispatch({type: IMPORT_PLAYERS.SUCCESS});
      dispatch(push(`/tournaments/show/${action.tournamentId}/players/1`));
    })
    .catch(err => {
      dispatch(addNotification({
        type: 'danger',
        text: err.response.data.error_description
      }));
      dispatch(getTournamentPlayers(action.tournamentId));
      dispatch({type: IMPORT_PLAYERS.ERROR});
    })
    .then(() => done());
  }
});

export default {
  importPlayers,
};
