import {createLogic} from 'redux-logic';

import {USER_PERSONAL_TOKEN} from '../actions/types';
import actions from '../actions/userPersonalToken';

export const getAll = createLogic({
  type: USER_PERSONAL_TOKEN.GET_ALL.START,

  processOptions: {
    successType: USER_PERSONAL_TOKEN.GET_ALL.SUCCESS,
    failType: USER_PERSONAL_TOKEN.GET_ALL.ERROR,
  },

  process({httpClient, action}) {
    const {userId} = action.payload;

    return httpClient.get(`/api/v1/users/${userId}/caster_tokens`)
      .then(res => res.data);
  }
});

export const generate = createLogic({
  type: USER_PERSONAL_TOKEN.GENERATE.START,

  process({httpClient, action, genericActions}, dispatch, done) {
    const {userId} = action.payload;

    return httpClient.post(`/api/v1/users/${userId}/caster_tokens`, {})
      .then(res => res.data)
      .then((data) => {
        dispatch(genericActions.notification.add({
          type: 'success',
          intlKey: 'notifications.personalToken.generate.success',
          key: 'userPersonalToken',
        }));

        dispatch(actions.generateSuccess(data));
      })
      .catch((error) => {
        dispatch(genericActions.notification.add({
          type: 'danger',
          intlKey: 'notifications.general.error',
          key: 'userPersonalToken',
        }));

        dispatch(actions.generateError(error));
      })
      .then(done);
  }
});

export const regenerate = createLogic({
  type: USER_PERSONAL_TOKEN.REGENERATE.START,

  process({httpClient, action, genericActions}, dispatch, done) {
    const {userId, tokenId} = action.payload;

    return Promise.all([
      httpClient.delete(`/api/v1/caster_tokens/${tokenId}`),
      httpClient.post(`/api/v1/users/${userId}/caster_tokens`, {})
    ])
      .then(([resRemove, resGenerate]) => [resRemove.data, resGenerate.data])
      .then(([, resGenerate]) => {
        dispatch(genericActions.notification.add({
          type: 'success',
          intlKey: 'notifications.personalToken.regenerate.success',
          key: 'userPersonalToken',
        }));

        dispatch(actions.regenerateSuccess({
          removedId: tokenId,
          generateData: resGenerate,
        }));
      })
      .catch((error) => {
        dispatch(genericActions.notification.add({
          type: 'danger',
          intlKey: 'notifications.general.error',
          key: 'userPersonalToken',
        }));

        dispatch(actions.regenerateError(error));
      })
      .then(done);
  }
});

export const remove = createLogic({
  type: USER_PERSONAL_TOKEN.REMOVE.START,

  process({httpClient, action, genericActions}, dispatch, done) {
    const {userId, tokenId} = action.payload;

    return httpClient.delete(`/api/v1/caster_tokens/${tokenId}`)
      .then(() => {
        dispatch(genericActions.notification.add({
          type: 'success',
          intlKey: 'notifications.personalToken.remove.success',
          key: 'userPersonalToken',
        }));

        dispatch(actions.removeSuccess({userId, tokenId}));
      })
      .catch((error) => {
        dispatch(genericActions.notification.add({
          type: 'danger',
          intlKey: 'notifications.general.error',
          key: 'userPersonalToken',
        }));

        dispatch(actions.removeError(error));
      })
      .then(done);
  }
});

export default [
  getAll,
  generate,
  remove,
  regenerate,
];
