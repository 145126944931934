import { createLogic } from 'redux-logic';

import { FORMATS } from '../actions/types';

export const get = createLogic({
  type: FORMATS.GET,
  latest: true,

  process({ httpClient, getState }, dispatch, done) {
    const formatsData = getState().formats.data;

    if(formatsData) {
      return dispatch({
        type: FORMATS.SUCCESS,
        payload: formatsData
      });
    }
    
    return httpClient.get('/api/v1/formats')
      .then(res => {
        return dispatch({
          type: FORMATS.SUCCESS,
          payload: res.data
        });
      })
      .catch(err => {
        return dispatch({
          type: FORMATS.ERROR,
          payload: err
        });
      })
      .then(() => done());
  }
});

export default {
  get
};
