import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class AccessControl extends React.Component {

  renderContentBasedOnRole() {
    const { userRole, roles, forbiddenRender, forbiddenRedirect} = this.props;
    const isAllowedRole = roles ? roles.includes(userRole) : true;

    if(!userRole) {
      return null;
    }

    if(!isAllowedRole) {
      if(forbiddenRender) {
        return forbiddenRender;
      }
      if(forbiddenRedirect) {
        return <Redirect to={forbiddenRedirect}/>;
      }

      return null;
    }

    return this.props.children;
  }

  render() {
    return (
      <React.Fragment>
        {this.renderContentBasedOnRole()}
      </React.Fragment>
    );
  }
}

AccessControl.defaultProps = {
  forbiddenRender: null,
};

AccessControl.propTypes = {
  userRole: PropTypes.string.isRequired,
  roles: PropTypes.array,
  forbiddenRender: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  forbiddenRedirect: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
export default connect((state) => ({
  userRole: state.user.userRole,
}), undefined)(AccessControl);
