const ls = {
  clear: () => {
      localStorage.clear();
  },

  get: (key) => {
      return JSON.parse(localStorage.getItem(key));
  },

  getDefault: (key, defaultValue) => {
      const stored = ls.get(key);

      if (stored) {
          return stored;
      }

      ls.set(key, defaultValue);
      return defaultValue;
  },

  remove: (key) => {
      localStorage.removeItem(key);
  },

  set: (key, value) => {
      localStorage.setItem(key, JSON.stringify(value));
  },
};

export default ls;
