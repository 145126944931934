import {
  TOURNAMENT,
  TOURNAMENTS,
  TOURNAMENTS_ALL,
  TOURNAMENTS_ALL_SEARCH,
  SAVE_TOURNAMENT,
  TOURNAMENT_ROLES_JUDGE,
  TOURNAMENT_ROLES_ORGANIZER,
  TOURNAMENTS_PLAYER,
  TOURNAMENT_JOIN,
  TOURNAMENT_JOIN_MODAL,
  TOURNAMENT_ASSOCIATIONS,
  TOURNAMENTS_JUDGE,
  DELETE_TOURNAMENT,
  CREATE_TOURNAMENT,
  TOURNAMENT_JOIN_SIMPLE,
  SAVE_TOURNAMENT_AND_UPDATE,
  MODAL,
} from './types';

import modalNames from '../components/modals/names';

export const getTournaments = (page, role, userId, searchQuery, filterByType, filterByStatus, sort, order) => ({
  type: TOURNAMENTS.FETCH,
  page: page || 1,
  role: role,
  userId: userId,
  searchQuery: searchQuery,
  filterByType,
  filterByStatus,
  sort: sort || 'createdAt',
  order: order || 'DESC'
});

export const getAllTournaments = (page, name, filterByType, filterByStatus, sort, order) => ({
  type: TOURNAMENTS_ALL.FETCH,
  payload: {
    page: page || 1,
    name,
    filterByType,
    filterByStatus,
    sort: sort || 'createdAt',
    order: order || 'DESC'
  }
});

export const tournamentsAllSearch = (name, page) => ({
  type: TOURNAMENTS_ALL_SEARCH.FETCH,
  name,
  page: page || 1,
});

export const getTournamentsPlayer = (page, userId, searchQuery, filterByType, filterByStatus, sort, order) => ({
  type: TOURNAMENTS_PLAYER.FETCH,
  page: page || 1,
  userId: userId,
  searchQuery: searchQuery,
  filterByType,
  filterByStatus,
  sort: sort || 'createdAt',
  order: order || 'DESC'
});

export const getTournamentsJudge = (page, userId) => ({
  type: TOURNAMENTS_JUDGE.FETCH,
  page: page || 1,
  userId: userId
});

export const getTournament = (id) => ({
  type: TOURNAMENT.FETCH,
  id: id
});

export const setTournament = (tournament) => ({
  type: TOURNAMENT.SET,
  payload: tournament
});

export const getTournamentByPasscode = (passcode, {isClientContext = false} = {}) => ({
  type: TOURNAMENT.FETCH_BY_PASSCODE,
  payload: {
    passcode,
    isClientContext,
  }
});

export const saveTournament = (id, data) => ({
  type: SAVE_TOURNAMENT.FETCH,
  data: data,
  id: id
});

export const saveTournamentAndUpdate = (id, data, nextActions = []) => ({
  type: SAVE_TOURNAMENT_AND_UPDATE,
  payload: {
    id,
    data,
    nextActions,
  }
});


export const createTournament = (data, nextActions = []) => ({
  type: CREATE_TOURNAMENT.FETCH,
  payload: {
    data,
    nextActions,
  }
});

export const deleteTournament = (id, role) => ({
  type: DELETE_TOURNAMENT.START,
  id: id,
  role: role
});

export const saveTournamentClear = () => ({
  type: SAVE_TOURNAMENT.CLEAR,
});

export const getTournamentRolesJudge = (id) => ({
  type: TOURNAMENT_ROLES_JUDGE.FETCH,
  payload: id
});

export const getTournamentRolesOrganizer = (id) => ({
  type: TOURNAMENT_ROLES_ORGANIZER.FETCH,
  payload: id
});

export const joinTournament = (passCode) => ({
  type: TOURNAMENT_JOIN.START,
  payload: passCode
});

export const joinTeamTournament = (tournament, passCode) => ({
  type: TOURNAMENT_JOIN.TEAM.START,
  payload: {
    tournament,
    passCode,
  }
});

export const joinIndividualTournament = (tournament) => ({
  type: TOURNAMENT_JOIN.INDIVIDUAL.START,
  payload: {
    tournament,
  }
});

export const joinTournamentModalAction = (isOpen, tournamentId, playerId) => ({
  type: TOURNAMENT_JOIN_MODAL.START,
  isOpen: isOpen,
  tournamentId: tournamentId,
  playerId: playerId,
});

export const joinTournamentSimple = (passCode) => ({
  type: TOURNAMENT_JOIN_SIMPLE.START,
  payload: passCode
});

export const openJoinTeamTournamentModal = (data) => ({
  type: MODAL.OPEN,
  payload: {
    name: modalNames.JOIN_TEAM_TOURNAMENT,
    data,
  }
});

/**
 *
 * @param dataArr[]
 * @param {string} dataArr[].userId
 * @param {string} dataArr[].tournamentId
 * @param {string} dataArr[].role   - 'Judge' | 'Player'
 * @returns {{type: string, payload: *}}
 */
export const addAssociations = (dataArr) => ({
  type: TOURNAMENT_ASSOCIATIONS.START,
  payload: dataArr
});
