import React from 'react';
import PropTypes from 'prop-types';
import {Route, Switch, withRouter} from 'react-router-dom';

import SimpleTopBar from '../../../Layout/SimpleTopBar';
import TournamentDetails from './TournamentDetails';
import NotifierMain from '../../../Notifier/NotifierMain';

import CreatePlayer from './CreatePlayer';
import CreateDeckContainer from './CreateDeckContainer';
import CreateTeamContainer from './CreateTeamContainer';
import TeamDetailsContainer from './TeamDetailsContainer';

import './Create.scss';

class Create extends React.Component {
  renderRoutes() {
    const { match, tournament } = this.props;

    const withTournament = (Component) => () => {
      return <Component tournament={tournament} passcode={match.params.passcode} />;
    };

    if (tournament.type === 'Team') {
      return (
        <Switch>
          <Route path={`${match.path}/team`} render={withTournament(CreateTeamContainer)} />
          <Route path={`${match.path}/team-details`} render={withTournament(TeamDetailsContainer)} />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route path={`${match.path}/player`} render={withTournament(CreatePlayer)} />
        <Route path={`${match.path}/deck`} render={withTournament(CreateDeckContainer)} />
      </Switch>
    );
  }

  renderTournamentDetails() {
    const {tournament, location} = this.props;

    if ( tournament.type === 'Team' && location.pathname === `/join-tournament/${tournament.passCode}/create/team`) {
      return null;
    }

    return (
      <div className="sidebar-container d-none d-lg-block">
        <TournamentDetails data={tournament} />
      </div>
    );
  }

  render() {
    return (
      <div className="page-join-tournament-unregistered-create">
        <SimpleTopBar />
        <NotifierMain className="fix-topbar-padding"/>
        <div>
          <div className="full-height d-flex">
              {this.renderTournamentDetails()}
            <div className="main-container">
              {this.renderRoutes()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Create.propTypes = {
  match: PropTypes.object.isRequired,
  tournament: PropTypes.object.isRequired,
  passcode: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withRouter(Create);
