import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import DeckOnLivestreamBox from './DeckOnLivestreamBox';

import userPersonalTokenActions from '../../actions/userPersonalToken';
import notificationActions from '../../actions/notificationActions';

class DeckOnLivestreamBoxContainer extends React.Component {
  onGetTokens = () => {
    const {userId, tokensGetAll} = this.props;

    tokensGetAll({userId});
  };

  onGenerateToken = () => {
    const {userId, tokensGenerate} = this.props;

    tokensGenerate({userId});
  };

  onRegenerateToken = (tokenId) => {
    const {userId, tokensRegenerate} = this.props;

    tokensRegenerate({userId, tokenId});
  };

  onDeleteToken = (tokenId) => {
    const {userId, tokensRemove} = this.props;

    tokensRemove({userId, tokenId});
  };

  onCopyToken = () => {
    const {notificationAdd} = this.props;

    notificationAdd({
      type: 'success',
      intlKey: 'notifications.personalToken.copy.success',
      key: 'userPersonalToken',
    });
  };

  render() {
    const {tokens} = this.props;

    return (
      <DeckOnLivestreamBox tokens={tokens}
                           onGetTokens={this.onGetTokens}
                           onGenerateToken={this.onGenerateToken}
                           onRegenerateToken={this.onRegenerateToken}
                           onDeleteToken={this.onDeleteToken}
                           onCopyToken={this.onCopyToken}/>
    );
  }
}

DeckOnLivestreamBoxContainer.propTypes = {
  userId: PropTypes.string.isRequired,

  tokens: PropTypes.object.isRequired,

  tokensGetAll: PropTypes.func.isRequired,
  tokensGenerate: PropTypes.func.isRequired,
  tokensRegenerate: PropTypes.func.isRequired,
  tokensRemove: PropTypes.func.isRequired,
  notificationAdd: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    tokens: state.userPersonalToken,
  }),
  ({
    tokensGetAll: userPersonalTokenActions.getAll,
    tokensGenerate: userPersonalTokenActions.generate,
    tokensRegenerate: userPersonalTokenActions.regenerate,
    tokensRemove: userPersonalTokenActions.remove,
    notificationAdd: notificationActions.add,
  })
)(DeckOnLivestreamBoxContainer);
