import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import actions from '../../../../actions/unregisteredPlayer';
import AuthService from '../../../../services/AuthService';

import CreateDeck from './CreateDeck';
import Spinner from '../../../Spinner';

class CreateDeckContainer extends React.Component {
  componentDidMount() {
    const {initDeckCreator, tournament} = this.props;

    const isAuthenticated = AuthService.isLogged();

    if(isAuthenticated) {
      AuthService.setHeaders();
    }

    initDeckCreator(tournament.id);
  }

  componentWillUnmount() {
    const {setDeck} = this.props;
    setDeck(null);
  }

  onSave = () => {
    const {history, passcode} = this.props;

    history.replace(`/join-tournament/${passcode}/success`);
  };

  render() {
    const {player, tournament, deck} = this.props;

    if(!player || !deck || !tournament) {
      return <Spinner class="mt-5"/>;
    }

    return (
      <CreateDeck player={player}
                  deck={deck}
                  tournament={tournament}
                  supportCustomCards={tournament.customCards}
                  onSave={this.onSave}/>
    );
  }
}

CreateDeckContainer.propTypes = {
  tournament: PropTypes.object.isRequired,
  passcode: PropTypes.string.isRequired,

  player: PropTypes.object,
  deck: PropTypes.object,

  initDeckCreator: PropTypes.func.isRequired,
  setDeck: PropTypes.func.isRequired,

  history: PropTypes.object.isRequired,
};

export default withRouter(connect(
  state => ({
    player: state.unregisterdPlayer.player,
    deck: state.unregisterdPlayer.deck,
  }),
  {
    initDeckCreator: actions.initDeckCreator,
    setDeck: actions.setDeck
  }
)(CreateDeckContainer));
