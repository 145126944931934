import { CARD_SEARCH, CARD_SELECT } from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  data: [],
  selectedImage: {},
};

export default (state = initialState, action) => {
  switch(action.type){
    case CARD_SEARCH.FETCH:
      return {
        ...state,
        isLoading: true,
      };
    case CARD_SEARCH.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case CARD_SEARCH.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case CARD_SELECT.SHOW:
      return {
        ...state,
        selectedImage: action.payload,
      };
    default: 
      return state;
  }
};