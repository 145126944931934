import React from 'react';
import PropTypes from 'prop-types';
import CreateDeckList from '../../../pages/CreateDeckListContainer';
import Spinner from '../../../Spinner';

class TournamentDeckBuilderModal extends React.Component {
  componentDidMount() {
    const {data, onCreateDeck} = this.props;
    onCreateDeck(data.deck ? data.deck.id : null);
  }

  renderDeck() {
    const {draft, toggle, supportCustomCards} = this.props;

    if(draft.isLoad) {
      return <Spinner/>;
    }

    if(draft.data) {
      return (
        <CreateDeckList id={`${draft.data.id}`}
                        tournamentDecklist
                        disableFormat
                        btnFixed={true}
                        onClose={toggle}
                        inModal={true}
                        supportCustomCards={supportCustomCards}
                      />
      );
    }
  }

  render() {
    return (
      <div>
        {this.renderDeck()}
      </div>
    );
  }
}

TournamentDeckBuilderModal.propTypes = {
  toggle: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.string,
    biography: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      dciNumber: PropTypes.string,
      biography: PropTypes.string,
    }),
    paperdeck: PropTypes.bool.isRequired,
  }).isRequired,
  onCreateDeck: PropTypes.func.isRequired,
  draft: PropTypes.shape({
    isLoad: PropTypes.bool.isRequired,
    data: PropTypes.object,
  }).isRequired,
  supportCustomCards: PropTypes.bool
};

export default TournamentDeckBuilderModal;