import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import httpClient from '../../services/clientContextHttpClient';
import { FormattedMessage } from 'react-intl';

export default class Username extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usernameAvailable: null,
      lengthValid: false,
      isValid: false,
      isBlurred: false,
      validating: false,
    };
  }

  componentDidUpdate() {
    if (
      this.state.isValid !== this.state.usernameAvailable &&
      this.state.lengthValid
    ) {
      this.changeValidation(
        this.state.usernameAvailable && this.state.lengthValid
      );
    }
  }

  changeValidation(value) {
    if (this.state.isValid !== value) {
      this.props.onValidationChanged(value);
    }
    this.setState({
      isValid: value,
    });
  }

  checkUsernameAvailable() {
    if (!this.state.lengthValid) {
      this.setState({
        validating: true,
        isBlurred: true,
      });
      return;
    }
    this.setState({
      isBlurred: true,
    });

    httpClient
      .get(`/api/v1/available_usernames/${this.props.value}`)
      .then(() => {
        this.setState({
          usernameAvailable: true,
          validating: false,
        });
      })
      .catch(() => {
        this.setState({
          usernameAvailable: false,
          validating: false,
        });
      });
  }

  handleChange(value) {
    this.props.onChange(value);
    this.setState({
      lengthValid: this.validateLength(value),
      isValid: false,
    });
  }

  validateLength(value) {
    if (value.length < 1) {
      return false;
    }
    return true;
  }

  renderValidation() {
    const {
      isValid,
      isBlurred,
      lengthValid,
      usernameAvailable,
      validating,
    } = this.state;

    if (isValid) {
      return <div className="valid-feedback"><FormattedMessage id="register.username.valid"/></div>;
    }
    if (isBlurred && !lengthValid) {
      return <div className="invalid-feedback"><FormattedMessage id="register.username.invalid"/></div>;
    }
    if (isBlurred && !usernameAvailable && !validating) {
      return <div className="invalid-feedback"><FormattedMessage id="register.username.error"/></div>;
    }
  }

  render() {
    const {
      isValid,
      isBlurred,
      lengthValid,
      usernameAvailable,
      validating,
    } = this.state;

    const valid = isValid;
    const invalid =
      (isBlurred && !lengthValid) ||
      (isBlurred && !usernameAvailable && !validating);

    const inputClass = classNames('form-control', 'register-form__input', {
      'is-valid': valid,
      'is-invalid': invalid,
    });

    return (
      <React.Fragment>
        <input
          type="text"
          className={inputClass}
          value={this.props.value}
          onChange={e => this.handleChange(e.target.value)}
          onBlur={() => this.checkUsernameAvailable()}
        />
        {this.renderValidation()}
      </React.Fragment>
    );
  }
}

Username.propTypes = {
  onValidationChanged: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
