import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getCardPreview, clearCardPreview} from '../../../actions/cardPreviewActions';
import Spinner from '../../Spinner';

import './PreviewCard.scss';

class PreviewCard extends React.Component {
  componentDidMount() {
    const {getCardPreview, cardId} = this.props;
    getCardPreview(cardId);
  }

  onClose = (e) => {
    const {clearCardPreview, close} = this.props;
    clearCardPreview();
    close(e);
  }

  getLink(url) {
    const { partnerId } = this.props;

    if (partnerId) {
      return `${url}&partner_args=${partnerId}`;
    }

    return url;
  }

  renderCard = () => {
    const { cardPreview } = this.props;

    if(cardPreview.isLoad) {
      return (
        <Spinner size={30}/>
      );
    }

    if(!cardPreview.data) {
      return null;
    }

    // const cardUrl = cardPreview.data.cardGroup.url;
    // const cardPrice = cardPreview.data.cardGroup.price;

    return (
      <div className="preview">
        <div onClick={(e) => e.stopPropagation()}>
          <img src={cardPreview.data.imageUrl} alt={cardPreview.data.name}/>
        </div>
        {/*
          (!purchases || (purchases === 'true')) && cardPrice && (
            <div className="price-wrapper" onClick={(e) => e.stopPropagation()}>
              <span className="price-text"><span className="pr-3"><FormattedMessage id="preview.card.text"/>:</span> ${cardPreview.data.cardGroup.price}</span>
              {cardUrl && <a className="price-buy" href={this.getLink(cardPreview.data.cardGroup.url)} target="_blank"><FormattedMessage id="preview.card.buy"/></a>}
            </div>
          )
        } */}
      </div>
    );
  }

  render() {
    return (
      <div onClick={e => this.onClose(e)} className="preview-card">
        {this.renderCard()}
      </div>
    );
  }
}

PreviewCard.propTypes = {
  cardId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  getCardPreview: PropTypes.func.isRequired,
  clearCardPreview: PropTypes.func.isRequired,
  cardPreview: PropTypes.object,
  partnerId: PropTypes.string,
  purchases: PropTypes.string,
};

export default connect(
  (state) => ({
    cardPreview: state.cardPreview,
  }),
  ({
    getCardPreview,
    clearCardPreview
  })
)(PreviewCard);
