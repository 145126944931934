import {createLogic} from 'redux-logic';
import update from 'immutability-helper';

import {EDIT_TOURNAMENT_PLAYER} from '../actions/types';
import {getTournamentPlayers} from '../actions/tournamentMembers';
import { getTournamentTeams } from '../actions/tournamentTeams';

export const editTournamentPlayerGet = createLogic({
  type: EDIT_TOURNAMENT_PLAYER.GET.START,
  latest: true,

  processOptions: {
    successType: EDIT_TOURNAMENT_PLAYER.GET.SUCCESS,
    failType: EDIT_TOURNAMENT_PLAYER.GET.ERROR,
  },

  process({httpClient, action}) {
    const {userId, playerId, tournamentId} = action.payload;

    return Promise.all([
      httpClient.get(`/api/v1/tournaments/${tournamentId}/players/${userId}`),
      httpClient.get(`/api/v1/players/${playerId}/bio_schemas`),
    ])
      .then(([resPlayer, resBio]) => {
        const bioData = resBio.data;

        // Workaround for CBL-1405: Main user avatar did't change after update
        //
        // After change avatar, url of this avatar stay the same and browser
        // get this image from cache. We change this url adding timestamp on the end in query string.
        bioData.questions = bioData.questions.map(item => {
          if(item.type !== 'img'){
            return item;
          }

          const imgValue = item.membersAnswers[0] && item.membersAnswers[0].value;

          if(imgValue){
            const t = +new Date();

            return update(item, {
              membersAnswers: {
                [0]: {
                  value: {
                    $set: `${imgValue}?t=${t}`
                  }
                }
              }
            });
          }

          return item;
        });

        return {
          playerData: resPlayer.data,
          bioData: bioData,
        };
      });
  }
});

export const editTournamentPlayerSave = createLogic({
  type: EDIT_TOURNAMENT_PLAYER.SAVE.START,
  latest: true,

  process({httpClient, action, getState}, dispatch) {
    const {playerData, bioData, bioAvatar, onClose} = action.payload;
    const params = getState().editTournamentPlayer.params;

    const isAvatarRequest = bioAvatar !== undefined;
    const isAvatarDelete = isAvatarRequest && bioAvatar === null;
    const isAvatarUpdate = isAvatarRequest && bioAvatar !== null && !!bioAvatar.name;

    const requests = [
      httpClient.post(`/api/v1/players/${params.playerId}/bio_schemas`, bioData)
    ];

    if(playerData) {
      requests.push(
        httpClient.patch(`/api/v1/tournament_members/${params.playerId}`, playerData)
      );
    }

    if(isAvatarUpdate) {
      const formData = new FormData();
      formData.append('file', bioAvatar);
      requests.push(
        httpClient.post(`/api/v1/players/${params.playerId}/avatar`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
      );
    }
    if(isAvatarDelete) {
      requests.push(
        httpClient.delete(`/api/v1/players/${params.playerId}/avatar`)
      );
    }

    return Promise.all(requests)
      .then((res) => {
        const data = {
          bioData: res[0].data,
        };

        if(playerData) {
          data['playerData'] = res[1].data;
        }

        dispatch({
          type: EDIT_TOURNAMENT_PLAYER.SAVE.SUCCESS,
          payload: {
            onClose: onClose
          }
        });

        return data;
      })
      .catch((err) => {
        dispatch({
          type: EDIT_TOURNAMENT_PLAYER.SAVE.ERROR,
          payload: {
            onClose: onClose,
            error: err.response.data
          }
        });
      });
  }
});

export const editTournamentPlayerSaveSuccess = createLogic({
  type: EDIT_TOURNAMENT_PLAYER.SAVE.SUCCESS,
  latest: true,

  process({genericActions, action, getState}, dispatch, done) {
    const params = getState().editTournamentPlayer.params;
    const tournament = getState().tournament.data;
    action.payload.onClose();

    if(tournament.type === 'Team') {
      dispatch(getTournamentTeams(params.tournamentId));
    } else {
      dispatch(getTournamentPlayers(params.tournamentId));
    }

    dispatch(genericActions.notification.add({
      intlKey: 'notifications.general.success',
      type: 'success',
    }));
    done();
  }
});

export const editTournamentPlayerSaveError = createLogic({
  type: EDIT_TOURNAMENT_PLAYER.SAVE.ERROR,
  latest: true,

  process({genericActions, action}, dispatch, done) {
    action.payload.onClose();
    const error = action.payload.error.error_description;
    dispatch(genericActions.notification.add({
      text: error,
      type: 'danger',
    }));
    done();
  }
});

export default [
  editTournamentPlayerGet,
  editTournamentPlayerSave,
  editTournamentPlayerSaveSuccess,
  editTournamentPlayerSaveError,
];
