import React from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './MetaDataTable.scss';

class MetaDataTable extends React.Component {
  state = {
    inputName: '',
    inputValue: null,
    newRowInputName: '',
    newRowValue: null,
  };

  onNameInputChange = (e) => {
    return this.setState({
      inputName: e.target.value,
    });
  };

  onValueInputChange = (e) => {
    return this.setState({
      inputValue: e.target.value
    });
  };

  onNewRowNameInputChange = (e) => {
    return this.setState({
      newRowInputName: e.target.value,
    });
  };

  onNewRowValueInputChange = (e) => {
    return this.setState({
      newRowValue: e.target.value
    });
  };

  onEditButtonClick = (deckId) => {
    return this.props.onEditData(deckId);
  };

  onSaveButtonClick = () => {
    const { inputName, inputValue } = this.state;
    this.props.onSaveData(inputName, inputValue);

    return this.setState({
      inputName: '',
      inputValue: null,
    });
  };

  onCreateNewRow = () => {
    const {newRowInputName, newRowValue} = this.state;
    const {onCreateNewRow, onIsNewRowChange} = this.props;
    onCreateNewRow(newRowInputName, newRowValue);
    onIsNewRowChange(false);

    return this.setState({
      newRowInputName: '',
      newRowValue: null,
    });
  };

  onDeleteButtonClick = (deckId) => {
    return this.props.onDeleteData(deckId);
  };

  renderHeader() {
    const { isEditable } = this.props;
    return (
      <thead>
        <tr className="meta-table-nav">
          <th>
            <FormattedMessage id="metaData.table.header.archetype" />
          </th>
          <th>
            <FormattedMessage id="metaData.table.header.number" />
          </th>
          { isEditable &&
          <th className="actions-col">
            <FormattedMessage id="metaData.table.header.actions" />
          </th> }
        </tr>
      </thead>
    );
  }

  renderDefault(elem) {
    return (
      <tr className="meta-table-row" key={elem.id}>
        <td>{elem.name}</td>
        <td>{elem.amount}</td>
      </tr>
    );
  }

  renderOverride(elem) {
    const { editionId } = this.props;

    if (elem.id === editionId) {
      return this.renderEditableRow(elem);
    }

    return this.renderNormalRow(elem);
  }

  renderNormalRow(elem) {
    return (
      <tr className="meta-table-row" key={elem.id}>
        <td>{elem.name}</td>
        <td>{elem.amount}</td>
        <td>
          <button onClick={() => this.onEditButtonClick(elem.id)} className="btn btn-primary">
            <FormattedMessage id="metaData.button.edit" />
          </button>
          <div onClick={() => this.onDeleteButtonClick(elem.id)} className="d-inline deletion-wrapper">
            <i className="icon-delete"/>
          </div>
        </td>
      </tr>
    );
  }

  renderEditableRow(elem) {
    return (
      <tr className="meta-table-row" key={elem.id}>
        <td>
          <input
            type="text"
            onChange={(e) => this.onNameInputChange(e)}
            className="form-control meta-input"
            defaultValue={elem.name}
            placeholder="Enter new deck name"
          />
        </td>
        <td>
          <input
            type="number"
            onChange={(e) => this.onValueInputChange(e)}
            className="form-control meta-input"
            defaultValue={elem.amount}
          />
        </td>
        <td>
          <button onClick={() => this.onSaveButtonClick(elem.id)} className="btn btn-success">
            <FormattedMessage id="metaData.button.save" />
          </button>
          <div onClick={() => this.onDeleteButtonClick(elem.id)} className="d-inline deletion-wrapper">
            <i className="icon-delete"/>
          </div>
        </td>
      </tr>
    );
  }

  renderNewRow() {
    const { onIsNewRowChange } = this.props;

    return (
      <tr className="meta-table-row" /*key={elem.id}*/>
        <td>
          <input
            type="text"
            onChange={(e) => this.onNewRowNameInputChange(e)}
            className="form-control meta-input"
            placeholder="Enter new deck name"
          />
        </td>
        <td>
          <input
            type="number"
            onChange={(e) => this.onNewRowValueInputChange(e)}
            className="form-control meta-input"
          />
        </td>
        <td>
          <button onClick={() => onIsNewRowChange(false)} className="btn btn-danger mr-1">
            <FormattedMessage id="metaData.button.cancel" />
          </button>
          <button onClick={() => this.onCreateNewRow()} className="btn btn-success">
            <FormattedMessage id="metaData.button.save" />
          </button>
        </td>
      </tr>
    );
  }

  renderBody() {
    const { data, isEditable, isNewRow } = this.props;
    
    return (
      <tbody>
        { data.length !== 0 && data.map(elem => {
          return isEditable ? this.renderOverride(elem) : this.renderDefault(elem);
        }) }
        {isNewRow && this.renderNewRow()}
      </tbody>
    );
  }

  render() {
    return (
      <table className="meta-table">
        {this.renderHeader()}
        {this.renderBody()}
      </table>
    );
  }
}

MetaDataTable.propTypes = {
  data: propTypes.arrayOf(propTypes.shape({
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    amount: propTypes.number.isRequired,
  })).isRequired,
  isEditable: propTypes.bool,
  editionId: propTypes.string,
  onDeleteData: propTypes.func,
  onEditData: propTypes.func,
  onSaveData: propTypes.func,
  onCreateNewRow: propTypes.func, 
  onIsNewRowChange: propTypes.func, 
  isNewRow: propTypes.bool,
};

export default MetaDataTable;
