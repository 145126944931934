import React from 'react';
import PropTypes from 'prop-types';

import './DividerTextLine.scss';

class DividerTextLine extends React.Component {
  static defaultProps = {
    text: 'OR',
  };

  render() {
    const {text} = this.props;

    return (
      <div className="divider-line">{text}</div>
    );
  }
}

DividerTextLine.propTypes = {
  text: PropTypes.string,
};

export default DividerTextLine;
