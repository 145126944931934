import React from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router';
import {withRouter, Link} from 'react-router-dom';

import Tab from './Tab';
import Dropdown from './Dropdown';
import ImageCropper from './ImageCropper';
import ModifyTeam from './ModifyTeam';
import TeamMembersTable from './TeamMembersTable';
import DeckSelect from './DeckSelect';

import './index.scss';

class UI extends React.Component {
  render() {
    const {match} = this.props;

    const data = [
      {name: 'Tabs', url: '/tabs', component: Tab},
      {name: 'Dropdowns', url: '/dropdowns', component: Dropdown},
      {name: 'ImageCropper', url: '/image-cropper', component: ImageCropper},
      {name: 'TeamMembersTable', url: '/team-members-table', component: TeamMembersTable},
      {name: 'ModifyTeam', url: '/modify-team', component: ModifyTeam},
      {name: 'Deck Select', url: '/deck-select', component: DeckSelect},
    ];

    return (
      <div className="ui-page">
        <div className="container">
          <div className="row">
            <div className="col-2">
              <ul className="nav flex-column">
                {
                  data.map(item => {
                    return (
                      <li className="nav-item" key={item.name}>
                        <Link to={`${match.path}${item.url}`}>{item.name}</Link>
                      </li>
                    );
                  })
                }
              </ul>
            </div>

            <div className="col-10">
              <Switch>
                {
                  data.map(item => {
                    const Component = item.component;

                    return <Route key={item.name} path={`${match.path}${item.url}`} component={Component} />;
                  })
                }
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UI.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(UI);
