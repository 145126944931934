import {SUBMIT_DECK_FOR_TOURNAMENT} from '../../actions/types';

export const getTournaments = (nextActions = []) => ({
  type: SUBMIT_DECK_FOR_TOURNAMENT.TOURNAMENTS.START,
  payload: {
    nextActions,
  }
});

export const getTournamentsSuccess = (data) => ({
  type: SUBMIT_DECK_FOR_TOURNAMENT.TOURNAMENTS.SUCCESS,
  payload: data,
});

export const getTournamentsError = (data) => ({
  type: SUBMIT_DECK_FOR_TOURNAMENT.TOURNAMENTS.ERROR,
  payload: data,
});

export default {
  getTournaments,
  getTournamentsSuccess,
  getTournamentsError,
};
