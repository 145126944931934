import * as React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import Spinner from '../Spinner';
import TeamMembersDecklistEditTable from '../Tournament/Team/MembersDecklistTable/TeamMembersDecklistEditTable';

import teamTournamentActions from '../../actions/tournamentTeam';
import {createDraft} from '../../actions/deckActions';

class EditTeam extends React.Component {
  componentDidMount() {
    const teamId = this.getTeamId();

    this.props.getTournamentTeam(teamId);
  }

  onSubmit = (editedData) => {
    const teamId = this.getTeamId();

    this.props.editTournamentTeam(teamId, editedData);
  };

  onEditDecklist = (deckId) => {
    const teamId = this.getTeamId();

    this.props.createDraft(deckId, `/team/edit/${teamId}/decks/:id`);
  };

  getTeamId() {
    return this.props.match.params.teamId;
  }

  renderTable() {
    const data = this.props.tournamentTeam.data;

    const tableData = data.players.map(item => ({
      firstName: item.player.firstName,
      lastName: item.player.lastName,
      dciNumber: item.player.dciNumber,
      format: item.format.name,
      deckId: item.player.deck.id,
    }));

    return (
      <TeamMembersDecklistEditTable
        data={tableData}
        onSubmit={this.onSubmit}
        onEditDecklist={this.onEditDecklist}
      />
    );
  }

  render() {
    const {tournamentTeam} = this.props;

    if(tournamentTeam.get.isPending || !tournamentTeam.data) {
      return <Spinner/>;
    }

    return (
      <div className="container my-5">
        <div>
          <h4 className="section-header mb-5">
            <FormattedMessage id="tournament.edit.title"/>
          </h4>

          {this.renderTable()}
        </div>
      </div>

    );
  }
}

EditTeam.propTypes = {
  match: PropTypes.object.isRequired,

  tournamentTeam: PropTypes.object.isRequired,

  getTournamentTeam: PropTypes.func.isRequired,
  editTournamentTeam: PropTypes.func.isRequired,
  createDraft: PropTypes.func.isRequired,
};

export default withRouter(connect(
  state => ({
    tournamentTeam: state.tournamentTeam,
  }),
  {
    getTournamentTeam: teamTournamentActions.getTournamentTeam,
    editTournamentTeam: teamTournamentActions.editTournamentTeam,
    createDraft,
  }
)(EditTeam));
