class DeckValidationErrorManager {
  static getDeckErrorMessage(error) {
    switch(error.type) {
      case 'commander':
        return `Deck should contain ${error.amountLimit} commander card`;
      case 'maindeck':
        return `Maindeck should have at least ${error.amountLimit} cards, ${error.amount} cards added.`;
      case 'sideboard':
        return `Sideboard should have at most ${error.amountLimit} cards, ${error.amount} cards added.`;
    }
  }

  static getRestErrorMessage(errors) {
    const unique = errors.filter((item, index, arr) => arr.findIndex((arrItem) => arrItem.group === item.group) === index);

    if(unique.length === 0){
      return null;
    }

    return `Deck contains ${unique.length} invalid cards`;
  }

  static getAmount(validationData) {
    return Object.keys(validationData)
      .reduce((prev, current) => prev + validationData[current].length, 0);
  }
}

export default DeckValidationErrorManager;
