import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

import ExclamationMark from '../../src/assets/images/excl.svg';
import ExclamationMarkWhite from '../../src/assets/images/excl-white.svg';

import iconInfo from '../../src/assets/images/excl-info.svg';
import iconInfoDanger from '../../src/assets/images/excl-info-danger.svg';

const variants = {
  danger: ExclamationMark,
  info: ExclamationMarkWhite,
  iconInfo: iconInfo,
  iconInfoDanger: iconInfoDanger,
};

class GenericTooltip extends React.Component {
  static defaultProps = {
    variant: 'danger',
  };

  elem = null;

  constructor() {
    super();

    this.state = {
      isTooltipOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('touchstart', this.clickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.clickOutside);
  }

  clickOutside = (e) => {
    const {isTooltipOpen} = this.state;

    if(this.elem.contains(e.target)) {
      return;
    }

    if(!isTooltipOpen) {
      return;
    }

    this.close();
  };

  toggle = () => {
    this.setState({
      isTooltipOpen: !this.state.isTooltipOpen,
    });
  };

  open = () => {
    this.setState({
      isTooltipOpen: true,
    });
  };

  close = () => {
    this.setState({
      isTooltipOpen: false,
    });
  };

  render() {
    const { id, placement, message, className, variant, children } = this.props;
    return (
      <span ref={(elem) => this.elem = elem} className={'generic-tooltip'}>
        {children ?
          <span
            id={`deckTooltip-${id}`}
            onClick={this.toggle}
            onMouseOver={this.open}
            onMouseLeave={this.close}
          >
            {children}
          </span> :
          <img className="exclamation-mark"
            src={variants[variant]}
            id={`deckTooltip-${id}`}
            alt=""
            onClick={this.toggle}
            onMouseOver={this.open}
            onMouseLeave={this.close}
          />
        }
        <Tooltip className={classnames('tooltip-invalid', className)}
                 placement={placement}
                 isOpen={this.state.isTooltipOpen}
                 target={`deckTooltip-${id}`}>
          {message}
        </Tooltip>
      </span>
    );
  }
}

GenericTooltip.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  placement: PropTypes.string,
  children: PropTypes.node,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  variant: PropTypes.string,
};

export default GenericTooltip;
