import React from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';

import SimpleTopBar from '../../../Layout/SimpleTopBar';
import DateFormatted from '../../../DateFormatted/DateFormatted';
import { FormattedMessage } from 'react-intl';

import logo from '../../../../assets/images/logo.svg';
import registerSuccess from '../../../../assets/images/register-success.png';
import './Success.scss';

class Success extends React.Component {
  onClickRegister = () => {
    const {history} = this.props;

    history.push('/register');
  };

  render() {
    const {tournament} = this.props;

    return (
      <div className="page-join-tournament-unregistered-success">
        <SimpleTopBar />

        <div className="container-fluid fix-topbar-padding">
          <div className="row main-wrapper">
            <div className="col-12 col-lg-6 left">
              <img src={registerSuccess} alt="Register success"/>

              <p><FormattedMessage id="register.tournament.deck.success.text"/></p>

              <span><FormattedMessage id="register.tournament.deck.success.details"/></span>

              <div className="tournament-details-box">
                <h4>{tournament.name}</h4>
                <h5><DateFormatted value={tournament.startDate}/> {tournament.city}</h5>
              </div>
            </div>

            <div className="col-12 col-lg-6 right">
              <img src={logo} alt="Cardboard.live"/>

              <ul className="list-unstyled">
                <li><i className="icon-decklist"/><FormattedMessage id="register.tournament.decklist"/></li>
                <li><i className="icon-tournaments"/><FormattedMessage id="register.tournament.managment"/></li>
                <li><i className="icon-streaming-tools"/><FormattedMessage id="register.tournament.tools"/></li>
              </ul>

              <button className="btn btn-block btn-lg btn-primary" onClick={this.onClickRegister}><FormattedMessage id="register.tournament.btn.register"/></button>

              <Link to={`/login`}><FormattedMessage id="register.tournament.deck.success.gotologin"/></Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Success.propTypes = {
  history: PropTypes.object.isRequired,
  tournament: PropTypes.object.isRequired,
  passcode: PropTypes.string.isRequired,
};

export default withRouter(Success);
