export const getPublicDeckArenaFormat = (state) => {
  return {
    data: state.publicDecks.arenaFormat.data,
  };
};

export const getPublicDeckCards = (state) => {
  return {
    data: state.publicDecks.cardsData.data,
  };
};

export const getPublicDeckDeck = (state) => {
  return {
    data: state.publicDecks.deckData.data,
  };
};
