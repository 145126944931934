import { createLogic } from 'redux-logic';

import { CHANNEL_LIVE } from '../actions/types';

export const getChannelLive = createLogic({
  type: CHANNEL_LIVE.FETCH,
  latest: true,

  processOptions: {
    successType: CHANNEL_LIVE.SUCCESS,
    failType: CHANNEL_LIVE.ERROR,
  },

  process({ httpVodkaClient, action }) {
    return httpVodkaClient.get(`/api/v1/public/online_channels`, {
      params: action.payload,
    })
      .then(res => res.data);
  }
});

export default {
  getChannelLive
};
