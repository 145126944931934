import { UNREGISTERED_TEAM } from '../../actions/types';

const initialState = {
  team: null,
  token: null,
  error: null,
  createDeck: {
    deck: null,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch(action.type) {
    case UNREGISTERED_TEAM.CREATE.SUCCESS:
      return {
        ...state,
        team: {
          data: action.payload.data.team,
        }
      };
    case UNREGISTERED_TEAM.CREATE.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case UNREGISTERED_TEAM.GET.SUCCESS:
      return {
        ...state,
        team: {
          data: action.payload,
        },
      };
    case UNREGISTERED_TEAM.GET.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case UNREGISTERED_TEAM.DECK_CREATE.SUCCESS:
      return {
        ...state,
        createDeck: {
          ...state.createDeck,
          deck: action.payload,
        },
      };
    case UNREGISTERED_TEAM.DECK_CREATE.ERROR:
      return {
        ...state,
        createDeck: {
          ...state.createDeck,
          error: action.payload,
        },
      };
    case UNREGISTERED_TEAM.DECK_CREATE.RESET:
      return {
        ...state,
        createDeck: {
          ...state.createDeck,
          deck: null,
        },
      };
    case UNREGISTERED_TEAM.UPDATE.SUCCESS:
      return {
        ...state,
        team: {
          data: {
            ...action.payload.team,
            players: state.team.data.players,
          },
        },
      };
    case UNREGISTERED_TEAM.UPDATE.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
