import {rolesShortcut} from '../components/const';

export const displayUserRoles = (roles, divider, isShortcut) => {
  return roles
  .filter(item => item !== 'USER') 
  .map(item => {
    if(isShortcut) {
      return rolesShortcut[item];
    }
    return item.toLowerCase();
  }) 
  .join(divider);

};