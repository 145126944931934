import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import PieChart from 'recharts/lib/chart/PieChart';
import Pie from 'recharts/lib/polar/Pie';
import Cell from 'recharts/lib/component/Cell';
import Tooltip from 'recharts/lib/component/Tooltip';

import './DecksPieChart.scss';

class DecksPieChart extends React.Component {
  state = {
    activeIndex: null
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  getColor(index) {
    const {colors} = this.props;

    return ColorSchema.getColor(colors, index);
  }

  renderCell = (entry, index) => {
    return (
      <Cell key={index}
            fill={this.getColor(index)}
            stroke={'transparent'}/>
    );
  };

  renderLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload}) => {
    const {fullDisplayAmount, size} = this.props;

    const RADIAN = Math.PI / 180;
    const offsetFromCircle = 1.5;

    const radius  = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x       = cx + radius * offsetFromCircle * Math.cos(-midAngle * RADIAN);
    const y       = cy  + radius * offsetFromCircle * Math.sin(-midAngle * RADIAN);
    const isOnRight = x > cx;

    if(index > fullDisplayAmount - 1) {
      return null;
    }

    const circleSize = size.w / 100;
    const circleMargin = size.w / 45;
    const textMargin = size.h / 20;

    return (
      <g transform={`translate(${x} ${y})`}
         fill="#fff"
         textAnchor={isOnRight ? 'start' : 'end'}
         dominantBaseline="central"
         className="label">
        <circle cx={isOnRight ? -circleMargin : circleMargin}
                cy={2}
                r={circleSize}
                fill={this.getColor(index)} />

        <text x={0} y={0} className={'main'}>
          {`${(percent * 100).toFixed(0)}%`} {payload.name}
        </text>
        <text x={0} y={textMargin} className={'details'}>
          {payload.value} <FormattedMessage id="general.deck" values={{amount: payload.value}} tagName={'tspan'}/>
        </text>
      </g>
    );
  };

  renderTooltip = ({active, payload}) => {
    const {activeIndex} = this.state;
    const {fullDisplayAmount, data} = this.props;

    if(!active){
      return null;
    }
    if(activeIndex <= fullDisplayAmount - 1){
      return null;
    }

    const item      = payload[0].payload;
    const allDecks  = data.reduce((prev, current) => prev + current.value, 0);
    const percent   = (item.value / allDecks * 100).toFixed(0);

    return (
      <div className={'tooltip-chart'}>
        <span className={'dot'} style={{backgroundColor: this.getColor(activeIndex)}}/>
        <div className={'texts'}>
          <p className={'main'}>{percent}% {item.name}</p>
          <p className={'details'}>
            {item.value} <FormattedMessage id="general.deck" values={{amount: item.value}}/>
          </p>
        </div>
      </div>
    );
  };

  render () {
    const {data, size} = this.props;

    const radius = size.w * 0.3;

    const sortedData  = data.sort((a, b) => a.value < b.value);
    const allDecks    = data.reduce((prev, current) => prev + current.value, 0);

    return (
      <div className="decks-pie-chart">
        <PieChart width={size.w} height={size.h}>
          <Pie data={sortedData}
               label={this.renderLabel}
               dataKey={'value'}
               cx={'50%'}
               cy={'50%'}
               innerRadius={radius * 0.40}
               outerRadius={radius * 0.55}
               onMouseEnter={this.onPieEnter}>
            {data.map(this.renderCell)}
          </Pie>

          <Tooltip content={this.renderTooltip}/>
        </PieChart>

        <div className="info" style={{width: size.w, height: size.h}}>
          <h5>{allDecks}</h5>
          <p><FormattedMessage id="general.deck" values={{amount: allDecks}}/></p>
        </div>
      </div>
    );
  }
}

DecksPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number.isRequired,
  })).isRequired,
  size: PropTypes.shape({
    w: PropTypes.number.isRequired,
    h: PropTypes.number.isRequired,
  }).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  fullDisplayAmount: PropTypes.number,
};

export default DecksPieChart;

export const ColorSchema = {
  default: [
    '#24dbf2',
    '#ae85f8',
    '#f15757',
    '#afafaf',
    '#0288d1',
    '#e91e63',
    '#afe571',
    '#77a9c1',
    '#ff8f00',
    '#eae000',
  ],

  getColor: (schema, index) => schema[index % schema.length],
};
