import * as React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {connect} from 'react-redux';
import {getUsers} from '../../actions/usersActions';
import {deleteUser} from '../../actions/userActions';
import DataTableWithMobileDropdown from '../DataTable/DataTableWithMobileDropdown';
import Search from '../Search';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

import AccessControl from '../Utils/AccessControl';
import Pagination from '../Pagination/Pagination';

import {displayUserRoles} from '../../services/DisplayRoles';
import {roles, rolesShortcut} from '../const';
import Spinner from '../Spinner';

class UsersList extends React.Component {
  state = {
    isOpen: false,
    deleteId: null,
    searchQuery: null,
    selectedFilter: null,
  };

  componentDidMount() {
    this.getUsers(this.props.match.params.page);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.page !== this.props.match.params.page && !prevState.selectedFilter) {
      this.getUsers(this.props.match.params.page);
    }

    if (prevProps.match.params.page !== this.props.match.params.page && prevState.selectedFilter) {
      const filter = prevState.selectedFilter.map(item => item.value);
      this.props.getUsers(this.props.match.params.page, null, filter);
    }
  }

  getUsers(page, searchQuery=this.state.searchQuery){
    const filter = this.state.selectedFilter
      ? this.state.selectedFilter.map(item => item.value)
      : null;
    this.props.getUsers(page, searchQuery, filter);
  }

  submit = () => {
    this.props.deleteUser(this.state.deleteId);
    this.toggleModal(null, null);
  }

  toggleModal = (e, id) => {
    e && e.stopPropagation();

    this.setState({
      isOpen: !this.state.isOpen,
      deleteId: id
    });
  }

  goToEdit(e, id) {
    e && e.stopPropagation();
    this.props.history.push({
      pathname: `/users/show/${id}`,
      state: {isEdit: true}
    });
  }

  goToUser(id) {
    this.props.history.push('/users/show/' + id);
  }

  renderUsersList = () => {
    const users = this.props.users.data;

    if(!users || this.props.users.isLoad){
      return <Spinner/>;
    }

    if (!users || users.data.length < 1) {
      return <div className="text-center"><FormattedMessage id="users.no.users"/></div>;
    }

    const columns = [
      {name: 'DCI', flex: 1, key: 1},
      {name: 'LAST NAME', flex: 2, key: 2},
      {name: 'FIRST NAME', flex: 2, key: 3},
      {name: 'EMAIL', flex: 2, key: 4},
      {name: 'ROLE', flex: 1, key: 5},
      {name: 'EDIT', flex: 1, key: 6, isVisibleOnMobile: false},
      {name: 'DELETE', flex: 1, key: 7, isVisibleOnMobile: false},
    ];

    const data = users.data.map((item, index) => {
      return [
        `#${item.dciNumber}`,
        item.lastName,
        item.firstName,
        <span key={index + '-email'} className="user-email">{item.email}</span>,
        displayUserRoles(item.roles, ', ', true),
        <div key={index + '-edit'} className="tournament-link text-center" onClick={(e) => this.goToEdit(e, item.id)}><i
          className="icon-edit"/></div>,
        <div key={index + '-delete'} className="tournament-link text-center"
             onClick={(e) => this.toggleModal(e, item.id)}><i className="icon-delete"/></div>
      ];
    });

    const dropdownData = (dataRow, rowIndex) => [
      <span key={rowIndex + '-edit'} className="tournament-link tournament-link-mobile" onClick={(e) => this.goToEdit(e, users.data[rowIndex].id)}><FormattedMessage id="tournaments.btn.edit"/></span>,
      <span key={rowIndex + '-remove'} className="tournament-link tournament-link-mobile" onClick={(e) => this.toggleModal(e, users.data[rowIndex].id)}><FormattedMessage id="btn.delete"/></span>,
    ];

    return (
      <DataTableWithMobileDropdown
        columns={columns}
        data={data}
        onRowClick={(nr) => this.goToUser(users.data[nr].id)}
        dropdownData={dropdownData}
      />
    );
  }

  searchUser = (searchQuery) => {
    const {match} = this.props;
    const page = searchQuery && searchQuery.length >= 3 ? 1 : match.params.page;
    if(searchQuery !== this.state.searchQuery){
      this.getUsers(page, searchQuery);
    }
    this.setState({
      searchQuery
    });
  }

  selectFilter = (selected) => {
    this.setState({
      selectedFilter: selected
    });

    const filter = selected.map(item => item.value);
    this.props.history.push('/users/1');
    this.props.getUsers(1, this.state.searchQuery, filter);
  }

  renderPagination() {
    const {users} = this.props;
    const {searchQuery} = this.state;

    const total = users.data && users.data.headers && users.data.headers['x-last-page'];

    if(!total || searchQuery){
      return null;
    }
    return <Pagination totalPages={total} url={'/users/:page'}/>;
  }

  render() {
    const {users} = this.props;
    const {selectedFilter} = this.state;
    
    const rolesOptions = Object
      .keys(rolesShortcut)
      .filter(item => item !== 'ADMIN')
      .map(item => ({value: item, label: item}));

    if(!users){
      return null;
    }

    return (
      <AccessControl roles={[roles.admin]} forbiddenRedirect={'/'}>
        <div className="container my-5">
          <div>
            <h4 className="section-header mb-5"><FormattedMessage id="users.title"/></h4>
            <div className="row">
              <div className="mb-3 col-md-6">
                <Search onUpdate={this.searchUser} />
              </div>
              <div className="col-md-3 offset-md-3">
                <Select
                  className="react-select-group-flat"
                  options={rolesOptions}
                  value={selectedFilter}
                  onChange={this.selectFilter}
                  multi
                  placeholder="Default"
                />
              </div>
            </div>
            <div className="tournament-list mt-3">
              <div className="tournament-list-table-wrapper">
                {this.renderUsersList()}
              </div>
              <div className="pagination-wrapper">
                {this.renderPagination()}
              </div>

            </div>
            <Modal isOpen={this.state.isOpen} toggle={(e) => this.toggleModal(e, null)}>
              <ModalHeader toggle={(e) => this.toggleModal(e, null)}>Delete user</ModalHeader>
              <ModalBody>
                User will be delete. Are you sure?
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-success" onClick={this.submit}>Yes</button>
                <button className="btn btn-outline-secondary" onClick={(e) => this.toggleModal(e, null)}>No</button>
              </ModalFooter>
            </Modal>
          </div>
          <hr className="border-info"/>
        </div>
      </AccessControl>
    );
  }
}

UsersList.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.object,
  deleteUser: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    users: state.users,
  }),
  ({
    getUsers,
    deleteUser,
  })
)(UsersList);
