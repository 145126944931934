import React from 'react';
import StoresListOrganizer from './StoresListOrganizer';
import StoresListAdmin from './StoresListAdmin';
import AccessControl from '../Utils/AccessControl';
import { roles } from '../const';

import './StoresList.scss';

class StoresList extends React.Component {

  render() {
    return (
      <div className="container my-5">
        <AccessControl roles={[roles.organizer]}>
          <StoresListOrganizer/>
        </AccessControl>
        <AccessControl roles={[roles.admin]}>
          <StoresListAdmin/>
        </AccessControl>
      </div>
    );
  }
}

export default StoresList;
