import * as React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'react-router-redux';

import LoggedLayout from './Layout/LoggedLayout';
import PrivateRoute from './PrivateRoute';

import ResetPassword from './pages/ResetPassword';
import Login from './pages/Login';
import Register from './pages/Register';
import EmailConfirmation from './pages/EmailConfirmation';
import SuccessRegister from './pages/SuccessRegister';
import ChangePassword from './pages/ChangePassword';
import CheckEmail from './pages/CheckEmail';
import SuccessPasswordChange from './pages/SuccessPasswordChange';
import FailPasswordChange from './pages/FailPasswordChange';
import ScrollToTop from '../components/Utils/ScrollToTop';
import SharedDeck from './pages/SharedDeck/SharedDeckContainer';
import PublicDeckContainer from './pages/SharedDeck/PublicDeckContainer';

import AllModals from './modals/AllModals';

import UI from './pages/UI';

import JoinTournamentUnregistered from './pages/JoinTournamentUnregistered/Index';

import AuthService from '../services/AuthService';

class App extends React.Component {
  render() {
    return (
      <ConnectedRouter history={this.props.history}>
        <ScrollToTop>
          <AllModals/>

          {/*
            This path '/t/:passcode' is for logged users but, if unlogged user go there
            we want to redirect him to another route.

            Use <Redirect> component don't work in this case
          */}
          <Route exact path="/t/:passcode" render={(props) => {
            const isAuthenticated = AuthService.isLogged();

            if(isAuthenticated){
              return null;
            }

            location.href = `/join-tournament/${props.match.params.passcode}`;
          }}/>

          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/email_confirmation/:token" component={EmailConfirmation} />
            <Route path="/change_password/:token" component={ChangePassword} />
            <Route path="/change-password-success" component={SuccessPasswordChange} />
            <Route path="/change-password-fail" component={FailPasswordChange} />
            <Route path="/success-register" component={SuccessRegister} />
            <Route path="/check-email" component={CheckEmail}/>
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/shared-deck/:id" component={SharedDeck} />
            <Route path="/s/:name" component={PublicDeckContainer} />
            <Route path="/join-tournament/:passcode" component={JoinTournamentUnregistered} />

            {process.env.NODE_ENV === 'development' && <Route path="/ui" component={UI} />}

            <PrivateRoute path="/" component={LoggedLayout} />
          </Switch>
        </ScrollToTop>
      </ConnectedRouter>
    );
  }
}

App.propTypes = {
  history: PropTypes.object,
};

export default hot(module)(App);
