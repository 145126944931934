import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {connect} from 'react-redux';

import ModalConnector from '../Generic/Modals/ModalConnector';
import { FormattedMessage } from 'react-intl';
import {addStreamer} from './../../actions/streamersActions';

import names from './names';

class AddNewStreamerModal extends React.Component {
  state = {
    name: ''
  };

  onNameChange = (e) => {
    const value = e.target.value;
    this.setState({
      name: value
    });
  }

  onSubmit = (value) => {
    this.setState({
      name: ''
    });
    this.props.addStreamer({
      originalName: value,
      monetization: false,
      arenaSupport: true
    });
  };

  onClose = (onClose) => {
    this.setState({
      name: ''
    });
    onClose();
  };

  render() {
    const {name} = this.state;
    return (
      <ModalConnector name={names.ADD_NEW_STREAMER}>
        {
          ({isOpen, onClose}) => {
            return (
              <Modal isOpen={isOpen} toggle={() => this.onClose(onClose)} size="lg">
                <ModalHeader toggle={() => this.onClose(onClose)}><FormattedMessage id="streamers.add"/></ModalHeader>
                <ModalBody>
                  <div>
                    <input type="text" value={name} onChange={(e) => this.onNameChange(e)} className="form-control"/>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button disabled={!name} className="btn btn-success" onClick={() => this.onSubmit(name)}>
                    <FormattedMessage id="streamers.add.ok"/>
                  </button>
                  <button className="btn btn-outline-secondary" onClick={() => this.onClose(onClose)}>
                    <FormattedMessage id="streamers.add.cancel"/>
                  </button>
                </ModalFooter>
              </Modal>
            );
          }
        }
      </ModalConnector>
    );
  }
}

AddNewStreamerModal.propTypes = {
  addStreamer: PropTypes.func.isRequired
};

export default connect(
  null,
  ({
    addStreamer
  })
)(AddNewStreamerModal);
