import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

class ConfirmPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPasswordRepeated: false,
      isValid: false,
      isBlurred: false,
    };
  }

  componentDidUpdate(prevProps){
    if(prevProps.passwordToRepeat !== this.props.passwordToRepeat && (this.props.value.length > 0)) {
      this.validatePassword(this.props.value);
    }
  }

  changeValidation(value) {
    if (this.state.isValid !== value) {
      this.props.onValidationChanged(value);
    }
    this.setState({
      isValid: value,
    });
  }

  handleChange(value) {
    this.props.onChange(value);
  }

  validateLength(value) {
    if (value.length === 0) {
      return false;
    }
    return true;
  }

  validatePassword(value) {
    this.setState({
      isBlurred: true,
    });

    if (value.length >= 8 && value === this.props.passwordToRepeat) {
      this.setState({
        isPasswordRepeated: true,
        isValid: true,
      });
      this.props.onValidationChanged(true);
    } else {
      this.setState({
        isPasswordRepeated: false,
      });
      this.props.onValidationChanged(false);
    }
  }

  renderValidation() {
    const { isPasswordRepeated, isBlurred } = this.state;

    if (isPasswordRepeated) {
      return <div className="valid-feedback"><FormattedMessage id="register.confirm.password.valid"/></div>;
    }
    if (isBlurred && !isPasswordRepeated) {
      return <div className="invalid-feedback"><FormattedMessage id="register.confirm.password.invalid"/></div>;
    }
  }

  render() {
    const { isPasswordRepeated, isBlurred } = this.state;

    const isValid = isPasswordRepeated;
    const isInvalid = isBlurred && !isPasswordRepeated;
    const inputClass = classNames('form-control', 'register-form__input', {
      'is-valid': isValid,
      'is-invalid': isInvalid,
    });
    return (
      <React.Fragment>
        <input
          type="password"
          className={inputClass}
          value={this.props.value}
          onChange={e => this.handleChange(e.target.value)}
          onBlur={e => this.validatePassword(e.target.value)}
        />
        {this.renderValidation()}
      </React.Fragment>
    );
  }
}

ConfirmPassword.propTypes = {
  onValidationChanged: PropTypes.func.isRequired,
  passwordToRepeat: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default ConfirmPassword;
