import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import { addCustomCard } from '../../../actions/cardsAction';
import plus from '../../../assets/images/svg/plus.svg';

class AddCustomCards extends React.Component {
  state = {
    isModalOpen: false,
    card: {
      name: '',
    }
  };

  handleChange = (e) => {
    const cardName = e.target.value;
    this.setState({
      card: {
        name: cardName
      }
    });
  }

  openModal = () => {
    this.setState({
      isModalOpen: true
    });
  }

  closeModal = () => {
    this.setState({
      card: {
        name: ''
      },
      isModalOpen: false
    });
  }

  addCustomCards = (number) => {
    const {deckId, activeNavIndex, addCustomCard, deckFormat, inModal, userDeck} = this.props;
    const {card} = this.state;

    addCustomCard({
      deckId: deckId,
      format: deckFormat,
      formatSectionIndex: activeNavIndex,
      cardName: card.name,
      amount: number,
      inModal: inModal,
      userDeck: userDeck
    });

    this.closeModal();
  }

  renderModalBody = () => {
    const {card} = this.state;

    const disableBtn = !card.name;

    return (
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <label className="create-decklist__form-label d-none d-lg-block"><FormattedMessage id="add.custom.cards.name.text"/></label>
          </div>
        </div>
        <div className="row">
            <div className="col-12">
              <div className="form-group d-none d-lg-block">
                <input
                  onChange={e => this.handleChange(e)}
                  value={card.name}
                  className="form-control col-lg-12 add-deck__form-input text-center text-lg-left" type="text"
                  />
              </div>
            </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card-search__add-cards pb-3">
              <button
                className="btn btn-form btn-primary add-cards__btn"
                type="button"
                onClick={() => this.addCustomCards(1)}
                disabled={disableBtn}
              >+1</button>
              <button
                className="btn btn-form btn-primary add-cards__btn"
                type="button"
                onClick={() => this.addCustomCards(2)}
                disabled={disableBtn}
              >+2</button>
              <button
                className="btn btn-form btn-primary add-cards__btn"
                type="button"
                onClick={() => this.addCustomCards(3)}
                disabled={disableBtn}
              >+3</button>
              <button
                className="btn btn-form btn-primary add-cards__btn"
                type="button"
                onClick={() => this.addCustomCards(4)}
                disabled={disableBtn}
              >+4</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {isModalOpen} = this.state;

    return (
      <div>
        <div className="custom-link">
          <img className="icon" src={plus}/> <span onClick={this.openModal} className="link"><FormattedMessage  id="add.cutom.cards"/></span>
        </div>
        <Modal isOpen={isModalOpen} size={'lg'} toggle={this.closeModal} className="deck-from-text-modal">
          <ModalHeader toggle={this.closeModal}>
            <span className="section-header"><FormattedMessage id="add.custom.cards.header"/></span>
          </ModalHeader>

          <ModalBody>
            {this.renderModalBody()}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AddCustomCards.propTypes = {
  inModal: PropTypes.bool,
  addCustomCard: PropTypes.func.isRequired,
  activeNavIndex: PropTypes.number.isRequired,
  deckId: PropTypes.string.isRequired,
  deckFormat: PropTypes.object.isRequired,
  userDeck: PropTypes.bool,
};



export default connect(
  null,
  ({
    addCustomCard
  })
)(AddCustomCards);
