import {createLogic} from 'redux-logic';
import {TOURNAMENT_TEAM} from '../actions/types';

export const get = createLogic({
  type: TOURNAMENT_TEAM.GET.START,

  processOptions: {
    successType: TOURNAMENT_TEAM.GET.SUCCESS,
    failType: TOURNAMENT_TEAM.GET.ERROR,
  },

  latest: true,

  process({httpClient, action}) {
    const {teamId} = action.payload;

    return httpClient.get(`/api/v1/teams/${teamId}`)
      .then(res => res.data);
  }
});

export const edit = createLogic({
  type: TOURNAMENT_TEAM.EDIT.START,

  processOptions: {
    successType: TOURNAMENT_TEAM.EDIT.SUCCESS,
    failType: TOURNAMENT_TEAM.EDIT.ERROR,
  },

  latest: true,

  process({httpClient, action}) {
    const {teamId, data} = action.payload;

    return httpClient.put(`/api/v1/teams/${teamId}/players`, data)
      .then(res => res.data);
  }
});

export const editSuccess = createLogic({
  type: TOURNAMENT_TEAM.EDIT.SUCCESS,

  process({genericActions}, dispatch, done) {
   dispatch(genericActions.notification.add({
     type: 'success',
     intlKey: 'notifications.general.success',
   }));
   done();
  }
});

export const editError = createLogic({
  type: TOURNAMENT_TEAM.EDIT.ERROR,

  process({genericActions}, dispatch, done) {
    dispatch(genericActions.notification.add({
      type: 'danger',
      intlKey: 'notifications.general.error',
    }));
    done();
  }
});

export default [
  get,
  edit,
  editSuccess,
  editError,
];
