import React from 'react';
import {Modal} from 'reactstrap';

import NotifierModal from '../../Notifier/NotifierModal';

class ModalWithNotifier extends React.Component {
  render() {
    const {children, ...props} = this.props;

    return (
      <Modal {...props}>
        <NotifierModal/>

        {children}
      </Modal>
    );
  }
}

ModalWithNotifier.propTypes = {
  ...Modal.propTypes,
};

export default ModalWithNotifier;
