import {createLogic} from 'redux-logic';
import {TOURNAMENT_TEAMS_STANDINGS} from '../actions/types';

import {updateTournamentTeamsStandingsSuccess, updateTournamentTeamsStandingsError} from '../actions/tournamentTeamsStandings';

export const get = createLogic({
  type: TOURNAMENT_TEAMS_STANDINGS.GET.START,

  processOptions: {
    successType: TOURNAMENT_TEAMS_STANDINGS.GET.SUCCESS,
    failType: TOURNAMENT_TEAMS_STANDINGS.GET.ERROR,
  },

  debounce: 300,
  latest: true,

  process({httpClient, action}) {
    const {tournamentId, options} = action.payload;
    const getTeams = httpClient.get(`/api/v1/team_tournaments/${tournamentId}/teams`, {
      params: {
        page: options.page,
        name: options.filters.name,
      }
    })
      .then((res) => ({
        pagination: {
          lastPage: res.headers['x-last-page'],
          page:     res.headers['x-page'],
          perPage:  res.headers['x-per-page'],
          total:    res.headers['x-total'],
        },
        data: res.data,
      }));

    const getExtras = httpClient.get(`/api/v1/tournaments/${tournamentId}/tournament_extras`)
      .then((res) => res.data);
    
      return Promise.all([
        getTeams,
        getExtras,
      ])
        .then(([teams, extras]) => {
          return {
            teams,
            extras,
          };
        });

  }
});

export const update = createLogic({
  type: TOURNAMENT_TEAMS_STANDINGS.UPDATE.START,
  latest: true,

  process({httpClient, action, genericActions}, dispatch, done) {
    const {tournamentId, data} = action.payload;
    const {teams, extras} = data;

    const isUpdateTeams = Object.keys(teams).length > 0;
    const isUpdateExtras  = Object.keys(extras).length > 0;

    const updateTeams = !isUpdateTeams
      ? null
      : Promise.all(Object.keys(teams).map(teamId => {
          const teamData = teams[teamId];

          return httpClient.patch(`/api/v1/teams/${teamId}`, teamData)
            .then(res => res.data)
            .then(data => ({
              id: teamId,
              isError: false,
              data,
            }))
            .catch(err => ({
              id: teamId,
              isError: true,
              data: {
                status: err.response.status,
                data: err.response.data,
              },
            }));
        }));

    const updateExtras = !isUpdateExtras
      ? null
      : httpClient.post(`/api/v1/tournaments/${tournamentId}/tournament_extras`, extras)
          .then(res => res.data)
          .then(data => ({
            isError: false,
            data,
          }))
          .catch(err => ({
            isError: true,
            data: {
              status: err.response.status,
              data: err.response.data,
            },
          }));

    return Promise.all([
      updateTeams,
      updateExtras,
    ])
      .then(([teams, extras]) => {
        const teamsErrors   = teams ? teams.filter(teamData => teamData.isError) : [];
        const teamsSuccess  = teams ? teams.filter(teamData => !teamData.isError) : [];

        const teamsReduce = (prev, current) => {
          prev[current.id] = current.data;

          return prev;
        };

        const errors = {
          extras: extras && extras.isError ? extras.data : null,
          teams: teamsErrors.reduce(teamsReduce, {}),
        };

        const successes = {
          extras: extras && !extras.isError ? extras.data : null,
          teams: teamsSuccess.reduce(teamsReduce, {}),
        };

        const isError   = errors.extras !== null || teamsErrors.length > 0;
        const isSuccess = successes.extras !== null || teamsSuccess.length > 0;

        if(isError) {
          dispatch(updateTournamentTeamsStandingsError(errors));
        }
        if(isSuccess) {
          dispatch(updateTournamentTeamsStandingsSuccess(successes));
        }

        if(isError) {
          dispatch(genericActions.notification.add({
            type: 'danger',
            intlKey: 'standings.update.notification.error',
          }));
        }
        else {
          dispatch(genericActions.notification.add({
            type: 'success',
            intlKey: 'standings.update.notification.success',
          }));
        }

        done();
      });
  }
});

export default [
  get,
  update,
];
