import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import Prompt from '../../../Generic/Modals/Prompt';

class TournamentUserDeleteModal extends React.Component {
  render() {
    const {isOpen, toggle, onSubmit, titleIntl, textIntl} = this.props;

    return (
      <React.Fragment>
        <Prompt
          isOpen={isOpen}
          onSubmit={onSubmit}
          onClose={toggle}
          title={<FormattedMessage id={titleIntl ? titleIntl : 'tournament.players.delete'} />}
          submitClassName={'btn-danger'}
        >
          <FormattedMessage id={textIntl ? textIntl : 'tournament.players.remove.text'} />
        </Prompt>
      </React.Fragment>
    );
  }
}

TournamentUserDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  titleIntl: PropTypes.string,
  textIntl: PropTypes.string,

};

export default TournamentUserDeleteModal;
