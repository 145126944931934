import { applyMiddleware, createStore, compose } from 'redux';
import { createLogicMiddleware } from 'redux-logic';
import { routerMiddleware } from 'react-router-redux';

import reducers from '../reducers/index';
import logic from '../logic/index';

import logicDep from './logicDep';

const isDevTools = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined;

const logicChunkSize = 100;

const getEnhancer = (history) => {
  // CBL-5064
  // splitting the array of logic and creating multiple middlewares to avoid
  // "Uncaught InternalError: too much recursion" on firefox on "/s/streamerName" page
  // https://github.com/jeffbski/redux-logic/issues/83#issuecomment-623204978

  const logicMiddlewares = [];

  for (let i = 0; i < logic.length; i += logicChunkSize) {
    const chunk = logic.slice(i, i + logicChunkSize);
    logicMiddlewares.push(createLogicMiddleware(chunk, logicDep));
  }

  const reactRouterMiddleware = routerMiddleware(history);

  const middleware = applyMiddleware(...logicMiddlewares, reactRouterMiddleware);

  return isDevTools
    ? compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__())
    : middleware;
};

export default (history) => {
  return createStore(reducers, getEnhancer(history));
};
