import React from 'react';

import ModifyTeam from '../../ModifyTeam/ModifyTeam';
import ModalModifyTeam from '../../ModifyTeam/ModalModifyTeam';
import ModalEditTeam from '../../ModifyTeam/ModalEditTeam';
import ModalAddTeam from '../../ModifyTeam/ModalAddTeam';

class ModifyTeamUI extends React.Component {
  state = {
    isOpenModal1: false,
    isOpenModal2: false,
    isOpenModal3: false,
    isOpenModal4: false,
  };

  render() {
    const players = [
      {firstName: 'A', lastName: 'B', dciNumber: 'asd', deckFormat: 'Modern'},
      {firstName: 'C', lastName: 'D', dciNumber: 'asd', deckFormat: 'Standard'},
      {firstName: 'E', lastName: 'F', dciNumber: 'asd', deckFormat: 'Legacy'},
    ];
    const players2 = [
      {firstName: 'A', lastName: 'B', dciNumber: 'asd', deckFormat: 'Modern'},
      {firstName: 'C', lastName: 'D', dciNumber: 'asd', deckFormat: 'Modern'},
      {firstName: 'E', lastName: 'F', dciNumber: 'asd', deckFormat: 'Modern'},
    ];

    const playersWithDecks = [
      {firstName: 'A', lastName: 'B', dciNumber: 'asd', deckFormat: 'Modern', deckId: null},
      {firstName: 'C', lastName: 'D', dciNumber: 'asd', deckFormat: 'Standard', deckId: 's2'},
      {firstName: 'E', lastName: 'F', dciNumber: 'asd', deckFormat: 'Legacy', deckId: null},
    ];
    const decks = {
      'Modern': [
        {name: 'Some Modern 1', id: 'm1', valid: true},
        {name: 'Some Modern 2', id: 'm2', valid: false},
      ],
      'Standard': [
        {name: 'Some Standard 1', id: 's1', valid: false},
        {name: 'Some Standard 2', id: 's2', valid: false},
      ],
      'Legacy': [
        {name: 'Some Legacy 1', id: 'l1', valid: true},
        {name: 'Some Legacy 2', id: 'l2', valid: true},
      ]
    };

    return (
      <div>
        <details>
          <summary>1. ModifyTeam</summary>

          <ModifyTeam
            players={players}
            onSubmit={() => {}}
            onCancel={() => {}}
          />
        </details>

        <details>
          <summary>2. ModifyTeam with the same deckFormat</summary>

          <ModifyTeam
            players={players2}
            onSubmit={() => {}}
            onCancel={() => {}}
          />
        </details>

        <details>
          <summary>3. ModifyTeam - change text + delete button</summary>

          <ModifyTeam
            players={players}
            title="Some title"
            submitText="Button submit"
            cancelText="Button cancel"
            deleteText="Button delete"
            onSubmit={() => {}}
            onCancel={() => {}}
            onDelete={() => {}}
          />
        </details>

        <details>
          <summary>4. ModifyTeam with errors</summary>

          <ModifyTeam
            players={players}
            onSubmit={() => {}}
            onCancel={() => {}}
            errors={{
              0: {
                dciNumber: 'wrong DCI'
              },
              2: {
                lastName: 'Some error here'
              }
            }}
          />
        </details>

        <details>
          <summary>5. ModifyTeam with select deck</summary>

          <ModifyTeam
            players={playersWithDecks}
            onSubmit={(data) => {
              // eslint-disable-next-line no-console
              console.log('submit', data);
            }}
            onCancel={() => {}}
            decks={decks}
            errors={{
              0: {
                deckId: 'Some deck error'
              },
            }}
          />
        </details>

        <details>
          <summary>6. ModalModifyTeam</summary>

          <button className="btn btn-small" onClick={() => this.setState({isOpenModal1: true})}>Open</button>

          <ModalModifyTeam
            isOpen={this.state.isOpenModal1}
            players={players}
            onSubmit={() => {}}
            onCancel={() => this.setState({isOpenModal1: false})}
          />
        </details>

        <details>
          <summary>7. ModalModifyTeam with select deck</summary>

          <button className="btn btn-small" onClick={() => this.setState({isOpenModal2: true})}>Open</button>

          <ModalModifyTeam
            isOpen={this.state.isOpenModal2}
            players={playersWithDecks}
            decks={decks}
            onSubmit={() => {}}
            onCancel={() => this.setState({isOpenModal2: false})}
          />
        </details>

        <details>
          <summary>8. ModalEditTeam (with provided texts)</summary>

          <button className="btn btn-small" onClick={() => this.setState({isOpenModal3: true})}>Open</button>

          <ModalEditTeam
            isOpen={this.state.isOpenModal3}
            players={playersWithDecks}
            decks={decks}
            onSubmit={() => {}}
            onDelete={() => {}}
            onCancel={() => this.setState({isOpenModal3: false})}
          />
        </details>

        <details>
          <summary>9. ModalAddTeam (with provided texts)</summary>

          <button className="btn btn-small" onClick={() => this.setState({isOpenModal4: true})}>Open</button>

          <ModalAddTeam
            isOpen={this.state.isOpenModal4}
            players={playersWithDecks}
            decks={decks}
            onSubmit={() => {}}
            onCancel={() => this.setState({isOpenModal4: false})}
          />
        </details>

      </div>
    );
  }
}

export default ModifyTeamUI;
