import React from 'react';

import TeamMembersDecklistTable from '../../Tournament/Team/MembersDecklistTable/TeamMembersDecklistTable';
import TeamMembersDecklistEditTable from '../../Tournament/Team/MembersDecklistTable/TeamMembersDecklistEditTable';
import TeamMembersDecklistCreateTable from '../../Tournament/Team/MembersDecklistTable/TeamMembersDecklistCreateTable';

class TeamMembersTableUI extends React.Component {
  render() {
    const data = [
      { firstName: '1', lastName: '2', dciNumber: '3', format: 'Legacy', deckId: null},
      { firstName: '1', lastName: '2', dciNumber: '3', format: 'Modern', deckId: '123'},
      { firstName: '1', lastName: '2', dciNumber: '3', format: 'Standard', deckId: null},
    ];

    return (
      <div>
        <h5>1. Generic - TeamMembersDecklistTable</h5>

        <TeamMembersDecklistTable
          data={data}
          buttonSubmitText="Submit"
          submitDisabled={true}
          dropdownData={() => ['Option 1', 'Option 2']}
          onSubmit={() => {}}
        >
            {
              (item) => [
                item.firstName,
                item.lastName,
                item.dciNumber,
                item.format,
                item.deckId ? 'Yes' : '-',
              ]
            }
        </TeamMembersDecklistTable>

        <h5>2. Edit - TeamMembersDecklistEditTable</h5>

        <TeamMembersDecklistEditTable
          data={data}
          onSubmit={(editedData) => {
            // eslint-disable-next-line no-console
            console.log('submit', editedData);
          }}
          onEditDecklist={(deckId) => {
            // eslint-disable-next-line no-console
            console.log('edit', deckId);
          }}
        />

        <h5>3. Create - TeamMembersDecklistCreateTable</h5>

        <TeamMembersDecklistCreateTable
          data={data}
          onSubmit={(editedData) => {
            // eslint-disable-next-line no-console
            console.log('submit', editedData);
          }}
          onEditDecklist={(deckId) => {
            // eslint-disable-next-line no-console
            console.log('edit', deckId);
          }}
          onCreateDecklist={() => {
            // eslint-disable-next-line no-console
            console.log('create');
          }}
        />
      </div>
    );
  }
}

export default TeamMembersTableUI;
