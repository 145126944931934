import {TOURNAMENT_USER, TOURNAMENT_SHADOW_USER_UPDATE} from './types';

export const get = (tournamentId, userId) => ({
  type: TOURNAMENT_USER.GET,
  payload: {
    tournamentId,
    userId,
  }
});

export const clear = () => ({
  type: TOURNAMENT_USER.CLEAR,
});

export const set = (data) => ({
  type: TOURNAMENT_USER.SET,
  payload: data,
});

export const update = (data) => ({
  type: TOURNAMENT_USER.UPDATE,
  payload: data,
});

export const setError = (error) => ({
  type: TOURNAMENT_USER.SET_ERROR,
  payload: error,
});

export const createUserError = (error) => ({
  type: TOURNAMENT_USER.CREATE_USER_ERROR,
  payload: error,
});

export const create = (tournamentId, data, redirectUrl, callback) => ({
  type: TOURNAMENT_USER.CREATE,
  payload: {
    tournamentId,
    data,
    redirectUrl,
    callback,
  }
});

export const saveDeckType = ({membershipId, paperdeck, biography, redirectUrl}) => ({
  type: TOURNAMENT_USER.SAVE_DECK_TYPE,
  payload: {
    membershipId,
    paperdeck,
    biography,
    redirectUrl,
  },
});

export const createDeck = ({masterDeckId, userId, tournamentId}) => ({
  type: TOURNAMENT_USER.CREATE_DECK.START,
  payload: {
    masterDeckId,
    userId,
    tournamentId,
  },
});

export const createDeckSuccess = (data) => ({
  type: TOURNAMENT_USER.CREATE_DECK.SUCCESS,
  payload: data
});

export const createDeckError = (err) => ({
  type: TOURNAMENT_USER.CREATE_DECK.ERROR,
  payload: err
});

export const updateShadowUser = (tournamentId, userId, data) => ({
  type: TOURNAMENT_SHADOW_USER_UPDATE.START,
  payload: {
    tournamentId,
    userId,
    data
  }
});
