import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, FormattedDate} from 'react-intl';
import {hiddenSideboard, customCards} from '../../components/const';

class TournamentShow extends React.Component {
  getLabelBasedOnValue = (value, data) => {
    if(!data){
      return null;
    }

    const element = data.find(item => item.value === value);

    return element ? element.label : null;
  };

  render() {
    const {tournament, states, countries, renderButton} = this.props;

    const biographyType = tournament.bioSchema && tournament.bioSchema.name;

    return (
      <form className="tournament container pb-5 mb-5">
        <div className="tournament-form mt-5">
          <h4 className="section-header mb-5">
            <span><FormattedMessage id="tournament.info.text"/></span>

            {renderButton && renderButton()}
          </h4>

          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="tournament-overview__wrapper">
                    <label className="text-uppercase tournament-overview__title">
                      <FormattedMessage id="tournament.label.store"/>
                    </label>
                    <div className="mb-2 ml-2 tournament-overview__info">
                      {tournament.store && tournament.store.name || '-'}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tournament-overview__wrapper">
                    <label className="text-uppercase tournament-overview__title">
                      <FormattedMessage id="tournament.label.date"/>
                    </label>
                    <div className="mb-2 ml-2 tournament-overview__info">
                      <FormattedDate value={tournament.startDate}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="tournament-overview__wrapper">
                    <label className="text-uppercase tournament-overview__title">
                      <FormattedMessage id="tournament.label.type"/>
                    </label>
                    <div className="mb-2 ml-2 tournament-overview__info">
                      { tournament.type ? <FormattedMessage id={`tournament_type.${tournament.type}`}/> : '-' }
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tournament-overview__wrapper">
                    <label className="text-uppercase tournament-overview__title">
                      <FormattedMessage id="tournament.label.format"/>
                    </label>
                    <div className="mb-2 ml-2 tournament-overview__info">
                      {tournament.format || '-'}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tournament-overview__wrapper">
                    <label className="text-uppercase tournament-overview__title">
                      <FormattedMessage id="tournament.label.bio"/>
                    </label>
                    <div className="mb-2 ml-2 tournament-overview__info">
                      {biographyType && <FormattedMessage id={`tournament.bioSchema.${biographyType}`}/> || '-'}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="tournament-overview__wrapper">
                    <label className="text-uppercase tournament-overview__title">
                      <FormattedMessage id="tournament.label.city"/>
                    </label>
                    <div className="mb-2 ml-2 tournament-overview__info">
                      {tournament.city || '-'}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tournament-overview__wrapper">
                    <label className="text-uppercase tournament-overview__title">
                      <FormattedMessage id="tournament.label.state"/>
                    </label>
                    <div className="mb-2 ml-2 tournament-overview__info">
                      {this.getLabelBasedOnValue(tournament.state, states)}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tournament-overview__wrapper">
                    <label className="text-uppercase tournament-overview__title">
                      <FormattedMessage id="tournament.label.country"/>
                    </label>
                    <div className="mb-2 ml-2 tournament-overview__info">
                      {this.getLabelBasedOnValue(tournament.country, countries)}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tournament-overview__wrapper">
                    <label className="text-uppercase tournament-overview__title">
                      <FormattedMessage id="tournament.label.customCards"/>
                    </label>
                    <div className="mb-2 ml-2 tournament-overview__info">
                      {this.getLabelBasedOnValue(tournament.customCards || false, customCards)}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tournament-overview__wrapper">
                    <label className="text-uppercase tournament-overview__title">
                      <FormattedMessage id="tournament.label.hiddenSideboard"/>
                    </label>
                    <div className="mb-2 ml-2 tournament-overview__info">
                      {this.getLabelBasedOnValue(tournament.hiddenSideboard || false, hiddenSideboard)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

TournamentShow.propTypes = {
  tournament: PropTypes.shape({
    store: PropTypes.shape({
      name: PropTypes.string,
    }),
    startDate: PropTypes.string,
    format: PropTypes.string,
    type: PropTypes.string,
    bioSchema: PropTypes.shape({
      avatar: PropTypes.bool,
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  states: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  renderButton: PropTypes.func.isRequired,
};

export default TournamentShow;
