import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import reset from './authReducer/resetPasswordReducer';
import login from './authReducer/login';
import logout from './authReducer/logout';
import user from './authReducer/user';
import search from './cardSearchReducer';
import createDeck from './createDeck';
import createDraft from './createDraft';
import formats from './formats';
import decks from './decks';
import userDecks from './userDecks';
import decksPatch from './decksPatch';
import fetchDeck from './fetchDeck';
import saveDeck from './saveDeck';
import savedTextDeck from './savedTextDeck';
import importedDeck from './importedDeck';
import tournament from './tournament';
import tournaments from './tournaments';
import tournamentsAll from './tournamentsAll';
import tournamentsPlayer from './tournamentsPlayer';
import tournamentsJudge from './tournamentsJudge';
import savedTournament from './savedTournament';
import change from './authReducer/changePasswordReducer';
import countries from './countries';
import states from './states';
import users from './users';
import singleUser from './singleUser';
import tournamentMembers from './tournamentMembers';
import deckCards from './cardsReducer';
import tournamentRoles from './tournamentRoles';
import notification from './notification';
import stores from './stores';
import storesAll from './storesAll';
import createStore from './createStore';
import storeDetails from './storeDetails';
import deckValidation from './deckValidation';
import joinTournamentModal from './joinTournamentModal';
import publicDecks from './publicDecks';
import deckNames from './deckNames';
import deckNameDetails from './deckNameDetails';
import deckNameFormat from './deckNameFormat';
import adminStatistics from './adminStatistics';
import organizerStatistics from './organizerStatistics';
import tournamentUser from './tournamentUser';
import userPersonalToken from './userPersonalToken';
import unregisterdPlayer from './unregisterdPlayer';
import playersImport from './playersImport';
import standingsImport from './standingsImport';
import metaData from './metaData';
import cardPreview from './cardPreview';

import biography from './biography';
import biographyMember from './biographyMember';
import bioSchemas from './bioSchemas';

import joinTournamentSimple from './joinTournamentSimple';
import submitDeckForTournament from './submitDeckForTournament/index';
import editTournamentPlayer from './editTournamentPlayer';
import selectTournamentPlayerBio from './selectTournamentPlayerBio';
import tournamentStandings from './tournamentStandings';
import tournamentTypes from './tournamentTypes';
import tournamentTeams from './tournamentTeams';
import tournamentTeamsStandings from './tournamentTeamsStandings';
import tournamentTeamDeckFormats from './tournamentTeamDeckFormats';
import modal from './modal';
import joinTeamTournament from './joinTeamTournament';
import tournamentTeam from './tournamentTeam';
import streamers from './streamers';
import shortenerUrl from './shortenerUrl';
import arenaFormat from './arenaFormat';
import recentDecks from './recentDecks';
import teamsImport from './teamsImport';
import streamersDecks from './streamersDecks';
import streamersInfo from './streamersInfo';
import linkedAccounts from './linkedAccounts';
import channelLive from './channelLiveReducer';

export default combineReducers({
  reset,
  login,
  logout,
  user,
  createDeck,
  createDraft,
  search,
  decks,
  userDecks,
  decksPatch,
  fetchDeck,
  saveDeck,
  savedTextDeck,
  importedDeck,
  formats,
  tournament,
  tournaments,
  tournamentsAll,
  tournamentsPlayer,
  tournamentsJudge,
  savedTournament,
  change,
  countries,
  states,
  users,
  singleUser,
  tournamentMembers,
  deckCards,
  tournamentRoles,
  notification,
  stores,
  storesAll,
  createStore,
  storeDetails,
  deckValidation,
  joinTournamentModal,
  metaData,
  deckNames,
  deckNameDetails,
  deckNameFormat,
  publicDecks,
  adminStatistics,
  organizerStatistics,
  tournamentUser,
  userPersonalToken,
  unregisterdPlayer,
  playersImport,
  standingsImport,
  biography,
  biographyMember,
  bioSchemas,
  joinTournamentSimple,
  submitDeckForTournament,
  editTournamentPlayer,
  selectTournamentPlayerBio,
  tournamentStandings,
  tournamentTypes,
  tournamentTeams,
  tournamentTeamsStandings,
  tournamentTeamDeckFormats,
  joinTeamTournament,
  tournamentTeam,
  modal,
  cardPreview,
  routing: routerReducer,
  streamers,
  shortenerUrl,
  arenaFormat,
  recentDecks,
  teamsImport,
  streamersDecks,
  streamersInfo,
  linkedAccounts,
  channelLive
});
