import {UNREGISTERED_PLAYER} from './types';

export const create = (passcode, userData, nextActions = []) => ({
  type: UNREGISTERED_PLAYER.CREATE.START,
  payload: {
    passcode,
    userData,
    nextActions,
  },
});

export const createSuccess = (data) => ({
  type: UNREGISTERED_PLAYER.CREATE.SUCCESS,
  payload: data,
});

export const createError = (data) => ({
  type: UNREGISTERED_PLAYER.CREATE.ERROR,
  payload: data,
});

export const update = (userId, userData) => ({
  type: UNREGISTERED_PLAYER.UPDATE.START,
  payload: {
    userId,
    userData,
  },
});

export const updateSuccess = (data) => ({
  type: UNREGISTERED_PLAYER.UPDATE.SUCCESS,
  payload: data,
});

export const updateError = (data) => ({
  type: UNREGISTERED_PLAYER.UPDATE.ERROR,
  payload: data,
});

export const initDeckCreator = (tournamentId) => ({
  type: UNREGISTERED_PLAYER.INIT_DECK_CREATOR.START,
  payload: {
    tournamentId,
  }
});

export const initDeckCreatorSuccess = () => ({
  type: UNREGISTERED_PLAYER.INIT_DECK_CREATOR.SUCCESS,
});

export const initDeckCreatorError = (err) => ({
  type: UNREGISTERED_PLAYER.INIT_DECK_CREATOR.ERROR,
  payload: err,
});

export const setPlayer = (player) => ({
  type: UNREGISTERED_PLAYER.SET_PLAYER,
  payload: player,
});

export const setDeck = (deck) => ({
  type: UNREGISTERED_PLAYER.SET_DECK,
  payload: deck,
});

export default {
  create,
  createSuccess,
  createError,

  update,
  updateSuccess,
  updateError,

  initDeckCreator,
  initDeckCreatorSuccess,
  initDeckCreatorError,

  setPlayer,
  setDeck,
};
