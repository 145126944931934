import { createLogic } from 'redux-logic';

import { CARD_SEARCH } from '../actions/types';

export const get = createLogic({
  type: CARD_SEARCH.FETCH,
  latest: true,

  processOptions: {
    successType: CARD_SEARCH.SUCCESS,
    failType: CARD_SEARCH.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get(`/api/v1/cards`, {
      params: action.payload,
    })
      .then(res => res.data);
  }
});

export default {
  get
};
