export const LOGIN = {
  ERROR: 'LOGIN_ERROR',
  START: 'LOGIN_START',
  SUCCESS: 'LOGIN_SUCCESS',
  CANCEL_CURRENT: 'LOGIN_CANCEL_CURRENT',
};

export const LOGOUT = {
  START: 'LOGOUT_START',
  END: 'LOGOUT_END',
};

export const GET_CURRENT_USER = {
  ERROR: 'GET_CURRENT_USER_ERROR',
  START: 'GET_CURRENT_USER_START',
  SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  CANCEL_CURRENT: 'GET_CURRENT_USER_CANCEL_CURRENT',
};

export const GET_SINGLE_USER = {
  FETCH: 'GET_SINGLE_USER_FETCH',
  SUCCESS: 'GET_SINGLE_USER_SUCCESS',
  ERROR: 'GET_SINGLE_USER_ERROR'
};

export const RESET_PASSWORD = {
  ERROR: 'RESET_PASSWORD_ERROR',
  START: 'RESET_PASSWORD_START',
  SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET: 'RESET_PASSWORD_RESET',
};

export const CREATE_DECK = {
  START: 'CREATE_DECK_START',
  SUCCESS: 'CREATE_DECK_SUCCESS',
  ERROR: 'CREATE_DECK_ERROR'
};

export const DELETE_DECK = {
  START: 'DELETE_DECK_START',
  SUCCESS: 'DELETE_DECK_SUCCESS',
  ERROR: 'DELETE_DECK_ERROR'
};

export const FORMATS = {
  GET: 'FORMATS_GET',
  SUCCESS: 'FORMATS_SUCCESS',
  ERROR: 'FORMATS_ERROR'
};

export const BIOSCHEMAS = {
  GET: 'BIOSCHEMAS_GET',
  SUCCESS: 'BIOSCHEMAS_SUCCESS',
  ERROR: 'BIOSCHEMAS_ERROR'
};

export const TOURNAMENT = {
  FETCH: 'TOURNAMENT_FETCH',
  FETCH_BY_PASSCODE: 'TOURNAMENT_FETCH_BY_PASSCODE',
  SUCCESS: 'TOURNAMENT_SUCCESS',
  ERROR: 'TOURNAMENT_ERROR',
  SET: 'TOURNAMENT_SET',
};

export const TOURNAMENT_JOIN = {
  START: 'TOURNAMENT_JOIN_START',
  TEAM: {
    START: 'TOURNAMENT_JOIN/TEAM/START'
  },
  INDIVIDUAL: {
    START: 'TOURNAMENT_JOIN/INDIVIDUAL/START'
  }
};

export const TOURNAMENT_JOIN_MODAL = {
  START: 'TOURNAMENT_JOIN_MODAL_START',
};

export const TOURNAMENT_JOIN_SIMPLE = {
  START: 'TOURNAMENT_JOIN_SIMPLE_START',
  SUCCESS: 'TOURNAMENT_JOIN_SIMPLE_SUCCESS',
  ERROR: 'TOURNAMENT_JOIN_SIMPLE_ERROR',
};

export const TOURNAMENT_ASSOCIATIONS = {
  START: 'TOURNAMENT_ASSOCIATIONS_START'
};


export const TOURNAMENTS = {
  FETCH: 'TOURNAMENTS_FETCH',
  SUCCESS: 'TOURNAMENTS_SUCCESS',
  ERROR: 'TOURNAMENTS_ERROR',
};

export const TOURNAMENTS_ALL = {
  FETCH: 'TOURNAMENTS_ALL_FETCH',
  SUCCESS: 'TOURNAMENTS_ALL_SUCCESS',
  ERROR: 'TOURNAMENTS_ALL_ERROR',
};

export const TOURNAMENTS_PLAYER = {
  FETCH: 'TOURNAMENTS_PLAYER_FETCH',
  SUCCESS: 'TOURNAMENTS_PLAYER_SUCCESS',
  ERROR: 'TOURNAMENTS_PLAYER_ERROR',
};

export const TOURNAMENTS_JUDGE = {
  FETCH: 'TOURNAMENTS_JUDGE_FETCH',
  SUCCESS: 'TOURNAMENTS_JUDGE_SUCCESS',
  ERROR: 'TOURNAMENTS_JUDGE_ERROR',
};

export const SAVE_TOURNAMENT = {
  FETCH: 'SAVE_TOURNAMENT_FETCH',
  SUCCESS: 'SAVE_TOURNAMENT_SUCCESS',
  ERROR: 'SAVE_TOURNAMENT_ERROR',
  CLEAR: 'SAVE_TOURNAMENT_CLEAR',
};

export const SAVE_TOURNAMENT_AND_UPDATE = 'SAVE_TOURNAMENT_AND_UPDATE';

export const CREATE_TOURNAMENT = {
  FETCH: 'CREATE_TOURNAMENT_FETCH',
  SUCCESS: 'CREATE_TOURNAMENT_SUCCESS',
  ERROR: 'CREATE_TOURNAMENT_ERROR',
  CLEAR: 'CREATE_TOURNAMENT_CLEAR',
};

export const DELETE_TOURNAMENT = {
  START: 'SAVE_TOURNAMENT_START',
};

export const DECKS = {
  FETCH: 'DECKS_FETCH',
  SUCCESS: 'DECKS_SUCCESS',
  ERROR: 'DECKS_ERROR'
};

export const USER_DECKS = {
  FETCH: 'USER_DECKS_FETCH',
  SUCCESS: 'USER_DECKS_SUCCESS',
  ERROR: 'USER_DECKS_ERROR'
};

export const CHANGE_PASSWORD = {
  START: 'CHANGE_PASSWORD_START',
  SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  ERROR: 'CHANGE_PASSWORD_ERROR',
};

export const SET_USER_ROLE = 'SET_USER_ROLE';

export const COUNTRIES = {
  FETCH: 'COUNTRIES_FETCH',
  SUCCESS: 'COUNTRIES_SUCCESS',
  ERROR: 'COUNTRIES_ERROR',
};

export const STATES = {
  FETCH: 'STATES_FETCH',
  SUCCESS: 'STATES_SUCCESS',
  ERROR: 'STATES_ERROR',
};

export const USERS = {
  FETCH: 'USERS_FETCH',
  SUCCESS: 'USERS_SUCCESS',
  ERROR: 'USERS_ERROR',
  CLEAR: 'USERS_CLEAR',
};

export const USER_DELETE = {
  START: 'USER_DELETE_START',
  SUCCESS: 'USER_DELETE_SUCCESS',
  ERROR: 'USER_DELETE_ERROR',
};

export const USER_UPDATE = {
  START: 'USER_UPDATE_START',
  SUCCESS: 'USER_UPDATE_SUCCESS',
  ERROR: 'USER_UPDATE_ERROR',
  TOGGLE: 'USER_UPDATE_TOGGLE'
};

export const TOURNAMENT_PLAYERS = {
  FETCH: 'TOURNAMENT_PLAYERS_FETCH',
  SUCCESS: 'TOURNAMENT_PLAYERS_SUCCESS',
  ERROR: 'TOURNAMENT_PLAYERS_ERROR',
};

export const TOURNAMENT_PLAYERS_UPDATE = {
  START: 'TOURNAMENT_PLAYERS_UPDATE/START',
  SUCCESS: 'TOURNAMENT_PLAYERS_UPDATE/SUCCESS',
  ERROR: 'TOURNAMENT_PLAYERS_UPDATE/ERROR',
};

export const TOURNAMENT_EXTRAS = {
  FETCH: 'TOURNAMENT_EXTRAS_FETCH',
  UPDATE: 'TOURNAMENT_EXTRAS_UPDATE',
  SUCCESS: 'TOURNAMENT_EXTRAS_SUCCESS',
  ERROR: 'TOURNAMENT_EXTRAS_ERROR',
};


export const TOURNAMENT_PLAYERS_SEARCH = {
  START: 'TOURNAMENT_PLAYERS_SEARCH_START',
  SUCCESS: 'TOURNAMENT_PLAYERS_SEARCH_SUCCESS',
  ERROR: 'TOURNAMENT_PLAYERS_SEARCH_ERROR',
};

export const TOURNAMENT_JUDGES = {
  FETCH: 'TOURNAMENT_JUDGES_FETCH',
  SUCCESS: 'TOURNAMENT_JUDGES_SUCCESS',
  ERROR: 'TOURNAMENT_JUDGES_ERROR',
};

export const TOURNAMENT_JUDGES_ADD = {
  START: 'TOURNAMENT_JUDGES_ADD_START',
  SUCCESS: 'TOURNAMENT_JUDGES_ADD_SUCCESS',
  ERROR: 'TOURNAMENT_JUDGES_ADD_ERROR',
};

export const TOURNAMENT_JUDGES_DELETE = {
  START: 'TOURNAMENT_JUDGES_DELETE_START',
  SUCCESS: 'TOURNAMENT_JUDGES_DELETE_SUCCESS',
  ERROR: 'TOURNAMENT_JUDGES_DELETE_ERROR',
};

export const TOURNAMENT_PLAYER_DELETE = {
  START: 'TOURNAMENT_PLAYER_DELETE_START',
  SUCCESS: 'TOURNAMENT_PLAYER_DELETE_SUCCESS',
  ERROR: 'TOURNAMENT_PLAYER_DELETE_ERROR',
};

export const TOURNAMENT_ORGANIZERS = {
  FETCH: 'TOURNAMENT_ORGANIZERS_FETCH',
  SUCCESS: 'TOURNAMENT_ORGANIZERS_SUCCESS',
  ERROR: 'TOURNAMENT_ORGANIZERS_ERROR',
};

export const TOURNAMENT_ORGANIZERS_ADD = {
  START: 'TOURNAMENT_ORGANIZERS_ADD_START',
  SUCCESS: 'TOURNAMENT_ORGANIZERS_ADD_SUCCESS',
  ERROR: 'TOURNAMENT_ORGANIZERS_ADD_ERROR',
};

export const TOURNAMENT_ORGANIZERS_DELETE = {
  START: 'TOURNAMENT_ORGANIZERS_DELETE_START',
  SUCCESS: 'TOURNAMENT_ORGANIZERS_DELETE_SUCCESS',
  ERROR: 'TOURNAMENT_ORGANIZERS_DELETE_ERROR',
};

export const TOURNAMENT_CASTERS = {
  FETCH: 'TOURNAMENT_CASTERS_FETCH',
  SUCCESS: 'TOURNAMENT_CASTERS_SUCCESS',
  ERROR: 'TOURNAMENT_CASTERS_ERROR',
};

export const TOURNAMENT_CASTERS_ADD = {
  START: 'TOURNAMENT_CASTERS_ADD_START',
  SUCCESS: 'TOURNAMENT_CASTERS_ADD_SUCCESS',
  ERROR: 'TOURNAMENT_CASTERS_ADD_ERROR',
};

export const TOURNAMENT_CASTERS_DELETE = {
  START: 'TOURNAMENT_CASTERS_DELETE_START',
  SUCCESS: 'TOURNAMENT_CASTERS_DELETE_SUCCESS',
  ERROR: 'TOURNAMENT_CASTERS_DELETE_ERROR',
};

export const TOURNAMENT_ROLES_JUDGE = {
  FETCH: 'TOURNAMENT_ROLES_JUDGE_FETCH',
  SUCCESS: 'TOURNAMENT_ROLES_JUDGE_SUCCESS',
  ERROR: 'TOURNAMENT_ROLES_JUDGE_ERROR',
};

export const TOURNAMENT_ROLES_ORGANIZER = {
  FETCH: 'TOURNAMENT_ROLES_ORGANIZER_FETCH',
  SUCCESS: 'TOURNAMENT_ROLES_ORGANIZER_SUCCESS',
  ERROR: 'TOURNAMENT_ROLES_ORGANIZER_ERROR',
};

export const DECK_CARDS = {
  FETCH: 'DECK_CARDS_FETCH',
  UPDATE_BASED_ON_FORMAT: 'DECK_CARDS_UPDATE_BASED_ON_FORMAT',
  SUCCESS: 'DECK_CARDS_SUCCESS',
  ERROR: 'DECK_CARDS_ERROR',
};

export const ADD_CARD = {
  START: 'ADD_CARD_START',
  SUCCESS: 'ADD_CARD_SUCCESS',
  ERROR: 'ADD_CARD_ERROR',
};

export const ADD_CUSTOM_CARD = {
  START: 'ADD_CUSTOM_CARD_START',
  SUCCESS: 'ADD_CUSTOM_CARD_SUCCESS',
};

export const CHANGE_QTY = {
  START: 'CHANGE_QTY_START',
  UPDATE: 'CHANGE_QTY_UPDATE',
  SUCCESS: 'CHANGE_QTY_SUCCESS',
  ERROR: 'CHANGE_QTY_ERROR',
  DEBOUNCE: 'CHANGE_QTY_DEBOUNCE',
};

export const DELETE_CARD = {
  START: 'DELETE_START',
  SUCCESS: 'DELETE_SUCCESS',
  ERROR: 'DELETE_ERROR',
};

export const DELETE_ALL_CARDS = {
  START: 'DELETE_ALL_CARDS_START',
  SUCCESS: 'DELETE_ALL_CARDS_SUCCESS',
  ERROR: 'DELETE_ALL_CARDS_ERROR',
};

export const CARD_SEARCH = {
  FETCH: 'CARD_SEARCH_FETCH',
  SUCCESS: 'CARD_SEARCH_SUCCESS',
  ERROR: 'CARD_SEARCH_ERROR',
};

export const CARD_SELECT = {
  SHOW: 'CARD_SELECT_SHOW',
};

export const DECKS_PATCH = {
  PATCH: 'DECKS_PATCH_PATCH',
  SUCCESS: 'DECKS_PATCH_SUCCESS',
  ERROR: 'DECKS_PATCH_ERROR',
};

export const FETCH_DECK = {
  FETCH: 'FETCH_DECK_FETCH',
  SUCCESS: 'FETCH_DECK_SUCCESS',
  ERROR: 'FETCH_DECK_ERROR',
};

export const SAVE_DECK = {
  START: 'SAVE_DECK_START',
  SUCCESS: 'SAVE_DECK_SUCCESS',
  ERROR: 'SAVE_DECK_ERROR',
};

export const LINKED_ACCOUNTS = {
  GET: {
    START: 'LINKED_ACCOUNTS/GET/START',
    SUCCESS: 'LINKED_ACCOUNTS/GET/SUCCESS',
    ERROR: 'LINKED_ACCOUNTS/GET/ERROR',
  },
  NEW: {
    START: 'LINKED_ACCOUNTS/NEW/START',
    SUCCESS: 'LINKED_ACCOUNTS/NEW/SUCCESS',
    ERROR: 'LINKED_ACCOUNTS/NEW/ERROR',
  },
  DELETE: {
    START: 'LINKED_ACCOUNTS/DELETE/START',
    SUCCESS: 'LINKED_ACCOUNTS/DELETE/SUCCESS',
    ERROR: 'LINKED_ACCOUNTS/DELETE/ERROR',
  }
};

export const SAVE_TEXT_DECK = {
  START: 'SAVE_TEXT_DECK_START',
  SUCCESS: 'SAVE_TEXT_DECK_SUCCESS',
  CLEAR: 'SAVE_TEXT_DECK_CLEAR',
  REMOVE_CARD: 'SAVE_TEXT_DECK_REMOVE_CARD'
};

export const IMPORT_DECK = {
  START: 'IMPORT_DECK_DECK_START',
  SUCCESS: 'IMPORT_DECK_DECK_SUCCESS',
  CLEAR: 'IMPORT_DECK_DECK_CLEAR',
  REMOVE_CARD: 'IMPORT_DECK_DECK_REMOVE_CARD'
};

export const SAVE_TEXT_DECK_SUGGESTIONS = {
  START: 'SAVE_TEXT_DECK_SUGGESTIONS_START',
};

export const IMPORT_DECK_SUGGESTIONS = {
  START: 'IMPORT_DECK_SUGGESTIONS_START',
};

export const CREATE_DECK_DRAFT = {
  START: 'CREATE_DECK_DRAFT_START',
  SUCCESS: 'CREATE_DECK_DRAFT_SUCCESS',
  ERROR: 'CREATE_DECK_DRAFT_ERROR',
};

export const NOTIFICATION = {
  ADD: 'NOTIFICATION_ADD',
  REMOVE: 'NOTIFICATION_REMOVE'
};

export const UPDATE_DECK_CARDS = 'UPDATE_DECK_CARDS';

export const STORES_ALL = {
  FETCH: 'STORES_ALL_FETCH',
  SUCCESS: 'STORES_ALL_SUCCESS',
  ERROR: 'STORES_ALL_ERROR',
};

export const STORES = {
  FETCH: 'STORES_FETCH',
  SUCCESS: 'STORES_SUCCESS',
  ERROR: 'STORES_ERROR',
};

export const EDIT_STORE = {
  START: 'EDIT_STORE_START',
  SUCCESS: 'EDIT_STORE_SUCCESS',
  ERROR: 'EDIT_STORE_ERROR',
};

export const CREATE_STORE = {
  START: 'CREATE_STORE_START',
  SUCCESS: 'CREATE_STORE_SUCCESS',
  ERROR: 'CREATE_STORE_ERROR',
};

export const STORE_DETAILS = {
  START: 'STORE_DETAILS_START',
  SUCCESS: 'STORE_DETAILS_SUCCESS',
  ERROR: 'STORE_DETAILS_ERROR',
};

export const DECK_VALIDATOR = {
  SET_CARD_AMOUNT_ERRORS: 'DECK_VALIDATOR_SET_CARD_AMOUNT_ERRORS',
  SET_DECK_ERRORS: 'DECK_VALIDATOR_SET_DECK_ERRORS',
  SET_ALL_ERRORS: 'DECK_VALIDATOR_SET_ALL_ERRORS',

  VALID_CARD_AMOUNT: 'DECK_VALIDATOR_VALID_CARD_AMOUNT',
  VALID_DECK: 'DECK_VALIDATOR_VALID_DECK',
  VALID_ALL: 'DECK_VALIDATOR_VALID_ALL',
};

export const NEED_VALIDATION = {
  VALIDATION: 'VALIDATION',
};

export const ADMIN_STATISTICS = {
  FETCH: 'ADMIN_STATISTICS_FETCH',
  FETCH_ALL: 'ADMIN_STATISTICS_FETCH_ALL',
  UPDATE: 'ADMIN_STATISTICS_UPDATE',
  UPDATE_ERROR: 'ADMIN_STATISTICS_UPDATE_ERROR',
};

export const ORGANIZER_STATISTICS = {
  FETCH: 'ORGANIZER_STATISTICS_FETCH',
  FETCH_ALL: 'ORGANIZER_STATISTICS_FETCH_ALL',
  UPDATE: 'ORGANIZER_STATISTICS_UPDATE',
  UPDATE_ERROR: 'ORGANIZER_STATISTICS_UPDATE_ERROR',
};

export const PUBLIC_DECK = {
  FETCH: 'PUBLIC_DECK_FETCH',
  SUCCESS: 'PUBLIC_DECK_SUCCESS',
  ERROR: 'PUBLIC_DECK_ERROR',
};

export const RECENT_DECKS = {
  START: 'RECENT_DECKS_START',
  SUCCESS: 'RECENT_DECKS_SUCCESS',
  ERROR: 'RECENT_DECKS_ERROR',
};

export const STREAMERS_DECKS = {
  START: 'STREAMERS_DECKS_START',
  SUCCESS: 'STREAMERS_DECKS_SUCCESS',
  ERROR: 'STREAMERS_DECKS_ERROR',
};

export const STREAMERS_DECKS_LOAD_MORE = {
  START: 'STREAMERS_DECKS_LOAD_MORE_START',
  SUCCESS: 'STREAMERS_DECKS_LOAD_MORE_SUCCESS',
  ERROR: 'STREAMERS_DECKS_LOAD_MORE_ERROR',
};

export const STREAMERS_DECKS_EDIT_NAME = {
  START: 'STREAMERS_DECKS_EDIT_NAME_START',
  SUCCESS: 'STREAMERS_DECKS_EDIT_NAME_SUCCESS',
  ERROR: 'STREAMERS_DECKS_EDIT_NAME_ERROR',
};

export const STREAMERS_DECKS_DELETE = {
  START: 'STREAMERS_DECKS_DELETE_START',
  SUCCESS: 'STREAMERS_DECKS_DELETE_SUCCESS',
  ERROR: 'STREAMERS_DECKS_DELETE_ERROR',
};

export const STREAMERS_INFO = {
  START: 'STREAMERS_INFO_START',
  SUCCESS: 'STREAMERS_INFO_SUCCESS',
  ERROR: 'STREAMERS_INFO_ERROR',
};


export const SHORTENER_URL = {
  START: 'SHORTENER_URL_START',
  SUCCESS: 'SHORTENER_URL_SUCCESS',
  ERROR: 'SHORTENER_URL_ERROR',
};

export const PUBLIC_CARDS = {
  FETCH: 'PUBLIC_CARDS_FETCH',
  SUCCESS: 'PUBLIC_CARDS_SUCCESS',
  ERROR: 'PUBLIC_CARDS_ERROR',
};

export const CARD_PREVIEW = {
  FETCH: 'CARD_PREVIEW_FETCH',
  SUCCESS: 'CARD_PREVIEW_SUCCESS',
  ERROR: 'CARD_PREVIEW_ERROR',
  CLEAR: 'CARD_PREVIEW_CLEAR'
};

export const DECKNAMES = {
  START: 'DECKNAMES_START',
  SUCCESS: 'DECKNAMES_SUCCESS',
  ERROR: 'DECKNAMES_ERROR',
};

export const DECKNAME_DETAILS = {
  START: 'DECKNAME_DETAILS_START',
  SUCCESS: 'DECKNAME_DETAILS_SUCCESS',
  ERROR: 'DECKNAME_DETAILS_ERROR',
};

export const DECKNAME_CREATE = {
  START: 'DECKNAME_CREATE_START',
  SUCCESS: 'DECKNAME_CREATE_SUCCESS',
  ERROR: 'DECKNAME_CREATE_ERROR',
};

export const DECKNAME_UPDATE = {
  START: 'DECKNAME_UPDATE_START',
  SUCCESS: 'DECKNAME_UPDATE_SUCCESS',
  ERROR: 'DECKNAME_UPDATE_ERROR',
};

export const DECKNAME_DELETE = {
  START: 'DECKNAME_DELETE_START',
  SUCCESS: 'DECKNAME_DELETE_SUCCESS',
  ERROR: 'DECKNAME_DELETE_ERROR',
};

export const DECKNAME_FORMAT = {
  START: 'DECKNAME_FORMAT_START',
  SUCCESS: 'DECKNAME_FORMAT_SUCCESS',
  ERROR: 'DECKNAME_FORMAT_ERROR',
};

export const SET_DECKNAME_FORMAT = 'SET_DECKNAME_FORMAT';
export const TOURNAMENT_USER = {
  GET: 'TOURNAMENT_USER_GET',
  CLEAR: 'TOURNAMENT_USER_CLEAR',
  SET: 'TOURNAMENT_USER_SET',
  UPDATE: 'TOURNAMENT_USER_UPDATE',
  SET_ERROR: 'TOURNAMENT_USER_SET_ERROR',
  CREATE_USER_ERROR: 'TOURNAMENT_USER_CREATE_USER_ERROR',
  CREATE: 'TOURNAMENT_USER_CREATE',
  SAVE_DECK_TYPE: 'TOURNAMENT_USER_SAVE_DECK_TYPE',

  CREATE_DECK: {
    START: 'TOURNAMENT_USER/CREATE_DECK/START',
    SUCCESS: 'TOURNAMENT_USER/CREATE_DECK/SUCCESS',
    ERROR: 'TOURNAMENT_USER/CREATE_DECK/ERROR',
  }
};

export const TOURNAMENT_SHADOW_USER_UPDATE = {
  START: 'TOURNAMENT_SHADOW_USER_UPDATE_START',
  SUCCESS: 'TOURNAMENT_SHADOW_USER_UPDATE_SUCCESS',
  ERROR: 'TOURNAMENT_SHADOW_USER_UPDATE_ERROR',
};

export const IMPORT_STANDINGS = {
  START: 'IMPORT_STANDINGS_START',
  SUCCESS: 'IMPORT_STANDINGS_SUCCESS',
  ERROR: 'IMPORT_STANDINGS_ERROR'
};

export const IMPORT_PLAYERS = {
  START: 'IMPORT_PLAYERS_START',
  SUCCESS: 'IMPORT_PLAYERS_SUCCESS',
  ERROR: 'IMPORT_PLAYERS_ERROR'
};

export const BIOGRAPHY = {
  FETCH: 'BIOGRAPHY_FETCH',
  SUCCESS: 'BIOGRAPHY_SUCCESS',
  ERROR: 'BIOGRAPHY_ERROR',
};

export const BIOGRAPHY_UPDATE = {
  START: 'BIOGRAPHY_START',
};

export const BIOGRAPHY_UPDATE_MEMBER = {
  START: 'BIOGRAPHY_UPDATE_MEMBER_START',
};

export const BIOGRAPHY_MEMBER = {
  FETCH: 'BIOGRAPHY_MEMBER_FETCH',
  SUCCESS: 'BIOGRAPHY_MEMBER_SUCCESS',
  ERROR: 'BIOGRAPHY_MEMBER_ERROR',
};

export const SUBMIT_DECK_FOR_TOURNAMENT = {
  SELECT_TOURNAMENT: 'SUBMIT_DECK_FOR_TOURNAMENT/SELECT_TOURNAMENT',
  JOIN_TOURNAMENT: {
    START: 'SUBMIT_DECK_FOR_TOURNAMENT/JOIN_TOURNAMENT/START',
    SUCCESS: 'SUBMIT_DECK_FOR_TOURNAMENT/JOIN_TOURNAMENT/SUCCESS',
    ERROR: 'SUBMIT_DECK_FOR_TOURNAMENT/JOIN_TOURNAMENT/ERROR',
    CLEAR: 'SUBMIT_DECK_FOR_TOURNAMENT/JOIN_TOURNAMENT/CLEAR',
  },
  TOURNAMENTS: {
    START: 'SUBMIT_DECK_FOR_TOURNAMENT/TOURNAMENTS/START',
    SUCCESS: 'SUBMIT_DECK_FOR_TOURNAMENT/TOURNAMENTS/SUCCESS',
    ERROR: 'SUBMIT_DECK_FOR_TOURNAMENT/TOURNAMENTS/ERROR',
  },
  REGISTER_DECK: {
    START: 'SUBMIT_DECK_FOR_TOURNAMENT/REGISTER_DECK/START',
    SUCCESS: 'SUBMIT_DECK_FOR_TOURNAMENT/REGISTER_DECK/SUCCESS',
    ERROR: 'SUBMIT_DECK_FOR_TOURNAMENT/REGISTER_DECK/ERROR',
    CLEAR: 'SUBMIT_DECK_FOR_TOURNAMENT/REGISTER_DECK/CLEAR',
  },
};

export const USER_PERSONAL_TOKEN = {
  GET_ALL: {
    START: 'USER_PERSONAL_TOKEN/GET_ALL/START',
    SUCCESS: 'USER_PERSONAL_TOKEN/GET_ALL/SUCCESS',
    ERROR: 'USER_PERSONAL_TOKEN/GET_ALL/ERROR',
  },
  GENERATE: {
    START: 'USER_PERSONAL_TOKEN/GENERATE/START',
    SUCCESS: 'USER_PERSONAL_TOKEN/GENERATE/SUCCESS',
    ERROR: 'USER_PERSONAL_TOKEN/GENERATE/ERROR',
  },
  REGENERATE: {
    START: 'USER_PERSONAL_TOKEN/REGENERATE/START',
    SUCCESS: 'USER_PERSONAL_TOKEN/REGENERATE/SUCCESS',
    ERROR: 'USER_PERSONAL_TOKEN/REGENERATE/ERROR',
  },
  REMOVE: {
    START: 'USER_PERSONAL_TOKEN/REMOVE/START',
    SUCCESS: 'USER_PERSONAL_TOKEN/REMOVE/SUCCESS',
    ERROR: 'USER_PERSONAL_TOKEN/REMOVE/ERROR',
  }
};

export const UNREGISTERED_PLAYER = {
  CREATE: {
    START: 'UNREGISTERED_PLAYER/CREATE/START',
    SUCCESS: 'UNREGISTERED_PLAYER/CREATE/SUCCESS',
    ERROR: 'UNREGISTERED_PLAYER/CREATE/ERROR',
  },
  UPDATE: {
    START: 'UNREGISTERED_PLAYER/UPDATE/START',
    SUCCESS: 'UNREGISTERED_PLAYER/UPDATE/SUCCESS',
    ERROR: 'UNREGISTERED_PLAYER/UPDATE/ERROR',
  },

  INIT_DECK_CREATOR: {
    START: 'UNREGISTERED_PLAYER/INIT_DECK_CREATOR/START',
    SUCCESS: 'UNREGISTERED_PLAYER/INIT_DECK_CREATOR/SUCCESS',
    ERROR: 'UNREGISTERED_PLAYER/INIT_DECK_CREATOR/ERROR',
  },
  SET_PLAYER: 'UNREGISTERED_PLAYER/SET_PLAYER',
  SET_DECK: 'UNREGISTERED_PLAYER/SET_DECK',
};

export const EDIT_TOURNAMENT_PLAYER = {
  GET: {
    START: 'EDIT_TOURNAMENT_PLAYER/GET/START',
    SUCCESS: 'EDIT_TOURNAMENT_PLAYER/GET/SUCCESS',
    ERROR: 'EDIT_TOURNAMENT_PLAYER/GET/ERROR',
  },
  SAVE: {
    START: 'EDIT_TOURNAMENT_PLAYER/SAVE/START',
    SUCCESS: 'EDIT_TOURNAMENT_PLAYER/SAVE/SUCCESS',
    ERROR: 'EDIT_TOURNAMENT_PLAYER/SAVE/ERROR',
  },
  CLEAR: 'EDIT_TOURNAMENT_PLAYER/CLEAR',
};


export const SELECT_TOURNAMENT_PLAYER_BIO = {
  GET_SCHEMA: {
    START: 'SELECT_TOURNAMENT_PLAYER_BIO/GET_SCHEMA/START',
    SUCCESS: 'SELECT_TOURNAMENT_PLAYER_BIO/GET_SCHEMA/SUCCESS',
    ERROR: 'SELECT_TOURNAMENT_PLAYER_BIO/GET_SCHEMA/ERROR',
  },
  SAVE: {
    START: 'SELECT_TOURNAMENT_PLAYER_BIO/SAVE/START',
    SUCCESS: 'SELECT_TOURNAMENT_PLAYER_BIO/SAVE/SUCCESS',
    ERROR: 'SELECT_TOURNAMENT_PLAYER_BIO/SAVE/ERROR',
  },
  CLEAR: 'SELECT_TOURNAMENT_PLAYER_BIO/CLEAR',
};

export const TOURNAMENT_STANDINGS = {
  GET: {
    START: 'TOURNAMENT_STANDINGS/GET/START',
    SUCCESS: 'TOURNAMENT_STANDINGS/GET/SUCCESS',
    ERROR: 'TOURNAMENT_STANDINGS/GET/ERROR',
  },
  UPDATE: {
    START: 'TOURNAMENT_STANDINGS/UPDATE/START',
    SUCCESS: 'TOURNAMENT_STANDINGS/UPDATE/SUCCESS',
    ERROR: 'TOURNAMENT_STANDINGS/UPDATE/ERROR',
  },
  ADD: 'TOURNAMENT_STANDINGS/ADD',
  DELETE_ROW: 'TOURNAMENT_STANDINGS/DELETE_ROW',
  DELETE: {
    START: 'TOURNAMENT_STANDINGS/DELETE/START',
    SUCCESS: 'TOURNAMENT_STANDINGS/DELETE/SUCCESS',
    ERROR: 'TOURNAMENT_STANDINGS/DELETE/ERROR',
  }
};

export const METADATA = {
  GET: {
      FETCH: 'METADATA/GET/FETCH',
      SUCCESS: 'METADATA/GET/SUCCESS',
      ERROR: 'METADATA/GET/ERROR',
  },
  ADD_NEW: {
      START: 'METADATA/ADD_NEW/START',
      SUCCESS: 'METADATA/ADD_NEW/SUCCESS',
      ERROR: 'METADATA/ADD_NEW/ERROR',
  },
  UPDATE: {
      START: 'METADATA/UPDATE/START',
      SUCCESS: 'METADATA/UPDATE/SUCCESS',
      ERROR: 'METADATA/UPDATE/ERROR',
  },
  DELETE: {
      START: 'METADATA/DELETE/START',
      SUCCESS: 'METADATA/DELETE/SUCCESS',
      ERROR: 'METADATA/DELETE/ERROR',
  },
  TEAM_INIT_GET: {
    FETCH: 'METADATA/TEAM_INIT_GET/FETCH',
    SUCCESS: 'METADATA/TEAM_INIT_GET/SUCCESS',
    ERROR: 'METADATA/TEAM_INIT_GET/SUCCESS',
  },
  UPDATE_STATE: 'METADATA_UPDATE_STATE',
  EDITION_ID: 'METADATA_EDITION_ID',
  OVERWRITE_UPDATE: 'METADATA_OVERWRITE_UPDATE',
  RESET: 'METADATA_RESET_DATA',
  RESET_EDIT_ID: 'METADATA_RESET_EDITION_ID',
};

export const TOURNAMENT_TYPES = {
  GET_TYPES: {
    START: 'TOURNAMENT_TYPE/GET_TYPES/START',
    SUCCESS: 'TOURNAMENT_TYPE/GET_TYPES/SUCCESS',
    ERROR: 'TOURNAMENT_TYPE/GET_TYPES/ERROR',
  },
  GET_FORMATS: {
    START: 'TOURNAMENT_TYPE/GET_FORMAT/START',
    SUCCESS: 'TOURNAMENT_TYPE/GET_FORMAT/SUCCESS',
    ERROR: 'TOURNAMENT_TYPE/GET_FORMAT/ERROR',
  },
  GET_ALL_DATA: {
    START: 'TOURNAMENT_TYPE/GET_ALL_DATA/START',
    SUCCESS: 'TOURNAMENT_TYPE/GET_ALL_DATA/SUCCESS',
    ERROR: 'TOURNAMENT_TYPE/GET_ALL_DATA/ERROR',
  }
};

export const TOURNAMENT_TEAMS = {
  GET: {
    START: 'TOURNAMENT_TEAMS/GET/START',
    SUCCESS: 'TOURNAMENT_TYPE/GET/SUCCESS',
    ERROR: 'TOURNAMENT_TYPE/GET/ERROR',
  },
  ADD: {
    START: 'TOURNAMENT_TEAMS/ADD/START',
    SUCCESS: 'TOURNAMENT_TYPE/ADD/SUCCESS',
    ERROR: 'TOURNAMENT_TYPE/ADD/ERROR',
    CLEAR: 'TOURNAMENT_TYPE/ADD/CLEAR',
  },
  EDIT: {
    START: 'TOURNAMENT_TEAMS/EDIT/START',
    SUCCESS: 'TOURNAMENT_TYPE/EDIT/SUCCESS',
    ERROR: 'TOURNAMENT_TYPE/EDIT/ERROR',
    CLEAR: 'TOURNAMENT_TYPE/EDIT/CLEAR',
  },
  UPDATE_PLAYER: {
    START: 'TOURNAMENT_TEAMS/UPDATE_PLAYER/START',
    SUCCESS: 'TOURNAMENT_TEAMS/UPDATE_PLAYER/SUCCESS',
    ERROR: 'TOURNAMENT_TEAMS/UPDATE_PLAYER/ERROR',
  },
  REMOVE: {
    START: 'TOURNAMENT_TEAMS/REMOVE/START',
    SUCCESS: 'TOURNAMENT_TYPE/REMOVE/SUCCESS',
    ERROR: 'TOURNAMENT_TYPE/REMOVE/ERROR',
  },
  IMPORT: {
    START: 'TOURNAMENT_TEAMS/IMPORT/START',
    SUCCESS: 'TOURNAMENT_TEAMS/IMPORT/SUCCESS',
    ERROR: 'TOURNAMENT_TEAMS/IMPORT/ERROR',
  }
};

export const TOURNAMENT_TEAM = {
  GET: {
    START: 'TOURNAMENT_TEAM/GET/START',
    SUCCESS: 'TOURNAMENT_TEAM/GET/SUCCESS',
    ERROR: 'TOURNAMENT_TEAM/GET/ERROR',
  },
  EDIT: {
    START: 'TOURNAMENT_TEAM/EDIT/START',
    SUCCESS: 'TOURNAMENT_TEAM/EDIT/SUCCESS',
    ERROR: 'TOURNAMENT_TEAM/EDIT/ERROR',
  },
};

export const JOIN_TEAM_TOURNAMENT = {
  START: 'JOIN_TEAM_TOURNAMENT/START',
  SUCCESS: 'JOIN_TEAM_TOURNAMENT/SUCCESS',
  ERROR: 'JOIN_TEAM_TOURNAMENT/ERROR',
  CLEAR: 'JOIN_TEAM_TOURNAMENT/CLEAR',
};

export const LEAVE_TEAM_TOURNAMENT = {
  START: 'LEAVE_TEAM_TOURNAMENT/START',
  SUCCESS: 'LEAVE_TEAM_TOURNAMENT/SUCCESS',
  ERROR: 'LEAVE_TEAM_TOURNAMENT/ERROR',
};


export const TOURNAMENT_TEAM_DECK_FORMATS = {
  GET: {
    START: 'TOURNAMENT_TEAM_DECK_FORMATS/GET/START',
    SUCCESS: 'TOURNAMENT_TEAM_DECK_FORMATS/GET/SUCCESS',
    ERROR: 'TOURNAMENT_TEAM_DECK_FORMATS/GET/ERROR',
  },
  RESET: 'TOURNAMENT_TEAM_DECK_FORMATS/RESET',
};

export const TOURNAMENT_TEAM_DECK_FORMATS_NO_REGISTER = {
  GET: {
    START: 'TOURNAMENT_TEAM_DECK_FORMATS_NO_REGISTER/GET/START',
    SUCCESS: 'TOURNAMENT_TEAM_DECK_FORMATS_NO_REGISTER/GET/SUCCESS',
    ERROR: 'TOURNAMENT_TEAM_DECK_FORMATS_NO_REGISTER/GET/ERROR',
  },
};

export const TOURNAMENT_TEAMS_STANDINGS = {
  GET: {
    START: 'TOURNAMENT_TEAMS_STANDINGS/GET/START',
    SUCCESS: 'TOURNAMENT_TEAMS_STANDINGS/GET/SUCCESS',
    ERROR: 'TOURNAMENT_TEAMS_STANDINGS/GET/ERROR',
  },
  UPDATE: {
    START: 'TOURNAMENT_TEAMS_STANDINGS/UPDATE/START',
    SUCCESS: 'TOURNAMENT_TEAMS_STANDINGS/UPDATE/SUCCESS',
    ERROR: 'TOURNAMENT_TEAMS_STANDINGS/UPDATE/ERROR',
  }
};

export const UNREGISTERED_TEAM = {
  CREATE: {
    START: 'UNREGISTERED_TEAM/CREATE/START',
    SUCCESS: 'UNREGISTERED_TEAM/CREATE/SUCCESS',
    ERROR: 'UNREGISTERED_TEAM/CREATE/ERROR',
  },
  GET: {
    START: 'UNREGISTERED_TEAM/GET/START',
    SUCCESS: 'UNREGISTERED_TEAM/GET/SUCCESS',
    ERROR: 'UNREGISTERED_TEAM/GET/ERROR',
  },
  DECK_CREATE: {
    START: 'UNREGISTERED_TEAM/DECK_CREATE/START',
    SUCCESS: 'UNREGISTERED_TEAM/DECK_CREATE/SUCCESS',
    ERROR: 'UNREGISTERED_TEAM/DECK_CREATE/ERROR',
    RESET: 'UNREGISTERED_TEAM/DECK_CREATE/RESET',
  },
  UPDATE: {
    START: 'UNREGISTERED_TEAM/UPDATE/START',
    SUCCESS: 'UNREGISTERED_TEAM/UPDATE/SUCCESS',
    ERROR: 'UNREGISTERED_TEAM/UPDATE/ERROR',
  }
};

export const MODAL = {
  OPEN: 'MODAL/OPEN',
  CLOSE: 'MODAL/CLOSE',
  CLOSE_ACTUAL_OPENED: 'MODAL/CLOSE_ACTUAL_OPENED',
};

export const STREAMERS = {
  GET: {
    START: 'STREAMERS/GET/START',
    SUCCESS: 'STREAMERS/GET/SUCCESS',
    ERROR: 'STREAMERS/GET/ERROR',
  },
  UPDATE: {
    START: 'STREAMERS/UPDATE/START',
    SUCCESS: 'STREAMERS/UPDATE/SUCCESS',
    ERROR: 'STREAMERS/UPDATE/ERROR',
  },
  ADD: {
    START: 'STREAMERS/ADD/START',
  },
  DELETE: {
    START: 'STREAMERS/DELETE/START',
  }
};

export const ARENA_FORMAT = {
  GET: {
    START: 'ARENA_FORMAT/GET/START',
    ERROR: 'ARENA_FORMAT/GET/ERROR',
    SUCCESS: 'ARENA_FORMAT/GET/SUCCESS',
  },
};

export const PUBLIC_ARENA_FORMAT = {
  START: 'PUBLIC_ARENA_FORMAT/GET/START',
  ERROR: 'PUBLIC_ARENA_FORMAT/GET/ERROR',
  SUCCESS: 'PUBLIC_ARENA_FORMAT/GET/SUCCESS',
};

export const CHANNEL_LIVE = {
  FETCH: 'CHANNEL_LIVE_FETCH',
  SUCCESS: 'CHANNEL_LIVE_SUCCESS',
  ERROR: 'CHANNEL_LIVE_ERROR',
};