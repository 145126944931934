import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import OrganizerMetrics from './OrganizerMetrics';
import Spinner from '../../Spinner';
import NoDataError from './NoDataError';

import {fetchAll} from '../../../actions/organizerStatistics';
import {fetchFormats} from '../../../actions/formatsActions';

class OrganizerMetricsContainer extends React.Component {
  componentDidMount() {
    const {fetchAll, fetchFormats} = this.props;

    const since = moment().subtract(7, 'days').startOf('day').unix();
    const until = moment().endOf('day').unix();

    fetchAll({
      deck_names: {
        formatId: 1,
        since,
        until,
      },
      cards: {
        formatId: 1,
        since,
        until,
      },
    });
    fetchFormats();
  }

  render() {
    const {statistics, formats} = this.props;

    const isLoaded =
      !!statistics.deck_names.data &&
      !!statistics.cards.data &&
      !!formats.data;

    const doesLackData = isLoaded && !statistics.tournaments.data;

    let content;
    if(isLoaded) {
      if(doesLackData) {
        content = <NoDataError />;
      } else {
        content = <OrganizerMetrics statistics={statistics} formats={formats.data}/>;
      }
    } else {
      content = <Spinner size={40} />;
    }

    return (
      <div className="container my-5">
        <h4 className="section-header mb-5">
          <FormattedMessage id="metrics.title"/>
        </h4>

        { content }
      </div>
    );
  }
}

OrganizerMetricsContainer.propTypes = {
  fetchAll: PropTypes.func.isRequired,
  fetchFormats: PropTypes.func.isRequired,
  statistics: PropTypes.object.isRequired,
  formats: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    statistics: state.organizerStatistics,
    formats: state.formats,
  }),
  {
    fetchAll,
    fetchFormats,
  }
)(OrganizerMetricsContainer);
