// Parse this:
//
// {
//   "array[0].firstName": "This value should not be blank.",
//   "array[0].lastName": "This value should not be blank.",
//   "array[2].lastName": "This value should not be blank."
// }
//
// To:
//
// {
//   [0]: {
//      firstName: "This value should not be blank.",
//      lastName: "This value should not be blank."
//   }
//   [2]: {
//      lastName: "This value should not be blank."
//   }
// }
export function parseArray(extras) {
  return Object.keys(extras).reduce((prev, item) => {
    const match = item.match(/array\[([0-9]+)\]\.(\w*)/);
    const index = match[1];
    const field = match[2];

    const itemData = {
      [field]: extras[item],
    };

    return {
      ...prev,
      [index]: prev[index] ? {...prev[index], ...itemData} : itemData,
    };
  }, {});

}

export default {
  parseArray,
};
