import { createLogic } from 'redux-logic';

import { CARD_PREVIEW } from '../actions/types';

export const getCard = createLogic({
  type: CARD_PREVIEW.FETCH,
  latest: true,

  processOptions: {
    successType: CARD_PREVIEW.SUCCESS,
    failType: CARD_PREVIEW.ERROR,
  },

  process({ clientContextHttpClient, action }) {
    return clientContextHttpClient.get(`/api/v1/cards/${action.id}/price`)
      .then(res => res.data);
  }
});

export default {
  getCard
};
