import * as React from 'react';
import Tabs from '../Generic/Tabs/Tabs';
import ManageAccount from './ManageAccount';
import LinkedAccounts from './LinkedAccounts';
import { Route, Redirect, Switch, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';

import './ManageAccountContainer.scss';

const manageAccountPath = '/manage-account';

const routes = [
  { name: 'Account details', component: ManageAccount, url: '/details' },
  { name: 'Linked accounts', component: LinkedAccounts, url: '/linked-accounts' }
];

class ManageAccountContainer extends React.Component {
  onTabChangeIndex = (index) => {
    this.setState({
      tabIndex: index
    });
  };

  renderTabs() {
    const { location } = this.props;

    const index = routes.findIndex((item) => {
      const match = matchPath(location.pathname, {
        path: `${manageAccountPath}${item.url}`
      });

      return !!match;
    });

    return (
      <Tabs
        className="manage-account-tabs"
        data={routes}
        index={index}
        onChangeIndex={this.onTabChangeIndex}
        renderTabItem={(item) => {
          return (
            <Link to={`${manageAccountPath}${item.url}`}>{item.name}</Link>
          );
        }} />
    );
  }

  render() {
    return (
      <div className="my-5 manage-account-container container">
        <h2 className="text-info section-header mb-4"><FormattedMessage id="account.title" /></h2>
        {this.renderTabs()}
        <Switch>
          {
            routes.map(route => {
              return <Route key={route.url}
                path={`${manageAccountPath}${route.url}`}
                component={() => <route.component />} />;
            })
          }
          <Route exact path={`${manageAccountPath}`} component={() => <Redirect to={`${manageAccountPath}/details`} />} />
        </Switch>

      </div>
    );
  }
}

ManageAccountContainer.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ManageAccountContainer;
