import httpClient from './httpClient';

class Stores {
  static createStore() {
    return httpClient.post('/api/v1/stores', {
      'name': 'Type name here',
    });
  }

  static deleteStore(id) {
    return httpClient.delete('/api/v1/stores/' + id);
  }
}
export default Stores;
