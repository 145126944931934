import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthService from '../services/AuthService';
import { getMe } from '../actions/authActions';
import { resetCurrentUser } from '../actions/authActions';

class PrivateRoute extends React.Component {
  renderLogout() {
    const {resetCurrentUser} = this.props;

    AuthService.logout();
    resetCurrentUser();

    return (
      <Redirect to={{
        pathname: '/login',
      }} />
    );
  }

  render() {
    const isAuthenticated = AuthService.isLogged();
    const { component, ...rest } = this.props;
    const Component = component;

    if(isAuthenticated && rest.userError) {
      // If access token is not valid
      return this.renderLogout();
    }

    if(isAuthenticated) {
      AuthService.setHeaders();
    }

    if(isAuthenticated && !rest.user) {
      rest.getMe();
      return null;
    }

    // Auto logout guests
    if(isAuthenticated && rest.user.roles.includes('GUEST')){
      return this.renderLogout();
    }

    return (
      <Route {...rest} render={(props) => (
        isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
      )} />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  location: PropTypes.object,

  user: PropTypes.object,
  userError: PropTypes.bool.isRequired,

  getMe: PropTypes.func.isRequired,
  resetCurrentUser: PropTypes.func.isRequired,
};

export default connect((state) => ({
  user: state.user.data,
  userError: state.user.isError,
}), {
  getMe,
  resetCurrentUser,
})(PrivateRoute);
