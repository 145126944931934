import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import logo from '../../assets/images/frontier-logo.svg';
import { getChannelLive } from '../../actions/channelLiveAction';
import config from 'appConfig';
import ReactGA from 'react-ga';

import './ChannelLive.scss';

class ChannelLive extends React.Component {
  componentDidMount() {
    const {getChannelLive} = this.props;
    getChannelLive();
  }

  onChannelBoxClick = (channelName) => {
    ReactGA.event({
      category: channelName,
      action: 'Online clicked channel',
    });

    window.open(`${config.VODKA_PORTAL_URL}/${channelName}`, '_blank');
  };

  render() {
    const {channelLive} = this.props;
    
    const channelToShow = channelLive.data.length >= 3 ?
          channelLive.data.slice(channelLive.data.length - 3) :
          channelLive.data;

    if(channelToShow.length < 1) {
      return null;
    }

    return (
      <div className="channel-live">
        <div className="channel-live__box">
          <img src={logo} />
          <span className="channel-live__dot"></span>
          <span className="channel-live__live">Live</span>
        </div>
        {
          channelToShow.map((item, id) => {
            return (
              <div onClick={() => this.onChannelBoxClick(item.name)} className="channel-live__channel-box" key={id}>
                <span className="channel-live__channel-name">{item.name}</span>
                <span className="channel-live__channel-icon"></span>
                <span className="channel-live__channel-viewers">{item.streamStatus.viewersCount}</span>
              </div>
            );
          })
        }
      </div>
    );
  }
}

ChannelLive.propTypes = {
  channelLive: PropTypes.object,
  getChannelLive: PropTypes.func,
};

export default connect(
  (state) => ({
    channelLive: state.channelLive,
  }),
  ({
    getChannelLive,
  })
)(ChannelLive);
