import * as React from 'react';
import { createLogic } from 'redux-logic';
import {
  BIOGRAPHY,
  BIOGRAPHY_UPDATE,
  BIOGRAPHY_UPDATE_MEMBER,
  BIOGRAPHY_MEMBER,
  BIOSCHEMAS
} from '../actions/types';

import { add as addNotification } from '../actions/notificationActions';
import { FormattedMessage } from 'react-intl';

export const getBiography = createLogic({
  type: BIOGRAPHY.FETCH,
  latest: true,

  processOptions: {
    successType: BIOGRAPHY.SUCCESS,
    failType: BIOGRAPHY.ERROR,
  },

  process({ httpClient }) {
    return httpClient.get('/api/v1/me/bio_questions')
      .then(res => res.data);
  }
});

export const updateBiography = createLogic({
  type: BIOGRAPHY_UPDATE.START,

  process({ httpClient, action }, dispatch, done) {
    const promises = action.data.map((item) => {
      const answer = item.usersAnswers[0] ? item.usersAnswers[0].answer : '';
      return httpClient.put(`/api/v1/users/${action.userId}/bio_questions/${item.id}`, {
        answer
      });
    });

    return Promise.all(promises)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="biography.updated.success"/>
        }));
        if(action.redirectUrl) {
          action.redirectUrl();
        }
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="biography.updated.error"/>
        }));
      })
      .then(done);
  }
});

export const getTournamentUserBiography = createLogic({
  type: BIOGRAPHY_MEMBER.FETCH,
  latest: true,

  processOptions: {
    successType: BIOGRAPHY_MEMBER.SUCCESS,
    failType: BIOGRAPHY_MEMBER.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get(`/api/v1/players/${action.membershipId}/bio_questions`)
      .then(res => res.data);
  }
});

export const updateTournamentUserBiography = createLogic({
  type: BIOGRAPHY_UPDATE_MEMBER.START,

  process({ httpClient, action }, dispatch, done) {
    const promises = action.data.map((item) => {
      const answer = item.membersAnswers[0] ? item.membersAnswers[0].answer : '';
      return httpClient.put(`/api/v1/players/${action.membershipId}/bio_questions/${item.id}`, {
        answer
      });
    });

    return Promise.all(promises)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="biography.updated.success"/>
        }));
        if(action.redirect) {
          action.redirect();
        }
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="biography.updated.error"/>
        }));
      })
      .then(done);
  }
});

export const getBioSchemas = createLogic({
  type: BIOSCHEMAS.GET,
  latest: true,

  process({ httpClient, getState }, dispatch, done) {
    const bioSchemasData = getState().bioSchemas.data;

    if(bioSchemasData) {
      return dispatch({
        type: BIOSCHEMAS.SUCCESS,
        payload: bioSchemasData
      });
    }
    
    return httpClient.get('/api/v1/bio_schemas')
      .then(res => {
        return dispatch({
          type: BIOSCHEMAS.SUCCESS,
          payload: res.data
        });
      })
      .catch(err => {
        return dispatch({
          type: BIOSCHEMAS.ERROR,
          payload: err
        });
      })
      .then(() => done());
  }
});

export default {
  getBiography,
  updateBiography,
  getTournamentUserBiography,
  updateTournamentUserBiography,
  getBioSchemas
};
