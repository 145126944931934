import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import RecentDecks from './RecentDecks';

import {getRecentDecks} from '../../../actions/publicDecksActions';

class RecentDecksContainer extends React.Component {
  componentDidMount(){
    this.props.getRecentDecks(this.props.channelId);
  }
  render() {
    if(!this.props.recentDecks) {
      return null;
    }
    return (
      <RecentDecks decks={this.props.recentDecks.data} channelId={this.props.channelId}/>
    );
  }
}

RecentDecksContainer.propTypes = {
  recentDecks: PropTypes.object,
  getRecentDecks: PropTypes.func,
  channelId: PropTypes.string
};

export default connect(
  (state) => ({
    recentDecks: state.recentDecks,
  }),
  ({
    getRecentDecks,
  })
)(RecentDecksContainer);