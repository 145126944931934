import React from 'react';
import {connect} from 'react-redux';
import {withRouter, Switch, Route, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {getTournamentByPasscode} from '../../../actions/tournamentActions';

import Main from './Root/Main';
import Create from './Create/Create';
import Success from './Success/Success';

import errorImg from '../../../assets/images/register-error.png';


class Index extends React.Component {
  componentDidMount() {
    const {match, getTournamentByPasscode} = this.props;

    getTournamentByPasscode(match.params.passcode, {
      isClientContext: true,
    });
  }

  render() {
    const {tournament, match} = this.props;

    if(tournament.isError) {
      const {error_description} = tournament.error.response.data;

      return (
        <div className="container fix-topbar-padding">
        <div className="row justify-content-center pt-5">
          <div className="col-lg-6 d-flex align-items-center flex-column pt-5">
            <img src={errorImg} alt=""/>
            <div className="py-5">
              <h4 className="text-center">{error_description}</h4>
              <h6 className="text-center"><FormattedMessage id="tournament.error"/></h6>
            </div>
            <div>
              <Link className="py-2 px-5 btn btn-primary" style={{textDecoration: 'none', color: 'white'}} to="/"><FormattedMessage id="tournament.error.btn.back"/></Link>
            </div>
          </div>
        </div>
        </div>
      );
    }
    if(tournament.isLoad || !tournament.data) {
      return null;
    }

    const withTournament = (Component) => () => {
      return <Component tournament={tournament.data} passcode={match.params.passcode}/>;
    };

    return (
      <Switch>
        <Route exact path={`${match.path}`} render={withTournament(Main)} />
        <Route path={`${match.path}/create`} render={withTournament(Create)} />
        <Route path={`${match.path}/success`} render={withTournament(Success)} />
      </Switch>
    );
  }
}

Index.propTypes = {
  match: PropTypes.object.isRequired,

  tournament: PropTypes.object.isRequired,
  getTournamentByPasscode: PropTypes.func.isRequired,
};

export default withRouter(connect(
  state => ({
    tournament: state.tournament
  }),
  {
    getTournamentByPasscode,
  }
)(Index));
