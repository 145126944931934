import {
  LOGOUT,
} from '../../actions/types';

const initialState = {
  isLoggingOut: false,
};

export default (state = initialState, action) => {
  switch(action.type) {
    case LOGOUT.START:
      return {
        ...state,
        isLoggingOut: true,
      };
    case LOGOUT.END:
      return {
        ...state,
        isLoggingOut: false,
      };
    default:
      return state;
  }
};
