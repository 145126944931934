import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Spinner = (props) => (
  <div className={classnames('spinner-container', props.class, {
    'spinner-full': props.full,
    [`text-${props.align}`]: props.align
  })}>
    <svg
      className="spinner"
      width={`${props.size}px`}
      height={`${props.size}px`}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path"
        fill="none"
        strokeWidth="5"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  </div>
);

Spinner.defaultProps = {
  align: 'center',
  size: 50,
  class: null,
  full: false,
};

Spinner.propTypes = {
  align: PropTypes.string,
  size: PropTypes.number,
  class: PropTypes.string,
  full: PropTypes.bool,
};

export default Spinner;
