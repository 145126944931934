import {createLogic} from 'redux-logic';

import {SELECT_TOURNAMENT_PLAYER_BIO} from '../actions/types';

export const getSchema = createLogic({
  type: SELECT_TOURNAMENT_PLAYER_BIO.GET_SCHEMA.START,
  latest: true,

  processOptions: {
    successType: SELECT_TOURNAMENT_PLAYER_BIO.GET_SCHEMA.SUCCESS,
    failType: SELECT_TOURNAMENT_PLAYER_BIO.GET_SCHEMA.ERROR,
  },

  process({httpClient, action}) {
    const {tournamentId} = action.payload;

    return httpClient.get(`/api/v1/tournaments/${tournamentId}`)
      .then(res => res.data)
      .then(data => {
        return data.bioSchema;
      });
  }
});

export const save = createLogic({
  type: SELECT_TOURNAMENT_PLAYER_BIO.SAVE.START,
  latest: true,

  process({httpClient, action, getState}, dispatch) {
    const {bioData, bioAvatar, onClose} = action.payload;
    const {params} = getState().selectTournamentPlayerBio;

    const isAvatarRequest = bioAvatar !== undefined;
    const isAvatarDelete  = isAvatarRequest && bioAvatar === null;
    const isAvatarUpdate  = isAvatarRequest && bioAvatar !== null && !!bioAvatar.name;

    const requests = [
      httpClient.post(`/api/v1/players/${params.playerId}/bio_schemas`, bioData)
    ];

    if(isAvatarUpdate) {
      const formData = new FormData();
      formData.append('file', bioAvatar);
      requests.push(
        httpClient.post(`/api/v1/players/${params.playerId}/avatar`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
      );
    }
    if(isAvatarDelete) {
      requests.push(
        httpClient.delete(`/api/v1/players/${params.playerId}/avatar`)
      );
    }

    return Promise.all(requests)
      .then(() => {
        dispatch({
          type: SELECT_TOURNAMENT_PLAYER_BIO.SAVE.SUCCESS,
          payload: {
            onClose: onClose
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SELECT_TOURNAMENT_PLAYER_BIO.SAVE.ERROR,
          payload: {
            onClose: onClose
          }
        });
      });
  }
});

export const saveSuccess = createLogic({
  type: SELECT_TOURNAMENT_PLAYER_BIO.SAVE.SUCCESS,
  latest: true,

  process({genericActions, action}, dispatch, done) {
    action.payload.onClose();
    dispatch(genericActions.notification.add({
      intlKey: 'notifications.general.success',
      type: 'success',
    }));
    done();
  }
});

export const saveError = createLogic({
  type: SELECT_TOURNAMENT_PLAYER_BIO.SAVE.ERROR,
  latest: true,

  process({genericActions, action}, dispatch, done) {
    action.payload.onClose();
    dispatch(genericActions.notification.add({
      intlKey: 'notifications.general.error',
      type: 'danger',
    }));
    done();
  }
});

export default [
  getSchema,
  save,
  saveSuccess,
  saveError,
];
