import { createLogic } from 'redux-logic';

import { STREAMERS } from '../actions/types';
import { add as addNotification } from '../actions/notificationActions';
import {close as closeModal} from '../actions/modal';
import { getStreamersList } from '../actions/streamersActions';
import modalNames from '../components/modals/names';

export const getStreamers = createLogic({
  type: STREAMERS.GET.START,
  latest: true,

  processOptions: {
    successType: STREAMERS.GET.SUCCESS,
    failType: STREAMERS.GET.ERROR,
  },

  process({ httpClient }) {
    return httpClient.get('/api/v1/streamers')
      .then(res => res.data);
    }
});

export const addNewStreamer = createLogic({
  type: STREAMERS.ADD.START,

  process({ httpClient, action}, dispatch, done) {
    return httpClient.post('/api/v1/streamers', action.payload)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          intlKey: 'streamers.add.success',
        }));
        dispatch(closeModal(modalNames.ADD_NEW_STREAMER));
        dispatch(getStreamersList());
      })
      .catch((err) => {
        if(err.response.status === 422) {
          dispatch(addNotification({
            type: 'danger',
            intlKey: 'streamers.add.error.channel',
          }));
        } else {
          dispatch(addNotification({
            type: 'danger',
            intlKey: 'streamers.add.error',
          }));
        }

        dispatch(closeModal(modalNames.ADD_NEW_STREAMER));

      })
      .then(() => done());

    }
});

export const updateStreamers = createLogic({
  type: STREAMERS.UPDATE.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.patch(`/api/v1/streamers/${action.payload.id}`, {
      [action.payload.key]: action.payload.value,
    })
    .then((res) => {
      dispatch({
        type: STREAMERS.UPDATE.SUCCESS,
        payload: res.data
      });
      dispatch(addNotification({
        type: 'success',
        intlKey: 'streamers.update.success',
      }));
    })
    .catch(() => {
      dispatch(addNotification({
        type: 'danger',
        intlKey: 'streamers.update.error',
      }));
    })
    .then(() => done());
    }
});

export const deleteStreamers = createLogic({
  type: STREAMERS.DELETE.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.delete(`/api/v1/streamers/${action.payload.id}`)
      .then(() => {
        dispatch(getStreamersList());
      })
      .then(() => done());
    }
});

export default [
  getStreamers,
  addNewStreamer,
  updateStreamers,
  deleteStreamers
];
