import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import BioFormSCG from './BioFormSCG';
import BioFormDefault from './BioFormDefault';
import Spinner from '../Spinner';

import './BioModalEdit.scss';

class BioModalEdit extends React.Component {
  state = {
    photoUrl: null,
  };

  renderBio = (formBody, buttons) => {
    const {renderBeforeBody} = this.props;

    return (
      <React.Fragment>
        <ModalBody>
          {renderBeforeBody}
          {formBody}
        </ModalBody>
        <ModalFooter>
          {buttons}
        </ModalFooter>
      </React.Fragment>
    );
  };

  renderImageCropper = (main, buttons) => {
    return (
      <React.Fragment>
        <ModalBody>
          {main}
        </ModalBody>
        <ModalFooter>
          {buttons}
        </ModalFooter>
      </React.Fragment>
    );
  };

  renderLoader(){
    const {showLoader} = this.props;

    if(showLoader){
      return(
        <div className="text-right">
          <Spinner size={30} align={'right'}/> Uploading...
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      isOpen,
      onClose,
      onSubmit,
      bioType,
      data,
      title,
      showLoader
    } = this.props;

    const components = {
      'default': BioFormDefault,
      'scg': BioFormSCG,
    };
    const Component = components[bioType];

    return (
      <Modal isOpen={isOpen} toggle={onClose} className="bio-modal-edit">
        <ModalHeader toggle={showLoader ? ()=>{} : onClose}>{title}</ModalHeader>

        <ModalBody>
          {this.renderLoader()}
          <Component render={this.renderBio}
                     renderImageCropper={this.renderImageCropper}
                     onSubmit={onSubmit}
                     onCancel={onClose}
                     showLoader={showLoader}
                     data={data}/>
        </ModalBody>
      </Modal>
    );
  }
}

BioModalEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  renderBeforeBody: PropTypes.element,
  title: PropTypes.string,
  showLoader: PropTypes.bool,
  bioType: PropTypes.oneOf(['default', 'scg']).isRequired,
};

export default BioModalEdit;
