import axios from 'axios';
import config from 'appConfig';

export default axios.create({
  baseURL: config.SHORTENER_URL,
  timeout: 0,
  headers: {
    'Authorization': 'Bearer ' + config.CLIENT_TOKEN
  }
});
