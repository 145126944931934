import {
  SET_USER_ROLE,
  GET_SINGLE_USER,
  USER_DELETE,
  USER_UPDATE,
} from './types';

export const setUserRole = (role) => ({
  type: SET_USER_ROLE,
  payload: role,
});

export const getSingleUser = (id) => ({
  type: GET_SINGLE_USER.FETCH,
  id: id,
});

export const deleteUser = (id) => ({
  type: USER_DELETE.START,
  id: id,
});

export const updateUser = (id, data) => ({
  type: USER_UPDATE.START,
  id: id,
  data: data
});

export const updateUserToggle = () => ({
  type: USER_UPDATE.TOGGLE
});
