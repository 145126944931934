const TYPES = {
  CUSTOM: 'Custom',
  CREATURE: 'Creature',
  PLANESWALKER: 'Planeswalker',
  ARTIFACT: 'Artifact',
  ENCHANTMENT: 'Enchantment',
  INSTANT: 'Instant',
  SORCERY: 'Sorcery',
  NONLAND: 'NonLand',
  LAND: 'Land',
  HOST: 'Host',
  SCHEME: 'Scheme',
};

const SUPERTYPES = {
  BASIC: 'Basic'
};


class Card {
  static TYPES = TYPES;
  static SUPERTYPES = SUPERTYPES;

  static mainTypeOrder = [
    TYPES.CUSTOM,
    TYPES.CREATURE,
    TYPES.ARTIFACT,
    TYPES.ENCHANTMENT,
    TYPES.PLANESWALKER,
    TYPES.INSTANT,
    TYPES.SORCERY,
    TYPES.NONLAND,
    TYPES.LAND,
    TYPES.HOST,
    TYPES.SCHEME,
  ];

  static getMainType(card) {
    if(card.types.includes(this.TYPES.LAND)){
      return this.TYPES.LAND;
    }

    return this.mainTypeOrder.find((typeName) => card.types.includes(typeName));
  }

  static isLand(card) {
    if(card.types.includes(this.TYPES.LAND)){
      return this.TYPES.LAND;
    }

    return this.TYPES.NONLAND;
  }

  static compareCardsType(typeA, typeB) {
    const typesOrder = this.mainTypeOrder;
    const aTypeIndex = typesOrder.indexOf(typeA);
    const bTypeIndex = typesOrder.indexOf(typeB);

    if (aTypeIndex < bTypeIndex){
      return -1;
    }
    if (aTypeIndex > bTypeIndex){
      return 1;
    }

    return 0;
  }
}

export default Card;
