import {TOURNAMENT_TEAMS_STANDINGS} from './types';

export const getTournamentTeamsStandings = (tournamentId, {page = 1, filters = {}} = {}) => ({
  type: TOURNAMENT_TEAMS_STANDINGS.GET.START,
  payload: {
    tournamentId,
    options: {
      page,
      filters,
    }
  }
});

export const updateTournamentTeamsStandings = ({tournamentId, data}) => ({
  type: TOURNAMENT_TEAMS_STANDINGS.UPDATE.START,
  payload: {
    tournamentId,
    data,
  }
});

export const updateTournamentTeamsStandingsSuccess = (data) => ({
  type: TOURNAMENT_TEAMS_STANDINGS.UPDATE.SUCCESS,
  payload: data
});

export const updateTournamentTeamsStandingsError = (data) => ({
  type: TOURNAMENT_TEAMS_STANDINGS.UPDATE.ERROR,
  payload: data
});
