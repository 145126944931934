import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ImageCropper from '../ImageCropper/ImageCropper';
import SelectImageWithPreview from '../SelectImage/SelectImageWithPreview';

import defaultAvatar from '../../assets/images/default-avatar.png';

import './BioForm.scss';

const FIELD_TYPE = {
  INPUT: 'input',
  IMAGE: 'img',
  TEXTAREA: 'textarea',
};

class BioForm extends React.Component {
  static defaultProps = {
    render: (formBody, buttons) => {
      return (
        <React.Fragment>
          {formBody}
          {buttons}
        </React.Fragment>
      );
    }
  };

  constructor(props) {
    super(props);

    const fields = props.fields.reduce((prev, current) => [...prev, ...current], []);

    const emptyData       = fields.reduce(this.reduceFieldsToEmptyData, {});
    const emptyImageUrls  = fields.reduce(this.reduceFieldsToEmptyImageUrls, {});
    const dataImages      = fields.reduce(this.createReduceFieldsToImagesData(props), {});

    this.state = {
      data: {...emptyData, ...props.data},
      imageUrls: {...emptyImageUrls, ...dataImages},
      cropping: {
        isActive: false,
        url: null,
        fieldName: null,
      },
    };
  }

  reduceFieldsToEmptyData = (prev, fieldData) => {
    return {
      ...prev,
      [fieldData.name]: ''
    };
  };

  reduceFieldsToEmptyImageUrls = (prev, fieldData) => {
    if(fieldData.type !== FIELD_TYPE.IMAGE) {
      return prev;
    }

    return  {
      ...prev,
      [fieldData.name]: null
    };
  };

  createReduceFieldsToImagesData = (props) => {
    return (prev, fieldData) => {
      if(fieldData.type !== FIELD_TYPE.IMAGE) {
        return prev;
      }

      const fieldName = fieldData.name;
      const dataValue = props.data[fieldName];

      if(!dataValue){
        return prev;
      }

      return  {
        ...prev,
        [fieldName]: dataValue,
      };
    };
  };

  openCropping(fieldName, url) {
    this.setState({
      cropping: {
        isActive: true,
        url,
        fieldName,
      },
    });
  }

  closeCropping() {
    this.setState({
      cropping: {
        isActive: false,
        url: null,
        fieldName: null,
      },
    });
  }

  onSubmit = (e) => {
    const {onSubmit, fields} = this.props;
    const {data} = this.state;

    e.preventDefault();

    onSubmit(data, fields.reduce((prev, current) => [...prev, ...current], []));
  };

  onChangeFieldImage = (fieldName, data) => {
    const imageUrl = this.state.imageUrls[fieldName];

    if(imageUrl) {
      (window.URL || window.webkitURL).revokeObjectURL(imageUrl);
    }

    this.openCropping(fieldName, data.imageUrl);
  };

  onRemoveFieldImage = (fieldName) => {
    const imageUrl = this.state.imageUrls[fieldName];

    (window.URL || window.webkitURL).revokeObjectURL(imageUrl);

    this.setState((state) => ({
      imageUrls: {
        ...state.imageUrls,
        [fieldName]: null,
      },
      data: {
        ...state.data,
        [fieldName]: null,
      }
    }));
  };

  onChangeFieldInput = (e, fieldName) => {
    const value = e.target.value;

    this.changeDataField(fieldName, value);
  };

  onChangeFieldTextarea = (e, fieldName) => {
    const value = e.target.value;

    this.changeDataField(fieldName, value);
  };

  onSubmitCropping = (image) => {
    this.setState((state) => {
      const fieldName = state.cropping.fieldName;

      return {
        imageUrls: {
          ...state.imageUrls,
          [fieldName]: image.url,
        },
        data: {
          ...state.data,
          [fieldName]: image.file,
        },
      };
    });

    this.closeCropping();
  };

  onCancelCropping = () => {
    this.closeCropping();
  };

  changeDataField(fieldName, value) {
    this.setState((state) => ({
      data: {
        ...state.data,
        [fieldName]: value,
      }
    }));
  }

  renderRow(fieldsRow, index) {
    return (
      <div className="row" key={`row-${index}`}>
        {fieldsRow.map((fieldData, index) => this.renderCol(fieldData, index))}
      </div>
    );
  }

  renderCol(fieldData, index) {
    return (
      <div className="col" key={`col-${index}`}>
        {this.renderField(fieldData)}
      </div>
    );
  }

  renderField(fieldData) {
    switch(fieldData.type) {
      case FIELD_TYPE.INPUT:
        return this.renderFieldInput(fieldData);
      case FIELD_TYPE.IMAGE:
        return this.renderFieldImage(fieldData);
      case FIELD_TYPE.TEXTAREA:
        return this.renderFieldTextarea(fieldData);
    }
  }

  renderFieldInput(fieldData) {
    const {data} = this.state;
    const fieldName = fieldData.name;

    return (
      <div className="form-group">
        <label htmlFor={fieldName}>{fieldData.label}</label>
        <input type="text"
               className="form-control"
               id={fieldName}
               value={data[fieldName]}
               onChange={(e) => this.onChangeFieldInput(e, fieldName)} />
      </div>
    );
  }

  renderFieldImage(fieldData) {
    const {imageUrls} = this.state;
    const {showLoader} = this.props;

    const fieldName = fieldData.name;
    const photoUrl = imageUrls[fieldData.name];

    const onRemove = showLoader
      ? null
      : () => this.onRemoveFieldImage(fieldName);

    return (
      <SelectImageWithPreview className="form-group"
                              image={photoUrl}
                              defaultImage={defaultAvatar}
                              onRemove={onRemove}
                              onChange={(data) => this.onChangeFieldImage(fieldName, data)}
                              labelText={fieldData.label}
                              buttonText={fieldData.data.buttonText}/>
    );
  }

  renderFieldTextarea(fieldData) {
    const {data} = this.state;
    const fieldName = fieldData.name;

    const textAreaDefaultProps = {
      rows: 2
    };
    const textAreaProps = {
      ...textAreaDefaultProps,
      ...{
        rows: fieldData.data.rows,
      }
    };

    return (
      <div className="form-group">
        <label htmlFor={fieldData.name}>{fieldData.label}</label>
        <textarea className="form-control"
                  id={fieldData.name}
                  {...textAreaProps}
                  value={data[fieldName]}
                  onChange={(e) => this.onChangeFieldTextarea(e, fieldName)}/>
      </div>
    );
  }

  renderButtons() {
    const {onCancel, showLoader} = this.props;

    return (
      <React.Fragment>
        {
          onCancel &&
          <button type="button" className={classnames('btn btn-secondary', {
            'disabled' : showLoader
          })} onClick={onCancel}>
            Cancel
          </button>
        }
        <button type="submit" className={classnames('btn btn-success', {
          'disabled' : showLoader
        })}>
          Save
        </button>
      </React.Fragment>
    );
  }

  render() {
    const {fields, className, render, renderImageCropper} = this.props;
    const {cropping} = this.state;

    const formBody = fields.map((fieldsRow, index) => this.renderRow(fieldsRow, index));
    const buttons = this.renderButtons();

    if(cropping.isActive) {
      return (
        <ImageCropper src={cropping.url}
                      bestFit
                      onSubmit={this.onSubmitCropping}
                      onCancel={this.onCancelCropping}
                      cancelButton={{
                        text: 'Cancel',
                        className: 'btn-secondary'
                      }}
                      submitButton={{
                        text: 'Save',
                        className: 'btn-success'
                      }}
                      render={renderImageCropper}/>
      );
    }

    return (
      <form onSubmit={this.onSubmit} className={classnames('bio-form', className)}>
        {render(formBody, buttons)}
      </form>
    );
  }
}

BioForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(Object.values(FIELD_TYPE)).isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        data: PropTypes.object,
      })
    )
  ).isRequired,
  data: PropTypes.object,
  className: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  render: PropTypes.func,
  renderImageCropper: PropTypes.func,
  showLoader: PropTypes.bool,
};

export default BioForm;
