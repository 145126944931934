import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import DataTableWithMobileDropdown from '../../../DataTable/DataTableWithMobileDropdown';

import './TeamMembersDecklistTable.scss';

class TeamMembersDecklistTable extends React.Component {
  render() {
    const {data, buttonSubmitText, submitDisabled, children, dropdownData, onSubmit, intl} = this.props;

    const columns = [
      {
        name: intl.formatMessage({id: 'team.member-decklist-table.generic.col.firstName'})
      },
      {
        name: intl.formatMessage({id: 'team.member-decklist-table.generic.col.lastName'})
      },
      {
        name: intl.formatMessage({id: 'team.member-decklist-table.generic.col.dciNumber'})
      },
      {
        name: intl.formatMessage({id: 'team.member-decklist-table.generic.col.format'})
      },
      {
        name: intl.formatMessage({id: 'team.member-decklist-table.generic.col.decklists'}),
        isVisibleOnMobile: false,
        className: 'col-decklist'
      },
    ];
    const tableData = data.map((item, index) => children(item, index));

    return (
      <div className="team-members-decklist-table">
        <DataTableWithMobileDropdown
          columns={columns}
          data={tableData}
          dropdownData={dropdownData}
          disableHover={true}
        />

        <button
          disabled={submitDisabled}
          className="btn btn-success btn-lg float-right"
          onClick={onSubmit}
        >{buttonSubmitText}</button>
      </div>
    );
  }
}

TeamMembersDecklistTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    dciNumber: PropTypes.string,
    format: PropTypes.string,
    deckId: PropTypes.string,
  })).isRequired,
  buttonSubmitText: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  dropdownData: DataTableWithMobileDropdown.propTypes.dropdownData,
  submitDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,

  intl: PropTypes.object.isRequired,
};

export default injectIntl(TeamMembersDecklistTable);
