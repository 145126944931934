import { createLogic } from 'redux-logic';

import { RESET_PASSWORD } from '../actions/types';

export const all = createLogic({
  type: /^RESET_PASSWORD_.*$/,

  process({ action }) {
    /* eslint-disable no-console */
    console.log(action);
    /* eslint-enable no-console */
  }
});


export const post = createLogic({
  type: RESET_PASSWORD.START,

  processOptions: {
    successType: RESET_PASSWORD.SUCCESS,
    failType: RESET_PASSWORD.ERROR,
  },

  process({ clientContextHttpClient, action }) {
    return clientContextHttpClient.post('/api/v1/password_reset', action.payload )
    .then(res => res.body);
  }
});

export default {
  all,
  post
};
