import {createLogic} from 'redux-logic';
import {pickBy} from 'lodash';

import {ORGANIZER_STATISTICS} from '../actions/types';
import * as organizerStatisticsActions from '../actions/organizerStatistics';

const urls = {
  deck_names: '/api/v1/organizer_stats/formats/:formatId/deck_names',
  tournaments: '/api/v1/organizer_stats/users',
  cards: '/api/v1/organizer_stats/formats/:formatId/cards',
};

export const fetch = createLogic({
  type: ORGANIZER_STATISTICS.FETCH,

  process({ httpClient, action }, dispatch, done) {
    const payload = action.payload;
    const baseUrl = urls[payload.name];

    const url = Object.keys(payload.params)
      .reduce((prev, next) => prev.replace(`:${next}`, payload.params[next]), baseUrl);
    const qParams = pickBy(payload.params, (val, key) => baseUrl.indexOf(`:${key}`) === -1);

    return httpClient.get(url, {params: qParams})
      .then(res => res.data)
      .then(data => {
        dispatch(organizerStatisticsActions.update(payload.name, data, payload.params));
      })
      .catch(err => dispatch(organizerStatisticsActions.updateError(payload.name, err, payload.params)))
      .then(done);
  }
});

export const fetchAll = createLogic({
  type: ORGANIZER_STATISTICS.FETCH_ALL,

  process({ action }, dispatch, done) {
    const payload = action.payload;

    Object.keys(urls).forEach((name) => {
      const params = payload.params[name] || {};

      dispatch(organizerStatisticsActions.fetch(name, params));
    });

    done();
  }
});

export default {
  fetch,
  fetchAll
};
