import {
  GET_SINGLE_USER, USER_UPDATE
} from '../actions/types';

const initialState = {
  data: null,
  created: null,
  isLoad: false,
  isError: false,
  error: null,
  isEditMode: false,
};

export default (state = initialState, action) => {
switch (action.type) {
  case GET_SINGLE_USER.FETCH:
    return {
      ...state,
      isLoad: true,
      isError: false,
      error: null,
    };
  case GET_SINGLE_USER.ERROR:
    return {
      ...state,
      isLoad: false,
      isError: true,
      error: action.payload
    };
  case GET_SINGLE_USER.SUCCESS:
    return {
      ...state,
      isLoad: false,
      isError: false,
      error: null,
      data: action.payload,
    };
    case USER_UPDATE.START:
      return {
        ...state,
        isLoad: true,
        isError: false,
        error: null,
      };
    case USER_UPDATE.SUCCESS:
      return {
        ...state,
        isLoad: false,
        isError: false,
        error: null,
        data: action.payload,
      };
    case USER_UPDATE.ERROR:
      return {
        ...state,
        isLoad: false,
        isError: true,
        error: action.payload,
      };
    case USER_UPDATE.TOGGLE:
      return {
        ...state,
        isEditMode: !state.isEditMode
      };
  default:
    return state;
  }
};
