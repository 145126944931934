import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patch, fetchDeck, save, getDecknames } from '../../actions/deckActions';
import { fetchFormats } from '../../actions/formatsActions';
import { add as addNotification } from '../../actions/notificationActions';

import CreateDeckList from './CreateDeckList';

import './CreateDeckList.scss';

class CreateDeckListContainer extends React.Component {
  componentDidMount() {
    this.props.fetchDeck(this.props.id);
    this.props.fetchFormats();
  }

  render() {
    return (
      <CreateDeckList
        id={this.props.id}
        formats={this.props.formats}
        deck={this.props.deck}
        decksPatch={this.props.decksPatch}
        isDecksPatchLoad={this.props.isDecksPatchLoad}
        isCardSearchLoad={this.props.isCardSearchLoad}
        isDecksCardLoad={this.props.isDecksCardLoad}
        saveDeck={this.props.saveDeck}
        deckValidation={this.props.deckValidation}
        patch={this.props.patch}
        save={this.props.save}
        organizer={this.props.organizer}
        unregistered={this.props.unregistered}
        tournamentDecklist={this.props.tournamentDecklist}
        disableFormat={this.props.disableFormat}
        onSave={this.props.onSave}
        onClose={this.props.onClose}
        userRole={this.props.userRole}
        getDecknames={this.props.getDecknames}
        deckNames={this.props.deckNames}
        addNotification={this.props.addNotification}
        btnFixed={this.props.btnFixed}
        inModal={this.props.inModal}
        supportCustomCards={this.props.supportCustomCards}
        userDeck={this.props.userDeck}
      />
    );
  }
}

CreateDeckListContainer.propTypes = {
  id: PropTypes.string.isRequired,
  deck: PropTypes.object,
  decksPatch: PropTypes.object,
  fetchFormats: PropTypes.func.isRequired,
  match: PropTypes.object,
  patch: PropTypes.func.isRequired,
  fetchDeck: PropTypes.func.isRequired,
  formats: PropTypes.object,
  save: PropTypes.func.isRequired,
  saveDeck: PropTypes.object,
  // history: PropTypes.object.isRequired,
  isDecksPatchLoad: PropTypes.bool,
  isCardSearchLoad: PropTypes.bool,
  isDecksCardLoad: PropTypes.bool,
  deckValidation: PropTypes.object.isRequired,
  organizer: PropTypes.bool,
  unregistered: PropTypes.bool,
  disableFormat: PropTypes.bool,
  tournamentDecklist: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  userRole: PropTypes.string,
  deckNames: PropTypes.object,
  getDecknames: PropTypes.func,
  addNotification: PropTypes.func,
  btnFixed: PropTypes.bool,
  inModal: PropTypes.bool,
  supportCustomCards: PropTypes.bool,
  userDeck: PropTypes.bool,
};

export default connect(
  state => ({
    deck: state.fetchDeck,
    decksPatch: state.decksPatch,
    isDecksPatchLoad: state.decksPatch.isLoad,
    isCardSearchLoad: state.search.isLoading,
    isDecksCardLoad: state.deckCards.isLoad,
    formats: state.formats,
    saveDeck: state.saveDeck,
    deckValidation: state.deckValidation,
    userRole: state.user.userRole,
    deckNames: state.deckNames,
  }),
  ({
    fetchDeck,
    fetchFormats,
    patch,
    save,
    getDecknames,
    addNotification,
  })
)(CreateDeckListContainer);
