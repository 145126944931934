import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { replace } from 'react-router-redux';

import TeamMembersDecklistCreateTable from '../../../Tournament/Team/MembersDecklistTable/TeamMembersDecklistCreateTable';

import { getUnregisterTournamentTeam, unregisteredTeamPlayerCreateDeck, unregisteredTeamSend, unregisterTeamDeckModalCancel } from '../../../../actions/tournamentTeams';

import ls from '../../../../services/LocalStorage';
import CreateDeckTeamModalContainer from './CreateDeckTeamModalContainer';

import './TeamDetailsContainer.scss';

class TeamDetailsContainer extends React.Component {
  state = {
    teamId: null,
    modalDeck: {
      isOpen: false,
      user: null,
    },
  }

  componentDidMount() {
    const { getUnregisterTournamentTeam, unregisterTeam } = this.props;
    const teamId = ls.get('teamId');

    this.setState({
      teamId,
    });

    if (!unregisterTeam.team) {
      return getUnregisterTournamentTeam(teamId);
    }

    return;
  }

  onDeckEditModalClose = () => {
    const {unregisterTeamDeckModalCancel} = this.props;

    unregisterTeamDeckModalCancel();

    this.setState({
      modalDeck: {
        isOpen: false,
        user: null,
      },
    });
  };

  onDeckSave = () => {
    window.location.reload();

    this.setState({
      modalDeck: {
        isOpen: false,
        user: null,
      },
    });
  }

  onEditDecklist = (userId) => {
    const { unregisterTeam, unregisteredTeamPlayerCreateDeck, tournament } = this.props;
    const user = unregisterTeam.team.data.players.find(elem => {
      return elem.id === userId;
    });

    unregisteredTeamPlayerCreateDeck(tournament.id, user.id, user.player.deck.id);

    this.setState({
      modalDeck: {
        isOpen: true,
        user,
      },
    });
  };

  onTeamSubmit = () => {
    const { passcode, unregisteredTeamSend, unregisterTeam } = this.props;
    const { teamId } = this.state;
    const nextActions = [replace(`/join-tournament/${passcode}/success`)];

    unregisteredTeamSend(teamId, unregisterTeam.team.data, nextActions);
  }

  getPlayersData() {
    const { unregisterTeam } = this.props;

    return unregisterTeam.team.data.players.map(elem => {
      return {
        firstName: elem.player.firstName,
        lastName: elem.player.lastName,
        dciNumber: elem.player.dciNumber,
        format: elem.format.name,
        deckId: elem.player.deck.id,
        validDeck: elem.player.deck.valid,
        userId: elem.id,
      };
    });
  }

  render() {
    const { unregisterTeam, tournament } = this.props;
    const { modalDeck } = this.state;

    if (!unregisterTeam.team) {
      return null;
    }

    return (
      <div className="team-details-container">
        <h5 className="team-details-title"><FormattedMessage id="team.member-decklist-table.create.create" /></h5>
        <TeamMembersDecklistCreateTable
          data={this.getPlayersData()}
          onEditDecklist={this.onEditDecklist}
          onSubmit={this.onTeamSubmit}
        />
        {
          modalDeck.isOpen &&
          <CreateDeckTeamModalContainer
            isOpen={true}
            onClose={this.onDeckEditModalClose}
            userId={modalDeck.user.id}
            deck={unregisterTeam.createDeck.deck}
            tournamentId={tournament.id}
            onSave={this.onDeckSave}
          />
        }
      </div>
    );
  }
}

TeamDetailsContainer.propTypes = {
  unregisterTeam: PropTypes.object.isRequired,
  getUnregisterTournamentTeam: PropTypes.func,
  unregisteredTeamPlayerCreateDeck: PropTypes.func,
  unregisterTeamDeckModalCancel: PropTypes.func,
  unregisteredTeamSend: PropTypes.func,
  tournament: PropTypes.object,
  passcode: PropTypes.string,
};

export default connect(
  state => ({
    unregisterTeam: state.tournamentTeams.unregisterTeam
  }),
  {
    getUnregisterTournamentTeam,
    unregisteredTeamPlayerCreateDeck,
    unregisterTeamDeckModalCancel,
    unregisteredTeamSend,
  }
)(TeamDetailsContainer);
