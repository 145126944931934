import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPublicDecks, getShortenerUrl, getPublicArenaFormat, getStreamersInfo, getStreamerDecks } from '../../../actions/publicDecksActions';
import { add as addNotification } from '../../../actions/notificationActions';

import { getPublicDeckArenaFormat, getPublicDeckDeck, getPublicDeckCards } from '../../../selectors/publicDeck';
import SharedDeck from './SharedDeck';
import Spinner from '../../Spinner';
import './SharedDeckContainer.scss';

class SharedDeckContainer extends React.Component {
  state = {
    params: null,
  };

  componentDidMount() {
    const { match } = this.props;

    const params = this.getQueryParam();

    this.props.getPublicDecks(match.params.id);
    this.props.getShortenerUrl(match.params.id, params);
    this.props.getPublicArenaFormat(match.params.id);
    this.props.getStreamersInfo(params && params.channel_id);
    params && params.channel_id && this.props.getStreamerDecks(params.channel_id, match.params.id);
    this.setState({
      params,
    });
  }

  getQueryParam() {
    const { location } = this.props;
    let queryArr = [];
    const re = /[?&]([^=&]+)=([^&]+)/g;
    const params = [];
    const queryObj = {};

    if (!location.search) {
      return null;
    }

    while ((queryArr = re.exec(location.search)) !== null) {
      params.push(queryArr);
    }

    params.forEach((elem) => {
      return queryObj[elem[1]] = elem[2];
    });

    return queryObj;
  }

  render() {
    const { deckData, cardsData, arenaFormat, streamersInfo, streamersDecks } = this.props;
    const url = this.props.shortenerUrl.data && this.props.shortenerUrl.data.url;
    const partnerId = this.state.params && this.state.params.partner_id;
    const purchases = this.state.params && this.state.params.purchases;
    const channelId = this.state.params && this.state.params.channel_id;

    if (!deckData.data || !cardsData.data) {
      return <Spinner class="shared-deck-container__spinner" />;
    }

    const deckName = (channelId && streamersDecks.data)
      ? streamersDecks.data.data[0].displayName
      : deckData.data.name;

    return (
      <SharedDeck
        deckName={deckName}
        deckData={deckData.data}
        deckCards={cardsData.data}
        onCopyToClipboard={this.props.addNotification}
        partnerId={partnerId}
        purchases={purchases}
        shortenerUrl={url}
        arenaFormat={arenaFormat.data}
        channelId={channelId}
        channelName={streamersInfo.data.name}

      />
    );
  }
}

SharedDeckContainer.propTypes = {
  getPublicDecks: PropTypes.func.isRequired,
  shortenerUrl: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  addNotification: PropTypes.func,
  getShortenerUrl: PropTypes.func,
  getPublicArenaFormat: PropTypes.func,
  deckData: PropTypes.object,
  arenaFormat: PropTypes.object,
  cardsData: PropTypes.object,
  getStreamersInfo: PropTypes.func,
  streamersInfo: PropTypes.object,
  getStreamerDecks: PropTypes.func,
  streamersDecks: PropTypes.object
};

export default connect(
  (state) => ({
    deckData: getPublicDeckDeck(state),
    arenaFormat: getPublicDeckArenaFormat(state),
    cardsData: getPublicDeckCards(state),
    shortenerUrl: state.shortenerUrl,
    streamersInfo: state.streamersInfo,
    streamersDecks: state.streamersDecks
  }),
  ({
    getPublicDecks,
    addNotification,
    getShortenerUrl,
    getPublicArenaFormat,
    getStreamersInfo,
    getStreamerDecks
  })
)(SharedDeckContainer);
