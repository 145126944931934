import {
  STORES_ALL,
  STORES,
  EDIT_STORE,
  STORE_DETAILS,
  CREATE_STORE,
} from './types';

export const getAllStores = (page, search) => ({
  type: STORES_ALL.FETCH,
  payload: {
    page: page || 1,
    search
  }
});

export const getStores = (page, search) => ({
  type: STORES.FETCH,
  payload: {
    page: page || 1,
    search
  }
});

export const getStoreDetails = (id) => ({
  type: STORE_DETAILS.START,
  payload: id
});

export const editStore = (id, data) => ({
  type: EDIT_STORE.START,
  payload: { id, data}
});

export const createStore = (data) => ({
  type: CREATE_STORE.START,
  payload: data
});
