import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GenericTooltip from './GenericTooltip';
import DeckProgressBar from './Utils/DeckProgressBar';
import DeckCards from './Utils/DeckCards';
import Spinner from './Spinner';

import { fetch, createDraft, deleteDeck } from '../actions/deckActions';
import { FormattedMessage } from 'react-intl';
import DateFormatted from './DateFormatted/DateFormatted';
import Prompt from './Generic/Modals/Prompt';
import DeckOnLivestreamBoxContainer from './DeckOnLivestream/DeckOnLivestreamBoxContainer';

import './DecksList.scss';

class DecksList extends React.Component {
  state = {
    isOpenModalDelete: false,
    deletedDeckId: null,
  };

  componentDidMount() {
    this.props.fetch();
  }

  renderDecks(decks) {
    if (!decks) {
      return;
    }

    return (
      <div className="row">
        {decks.map((deck) => {
          return (
            <div className="col-lg-4 mb-3" key={deck.id}>
              { this.renderSingleDeck(deck) }
            </div>
          );
        })}
      </div>
    );
  }

  onEdit = (draftId, deckId) => {
    return this.props.createDraft(deckId, '/decks/:id');
  }

  openModalDelete = (deckId) => {
    this.setState({
      isOpenModalDelete: true,
      deletedDeckId: deckId,
    });
  };

  closeModalDelete = () => {
    this.setState({
      isOpenModalDelete: false,
      deletedDeckId: null,
    });
  };

  deleteDeck = () => {
    const {deletedDeckId} = this.state;
    const {deleteDeck, fetch} = this.props;

    deleteDeck(deletedDeckId, [
      fetch()
    ]);
  };

  renderSingleDeck(deck) {
    if (!deck) {
      return;
    }

    const format = deck.customFormat || deck.format.name;
    return (
      <div className="deck">

        {!deck.valid
          ? <GenericTooltip id={deck.id} placement={'right'} message={'This deck is not valid'}/>
          : null
        }

        <span className="deck__delete" onClick={() => this.openModalDelete(deck.id)}>
          <i className="icon-x2"/>
        </span>

        <div className="deck__wrapper">
          <h3 className="deck__top-name" title={deck.name}>{deck.name}</h3>
          <p className="deck__top-text"><DeckCards format={deck.format && deck.format.name} amount={deck.amount}/> cards</p>
        </div>
        <p className="small">({format})</p>
        <DeckProgressBar format={deck.format && deck.format.name} amount={deck.amount}/>
        <div className="deck__wrapper deck__wrapper--extra-p-top">
          <p className="deck__created">
            <FormattedMessage id="deck.created.at"/>{' '}<DateFormatted value={deck.createdAt}/>
          </p>
          <div
            className="deck__link"
            onClick={() => this.onEdit(deck.draftId, deck.id)}
          >
            <FormattedMessage id="edit.deck.title"/>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {isOpenModalDelete} = this.state;
    const {user} = this.props;

    if(!this.props.decks.data && this.props.decks.isLoad){
      return <Spinner/>;
    }

    return (
      <div className="decks-list">
        <div className="decks-list__divider"/>
        <h2 className="decks-list__title"><FormattedMessage id="deck.title.your.decks"/></h2>
        <DeckOnLivestreamBoxContainer userId={user.data.id}/>
        {this.renderDecks(this.props.decks.data)}

        <Prompt isOpen={isOpenModalDelete}
                onSubmit={this.deleteDeck}
                onClose={this.closeModalDelete}
                title={<FormattedMessage id="deck.modal.delete"/>}
                submitClassName={'btn-danger'}>
          <FormattedMessage id="deck.modal.text"/>
        </Prompt>
      </div>
    );
  }
}

DecksList.propTypes = {
  decks: PropTypes.object,
  draft: PropTypes.object,
  fetch: PropTypes.func.isRequired,
  createDraft: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  deleteDeck: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    decks: state.decks,
    draft: state.createDraft,
    user: state.user,
  }),
  ({
    fetch,
    createDraft,
    deleteDeck
  })
)(DecksList);
