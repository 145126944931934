import {
  IMPORT_STANDINGS,
} from './types';

export const importStandings = (tournamentId, file) => ({
  type: IMPORT_STANDINGS.START,
  tournamentId: tournamentId,
  file: file
});
  
  