import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getStreamersInfo} from '../../../actions/publicDecksActions';
import PublicDeck from './PublicDeck';
import { add as addNotification } from '../../../actions/notificationActions';

class PublicDeckContainer extends React.Component {
  componentDidMount() {
    const {match} = this.props;

    this.props.getStreamersInfo(match.params.name);
  }
  render() {
    const {streamersInfo} = this.props;

    if(!streamersInfo.data){
      return null;
    }

    return (
      <PublicDeck
        channelId={streamersInfo.data.id}
        name={streamersInfo.data.name}
        url={streamersInfo.data.imgUrl}
        onCopyToClipboard={this.props.addNotification}
      />
    );
  }
}

PublicDeckContainer.propTypes = {
  match: PropTypes.object,
  streamersInfo: PropTypes.object,
  getStreamersInfo: PropTypes.func.isRequired,
  addNotification: PropTypes.func,
};

export default connect(
  (state) => ({
    streamersInfo: state.streamersInfo
  }),
  ({
    getStreamersInfo,
    addNotification,
  })
)(PublicDeckContainer);
