import React from 'react';
import { Link } from 'react-router-dom';
import TopBar from '../Layout/TopBar';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import checkEmailImg from '../../assets/images/mail.png';

class CheckEmail extends React.Component {
  render(){
    return(
      <React.Fragment>
        <TopBar rightView={null} mobileBreakpoint={null} />
        <div className="container fix-topbar-padding">
          <div className="row justify-content-center">
            <div className="col-lg-6 d-flex align-items-center flex-column pt-5">
              <img src={checkEmailImg} alt=""/>
              <div className="py-5">
                <p className="text-center h5"><FormattedHTMLMessage id="check.email.text"/></p>
              </div>
              <div>
                <Link className="py-2 px-2 btn btn-primary" style={{textDecoration: 'none', color: 'white'}} to="/"><FormattedMessage id="check.email.btn.back"/></Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CheckEmail;
