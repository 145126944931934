import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './StatusDot.scss';

export default class StatusDot extends React.PureComponent {
  render() {
    const classNames = classnames('tournament-status-dot', this.props.className, {
      'tournament-status-dot--completed': this.props.status === 'completed',
      'tournament-status-dot--in-progress': this.props.status === 'in_progress',
      'tournament-status-dot--not-started': this.props.status === 'not_started'
    });
    return <span className={classNames} />;
  }
}

StatusDot.propTypes = {
  status: function (props, propName, componentName) {
    if (!/completed|in_progress|not_started/.test(props[propName])) {
      return new Error(
        'Invalid prop `' + propName + '` supplied to' +
        ' `' + componentName + '`. Correct values: `completed`, `in_progress`, `not_started`. Validation failed.'
      );
    }
  },
  className: PropTypes.string
};
