import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import DatePicker from 'react-datepicker';

class DatePickerFormatted extends React.Component {
  static defaultProps = {
    dateFormat: 'short'
  };

  render() {
    const {intl, dateFormat, ...restProps} = this.props;

    return (
      <DatePicker
        dateFormat={intl.messages.formatters.date[dateFormat]}
        {...restProps}
      />
    );
  }
}

DatePickerFormatted.propTypes = {
  intl: PropTypes.object.isRequired,
  dateFormat: PropTypes.string,
};

export default injectIntl(DatePickerFormatted);
