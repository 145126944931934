import { createLogic } from 'redux-logic';

import { GET_SINGLE_USER, USER_DELETE, USER_UPDATE } from '../actions/types';

import { add as addNotification } from '../actions/notificationActions';
import {getUsers} from '../actions/usersActions';

export const getSingleUserLogic = createLogic({
  type: GET_SINGLE_USER.FETCH,
  latest: true,

  processOptions: {
    successType: GET_SINGLE_USER.SUCCESS,
    failType: GET_SINGLE_USER.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get(`/api/v1/users/${action.id}`)
      .then(res => res.data);
  }
});

export const deleteUser = createLogic({
  type: USER_DELETE.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.delete(`/api/v1/users/${action.id}`)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: 'User successfully deleted'
        }));
        dispatch(getUsers());
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: 'Error. Try again'
        }));
      })
      .then(() => done());
  }
});

const displayErrorMessage = (err) => {
  let errMessage = 'Error. Try again. ';

  if(err.roles) {
    errMessage = errMessage.concat(`${err.roles}. `);
  }
  if(err.judgeLevel) {
    errMessage = errMessage.concat(`${err.judgeLevel} `);
  }

  return errMessage;
};

export const updateUser = createLogic({
  type: USER_UPDATE.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.patch(`/api/v1/users/${action.id}`, action.data)
      .then((res) => {
        dispatch(addNotification({
          type: 'success',
          text: 'User successfully updated'
        }));
        dispatch({
          type: USER_UPDATE.SUCCESS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch(addNotification({
          type: 'danger',
          text: displayErrorMessage(err.response.data.extras)
        }));
        dispatch({
          type: USER_UPDATE.ERROR,
          payload: err
        });
      })
      .then(() => done());
  }
});

export default {
  getSingleUserLogic,
  deleteUser,
  updateUser,
};
