import {combineReducers} from 'redux';

import get from './get';
import edit from './edit';
import data from './data';

export default combineReducers({
  get,
  edit,
  data,
});
