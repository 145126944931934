import React from 'react';
import { Link } from 'react-router-dom';
import registerSuccessImg from '../../assets/images/register-success.png';
import AuthService from '../../services/AuthService';
import { FormattedMessage } from 'react-intl';

class SuccessRegister extends React.Component {
  componentDidMount() {
    AuthService.logout();
  }

  render() {
    return (
      <div className="text-center p-5">
        <div className="success-register__img">
          <img src={registerSuccessImg} alt="success" />
        </div>
        <div className="success-register__text">
          <h4 className="mt-3"><FormattedMessage id="register.success.title"/></h4>
          <h6 className="mt-3">
            <FormattedMessage id="register.success.text"/>
          </h6>
        </div>
        <Link to="/login" className="btn btn-primary mt-3">
          <FormattedMessage id="register.success.btn"/>
        </Link>
      </div>
    );
  }
}

export default SuccessRegister;
