import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import {getCountries, getStates} from '../../../../actions/politicalActions';
import {getAllStores} from '../../../../actions/storesActions';
import {getBioSchemas} from '../../../../actions/biographyActions';
import {saveTournamentAndUpdate, saveTournamentClear} from '../../../../actions/tournamentActions';
import {getAllTournamentTypesData} from '../../../../actions/tournamentTypes';

import AccessControl from '../../../Utils/AccessControl';
import AccessDenied from '../../../pages/AccessDenied';
import {roles} from '../../../const';

import TournamentForm from '../../TournamentForm';
import Spinner from '../../../Spinner';

class TournamentLocationEdit extends React.Component {
  componentDidMount(){
    const {
      tournament,
      countries,
      getStates,
      getCountries,
      getAllStores,
      getBioSchemas,
      saveTournamentClear,
      getAllTournamentTypesData,
    } = this.props;

    if(!countries.data){
      getCountries();
    }

    saveTournamentClear();
    tournament.country && getStates(tournament.country);
    getAllStores();
    getAllTournamentTypesData();
    getBioSchemas();
  }

  updateStates = (country) => {
    const {getStates} = this.props;

    getStates(country);
  };

  onSave = (data) => {
    const {saveTournamentAndUpdate, tournament} = this.props;

    const nextActions = [
      replace(`../info`),
    ];

    saveTournamentAndUpdate(tournament.id, data, nextActions);
  };

  onCancel = () => {
    const {replace} = this.props;

    replace(`../info`);
  };

  render() {
    const {tournament, tournamentTypes, states, countries, stores, bioSchemas} = this.props;

    if(
      !countries.data ||
      !stores.data ||
      !stores.data.data ||
      !tournamentTypes.isGettingAllSuccess ||
      !bioSchemas.data
    ) {
      return <Spinner/>;
    }

    return (
      <AccessControl roles={[roles.organizer, roles.admin]} forbiddenRender={ <AccessDenied /> }>
        <TournamentForm tournament={tournament}
                        states={states.data}
                        countries={countries.data}
                        stores={stores.data.data}
                        types={tournamentTypes.types}
                        formats={tournamentTypes.formats.data}
                        bioSchemas={bioSchemas.data}
                        updateStates={this.updateStates}
                        onSave={this.onSave}
                        onCancel={this.onCancel}
                        isDisabledType
                        isDisabledFormat={tournament.type === 'Team'}/>
      </AccessControl>
    );
  }
}

TournamentLocationEdit.propTypes = {
  tournament: PropTypes.object.isRequired,
  countries: PropTypes.object.isRequired,
  states: PropTypes.object.isRequired,
  stores: PropTypes.object.isRequired,
  bioSchemas: PropTypes.object.isRequired,
  savedTournament: PropTypes.object.isRequired,

  getCountries: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
  getBioSchemas: PropTypes.func.isRequired,
  getAllStores: PropTypes.func.isRequired,
  saveTournamentAndUpdate: PropTypes.func.isRequired,
  saveTournamentClear: PropTypes.func.isRequired,
  getAllTournamentTypesData: PropTypes.func.isRequired,
  tournamentTypes: PropTypes.object.isRequired,
  replace: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    countries: state.countries,
    states: state.states,
    stores: state.storesAll,
    bioSchemas: state.bioSchemas,
    savedTournament: state.savedTournament,
    tournamentTypes: state.tournamentTypes,
  }),
  ({
    getCountries,
    getStates,
    getAllStores,
    getBioSchemas,
    saveTournamentAndUpdate,
    saveTournamentClear,
    getAllTournamentTypesData,
    replace,
  })
)(TournamentLocationEdit);
