import React from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import DataTableWithMobileDropdown from '../DataTable/DataTableWithMobileDropdown';
import Spinner from '../Spinner';
import GenericTooltip from '../GenericTooltip';
import TournamentUserDeleteModal from './Tabs/Users/TournamentUserDeleteModal';
import ModalEditTournamentPlayer from './ModalEditTournamentPlayer';
import TournamentDeckBuilderModal from './Tabs/Players/TournamentDeckBuilderModalContainer';
import AddNewPlayerModal from './Tabs/Players/AddNewPlayerModal';
import {getSortingState} from '../../services/Sorting';
import {roles} from '../const';
import {create} from '../../actions/tournamentUserActions';
import {
  getTournamentPlayers,
  deleteTournamentPlayer,
  tournamentPlayerUpdate
} from '../../actions/tournamentMembers';
import {importPlayers} from '../../actions/playersActions';
import {open} from '../../actions/modal';
import ChangeableDropdownColor from '../Generic/ChangeableDropdown/ChangeableDropdownColor';
import Prompt from '../Generic/Modals/Prompt';
import Pagination from '../Pagination/Pagination';
import moment from 'moment';

import PrintTournamentPlayer from './PrintTournamentPlayer/PrintTournamentPlayer';

import names from '../modals/names';

import './Players.scss';

const DECK_STATE_ONLINE = 'online';

class Players extends React.Component {
  state = {
    isOpen: false,
    isPlayerModalOpen: false,
    deletePlayerId: null,
    searchValue: '',
    bioLoader: false,
    modalDeckBuilder: {
      isOpen: false,
      playerId: null,
    },
    deckWarningPrompt: {
      isOpen: false,
      data: null,
    },
    bioModal: {
      isOpen: false,
      data: null,
    },
    sorting: null
  };

  componentDidMount() {
    const {tournament, getTournamentPlayers, match} = this.props;
    const {sorting} = this.state;

    const sortColumn = sorting && sorting.actualSortedKey;
    const sortType = sorting && sorting.type;

    getTournamentPlayers(tournament.id, null, match.params.page, sortColumn, sortType);
  }

  componentDidUpdate(prevProps) {
    const {match} = this.props;

    if (prevProps.match.params.page !== match.params.page) {
      this.reloadTableData();
    }
  }

  showBioLoader = () => {
    this.setState({
      bioLoader: true
    });
  }

  hideBioLoader = () => {
    this.setState({
      bioLoader: false
    });
  }

  reloadTableData() {
    const {tournament, match, getTournamentPlayers} = this.props;
    const {searchValue, sorting} = this.state;

    const sortColumn = sorting && sorting.actualSortedKey;
    const sortType = sorting && sorting.type;

    getTournamentPlayers(tournament.id, searchValue, match.params.page, sortColumn, sortType);
  }

  handleInfoClick = () => {
    this.props.open(names.HOW_TO_IMPORT_PLAYERS);
  }

  handleSearchInputChange = (e) => {
    const { tournament, match } = this.props;
    const {sorting} = this.state;
    this.setState({
      searchValue: e.target.value,
    });
    const sortColumn = sorting && sorting.actualSortedKey;
    const sortType = sorting && sorting.type;

    const page = e.target.value.length > 0 ? 1 : match.params.page;

    this.props.getTournamentPlayers(tournament.id, e.target.value, page, sortColumn, sortType);
  };

  handleDeckBuilderClose = () => {
    this.toggleDeckBuilderModal(null);
    this.reloadTableData();
  }

  submitDelete = () => {
    const {deleteTournamentPlayer, tournament} = this.props;
    const {deletePlayerId} = this.state;

    deleteTournamentPlayer(tournament.id, deletePlayerId);
    this.toggleModal(null);
  };

  onCreateUser = (userData) => {
    const { tournament } = this.props;
    this.props.create(tournament.id, userData, null, this.toggleNewPlayerModal);
  };

  onSubmitTournamentPlayer = (bioData, playerData) => {
    const {tournamentPlayerUpdate} = this.props;
    const {bioModal} = this.state;

    const idPlayer = bioModal.player.id;

    tournamentPlayerUpdate(idPlayer, playerData);
    this.closeBioModal();
  };

  toggleModal = (id) => {
    if(!id) {
      return this.setState(() => ({
        isOpen: false,
        deletePlayerId: id
      }));
    }

    this.setState((state) => ({
      isOpen: !state.isOpen,
      deletePlayerId: id
    }));
  };

  openBioModal = (data) => {
    this.setState({
      bioModal: {
        isOpen: true,
        data,
      },
    });
  };

  closeBioModal = () => {
    this.hideBioLoader();
    this.setState({
      bioModal: {
        isOpen: false,
        data: null
      },
    });
  };

  toggleDeckBuilderModal = (id) => {
    this.setState((state) => ({
      modalDeckBuilder: {
        isOpen: !state.modalDeckBuilder.isOpen,
        playerId: id
      }
    }));
  };

  toggleNewPlayerModal = () => {
    this.setState((state) => ({
      isPlayerModalOpen: !state.isPlayerModalOpen,
    }));
  };

  onChangeDeckState = (playerId, userId, actualValue, updateValue) => {
    if(actualValue === DECK_STATE_ONLINE) {
      this.openDeckWarningPrompt({
        playerId,
        value: updateValue,
      });

      return;
    }

    this.changeDeckState(playerId, userId, updateValue);
  };

  changeDeckState = (playerId, userId, value) => {
    const {tournamentPlayerUpdate} = this.props;
    const isOnline = value === DECK_STATE_ONLINE;

    tournamentPlayerUpdate(playerId, {
      deckState: value,
    });

    if (isOnline) {
      this.toggleDeckBuilderModal(userId);
    }
  };

  openDeckWarningPrompt = (data) => {
    this.setState({
      deckWarningPrompt: {
        isOpen: true,
        data: data,
      },
    });
  };

  closeDeckWarningPrompt = () => {
    this.setState({
      deckWarningPrompt: {
        isOpen: false,
        data: null,
      },
    });
  };

  submitDeckWarningPrompt = () => {
    const {deckWarningPrompt} = this.state;
    const {playerId, value} = deckWarningPrompt.data;

    this.changeDeckState(playerId, null, value);
    this.closeDeckWarningPrompt();
  };

  renderUserInfoTooltip(children, index) {
    return (
      <React.Fragment>
        <span className="user-info-tooltip d-md-none">
          <GenericTooltip id={`user-info-standing-${index}`} variant={'info'} placement={'right'} message={'User without account'}/>
        </span>
        {children}
      </React.Fragment>
    );
  }

  getData = (tournamentMembers) => {
    const {userRole} = this.props;

    return tournamentMembers.map((item) => {
      if(userRole === roles.judge) {
        return {
          className: 'hover-disabled',
          data: [
            this.renderImportedField(item),
            item.lastName,
            item.firstName,
            item.dciNumber,
            this.renderBioField(item),
            this.renderDeckField(item),
            moment(item.createdAt).format('M/D/Y'),
          ]
        };
      }
      return {
        className: 'hover-disabled',
        data: [
          this.renderImportedField(item),
          item.lastName,
          item.firstName,
          item.dciNumber,
          this.renderBioField(item),
          this.renderDeckField(item),
          moment(item.createdAt).format('M/D/Y'),
          this.renderActionsField(item),
        ]
      };
    });
  };

  renderImportedField(userData) {
    const imported = userData.imported;

    return (
      <React.Fragment>
        {
          userData.deck && !userData.deck.valid &&
          <GenericTooltip id={`imported-${userData.id}`} placement={'left'} message={'Deck invalid'}/>
        }
        {
          imported &&
          <i className="icon-check"/>
        }
      </React.Fragment>
    );
  }

  renderPrintButton = (userData, isOnline) => {
    const {tournament} = this.props;

    return (
      <PrintTournamentPlayer tournamentId={tournament.id} userId={userData.user.id} isOnline={isOnline}/>
    );
  };

  renderBioField(userData) {
    const {tournament} = this.props;

    return (
      <React.Fragment>
        {userData.hasBio && <i className="icon-check"/>}

        <i className={classnames('icon-edit', {
          'icon-edit--margin': userData.hasBio,
        })} onClick={() => this.openBioModal({
          userId: userData.user.id,
          playerId: userData.id,
          tournamentId: tournament.id,
        })}/>
      </React.Fragment>
    );
  }

  renderDeckField(userData) {
    const options = [
      {value: 'paperdeck', name: 'Paper only', color: 'blue'},
      {value: 'online', name: 'Online', color: 'primary'},
      {value: 'none', name: 'None', color: 'gray'},
    ];

    const isNotValid = !userData.deck || (userData.deck && !userData.deck.valid);
    const isOnline = userData.deckState === DECK_STATE_ONLINE;

    const isShowInvalidIndicator = isNotValid && isOnline;

    return (
      <div className={classnames('deck-field-wrapper', {
        valid: !isShowInvalidIndicator
      })}>
        {
          isShowInvalidIndicator &&
          <GenericTooltip id={`deck-${userData.id}`} placement={'left'} message={'Deck invalid'}/>
        }

        <ChangeableDropdownColor options={options}
                                 renderCurrentValue
                                 value={userData.deckState}
                                 onChange={(value) => {this.onChangeDeckState(userData.id, userData.user.id, userData.deckState, value);}}/>

        <React.Fragment>
          <button className="btn-icon" disabled={!isOnline}>
            <i className="icon-edit" onClick={() => this.toggleDeckBuilderModal(userData.user.id)}/>
          </button>
          {this.renderPrintButton(userData, isOnline)}
        </React.Fragment>
      </div>
    );
  }

  renderActionsField(userData) {
    return (
      <div className="actions-field-wrapper">
        <i className="icon-delete" onClick={() => this.toggleModal(userData.user.id)}/>
      </div>
    );
  }

  onSort = (sortedKey) => {
    const {tournament, getTournamentPlayers, match} = this.props;
    const {sorting} = this.state;
    const sort = getSortingState(sorting, sortedKey);

    this.setState({
      sorting: sort
    });

    const sortColumn = sort && sort.actualSortedKey;
    const sortType = sort && sort.type;

    getTournamentPlayers(tournament.id, null, match.params.page, sortColumn, sortType);
  };

  renderTable = () => {
    const {tournamentMembers, userRole, playersImport} = this.props;
    const {sorting} = this.state;
    if (!tournamentMembers || !tournamentMembers.players.data) {
      return null;
    }

    if(playersImport.isLoad) {
      return <React.Fragment>
        <div className="py-3">
          <Spinner/>
          <p className="text-center"><FormattedMessage id="tournament.players.import.loading_message"/></p>
        </div>
      </React.Fragment>;
    }

    if (tournamentMembers.players.data.data.length === 0) {
      return <p className="text-center mt-3"><FormattedMessage id="tournament.players.no.registered"/></p>;
    }

    let columns;

    if(userRole === roles.judge) {
      columns = [
        {name: 'IMPORTED?', className: 'col-imported'},
        {name: 'LAST NAME', sortedKey: 'lastName'},
        {name: 'FIRST NAME', sortedKey: 'firstName'},
        {name: 'DCI'},
        {name: 'BIO', className: 'col-bio'},
        {name: 'DECK', sortedKey: 'deckState', className: 'col-deck', flex: 2},
        {name: 'REGISTERED AT', sortedKey: 'createdAt'},
      ];
    } else {
      columns = [
        {name: 'IMPORTED?', className: 'col-imported'},
        {name: 'LAST NAME', sortedKey: 'lastName'},
        {name: 'FIRST NAME', sortedKey: 'firstName'},
        {name: 'DCI'},
        {name: 'BIO', className: 'col-bio'},
        {name: 'DECK', sortedKey: 'deckState', className: 'col-deck', flex: 2},
        {name: 'REGISTERED AT', sortedKey: 'createdAt'},
        {name: 'DELETE', className: 'col-actions', isVisibleOnMobile: false},
      ];
    }
    const dropdownData = (dataRow, rowIndex) => {
      const userData = tournamentMembers.players.data.data[rowIndex];
      const isOnline = userData.deckState === DECK_STATE_ONLINE;
      const buttons = [
        <div key={`${rowIndex}-edit`} onClick={() => {
          this.openBioModal({
            userId: userData.user.id,
            playerId: userData.id,
            tournamentId: this.props.tournament.id,
          });
        }}>
          <FormattedMessage id="tournament.players.mobile.edit"/> <i className="icon-manage-account"/>
        </div>,

        <PrintTournamentPlayer
          key={`${rowIndex}-print`}
          tournamentId={this.props.tournament.id}
          userId={userData.user.id}
          isOnline={isOnline}
          tag="div"
          renderContent={ () => (<React.Fragment><FormattedMessage id="tournament.players.mobile.print"/> <i className="icon-print"/></React.Fragment>) }/>,

        <div key={`${rowIndex}-delete`} onClick={() => this.toggleModal(userData.user.id)}>
          <FormattedMessage id="tournament.players.mobile.delete"/> <i className="icon-delete"/>
        </div>,
      ];
      if (isOnline) {
        buttons.unshift((<div key={`${rowIndex}-edit-deck`}
         onClick={() => this.toggleDeckBuilderModal(userData.user.id)}
        >
          <FormattedMessage id="tournament.players.mobile.edit.deck"/>  <i className="icon-decklist"/>
        </div>));
      }

      return buttons;
    };

    const members = tournamentMembers.players.data.data.filter(item => !item.deleted);

    const data = this.getData(members);
    const keys = members.map(e => e.user.id);

    return (
      <DataTableWithMobileDropdown
        className={'data-table--th-center'}
        data={data}
        columns={columns}
        keys={keys}
        dropdownData={dropdownData}
        onSort={this.onSort}
        sortedOptions={sorting}
      />
    );
  };

  renderPagination = () => {
    const {tournamentMembers, tournament} = this.props;
    const {searchValue} = this.state;

    if(searchValue){
      return null;
    }

    let total = null;

    total = tournamentMembers.players.data && tournamentMembers.players.data.headers && tournamentMembers.players.data.headers['x-last-page'];

    return total && <Pagination totalPages={total} url={`/tournaments/show/${tournament.id}/players/:page`}/>;
  }

  uploadFile = (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    this.props.importPlayers(this.props.tournament.id, file);

    this.setState({
      sorting: null
    });
  };

  render() {
    const {tournamentMembers, tournament, intl} = this.props;
    const {deckWarningPrompt, modalDeckBuilder, bioModal} = this.state;

    if(!tournamentMembers.players.data){
      return <Spinner/>;
    }

    return (
      <div className="players-wrapper">
        <div className="d-flex align-items-center players-top">
          <h4 className="section-header mb-4">
            <FormattedMessage id="tournament.players.title"/>
          </h4>
          <div className="d-flex ml-auto">
            <span onClick={this.toggleNewPlayerModal} className="btn btn-primary">
                <span className="d-none d-md-block"><FormattedMessage id="tournament.players.btn.add"/></span>
              <i className="fa fa-plus d-md-none"/>
            </span>
          </div>
        </div>
        <div>
          <div className="form-row">
            <div className="mb-3 col-md-6">
              <div className="input-group">
                <input onChange={this.handleSearchInputChange} type="text" className="form-control" placeholder={intl.formatMessage({id: 'tournament.players.search.placeholder'})} value={this.state.searchValue} autoComplete="nope" autofill="off" spellCheck="false" autoCorrect="off" autoCapitalize="off"/>
                <div className="input-group-append">
                  <button className="btn btn-light" type="button"><i className="icon-search"/></button>
                </div>
              </div>
            </div>
            <div className="col-md-3 offset-md-3 d-none">
              <select className="form-control custom-select">
                <option>Default</option>
              </select>
            </div>
          </div>
        </div>

        <div className="tournament-list-table-wrapper">
          {this.renderTable()}
        </div>
        <div className="pagination-wrapper">
          {this.renderPagination()}
        </div>
        <div className="players-links-wrapper">
          <GenericTooltip
            id={'player-upload'}
            className={'tooltip-info'}
            placement={'right'}
            message={<FormattedMessage id="tournament.players.import.players.tooltip.text"/>}
          >
            <div className="players-import players-link">
              <input type="file" className="file-upload" value="" onChange={this.uploadFile}/>
              <FormattedMessage id="tournament.players.import.players"/>
            </div>
          </GenericTooltip>
          <span className="howto-link" onClick={this.handleInfoClick}><FormattedMessage id="modal.help.link"/></span>
        </div>

        <TournamentUserDeleteModal
          isOpen={this.state.isOpen}
          toggle={() => this.toggleModal(null)}
          onSubmit={this.submitDelete}
        />

        <Prompt isOpen={deckWarningPrompt.isOpen}
                onSubmit={this.submitDeckWarningPrompt}
                onClose={this.closeDeckWarningPrompt}
                title={<FormattedMessage id="deckStatusWarningPrompt.header"/>}
                submitClassName={'btn-danger'}>
          <FormattedMessage id="deckStatusWarningPrompt.text"/>
        </Prompt>

        {modalDeckBuilder.isOpen && <TournamentDeckBuilderModal
                                      isOpen={true}
                                      toggle={this.handleDeckBuilderClose}
                                      userId={modalDeckBuilder.playerId}
                                      tournamentId={tournament.id}
                                      supportCustomCards={tournament.customCards}
                                    />}
        <AddNewPlayerModal  isOpen={this.state.isPlayerModalOpen}
                            toggle={() => this.toggleNewPlayerModal()}
                            onSubmit={this.onCreateUser}/>

        {
          bioModal.isOpen &&
          <ModalEditTournamentPlayer isOpen={bioModal.isOpen}
                                     showBioLoader={this.showBioLoader}
                                     bioLoader={this.state.bioLoader}
                                     data={bioModal.data}
                                     bioType={tournament.bioSchema.name}
                                     onClose={this.closeBioModal}/>
        }

      </div>
    );
  }
}

Players.propTypes = {
  getTournamentPlayers: PropTypes.func,
  tournamentMembers: PropTypes.object,
  tournament: PropTypes.object.isRequired,
  deleteTournamentPlayer: PropTypes.func,
  userRole: PropTypes.string,
  create: PropTypes.func.isRequired,
  tournamentPlayerUpdate: PropTypes.func.isRequired,
  importPlayers: PropTypes.func,
  playersImport: PropTypes.object,
  match: PropTypes.object,
  intl: PropTypes.object,
  open: PropTypes.func,
};

export default injectIntl(withRouter(connect(
  (state) => ({
    tournamentMembers: state.tournamentMembers,
    userRole: state.user.userRole,
    playersImport: state.playersImport,
  }),
  ({
    getTournamentPlayers,
    deleteTournamentPlayer,
    tournamentPlayerUpdate,
    importPlayers,
    create,
    open,
  })
)(Players)));
