import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import CopyToClipboard from 'react-copy-to-clipboard';
import {UncontrolledTooltip} from 'reactstrap';

import Modal from './DeckOnLivestreamModal';

import './DeckOnLivestreamBox.scss';
import twitchImg from '../../assets/images/twitch.svg';

class DeckOnLivestreamBox extends React.Component {
  state = {
    isOpenModal: false,
  };

  componentDidMount() {
    this.props.onGetTokens();
  }

  onShowModal = (e) => {
    e.preventDefault();

    this.setState({
      isOpenModal: true,
    });
  };

  onCloseModal = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  onGenerate = (e) => {
    e.preventDefault();

    this.props.onGenerateToken();
  };

  renderContent() {
    const {tokens} = this.props;

    if(!tokens.data || tokens.isPending){
      return this.renderContentLoading();
    }

    return this.renderContentLoaded();
  }

  renderContentLoading() {
    return (
      <React.Fragment>
        <div className="container-info">
          <p><FormattedMessage id="personalToken.box.text"/></p>
          <strong>Loading...</strong>
        </div>
      </React.Fragment>
    );
  }

  renderContentLoaded() {
    const {tokens} = this.props;

    const token = tokens.data.length === 0
      ? null
      : tokens.data[0];

    return (
      <React.Fragment>
        <div className="container-info">
          {this.renderTokenInfo(token)}
        </div>
        <div className="container-buttons">
          {this.renderTokenButtons(token)}
        </div>
      </React.Fragment>
    );
  }

  renderTokenInfo(tokenData) {
    const {onCopyToken} = this.props;

    if(!tokenData){
      return (
        <Fragment>
          <p><FormattedMessage id="personalToken.box.text"/></p>
          <a href="" onClick={this.onGenerate}><FormattedMessage id="personalToken.box.generate"/></a>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <p><FormattedMessage id="personalToken.box.textTokenExist"/></p>
        <CopyToClipboard text={tokenData.token} onCopy={onCopyToken}>
          <strong className="livestream-token">{tokenData.token}</strong>
        </CopyToClipboard>
      </Fragment>
      
    );
  }

  renderTokenButtons(tokenData) {
    const {tokens, onRegenerateToken, onDeleteToken, onCopyToken} = this.props;

    if(!tokenData){
      return null;
    }

    const btnIds = {
      copy: `personalTokenCopy`,
      regenerate: `personalTokenRegenerate`,
      remove: `personalTokenRemove`,
    };

    const isRegenerated = tokens.regenerated.includes(tokenData.id);

    return (
      <React.Fragment>
        <CopyToClipboard text={tokenData.token} onCopy={onCopyToken}>
          <button className="btn btn-sm btn-success" id={btnIds.copy}>
            <i className="icon-copying"/>
          </button>
        </CopyToClipboard>
        <button className="btn btn-sm btn-light"
                id={btnIds.regenerate}
                disabled={isRegenerated}
                onClick={() => onRegenerateToken(tokenData.id)}>
          <i className="icon-refresh"/>
        </button>
        <button className="btn btn-sm btn-danger" id={btnIds.remove} onClick={() => onDeleteToken(tokenData.id)}>
          <i className="icon-x2"/>
        </button>

        <UncontrolledTooltip target={btnIds.copy} delay={0}>
          <FormattedMessage id="personalToken.modal.main.tooltips.copy"/>
        </UncontrolledTooltip>
        <UncontrolledTooltip target={btnIds.regenerate} delay={0}>
          <FormattedMessage id="personalToken.modal.main.tooltips.regenerate"/>
        </UncontrolledTooltip>
        <UncontrolledTooltip target={btnIds.remove} delay={0}>
          <FormattedMessage id="personalToken.modal.main.tooltips.remove"/>
        </UncontrolledTooltip>
      </React.Fragment>
    );
  }

  render() {
    const {isOpenModal} = this.state;

    return (
      <React.Fragment>
        <div className="deck-on-livestream-box">
          <div className="row top livestream-container">
            <div className="container-icon">
              <img src={twitchImg} alt={'Twitch'}/>
            </div>
            {this.renderContent()}
          </div>
          <div className="row bottom">
            <a href="" onClick={this.onShowModal}><FormattedMessage id="personalToken.box.link"/></a>
          </div>
        </div>

        <Modal isOpen={isOpenModal} onClose={this.onCloseModal}/>
      </React.Fragment>
    );
  }
}

DeckOnLivestreamBox.propTypes = {
  tokens: PropTypes.object.isRequired,
  onGetTokens: PropTypes.func.isRequired,
  onGenerateToken: PropTypes.func.isRequired,
  onRegenerateToken: PropTypes.func.isRequired,
  onDeleteToken: PropTypes.func.isRequired,
  onCopyToken: PropTypes.func,
};

export default DeckOnLivestreamBox;
