import {createLogic} from 'redux-logic';
import {TOURNAMENT_STANDINGS} from '../actions/types';
import {updateTournamentStandingsSuccess, updateTournamentStandingsError} from '../actions/tournamentStandings';

export const get = createLogic({
  type: TOURNAMENT_STANDINGS.GET.START,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_STANDINGS.GET.SUCCESS,
    failType: TOURNAMENT_STANDINGS.GET.ERROR,
  },

  process({httpClient, action}) {
    const {tournamentId, search, page, sort, order} = action.payload;

    const getStandings = httpClient.get(`/api/v1/tournaments/${tournamentId}/standings`, {
      params: {
        name: search,
        page: page,
        sort: sort || 'createdAt',
        order: order || 'DESC'
      }
    })
      .then((res) => ({
        pagination: {
          lastPage: res.headers['x-last-page'],
          page:     res.headers['x-page'],
          perPage:  res.headers['x-per-page'],
          total:    res.headers['x-total'],
        },
        data: res.data,
      }));

    const getExtras = httpClient.get(`/api/v1/tournaments/${tournamentId}/tournament_extras`)
      .then((res) => res.data);

    return Promise.all([
      getStandings,
      getExtras,
    ])
      .then(([players, extras]) => {
        return {
          players,
          extras,
        };
      });
  }
});

export const update = createLogic({
  type: TOURNAMENT_STANDINGS.UPDATE.START,
  latest: true,

  process({httpClient, action, genericActions}, dispatch, done) {
    const {tournamentId, data} = action.payload;
    const {players, extras, newData} = data;

    const isUpdatePlayers = Object.keys(players).length > 0;
    const isUpdateExtras  = Object.keys(extras).length > 0;
    const isNewData  = Object.keys(newData).length > 0;

    const updatePlayers = !isUpdatePlayers
      ? null
      : Promise.all(Object.keys(players).map(playerId => {
          const playerData = players[playerId];

          return httpClient.patch(`/api/v1/tournaments/${tournamentId}/standings/${playerId}`, playerData)
            .then(res => res.data)
            .then(data => ({
              id: playerId,
              isError: false,
              data,
            }))
            .catch(err => ({
              id: playerId,
              isError: true,
              data: {
                status: err.response.status,
                data: err.response.data,
              },
            }));
        }));

    const updateExtras = !isUpdateExtras
      ? null
      : httpClient.post(`/api/v1/tournaments/${tournamentId}/tournament_extras`, extras)
          .then(res => res.data)
          .then(data => ({
            isError: false,
            data,
          }))
          .catch(err => ({
            isError: true,
            data: {
              status: err.response.status,
              data: err.response.data,
            },
          }));
      
      const addNewData = !isNewData
        ? null
        : Promise.all(Object.keys(newData).map(standingId => {
            const newStandingsData = newData[standingId];

            return httpClient.post(`/api/v1/tournaments/${tournamentId}/standings`, newStandingsData)
              .then(res => res.data)
              .then(data => ({
                id: tournamentId,
                isError: false,
                data,
              }))
              .catch(err => ({
                id: tournamentId,
                isError: true,
                data: {
                  status: err.response.status,
                  data: err.response.data,
                },
              }));
          }));

    return Promise.all([
      updatePlayers,
      updateExtras,
      addNewData
    ])
      .then(([players, extras, newStandings]) => {
        const playersErrors   = players ? players.filter(playerData => playerData.isError) : [];
        const playersSuccess  = players ? players.filter(playerData => !playerData.isError) : [];
        
        const standingErrors   = newStandings ? newStandings.filter(newStandings => newStandings.isError) : [];
        const standingSuccess  = newStandings ? newStandings.filter(newStandings => !newStandings.isError) : [];

        const playersReduce = (prev, current) => {
          prev[current.id] = current.data;

          return prev;
        };

        const errors = {
          extras: extras && extras.isError ? extras.data : null,
          players: playersErrors.reduce(playersReduce, {}),
          standings: standingErrors.reduce(playersReduce, {}),
        };

        const successes = {
          extras: extras && !extras.isError ? extras.data : null,
          players: playersSuccess.reduce(playersReduce, {}),
          standings: standingSuccess.reduce(playersReduce, {}),
        };

        const isError   = errors.extras !== null || playersErrors.length > 0 || standingErrors.length > 0;
        const isSuccess = successes.extras !== null || playersSuccess.length > 0 || standingSuccess.length > 0;

        if(isError) {
          dispatch(updateTournamentStandingsError(errors));
        }
        if(isSuccess) {
          dispatch(updateTournamentStandingsSuccess(successes));
        }

        if(isError) {
          if(Object.keys(errors.players).length > 0) {
            Object.keys(errors.players).map(item => {
              if(errors.players[item].data.error === 'DCIAlreadyRegistered') {
                dispatch(genericActions.notification.add({
                  type: 'danger',
                  text: errors.players[item].data.error_description,
                }));
              } else {
                dispatch(genericActions.notification.add({
                  type: 'danger',
                  intlKey: 'standings.update.notification.error',
                }));
              }
            });
          } else {
            dispatch(genericActions.notification.add({
              type: 'danger',
              intlKey: 'standings.update.notification.error',
            }));
          }
        }
        else {
          dispatch(genericActions.notification.add({
            type: 'success',
            intlKey: 'standings.update.notification.success',
          }));
        }

        done();
      });
  }
});

export const deleteStanding = createLogic({
  type: TOURNAMENT_STANDINGS.DELETE.START,

  process({httpClient, action, genericActions}, dispatch, done) {
    const {tournamentId, standingId} = action.payload;

    return httpClient.delete(`/api/v1/tournaments/${tournamentId}/standings/${standingId}`)
      .then(()=>{
        dispatch({ type: TOURNAMENT_STANDINGS.DELETE.SUCCESS, payload: {standingId}});
        dispatch(genericActions.notification.add({
          type: 'success',
          intlKey: 'standings.delete.notification.success'
        }));
      })
      .catch(() => {
        dispatch(genericActions.notification.add({
          type: 'danger',
          text: 'standings.delete.notification.error'
        }));
      })
      .then(done);
  }
});

export default [
  get,
  update,
  deleteStanding
];
