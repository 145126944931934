import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import ModalConnector from '../Generic/Modals/ModalConnector';
import { Scrollbars } from 'react-custom-scrollbars';

import names from './names';

class StandingsImportModal extends React.Component {
  render() {
    return (
      <ModalConnector name={names.IMPORT_STANDINGS_RESULTS}>
        {
          ({isOpen, data, onClose}) => {
            return (
              <Modal isOpen={isOpen} toggle={onClose} size="lg">
                <ModalHeader toggle={onClose}><FormattedMessage id="standings.import.modal.title"/></ModalHeader>
                <ModalBody>
                  <p>
                    <FormattedMessage id="standings.import.modal.description" />
                  </p>
                  <Scrollbars
                    autoHeight autoHeightMax={'70vh'}
                    className="pl-2"
                    style={ { background: 'rgba(255,255,255,0.1)' }}
                    renderThumbVertical={props => <div {...props} className="scrollbar-primary"/>}
                  >
                    {
                      data.unrecognized.map((item, id) => {
                        return(
                          <div key={id} className=""><small>{item}</small></div>
                        );
                      })
                    }
                  </Scrollbars>
                </ModalBody>
                <ModalFooter>
                  <button className="btn btn-primary" onClick={onClose}>
                    <FormattedMessage id="standings.import.modal.btn"/>
                  </button>
                </ModalFooter>
              </Modal>
            );
          }
        }
      </ModalConnector>
    );
  }
}

export default StandingsImportModal;
