import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import httpClient from '../../services/clientContextHttpClient';
import { FormattedMessage } from 'react-intl';

export default class Email extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAvailable: null,
      emailValid: false,
      isValid: false,
      isBlurred: false,
      validating: true,
    };
  }

  componentDidUpdate() {
    if (
      this.state.isValid !== this.state.emailAvailable &&
      this.state.emailValid
    ) {
      this.changeValidation(this.state.emailAvailable && this.state.emailValid);
    }
  }

  changeValidation(value) {
    if (this.state.isValid !== value) {
      this.props.onValidationChanged(value);
    }
    this.setState({
      isValid: value,
    });
  }

  checkUsernameAvailable() {
    if (!this.state.emailValid) {
      this.setState({
        isBlurred: true,
        validating: true,
      });
      return;
    }

    this.setState({
      isBlurred: true,
    });

    httpClient
      .get(`/api/v1/available_emails/${this.props.value}`)
      .then(() => {
        this.setState({
          emailAvailable: true,
          validating: false,
        });
      })
      .catch(() => {
        this.setState({
          emailAvailable: false,
          validating: false,
        });
      });
  }

  handleChange(value) {
    this.props.onChange(value);
    this.setState({
      emailValid: this.validateEmail(value),
      isValid: false,
    });
  }

  validateEmail(value) {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return false;
    }
    return true;
  }

  renderValidation() {
    const {error} = this.props;
    const {isValid, emailValid, isBlurred, emailAvailable, validating} = this.state;

    if (error) {
      return <div className="invalid-feedback">{error}</div>;
    }
    if (isValid) {
      return <div className="valid-feedback"><FormattedMessage id="register.email.valid"/></div>;
    }
    if (isBlurred && !emailValid) {
      return <div className="invalid-feedback"><FormattedMessage id="register.email.invalid"/></div>;
    }
    if (
      isBlurred &&
      !emailAvailable &&
      !validating
    ) {
      return <div className="invalid-feedback"><FormattedMessage id="register.email.error"/></div>;
    }
  }

  render() {
    const {
      isValid,
      isBlurred,
      emailValid,
      emailAvailable,
      validating,
    } = this.state;
    const {error} = this.props;
    const valid = isValid;
    const invalid =
      (isBlurred && !emailValid) ||
      (isBlurred && !emailAvailable && !validating);
    const inputClass = classNames('form-control', 'register-form__input', {
      'is-valid': valid && !error,
      'is-invalid': invalid || error,
    });
    return (
      <React.Fragment>
        <input
          type="text"
          className={inputClass}
          value={this.props.value}
          onChange={e => this.handleChange(e.target.value)}
          onBlur={() => this.checkUsernameAvailable()}
        />
        {this.renderValidation()}
      </React.Fragment>
    );
  }
}

Email.propTypes = {
  onValidationChanged: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string
};
