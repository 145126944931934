import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CreateDeckList from '../pages/CreateDeckListContainer';

import {roles} from '../const';

class EditTeamDeck extends React.Component {
  render() {
    const deckId = this.props.match.params.id;
    const teamId = this.props.match.params.teamId;

    const isUnregisteredFlag = this.props.userRole !== roles.admin;

    // 1. If `unregistered` === true can't save invalid deck - it stupid, i know...
    // 2. If `tournamentDecklist` === true don't show "Save and submit" button - it stupid, i know...
    return (
      <CreateDeckList
        id={deckId}
        history={this.props.history}
        unregistered={isUnregisteredFlag}
        tournamentDecklist
        onSave={() => {
          this.props.history.replace(`/team/edit/${teamId}`);
        }}
      />
    );
  }
}

EditTeamDeck.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    userRole: state.user.userRole,
  })
)(EditTeamDeck);
