import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DataTableWithMobileDropdown from '../DataTable/DataTableWithMobileDropdown';
import Toggle from 'react-toggle';

import { updateStreamer } from './../../actions/streamersActions';

import './StreamersList.scss';

class StreamersList extends React.Component {

  toggleView(e, key, id) {
    const { updateStreamer } = this.props;

    updateStreamer(id, key, e.target.checked);
  }

  renderStreamersList = () => {
    const { openDeleteConfirmationModal, streamers } = this.props;

    const columns = [
      { name: 'CHANNEL NAME' },
      { name: 'MONETIZATION' },
      { name: 'ARENA SUPPORT' },
      { name: 'ADS' },
      { name: 'BOT SUPPORT' },
      { name: 'DELETE', isVisibleOnMobile: false }
    ];

    const data = streamers.map((item, index) => {
      return [
        item.originalName,
        <Toggle
          key={`monetization-${index}`}
          checked={item.monetization}
          icons={false}
          onChange={(e) => this.toggleView(e, 'monetization', item.id)}
        />,
        <Toggle
          key={`arena-${index}`}
          checked={item.arenaSupport}
          icons={false}
          onChange={(e) => this.toggleView(e, 'arenaSupport', item.id)}
        />,
        <Toggle
          key={`ads-${index}`}
          checked={item.ads}
          icons={false}
          onChange={(e) => this.toggleView(e, 'ads', item.id)}
        />,
        <Toggle
          key={`bot-${index}`}
          checked={item.botSupport}
          icons={false}
          onChange={(e) => this.toggleView(e, 'botSupport', item.id)}
        />,
        <div
          key={index + '-delete'}
          className="text-center"
          onClick={(e) => openDeleteConfirmationModal(e, item.id)}
        >
          <i className="icon-delete" />
        </div>
      ];
    });

    const dropdownData = (dataRow, rowIndex) => [
      <span key={rowIndex + '-remove'} className="tournament-link tournament-link-mobile" onClick={(e) => this.toggleModal(e)}>Delete</span>,
    ];

    return (
      <DataTableWithMobileDropdown
        columns={columns}
        data={data}
        dropdownData={dropdownData}
        scrollable={false}
      />
    );
  }
  render() {
    return (
      <div className="tournament-list mt-3">
        <div className="tournament-list-table-wrapper streamers-list">
          {this.renderStreamersList()}
        </div>
      </div>
    );
  }
}

StreamersList.propTypes = {
  openDeleteConfirmationModal: PropTypes.func.isRequired,
  streamers: PropTypes.array,
  updateStreamer: PropTypes.func.isRequired
};

export default connect(
  null,
  ({
    updateStreamer
  })
)(StreamersList);
