import * as React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { cardsToText } from 'cbl-javascript-libraries/deck-to-text';
import { LINE_SEPARATOR, fixLineSeparatorForBrowser } from 'cbl-javascript-libraries/clipboard-utils';
import copy from '../../../assets/images/svg/copy.svg';

class DeckToClipboard extends React.Component {
  getClipboardData(){
    const {data, format, isArena, onlySideboard} = this.props;

    if(isArena) {
      return fixLineSeparatorForBrowser(data);
    }

    if(format.details.length !== data.length) {
      return;
    }

    if(onlySideboard) {
      const sectionIndex = format.details.findIndex(e => e.variable === 'sideboard');
      return cardsToText(data[sectionIndex]);
    }

    return data.reduce((prev, current, index) => {
      if(current.length > 0) {
        return prev + format.details[index].variable + LINE_SEPARATOR + cardsToText(current) + LINE_SEPARATOR;
      }

      return prev + '';
    }, '');
  }

  copySuccess= () => {
    const {intl, inModal} = this.props;
    this.props.onCopyToClipboard({
      text: intl.messages['shared.deck.oncopy.label'],
      type: 'success',
      timeout: 4,
      target: inModal ? 'modal' : 'main'
    });
  };

  getLabel = () => {
    const {isArena, onlySideboard} = this.props;
    if (isArena) {
      return 'deck.to.clipboard.arena';
    }
    else {
      return onlySideboard ? 'deck.to.clipboard.sideboard' : 'deck.to.clipboard';
    }
  }

  render() {
    const {data} = this.props;
    if(!data) {
      return null;
    }

    return (
      <div>
        <CopyToClipboard className="custom-link" text={this.getClipboardData()} onCopy={this.copySuccess}>
          <span>
            <img src={copy} className="icon" />
            <span className="link">
              <FormattedMessage id={this.getLabel()} />
            </span>
          </span>
        </CopyToClipboard>
      </div>
    );
  }
}

DeckToClipboard.propTypes = {
  data: PropTypes.oneOfType(PropTypes.array, PropTypes.string),
  format: PropTypes.object,
  onCopyToClipboard: PropTypes.func,
  intl: PropTypes.object,
  inModal: PropTypes.bool,
  isArena: PropTypes.bool,
  onlySideboard: PropTypes.bool,
};

export default injectIntl(DeckToClipboard);
