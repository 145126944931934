import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import './AccessDenied.scss';

class AccessDenied extends React.Component {
  render() {
    return (
      <div className="access-denied">
        <div className="access-denied__wrapper">
          <i className="fa fa-user-times access-denied__icon" />
          <p className="access-denied__title"><strong><FormattedMessage id="access.denied.title"/></strong></p>
          <p className="access-denied__text"><FormattedMessage id="access.denied.text"/></p>
          <Link className="btn access-denied__btn" to="/"><FormattedMessage id="access.denied.goback"/></Link>
        </div>
      </div>
    );
  }
}

export default AccessDenied;
