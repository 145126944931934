import * as React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import classnames from 'classnames';

import GenericTooltip from '../../GenericTooltip';
import CardName from '../CardName/CardName';

import DeckService from '../../../services/Deck';

import './DeckList.scss';

class DeckList extends React.Component {
  deleteTimers = {};

  static defaultProps = {
    groupByTypes: true,
    onChangeData: () => {},
    activeIndex: 0,
    onSelect: () => {},
    onDeselect: () => {},
  };

  onDelete = (card) => {
    const {onChangeData, data, activeIndex} = this.props;

    const newData = data.slice();
    newData[activeIndex] = newData[activeIndex]
      .filter((cardData) => cardData.card.cardId !== card.cardId);

    onChangeData(
      {type: 'DELETE'},
      card,
      newData
    );
  }

  onChangeAmount = (cData, amount) => {
    const {onChangeData, data, activeIndex} = this.props;

    amount = Number(amount);

    if(!Number.isFinite(amount) || amount < 0){
      // Block update amount less then 0 or NaN
      return;
    }

    if(amount === 0){
      // Make some delay to give time for potential correction,
      // if no any changes in this time - delete (but send as 'CHANGE_AMOUNT')
      this.deleteTimers[cData.card.cardId] = setTimeout(() => {
        const newData = data.slice();
        newData[activeIndex] = newData[activeIndex]
          .filter((cardData) => cardData.card.cardId !== cData.card.cardId);

        onChangeData(
          {type: 'CHANGE_AMOUNT', data: amount},
          cData,
          newData
        );
      }, 2000);
    }
    else if(this.deleteTimers[cData.card.cardId]){
      // User make correction, cancel deleting
      clearTimeout(this.deleteTimers[cData.card.cardId]);
      delete this.deleteTimers[cData.card.cardId];
    }

    const newData = data.slice();
    newData[activeIndex] = data[activeIndex]
      .map((cardData) => cardData.card.cardId !== cData.card.cardId ? cardData : {...cardData, amount});

    onChangeData(
      {type: amount > 0 ? 'CHANGE_AMOUNT' : 'CHANGE_AMOUNT_PREPARE', data: amount},
      cData,
      newData
    );
  }

  onDecreaseAmount = (cardData) => {
    this.onChangeAmount(cardData, cardData.amount - 1);
  }

  onIncreaseAmount = (cardData) => {
    this.onChangeAmount(cardData, cardData.amount + 1);
  }

  renderGroupByTypes(data) {
    return data.map((group, id) => {
      return (
        <React.Fragment key={id}>
          <li className={'category'}>{group.type}</li>
          {this.renderBasic(group.cards)}
        </React.Fragment>
      );
    });
  }

  renderCardError(cardData) {
    const { validation } = this.props;

    const prepareErrors = (data, typeName) => {
      return data
        .filter(item => item.group === cardData.card.cardGroup.id)
        .map(item => ({ type: typeName, data: item }));
    };

    const errors = [
      ...prepareErrors(validation.cardAmount, 'cardAmount'),
      ...prepareErrors(validation.cardFormat, 'cardFormat'),
    ];

    if(errors.length === 0) {
      return null;
    }

    const messages = errors.map(error => {
      switch(error.type) {
        case 'cardFormat':
          return `This card is not valid in choosen format`;
        case 'cardAmount':
          return `To many, max amount is: ${error.data.maxAmount}`;
      }
    });

    const messagesNode = (
      <ul>
        {messages.map((message, i) => <li key={i}>{message}</li>)}
      </ul>
    );

    return <GenericTooltip id={cardData.cardId}
                           placement={'right'}
                           message={messagesNode}/>;
  }

  renderBasic(data) {
    if(!data) {
      return;
    }

    const { onSelect, onDeselect, disabled } = this.props;

    return data.map((cardData) => {
      return (
        <li className={'item'}
            key={cardData.cardId}
            onMouseOver={() => onSelect(cardData)}
            onMouseLeave={() => onDeselect(cardData)}>
          <span className={'name'}>
            <CardName name={cardData.card.name} names={cardData.card.names}/>

            {this.renderCardError(cardData)}
          </span>
          <div className={'amount-control mr-3'}>
            <span className={'decrease-btn'}
                  onClick={() => this.onDecreaseAmount(cardData)}><i className={'icon-minus'}/></span>
            <div className="d-none d-md-block">
              <input className={'input-amount'}
                     type="number"
                     disabled={disabled}
                     value={cardData.amount}
                     onChange={(e) => this.onChangeAmount(cardData , e.target.value)}
                     min={0} />
            </div>
            <span className={'amount d-block d-md-none'}>{cardData.amount}</span>
            <span className={'increase-btn'}
                  onClick={() => this.onIncreaseAmount(cardData)}><i className={'icon-plus'}/></span>
          </div>
          <span className={'delete-btn'}
                onClick={() => this.onDelete(cardData)}><i className={'icon-delete'}/></span>
        </li>
      );
    });
  }

  render() {
    const {data, activeIndex, groupByTypes, disabled, onDeleteAllCards} = this.props;
    const activeData = data[activeIndex];

    return (
      <div className="deck-list__wrapper mb-3">
        <Scrollbars style={{ height: 250 }}>
          <ul className={classnames('deck-list', {
            'deck-list--disabled': disabled,
          })}>
            {activeData && (groupByTypes
              ? this.renderGroupByTypes(DeckService.groupByTypes(activeData))
              : this.renderBasic(activeData))}
          </ul>
        </Scrollbars>
        <div onClick={onDeleteAllCards} className="delete-all-cards"><i className="icon-delete"/>Clear decklist</div>
      </div>
    );
  }
}

DeckList.propTypes = {
  imageShow: PropTypes.object,
  data: PropTypes.array,
  onChangeData: PropTypes.func,
  onDeleteAllCards: PropTypes.func,
  groupByTypes: PropTypes.bool,
  activeIndex: PropTypes.number,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  disabled: PropTypes.bool,
  validation: PropTypes.object,
};

export default DeckList;
