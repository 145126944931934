import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';

import './ImageCropper.scss';

// @see: https://github.com/DominicTobias/react-image-crop#what-about-showing-the-crop-on-the-client
function getCroppedImg(image, pixelCrop, fileName) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    pixelCrop.x * scaleX,
    pixelCrop.y * scaleY,
    pixelCrop.width * scaleX,
    pixelCrop.height * scaleY,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob(file => {
      file.name = fileName;
      resolve(file);
    }, 'image/jpeg');
  });
}

function getImage(base64Url) {
  return new Promise ((resolve) => {
    const image = new Image();

    image.onload = () => {
      resolve({
        image,
        size: {
          w: image.width,
          h: image.height,
        }
      });
    };

    image.src = base64Url;
  });
}

function rotateRight90(base64Url) {
  return getImage(base64Url)
    .then(data => {
      const {image, size} = data;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const width = size.h;
      const height = size.w;

      canvas.width  = width;
      canvas.height = height;

      ctx.rotate(90 * Math.PI / 180);
      ctx.translate(0, -width);
      ctx.drawImage(image, 0, 0);

      return canvas.toDataURL('image/jpeg');
    });
}

class ImageCropper extends React.Component {
  static defaultProps = {
    cancelButton: {
      text: 'Cancel',
      className: 'btn-danger',
    },
    submitButton: {
      text: 'Save',
      className: 'btn-success',
    },
    rotateButton: {
      text: 'Rotate',
      className: 'btn-warning',
    },
    initialCrop: {
      x: 0,
      y: 0,
      width: 100,
      aspect: 1,
      unit: '%'
    },
    bestFit: false,
    keepSelection: true,
    fileName: 'image',
    render: (main, buttons) => {
      return (
        <React.Fragment>
          {main}
          {buttons}
        </React.Fragment>
      );
    }
  };

  state = {
    crop: this.props.initialCrop,
    src: this.props.src,
  };

  image = {
    ref: null,
    pixelCrop: null
  };

  getCropToBestFit(imageSize) {
    let data = {};

    if(imageSize.w > imageSize.h) {
      const sizePercent = imageSize.h / imageSize.w * 100;

      data = {
        width: undefined,
        height: 100,
        x: (100 - sizePercent) / 2,
        unit: '%',
      };
    }
    else {
      const sizePercent = imageSize.w / imageSize.h * 100;

      data = {
        height: undefined,
        width: 100,
        y: (100 - sizePercent) / 2,
        unit: '%',
      };
    }

    return data;
  }

  onChangeCrop = (crop) => {
    this.setState({ crop });
  };

  onImageLoaded = (ref, pixelCrop) => {
    const {bestFit} = this.props;

    this.image = {
      ref,
      pixelCrop
    };

    if(!bestFit) {
      return;
    }

    const imageRect = ref.getBoundingClientRect();
    const imageSize = {
      w: imageRect.width,
      h: imageRect.height,
    };

    setTimeout(() => {
      this.setState((state) => {
        return {
          crop: {
            ...state.crop,
            ...this.getCropToBestFit(imageSize),
          },
        };
      });
    }, 0);
  };

  onCompleteCrop = (pixelCrop) => {
    this.image = {
      ...this.image,
      pixelCrop,
    };
  };

  onSubmit = () => {
    const {onSubmit, fileName} = this.props;

    getCroppedImg(this.image.ref, this.image.pixelCrop, `${fileName}.jpg`)
      .then(file => {
        const url = (window.URL || window.webkitURL).createObjectURL(file);

        onSubmit({
          file,
          url,
        });
      });
  };

  onRotate = () => {
    const {src} = this.state;

    rotateRight90(src)
      .then((rotateSrc) => {
        this.setState({
          src: rotateSrc,
        });
      });
  };

  render() {
    const {className, cancelButton, submitButton, rotateButton, onCancel, render} = this.props;
    const {crop, src} = this.state;

    const main = (
      <div className="main">
        <ReactCrop src={src}
                   keepSelection={true}
                   crop={crop}
                   onChange={this.onChangeCrop}
                   onImageLoaded={this.onImageLoaded}
                   onComplete={this.onCompleteCrop}/>
      </div>
    );
    const buttons = (
      <React.Fragment>
        <div className={classnames('btn mr-auto', rotateButton.className)} onClick={this.onRotate}>{rotateButton.text}</div>
        <div className={classnames('btn', cancelButton.className)} onClick={onCancel}>{cancelButton.text}</div>
        <div className={classnames('btn', submitButton.className)} onClick={this.onSubmit}>{submitButton.text}</div>
      </React.Fragment>
    );

    return (
      <div className={classnames('image-cropper', className)}>
        {render(main, buttons)}
      </div>
    );
  }
}

ImageCropper.propTypes = {
  src: ReactCrop.propTypes.src,
  initialCrop: ReactCrop.propTypes.crop,
  bestFit: PropTypes.bool,
  className: PropTypes.string,
  fileName: PropTypes.string,
  cancelButton: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
  }),
  submitButton: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
  }),
  rotateButton: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  render: PropTypes.func,
};

export default ImageCropper;
