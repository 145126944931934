import {TOURNAMENT_TEAMS_STANDINGS} from '../../actions/types';

const initialState = {
  isPending: null,
  errors: null,
  successes: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_TEAMS_STANDINGS.UPDATE.START:
      return {
        isPending: true,
        errors: null,
        successes: null,
      };
    case TOURNAMENT_TEAMS_STANDINGS.UPDATE.ERROR:
      return {
        ...state,
        isPending: false,
        errors: action.payload,
      };
    case TOURNAMENT_TEAMS_STANDINGS.UPDATE.SUCCESS:
      return {
        ...state,
        isPending: false,
        successes: action.payload,
      };
    case TOURNAMENT_TEAMS_STANDINGS.GET.START:
      return {
        ...state,
        errors: null,
        successes: null,
      };
    default:
      return state;
  }
};
