import tournament from './tournamentLogic';
import political from './politicalLogic';
import cards from './cardsLogic';
import search from './cardSearchLogic';
import reset from './resetPasswordLogic';
import formats from './formatsLogic';
import decks from './decksLogic';
import change from './changePasswordLogic';
import users from './usersLogic';
import user from './userLogic';
import tournamentMembers from './tournamentMembers';
import auth from './authLogic';
import notification from './notificationLogic';
import stores from './storesLogic';
import publicDeck from './publicDeckLogic';
import adminStatistics from './adminStatisticsLogic';
import organizerStatistics from './organizerStatisticsLogic';
import tournamentUser from './tournamentUserLogic';
import standings from './standingsLogic';
import players from './playersLogic';
import userPersonalToken from './userPersonalToken';
import unregisterdPlayer from './unregisterdPlayer';
import biography from './biographyLogic';
import submitDeckForTournament from './submitDeckForTournament';
import editTournamentPlayer from './editTournamentPlayer';
import selectTournamentPlayerBio from './selectTournamentPlayerBio';
import metaData from './metaDataLogic';
import tournamentStandings from './tournamentStandings';
import tournamentTypes from './tournamentTypes';
import tournamentTeams from './tournamentTeams';
import tournamentTeamStandings from './tournamentTeamsStandings';
import tournamentTeamDeckFormats from './tournamentTeamDeckFormats';
import modal from './modal';
import joinTeamTournament from './joinTeamTournament';
import leaveTeamTournament from './leaveTeamTournament';
import tournamentTeam from './tournamentTeam';
import cardPreview from './cardPreviewLogic';
import streamers from './streamersLogic';
import linkedAccounts from './linkedAccountLogic';
import channelLive from './channelLiveLogic';

export default [
  auth.login,
  auth.getMe,
  auth.logout,
  auth.changeRole,

  cards.validCardAmount,
  cards.validDeck,
  cards.validAll,
  cards.validation,

  cards.get,
  cards.updateDeckCardBasedOnFormat,

  cards.addCard,
  cards.addCardSuccess,
  cards.addCustomCard,
  cards.addCustomCardSuccess,

  cards.deleteCard,
  cards.deleteCardSuccess,
  cards.deleteAllCards,

  cards.changeQtyCardDebounce,
  cards.changeQtyCard,
  cards.changeQtyCardUpdate,
  cards.changeQtyCardSuccess,

  reset.post,
  decks.get,
  decks.getUserDecks,
  change.put,
  decks.patch,
  decks.fetchDeck,
  decks.save,
  decks.create,
  decks.createDraft,
  decks.deleteDeck,
  decks.saveTextDeck,
  decks.saveTextDeckAllSuggestions,
  decks.importDeck,
  decks.importDeckAllSuggestions,
  decks.getDecknames,
  decks.createDeckname,
  decks.getDecknameDetails,
  decks.updateDeckname,
  decks.setDecknameFormat,
  decks.deleteDeckname,
  decks.getArenaFormat,
  search.get,
  formats.get,
  tournament.getTournaments,
  tournament.getAllTournaments,
  tournament.getTournamentsPlayer,
  tournament.getTournamentsJudge,
  tournament.getTournament,
  tournament.getTournamentByPasscode,
  tournament.getTournamentRolesJudge,
  tournament.getTournamentRolesOrganizer,
  tournament.saveTournament,
  tournament.createTournament,
  tournament.deleteTournament,
  tournament.saveTournamentClear,
  tournament.saveTournamentAndUpdate,
  tournament.joinTournament,
  tournament.joinIndividualTournament,
  tournament.joinTeamTournament,
  tournament.joinTournamentSimple,
  tournament.addAssociation,
  metaData.get,
  metaData.updateTournamentOverwrite,
  metaData.postNew,
  metaData.postNewUpdate,
  metaData.postNewError,
  metaData.patchData,
  metaData.patchDataUpdate,
  metaData.deleteData,
  metaData.deleteDataUpdate,
  metaData.getInitMetaData,
  political.getCountries,
  political.getStates,
  users.getUsers,
  user.getSingleUserLogic,
  user.deleteUser,
  user.updateUser,
  tournamentMembers.getTournamentPlayers,
  tournamentMembers.getTournamentExtras,
  tournamentMembers.updateTournamentExtras,
  tournamentMembers.getTournamentJudges,
  tournamentMembers.getTournamentSearchPlayers,
  tournamentMembers.getTournamentOrganizers,
  tournamentMembers.getTournamentCasters,
  tournamentMembers.addTournamentCaster,
  tournamentMembers.deleteTournamentOrganizer,
  tournamentMembers.deleteTournamentCaster,
  tournamentMembers.addTournamentJudges,
  tournamentMembers.deleteTournamentJudge,
  tournamentMembers.deleteTournamentPlayer,
  tournamentMembers.addTournamentOrganizer,
  tournamentMembers.tournamentPlayerUpdate,
  notification.add,
  stores.createStore,
  stores.getAllStores,
  stores.getStores,
  stores.getStoreDetails,
  stores.editStore,

  adminStatistics.fetch,
  adminStatistics.fetchAll,

  organizerStatistics.fetch,
  organizerStatistics.fetchAll,

  publicDeck.getPublicDecks,
  publicDeck.getPublicCards,
  publicDeck.getShortenerUrl,
  publicDeck.getPublicArenaFormat,
  publicDeck.getRecentDecks,
  publicDeck.getStreamerDecks,
  publicDeck.editStreamerDeck,
  publicDeck.deleteStreamerDeck,
  publicDeck.loadMoreStreamerDecks,
  publicDeck.getStreamersInfo,

  tournamentUser.fetch,
  tournamentUser.create,
  tournamentUser.saveDeckType,
  tournamentUser.createDeck,
  tournamentUser.updateShadowUser,
  standings.importStandings,
  players.importPlayers,
  biography.getBiography,
  biography.updateBiography,
  biography.getTournamentUserBiography,
  biography.updateTournamentUserBiography,
  biography.getBioSchemas,
  cardPreview.getCard,
  channelLive.getChannelLive,
  ...streamers,
  ...unregisterdPlayer,
  ...submitDeckForTournament,
  ...userPersonalToken,
  ...editTournamentPlayer,
  ...selectTournamentPlayerBio,
  ...tournamentStandings,
  ...tournamentTypes,
  ...tournamentTeams,
  ...tournamentTeamStandings,
  ...tournamentTeamDeckFormats,
  ...modal,
  ...joinTeamTournament,
  ...leaveTeamTournament,
  ...tournamentTeam,
  ...linkedAccounts
];
