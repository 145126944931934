import React from 'react';

import JoinTeamTournamentModal from './JoinTeamTournamentModal';
import LeaveTeamTournamentModal from './LeaveTeamTournamentModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import AddNewStreamerModal from './AddNewStreamerModal';
import StandingsImportModal from './StandingsImportModal';
import HowToPlayers from './HowToPlayers';
import HowToStandings from './HowToStandings';

class AllModals extends React.Component {
  render() {
    return (
      <React.Fragment>
        <JoinTeamTournamentModal/>
        <LeaveTeamTournamentModal/>
        <DeleteConfirmationModal/>
        <AddNewStreamerModal/>
        <StandingsImportModal/>
        <HowToPlayers />
        <HowToStandings/>
      </React.Fragment>
    );
  }
}

export default AllModals;
