import {connect} from 'react-redux';

import Notifier from './Notifier';

export default connect(
  (state) => ({
    messages: state.notification.messages.filter(m => m.target === 'modal'),
    className: 'notifier-modal',
  }),
  null,
)(Notifier);
