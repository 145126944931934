import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class ChangeableDropdown extends React.Component {
  static defaultProps = {
    renderCurrentValue: false,
    color: 'primary',
  };

  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState(state => ({
      isOpen: !state.isOpen
    }));
  };

  renderOptions() {
    const {renderCurrentValue, options, value, onChange} = this.props;

    const renderedOptions = renderCurrentValue
      ? options
      : options.filter(item => item.value !== value);

    return (
      renderedOptions.map(item => {
        return (
          <DropdownItem key={item.value}
                        disabled={item.value === value}
                        onClick={() => onChange(item.value, item)}>
            {item.name}
          </DropdownItem>
        );
      })
    );
  }

  render() {
    const {options, value, className, btnClassName, color, disabled} = this.props;
    const {isOpen} = this.state;
    const valueData = options.find(item => item.value === value);
    const btnColor = color ? `btn-${color}` : null;
    return (
      <Dropdown isOpen={isOpen} className={classnames(className, 'changeable-dropdown')} toggle={this.toggle} group>
        <DropdownToggle disabled={disabled} className={btnClassName} color={color}>
          {valueData.name}
        </DropdownToggle>
        {!disabled && <button className={classnames('btn dropdown-toggle', className, btnColor)} onClick={this.toggle}/>}
        <DropdownMenu>
          {this.renderOptions()}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

ChangeableDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
    }),
    PropTypes.object,
  ])).isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  btnClassName: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  renderCurrentValue: PropTypes.bool,
};

export default ChangeableDropdown;
