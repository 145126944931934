import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import uuid from 'uuid/v1';
import classnames from 'classnames';

import MetaDataContent from './MetaDataContent';
import Spinner from '../../../Spinner';

import {
  addNewData,
  getMetaData,
  deleteData,
  saveChange,
  resetMetaData,
  resetEditionId,
  updateTournamentOverWrite,
  editionIdChange,
  getInitMetaData,
} from '../../../../actions/metaDataActions';
import {tournamentTeamDeckFormatReset} from '../../../../actions/tournamentTeamDeckFormats';

import {getTournamentTeamDeckFormatsToMetaGame} from '../../../../selectors/tournamentTeamDeckFormats';

import './MetaData.scss';

class MetaData extends React.Component {
  state = {
    metaDataShow: null,
    metaDataType: null,
    isConfirmOpen: null,
    isSwitchDataOpen: null,
    deleteId: null,
    isNewRow: false, 
    selectedFormat: null,
  };

  componentDidMount() {
    const { tournamentId, isEditable, tournamentType, tournamentFormat, getInitMetaData, getMetaData, deckFormats} = this.props;

    if(tournamentType === 'Team') {
      getInitMetaData(tournamentId, tournamentFormat);
    } else {
      getMetaData(tournamentId);
    }

    this.setState({
      metaDataShow: isEditable ? 'overwrite' : 'default',
      selectedFormat: deckFormats.length === 0 ? null : deckFormats[0],
    });
  }

  componentDidUpdate(prevProps) {
    const {getMetaData, tournamentId, deckFormats, tournamentStatus} = this.props;

    if (prevProps.tournamentStatus !== tournamentStatus) {
      getMetaData(tournamentId, deckFormats[0]);
    }

    if(prevProps.deckFormats.length !== deckFormats.length) {
      this.setState({
        selectedFormat: deckFormats[0],
      });
    }
  }

  componentWillUnmount() {
    this.props.tournamentTeamDeckFormatReset();
  }

  getData(data, isEditable) {
    if (!data) {
      return null;
    }

    if (isEditable) {
      return data;
    }

    return data.map(elem => {
      return {
        id: uuid(),
        name: elem.name,
        amount: elem.amount,
      };
    });
  }

  onIsNewRowChange = (value) => {
    this.setState({
      isNewRow: value,
    });
  }

  onAddNewButtonClick = () => {
    window.scrollTo(0, document.body.scrollHeight);
    this.onIsNewRowChange(true);
  };

  onCreateNewRow = (name, amount) => {
    const { tournamentId } = this.props;
    const { selectedFormat } = this.state;
    return this.props.addNewData(tournamentId, name, amount, selectedFormat);
  }

  onSaveData = (name, amount) => {
    const { tournamentId, editionId } = this.props;
    this.props.saveChange(tournamentId, editionId, name, amount);

    return this.props.resetEditionId();
  };

  onDeleteData = (deckId) => {
    return this.toggleConfirmModal(deckId);
  };

  onDeleteSubmit = () => {
    const { tournamentId } = this.props;
    const { deleteId } = this.state;

    this.props.deleteData(tournamentId, deleteId);
    return this.toggleConfirmModal();
  };

  onSwitchSubmit = () => {
    const { tournamentId, updateTournamentOverWrite, resetMetaData, isEditable, deckFormats } = this.props;

    this.setState({
      metaDataShow: !isEditable ? 'overwrite' : 'default',
    });
    resetMetaData();
    updateTournamentOverWrite(tournamentId, !isEditable, deckFormats[0]);

    return this.toggleSwitchModal();
  };

  onRadioButtonChange = () => {
    return this.toggleSwitchModal();
  };

  onDeckFormatChange = (selectedFormat) => {
    const {getMetaData, tournamentId} = this.props;

    getMetaData(tournamentId, selectedFormat);

    return this.setState({
      selectedFormat,
    });
  };

  toggleConfirmModal(deleteId) {
    this.setState(prevState => ({
      isConfirmOpen: !prevState.isConfirmOpen,
      deleteId,
    }));
  }

  toggleSwitchModal = () => {
    this.setState(prevState => ({
      isSwitchDataOpen: !prevState.isSwitchDataOpen,
    }));
  };

  renderAddNewButton(isEditable) {
    if (!isEditable) {
      return null;
    }

    return <div className="col-lg-2">
      <button
        className="btn btn-primary"
        onClick={this.onAddNewButtonClick}
      >
        <FormattedMessage id="metaData.button.new" />
      </button>
    </div>;
  }

  renderDeckFormatSwitch() {
    const {deckFormats} = this.props;
    const {selectedFormat} = this.state;
    if(deckFormats.length <= 1) {
      return null;
    }

    return (
      <div className="col-md-12 d-flex p-0
      ">
        {
          deckFormats.map(elem => {
            return (
              <div
                className={classnames('choose-format', {
                  'choose-format--active': elem === selectedFormat
                })}
                onClick={() => this.onDeckFormatChange(elem)}
                key={elem}
              >
                {elem}
              </div>
            );
          })
        }
      </div>
    );
  }

  renderContent(data, isEditable) {
    if (data.isLoad) {
      return <Spinner />;
    }

    return (
      <MetaDataContent
        isEditable={isEditable}
        editionId={this.props.editionId}
        data={this.getData(data.data, isEditable)}
        onDeleteData={this.onDeleteData}
        onEditData={this.props.editionIdChange}
        onSaveData={this.onSaveData}
        isNewRow={this.state.isNewRow}
        onIsNewRowChange={this.onIsNewRowChange}
        onCreateNewRow={this.onCreateNewRow}
      />
    );
  }

  render() {
    const { data, isEditable, tournamentStatus } = this.props;
    const { metaDataShow, isConfirmOpen, isSwitchDataOpen } = this.state;

    if (tournamentStatus === 'not_started') {
      return <p className="text-center">
        <FormattedMessage id="metaData.status.text" />
      </p>;
    }

    return <React.Fragment>
      <div className="d-flex py-3">
        <form className="col-lg-10">
          <label className="metadata-label">
            <input
              className="radio-btn"
              type="radio"
              name="metadata"
              value="default"
              checked={metaDataShow === 'default'}
              onChange={this.onRadioButtonChange}
            />
            <FormattedMessage id="metaData.radio.default" />
          </label>
          <label className="metadata-label">
            <input
              className="radio-btn"
              type="radio"
              name="metadata"
              value="overwrite"
              checked={metaDataShow === 'overwrite'}
              onChange={this.onRadioButtonChange}
            />
            <FormattedMessage id="metaData.radio.override" />
          </label>
        </form>
        {this.renderAddNewButton(isEditable)}
      </div>
      <div className="meta-data">
        {this.renderDeckFormatSwitch()}
        {this.renderContent(data, isEditable)}
      </div>
      <Modal isOpen={isConfirmOpen} toggle={()=>this.toggleConfirmModal(null)}>
        <ModalHeader toggle={()=>this.toggleConfirmModal(null)}>
          <FormattedMessage id="metaData.modal.title" />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage id="metaData.modal.text" />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-danger" onClick={this.onDeleteSubmit}>
            <FormattedMessage id="tournament.delete.modal.submit" />
          </button>
          <button className="btn btn-outline-secondary" onClick={()=>this.toggleConfirmModal(null)}>
            <FormattedMessage id="tournament.delete.modal.cancel" />
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isSwitchDataOpen} toggle={this.toggleSwitchModal}>
        <ModalHeader toggle={this.toggleSwitchModal} />
        <ModalBody>
          <FormattedMessage id={isEditable ? 'metaData.modal.switch.override.text' : 'metaData.modal.switch.default.text'} />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={this.toggleSwitchModal}>
            <FormattedMessage id="metaData.modal.switch.button.cancel" />
          </button>
          <button className="btn btn-success" onClick={this.onSwitchSubmit}>
            <FormattedMessage id="metaData.modal.switch.button.confirm" />
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>;
  }
}

MetaData.propTypes = {
  data: propTypes.shape({
    isLoad: propTypes.bool,
    isError: propTypes.bool,
    error: propTypes.object,
    data: propTypes.arrayOf(propTypes.shape({
      id: propTypes.string,
      name: propTypes.string,
      amount: propTypes.number,
    })),
  }),
  deckFormats: propTypes.array,
  editionId: propTypes.string,
  isEditable: propTypes.bool.isRequired,
  tournamentType: propTypes.string.isRequired,
  tournamentFormat: propTypes.string.isRequired,
  tournamentId: propTypes.string.isRequired,
  tournamentStatus: propTypes.string.isRequired,
  addNewData: propTypes.func.isRequired,
  getMetaData: propTypes.func.isRequired,
  getInitMetaData: propTypes.func,
  tournamentTeamDeckFormatReset: propTypes.func.isRequired,
  deleteData: propTypes.func.isRequired,
  saveChange: propTypes.func.isRequired,
  resetMetaData: propTypes.func.isRequired,
  resetEditionId: propTypes.func.isRequired,
  updateTournamentOverWrite: propTypes.func.isRequired,
  editionIdChange: propTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => ({
    deckFormats: getTournamentTeamDeckFormatsToMetaGame(state.tournamentTeamDeckFormats, ownProps.tournamentFormat),
    data: state.metaData,
    editionId: state.metaData.editionId,
    editName: state.metaData.editName,
    editValue: state.metaData.editValue,
  }),
  ({
    addNewData,
    getMetaData,
    deleteData,
    saveChange,
    resetMetaData,
    resetEditionId,
    updateTournamentOverWrite,
    editionIdChange,
    getInitMetaData,
    tournamentTeamDeckFormatReset,
  })
)(MetaData);
