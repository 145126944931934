import {
  DECKS,
  USER_DECKS,
  DECKS_PATCH,
  FETCH_DECK,
  SAVE_DECK,
  CREATE_DECK,
  CREATE_DECK_DRAFT,
  DELETE_DECK,
  SAVE_TEXT_DECK,
  SAVE_TEXT_DECK_SUGGESTIONS,
  IMPORT_DECK,
  IMPORT_DECK_SUGGESTIONS,
  DECKNAMES,
  DECKNAME_DETAILS,
  DECKNAME_CREATE,
  DECKNAME_UPDATE,
  DECKNAME_DELETE,
  DECKNAME_FORMAT,
  SET_DECKNAME_FORMAT,
  ARENA_FORMAT,
} from './types';

export const fetch = () => ({
  type: DECKS.FETCH,
});

export const fetchUserDecks = userId => ({
  type: USER_DECKS.FETCH,
  payload: userId
});

export const patch = data => ({
  type: DECKS_PATCH.PATCH,
  payload: data
});

export const fetchDeck = id => ({
  type: FETCH_DECK.FETCH,
  payload: id
});

export const save = id => ({
  type: SAVE_DECK.START,
  payload: id
});

export const create = data => ({
  type: CREATE_DECK.START,
  payload: data
});

export const createDraft = (id, successRedirectUrl = null) => ({
  type: CREATE_DECK_DRAFT.START,
  payload: {
    id,
    successRedirectUrl
  }
});

export const deleteDeck = (deckId, nextActions = []) => ({
  type: DELETE_DECK.START,
  payload: {
    deckId,
    nextActions,
  },
});

export const saveTextDeck = (deckId, content, inModal) => ({
  type: SAVE_TEXT_DECK.START,
  deckId: deckId,
  content: content,
  inModal: inModal
});

export const saveTextDeckAllSuggestions = (dataArr, deckId, format, inModal) => ({
  type: SAVE_TEXT_DECK_SUGGESTIONS.START,
  dataArr: dataArr,
  deckId: deckId,
  format: format,
  inModal: inModal
});

export const removeCardSuggestion = (suggestion) => ({
  type: SAVE_TEXT_DECK.REMOVE_CARD,
  card: suggestion
});

export const clearTextDeck = () => ({
  type: SAVE_TEXT_DECK.CLEAR
});

export const importDeck = (deckId, content, inModal) => ({
  type: IMPORT_DECK.START,
  deckId: deckId,
  content: content,
  inModal: inModal
});

export const importDeckAllSuggestions = (dataArr, deckId, format, inModal) => ({
  type: IMPORT_DECK_SUGGESTIONS.START,
  dataArr: dataArr,
  deckId: deckId,
  format: format,
  inModal: inModal
});

export const removeImportedCardSuggestion = (suggestion) => ({
  type: IMPORT_DECK.REMOVE_CARD,
  card: suggestion
});

export const clearImportedDeck = () => ({
  type: IMPORT_DECK.CLEAR
});

export const getDecknames = (formatId, page, name, tournamentId) => ({
  type: DECKNAMES.START,
  payload: {
    formatId,
    tournamentId,
    page: page || 1,
    name
  }
});

export const getDecknameDetails = (id, redirectUrl) => ({
  type: DECKNAME_DETAILS.START,
  payload: {
    id,
    redirectUrl
  }
});

export const createDeckname = (name, formatId) => ({
  type: DECKNAME_CREATE.START,
    payload: {
      name,
      formatId
    }
});

export const updateDeckname = (id, name, formatId, page) => ({
  type: DECKNAME_UPDATE.START,
  payload: {
    id,
    name,
    formatId,
    page
  }
});

export const deleteDeckname = (id, formatId) => ({
  type: DECKNAME_DELETE.START,
  payload: {
    id,
    formatId
  }
});

export const setDecknameFormat = (deckNameId, formatId) => ({
  type: DECKNAME_FORMAT.START,
  payload: {
    deckNameId,
    formatId
  }
});

export const setDeckNameFormat = (format) => ({
  type: SET_DECKNAME_FORMAT,
  payload: format
});

export const getArenaFormat = (deckId) => ({
  type: ARENA_FORMAT.GET.START,
  payload: {
    deckId,
  }
});
