import * as React from 'react';
import { createLogic } from 'redux-logic';

import {
  DECKS,
  USER_DECKS,
  DECKS_PATCH,
  FETCH_DECK,
  SAVE_DECK,
  CREATE_DECK,
  CREATE_DECK_DRAFT,
  DELETE_DECK,
  DECK_VALIDATOR,
  DECK_CARDS,
  SAVE_TEXT_DECK,
  SAVE_TEXT_DECK_SUGGESTIONS,
  IMPORT_DECK,
  IMPORT_DECK_SUGGESTIONS,
  DECKNAMES,
  DECKNAME_DETAILS,
  DECKNAME_CREATE,
  DECKNAME_UPDATE,
  DECKNAME_FORMAT,
  DECKNAME_DELETE,
  ARENA_FORMAT,
} from '../actions/types';
import { FormattedMessage } from 'react-intl';
import { add as addNotification } from '../actions/notificationActions';
import {fetch, addCardSuccess} from '../actions/cardsAction';
import {push} from 'react-router-redux';

export const get = createLogic({
  type: DECKS.FETCH,
  latest: true,

  processOptions: {
    successType: DECKS.SUCCESS,
    failType: DECKS.ERROR,
  },

  process({ httpClient }) {
    return httpClient.get('/api/v1/me/decks')
      .then(res => res.data);
  }
});

export const getUserDecks = createLogic({
  type: USER_DECKS.FETCH,
  latest: true,

  processOptions: {
    successType: USER_DECKS.SUCCESS,
    failType: USER_DECKS.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get(`/api/v1/users/${action.payload}/decks`)
      .then(res => res.data);
    }
});

export const create = createLogic({
  type: CREATE_DECK.START,
  latest: true,

  processOptions: {
    successType: CREATE_DECK.SUCCESS,
    failType: CREATE_DECK.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.post('/api/v1/decks', action.payload)
      .then(res => res.data);
  }
});

export const patch = createLogic({
  type: DECKS_PATCH.PATCH,
  latest: true,

  process({ httpClient, action, getState }, dispatch, done) {
    return httpClient.patch('/api/v1/decks/' + action.payload.id, action.payload.data)
      .then(res => {
        const data = res.data;

        dispatch({
          type: DECKS_PATCH.SUCCESS,
          payload: data,
        });

        if(action.payload.isChangeFormat) {
          const cards = getState().deckCards.data.reduce((prev, current) => {
            return [...prev, ...current];
          }, []);

          dispatch({
            type: DECK_CARDS.UPDATE_BASED_ON_FORMAT,
            payload: {
              format: data.format,
              cards: cards,
            },
          });
          dispatch({
            type: DECK_VALIDATOR.VALID_ALL,
            payload: {
              deckId: action.payload.id
            },
          });
        }
      })
      .catch(err => {
        dispatch({
          type: DECKS_PATCH.ERROR,
          payload: err,
        });
      })
      .then(done);
  }
});

export const fetchDeck = createLogic({
  type: FETCH_DECK.FETCH,
  latest: true,

  processOptions: {
    successType: FETCH_DECK.SUCCESS,
    failType: FETCH_DECK.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get('/api/v1/decks/' + action.payload)
      .then(res => res.data);
  }
});

export const save = createLogic({
  type: SAVE_DECK.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.put('/api/v1/decks/' + action.payload)
      .then(res => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="deck.save.success"/>
        }));
        dispatch({
          type: SAVE_DECK.SUCCESS,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="deck.save.error"/>
        }));
        dispatch({
          type: SAVE_DECK.ERROR,
          payload: err
        });
      })
      .then(() => done());
  }
});

export const saveTextDeck = createLogic({
  type: SAVE_TEXT_DECK.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.post(`/api/v1/decks/${action.deckId}/importer`, action.content,
    {
      headers: {
        'Content-Type': 'text/plain',
      }
    })
      .then((res) => {
        const recognizedCards = res.data.recognizedCards;

        if(recognizedCards.length > 0){
          dispatch(addNotification({
            type: 'success',
            text: <FormattedMessage id="deck.from.text.save.success"/>,
            target: action.inModal ? 'modal' : 'main'
          }));
        }
        dispatch({
          type: SAVE_TEXT_DECK.SUCCESS,
          payload: res.data
        });
        dispatch(fetch(action.deckId));
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="deck.from.text.save.error"/>,
          target: action.inModal ? 'modal' : 'main'
        }));
      })
      .then(() => done());
  }
});

export const importDeck = createLogic({
  type: IMPORT_DECK.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const formData = new FormData();
    formData.append('file', action.content);
    return httpClient.post(`/api/v1/decks/${action.deckId}/importer`, formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
      .then((res) => {
        const recognizedCards = res.data.recognizedCards;

        if(recognizedCards.length > 0){
          dispatch(addNotification({
            type: 'success',
            text: <FormattedMessage id="deck.from.text.save.success"/>,
            target: action.inModal ? 'modal' : 'main'
          }));
        }
        dispatch({
          type: IMPORT_DECK.SUCCESS,
          payload: res.data
        });
        dispatch(fetch(action.deckId));
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="deck.from.text.save.error"/>,
          target: action.inModal ? 'modal' : 'main'
        }));
      })
      .then(() => done());
  }
});

export const saveTextDeckAllSuggestions = createLogic({
  type: SAVE_TEXT_DECK_SUGGESTIONS.START,

  process({ httpClient, action }, dispatch, done) {

    const promises = action.dataArr.map((data) => {
      if(!data.suggestion) {
        return null;
      }

      return httpClient.post(`/api/v1/decks/${action.deckId}/cards`, {
        cardId: data.suggestion.cardId,
        formatSectionIndex: action.format.details.findIndex(item => item.variable ===  data.suggestion.type),
        type: data.suggestion.type,
        amount: data.suggestion.amount
      });
    });

    return Promise.all(promises)
      .then((res) => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="import.deck.add.card.success"/>,
          target: action.inModal ? 'modal' : 'main'
        }));

        action.dataArr.map((data, index) => {
          if(data.suggestion) {
            const responseData = res[index].data;
            const suggestion = data.suggestion;

            const actionData = {
              amount: suggestion.amount,
              card: suggestion.card,
              deckId: action.deckId,
              format: action.format,
              formatSectionIndex: action.format.details.findIndex(item => item.variable === suggestion.type),
              inModal: action.inModal
            };

            dispatch(addCardSuccess(responseData, actionData));
          }
        });

        // Full valid
        dispatch({
          type: DECK_VALIDATOR.VALID_ALL,
          payload: {
            deckId: action.deckId
          }
        });
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="import.deck.add.card.error"/>,
          target: action.inModal ? 'modal' : 'main'
        }));
      })
      .then(() => done());
  }
});

export const importDeckAllSuggestions = createLogic({
  type: IMPORT_DECK_SUGGESTIONS.START,

  process({ httpClient, action }, dispatch, done) {
    const promises = action.dataArr.map((data) => {
      if(!data.suggestion) {
        return null;
      }

      return httpClient.post(`/api/v1/decks/${action.deckId}/cards`, {
        cardId: data.suggestion.cardId,
        formatSectionIndex: action.format.details.findIndex(item => item.variable ===  data.suggestion.type),
        type: data.suggestion.type,
        amount: data.suggestion.amount
      });
    });

    return Promise.all(promises)
      .then((res) => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="import.deck.add.card.success"/>,
          target: action.inModal ? 'modal' : 'main'
        }));

        action.dataArr.map((data, index) => {
          if(data.suggestion) {
            const responseData = res[index].data;
            const suggestion = data.suggestion;

            const actionData = {
              amount: suggestion.amount,
              card: suggestion.card,
              deckId: action.deckId,
              format: action.format,
              formatSectionIndex: action.format.details.findIndex(item => item.variable === suggestion.type),
              inModal: action.inModal,
            };

            dispatch(addCardSuccess(responseData, actionData));
          }
        });

        // Full valid
        dispatch({
          type: DECK_VALIDATOR.VALID_ALL,
          payload: {
            deckId: action.deckId
          }
        });
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="import.deck.add.card.error"/>,
          target: action.inModal ? 'modal' : 'main'
        }));
      })
      .then(() => done());
  }
});

export const createDraft = createLogic({
  type: CREATE_DECK_DRAFT.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const payload = action.payload;
    const isRedirect = !!payload.successRedirectUrl;

    return httpClient.post(`/api/v1/decks/${payload.id}/draft`)
      .then(res => res.data)
      .then(data => {
        dispatch({
          type: CREATE_DECK_DRAFT.SUCCESS,
          payload: data,
        });

        if(isRedirect) {
          const url = payload.successRedirectUrl.replace(':id', data.id);

          dispatch(push(url));
        }
      })
      .catch(err => {
        dispatch({
          type: CREATE_DECK_DRAFT.ERROR,
          payload: err,
          error: true,
        });
      })
      .then(done);
  }
});

export const deleteDeck = createLogic({
  type: DELETE_DECK.START,

  process({ httpClient, action }, dispatch, done) {
    const payload = action.payload;

    return httpClient.delete('/api/v1/decks/' + payload.deckId)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="deck.delete.success"/>
        }));

        payload.nextActions.forEach((action) => dispatch(action));
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="deck.delete.error"/>
        }));
      })
      .then(() => done());
  }
});

export const getDecknames = createLogic({
  type: DECKNAMES.START,

  process({ httpClient, action }, dispatch, done) {
    const { formatId, page, name, tournamentId } = action.payload;

    if(!tournamentId) {
      return httpClient.get('/api/v1/formats/' + formatId + '/available_deck_names', {
        params: {
          name: name,
          page: page
        }
      })
      .then((res)=> {
        dispatch({
          type: DECKNAMES.SUCCESS,
          payload: res
        });
      })
      .catch((err)=> {
        dispatch({
          type: DECKNAMES.ERROR,
          payload: err.response
        });
      })
      .then(done);
    }

    return Promise.all([
      httpClient.get('/api/v1/tournaments/' + tournamentId + '/registered_deck_names', {
        params: {
          name: name,
          page: page
        }
      }),
      httpClient.get('/api/v1/formats/' + formatId + '/available_deck_names', {
        params: {
          name: name,
          page: page
        }
      }),
    ])
      .then(([registered, available]) => {
        const registeredDecks = registered.data;
        const availableDecks = available.data;

        dispatch({
          type: DECKNAMES.SUCCESS,
          payload: {
            data: registeredDecks.concat(availableDecks)
          }
        });
      })
      .catch(err => {
        dispatch({
          type: DECKNAMES.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  }
});

export const getDecknameDetails = createLogic({
  type: DECKNAME_DETAILS.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id, redirectUrl } = action.payload;
    const isRedirect = !!redirectUrl;

    return httpClient.get('/api/v1/deck_names/' + id)
    .then(res => res.data)
    .then(data => {
      dispatch({
        type: DECKNAME_DETAILS.SUCCESS,
        payload: data,
      });

      if(isRedirect) {
        dispatch(push(redirectUrl));
      }
    })
    .catch(err => {
      dispatch({
        type: DECKNAME_DETAILS.ERROR,
        payload: err,
        error: true,
      });
    })
    .then(done);
  }
});

export const createDeckname = createLogic({
  type: DECKNAME_CREATE.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { name, formatId } = action.payload;
    return httpClient.post('/api/v1/deck_names', { name })
    .then(res => {
      const { data } = res;
      dispatch({
        type: DECKNAME_FORMAT.START,
        payload: {
          deckNameId: data.id,
          formatId,
        },
      });
    })
    .catch(err => {
      dispatch({
        type: DECKNAME_CREATE.ERROR,
        payload: err,
      });
    })
    .then(done);
  }
});

export const updateDeckname = createLogic({
  type: DECKNAME_UPDATE.START,

  process({ httpClient, action }, dispatch, done) {
    const { id, name, formatId, page } = action.payload;
    return httpClient.patch('/api/v1/deck_names/' + id, name)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="deck.name.message.update.success"/>
        }));
        dispatch({
          type: DECKNAMES.START,
          payload: {
            formatId,
            page
          }
        });
        dispatch(push('/deck-names/' + page));
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="deck.name.message.error"/>
        }));
      })
      .then(() => done());
    }
});

export const setDecknameFormat = createLogic({
  type: DECKNAME_FORMAT.START,

  process({ httpClient, action }, dispatch, done) {
    const { formatId, deckNameId } = action.payload;
    return httpClient.put('/api/v1/formats/' + formatId + '/deck_names/' + deckNameId)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="deck.name.message.create.success"/>
        }));
        dispatch({
          type: DECKNAMES.START,
          payload: {
            formatId,
            page: 1,
          }
        });
        dispatch(push('/deck-names'));
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="deck.name.message.error"/>
        }));
      })
      .then(() => done());
    }
});

export const deleteDeckname = createLogic({
type: DECKNAME_DELETE.START,

process({ httpClient, action }, dispatch, done) {
  const { id, formatId } = action.payload;
  return httpClient.delete('/api/v1/deck_names/' + id)
    .then(() => {
      dispatch(addNotification({
        type: 'success',
        text: <FormattedMessage id="deck.name.message.delete.success"/>
      }));
      dispatch({
        type: DECKNAMES.START,
        payload: {
          formatId,
          page: 1,
        }
      });
    })
    .catch(() => {
      dispatch(addNotification({
        type: 'danger',
        text: <FormattedMessage id="deck.name.message.error"/>
      }));
    })
    .then(() => done());
}
});

export const getArenaFormat = createLogic({
  type: ARENA_FORMAT.GET.START,
  processOptions: {
    successType: ARENA_FORMAT.GET.SUCCESS,
    failType: ARENA_FORMAT.GET.ERROR,
  },

  process({ httpClient, action}) {
    return httpClient.get(`/api/v1/decks/${action.payload.deckId}/arena`)
      .then(res => res.data);
  }
});

export default {
  get,
  getUserDecks,
  patch,
  fetchDeck,
  save,
  create,
  createDraft,
  deleteDeck,
  saveTextDeck,
  saveTextDeckAllSuggestions,
  importDeck,
  importDeckAllSuggestions,
  getDecknames,
  createDeckname,
  getDecknameDetails,
  updateDeckname,
  setDecknameFormat,
  deleteDeckname,
  getArenaFormat,
};
