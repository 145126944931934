import React from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {omit} from 'lodash';

import BioModalEdit from '../Bio/BioModalEdit';
import * as editTournamentPlayerActions from '../../actions/editTournamentPlayer';

class ModalEditTournamentPlayer extends React.Component {
  state = {
    playerData: null,
    playerDataError: {
      firstName: false,
      lastName: false
    },
    showLoader: false
  };

  componentDidMount() {
    const {getInitData, data} = this.props;

    getInitData(data);
  }

  onSubmit = (bioData) => {
    const {onClose, saveData, initData, showBioLoader} = this.props;
    const {playerData} = this.state;

    const isEmptyFirstName = playerData && playerData.firstName === '';
    const isEmptyLastName = playerData && playerData.lastName === '';

    
    this.setState({
      playerDataError: {
        firstName: isEmptyFirstName,
        lastName: isEmptyLastName
      }
    });

    if(isEmptyFirstName || isEmptyLastName) {
      return;
    }

    showBioLoader();
    const questions = initData.data.bioData.questions;

    const formattedBio = Object.keys(bioData)
      .reduce((prev, current) => {
        const question  = questions.find(item => item.id === parseInt(current, 10));
        const isImage   = question.type === 'img';
        const res       = {...prev};

        if(!isImage) {
          res.data.push({
            questionId: current,
            value: bioData[current],
          });
        }
        else {
          res.avatar = bioData[current];
        }

        return res;
      }, {
        data: [],
      });

    if(formattedBio.avatar !== undefined) {
      saveData({
        playerData,
        bioData: formattedBio.data,
        bioAvatar: formattedBio.avatar,
        onClose
      });
    }
    else {
      saveData({
        playerData,
        bioData: formattedBio.data,
        onClose
      });
    }
  };

  onChangeUserData = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;

    this.setState((state) => ({
      playerData: {
        ...state.playerData,
        [fieldName]: value
      }
    }));
  };

  renderUserDataForm() {
    const {initData} = this.props;
    const {playerDataError} = this.state;

    const playerData = initData.data.playerData;

    const lastNameError = playerDataError.lastName;
    const firstNameError = playerDataError.firstName;

    return (
      <div className="row mb-5">
        <div className="col-12 col-md-4">
          <label className="text-uppercase"><FormattedMessage id="user.label.last.name"/></label>
          <input type="text"
                 autoComplete="nope" autofill="off" spellCheck="false" autoCorrect="off" autoCapitalize="off"
                 className={classnames('form-control user-disabled user-name', {
                  'is-invalid': lastNameError
                 })}
                 defaultValue={playerData.lastName}
                 name="lastName"
                 onChange={this.onChangeUserData}
          />
          {lastNameError && <span className="text-danger"><FormattedMessage id="biomodal.emptyname.error"/></span>}
        </div>
        <div className="col-12 col-md-4">
          <label className="text-uppercase"><FormattedMessage id="user.label.first.name"/></label>
          <input type="text"
                 autoComplete="nope" autofill="off" spellCheck="false" autoCorrect="off" autoCapitalize="off"
                 className={classnames('form-control user-disabled user-name', {
                  'is-invalid': firstNameError
                 })}
                 defaultValue={playerData.firstName}
                 name="firstName"
                 onChange={this.onChangeUserData}
          />
          {firstNameError && <span className="text-danger"><FormattedMessage id="biomodal.emptyname.error"/></span>}
        </div>
        <div className="col-12 col-md-4">
          <label className="text-uppercase"><FormattedMessage id="user.label.dci"/></label>
          <input type="text"
                 autoComplete="nope" autofill="off" spellCheck="false" autoCorrect="off" autoCapitalize="off"
                 className="form-control user-disabled user-dci"
                 defaultValue={playerData.dciNumber}
                 name="dciNumber"
                 onChange={this.onChangeUserData}
          />
        </div>
      </div>
    );
  }

  render() {
    const {isOpen, bioType, onClose, initData, bioLoader} = this.props;

    if(initData.isPending || !initData.data) {
      return null;
    }

    const userDataForm = this.renderUserDataForm();

    const bioData = initData.data.bioData.questions.reduce(
      (prev, current) => {
        const values = current.membersAnswers;
        const value = values.length > 0 ? values[0].value : '';

        return {
          ...prev,
          [current.id]: value
        };
      },
      {}
    );

    return (
      <BioModalEdit isOpen={isOpen}
                    showLoader={bioLoader}
                    data={bioData}
                    bioType={bioType}
                    onClose={onClose}
                    onSubmit={this.onSubmit}
                    renderBeforeBody={userDataForm}/>
    );
  }
}

const parentPropTypes = omit(
  BioModalEdit.propTypes,
  ['renderBeforeBody', 'data', 'onSubmit']
);


ModalEditTournamentPlayer.propTypes = {
  ...parentPropTypes,
  data: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    tournamentId: PropTypes.string.isRequired,
  }).isRequired,

  initData: PropTypes.object.isRequired,

  getInitData: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    initData: state.editTournamentPlayer.get,
  }),
  ({
    getInitData: editTournamentPlayerActions.get,
    saveData: editTournamentPlayerActions.save,
    clearData: editTournamentPlayerActions.clear,
  })
)(ModalEditTournamentPlayer);
