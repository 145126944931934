import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Select from 'react-select';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import {getUsers, clearUsers} from '../../actions/usersActions';

class ModalSelectUser extends React.Component {
  static defaultProps = {
    buttonText: {
      submit: 'Add',
      cancel: 'Cancel',
    },
    closeOnSubmit: true,
  };

  state = {
    selected: null,
  };

  onChange = (value) => {
    this.setState({
      selected: value,
    });
  };

  onInputChange = (value) => {
    const {getUsers, clearUsers} = this.props;

    if(!value){
      clearUsers();
      return;
    }

    getUsers(1, value);
  };

  onSubmit = () => {
    const {onSubmit, onClose, closeOnSubmit} = this.props;
    const {selected} = this.state;

    onSubmit(selected);

    if(closeOnSubmit){
      onClose();
    }
  };

  onClose = () => {
    const {onClose, clearUsers} = this.props;

    clearUsers();
    this.onChange(null);

    onClose();
  };

  getOptions() {
    const {users} = this.props;

    return users.data
      ? users.data.data.map(item => ({
          label: `${item.firstName} ${item.lastName}`,
          value: `${item.firstName} ${item.lastName}`,
          id:     item.id,
          data:   item,
        }))
      : null;
  }

  selectRenderer(option) {
    return (
      <div>{option.data.firstName} {option.data.lastName} {option.data.email}</div>
    );
  }

  render() {
    const {isOpen, title, buttonText} = this.props;
    const {selected} = this.state;

    const options = this.getOptions();

    return (
      <Modal isOpen={isOpen} toggle={this.onClose}>
        <ModalHeader toggle={this.onClose}>{title}</ModalHeader>
        <ModalBody>
          <div>
            <Select
              onBlurResetsInput={false}
              onSelectResetsInput={false}
              autoFocus
              value={selected}
              optionRenderer={this.selectRenderer}
              valueRenderer={this.selectRenderer}
              options={options}
              onChange={this.onChange}
              onInputChange={this.onInputChange}
              searchable={true}
              filterOptions={false}
              matchProp={'any'}
              inputProps={{
                autoComplete: 'off',
                autofill: 'off',
                spellCheck: 'false',
                autoCorrect: 'off',
                autoCapitalize: 'off'
              }}
              placeholder="Type to search..."
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button onClick={this.onSubmit} className="btn btn-success" disabled={!selected}>{buttonText.submit}</button>
          <button onClick={this.onClose} className="btn btn-outline-secondary">{buttonText.cancel}</button>
        </ModalFooter>
      </Modal>
    );
  }
}

ModalSelectUser.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.shape({
    submit: PropTypes.string,
    cancel: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeOnSubmit: PropTypes.bool,

  users: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  clearUsers: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    users: state.users,
  }),
  ({
    getUsers,
    clearUsers
  })
)(ModalSelectUser);
