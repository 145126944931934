import { LINKED_ACCOUNTS } from '../actions/types';

const initialState = {
  data: null,
  isPending: false,
  isError: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LINKED_ACCOUNTS.GET.START:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
      };
    case LINKED_ACCOUNTS.GET.SUCCESS:
      return {
        ...state,
        isPending: false,
        isError: false,
        error: null,
        data: action.payload,
      };
    case LINKED_ACCOUNTS.GET.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload
      };
    case LINKED_ACCOUNTS.NEW.START:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
      };
    case LINKED_ACCOUNTS.NEW.SUCCESS:
      return {
        ...state,
        isPending: false,
        isError: false,
        error: null,
      };
    case LINKED_ACCOUNTS.NEW.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload
      };
    case LINKED_ACCOUNTS.DELETE.START:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
      };
    case LINKED_ACCOUNTS.DELETE.SUCCESS:
      return {
        ...state,
        isPending: false,
        isError: false,
        error: null,
      };
    case LINKED_ACCOUNTS.DELETE.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload
      };
    default:
      return state;
  }
};
