export const getTournamentTeamsSelector = (state) => {
  return {
    isLoading: state.get.isPending || (!state.get.isSuccess && !state.get.isError),
    data: state.allIds.map(id => state.byId[id]),
    pagination: state.pagination,
  };
};

export default {
  getTournamentTeamsSelector,
};
