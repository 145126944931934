import * as React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import { connect } from 'react-redux';
import {fetchFormats} from '../../actions/formatsActions';

class DeckProgressBar extends React.Component {
  componentDidMount() {
    this.props.fetchFormats();
  }

  getFormatValue = () => {
    const {formats} = this.props;
    if(!formats.data) {
      return null;
    }
    let value = null;
    const formatInd = formats.data.findIndex(i => i.name === this.props.format);

    const detailsInd = formats.data[formatInd].details.findIndex(i => i.variable === 'maindeck');
    if(formats.data[formatInd].details[detailsInd].operator === 'any'){
      value = 1;
    } else {
      value = formats.data[formatInd].details[detailsInd].value;
    }

    return value;
  }
  
  render() {
  const {amount} = this.props;
  const formatValue = this.props.formats.data && this.getFormatValue();

  const progressValue = amount.maindeck && formatValue ? amount.maindeck/formatValue *100 : 0;
  return (
    <div>
      <Progress color="primary" value={progressValue} className="custom-progress"/>
    </div>
  );
  }
}

DeckProgressBar.propTypes = {
  amount: PropTypes.any,
  format: PropTypes.string,
  formats: PropTypes.object,
  fetchFormats: PropTypes.func,
};

export default connect(
  (state) => ({
    formats: state.formats,
  }),
  ({
    fetchFormats,
  })
)(DeckProgressBar);
