import { TOURNAMENT_JOIN_MODAL } from '../actions/types';

const initialState = {
  isOpen: false,
  tournamentId: null,
  playerId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_JOIN_MODAL.START:
      return {
        ...state,
        isOpen: action.isOpen,
        tournamentId: action.tournamentId,
        playerId: action.playerId,
      };
    default:
      return state;
  }
};
