import { CARD_SEARCH, CARD_SELECT } from './types';

export const cardSearch = (data) => ({
  type: CARD_SEARCH.FETCH,
  payload: data,
});

export const cardImageShow = (data) => ({
  type: CARD_SELECT.SHOW,
  payload: data,
});