export const isValid = (oldPass, newPass, repeatedPass) => {
  if(!oldPass) {
    return false;
  }

  if(oldPass === newPass) {
    return false;
  }

  if(newPass !== repeatedPass) {
    return false;
  }

  if(newPass.length < 8) {
    return false;
  }

  return true;
};