import classNames from 'classnames';
import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default class Password extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange(value, type) {
    this.props.onChange(value, type);
  }

  isPasswordValid() {
    const {oldPassValue, passValue} = this.props;

    if(!passValue) {
      return null;
    }

    if(passValue === oldPassValue) {
      return <div className="invalid-feedback"><FormattedMessage id="password.error2"/></div>;
    }

    if(passValue.length < 8) {
      return <div className="invalid-feedback"><FormattedMessage id="password.error3"/></div>;
    }

    return true;
  }

  isPasswordRepeatValid() {
    const {passValue, repeatPassValue} = this.props;

    if(!repeatPassValue) {
      return null;
    }

    if( repeatPassValue === passValue ) {
      return true;
    }

    return <div className="invalid-feedback"><FormattedMessage id="password.error4"/></div>;
  }

  render() {
    const {oldPassValue, passValue} = this.props;

    const oldInputClass = classNames('form-control', 'register-form__input', {
      'is-valid': oldPassValue.length>0,
      'is-invalid': passValue && !oldPassValue,
    });

    const newInputClass = classNames('form-control', 'register-form__input', {
      'is-valid': this.isPasswordValid() === true,
      'is-invalid': (this.isPasswordValid() !== true) && (this.isPasswordValid() !== null),
    });

    const newRepeatInputClass = classNames('form-control', 'register-form__input', {
      'is-valid': this.isPasswordRepeatValid() === true,
      'is-invalid': (this.isPasswordRepeatValid() !== true) && (this.isPasswordRepeatValid() !== null),
    });

    return (
      <React.Fragment>
        <form className="change-password-form">
            <div className="form-row">
                <div className="form-group col-md-5">
                  <label htmlFor="email"><FormattedMessage id="password.old.label"/></label>
                  <input
                    type="password"
                    className={oldInputClass}
                    value={this.props.oldPassValue}
                    onChange={(e) => this.handleChange(e.target.value, 'oldPassword')}
                />
                </div>
            </div>
              <div className="form-row">
                  <div className="form-group col-md-5">
                    <label htmlFor="email"><FormattedMessage id="password.new.label"/></label>
                    <input
                      type="password"
                      className={newInputClass}
                      value={this.props.passValue}
                      onChange={(e) => this.handleChange(e.target.value, 'password')}
                    />
                      {this.isPasswordValid()}
                  </div>
                  <div className="form-group col-md-5 offset-md-1">
                    <label htmlFor="dci number"><FormattedMessage id="password.new.repeat.label"/></label>
                    <input
                      type="password"
                      className={newRepeatInputClass}
                      value={this.props.repeatPassValue}
                      onChange={(e) => this.handleChange(e.target.value, 'passwordRepeat')}
                    />
                    {this.isPasswordRepeatValid()}
                  </div>
                </div>
              </form>

              {this.props.submitError && <div className="text-danger">{this.props.submitError}</div>}
      </React.Fragment>
    );
  }
}

Password.propTypes = {
  onValidationChanged: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  passValue: PropTypes.string.isRequired,
  oldPassValue: PropTypes.string.isRequired,
  repeatPassValue: PropTypes.string.isRequired,
  submitError: PropTypes.string,
};
