import React from 'react';
import PropTypes from 'prop-types';
import loadImage from 'blueimp-load-image';

import './SelectImageButton.scss';

class SelectImageButton extends React.Component {
  refInput = null;

  onSelect = () => {
    this.refInput.click();
  };

  onChange = (e) => {
    const image = e.target.files[0];

    // Save correct orientation of image
    // @see: https://github.com/DominicTobias/react-image-crop/issues/181#issuecomment-396552104
    loadImage(
      image,
      (canvas) => {
        const imageUrl = canvas.toDataURL('image/jpeg');

        this.props.onChange({
          image,
          imageUrl,
        });
      },
      {
        orientation: true,
      }
    );
  };

  onClick = (e) => {
    // Allow select the same file
    // @see: https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component
    e.target.value = null;
  };

  render() {
    const {text} = this.props;

    return (
      <div className="select-image-button">
        <input type="file"
               accept="image/*"
               className="form-control"
               ref={(elem) => this.refInput = elem}
               onChange={this.onChange}
               onClick={this.onClick}/>
        <button type="button" className="btn btn-primary" onClick={this.onSelect}>
          {text}
        </button>
      </div>
    );
  }
}

SelectImageButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default SelectImageButton;
