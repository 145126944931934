import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {omit} from 'lodash';

import ImageCropper from './ImageCropper';

class ImageCropperModal extends React.Component {
  renderImageCropper = (main, buttons) => {
    return (
      <React.Fragment>
        <ModalBody>
          {main}
        </ModalBody>
        <ModalFooter>
          {buttons}
        </ModalFooter>
      </React.Fragment>
    );
  };

  render() {
    const {isOpen, onClose, title, ...props} = this.props;

    return (
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>{title}</ModalHeader>

        <ModalBody>
          {
            isOpen &&
            <ImageCropper {...props}
                          render={this.renderImageCropper}
                          onCancel={onClose}/>
          }
        </ModalBody>
      </Modal>
    );
  }
}

const fromImageCropperPropTypes = omit(
  ImageCropper.propTypes,
  ['render', 'onCancel', 'src'],
);

ImageCropperModal.propTypes = {
  ...fromImageCropperPropTypes,
  src: PropTypes.oneOfType([
    ImageCropper.propTypes.src,
    PropTypes.oneOf([null]),
  ]),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default ImageCropperModal;
