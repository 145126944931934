import React from 'react';
import PropTypes from 'prop-types';
import {withRouter, Route, Switch} from 'react-router-dom';

import TournamentLocationShow from './TournamentLocationShow';
import TournamentLocationEdit from './TournamentLocationEdit';

class TournamentLocation extends React.Component {
  render() {
    const {match, tournament} = this.props;

    const withTournament = (Component) => () => <Component tournament={tournament}/>;
    const edit = (Component) => () => <Component tournament={tournament} tournamentId={tournament.id}/>;

    return (
      <React.Fragment>
        <Switch>
          <Route path={`${match.path}/edit`} render={edit(TournamentLocationEdit)}/>
          <Route path={`${match.path}/`} render={withTournament(TournamentLocationShow)}/>
        </Switch>
      </React.Fragment>
    );
  }
}

TournamentLocation.propTypes = {
  match: PropTypes.object.isRequired,
  tournament: PropTypes.object,
};

export default withRouter(TournamentLocation);
