import { CHANNEL_LIVE } from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  data: [],
};

export default (state = initialState, action) => {
  switch(action.type){
    case CHANNEL_LIVE.FETCH:
      return {
        ...state,
        isLoading: true,
      };
    case CHANNEL_LIVE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case CHANNEL_LIVE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default: 
      return state;
  }
};
