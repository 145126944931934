import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, ButtonGroup } from 'reactstrap';
import {FacebookShareButton, TwitterShareButton, RedditShareButton} from 'react-share';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import { decklistToText } from 'cbl-javascript-libraries/deck-to-text';

import TopBar from '../../Layout/TopBar';
import artifact from '../../../assets/images/types/icon-type-artifact.png';
import creature from '../../../assets/images/types/icon-type-creature.png';
import enchantment from '../../../assets/images/types/icon-type-enchantment.png';
import instant from '../../../assets/images/types/icon-type-instant.png';
import land from '../../../assets/images/types/icon-type-land.png';
import planeswalker from '../../../assets/images/types/icon-type-planeswalker.png';
import sorcery from '../../../assets/images/types/icon-type-sorcery.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {cardSizing} from '../../const';
import NotifierMain from '../../Notifier/NotifierMain';
import RecentDecksContainer from './RecentDecksContainer';

import {fixLineSeparatorForBrowser} from 'cbl-javascript-libraries/clipboard-utils';
import DeckService from '../../../services/Deck';

import {sortByType, groupByCardMainType, flatGroupByCardMainType, flatGroupByManaColor, flatGroupByCMC, sortByColors,
  sortByCardsAmountDesc, sortByCMC, sortByCMCWithXLastWithinManaGroup, sortByName, createTypesComparator, flattenCardRelations } from '../../../services/DeckCards';

import PreviewCard from './PreviewCard';

import './SharedDeck.scss';
import customCardImg from '../../../assets/images/custom_card.png';

const PRESENTATION_MODE = {
  SQUEEZED: 'SQUEEZED',
  BY_TYPE: 'BY_TYPE',
  BY_MANA_COST: 'BY_MANA_COST',
};

class SharedDeck extends React.Component {
  state = {
    isVisible: true,
    presentationMode: PRESENTATION_MODE.SQUEEZED,
    previewCardId: null
  };

  onChangeMode(mode) {
    this.setState({
      presentationMode: mode,
    });
  }

  toggleDropdown = (prop, state) => {
    this.setState({
      [prop]: !state
    });
  };

  getClipboardData = () => {
    const {deckCards} = this.props;

    if(!deckCards) {
      return null;
    }

    return decklistToText(deckCards);
  };

  closeCardPreview = (e) => {
    e.stopPropagation();

    this.setState({
      previewCardId: null
    });
  };

  openCardPreview = (id) => {
    this.setState({
      previewCardId: id
    });
  };

  renderPreview = () => {
    const {partnerId, purchases} = this.props;
    const {previewCardId} = this.state;

    if(!previewCardId) {
      return null;
    }

    return (
      <PreviewCard cardId={previewCardId} close={this.closeCardPreview} partnerId={partnerId} purchases={purchases} />
    );
  }

  renderCustomCard(id, cardName, cardMarginTop) {
    const tooltip = `
      <div class="custom-card-preview">
        <img src="${customCardImg}"/>
        <span>${cardName}</span>
      </div>
    `;

    return (
      <div key={id} className="shared-card custom-card" style={{marginTop: id * cardMarginTop}} data-tip={tooltip}>
        <img className="img-fluid" src={customCardImg} alt=""/>
        <span>{cardName}</span>
      </div>
    );
  }

  renderCard(id, cardName, imageUrl, cardMarginTop, cardId) {
    const {intl, purchases} = this.props;

    const img = `<img src="${imageUrl}"/>`;
    const imgWithPrice = `<div class="shared-deck-preview-card">
      <!-- <span>${intl.messages['shared.deck.tooltip.text']}</span> -->
      ${img}
    </div>`;
    const dataTip = (!purchases || purchases === 'true') ? imgWithPrice : img;

    if(!imageUrl) {
      return this.renderCustomCard(id, cardName, cardMarginTop);
    }

    return (
      <div onClick={() => this.openCardPreview(cardId)} key={id} className="shared-card" style={{marginTop: id * cardMarginTop}} data-tip={dataTip}>
        <img className="img-fluid" src={imageUrl} alt=""/>
      </div>
    );
  }

  prepareByCardType(deckCards) {
    const grouped = DeckService.groupByTypes(deckCards);

    const types = grouped.map(item => item.type);

    grouped.forEach(group => {
      group.cards.sort((a, b) => {
        return sortByCMC(a, b) || sortByColors(a, b) || sortByCardsAmountDesc(a, b) || sortByName(a, b);
      });
    });

    const rowsOfPiles = grouped.map(group => group.cards.map(card => flattenCardRelations([card])));

    return {
      types,
      rows: rowsOfPiles,
    };
  }

  prepareByManaCost(deckCards) {
    const grouped = DeckService.divideIntoLandsAndNonLands(deckCards);

    grouped.forEach(group => {
      group.cards.sort((a, b) => {
        return sortByCMCWithXLastWithinManaGroup(a, b) || sortByType(a, b) || sortByColors(a, b) || sortByCardsAmountDesc(a, b) || sortByName(a, b);
      });
    });

    return grouped.map(group => group.cards.map(card => flattenCardRelations([card])));
  }

  groupCardsIntoRowsOfPiles(deckCards) {
    let groups = [];

    const groupedByType = groupByCardMainType(deckCards);

    let { Creature: creatures = [] } = groupedByType;

    // zero: just custom cards
    let { Custom: custom = [] } = groupedByType;
    custom = custom.sort(sortByName);
    groups.push(custom);

    // first: just creatures
    creatures = creatures.sort((a, b) => {
      return sortByCMCWithXLastWithinManaGroup(a, b) || sortByColors(a, b) || sortByCardsAmountDesc(a, b) || sortByName(a, b);
    });
    groups.push(creatures);

    // second: Artifacts, Enchantemns, Planeswalkers
    const { Artifact = [], Enchantment = [], Planeswalker = [] } = groupedByType;
    const sortByTypeAEP = createTypesComparator(['Artifact', 'Enchantment', 'Planeswalker']);
    const group2 = [...Artifact, ...Enchantment, ...Planeswalker].sort((a, b) => {
      return sortByCMCWithXLastWithinManaGroup(a, b) || sortByTypeAEP(a, b) || sortByColors(a, b) || sortByCardsAmountDesc(a, b) || sortByName(a, b);
    });
    groups.push(group2);

    // third: Sorceries & Instants
    const { Sorcery = [], Instant = [] } = groupedByType;
    const sortByTypeIS = createTypesComparator(['Instant', 'Sorcery']);
    const group3 = [...Instant, ...Sorcery].sort((a, b) => {
      return sortByCMCWithXLastWithinManaGroup(a, b) || sortByTypeIS(a, b) || sortByColors(a, b) || sortByCardsAmountDesc(a, b) || sortByName(a, b);
    });
    groups.push(group3);

    // last but not least - Lands
    let { Land: lands = [] } = groupedByType;
    const basicLands = lands.filter(c => c.card.superTypes.includes('Basic')).sort(sortByColors);
    const nonBasicLands = lands.filter(c => !c.card.superTypes.includes('Basic')).sort(sortByColors);
    lands = [...nonBasicLands, ...basicLands];
    groups.push(lands);

    // rebalance groups -> squash ones that are next to each other if it's length <= 2,
    // then merge groups with 1 card to the previous group
    groups = groups.reduce((arr, group) => {
      if(arr.length === 0) {
        arr.push(group);
        return arr;
      }

      const previousIndex = arr.length-1;
      const previous = arr[previousIndex];
      if(previous.length === 1 || (previous.length <= 2 && group.length <= 2)) {
        arr[previousIndex] = [...previous, ...group];
      } else {
        arr.push(group);
      }

      return arr;
    }, []);

    const rowsOfPiles = groups.map(group => group.map(relation => flattenCardRelations([ relation ])));

    return rowsOfPiles;
  }

  renderMainDeck() {
    const {deckCards} = this.props;
    const {presentationMode} = this.state;

    const maindeck = deckCards.filter(item => item.type === 'maindeck');
    let rows;
    let types = null;

    if(presentationMode === PRESENTATION_MODE.BY_TYPE) {
      const data = this.prepareByCardType(maindeck);

      types = data.types;
      rows = data.rows;
    }
    else {
      rows = this.groupCardsIntoRowsOfPiles(maindeck);
    }

    if(presentationMode === PRESENTATION_MODE.BY_MANA_COST) {
      rows = this.prepareByManaCost(maindeck);
    }

    if(presentationMode === PRESENTATION_MODE.SQUEEZED) {
      const allPiles = rows.reduce((arr, piles) => [...arr, ...piles], []);
      const allCards = allPiles.reduce((arr, cards) => [...arr, ...cards], []);
      const pilesOfFour = [];
      for (let index = 0; index < allCards.length; index+=4) {
        pilesOfFour.push(allCards.slice(index, index+4));
      }
      rows = [ pilesOfFour ];
    }

    return (
      <React.Fragment>
        {
          rows.map((piles, index) => {
            return (
              <div className="row" key={index}>
                { types && types[index] && <h4>{types[index]}</h4> }
                { piles.map((pile, index) => <React.Fragment key={index}>{this.renderPileOfCards(pile)}</React.Fragment>) }
              </div>
            );
          })
        }
      </React.Fragment>
    );
  }

  renderPileOfCards(cards, marginTop = cardSizing.marginTop) {
    return (
      <div className="shared-cards__wrapper">
        {
          cards.map((card, id) => this.renderCard(id, card.name, card.imageUrl, marginTop, card.cardId))
        }
      </div>
    );
  }

  renderSideDeck(cards) {
    const sidedeck = cards.filter(item => item.type === 'sideboard');
    if(sidedeck.length < 1 || !sidedeck) {
      return (
        <FormattedMessage id="shared.deck.nosidedeck"/>
      );
    }

    return this.renderPileOfCards(flattenCardRelations(sidedeck), cardSizing.sidedeckMarginTop);
  }

  renderMana() {
    const cardsByCMC = flatGroupByCMC(this.props.deckCards);
    const mana = Object.keys(cardsByCMC)
      .reduce((map, cmc) => {
        const newCmc = Math.min(cmc, 7);
        map[newCmc] = (map[newCmc] || 0) + cardsByCMC[cmc].length;
        return map;
      }, {});

    return(
      Array.from({length: 8}, (i, id) => id).map( (item, id) =>
        <div key={id} className="mana-item">
          <span className="mana-text">{mana && mana[id]}</span>
          <span className="mana-bar" style={{height: 'auto', flexBasis: mana && mana[id] }}></span>
        </div>
      )
    );
  }

  renderTypes() {
    const types = flatGroupByCardMainType(this.props.deckCards);
    const icons = {
      'Artifact': artifact,
      'Creature': creature,
      'Enchantment': enchantment,
      'Instant': instant,
      'Land': land,
      'Planeswalker': planeswalker,
      'Sorcery': sorcery,
    };

    return(
      Object.keys(types).map( (item, id) =>
        <span key={id} className="shared-deck__types"><img src={icons[item]} title={item} className="icon-type"/>{types && types[item] && types[item].length}</span>
      )
    );
  }

  renderColors() {
    const colors = flatGroupByManaColor(this.props.deckCards);
    return (
      ['B', 'C', 'U', 'R', 'G', 'W'].map( (item, id)=>{
        return colors && colors[item] && <span key={id} className={`bar bar--${item}`} style={{flex: colors[item].length}} title={item}></span>;
      })
    );
  }

  copySuccess = () => {
    const {intl} = this.props;
    this.props.onCopyToClipboard({
      text: intl.messages['shared.deck.oncopy.label'],
      type: 'success',
      timeout: 4,
    });
  };

  renderModeButtons() {
    const {presentationMode} = this.state;

    const isSqueezed = presentationMode === PRESENTATION_MODE.SQUEEZED;
    const isByManaCost = presentationMode === PRESENTATION_MODE.BY_MANA_COST;
    const isByType = presentationMode === PRESENTATION_MODE.BY_TYPE;

    return (
      <ButtonGroup className="mode-btn-wrapper"size="sm">
        <Button color="info" outline active={isSqueezed} onClick={() => this.onChangeMode(PRESENTATION_MODE.SQUEEZED)}>
          Squeezed
        </Button>
        <Button color="info" outline active={isByType} onClick={() => this.onChangeMode(PRESENTATION_MODE.BY_TYPE)}>
          By Card Type
        </Button>
        <Button color="info" outline active={isByManaCost} onClick={() => this.onChangeMode(PRESENTATION_MODE.BY_MANA_COST)}>
          By Mana Cost
        </Button>
      </ButtonGroup>
    );
  }

  renderPanelTop(deckData) {
   const { deckName } = this.props;

    return (
      <div className="shared-deck__panel-top text-left">
        <div>
          <h3 className="shared-deck__title">{deckName}</h3>
          {
            deckData.user &&
            <p className="shared-deck__describe">
              <FormattedMessage id="shared.deck.top.playerdBy"/>
              <span className="value">{deckData.user.firstName} {deckData.user.lastName}</span>
            </p>
          }
          <p className="shared-deck__describe">
            <FormattedMessage id="shared.deck.top.format"/>
            <span className="value">{deckData.format.name}</span>
          </p>
          {
            deckData.tournament &&
            <p className="shared-deck__describe">
              <FormattedMessage id="shared.deck.top.tournament"/>
              <span className="value">{deckData.tournament.name}</span>
            </p>
          }
        </div>
        <div className="shared-deck__types-container d-none">
          <div className="shared-deck__types-wrapper">
            {this.renderTypes()}
          </div>
          <div className="colors-wrapper">
            {this.renderColors()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {isVisible, presentationMode} = this.state;
    const {deckData, deckCards, intl, shortenerUrl, arenaFormat, channelId, channelName, deckName} = this.props;

    if(!deckData) {
      return null;
    }

    const iconClass = classnames('fa d-inline-block d-md-none d-lg-none d-xl-none',
      {
        'fa-chevron-circle-down': isVisible,
        'fa-chevron-circle-left': !isVisible
      });

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
      <Fragment>
        <TopBar mobileBreakpoint={null} isEmptyTopBar={true} isChannelLive={false} />
        <NotifierMain className="fix-topbar-padding fix-topbar-padding-live-channel"/>
        {channelId && <RecentDecksContainer channelId={channelId}/>}

        {channelName && channelId &&
          <div className="container pt-4">
            <span className="share-deck-deck-link"><Link className="share-deck-deck-name" to={`/s/${channelName}`}>{channelName}</Link> / {deckName}</span>
          </div>
        }

        <div className="login-container text-center container shared-deck">
          <ReactTooltip place={'right'} html={true} key={presentationMode} disable={isMobile} />

          <div className="mt-1">
            <div className="row">
              <div className="col-md-9">
                {this.renderPanelTop(deckData)}
                <div className="shared-deck__panel-bottom text-left">
                  <span className={classnames('shared-deck__text-small shared-deck-dropdown-wrapper', {
                    disabled: !shortenerUrl
                  })}>
                  <span className="btn-share"><i className="icon-share"/><FormattedMessage id="shared.deck.share.btn"/></span>
                  <div className="share-dropdown">
                    {
                      shortenerUrl && (
                        <React.Fragment>
                          <FacebookShareButton url={shortenerUrl}>
                          <FormattedMessage id="shared.deck.fb.btn"/>
                          </FacebookShareButton>
                          <TwitterShareButton url={shortenerUrl} title={intl.formatMessage({id: 'shared.deck.top.share.twitter'})}>
                            <FormattedMessage id="shared.deck.twitter.btn"/>
                          </TwitterShareButton>
                          <RedditShareButton url={shortenerUrl}>
                            <FormattedMessage id="shared.deck.reddit.btn"/>
                          </RedditShareButton>
                        </React.Fragment>
                      )
                    }
                  </div>
                  </span>
                  <CopyToClipboard text={this.getClipboardData()} onCopy={this.copySuccess}>
                    <span className="shared-deck__text-small shared-deck__text-small--link"><i className="icon-copying"/> <FormattedMessage id="shared.deck.copy.btn"/></span>
                  </CopyToClipboard>
                  <CopyToClipboard text={fixLineSeparatorForBrowser(arenaFormat ? arenaFormat : '')} onCopy={this.copySuccess}>
                    <span className="shared-deck__text-small shared-deck__text-small--link"><i className="icon-copying"/> <FormattedMessage id="shared.deck.copy.arena.btn"/></span>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="col-md-3">
                <div className="shared-deck__panel-top shared-deck__panel-top--mana text-left">
                  <div className="mana-container">
                    <h6 className="font-weight-bold mana-title"><FormattedMessage id="shared.deck.mana.label"/>
                    <i onClick={() => this.toggleDropdown('isVisible', isVisible)} className={iconClass}/>
                  </h6>
                    <div className={classnames('mana-wrapper', {hidden: !isVisible})}>
                      {
                        this.renderMana()
                      }
                    </div>
                  </div>
                </div>
                <div className={classnames('shared-deck__panel-bottom center', {hidden: !isVisible})}>
                  <span className="shared-deck__number">0</span>
                  <span className="shared-deck__number">1</span>
                  <span className="shared-deck__number">2</span>
                  <span className="shared-deck__number">3</span>
                  <span className="shared-deck__number">4</span>
                  <span className="shared-deck__number">5</span>
                  <span className="shared-deck__number">6</span>
                  <span className="shared-deck__number">7+</span>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-9">
                <div className="d-block d-sm-flex flex-row mb-3 mt-2">
                  <div className="text-left font-weight-bold"><FormattedMessage id="shared.deck.maindeck.label"/></div>
                  <div className="ml-auto text-md-right text-right">
                    <small className="text-info d-none d-md-inline-block mr-2">Presentation mode:</small>
                    {this.renderModeButtons()}
                  </div>
                </div>
                <div className="shared-cards__container shared-cards__container--maindeck text-center">
                  { this.renderMainDeck() }
                </div>
              </div>
              <div className="col-md-3">
                <div className="text-left font-weight-bold mb-3 mt-2"><FormattedMessage id="shared.deck.sidedeck.label"/></div>
                <div className="shared-cards__container shared-cards__container--sidedeck text-center">
                  {deckCards && this.renderSideDeck(deckCards)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderPreview()}
      </Fragment>
    );
  }
}

SharedDeck.propTypes = {
  arenaFormat: PropTypes.string,
  match: PropTypes.object,
  deckCards: PropTypes.array,
  deckData: PropTypes.object,
  onCopyToClipboard: PropTypes.func,
  intl: PropTypes.object,
  shortenerUrl: PropTypes.string,
  partnerId: PropTypes.string,
  purchases: PropTypes.string,
  channelId: PropTypes.string,
  channelName: PropTypes.string,
  deckName: PropTypes.string
};

export default injectIntl(SharedDeck);
