import { STREAMERS_DECKS, STREAMERS_DECKS_LOAD_MORE, STREAMERS_DECKS_EDIT_NAME, STREAMERS_DECKS_DELETE } from '../actions/types';

const initialState = {
  data: null,
  isLoad: false,
  isError: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STREAMERS_DECKS.START:
      return {
        ...state,
        isLoad: true,
        isError: false,
        error: null,
      };
    case STREAMERS_DECKS.SUCCESS:
      return {
        ...state,
        isLoad: false,
        isError: false,
        error: null,
        data: action.payload,
      };
    case STREAMERS_DECKS.ERROR:
      return {
        ...state,
        isLoad: false,
        isError: true,
        error: action.payload
      };
    case STREAMERS_DECKS_LOAD_MORE.START:
      return {
        ...state,
        isLoad: true,
        isError: false,
        error: null,
      };
    case STREAMERS_DECKS_LOAD_MORE.SUCCESS:
      return {
        ...state,
        isLoad: false,
        isError: false,
        error: null,
        data: {
          ...action.payload,
          data: [...state.data.data, ...action.payload.data]
        }
      };
    case STREAMERS_DECKS_LOAD_MORE.ERROR:
      return {
        ...state,
        isLoad: false,
        isError: true,
        error: action.payload
      };
    case STREAMERS_DECKS_DELETE.START:
      return {
        ...state,
        isLoad: true,
        isError: false,
        error: null,
      };
    case STREAMERS_DECKS_DELETE.SUCCESS:
      return {
        ...state,
        isLoad: false,
        isError: false,
        error: null,
        data: {
          ...action.payload,
          data: state.data.data.filter(deck => deck.id !== action.payload.deckId)
        }
      };
    case STREAMERS_DECKS_DELETE.ERROR:
      return {
        ...state,
        isLoad: false,
        isError: true,
        error: action.payload
      };
    case STREAMERS_DECKS_EDIT_NAME.START:
      return {
        ...state,
        isLoad: true,
        isError: false,
        error: null,
      };
    case STREAMERS_DECKS_EDIT_NAME.SUCCESS: {
      const updatedDecks = state.data.data;
      const { displayName, id } = action.payload.data;

      updatedDecks.map((deck, index) => {
        if (deck.id === id) {
          updatedDecks[index] = { ...deck, displayName: displayName };
        }
      });

      return {
        ...state,
        isLoad: false,
        isError: false,
        error: null,
        data: {
          ...action.payload,
          data: updatedDecks
        }
      };
    }
    case STREAMERS_DECKS_EDIT_NAME.ERROR:
      return {
        ...state,
        isLoad: false,
        isError: true,
        error: action.payload
      };
    default:
      return state;
  }
};
