import {TOURNAMENT_TEAM} from './types';

export const getTournamentTeam = (teamId) => ({
  type: TOURNAMENT_TEAM.GET.START,
  payload: {
    teamId,
  }
});

export const editTournamentTeam = (teamId, data) => ({
  type: TOURNAMENT_TEAM.EDIT.START,
  payload: {
    teamId,
    data,
  }
});

export default {
  getTournamentTeam,
  editTournamentTeam,
};
