import {ADMIN_STATISTICS} from './types';

export const fetch = (name, params = {}, isRemove = true) => ({
  type: ADMIN_STATISTICS.FETCH,
  payload: {
    name,
    params,
    isRemove
  }
});

export const fetchAll = (params = {}) => ({
  type: ADMIN_STATISTICS.FETCH_ALL,
  payload: {
    params
  }
});

export const update = (name, data, params = {}) => ({
  type: ADMIN_STATISTICS.UPDATE,
  payload: {
    name,
    data,
    params
  }
});

export const updateError = (name, data) => ({
  type: ADMIN_STATISTICS.UPDATE_ERROR,
  payload: {
    name,
    data,
  }
});
