import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import httpClient from '../../../services/clientContextHttpClient';
import AuthService from '../../../services/AuthService';
import JoinTournamentService from '../../../services/JoinTournamentService';
import Spinner from '../../Spinner';
import { login, cancelCurrentLogin } from '../../../actions/authActions';

import './LoginForm.scss';

class LoginForm extends React.Component {
  static defaultProps = {
    redirectOnSuccess: '/'
  };

  state = {
    email: '',
    password: '',
    confirmationRepeat: {
      isSent: false,
      loading: false,
      error: false,
    }
  };

  componentDidMount() {
    this.props.cancelCurrentLogin();
    if (AuthService.isLogged()) {
      this.props.history.push('/');
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const {redirectOnSuccess} = this.props;
    const redirectLinkLS = JoinTournamentService.getTournamentData();

    let redirectURL = redirectOnSuccess;

    if(redirectLinkLS) {
      const now = new Date().getTime();
      const isExpired = now - redirectLinkLS.expire > 0;

      if(!isExpired) {
        redirectURL = redirectLinkLS.url;
      }
    }

    if (
      nextProps.loginSuccess !== this.props.loginSuccess &&
      !this.props.loginError
    ) {
      JoinTournamentService.removeTournamentData();
      this.props.history.push(redirectURL);
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onLoginSubmit = e => {
    e.preventDefault();

    this.setState({
      confirmationRepeat: {
        isSent: false,
        loading: false,
        error: false,
      }
    });

    const data = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.login(data);
  };

  resendConfirm = (e) => {
    e.preventDefault();

    this.setState({
      confirmationRepeat: {
        ...this.state.confirmationRepeat,
        loading: true,
      }
    });

    httpClient.post('/api/v1/email_confirmations', {
      email: this.state.email
    })
      .then(() => {
        this.props.cancelCurrentLogin();

        this.setState({
          email: '',
          password: '',
          confirmationRepeat: {
            isSent: true,
            loading: false,
            error: false,
          }
        });
      })
      .catch(() => {
        this.setState({
          confirmationRepeat: {
            isSent: false,
            loading: false,
            error: true,
          }
        });
      });
  };

  renderLoginInfo() {
    if (this.props.loginLoading) {
      return <Spinner/>;
    }

    if(this.state.confirmationRepeat.loading) {
      return <Spinner />;
    }

    if (this.props.loginError && this.props.errorMessage.data) {
      const notConfirmed = this.props.errorMessage.data.error === 'NotConfirmed';

      return (
        <React.Fragment>
          <div className="text-danger font-weight-bold">{this.props.errorMessage.data.error_description}</div>
          { notConfirmed && <a href="#" onClick={this.resendConfirm}><FormattedMessage id="login.msg.resend" /></a>}
        </React.Fragment>
      );
    }

    if(this.state.confirmationRepeat.isSent) {
      return <div className="text-success"><FormattedMessage id="login.msg.success" /></div>;
    }

    if(this.state.confirmationRepeat.isError) {
      return <div className="text-danger font-weight-bold"><FormattedMessage id="login.msg.err" /></div>;
    }

    return null;
  }

  render() {
    const notConfirmed = this.props.errorMessage && this.props.errorMessage.data.error === 'NotConfirmed';

    return (
      <form className="login-form" onSubmit={this.onLoginSubmit}>
        <div className="messages">
          {this.renderLoginInfo()}
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="email" className="h5"><FormattedMessage id="login.label.email" /></label>
            <input type="text"
                   name="email"
                   className={classNames('form-control', 'register-form__input', {
                     'is-invalid': this.props.loginError && !notConfirmed
                   })}
                   value={this.state.email}
                   onChange={this.handleChange}/>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group mt-3">
            <label htmlFor="password" className="h5"><FormattedMessage id="login.label.password" /></label>
            <input type="password"
                   name="password"
                   className={classNames('form-control', 'register-form__input', {
                     'is-invalid': this.props.loginError && !notConfirmed
                   })}
                   value={this.state.password}
                   onChange={this.handleChange}/>

            <Link to="/reset-password">
              <span className="h5">
                <small><FormattedMessage id="login.label.forgot_pass" /></small>
              </span>
            </Link>
          </div>
        </div>

        <div className="form-row mt-5">
          <div className="form-group">
            <button className="btn btn-block btn-primary btn-lg"><FormattedMessage id="login.btn" /></button>
          </div>
        </div>
      </form>
    );
  }
}

LoginForm.propTypes = {
  redirectOnSuccess: PropTypes.string,
  login: PropTypes.func,
  loginError: PropTypes.bool,
  errorMessage: PropTypes.object,
  loginLoading: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  loginSuccess: PropTypes.bool,
  cancelCurrentLogin: PropTypes.func
};

export default withRouter(connect(
  state => ({
    loginError: state.login.isError,
    errorMessage: state.login.error,
    loginLoading: state.login.isLoad,
    loginSuccess: state.login.success,
  }),
  {
    login,
    cancelCurrentLogin,
  }
)(LoginForm));
