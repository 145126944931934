import { LINKED_ACCOUNTS } from './types';

export const getLinkedAccounts = () => ({
  type: LINKED_ACCOUNTS.GET.START,
});

export const linkAccount = (code, provider) => ({
  type: LINKED_ACCOUNTS.NEW.START,
  payload: {
    code,
    provider,
  }
});

export const unlinkAccount = (id) => ({
  type: LINKED_ACCOUNTS.DELETE.START,
  payload: {
    id,
  }
});
