import {EDIT_TOURNAMENT_PLAYER} from '../actions/types';

const initialState = {
  params: {
    userId: null,
    playerId: null,
    tournamentId: null,
  },
  get: {
    isPending: false,
    isError: false,
    data: null,
  },
  save: {
    isPending: false,
    isError: false,
    data: null,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_TOURNAMENT_PLAYER.GET.START:
      return {
        ...state,
        params: {
          userId: action.payload.userId,
          playerId: action.payload.playerId,
          tournamentId: action.payload.tournamentId,
        },
        get: {
          isPending: true,
          isError: false,
          data: null,
        },
      };
    case EDIT_TOURNAMENT_PLAYER.GET.SUCCESS:
      return {
        ...state,
        get: {
          isPending: false,
          isError: false,
          data: action.payload,
        },
      };
    case EDIT_TOURNAMENT_PLAYER.GET.ERROR:
      return {
        ...state,
        get: {
          isPending: false,
          isError: true,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
