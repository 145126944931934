import {UNREGISTERED_PLAYER} from '../../actions/types';

const initialState = null;

export default (state = initialState, action) => {
  switch(action.type) {
    case UNREGISTERED_PLAYER.SET_DECK:
      return action.payload;
    default:
      return state;
  }
};
