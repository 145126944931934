import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Route, Redirect, Switch} from 'react-router-dom';

import { getTournament } from '../../actions/tournamentActions';

import TournamentData from './TournamentData';
import Spinner from '../Spinner';

import TournamentUsers from './Tabs/Users/TournamentUsers';
import TournamentCasters from './Tabs/Casters/TournamentCasters';
import TournamentLocation from './Tabs/Location/TournamentLocation';
import TournamentStandings from './Tabs/Standings/TournamentStandings';
import TournamentPlayers from './Tabs/Players/TournamentPlayers';
import TournamentTeams from './Tabs/Team/TournamentTeams';
import TournamentTeamStandings from './Tabs/TeamStandings/TournamentTeamStandings';
import TournamentMetaData from './Tabs/Meta/TournamentMetaData';
import DuoPlayers from './Tabs/Duo/DuoPlayers';

import {roles} from '../const';
import { TOURNAMENT_TYPES } from '../../services/TournamentType';

class TournamentOverview extends React.Component {
  componentDidMount(){
    const {tournamentId} = this.props.match.params;

    this.props.getTournament(tournamentId);
  }

  getData(tournamentType) {
    const isTeamTournament = tournamentType === TOURNAMENT_TYPES.TEAM;
    const isDuoTournament = tournamentType === TOURNAMENT_TYPES.DUO_STANDARD;

    if(isDuoTournament) {
      return {
        routes: [
          {url: '/players_duo/:page', component: DuoPlayers},
          {url: '/users', component: TournamentUsers},
          {url: '/casters', component: TournamentCasters},
          {url: '/info', component: TournamentLocation},
        ],
        tabs: [
          {name: `Players`, linkname: 'players_duo', link: `players_duo/1`, roles: [roles.judge, roles.organizer, roles.admin]},
          {name: `Users`, linkname: `users`, link: `users`, roles: [roles.organizer, roles.admin]},
          {name: `Casters`, linkname: `casters`, link: `casters`, roles: [roles.organizer, roles.admin]},
          {name: `Information`, linkname: `info`, link: `info`, roles: [roles.player, roles.judge, roles.organizer, roles.admin]}
        ]
      };
    }

    if(isTeamTournament) {
      return {
        routes: [
          {url: '/teams/:page', component: TournamentTeams},
          {url: '/users', component: TournamentUsers},
          {url: '/casters', component: TournamentCasters},
          {url: '/info', component: TournamentLocation},
          {url: '/standings/:page', component: TournamentTeamStandings},
          {url: '/metadata', component: TournamentMetaData},
        ],
        tabs: [
          {name: `Teams`, linkname: 'teams', link: `teams/1`, roles: [roles.judge, roles.organizer, roles.admin]},
          {name: `Users`, linkname: `users`, link: `users`, roles: [roles.organizer, roles.admin]},
          {name: `Casters`, linkname: `casters`, link: `casters`, roles: [roles.organizer, roles.admin]},
          {name: `Standings`, linkname: `standings`, link: `standings/1`, roles: [roles.judge, roles.organizer, roles.admin]},
          {name: `Information`, linkname: `info`, link: `info`, roles: [roles.player, roles.judge, roles.organizer, roles.admin]},
          {name: `Metagame`, linkname: `metadata`, link: `metadata`, roles: [roles.organizer, roles.admin]},
        ]
      };
    }

    return {
      routes: [
        {url: '/players/:page', component: TournamentPlayers},
        {url: '/users', component: TournamentUsers},
        {url: '/casters', component: TournamentCasters},
        {url: '/info', component: TournamentLocation},
        {url: '/standings/:page', component: TournamentStandings},
        {url: '/metadata', component: TournamentMetaData},
      ],
      tabs: [
        {name: `Players`, linkname: 'players', link: `players/1`, roles: [roles.judge, roles.organizer, roles.admin]},
        {name: `Users`, linkname: `users`, link: `users`, roles: [roles.organizer, roles.admin]},
        {name: `Casters`, linkname: `casters`, link: `casters`, roles: [roles.organizer, roles.admin]},
        {name: `Standings`, linkname: `standings`, link: `standings/1`, roles: [roles.judge, roles.organizer, roles.admin]},
        {name: `Information`, linkname: `info`, link: `info`, roles: [roles.player, roles.judge, roles.organizer, roles.admin]},
        {name: `Metagame`, linkname: `metadata`, link: `metadata`, roles: [roles.organizer, roles.admin]},
      ]
    };
  }

  render() {
    const {tournament, match} = this.props;

    if(!tournament.data || tournament.isLoad){
      return <Spinner/>;
    }

    const withTournament = (Component) => () => <Component tournament={tournament.data}/>;

    const {routes, tabs} = this.getData(tournament.data.type);

    return (
      <div className="tournament container pb-5 mb-5">
        <TournamentData data={tournament.data} tabs={tabs}/>

        <Switch>
          {
            routes.map(route => {
              return <Route key={route.url}
                            path={`${match.path}${route.url}`}
                            render={withTournament(route.component)}/>;
            })
          }
          <Route path={match.path} render={() => <Redirect to={`${match.url}/info`}/>}/>

        </Switch>
      </div>
    );
  }
}

TournamentOverview.propTypes = {
  getTournament: PropTypes.func.isRequired,
  tournament: PropTypes.object,
  match: PropTypes.object,
};

export default connect(
  (state) => ({
    tournament: state.tournament,
  }),
  ({
    getTournament,
  })
)(TournamentOverview);
