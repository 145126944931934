import * as React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {getTournamentsJudge} from '../../actions/tournamentActions';
import Select from 'react-select';
import DataTableWithMobileDropdown from '../DataTable/DataTableWithMobileDropdown';
import { deleteTournamentJudge } from '../../actions/tournamentMembers';
import { FormattedMessage } from 'react-intl';
import Pagination from '../Pagination/Pagination';
import Spinner from '../Spinner';
import DateFormatted from '../DateFormatted/DateFormatted';

import StatusDot from '../Tournament/StatusDot';

class TournamentListOrganizer extends React.Component {
  state = {
    isOpen: false,
    tournamentId: null
  };

  componentDidMount() {
    this.getUserTournaments(1);
  }

  getUserTournaments = (page) => {
    this.props.getTournamentsJudge(page, this.props.userId);
  }

  toggleModal = (tournamentId) => {
    this.setState({
      isOpen: !this.state.isOpen,
      tournamentId: tournamentId,
      error: null,
    });
  }

  removeFromTournament = () => {
    this.props.deleteTournamentJudge(this.state.tournamentId, this.props.userId, true);
    this.toggleModal(null);
  }

  renderTournamentsList = () => {
    const tournaments = this.props.tournamentsJudge.data;
    if (!tournaments || tournaments.data.length < 1) {
      return <div className="text-center"><FormattedMessage id="tournaments.no_tournaments"/></div>;
    }

    const columns = [
      {name: 'ID', flex: 1, key: 1},
      {name: 'NAME', flex: 2, key: 2},
      {name: 'TYPE', flex: 1, key: 3},
      {name: 'DATE', flex: 1, key: 4},
      {name: 'CITY', flex: 2, key: 5},
      {name: 'STATUS', flex: 1, key: 6},
      {name: 'DELETE', flex: 2, key: 7, isVisibleOnMobile: false},
    ];

    const data = tournaments.data.map((item, index) => {
      return [
        `#${item.id.split('-')[0]}`,
        item.name,
        item.type ? <FormattedMessage id={`tournament_type.${item.type}`}/> : '-',
        <DateFormatted key={index + '-date'} value={item.startDate}/>,
        item.city || '-',
        <div key={index + '-status'} className="text-center"><StatusDot status={item.status}/></div>,
        <div key={index + '-remove'} className="text-center" onClick={() => this.toggleModal(item.id)}><i className="icon-delete"/></div>,
      ];
    });

    const dropdownData = (dataRow, rowIndex) => [
      <span key={rowIndex + '-remove'} className="tournament-link tournament-link-mobile" onClick={() => this.toggleModal(tournaments.data[rowIndex].id)}><FormattedMessage id="btn.delete"/></span>,
    ];

    return (
      <DataTableWithMobileDropdown
        columns={columns}
        data={data}
        dropdownData={dropdownData}
      />
    );
  }

  render() {
    const {tournamentsJudge} = this.props;

    const total = tournamentsJudge.data && tournamentsJudge.data.headers && tournamentsJudge.data.headers['x-last-page'];
    const current = tournamentsJudge.data && tournamentsJudge.data.headers && tournamentsJudge.data.headers['x-page'];

    if(!tournamentsJudge.data || tournamentsJudge.isLoad){
      return <Spinner/>;
    }

    return (
      <div>
        <h4 className="section-header section-header--secondary mb-5"><FormattedMessage id="judge.title"/></h4>
        <div className="row d-none">
          <div className="input-group mb-3 col-md-6">
            <input type="text" className="form-control" placeholder="Name or ID"/>
            <div className="input-group-append">
              <button className="btn btn-light" type="button"><i className="icon-search"/></button>
            </div>
          </div>
          <div className="col-md-3 offset-md-3">
            <Select/>
          </div>
        </div>
        <div className="tournament-list mt-3">
          <div className="tournament-list-table-wrapper">
            {this.renderTournamentsList()}
          </div>
          <div className="pagination-wrapper">
            <Pagination totalPages={total} currentPage={current} onChangePage={this.getUserTournaments}/>
          </div>
        </div>
        <Modal isOpen={this.state.isOpen} toggle={() => this.toggleModal(null)}>
          <ModalHeader toggle={() => this.toggleModal(null)}><FormattedMessage id="judge.modal.delete"/></ModalHeader>
          <ModalBody>
            <FormattedMessage id="judge.modal.text"/>
            <div className="text-danger mt-2">{this.state.error}</div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={this.removeFromTournament}><FormattedMessage id="tournament.delete.modal.submit"/></button>
            <button className="btn btn-outline-secondary" onClick={() => this.toggleModal(null)}><FormattedMessage id="tournament.delete.modal.cancel"/></button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

TournamentListOrganizer.propTypes = {
  getTournamentsJudge: PropTypes.func.isRequired,
  tournamentsJudge: PropTypes.object,
  history: PropTypes.object,
  userId: PropTypes.string,
  deleteTournamentJudge: PropTypes.func,
};

export default connect(
  (state) => ({
    tournamentsJudge: state.tournamentsJudge,
  }),
  ({
    getTournamentsJudge,
    deleteTournamentJudge
  })
)(TournamentListOrganizer);
