import * as React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import { createDraft } from '../../actions/deckActions';

class GoToEditDeckButton extends React.Component {
  goToEditDeck= (e) => {
    e.stopPropagation();

    this.props.createDraft(this.props.deckId, '/decks/:id');
  };

  render() {
    const {children} = this.props;

    return (
      <span onClick={this.goToEditDeck}>{children}</span>
    );
  }
}

GoToEditDeckButton.propTypes = {
  draft: PropTypes.object,
  createDraft: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  deckId: PropTypes.string.isRequired,
  children: PropTypes.object,
};

export default connect(
  (state) => ({
    draft: state.createDraft,
  }),
  ({
    createDraft,
  })
)(GoToEditDeckButton);
