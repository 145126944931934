import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import GenericTooltip from '../GenericTooltip';

import './TournamentsSelect.scss';

class TournamentsSelect extends React.Component {
  renderOption = (option) => {
    return this.renderItem(option, `option-${option.value}`);
  };

  renderValue = (option) => {
    return this.renderItem(option, `value-${option.value}`);
  };

  renderItem(option, tooltipId) {
    return (
      <div>
        {option.name}

        {' '}

        {
          option.format &&
          <small>{option.format}</small>
        }

        {
          option.valid === false &&
          <div className="float-right">
            <GenericTooltip
              className="exclamation-mark"
              id={tooltipId}
              placement={'left'}
              message={'This tournament have different format'}
            />
          </div>
        }
      </div>
    );
  }

  render() {
    const {options, onChange, value} = this.props;

    return (
      <Select
        options={options}
        optionRenderer={this.renderOption}
        valueRenderer={this.renderValue}
        value={value}
        onChange={onChange}
        searchable={false}
        clearable={false}
      />
    );
  }
}

TournamentsSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    valid: PropTypes.bool,
    format: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

export default TournamentsSelect;
