import ls from './LocalStorage';
import httpClient from './httpClient';

export default class AuthService {
  static logout() {
    httpClient.defaults.headers.common['Authorization'] = undefined;
    ls.remove('auth');
    this.clearCurrentRole();
  }

  static isLogged() {
    return !!ls.get('auth');
  }

  static saveAuth(data) {
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
    ls.set('auth', data);
  }

  static setHeaders() {
    this.saveAuth(this.getAuth());
  }

  static getAuth() {
    return ls.get('auth');
  }

  static setCurrentRole(role) {
    ls.set('actualRole', role);
  }

  static getCurrentRole() {
    return ls.get('actualRole');
  }

  static clearCurrentRole() {
    return ls.remove('actualRole');
  }
}
