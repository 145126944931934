import {combineReducers} from 'redux';

import selectedTournament from './selectedTournament';
import joinTournament from './joinTournament';
import tournaments from './tournaments';
import registerDeck from './registerDeck';

export default combineReducers({
  selectedTournament,
  joinTournament,
  tournaments,
  registerDeck,
});
