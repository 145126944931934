import {JOIN_TEAM_TOURNAMENT} from './types';

export const joinTeamTournament = ({passCode, team}) => ({
  type: JOIN_TEAM_TOURNAMENT.START,
  payload: {
    passCode,
    team,
  }
});

export const clearJoinTeamTournament = () => ({
  type: JOIN_TEAM_TOURNAMENT.CLEAR,
});
