import * as React from 'react';

import { createLogic } from 'redux-logic';
import {
  PUBLIC_DECK, PUBLIC_CARDS, SHORTENER_URL, PUBLIC_ARENA_FORMAT, RECENT_DECKS,
  STREAMERS_DECKS, STREAMERS_DECKS_LOAD_MORE, STREAMERS_INFO, STREAMERS_DECKS_DELETE,
  STREAMERS_DECKS_EDIT_NAME
} from '../actions/types';

import { getPublicCards as getPublicCardsAction } from '../actions/publicDecksActions';

import { FormattedMessage } from 'react-intl';
import { add as addNotification } from '../actions/notificationActions';

export const getPublicDecks = createLogic({
  type: PUBLIC_DECK.FETCH,

  process({ clientContextHttpClient, action }, dispatch, done) {
    return clientContextHttpClient.get(`/api/v1/public_decks/${action.id}`)
      .then(res => {
        dispatch({
          type: PUBLIC_DECK.SUCCESS,
          payload: res.data
        });
        dispatch(getPublicCardsAction(action.id));
      })
      .catch(err => {
        dispatch({
          type: PUBLIC_DECK.ERROR,
          payload: err
        });
      })
      .then(() => done());
  }
});

export const deleteStreamerDeck = createLogic({
  type: STREAMERS_DECKS_DELETE.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { payload } = action;
    return httpClient.delete(`/api/v1/streamed_decks/${payload.deckId}`)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="streamed.decks.delete.success" />
        }));
        dispatch({type: STREAMERS_DECKS_DELETE.SUCCESS, payload });
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="streamed.decks.delete.error" />
        }));
        dispatch({type: STREAMERS_DECKS_DELETE.ERROR});
      })
      .finally(() => done());
  }
});

export const editStreamerDeck = createLogic({
  type: STREAMERS_DECKS_EDIT_NAME.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { payload } = action;
    return httpClient.patch(`/api/v1/streamed_decks/${payload.deckId}`, {
      displayName: payload.displayName
    })
      .then((res) => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="streamed.decks.edit.success" />
        }));
        dispatch({type: STREAMERS_DECKS_EDIT_NAME.SUCCESS, payload: res});
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="streamed.decks.edit.error" />
        }));
        dispatch({type: STREAMERS_DECKS_EDIT_NAME.ERROR});
      })
      .finally(() => done());
  }
});

export const getShortenerUrl = createLogic({
  type: SHORTENER_URL.START,
  latest: true,

  processOptions: {
    successType: SHORTENER_URL.SUCCESS,
    failType: SHORTENER_URL.ERROR,
  },

  process({ shortenerHttpClient, action }) {
    const { payload } = action;
    return shortenerHttpClient.get(`/v1/deck_links/${payload.id}`, {
      params: {
        ...payload.params
      },
    })
      .then(res => res.data);
  }
});

export const getPublicCards = createLogic({
  type: PUBLIC_CARDS.FETCH,
  latest: true,

  processOptions: {
    successType: PUBLIC_CARDS.SUCCESS,
    failType: PUBLIC_CARDS.ERROR,
  },

  process({ clientContextHttpClient, action }) {
    return clientContextHttpClient.get(`/api/v1/public_decks/${action.id}/cards`)
      .then(res => res.data);
  }
});

export const getPublicArenaFormat = createLogic({
  type: PUBLIC_ARENA_FORMAT.START,

  processOptions: {
    successType: PUBLIC_ARENA_FORMAT.SUCCESS,
    failType: PUBLIC_ARENA_FORMAT.ERROR,
  },

  process({ clientContextHttpClient, action }) {
    return clientContextHttpClient.get(`/api/v1/decks/${action.payload.deckId}/arena`)
      .then(res => res.data);
  }
});

export const getRecentDecks = createLogic({
  type: RECENT_DECKS.START,
  latest: true,

  processOptions: {
    successType: RECENT_DECKS.SUCCESS,
    failType: RECENT_DECKS.ERROR,
  },

  process({ clientContextHttpClient, action }) {
    return clientContextHttpClient.get(`/api/v1/channels/${action.payload.channelId}/streamed_decks`, {
      params: {
        'per_page': 5,
      }
    })
      .then(res => res.data);
  }
});

export const getStreamerDecks = createLogic({
  type: STREAMERS_DECKS.START,
  latest: true,

  processOptions: {
    successType: STREAMERS_DECKS.SUCCESS,
    failType: STREAMERS_DECKS.ERROR,
  },

  process({ clientContextHttpClient, action }) {
    const { page, channelId, deckId } = action.payload;

    return clientContextHttpClient.get(`/api/v1/channels/${channelId}/streamed_decks`, {
      params: {
        'page': page || 1,
        'deckId': deckId ? deckId : ''
      }
    })
      .then(res => res);
  }
});

export const loadMoreStreamerDecks = createLogic({
  type: STREAMERS_DECKS_LOAD_MORE.START,
  latest: true,

  processOptions: {
    successType: STREAMERS_DECKS_LOAD_MORE.SUCCESS,
    failType: STREAMERS_DECKS_LOAD_MORE.ERROR,
  },

  process({ clientContextHttpClient, action }) {
    const { page } = action.payload;

    return clientContextHttpClient.get(`/api/v1/channels/${action.payload.channelId}/streamed_decks`, {
      params: {
        'page': page || 1
      }
    })
      .then(res => res);
  }
});

export const getStreamersInfo = createLogic({
  type: STREAMERS_INFO.START,
  latest: true,

  processOptions: {
    successType: STREAMERS_INFO.SUCCESS,
    failType: STREAMERS_INFO.ERROR,
  },

  process({ clientContextHttpClient, action }) {
    const { channelValue } = action.payload;

    return clientContextHttpClient.get(`/api/v1/streamers_info/${channelValue}`)
      .then(res => res.data);
  }
});

export default {
  getPublicDecks,
  getPublicCards,
  getShortenerUrl,
  getPublicArenaFormat,
  getRecentDecks,
  getStreamerDecks,
  deleteStreamerDeck,
  loadMoreStreamerDecks,
  getStreamersInfo,
  editStreamerDeck
};
