import { createLogic } from 'redux-logic';

import Deck from '../services/Deck';
import {add} from '../actions/notificationActions';
import {
  DECK_CARDS,
  ADD_CARD,
  ADD_CUSTOM_CARD,
  DELETE_CARD,
  DELETE_ALL_CARDS,
  CHANGE_QTY,
  UPDATE_DECK_CARDS,
  DECK_VALIDATOR,
  NEED_VALIDATION
} from '../actions/types';

export const validCardAmount = createLogic({
  type: DECK_VALIDATOR.VALID_CARD_AMOUNT,

  process({ httpClient, action, getState }, dispatch, done) {
    const { deckId, cardGroup } = action.payload;

    return httpClient.get(`/api/v1/decks/${deckId}/groups/${cardGroup}/validate`)
      .then(() => {
        const state = getState().deckValidation.cardAmount;

        dispatch({
          type: DECK_VALIDATOR.SET_CARD_AMOUNT_ERRORS,
          payload: [...state.filter(item => item.group !== cardGroup)]
        });
      })
      .catch(err => {
        if(err.response.status !== 422){
          return;
        }

        const data  = err.response.data.extras;
        const state = getState().deckValidation.cardAmount;

        dispatch({
          type: DECK_VALIDATOR.SET_CARD_AMOUNT_ERRORS,
          payload: [
            ...state.filter(item => item.group !== data.group),
            data
          ]
        });
      })
      .then(done);
  }
});

export const validDeck = createLogic({
  type: DECK_VALIDATOR.VALID_DECK,

  process({ httpClient, action }, dispatch, done) {
    const { deckId } = action.payload;

    return httpClient.get(`/api/v1/decks/${deckId}/validate_amount`)
      .then(() => {
        dispatch({
          type: DECK_VALIDATOR.SET_DECK_ERRORS,
          payload: []
        });
      })
      .catch(err => {
        if(err.response.status !== 422){
          return;
        }

        const data  = err.response.data.extras.deck;

        dispatch({
          type: DECK_VALIDATOR.SET_DECK_ERRORS,
          payload: data
        });
      })
      .then(done);
  }
});

export const validAll = createLogic({
  type: DECK_VALIDATOR.VALID_ALL,

  process({httpClient, action}, dispatch, done) {
    const { deckId } = action.payload;

    return httpClient.get(`/api/v1/decks/${deckId}/validate`)
      .then(() => {
        dispatch({
          type: DECK_VALIDATOR.SET_ALL_ERRORS,
          payload: {
            deck: [],
            cardAmount: [],
            cardFormat: [],
          },
        });
      })
      .catch(err => {
        if(err.response.status !== 422){
          return;
        }

        const data = err.response.data.extras;

        dispatch({
          type: DECK_VALIDATOR.SET_ALL_ERRORS,
          payload: data,
        });
      })
      .then(done);
  }
});

export const get = createLogic({
  type: DECK_CARDS.FETCH,

  process({httpClient, action}, dispatch, done) {
    return Promise.all([
      httpClient.get(`/api/v1/decks/${action.id}`),
      httpClient.get(`/api/v1/decks/${action.id}/cards`),
    ])
      .then(([deckRes, cardsRes]) => {
        const cards = cardsRes.data;
        const deck = deckRes.data;

        dispatch({
          type: DECK_CARDS.UPDATE_BASED_ON_FORMAT,
          payload: {
            format: deck.format,
            cards,
          }
        });

        dispatch({
          type: DECK_VALIDATOR.VALID_ALL,
          payload: {
            deckId: action.id
          }
        });
      })
      .catch(err => {
        dispatch({
          type: DECK_CARDS.ERROR,
          payload: err.response,
          error: true,
        });
      })
      .then(done);
  }
});

export const updateDeckCardBasedOnFormat = createLogic({
  type: DECK_CARDS.UPDATE_BASED_ON_FORMAT,

  process({ action }, dispatch, done) {
    const format  = action.payload.format;
    const cards   = action.payload.cards;

    const formatSectionsName = format.details.map(item => item.variable);
    const cardDeck = formatSectionsName.map(formatName => {
      return cards.filter(elem => elem.type === formatName);
    });

    dispatch({
      type: DECK_CARDS.SUCCESS,
      payload: cardDeck
    });
    done();
  }
});

export const addCard = createLogic({
  type: ADD_CARD.START,

  processOptions: {
    successType: ADD_CARD.SUCCESS,
    failType: ADD_CARD.ERROR,
  },

  process({httpClient, action}) {
    const { deckId, amount, format, formatSectionIndex, card } = action.payload;

    return httpClient.post(`/api/v1/decks/${deckId}/cards`, {
      cardId: card.cardId,
      type: format.details[formatSectionIndex].variable,
      amount: amount
    })
      .then(res => ({
        responseData: res.data,
        data: action.payload,
      }));
  }
});

export const addCustomCard = createLogic({
  type: ADD_CUSTOM_CARD.START,
  
  processOptions: {
    successType: ADD_CUSTOM_CARD.SUCCESS,
    failType: ADD_CARD.ERROR,
  },

  process({httpClient, action}) {
    const { deckId, amount, format, formatSectionIndex, cardName } = action.payload;

    return httpClient.post(`/api/v1/decks/${deckId}/custom_cards`, {
      customName: cardName,
      type: format.details[formatSectionIndex].variable,
      amount: amount
    })
      .then(res => ({
          responseData: res.data,
          data: action.payload,
      }));
  }
});

export const addCustomCardSuccess = createLogic({
  type: ADD_CUSTOM_CARD.SUCCESS,

  process({getState, action}, dispatch, done) {
    const { data, responseData } = action.payload;

    const actualDeck  = getState().deckCards.data;
    const nextDeck    = actualDeck.slice();

    nextDeck[data.formatSectionIndex].push({
      ...responseData,
      card: {
        cardGroup: {
          id: null,
          unlimitedAmount: null
        },
        cardId: responseData.cardId,
        name: data.cardName,
        names: [data.cardName],
        type: 'Custom',
        types: ['Custom']
      }
    });
    
    let successMsg = null;
    if(data.userDeck){
      successMsg = 'add.custom.card.success';
    } else {
      successMsg = 'add.custom.card.info';
    }

    dispatch({
      type: UPDATE_DECK_CARDS,
      payload: nextDeck
    });

    dispatch(add({
      intlKey: successMsg,
      type: 'success',
      target: data.inModal ? 'modal' : 'main'
    }));

    dispatch({
      type: NEED_VALIDATION.VALIDATION,
      payload: {
        deckId: data.deckId,
        isNeedFullValid: data.isNeedFullValid,
        format: data.format,
        responseData: responseData
      }
    });

    done();
  }
});

export const addCardSuccess = createLogic({
  type: ADD_CARD.SUCCESS,

  process({ getState, action}, dispatch, done) {
    const { data, responseData } = action.payload;

    const actualDeck  = getState().deckCards.data;
    const nextDeck    = actualDeck.slice();

    const duplicateIndex  = actualDeck[data.formatSectionIndex].findIndex(card => card.cardId === data.card.cardId);
    const isDuplicate     = duplicateIndex !== -1;

    if(isDuplicate) {
      nextDeck[data.formatSectionIndex][duplicateIndex] = {
        ...responseData,
        card: data.card
      };
    }
    else {
      nextDeck[data.formatSectionIndex].push({
        ...responseData,
        card: data.card
      });
    }

    const cardGroupId = data.card.cardGroup.id;
    const cardAmount = Deck.getCardAmount(cardGroupId, nextDeck);

    // Update deck
    dispatch({
      type: UPDATE_DECK_CARDS,
      payload: nextDeck
    });
    dispatch(add({
      text: `+${data.amount} ${data.card.name} (Total: ${cardAmount})`,
      type: 'success',
      key: 'deck',
      target: data.inModal ? 'modal' : 'main'
    }));


    dispatch({
      type: NEED_VALIDATION.VALIDATION,
      payload: {
        deckId: data.deckId,
        isNeedFullValid: data.isNeedFullValid,
        format: data.format,
        responseData: responseData
      }
    });

    done();
  }
});


export const validation = createLogic({
  type: NEED_VALIDATION.VALIDATION,

  process({action}, dispatch, done) {
    const data = action.payload;

    const isNeedFullValidCommander =
      (data.format.name === 'Commander' || data.format.name === 'Custom Commander') &&
      data.format.details[data.formatSectionIndex].variable === 'commander';

    const isNeedFullValid = data.isNeedFullValid || isNeedFullValidCommander;

    if(isNeedFullValid) {
      dispatch({
        type: DECK_VALIDATOR.VALID_ALL,
        payload: {
          deckId: data.deckId
        }
      });
    }
    else if(data.format.validationEnabled){
      // Valid
      dispatch({
        type: DECK_VALIDATOR.VALID_CARD_AMOUNT,
        payload: {
          deckId: data.deckId,
          cardGroup: data.responseData.cardGroup,
        }
      });
      dispatch({
        type: DECK_VALIDATOR.VALID_DECK,
        payload: {
          deckId: data.deckId
        }
      });
    }


    done();
  }
});

export const changeQtyCardUpdate = createLogic({
  type: CHANGE_QTY.UPDATE,

  process({action, getState}, dispatch, done) {
    const data = action.payload;

    const actualDeck  = getState().deckCards.data;
    const nextDeck    = actualDeck.slice();

    const cardIndex   = actualDeck[data.formatSectionIndex].findIndex(card => card.cardId === data.card.cardId);

    const actualDeckCardAmount  = actualDeck[data.formatSectionIndex][cardIndex].amount;
    const nextDeckCardAmount    = data.amount;
    const cardGroupId           = data.card.cardGroup.id;
    const changeAmount          = nextDeckCardAmount - actualDeckCardAmount;
    const amountChar            = changeAmount < 0 ? '' : '+';
    const messageType           = changeAmount < 0 ? 'danger' : 'success';

    nextDeck[data.formatSectionIndex][cardIndex].amount = data.amount;

    const cardAmount = Deck.getCardAmount(cardGroupId, nextDeck);

    // Update deck
    dispatch({
      type: UPDATE_DECK_CARDS,
      payload: nextDeck,
    });

    dispatch(add({
      text: `${amountChar}${changeAmount} ${data.card.name} (Total: ${cardAmount})`,
      type: messageType,
      key: 'deck',
    }));

    done();
  }
});

export const changeQtyCard = createLogic({
  type: CHANGE_QTY.START,

  process({action}, dispatch, done) {
    // Fake update deck
    dispatch({
      type: CHANGE_QTY.UPDATE,
      payload: action.payload,
    });

    dispatch({
      type: CHANGE_QTY.DEBOUNCE,
      payload: action.payload,
    });

    done();
  }
});

export const changeQtyCardDebounce = createLogic({
  type: CHANGE_QTY.DEBOUNCE,
  debounce: 500,

  processOptions: {
    successType: CHANGE_QTY.SUCCESS,
    failType: CHANGE_QTY.ERROR,
  },

  latest: true,

  process({httpClient, action}) {
    const { card, elementId, amount, format, formatSectionIndex } = action.payload;

    return httpClient.patch(`/api/v1/decks/cards/${elementId}`, {
      type: format.details[formatSectionIndex].variable,
      cardId: card.cardId,
      amount,
    })
      .then(res => ({
        responseData: res.data,
        data: action.payload,
      }));
  }
});

export const changeQtyCardSuccess = createLogic({
  type: CHANGE_QTY.SUCCESS,

  process({ getState, action}, dispatch , done) {
    const { data, responseData } = action.payload;

    const actualDeck  = getState().deckCards.data;
    const nextDeck    = actualDeck.slice();

    const cardIndex = actualDeck[data.formatSectionIndex].findIndex(card => card.cardId === data.card.cardId);

    if(data.amount === 0) {
      // Only update when amount === 0;
      // In different situations before this logic should be run `changeQtyCard` logic witch update cards amount
      nextDeck[data.formatSectionIndex].splice(cardIndex, 1);
    }

    dispatch({
      type: UPDATE_DECK_CARDS,
      payload: nextDeck
    });

    if(data.format.validationEnabled){
      // Valid
      dispatch({
        type: DECK_VALIDATOR.VALID_CARD_AMOUNT,
        payload: {
          deckId: data.deckId,
          cardGroup: responseData.cardGroup,
        }
      });
      dispatch({
        type: DECK_VALIDATOR.VALID_DECK,
        payload: {
          deckId: data.deckId
        }
      });
    }

    done();
  }
});

export const deleteCard = createLogic({
  type: DELETE_CARD.START,

  processOptions: {
    successType: DELETE_CARD.SUCCESS,
    failType: DELETE_CARD.ERROR,
  },

  process({httpClient, action}) {
    const { elementId } = action.payload;

    return httpClient.delete(`/api/v1/decks/cards/${elementId}`)
      .then(() => action.payload);
  }
});

export const deleteCardSuccess = createLogic({
  type: DELETE_CARD.SUCCESS,

  process({ getState, action}, dispatch, done) {
    const data = action.payload;

    const actualDeck  = getState().deckCards.data;
    const nextDeck    = actualDeck.slice();

    const cardIndex   = actualDeck[data.formatSectionIndex].findIndex(card => card.cardId === data.card.cardId);
    const amount      = actualDeck[data.formatSectionIndex][cardIndex].amount;

    nextDeck[data.formatSectionIndex].splice(cardIndex, 1);

    const cardGroupId = data.card.cardGroup.id;
    const cardAmount  = Deck.getCardAmount(cardGroupId, nextDeck);

    // Update deck
    dispatch({
      type: UPDATE_DECK_CARDS,
      payload: nextDeck
    });
    dispatch(add({
      text: `-${amount} ${data.card.name} (Total: ${cardAmount})`,
      type: 'danger',
      key: 'deck',
    }));

    if(data.format.validationEnabled) {
      // Valid
      dispatch({
        type: DECK_VALIDATOR.VALID_ALL,
        payload: {
          deckId: data.deckId
        }
      });
    }

    done();
  }
});

export const deleteAllCards = createLogic({
  type: DELETE_ALL_CARDS.START,

  processOptions: {
    successType: DELETE_ALL_CARDS.SUCCESS,
    failType: DELETE_ALL_CARDS.ERROR,
  },

  process({httpClient, action}) {
    const { deckId } = action.payload;

    return httpClient.delete(`/api/v1/decks/${deckId}/cards`)
      .then(() => action.payload);
  }
});

export default {
  validCardAmount,
  validDeck,
  validAll,
  validation,

  get,
  updateDeckCardBasedOnFormat,

  addCard,
  addCardSuccess,
  addCustomCard,
  addCustomCardSuccess,

  deleteCard,
  deleteCardSuccess,
  deleteAllCards,

  changeQtyCard,
  changeQtyCardUpdate,
  changeQtyCardDebounce,
  changeQtyCardSuccess,
};
