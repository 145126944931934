import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import ModalAddTeam from '../../../ModifyTeam/ModalAddTeam';

import {addTournamentTeam, clearAddTournamentTeam} from '../../../../actions/tournamentTeams';

class ModalAddTeamContainer extends React.Component {
  componentDidUpdate(prevProps) {
    if(prevProps.data.isPending === true && this.props.data.isPending === false) {
      // Save action was ended
      this.onSubmitEnd();
    }
  }

  componentWillUnmount() {
    this.props.clearAddTournamentTeam();
  }

  onSubmit = (data) => {
    const {tournamentId, addTournamentTeam} = this.props;

    addTournamentTeam(tournamentId, data);
  };

  onSubmitEnd() {
    const {data, onClose} = this.props;

    if(data.isSuccess) {
      // If save action was end with success, close this modal
      onClose();
    }
  }

  render() {
    const {deckFormats, data, onClose} = this.props;
    const errors = data.error ? data.error.extras : null;

    return (
      <ModalAddTeam
        players={deckFormats.map(item => ({
          firstName: '',
          lastName: '',
          dciNumber: '',
          deckFormat: item,
        }))}
        errors={errors}
        onSubmit={this.onSubmit}
        onCancel={onClose}/>
    );
  }
}

ModalAddTeamContainer.propTypes = {
  deckFormats: PropTypes.arrayOf(PropTypes.string).isRequired,
  tournamentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,

  data: PropTypes.object.isRequired,
  addTournamentTeam: PropTypes.func.isRequired,
  clearAddTournamentTeam: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    data: state.tournamentTeams.add,
  }),
  {
    addTournamentTeam,
    clearAddTournamentTeam,
  }
)(ModalAddTeamContainer);
