import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import twitchOAuthHandler from '../../services/twitchOAuthHandler';
import Spinner from '../Spinner';
import { getLinkedAccounts, linkAccount, unlinkAccount } from '../../actions/linkedAccountActions';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import twitchIcon from '../../assets/images/twitch-icon.png';
import './LinkedAccounts.scss';
import ConfirmModal from '../Generic/Modals/ConfirmModal';

const TWITCH_OAUTH_REDIRECT_PATH = '/manage-account/linked-accounts/twitch-callback';

class LinkedAccounts extends React.Component {
  providers = [
    {
      icon: twitchIcon,
      provider: 'twitch',
      link: this.openTwitchAuthPage
    },
  ];

  state = {
    isModalOpen: false,
    uninkingAccouuntId: null
  }

  componentDidMount() {
    const { linkedAccounts, getLinkedAccounts } = this.props;

    if (window.location.pathname === TWITCH_OAUTH_REDIRECT_PATH) {
      this.processTwitchOAuthResponse();
    } else if (linkedAccounts.data === null) {
      getLinkedAccounts();
    }
  }

  processTwitchOAuthResponse = () => {
    const code = twitchOAuthHandler.processAccessCodeCallback();
    this.props.linkAccount(code, 'twitch');
  }

  renderAccounts = (accounts) => {
    return accounts.map((item, index) => {
      return (
        <div className="account-card" key={index}>
          <div>
            <img className="account-icon" src={item.icon} />
            <span className="account-card-text">{item.provider}</span>
          </div>
          <div>
            {item.info && <span className="account-card-text account-card-text--blue">{item.info.username}</span>}
            {item.info
              ? <button onClick={(() => this.setState({ uninkingAccouuntId: item.id, isModalOpen: true }))} type="button" className="btn btn-primary">Unlink</button>
              : <button onClick={() => item.link()} type="button" className="btn btn-primary">Link</button>
            }
          </div>
        </div>
      );
    });
  };

  mapAccounts = () => {
    const { linkedAccounts } = this.props;

    return this.providers.map((item) => {
      const account = linkedAccounts.data && linkedAccounts.data.find(acc => acc.provider == item.provider);
      return account ? { ...item, id: account.id, info: account.info } : item;
    });
  }

  openTwitchAuthPage() {
    window.location.replace(twitchOAuthHandler.urlForAuthCodeGrant(TWITCH_OAUTH_REDIRECT_PATH, ['user:read:broadcast', 'user:read:email']));
  }

  unLinkAccount = () => {
    const { unlinkAccount } = this.props;
    const { uninkingAccouuntId } = this.state;

    unlinkAccount(uninkingAccouuntId);
    this.onCloseModal();
  }

  onCloseModal = () => {
    this.setState({ isModalOpen: false, uninkingAccouuntId: null });
  }

  render() {
    const { linkedAccounts, intl } = this.props;
    const { isModalOpen } = this.state;
    const mappedAccounts = this.mapAccounts();

    if (linkedAccounts.isPending) {
      return (
        <div className="text-center">
          <p>Linking accounts</p>
          <Spinner />
        </div>
      );
    }

    return (
      <Fragment>
        <ConfirmModal
          isOpen={isModalOpen}
          onClick={() => this.unLinkAccount()}
          onClose={() => this.onCloseModal()}
          headerText={intl.formatMessage({ id: 'linked.accounts.delete.header' })}
          bodyText={intl.formatMessage({ id: 'linked.accounts.delete.body' })}
        />
        <div className="linked-accounts">
          {this.renderAccounts(mappedAccounts)}
        </div>
      </Fragment>
    );
  }
}

LinkedAccounts.propTypes = {
  getLinkedAccounts: PropTypes.func,
  linkAccount: PropTypes.func,
  unlinkAccount: PropTypes.func,
  linkedAccounts: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(connect(
  (state) => ({
    linkedAccounts: state.linkedAccounts
  }),
  ({
    getLinkedAccounts,
    linkAccount,
    unlinkAccount
  })
)(LinkedAccounts));

