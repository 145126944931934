import { createLogic } from 'redux-logic';
import { replace, push } from 'react-router-redux';

import {TOURNAMENT_USER, TOURNAMENT_SHADOW_USER_UPDATE} from '../actions/types';
import * as tournamentUserActions from '../actions/tournamentUserActions';
import * as notificationActions from '../actions/notificationActions';
import {getTournamentPlayers} from '../actions/tournamentMembers';

export const fetch = createLogic({
  type: TOURNAMENT_USER.GET,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_USER.SET,
    failType: TOURNAMENT_USER.SET_ERROR,
  },

  process({httpClient, action}) {
    const payload = action.payload;

    return httpClient.get(`/api/v1/tournaments/${payload.tournamentId}/players/${payload.userId}`)
      .then(res => res.data);
  }
});

export const create = createLogic({
  type: TOURNAMENT_USER.CREATE,
  latest: true,

  process({httpClient, action}, dispatch, done) {
    const payload = action.payload;
    return httpClient.post(`/api/v1/tournaments_register/${payload.tournamentId}/players`, payload.data)
      .then(res => res.data)
      .then((playerRes) => {
        dispatch(notificationActions.add({
          type: 'success',
          text: 'User successfully created',
        }));

        dispatch(getTournamentPlayers(payload.tournamentId));
        dispatch(tournamentUserActions.createUserError(null));

        if(payload.redirectUrl){
          dispatch(replace(payload.redirectUrl.replace(':userId', playerRes.user.id)));
        }

        if(payload.callback){
          payload.callback();
        }
      })
      .catch((err) => {
        if(payload.redirectUrl){
          dispatch(notificationActions.add({
            type: 'danger',
            text: 'Error',
          }));  
        }
        if(payload.callback){
          dispatch(tournamentUserActions.createUserError(err.response.data));
        }
      })
      .then(done);
  }
});

export const saveDeckType = createLogic({
  type: TOURNAMENT_USER.SAVE_DECK_TYPE,
  latest: true,

  process({httpClient, action}, dispatch, done) {
    const payload = action.payload;
    const data = action.payload.biography
      ? {
        paperdeck: payload.paperdeck,
        biography: payload.biography
      }
      : {
        paperdeck: payload.paperdeck,
      };

    return httpClient.patch(`/api/v1/tournament_members/${payload.membershipId}`, data)
      .then(res => res.data)
      .then(() => {
        dispatch(notificationActions.add({
          type: 'success',
          text: 'Successfully updated player\'s informations!',
        }));

        if(payload.redirectUrl){
          dispatch(push(payload.redirectUrl));
        }
      })
      .catch(() => {
        dispatch(notificationActions.add({
          type: 'danger',
          text: 'Error',
        }));
      })
      .then(done);
  }
});

export const createDeck = createLogic({
  type: TOURNAMENT_USER.CREATE_DECK.START,
  latest: true,

  process({httpClient, action}, dispatch, done) {
    const payload = action.payload;

    const requests = {
      createMasterDeck: () => httpClient.post(`/api/v1/tournaments/${payload.tournamentId}/players/${payload.userId}/decks`, {
        'name': '',
        'notes': '',
        'customFormat': '',
        'comment': []
      }),
      createDraft: (masterDeckId) => httpClient.post(`/api/v1/decks/${masterDeckId}/draft`),
    };

    if (!payload.masterDeckId) {
      return requests.createMasterDeck()
        .then(res => res.data)
        .then(data => {
          dispatch(tournamentUserActions.update({
            data: { deck: { id: data.id } },
          }));

          return requests.createDraft(data.id);
        })
        .then(res => res.data)
        .then((data) => {
          dispatch(tournamentUserActions.createDeckSuccess(data));
        })
        .catch((err) => {
          dispatch(tournamentUserActions.createDeckError(err));
        })
        .then(done);
    }

    return requests.createDraft(payload.masterDeckId)
      .then(res => res.data)
      .then((data) => {
        dispatch(tournamentUserActions.createDeckSuccess(data));
      })
      .catch((err) => {
        dispatch(tournamentUserActions.createDeckError(err));
      })
      .then(done);
  }
});

export const updateShadowUser = createLogic({
  type: TOURNAMENT_SHADOW_USER_UPDATE.START,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_SHADOW_USER_UPDATE.SUCCESS,
    failType: TOURNAMENT_SHADOW_USER_UPDATE.ERROR,
  },

  process({ httpClient, action }) {
    const { tournamentId, userId, data } = action.payload;
    return httpClient.patch(`/api/v1/tournaments/${tournamentId}/shadow_players/${userId}`, data)
      .then(res => res.data);
  }
});

export default {
  fetch,
  create,
  saveDeckType,
  createDeck,
  updateShadowUser,
};
