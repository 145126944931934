import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import TopBar from '../Layout/TopBar';
import LoginForm from './Login/LoginForm';

import logo from '../../assets/images/logo.svg';

class Login extends React.Component {
  render() {
    const {location} = this.props;
    const redirectOnSuccess = location.state ? location.state.from.pathname : '/';

    return (
      <Fragment>
        <TopBar mobileBreakpoint={null} />
        <div className="login-container text-center container fix-topbar-padding">
          <div className="mt-5">
            <div className="login-logo">
              <img src={logo} alt="cardboard live"/>
            </div>

            <div className="row">
              <div className="col-sm-8 col-md-6 col-lg-4 mx-auto">
                <LoginForm redirectOnSuccess={redirectOnSuccess}/>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Login;
