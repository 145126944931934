import React from 'react';
import PropTypes from 'prop-types';
import linkIco from '../../../assets/images/svg/link.svg';
// import { renderManaCost } from '../../../components/DeckBuilder/CardSearch/ManaCost';
import { Link } from 'react-router-dom';
import manaIcons from '../../DeckBuilder/CardSearch/manaIcons';
import classnames from 'classnames';

import './RecentDecks.scss';

class RecentDecks extends React.Component {
  state = {
    isVisible: false,
  };

  renderManaCost = (item, index) => {
    return <img className="mana-cost" key={index} src={manaIcons[item]} alt="" />;
  }

  toggleDropdown = () => {
    this.setState((state) => ({
      isVisible: !state.isVisible
    }));
  }

  renderDecks = () => {
    const {decks} = this.props;

    if(!decks) {
      return null;
    }

    return decks.map((item, index) => {

      if (index < 5) {
        return (
          <Link to={`/shared-deck/${item.deck.id}?channel_id=${this.props.channelId}`} target="_blank" key={index} className="recent-deck-item">
            <span className="deck-name">
              <img className="copylink-ico" src={linkIco}/>
              <span>{item.displayName ? item.displayName : item.deck.name}</span>
            </span>
            <span>
              {item.colors.map((item, index) => {
                return this.renderManaCost(item, index);
              })}
            </span>
          </Link>
        );
      }
    });
  }
  render() {
    const {isVisible} = this.state;
    const {decks} = this.props;
    const iconClass = classnames('fa d-inline-block d-lg-none d-xl-none open-decks-arrow',
      {
        'fa-chevron-circle-down': isVisible,
        'fa-chevron-circle-left': !isVisible
      });

    if(!decks || decks.length < 1) {
      return null;
    }

    return (
      <div className="recent-deck" onClick={() => this.toggleDropdown()}>
        <div className="container container-mobile">
          <div className="recent-deck-text">
            <span className="text-desktop">Previously streamed decks:</span>
            <span className="text-mobile">
              {isVisible ? 'Hide previously streamed decks:' : 'Show previously streamed decks:'}
            </span>
            <i className={iconClass}></i>
          </div>
        </div>
        <div className={classnames('recent-deck-container container', {
          visible: isVisible
        })}>
          {this.renderDecks()}
        </div>
      </div>

    );
  }
}

RecentDecks.propTypes = {
  decks: PropTypes.array,
  channelId: PropTypes.string
};

export default RecentDecks;
