import {
  STREAMERS,
} from './types';

export const getStreamersList = () => ({
  type: STREAMERS.GET.START,
});

export const addStreamer = (data) => ({
  type: STREAMERS.ADD.START,
  payload: data
});

export const deleteStreamer = (id) => ({
  type: STREAMERS.DELETE.START,
  payload: {
    id: id
  }
});

export const updateStreamer = (id, key, value) => ({
  type: STREAMERS.UPDATE.START,
  payload: {
    id,
    value,
    key,
  }
});
