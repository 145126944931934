import {SUBMIT_DECK_FOR_TOURNAMENT} from '../../actions/types';

const initialState = null;

export default (state = initialState, action) => {
  switch(action.type) {
    case SUBMIT_DECK_FOR_TOURNAMENT.SELECT_TOURNAMENT:
      return action.payload;
    default:
      return state;
  }
};
