import React from 'react';
import PropTypes from 'prop-types';
import DateFormatted from '../../DateFormatted/DateFormatted';

import DeckService from '../../../services/Deck';

import {sortByCMCWithXLast, sortByName} from '../../../services/DeckCards';

class PrintTournamentPlayerView extends React.Component {
  render() {
    const {data, className} = this.props;

    if(!data) {
      return null;
    }

    const allCards = data.cards.sort((a,b) => sortByCMCWithXLast(a, b) || sortByName(a,b));

    const maindeck = allCards.filter(item => item.type === 'maindeck');
    const sideboard = allCards.filter(item => item.type === 'sideboard');
    const maindeckGroupedByType = DeckService.groupByTypes(maindeck);
    const sideboardGroupedByType = DeckService.groupByTypes(sideboard);

    return (
      <div className={className}>
        <ul>
          <li>
            <strong>Player Name:</strong> <span>{data.user.firstName} {data.user.lastName}</span>
          </li>
          <li>
            <strong>DCI:</strong> <span>{data.user.dciNumber}</span>
          </li>
          <li>
            <strong>Deck Name:</strong> <span>{data.deck.name}</span>
          </li>
          <li>
            <strong>Format:</strong> <span>{data.deck.format}</span>
          </li>
          <li>
            <strong>Tournament:</strong> <span>{data.tournament.name} - <DateFormatted value={data.tournament.startDate}/></span>
          </li>
        </ul>

        <div>
          <strong>Maindeck</strong>
          <div className="deck-list">
            <ul className="card-list">
              {
                maindeckGroupedByType.map(group => {
                  return group.cards.map(card => {
                    return <li key={card.id}><span>{card.amount}</span> <span>{card.card.name}{card.custom && ' [Custom]'}</span></li>;
                  });
                })
              }
            </ul>
          </div>
        </div>

        <div>
          <strong>Sideboard</strong>
          <div className="deck-list">
            <ul className="card-list">
              {
                sideboardGroupedByType.map(group => {
                  return group.cards.map(card => {
                    return <li key={card.id}><span>{card.amount}</span> <span>{card.card.name}{card.custom && ' [Custom]'}</span></li>;
                  });
                })
              }
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

PrintTournamentPlayerView.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};

export default PrintTournamentPlayerView;
