import {createLogic} from 'redux-logic';
import {replace} from 'react-router-redux';

import {UNREGISTERED_PLAYER} from '../../actions/types';
import actions from '../../actions/unregisteredPlayer';

export const initDeckCreator = createLogic({
  type: UNREGISTERED_PLAYER.INIT_DECK_CREATOR.START,

  process({httpClient, action}, dispatch, done) {
    const {tournamentId} = action.payload;

    return Promise.all([
      httpClient.get('/api/v1/me'),
      httpClient.get(`api/v1/me/tournaments/${tournamentId}/decks`)
    ])
      .then(([resMe, resDeck]) => [resMe.data, resDeck.data])
      .then(([dataMe, dataDeck]) => {
        dispatch(actions.setPlayer(dataMe));

        return dataDeck;
      })
      .then(dataDeck => {
        return httpClient.post(`api/v1/decks/${dataDeck.id}/draft`);
      })
      .then(res => res.data)
      .then(dataDraft => {
        dispatch(actions.setDeck(dataDraft));
        dispatch(actions.initDeckCreatorSuccess());
      })
      .catch(err => {
        dispatch(actions.initDeckCreatorError(err.response));
      })
      .then(done);
  }
});

export const initDeckCreatorError = createLogic({
  type: UNREGISTERED_PLAYER.INIT_DECK_CREATOR.ERROR,

  process({action}, dispatch, done) {
    const response = action.payload;

    if(response.status === 401){
      dispatch(replace('/'));
    }

    done();
  }
});

export default [
  initDeckCreator,
  initDeckCreatorError,
];
