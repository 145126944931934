import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import GenericTooltip from '../components/GenericTooltip';

class DeckSelect extends React.Component {
  renderDeckOption(option) {
    return (
      <div>
        {option.label} <small>{option.format}</small>

        {
          !option.valid &&
          <div className="float-right">
            <GenericTooltip id={`option-${option.value}`} placement={'left'} message={'This deck is not valid'}/>
          </div>
        }
      </div>
    );
  }

  renderDeckValue(option) {
    return (
      <div>
        {option.label} <small>{option.format}</small>

        {
          !option.valid &&
          <div className="float-right" style={{paddingRight: 20}}>
            <GenericTooltip id={`value-${option.value}`} placement={'left'} message={'This deck is not valid'}/>
          </div>
        }
      </div>
    );
  }

  render() {
    const {options, value, onChange, placeholder} = this.props;

    const optionsData = options.map(item => ({
      ...item,
      label: item.name,
    }));

    return (
      <Select
        options={optionsData}
        optionRenderer={this.renderDeckOption}
        valueRenderer={this.renderDeckValue}
        value={value}
        onChange={(item) => onChange(item.value)}
        searchable={false}
        clearable={false}
        placeholder={placeholder}
      />
    );
  }
}

DeckSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    format: PropTypes.string,
    valid: PropTypes.bool.isRequired,
  })),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default DeckSelect;
