import React from 'react';

import errorImage from '../../../assets/images/general-error.png';
import { FormattedMessage } from 'react-intl';

class NoDataError extends React.Component {
  render(){
    return(
        <div className="row justify-content-center">
          <div className="col-lg-6 d-flex align-items-center flex-column">
            <img src={errorImage} alt=""/>
            <div className="py-5">
              <p className="text-center h5"><FormattedMessage id="metrics.noDataError.header"/></p>
              <p className="text-center h6"><FormattedMessage id="metrics.noDataError.description"/></p>
            </div>
          </div>
        </div>
    );
  }
}

export default NoDataError;
