import {
  DECK_CARDS,
  ADD_CARD,
  DELETE_CARD,
  DELETE_ALL_CARDS,
  CHANGE_QTY,
  UPDATE_DECK_CARDS,
} from '../actions/types';

const initialState = {
  data: null,
  isLoad: false,
  isError: false,
  error: null,
  postedCard: {},
  wholeCard: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DECK_CARDS.FETCH:
      return {
        ...state,
        isLoad: true,
        isError: false,
        error: null,
      };
    case DECK_CARDS.ERROR:
      return {
        ...state,
        isLoad: false,
        isError: true,
        error: action.payload
      };
    case DECK_CARDS.SUCCESS:
      return {
        ...state,
        isLoad: false,
        isError: false,
        error: null,
        data: action.payload,
      };
    case ADD_CARD.START:
      return {
        ...state,
        isLoad: true,
        isError: false,
        error: null,
        wholeCard: action.wholeCard,
      };
    case DELETE_CARD.START:
      return {
        ...state,
        isLoad: true,
        isError: false,
        error: null,
      };
    case CHANGE_QTY.START:
      return {
        ...state,
        isLoad: true,
        isError: true,
        error: null,
      };
    case UPDATE_DECK_CARDS:
      return {
        ...state,
        isLoad: false,
        data: action.payload,
      };
    case DELETE_ALL_CARDS.START:
      return {
        ...state,
        isLoad: true,
        isError: false,
        error: null,
      };
    case DELETE_ALL_CARDS.SUCCESS:
      return {
        ...state,
        isLoad: false,
        isError: false,
        error: null,
        data: state.data.map(() => {
          return [];
        })
      };
    case DELETE_ALL_CARDS.ERROR:
      return {
        ...state,
        isLoad: false,
        isError: true,
        error: null,
      };
    default:
      return state;
  }
};
