import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import moment from 'moment/moment';

import {roles} from '../const';
import {getTournamentByPasscode, joinTournament} from '../../actions/tournamentActions';
import {setUserRole} from '../../actions/userActions';
import TournamentList from '../Tournament/TournamentList/TournamentList';

import './JoinTournament.scss';

class JoinTournament extends React.Component {
  state = {
    isOpen: true,
  };

  componentDidMount() {
    const {params} = this.props.match;
    const {user} = this.props;

    const userRole = user.userRole;
    const userRoles = user.data.roles;

    if(userRole !== roles.player && userRoles.includes(roles.player)) {
      this.props.setUserRole(roles.player);
    }

    this.props.getTournamentByPasscode(params.passcode);
  }

  closeModal = () => {
    this.props.history.push('/tournaments');
  };

  join = () => {
    const {params} = this.props.match;

    this.props.joinTournament(params.passcode);
    this.props.history.push('/tournaments');
  };

  renderErrorView(messageId) {
    return (
      <React.Fragment>
        <TournamentList history={this.props.history} match={this.props.match} />

        <Modal isOpen={this.state.isOpen}>
          <ModalHeader toggle={this.closeModal}>
            <FormattedMessage id="joinTournamentLink.modal.header"/>
          </ModalHeader>
          <ModalBody>
            <FormattedMessage id={`joinTournamentLink.modal.${messageId}`}/>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <button className="btn btn-outline-secondary" onClick={this.closeModal}>
              <FormattedMessage id="joinTournamentLink.modal.cancel"/>
            </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }

  renderUserNoAccess() {
    return this.renderErrorView('userRoleError');
  }

  renderTournamentNotExists() {
    return this.renderErrorView('tournamentNotExists');
  }

  renderLoading() {
    return null;
  }

  render() {
    const {user, tournament} = this.props;
    const userRoles = user.data.roles;

    if(!userRoles.includes(roles.player)){
      return this.renderUserNoAccess();
    }

    if(!tournament.isLoad && tournament.isError) {
      return this.renderTournamentNotExists();
    }

    if(!tournament.data || tournament.isLoad) {
      return this.renderLoading();
    }

    const isStarted = tournament.data.status !== 'not_started';

    return (
      <React.Fragment>
        <TournamentList history={this.props.history} match={this.props.match} />

        <Modal isOpen={this.state.isOpen} size="lg" className="join-tournament-modal">
          <ModalHeader toggle={this.closeModal}>
            <FormattedMessage id="joinTournamentLink.modal.header"/>
          </ModalHeader>
          <ModalBody>
            <div className="row tournament-info">
              <div className="col-12 col-sm-7 col-lg-9">{tournament.data.name}</div>
              <div className="col-6 col-sm-2 col-lg-1">{moment(tournament.data.startDate).format('MM.D')}</div>
              <div className="col-6 col-sm-3 col-lg-2">{tournament.data.city ? tournament.data.city : '-'}</div>
            </div>
            <div className="text-center">
              {
                !isStarted &&
                <React.Fragment>
                  <h5><FormattedMessage id="joinTournamentLink.modal.p1"/></h5>
                  <h6><FormattedMessage id="joinTournamentLink.modal.p2"/></h6>
                </React.Fragment>
              }
              {
                isStarted &&
                <React.Fragment>
                  <h5><FormattedMessage id="joinTournamentLink.modal.statusError.p1"/></h5>
                  <h6><FormattedMessage id="joinTournamentLink.modal.statusError.p2"/></h6>
                </React.Fragment>
              }
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            {
              !isStarted &&
              <React.Fragment>
                <button className="btn btn-success" onClick={this.join}>
                  <FormattedMessage id="joinTournamentLink.modal.submit"/>
                </button>
                <button className="btn btn-outline-secondary" onClick={this.closeModal}>
                  <FormattedMessage id="joinTournamentLink.modal.cancel"/>
                </button>
              </React.Fragment>
            }
            {
              isStarted &&
              <React.Fragment>
                <button className="btn btn-outline-secondary" onClick={this.closeModal}>
                  <FormattedMessage id="joinTournamentLink.modal.cancel"/>
                </button>
              </React.Fragment>
            }
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

JoinTournament.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  tournament: PropTypes.object.isRequired,
  getTournamentByPasscode: PropTypes.func.isRequired,
  setUserRole: PropTypes.func.isRequired,
  joinTournament: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    user: state.user,
    tournament: state.tournament
  }),
  {
    getTournamentByPasscode,
    setUserRole,
    joinTournament,
  }
)(JoinTournament);
