import * as React from 'react';
import { createLogic } from 'redux-logic';
import {replace} from 'react-router-redux';

import {
  TOURNAMENT_PLAYERS,
  TOURNAMENT_JUDGES,
  TOURNAMENT_ORGANIZERS,
  TOURNAMENT_PLAYERS_SEARCH,
  TOURNAMENT_CASTERS,
  TOURNAMENT_CASTERS_ADD,
  TOURNAMENT_CASTERS_DELETE,
  TOURNAMENT_JUDGES_ADD,
  TOURNAMENT_JUDGES_DELETE,
  TOURNAMENT_PLAYER_DELETE,
  TOURNAMENT_ORGANIZERS_ADD,
  TOURNAMENT_ORGANIZERS_DELETE,
  TOURNAMENT_EXTRAS,
  TOURNAMENT_PLAYERS_UPDATE,
} from '../actions/types';

import { add as addNotification } from '../actions/notificationActions';
import {
  getTournamentCasters as getTournamentCastersAction,
  getTournamentJudges as getTournamentJudgesAction,
  getTournamentPlayers as getTournamentPlayersAction,
  getTournamentOrganizers as getTournamentOrganizersAction,
} from '../actions/tournamentMembers';

import { getTournamentsJudge } from '../actions/tournamentActions';

import { FormattedMessage } from 'react-intl';
import * as notificationActions from '../actions/notificationActions';

export const getTournamentPlayers = createLogic({
  type: TOURNAMENT_PLAYERS.FETCH,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_PLAYERS.SUCCESS,
    failType: TOURNAMENT_PLAYERS.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get(`/api/v1/tournaments/${action.tournamentId}/players`, {
      params: {
        name: action.name,
        page: action.page,
        sort: action.sort,
        order: action.order
      }
    })
      .then();
  }
});

export const getTournamentExtras = createLogic({
  type: TOURNAMENT_EXTRAS.FETCH,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_EXTRAS.SUCCESS,
    failType: TOURNAMENT_EXTRAS.ERROR,
  },

  process({ httpClient, action }) {
    const payload = action.payload;

    return httpClient.get(`/api/v1/tournaments/${payload}/tournament_extras`)
      .then(res => res.data);
  }
});

export const updateTournamentExtras = createLogic({
  type: TOURNAMENT_EXTRAS.UPDATE,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_EXTRAS.SUCCESS,
    failType: TOURNAMENT_EXTRAS.ERROR,
  },

  process({ httpClient, action }) {
    const {tournamentId, data} = action.payload;

    return httpClient.post(`/api/v1/tournaments/${tournamentId}/tournament_extras`, data)
      .then(res => res.data);
  }
});

export const getTournamentJudges = createLogic({
  type: TOURNAMENT_JUDGES.FETCH,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_JUDGES.SUCCESS,
    failType: TOURNAMENT_JUDGES.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get(`/api/v1/tournaments/${action.tournamentId}/members?role=2`)
      .then(res => res.data);
  }
});

export const addTournamentJudges = createLogic({
  type: TOURNAMENT_JUDGES_ADD.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.put(`/api/v1/tournaments/${action.tournamentId}/judges/${action.judgeId}`)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="judges.add.success"/>
        }));
        dispatch(getTournamentJudgesAction(action.tournamentId));
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="judges.add.error"/>
        }));
      })
      .then(() => done());
  }
});

export const deleteTournamentJudge = createLogic({
  type: TOURNAMENT_JUDGES_DELETE.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.delete(`/api/v1/tournaments/${action.tournamentId}/judges/${action.judgeId}`)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="judges.delete.success"/>
        }));
        if(action.isJudgeTournaments) {
          dispatch(getTournamentsJudge(1, action.judgeId));
        } else {
          dispatch(getTournamentJudgesAction(action.tournamentId));
        }
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="judges.delete.error"/>
        }));
      })
      .then(() => done());
  }
});

export const deleteTournamentPlayer = createLogic({
  type: TOURNAMENT_PLAYER_DELETE.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.delete(`/api/v1/tournaments/${action.tournamentId}/players/${action.playerId}`)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="player.delete.success"/>
        }));

        if(action.isRefreshPlayers){
          dispatch(getTournamentPlayersAction(action.tournamentId));
        }
        if(action.redirectUrl){
          dispatch(replace(action.redirectUrl));
        }
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="player.delete.error"/>
        }));
      })
      .then(() => done());
  }
});

export const getTournamentSearchPlayers = createLogic({
  type: TOURNAMENT_PLAYERS_SEARCH.START,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_PLAYERS_SEARCH.SUCCESS,
    failType: TOURNAMENT_PLAYERS_SEARCH.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get(`/api/v1/tournaments/${action.data.tournamentId}/players`, {
      params: {
        name: action.data.name,
        page: action.page,
      }
    })
      .then();
  }
});

export const getTournamentOrganizers = createLogic({
  type: TOURNAMENT_ORGANIZERS.FETCH,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_ORGANIZERS.SUCCESS,
    failType: TOURNAMENT_ORGANIZERS.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get(`/api/v1/tournaments/${action.tournamentId}/members?role=4`)
      .then(res => res.data);
  }
});

export const addTournamentOrganizer = createLogic({
  type: TOURNAMENT_ORGANIZERS_ADD.START,

  process({ httpClient, action }, dispatch, done) {
    const {tournamentId, userId} = action.payload;

    return httpClient.put(`/api/v1/tournaments/${tournamentId}/organizers/${userId}`)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="organizer.add.success"/>
        }));
        dispatch(getTournamentOrganizersAction(tournamentId));
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="organizer.add.error"/>
        }));
      })
      .then(done);
  }
});

export const deleteTournamentOrganizer = createLogic({
  type: TOURNAMENT_ORGANIZERS_DELETE.START,

  process({ httpClient, action }, dispatch, done) {
    const {tournamentId, userId, nextActions} = action.payload;

    return httpClient.delete(`/api/v1/tournaments/${tournamentId}/organizers/${userId}`)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="organizer.delete.success"/>
        }));

        nextActions.forEach((action) => dispatch(action));
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="organizer.delete.error"/>
        }));
      })
      .then(done);
  }
});

export const getTournamentCasters = createLogic({
  type: TOURNAMENT_CASTERS.FETCH,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_CASTERS.SUCCESS,
    failType: TOURNAMENT_CASTERS.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get(`/api/v1/tournaments/${action.tournamentId}/caster_tokens`)
      .then(res => res.data);
  }
});

export const addTournamentCaster = createLogic({
  type: TOURNAMENT_CASTERS_ADD.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.post(`/api/v1/tournaments/${action.tournamentId}/caster_tokens`, action.casterDescription)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: 'Caster successfully created'
        }));
        dispatch(getTournamentCastersAction(action.tournamentId));
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: 'Error. Try again.'
        }));
      })
      .then(() => done());
  }
});

export const deleteTournamentCaster = createLogic({
  type: TOURNAMENT_CASTERS_DELETE.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.delete(`/api/v1/caster_tokens/${action.casterId}`)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: 'Caster successfully deleted'
        }));
        dispatch(getTournamentCastersAction(action.tournamentId));
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: 'Error. Try again.'
        }));
      })
      .then(() => done());
  }
});


export const tournamentPlayerUpdate = createLogic({
  type: TOURNAMENT_PLAYERS_UPDATE.START,

  process({ httpClient, action }, dispatch, done) {
    const {playerId, data} = action.payload;

    return httpClient.patch(`/api/v1/tournament_members/${playerId}`, data)
      .then(res => res.data)
      .then((resData) => {
        dispatch(notificationActions.add({
          type: 'success',
          text: 'Successfully updated player\'s informations!',
        }));

        dispatch({
          type: TOURNAMENT_PLAYERS_UPDATE.SUCCESS,
          payload: {
            playerId,
            updatedData: data,
            resData,
          }
        });
      })
      .catch(() => {
        dispatch(notificationActions.add({
          type: 'danger',
          text: 'Error',
        }));

        dispatch({
          type: TOURNAMENT_PLAYERS_UPDATE.ERROR,
        });
      })
      .then(done);
  }
});

export default {
  getTournamentPlayers,
  getTournamentExtras,
  getTournamentJudges,
  getTournamentOrganizers,
  getTournamentSearchPlayers,
  getTournamentCasters,
  addTournamentCaster,
  deleteTournamentCaster,
  addTournamentJudges,
  deleteTournamentJudge,
  deleteTournamentPlayer,
  addTournamentOrganizer,
  deleteTournamentOrganizer,
  updateTournamentExtras,
  tournamentPlayerUpdate,
};
