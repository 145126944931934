import {SUBMIT_DECK_FOR_TOURNAMENT} from '../../actions/types';

export const selectTournament = (tournament) => ({
  type: SUBMIT_DECK_FOR_TOURNAMENT.SELECT_TOURNAMENT,
  payload: tournament
});

export default {
  selectTournament,
};
