export const getSortingState = (sorting, sortedKey) => {
  if(!sorting || sorting.actualSortedKey !== sortedKey) {
    return {
      actualSortedKey: sortedKey,
      type: 'ASC'
    };
  }

  switch(sorting.type){
    case 'DESC':
      return null;
    case 'ASC':
      return {
        actualSortedKey: sortedKey,
        type: 'DESC'
      };
    default:
      return {
        actualSortedKey: sortedKey,
        type: 'ASC'
      };
  }
};