import {
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  LOGIN,
  GET_CURRENT_USER,
  LOGOUT,
} from './types';

export const changePassword = (data) => ({
  type: CHANGE_PASSWORD.START,
  payload: data
});

export const resetPassword = (data) => ({
  type: RESET_PASSWORD.START,
  payload: data,
});

export const login = (data) => ({
  type: LOGIN.START,
  payload: data,
});


export const resetPasswordReset = () => ({
  type: RESET_PASSWORD.RESET
});

export const cancelCurrentLogin = () => ({
  type: LOGIN.CANCEL_CURRENT,
});

export const logout = () => ({
  type: LOGOUT.START,
});

export const resetCurrentUser = () => ({
  type: GET_CURRENT_USER.CANCEL_CURRENT,
});

export const getMe = () => ({
  type: GET_CURRENT_USER.START,
});
