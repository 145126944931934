import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { getTournamentJudges, addTournamentJudges, deleteTournamentJudge } from '../../actions/tournamentMembers';
import PropTypes from 'prop-types';
import { getUsers, clearUsers } from '../../actions/usersActions';
import Select from 'react-select';
import DataTableWithMobileDropdown from '../DataTable/DataTableWithMobileDropdown';
import Spinner from '../Spinner';

import './Tournament.scss';

class Judges extends React.Component {
  state = {
    isOpen: false,
    judge: '',
    deleteJudgeId: null,
    isConfirmOpen: null,
    error: null,
  };

  componentDidMount(){
    this.props.getTournamentJudges(this.props.tournamentId);
  }

  submit = () => {
    this.props.addTournamentJudges(this.props.tournamentId, this.state.judge.id);
    this.toggleModal();
  }

  submitDelete = () => {
    this.props.deleteTournamentJudge(this.props.tournamentId, this.state.deleteJudgeId);
    this.toggleConfirmModal(null);
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      judge: '',
      error: null
    }, () => {
      !this.state.isOpen && this.props.clearUsers();
    });
  }

  toggleConfirmModal = (id) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      deleteJudgeId: id,
    });
  }

  onJudgeSelect = (value) => {
		this.setState({
			judge: value,
		});
  }

  onJudgeChange = (value) => {
    if(!value) {
      return this.props.clearUsers();
    }
    this.props.getUsers(1, value);
    return this.props.users && this.props.users.data;
  }

  renderOption = (option) => {
		return (
			<div>{option.data.firstName} {option.data.lastName} {option.data.email}</div>
		);
  };

  renderValue = (option) => {
    return (
			<div>{option.data.firstName} {option.data.lastName} {option.data.email}</div>
		);
  };

  renderTable = () => {
    const {tournamentMembers} = this.props;
    if(!tournamentMembers || !tournamentMembers.judges.data) {
      return null;
    }

    if(tournamentMembers.judges.data.length == 0) {
      return <p className="text-center ">There are no judges assign to this tournament.</p>;
    }

    const data = tournamentMembers.judges.data.filter(item => !item.deleted).map((item,  index) => {
      return [
        item.user.dciNumber,
        item.user.lastName,
        item.user.firstName,
        <a key={`${index}-mail`} href={`mailto:${item.user.email}`}>{item.user.email}</a>,
        <div key={`${index}-delete`} className="tournament-link text-center" onClick={() => this.toggleConfirmModal(item.user.id)}><i className="icon-delete" /></div>,
      ];
    });

    const columns = [
      {name: 'DCI', flex: 1, key: 1},
      {name: 'LAST NAME', flex: 1, key: 2},
      {name: 'FIRST NAME', flex: 1, key: 3},
      {name: 'MAIL', flex: 2, key: 4},
      {name: 'DELETE', flex: 1, key: 5, isVisibleOnMobile: false},
    ];

    const dropdownData = (dataRow, rowIndex) => [
      <span key={rowIndex + '-remove'} className="tournament-link tournament-link-mobile" onClick={() => this.toggleConfirmModal(tournamentMembers.judges.data[rowIndex].user.id)}>Delete</span>,
    ];


    return (
      <DataTableWithMobileDropdown
        data={data}
        columns={columns}
        dropdownData={dropdownData}
      />);
  };

  render() {
    const {tournamentMembers} = this.props;

    const formattedData = this.props.users && this.props.users.data && this.props.users.data.map(item => {
      return {
        label: item.firstName + ' ' + item.lastName,
        value: item.firstName + ' ' + item.lastName,
        id: item.id,
        data: item,
      };
    });

    if(!tournamentMembers.judges.data || tournamentMembers.judges.isLoad){
      return <Spinner/>;
    }

    return (
      <div>
        <h4 className="section-header mb-4">
          Judges
          <button type="button" className="btn btn-primary float-right" onClick={this.toggleModal}>
            <span className="d-none d-md-block">Add Judge</span> <i className="icon-plus d-md-none"/>
          </button>
        </h4>
          <div className="tournament-table">
            {this.renderTable()}
          </div>
          <Modal isOpen={this.state.isOpen} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>Select judge</ModalHeader>
            <ModalBody>
              <div>
                <Select
                  id="state-select"
                  ref={(ref) => { this.select = ref; }}
                  onBlurResetsInput={false}
                  onSelectResetsInput={false}
                  autoFocus
                  value={this.state.judge}
                  optionRenderer={this.renderOption}
                  valueRenderer={this.renderValue}
                  options={formattedData}
                  onChange={this.onJudgeSelect}
                  onInputChange={this.onJudgeChange}
                  searchable={true}
                  filterOptions={false}
                  matchProp={'any'}
                  inputProps={{ autoComplete: 'off', autofill: 'off', spellCheck: 'false', autoCorrect: 'off', autoCapitalize: 'off' }}
                  placeholder="Type to search..."
                />
                {this.state.error && <div className="text-danger font-weight-bold mt-2">{this.state.error}</div>}
              </div>
            </ModalBody>
            <ModalFooter>
              <button onClick={this.submit} className="btn btn-success">Add judge</button>
              <button onClick={this.toggleModal} className="btn btn-outline-secondary">Cancel</button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.isConfirmOpen} toggle={()=>this.toggleConfirmModal(null)}>
            <ModalHeader toggle={()=>this.toggleConfirmModal(null)}>Delete judge</ModalHeader>
            <ModalBody>
              Judge will be removed from this tournament. Are you sure?
            </ModalBody>
            <ModalFooter>
              <button className="btn btn-danger" onClick={this.submitDelete}>Yes</button>
              <button className="btn btn-outline-secondary" onClick={()=>this.toggleConfirmModal(null)}>No</button>
            </ModalFooter>
          </Modal>
      </div>
    );
  }
}

Judges.propTypes = {
  getTournamentJudges: PropTypes.func,
  tournamentMembers: PropTypes.object,
  tournamentId: PropTypes.string,
  users: PropTypes.object,
  getUsers: PropTypes.func,
  clearUsers: PropTypes.func,
  addTournamentJudges: PropTypes.func,
  deleteTournamentJudge: PropTypes.func,
};

export default connect(
  (state) => ({
    tournamentMembers: state.tournamentMembers,
    users: state.users && state.users.data,
  }),
  ({
    getTournamentJudges,
    getUsers,
    clearUsers,
    addTournamentJudges,
    deleteTournamentJudge
  })
)(Judges);
