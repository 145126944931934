export const getPages = (actualPage, totalPages) => {
  if(totalPages <= 6) {
    return {
      pages: Array.from({length: totalPages}, (i, id) => id+1),
      isFirst: false,
      isLast: false
    };
  }

  if(actualPage < 5) {
    return {
      pages: Array.from({length: 5}, (i, id) => id+1),
      isFirst: false,
      isLast: true
    };
  }

  if(actualPage > totalPages - 4) {
    return {
      pages: Array.from({length: 5}, (i, id) => totalPages - 4 + id),
      isFirst: true,
      isLast: false
    };
  }

  return {
    pages: Array.from({length: 5}, (i, id) => actualPage - 2 + id),
    isFirst: true,
    isLast: true
  };
};