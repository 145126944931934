import * as React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import Tabs from '../../Generic/Tabs/Tabs';

const getMaxValue = (item) => {
  switch(item.operator) {
    case 'gte':
      return `${item.value}+`;
    case 'any':
      return null;
    default:
      return item.value;
  }
};

class DeckListNav extends React.Component {
  getData() {
    const {format, groupAmounts} = this.props;

    return format.details.map((item, index) => {
      return {
        name:   <FormattedMessage id={`deckGroup.${item.variable}`}/>,
        max:    getMaxValue(item),
        amount: groupAmounts[index] || 0,
      };
    });
  }

  renderTabItem(item) {
    const value = item.max
      ? `${item.amount} / ${item.max}`
      : item.amount;

    return (
      <span>
        {item.name} {' '}
        <span className={'amount'}>{value}</span>
      </span>
    );
  }

  render() {
    const {index, onChangeIndex} = this.props;

    return (
      <Tabs className={'deck-list-nav'}
            index={index}
            onChangeIndex={onChangeIndex}
            data={this.getData()}
            renderTabItem={this.renderTabItem}/>
    );
  }
}

DeckListNav.propTypes = {
  index: PropTypes.number.isRequired,
  onChangeIndex: PropTypes.func,
  format: PropTypes.object.isRequired,
  groupAmounts: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default DeckListNav;
