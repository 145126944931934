import React from 'react';
import PropTypes from 'prop-types';

import AccessControl from '../../../Utils/AccessControl';
import Casters from '../../Casters';

import {roles} from '../../../const';

class TournamentCasters extends React.Component {
  render() {
    const {tournament} = this.props;

    return (
      <AccessControl roles={[roles.organizer, roles.admin]}>
        <Casters tournamentId={tournament.id}/>
      </AccessControl>
    );
  }
}

TournamentCasters.propTypes = {
  tournament: PropTypes.object,
};

export default TournamentCasters;
