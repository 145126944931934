import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import httpClient from '../../services/httpClient';
import Name from './Name';
import DciNumber from './DciNumber';
import ChangePassword from './ChangePassword';
import { add as addNotification } from '../../actions/notificationActions';
import { getMe } from '../../actions/authActions';
import Spinner from '../Spinner';
import {isValid} from '../../services/PasswordValidation';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import SelectImageWithPreview from '../SelectImage/SelectImageWithPreview';
import ImageCropperModal from '../ImageCropper/ImageCropperModal';

import defaultAvatar from '../../assets/images/default-avatar.png';

class ManageAccount extends React.Component {
  constructor() {
    super();

    this.state = {
      croppingModal: {
        isOpen: false,
        data: null,
      },
      isModalOpen: false,
      formStatus: {
        loading: false,
      },
      passwordFormStatus: {
        loading: false,
        error: null,
      },
      formData: {
        biography: {
          value: '',
          isValid: false,
        },
        username: {
          value: '',
          isValid: false,
        },
        oldPassword: {
          value: '',
        },
        password: {
          value: '',
          isValid: false,
        },
        passwordRepeat: {
          value: '',
          isValid: false,
        },
        email: {
          value: '',
          isValid: false,
        },
        dciNumber: {
          value: '',
          isValid: true,
        },
        firstName: {
          value: '',
          isValid: false,
        },
        lastName: {
          value: '',
          isValid: false,
        },
        judgeLevel: '1',
        roles: {
          player: false,
          organizer: false,
          judge: false,
        },
      },
      avatar: {
        url: null,
        file: null,
      }
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      formData: {
        biography: {
          value: this.props.user.biography || '',
          isValid: false,
        },
        username: {
          value: this.props.user.username,
          isValid: true,
        },
        oldPassword: {
          value: '',
          isValid: false,
        },
        password: {
          value: '',
          isValid: false,
        },
        passwordRepeat: {
          value: '',
          isValid: false,
        },
        email: {
          value: this.props.user.email,
          isValid: true,
        },
        dciNumber: {
          value: this.props.user.dciNumber,
          isValid: true,
        },
        firstName: {
          value: this.props.user.firstName,
          isValid: true,
        },
        lastName: {
          value: this.props.user.lastName,
          isValid: true,
        },
        judgeLevel: (this.props.user.judgeLevel && this.props.user.judgeLevel.toString()) || '1',
        roles: {
          player: this.checkUserRole('PLAYER'),
          judge: this.checkUserRole('JUDGE') ,
          organizer: this.checkUserRole('ORGANIZER')
        },
      },
      avatar: {
        ...this.state.avatar,
        url: this.props.user.avatar,
      },
    });
  }

  openCroppingModal = (data) => {
    this.setState({
      croppingModal: {
        isOpen: true,
        data,
      }
    });
  };

  closeCroppingModal = () => {
    this.setState({
      croppingModal: {
        isOpen: false,
        data: null,
      }
    });
  };

  onChangeAvatar = (data) => {
    this.openCroppingModal(data);
  };

  onRemoveAvatar = () => {
    this.setState({
      avatar: {
        url: null,
        file: null,
      },
    });
  };

  onSubmitAvatarCropping = (data) => {
    this.setState({
      avatar: data,
    });
    this.closeCroppingModal();
  };

  update = (entity, property, value, subproperty) => {
    this.setState(prev => ({
      [entity]: {
        ...prev[entity],
        [property]: subproperty
          ? {...prev[entity][property], [subproperty]: value}
          : value,
      },
    }));
  };

  handleChangeCheckbox = (e, property, subproperty) => {
    this.update('formData', property, e.target.checked, subproperty);
  };

  handleChangeInput = (value, property) => {
    this.update('formData', property, value, 'value');
  };

  handleChangeSelect = (e, property) => {
    this.update('formData', property, e.target.value);
  };

  validationChanged(property, value) {
    this.update('formData', property, value, 'isValid');
  }

  handleRegisterSubmit = e => {
    const {avatar} = this.state;
    const {user} = this.props;

    e.preventDefault();
    const { dciNumber, firstName, lastName, judgeLevel, roles, biography } = this.state.formData;
    const isRolesChanged = ((roles.player !== this.checkUserRole('PLAYER')) ||
                            (roles.organizer !== this.checkUserRole('ORGANIZER'))  ||
                            (roles.judge !== this.checkUserRole('JUDGE')));

    this.setState({
      ...this.state,
      formStatus: {
        loading: true,
      }
    });

    const newAccountValues = {};
    if(this.props.user.dciNumber !== dciNumber.value) {
      newAccountValues.dciNumber = dciNumber.value;
    }
    if(this.props.user.firstName !== firstName.value) {
      newAccountValues.firstName = firstName.value;
    }
    if(this.props.user.lastName !== lastName.value) {
      newAccountValues.lastName = lastName.value;
    }
    if(this.props.user.biography !== biography.value) {
      newAccountValues.biography = biography.value;
    }
    if((this.props.user.judgeLevel && this.props.user.judgeLevel.toString()) !== judgeLevel) {
      newAccountValues.judgeLevel = parseInt(judgeLevel);
    }
    if(isRolesChanged) {
      newAccountValues.roles = [];
      if(roles.player) {
        newAccountValues.roles.push('PLAYER');
      }
      if(roles.organizer) {
        newAccountValues.roles.push('ORGANIZER');
      }
      if(roles.judge) {
        newAccountValues.roles.push('JUDGE');
        newAccountValues.judgeLevel = parseInt(judgeLevel);
      }

      const isAdmin = this.checkUserRole('ADMIN');

      if(isAdmin) {
        newAccountValues.roles.push('ADMIN');
      }
    }

    const requests = [
      httpClient.patch(`/api/v1/users/${user.id}`, newAccountValues)
    ];

    if(avatar.file) {
      const formData = new FormData();
      formData.append('file', avatar.file);

      requests.push(
        httpClient.post(`/api/v1/users/${user.id}/avatar`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
      );
    }
    else if(!avatar.url && user.avatar) {
      requests.push(
        httpClient.delete(`/api/v1/users/${user.id}/avatar`)
      );
    }

    Promise.all(requests)
      .then(() => {
        this.props.addNotification({
          text: <FormattedMessage id="account.notification.success"/>,
          type: 'success',
          timeout: 4,
        });
        this.props.getMe();
        this.props.history.push('/');
      }).catch(() => {
        this.setState({
          ...this.state,
          formStatus: {
            loading: false,
          }
        });
        this.props.addNotification({
          text: <FormattedMessage id="account.notification.error"/>,
          type: 'warning',
          timeout: 4,
        });
      });
  };

  checkUserRole(role) {
    if(this.props.user.roles.indexOf(role) > -1) {
      return true;
    }

    return false;
  }

  toggleChangePasswordModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      passwordFormStatus: {
        loading: false,
        error: null,
      },
      formData: {
        ...this.state.formData,
        oldPassword: {
          value: '',
          isValid: false,
        },
        password: {
          value: '',
          isValid: false,
        },
        passwordRepeat: {
          value: '',
          isValid: false,
        },
      }
    });
  };

  submitChangePassword() {
    const newPassword = {
      plainPassword: this.state.formData.password.value,
      oldPassword: this.state.formData.oldPassword.value,
    };

    this.setState({
      ...this.state,
      passwordFormStatus: {
        loading: true,
      }
    });

    httpClient.patch(`/api/v1/users/${this.props.user.id}`, newPassword)
      .then(() => {
        this.setState({
          ...this.state,
          passwordFormStatus: {
            loading: false,
            error: null,
          }
        });
        this.props.addNotification({
          text: <FormattedMessage id="password.error5"/>,
          type: 'success',
          timeout: 4,
        });
        this.props.getMe();
        this.props.history.push('/');
      })
      .catch(() => {
        this.setState({
          ...this.state,
          passwordFormStatus: {
            loading: false,
            error: <FormattedMessage id="password.error6"/>
          }
        });
      });
  }

  renderJudgeLevels = () => {
    const {roles, judgeLevel} = this.state.formData;
    const {intl} = this.props;
    if (!roles.judge) {
      return;
    }

    return (
      <React.Fragment>
        <label htmlFor="judgeLevelSelect"><FormattedMessage id="register.label.judge.level"/></label>
        <select
          id="judgeLevelSelect"
          className={`form-control custom-select ${judgeLevel !== (this.props.user.judgeLevel && this.props.user.judgeLevel.toString()) && 'is-valid'}`}
          onChange={e => this.handleChangeSelect(e, 'judgeLevel')}
          value={judgeLevel || ''}
        >
          <option value="1">{intl.formatMessage({id: 'register.label.level1'})}</option>
          <option value="2">{intl.formatMessage({id: 'register.label.level2'})}</option>
          <option value="3">{intl.formatMessage({id: 'register.label.level3'})}</option>
        </select>
        {judgeLevel !== (this.props.user.judgeLevel && this.props.user.judgeLevel.toString()) && <div className="valid-feedback"><FormattedMessage id="label.edited"/></div>}
      </React.Fragment>
    );
  };

  render() {
    const {
      dciNumber,
      firstName,
      lastName,
      roles,
      oldPassword,
      password,
      passwordRepeat,
      biography,
    } = this.state.formData;

    const {croppingModal, avatar} = this.state;
    const {intl} = this.props;

    const isRolesChanged = ((this.state.formData.roles.player !== this.checkUserRole('PLAYER')) ||
                            (this.state.formData.roles.organizer !== this.checkUserRole('ORGANIZER'))  ||
                            (this.state.formData.roles.judge !== this.checkUserRole('JUDGE')));

    const submitDisabled =
      !dciNumber.isValid ||
      !firstName.isValid ||
      !lastName.isValid ||
      (!roles.judge && !roles.organizer && !roles.player);

    const isPasswordValid = isValid(oldPassword.value, password.value, passwordRepeat.value);

    return (
      <React.Fragment>
        <div className="my-5 manage-account">
              <div className="d-flex justify-content-between">
                {this.state.formStatus.loading && <Spinner size={20} />}
              </div>
              <form className="manage-account-form" onSubmit={this.handleRegisterSubmit}>
                <div className="form-row mb-3">
                  <div className="form-group col-md-5">
                    <label htmlFor="user name"><FormattedMessage id="account.username"/></label>
                    <input
                      type="text"
                      className="form-control register-form__input"
                      value={this.props.user.username}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-row mb-3">
                  <div className="form-group col-md-5">
                    <button type="button" className="btn btn-primary btn-block" onClick={() => this.toggleChangePasswordModal()}><FormattedMessage id="account.change.password"/></button>
                  </div>
                </div>
                <div className="form-row mb-3">
                  <div className="form-group col-md-5">
                    <label htmlFor="email"><FormattedMessage id="account.email"/></label>
                    <input
                      type="text"
                      className="form-control register-form__input"
                      value={this.props.user.email}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-5 offset-md-1">
                    <label htmlFor="dci number"><FormattedMessage id="account.dci"/></label>
                    <DciNumber
                      value={this.state.formData.dciNumber.value || ''}
                      initialValue={this.props.user.dciNumber || ''}
                      onChange={value => this.handleChangeInput(value, 'dciNumber')}
                      onValidationChanged={newValidationValue =>
                        this.validationChanged('dciNumber', newValidationValue)
                      }
                      roles={this.state.formData.roles}
                      isRequired={this.state.formData.roles.player || this.state.formData.roles.judge}
                    />
                  </div>
                </div>
                <div className="form-row mb-3">
                  <div className="form-group col-md-5">
                    <label htmlFor="first name"><FormattedMessage id="account.first.name"/></label>
                    <Name
                      value={this.state.formData.firstName.value}
                      initialValue={this.props.user.firstName}
                      onChange={value => this.handleChangeInput(value, 'firstName')}
                      onValidationChanged={newValidationValue =>
                        this.validationChanged('firstName', newValidationValue)
                      }
                    />
                  </div>
                  <div className="form-group col-md-5 offset-md-1">
                    <label htmlFor="last name"><FormattedMessage id="account.last.name"/></label>
                    <Name
                      value={this.state.formData.lastName.value}
                      initialValue={this.props.user.lastName}
                      onChange={value => this.handleChangeInput(value, 'lastName')}
                      onValidationChanged={newValidationValue =>
                        this.validationChanged('lastName', newValidationValue)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description"><FormattedMessage id="account.avatar.label"/></label>

                    <SelectImageWithPreview image={avatar.url}
                                            defaultImage={defaultAvatar}
                                            onChange={this.onChangeAvatar}
                                            onRemove={this.onRemoveAvatar}
                                            buttonText={intl.messages['account.avatar.button']}/>
                  </div>
                  <div className="form-group col-md-11">
                    <label htmlFor="description"><FormattedMessage id="account.biography"/></label>
                    <textarea
                      className="form-control"
                      rows="5"
                      value={biography.value}
                      onChange={e => this.handleChangeInput(e.target.value, 'biography')}
                    />
                  </div>

                </div>
                <div className="form-row mb-3">
                  <div className="form-group col-md-5">
                    <label htmlFor="">
                      <FormattedMessage id="account.role.text"/>
                    </label>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="form-check__player"
                      >
                        <input
                          className="form-check-input"
                          id="form-check__player"
                          type="checkbox"
                          checked={this.state.formData.roles.player}
                          onChange={e =>
                            this.handleChangeCheckbox(e, 'roles', 'player')
                          }
                        />
                        <FormattedMessage id="account.role.player"/>
                      </label>
                    </div>
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor="form-check__organizer"
                      >
                        <input
                          className="form-check-input"
                          id="form-check__organizer"
                          type="checkbox"
                          checked={this.state.formData.roles.organizer}
                          onChange={e =>
                            this.handleChangeCheckbox(e, 'roles', 'organizer')
                          }
                        />
                        <FormattedMessage id="account.role.organizer"/>
                      </label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="form-check__judge">
                        <input
                          className="form-check-input"
                          id="form-check__judge"
                          type="checkbox"
                          checked={this.state.formData.roles.judge}
                          onChange={e =>
                            this.handleChangeCheckbox(e, 'roles', 'judge')
                          }
                        />
                        <FormattedMessage id="account.role.judge"/>
                      </label>
                    </div>
                    {isRolesChanged && <div className="valid-feedback d-block"><FormattedMessage id="label.edited"/></div>}
                  </div>
                  <div className="form-group col-md-5 offset-md-1">{this.renderJudgeLevels()}</div>
                </div>
                <p><FormattedMessage id="account.obligatory"/></p>
                <div className="form-row">
                  <div className="col-md-3 offset-md-6">
                    <button
                      className="btn btn-success btn-block"
                      type="submit"
                      disabled={submitDisabled}
                      >
                      <FormattedMessage id="account.proceed"/>
                    </button>
                  </div>
                </div>
              </form>
          </div>
        <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleChangePasswordModal(null)}>
          <ModalHeader toggle={() => this.toggleChangePasswordModal(null)}>
            <div className="d-flex align-items-center">
              <FormattedMessage id="account.change.password"/>
              {this.state.passwordFormStatus.loading && <Spinner size={20} class="d-flex align-items-center ml-3"/>}
            </div>
          </ModalHeader>
          <ModalBody>
            <ChangePassword
              oldPassValue={oldPassword.value}
              passValue={password.value}
              repeatPassValue={passwordRepeat.value}
              onChange={(value, type) => this.handleChangeInput(value, type)}
              onValidationChanged={(element, newValidationValue) =>
                this.validationChanged(element, newValidationValue)
              }
              submitError={this.state.passwordFormStatus.error}
            />
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-success" disabled={!isPasswordValid} onClick={() => this.submitChangePassword()}><FormattedMessage id="btn.change"/></button>
            <button className="btn btn-outline-secondary" onClick={() => this.toggleChangePasswordModal(null)}><FormattedMessage id="tournaments.btn.cancel"/></button>
          </ModalFooter>
        </Modal>

        <ImageCropperModal isOpen={croppingModal.isOpen}
                           onClose={this.closeCroppingModal}
                           title={intl.messages['account.avatar.cropping.header']}
                           src={croppingModal.data && croppingModal.data.imageUrl}
                           bestFit
                           onSubmit={this.onSubmitAvatarCropping}
                           cancelButton={{
                             text: intl.messages['account.avatar.cropping.cancel'],
                             className: 'btn-secondary'
                           }}
                           submitButton={{
                             text: intl.messages['account.avatar.cropping.save'],
                             className: 'btn-success'
                           }}/>
      </React.Fragment>
    );
  }
}
ManageAccount.propTypes = {
  user: PropTypes.object,
  addNotification: PropTypes.func,
  getMe: PropTypes.func,
  history: PropTypes.object,
  intl: PropTypes.object,
};
export default injectIntl(withRouter(connect(((state) => ({
  user: state.user.data,
})),  (dispatch) => ({
  getMe: () => dispatch(getMe()),
  addNotification: (message) => dispatch(addNotification(message))
}))(ManageAccount)));
