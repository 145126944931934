import {createLogic} from 'redux-logic';

import {MODAL} from '../actions/types';
import {close} from '../actions/modal';

export const closeActualOpened = createLogic({
  type: MODAL.CLOSE_ACTUAL_OPENED,

  process({getState}, dispatch, done) {
    const modalState = getState().modal;

    if(!modalState.isOpen) {
      return done();
    }

    dispatch(close(modalState.name));
    done();
  }
});

export default [
  closeActualOpened,
];
