import {USER_PERSONAL_TOKEN} from '../actions/types';

const initialState = {
  isPending: false,
  isError: false,
  data: null,
  error: null,
  regenerated: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_PERSONAL_TOKEN.GET_ALL.START:
      return {
        ...state,
        isPending: true,
        isError: false,
        data: null,
        error: null,
      };
    case USER_PERSONAL_TOKEN.GET_ALL.SUCCESS:
      return {
        ...state,
        isPending: false,
        isError: false,
        data: action.payload,
        error: null,
      };
    case USER_PERSONAL_TOKEN.GET_ALL.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload,
      };
    case USER_PERSONAL_TOKEN.GENERATE.SUCCESS:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload,
        ]
      };
    case USER_PERSONAL_TOKEN.REMOVE.SUCCESS: {
      const tokenId = action.payload.tokenId;
      const data = [...state.data].filter(tokenData => tokenData.id !== tokenId);

      return {
        ...state,
        data: data,
      };
    }
    case USER_PERSONAL_TOKEN.REGENERATE.START: {
      const {tokenId} = action.payload;

      return {
        ...state,
        regenerated: [...state.regenerated, tokenId],
      };
    }
    case USER_PERSONAL_TOKEN.REGENERATE.SUCCESS: {
      const {removedId, generateData} = action.payload;
      const data = [...state.data].filter(tokenData => tokenData.id !== removedId);

      return {
        ...state,
        data: [...data, generateData],
        regenerated: state.regenerated.filter(item => item !== removedId)
      };
    }
    case USER_PERSONAL_TOKEN.REGENERATE.ERROR: {
      const {removedId} = action.payload;

      return {
        ...state,
        regenerated: state.regenerated.filter(item => item !== removedId)
      };
    }
    default:
      return state;
  }
};
