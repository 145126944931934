import qs from 'qs';

export class TwitchOAuthHandler {
  constructor(config = {}) {

    const defaultConfig = {
      twitchApiHost: 'https://id.twitch.tv',
      authorizationEndpoint: '/oauth2/authorize',
      clientId: '',
    };

    this.config = {
      ...defaultConfig,
      ...config
    };
  }

  urlForAuthCodeGrant(callbackPath, scopes= []) {
    const { twitchApiHost, authorizationEndpoint, clientId } = this.config;
    return  `${twitchApiHost}${authorizationEndpoint}?response_type=code&client_id=${clientId}&redirect_uri=${window.location.origin}${callbackPath}&scope=${scopes.join(' ')}&force_verify=true`;
  }

  processAccessCodeCallback() {
    const query = qs.parse(window.location.search.slice(1));
    const code = query.code;

    return code;
  }
}

import { TWITH_OAUTH_CLIENT_ID } from 'appConfig';

export default new TwitchOAuthHandler({
  clientId: TWITH_OAUTH_CLIENT_ID,
});
