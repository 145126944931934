import { createLogic } from 'redux-logic';

import { COUNTRIES, STATES } from '../actions/types';

export const getCountries = createLogic({
  type: COUNTRIES.FETCH,
  latest: true,

  processOptions: {
    successType: COUNTRIES.SUCCESS,
    failType: COUNTRIES.ERROR,
  },

  process({ httpClient }) {
    return httpClient.get('/api/v1/countries')
      .then(res => res.data);
  }
});

export const getStates = createLogic({
  type: STATES.FETCH,
  latest: true,

  processOptions: {
    successType: STATES.SUCCESS,
    failType: STATES.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get('/api/v1/countries/' + action.country + '/states')
      .then(res => res.data);
  }
});

export default {
  getCountries,
  getStates,
};
