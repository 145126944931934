import httpClient from '../services/httpClient';
import httpVodkaClient from '../services/httpVodkaClient';
import clientContextHttpClient from '../services/clientContextHttpClient';
import shortenerHttpClient from '../services/shortenerHttpClient';

import notificationActions from '../actions/notificationActions';

export default {
  httpClient,
  httpVodkaClient,
  clientContextHttpClient,
  shortenerHttpClient,
  genericActions: {
    notification: notificationActions,
  }
};
