import { STREAMERS } from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  data: [],
};

export default (state = initialState, action) => {
  switch(action.type){
    case STREAMERS.GET.START:
      return {
        ...state,
        isLoading: true,
      };
    case STREAMERS.GET.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case STREAMERS.GET.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
     case STREAMERS.UPDATE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: state.data.map((item) => {
          if (item.id !== action.payload.id) {
            return item;
          }

          return {
            ...item,
            ...action.payload,
          };
        })
      };
    default: 
      return state;
  }
};