import React from 'react';
import PropTypes from 'prop-types';

import Standings from '../../Standings';

class TournamentStandings extends React.Component {
  render() {
    const {tournament, type} = this.props;

    return (
      <Standings tournamentId={tournament.id} type={type}/>
    );
  }
}

TournamentStandings.propTypes = {
  tournament: PropTypes.object,
  type: PropTypes.oneOf(['team'])
};

export default TournamentStandings;
