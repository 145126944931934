import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment/moment';
import { map, omit } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

import DataTableWithMobileDropdown from '../../DataTable/DataTableWithMobileDropdown';
import StatusDot from '../../Tournament/StatusDot';
import { getAllTournaments } from '../../../actions/tournamentActions';
import Select from 'react-select';
import './TournamentAssociationModal.scss';

const roles = [
  {label: '-', value: ''},
  {label: 'Player', value: 'Player'},
  {label: 'Judge', value: 'Judge'},
];

class TournamentAssociationModal extends React.Component {
  state = {
    search: '',
    changed: {},
  };

  componentDidMount() {
    this.props.fetch();
  }

  static getDerivedStateFromProps(nextProps){
    if(!nextProps.isOpen){
      return {
        search: '',
        changed: {},
      };
    }
    return null;
  }

  handleSearch = (e) => {
    this.setState({
      search: e.target.value,
    }, () => {
      this.props.fetch(this.state.search);
    });
  };

  handleSelectRole = (value, id) => {
    const { changed } = this.state;

    if(!value){
      this.setState({
        changed: omit(changed, id),
      });
    }
    else {
      const data = this.props.tournaments.find((item) => item.id === id);

      this.setState({
        changed: {
          ...changed,
          [data.id]: { data, value }
        }
      });
    }
  };

  handleResolve = () => {
    const { userId, onResolve, toggle } = this.props;

    const data = map(this.state.changed, (item) => ({
      tournament: item.data,
      role: item.value,
      userId,
    }));

    onResolve(data);
    toggle();
  };

  isChanged(id) {
    return this.state.changed[id] !== undefined;
  }

  getDataArray() {
    const { changed } = this.state;

    return [
      ...map(changed, (item) => item.data),
      ...this.props.tournaments.filter((item) => !this.isChanged(item.id))
    ];
  }

  getData() {
    const { changed } = this.state;

    const dataArray = this.getDataArray();

    return dataArray.map((item) => {
      const isChanged = this.isChanged(item.id);

      const data = [
        item.id.split('-')[0],
        item.name,
        item.type,
        moment(item.startDate).format('MM.D'),
        item.city,
        <StatusDot key={item.id} status={item.status}/>,
        <div key={item.id} className="table-select-container">
          <Select
            onChange={(e) => this.handleSelectRole(e.value, item.id)}
            value={isChanged ? changed[item.id].value : ''}
            options={roles}
            clearable={false}
            className="table-select-item"
          />
        </div>,
      ];

      return !isChanged
        ? data
        : {className: 'selected', data};
    });
  }

  render() {
    const { isOpen, toggle, intl } = this.props;
    const { changed } = this.state;

    const columns = [
      {name: 'Id'},
      {name: 'Name', flex: 2},
      {name: 'Type'},
      {name: 'Date'},
      {name: 'City'},
      {name: 'Status'},
      {name: 'Select role', isVisibleOnMobile: false},
    ];

    const dataArray = this.getDataArray();
    const data = this.getData();
    const changedAmount = Object.keys(changed).length;

    return (
      <Modal isOpen={isOpen}
             toggle={toggle}
             size={'lg'}
             className={'tournament-association-modal'}>
        <ModalHeader toggle={toggle}><FormattedMessage id="tournaments.associations.add"/></ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-6 col-sm-8">
              <div className="input-group">
                <input type="text"
                       className="form-control"
                       placeholder={intl.formatMessage({id: 'associations.modal.search.placeholder'})}
                       onChange={this.handleSearch}
                       value={this.state.search}/>
                <div className="input-group-append">
                  <button className="btn btn-light" type="button"><i className="icon-search"/></button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 offset-lg-2">
              {/*<Select/>*/}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <DataTableWithMobileDropdown
                columns={columns}
                data={data}
                dropdownData={(rowItem, rowIndex) => {
                  return roles.map((role) => {
                    const item = dataArray[rowIndex];

                    return <span key={item.id}
                                 onClick={() => this.handleSelectRole(role.value, item.id)}>{role.name}</span>;
                  });
                }}
                dropdownText={'Role'}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-success"
                  disabled={changedAmount === 0}
                  onClick={this.handleResolve}><FormattedMessage id="tournaments.btn.save"/></button>
          <button className="btn btn-secondary" onClick={toggle}><FormattedMessage id="tournaments.btn.cancel"/></button>
        </ModalFooter>
      </Modal>
    );
  }
}

TournamentAssociationModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  toggle: propTypes.func,
  fetch: propTypes.func.isRequired,
  tournaments: propTypes.array.isRequired,
  onResolve: propTypes.func.isRequired,
  userId: propTypes.string.isRequired,
  intl: propTypes.object,
};

export default injectIntl(connect(
  (state) => ({
    tournaments: state.tournamentsAll.data ? state.tournamentsAll.data.data : [],
  }),
  (dispatch) => ({
    fetch: (name) => dispatch(getAllTournaments(1, name)),
  })
)(TournamentAssociationModal));
