import {groupBy} from 'lodash';

import CardService from './Card';

/**
 * Instead of array of card relations, get flat array of cards
 */
export const flattenCardRelations = (cards) => {
  if (!cards) {
    return null;
  }

  return cards.reduce((prev, current) => {
    const length = current.amount ? current.amount : 1;

    return [...prev, ...Array.from({length: length}).fill(current.card)];
  }, []);

};

export const flatGroupByCardMainType = (deckCards) => {
  return groupBy(flattenCardRelations(deckCards), card => CardService.getMainType(card));
};

export const flatGroupByManaColor = (deckCards) => {
  return groupBy(flattenCardRelations(deckCards), card => card.colorIdentity ? card.colorIdentity[0] : null);
};

export const flatGroupByCMC = (deckCards) => {
  return groupBy(flattenCardRelations(deckCards), card => card.cmc);
};

export const groupByCardMainType = (deckCards) => {
  return groupBy(deckCards, card => CardService.getMainType(card.card));
};

export const groupByManaColor = (deckCards) => {
  return groupBy(deckCards, card => card.card.colorIdentity ? card.card.colorIdentity[0] : null);
};

export const groupByCMC = (deckCards) => {
  return groupBy(deckCards, card => card.card.cmc);
};

export function sortByType(a, b) {
  return CardService.mainTypeOrder.indexOf(a.card.type) - CardService.mainTypeOrder.indexOf(b.card.type);
}

export function sortLandsBySuperType(a, b) {
  if(!a.card.types.includes(CardService.TYPES.LAND) || !b.card.types.includes(CardService.TYPES.LAND)) {
    return 0; //if both card are not lands, then this is not relevant
  }

  const aIsBasicLand = a.card.superTypes.includes(CardService.SUPERTYPES.BASIC) ? 1 : 0;
  const bIsBasicLand = b.card.superTypes.includes(CardService.SUPERTYPES.BASIC) ? 1 : 0;
  return bIsBasicLand  - aIsBasicLand;
}

export function sortByCMC(a, b) {
  return a.card.cmc - b.card.cmc;
}

export function sortByCMCWithXLast(a, b) {
  const aHasX = (a.card.manaCost || '').includes('X') ? 1 : 0;
  const bHasX = (b.card.manaCost || '').includes('X') ? 1 : 0;

  return (aHasX - bHasX)  || sortByCMC(a, b);
}

export function sortByCMCWithXLastWithinManaGroup(a, b) {
  const aHasX = (a.card.manaCost || '').includes('X') ? 1 : 0;
  const bHasX = (b.card.manaCost || '').includes('X') ? 1 : 0;

  const aHasOnlyX = a.card.cmc == 0 && aHasX ? 1 : 0;
  const bHasOnlyX = b.card.cmc == 0 && bHasX ? 1 : 0;

  return (aHasOnlyX - bHasOnlyX) || sortByCMC(a, b) || (aHasX - bHasX);
}

export function sortByName(a, b) {
  return a.card.name.localeCompare(b.card.name);
}

export function sortByCardsAmount(a, b) {
  return a.amount - b.amount;
}

export function sortByCardsAmountDesc(a, b) {
  return sortByCardsAmount(b, a);
}

export function sortByColors(a, b) {
  const combinationsOrder = ['W', 'U', 'B', 'R', 'G', 'UW', 'BU', 'BR', 'GR', 'GW', 'BW', 'BG', 'GU', 'RU', 'RW',
    'BUW', 'BRU', 'BGR', 'GRW', 'GUW', 'RUW', 'GRU', 'BGU', 'BGW', 'BRW'];

  const aColorsStr = a.card.colorIdentity
    ? a.card.colorIdentity
        .filter(c => c != 'C')
        .sort()
        .join('')
    : '';

  const bColorsStr = b.card.colorIdentity
    ? b.card.colorIdentity
        .filter(c => c != 'C')
        .sort()
        .join('')
    : '';

  if (aColorsStr.length === 0 && bColorsStr.length === 0) {
    return 0;
  }

  if (aColorsStr.length !== bColorsStr.length) {
    if (aColorsStr.length === 0) {
      return 1;
    } else if (bColorsStr.length === 0) {
      return -1;
    }

    return aColorsStr.length - bColorsStr.length;
  }

  if (aColorsStr.length > 3) {
    return 0;
  }

  return combinationsOrder.indexOf(aColorsStr) - combinationsOrder.indexOf(bColorsStr);
}

export function createTypesComparator(typesOrder) {
  return (a, b) => {
    for (const type of typesOrder) {
      const isA = a.card.types.includes(type);
      const isB = b.card.types.includes(type);
      if (isA && !isB) {
        return -1;
      }
      if (!isA && isB) {
        return 1;
      }
    }

    return 0;
  };
}
