import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import './Notifier.scss';

class Notifier extends React.Component {
  renderMessage(message) {
    const type = message.type || 'info';
    const text = message.text ? message.text : <FormattedMessage id={message.intlKey}/>;

    return <div key={message.id}
                className={`alert alert-${type}`}
                style={{animationDelay: `0s, ${message.timeout - 0.3}s`}}>{text}</div>;
  }

  render() {
    const {messages, className} = this.props;

    return (
      <div className={classnames('notifier', className)}>
        <div className="notifier-wrapper">
          {messages.map((message) => this.renderMessage(message))}
        </div>
      </div>
    );
  }
}

Notifier.propTypes = {
  messages: propTypes.arrayOf(propTypes.shape({
    id: propTypes.string.isRequired,
    text: propTypes.string,
    intlKey: propTypes.string,
    type: propTypes.string,
    timeout: propTypes.number.isRequired,
    key: propTypes.string,
    target: propTypes.string.isRequired,
  })),
  className: propTypes.string,
};

export default Notifier;
