import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {create} from '../../../../actions/tournamentUserActions';
import TournamentUserData from './TournamentUserData';

class TournamentUserEditUserInfo extends React.Component {
  onCreateUser = (e, userData) => {
    e.preventDefault();
    const tournamentId = this.getUrlParams().overviewId;

    this.props.create(tournamentId, userData, `/tournaments/show/${tournamentId}/user/:userId`);
  };

  render() {
    const userData= {
      firstName: this.props.userData.firstName,
      lastName: this.props.userData.lastName,
      dciNumber: this.props.userData.dciNumber
    };

    return (
      <TournamentUserData
        userData={userData}
        onFormSubmit={this.onCreateUser}
        changeUserData={this.props.changeUserData}
      />
    );
  }
}

TournamentUserEditUserInfo.propTypes = {
  create: PropTypes.func.isRequired,
  userData: PropTypes.object,
  changeUserData: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    create
  }
)(TournamentUserEditUserInfo);
