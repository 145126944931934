import {TOURNAMENT_TYPES} from './types';

export const getTournamentTypes = () => ({
  type: TOURNAMENT_TYPES.GET_TYPES.START,
});

export const getTournamentTypeFormats = (tournamentType) => ({
  type: TOURNAMENT_TYPES.GET_FORMATS.START,
  payload: {
    tournamentType,
  },
});

export const getAllTournamentTypesData = () => ({
  type: TOURNAMENT_TYPES.GET_ALL_DATA.START,
});
