import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createHistory from 'history/createBrowserHistory';
import { addLocaleData, IntlProvider } from 'react-intl';
import * as en from 'react-intl/locale-data/en';
import ReactGA from 'react-ga';
import config from 'appConfig';

import configureStore from './store/configureStore';
import App from './components/App';

import './assets/styles/index.scss';

ReactGA.initialize(config.GA_KEY);

// fix an issue with copy to clipboard using styling of the button
// https://github.com/sudodoki/copy-to-clipboard/issues/46#issuecomment-287925065
document.addEventListener('copy', e => {
  // on Firefox or Edge `innerText` has different behavior, which causes copying wrong text
  const userAgent = navigator.userAgent.toLowerCase();
  if((userAgent.indexOf('firefox') > -1) || (userAgent.indexOf('edge') > -1)) {
    return;
  }

  e.clipboardData.setData('text/plain', e.srcElement.innerText);
  e.preventDefault();
});

const history = createHistory();

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const store = configureStore(history);
import messages from './assets/i18n/en.json';
addLocaleData([...en]);

ReactDOM.render(
  <IntlProvider locale="en" messages={messages}>
    <Provider store={store}>
      <App history={history}/>
    </Provider>
  </IntlProvider>,
  document.getElementById('root')
);
