import { PUBLIC_DECK, PUBLIC_CARDS, PUBLIC_ARENA_FORMAT } from '../actions/types';

const initialState = {
  deckData: {
    data: null,
    isLoad: false,
    isError: false,
    error: null,
  },
  cardsData: {
    data: null,
    isLoad: false,
    isError: false,
    error: null,
  },
  arenaFormat: {
    data: null,
    isLoad: false,
    isError: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PUBLIC_DECK.FETCH:
      return {
        ...state,
        deckData: {
          ...state.deckData,
          isLoad: true,
          isError: false,
          error: null,
        }
      };
    case PUBLIC_DECK.ERROR:
      return {
        ...state,
        deckData: {
          ...state.deckData,
          isLoad: false,
          isError: true,
          error: action.payload
        }
      };
    case PUBLIC_DECK.SUCCESS:
      return {
        ...state,
        deckData: {
          ...state.deckData,
          isLoad: false,
          isError: false,
          error: null,
          data: action.payload,
        }
      };
    case PUBLIC_CARDS.FETCH:
      return {
        ...state,
        cardsData: {
          ...state.cardsData,
          isLoad: true,
          isError: false,
          error: null,
        }
      };
    case PUBLIC_CARDS.ERROR:
      return {
        ...state,
        cardsData: {
          ...state.cardsData,
          isLoad: false,
          isError: true,
          error: action.payload
        }
      };
    case PUBLIC_CARDS.SUCCESS:
      return {
        ...state,
        cardsData: {
          ...state.cardsData,
          isLoad: false,
          isError: false,
          error: null,
          data: action.payload,
        }
      };
    case PUBLIC_ARENA_FORMAT.START:
      return {
        ...state,
        arenaFormat: {
          ...state.arenaFormat,
          isLoad: true,
          isError: false,
          error: null,
        }
      };
    case PUBLIC_ARENA_FORMAT.ERROR:
      return {
        ...state,
        arenaFormat: {
          ...state.arenaFormat,
          isLoad: false,
          isError: true,
          error: action.payload,
        }
      };
    case PUBLIC_ARENA_FORMAT.SUCCESS:
      return {
        ...state,
        arenaFormat: {
          ...state.arenaFormat,
          isLoad: false,
          isError: false,
          error: null,
          data: action.payload,
        }
      };
    default:
      return state;
  }
};
