import update from 'immutability-helper';
import {TOURNAMENT_TEAMS} from '../../actions/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_TEAMS.GET.SUCCESS: {
      const {data} = action.payload;

      return data.reduce((state, item) => ({...state, [item.id]: item}), {});
    }
    case TOURNAMENT_TEAMS.UPDATE_PLAYER.SUCCESS: {
      const { updatedPlayer, teamId } = action.payload;
      const teamPlayers = state[teamId].players;
      const index = teamPlayers.findIndex(elem => elem.player.id === updatedPlayer.id);

      return update(state, {
        [teamId]: {
          players: {
            [index]: {player: {$merge: updatedPlayer}}
          }
        }
      });
    }
    default:
      return state;
  }
};
