import { createLogic } from 'redux-logic';
import { METADATA, TOURNAMENT_TEAM_DECK_FORMATS } from '../actions/types';

import { getMetaData } from '../actions/metaDataActions';
import { setTournament } from '../actions/tournamentActions';

export const get = createLogic({
  type: METADATA.GET.FETCH,
  latest: true,

  processOptions: {
    successType: METADATA.GET.SUCCESS,
    failType: METADATA.GET.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get(`/api/v1/tournaments/${action.payload.id}/deck_names`, {
      params: {
        format: action.payload.deckFormat,
      }
    })
      .then(res => res.data);
  }
});

export const getInitMetaData = createLogic({
  type: METADATA.TEAM_INIT_GET.FETCH,

  process({ httpClient, action}, dispatch, done) {
    const {tournamentFormat, id} = action.payload;
    return httpClient.get(`/api/v1/team_decks`, {
      params: {
        format: tournamentFormat,
      },
    })
    .then( res => {
      dispatch({
        type: TOURNAMENT_TEAM_DECK_FORMATS.GET.SUCCESS,
        payload: {
          tournamentFormat: tournamentFormat,
          deckFormats: res.data,
        }
      });

      return httpClient.get(`/api/v1/tournaments/${id}/deck_names`, {
        params: {
          format: res.data[0],
        },
      })
      .then(res => {
        dispatch({
          type: METADATA.GET.SUCCESS,
          payload: res.data,
        });
      })
      .catch(err => {
        dispatch({
          type: METADATA.GET.ERROR,
          payload: err,
        });
      });
    })
    .catch(err => {
      dispatch({
        type: TOURNAMENT_TEAM_DECK_FORMATS.ERROR,
        payload: err,
      });
    })
    .then(done);
  }
});

export const updateTournamentOverwrite = createLogic({
  type: METADATA.OVERWRITE_UPDATE,

  process({ httpClient, action }, dispatch, done) {
    const { tournamentId, data, deckFormat } = action.payload;
    return httpClient.patch(`/api/v1/tournaments/${tournamentId}`, {
      statsOverwrite: data,
    })
      .then((res) => {
        dispatch(setTournament(res.data));
        dispatch(getMetaData(tournamentId, deckFormat));
      })
      .then(done);
  }
});

export const postNew = createLogic({
  type: METADATA.ADD_NEW.START,

  processOptions: {
    successType: METADATA.ADD_NEW.SUCCESS,
    failType: METADATA.ADD_NEW.ERROR,
  },

  process({ httpClient, action }) {
    const {tournamentId, name, amount, deckFormat} = action.payload;
    const body = {
      name: name,
      amount: amount,
    };
    if (deckFormat) {
      body.deckFormat = deckFormat;
    }

    return httpClient.post(`/api/v1/tournaments/${tournamentId}/deck_names`, body)
    .then(res => ({
      newData: res.data
    }));
  }
});

export const postNewUpdate = createLogic({
  type: METADATA.ADD_NEW.SUCCESS,

  process({ getState, action, genericActions }, dispatch, done) {
    const metaData = getState().metaData.data;
    metaData.push(action.payload.newData);

    dispatch(genericActions.notification.add({
      type: 'success',
      intlKey: 'notifications.general.success',
    }));

    dispatch({
      type: METADATA.UPDATE_STATE,
      payload: metaData,
    });

    done();
  }
});

export const postNewError = createLogic({
  type: METADATA.ADD_NEW.ERROR,

  process({genericActions}, dispatch, done) {
    dispatch(genericActions.notification.add({
      type: 'danger',
      intlKey: 'notifications.general.error',
    }));
    done();
  }
});

export const patchData = createLogic({
  type: METADATA.UPDATE.START,

  processOptions: {
    successType: METADATA.UPDATE.SUCCESS,
    failType: METADATA.UPDATE.ERROR,
  },

  process({ httpClient, getState, action }) {
    const { dataId, tournamentId, name, amount } = action.payload;
    const actualData = getState().metaData.data.find(elem => {
      return elem.id === dataId;
    });
    return httpClient.patch(`/api/v1/tournaments/${tournamentId}/deck_names/${dataId}`, {
      name: name ? name : actualData.name,
      amount: amount ? amount : actualData.amount,
    })
    .then(res => ({
      data: res.data,
      dataId,
    }));
  }
});

export const patchDataUpdate = createLogic({
  type: METADATA.UPDATE.SUCCESS,

  process({ getState, action }, dispatch, done) {
    const metaData = getState().metaData.data;
    const newData = metaData.map( elem => {
      if (elem.id === action.payload.dataId) {
        return action.payload.data;
      }

      return elem;
    });

    dispatch({
      type: METADATA.UPDATE_STATE,
      payload: newData,
    });

    done();
  }
});

export const deleteData = createLogic({
  type: METADATA.DELETE.START,

  processOptions: {
    successType: METADATA.DELETE.SUCCESS,
    failType: METADATA.DELETE.ERROR,
  },

  process({ httpClient, action }) {
    const { dataId, tournamentId } = action.payload;
    return httpClient.delete(`/api/v1/tournaments/${tournamentId}/deck_names/${dataId}`)
      .then(() => ({
        dataId,
      }));
  }
});

export const deleteDataUpdate = createLogic({
  type: METADATA.DELETE.SUCCESS,

  process({ getState, action }, dispatch, done) {
    const metaData = getState().metaData.data;
    const newData = metaData.filter(elem => {
      return elem.id !== action.payload.dataId;
    });

    dispatch({
      type: METADATA.UPDATE_STATE,
      payload: newData,
    });

    done();
  }
});

export default {
  get,
  getInitMetaData,
  postNew,
  postNewUpdate,
  postNewError,
  patchData,
  patchDataUpdate,
  deleteData,
  deleteDataUpdate,
  updateTournamentOverwrite,
};
