import React from 'react';

import ChangeableDropdown from '../../../components/Generic/ChangeableDropdown/ChangeableDropdown';
import ChangeableDropdownColor from '../../../components/Generic/ChangeableDropdown/ChangeableDropdownColor';
import TournamentStatusDropdown from '../../../components/Tournament/TournamentStatusDropdown';

class DropdownUI extends React.Component {
  state = {
    value1: 'bar',
    value2: 'bar',
    value3: 'bar',
    value4: 'zar',
    value5: 'zar',
    value6: 'zar',
    value7: 'not_started',
  };

  render() {
    const options = [
      {value: 'foo', name: 'Foo'},
      {value: 'bar', name: 'Bar'},
      {value: 'zar', name: 'Zar'},
    ];

    const options2 = [
      {value: 'foo', name: 'foo - success', color: 'success'},
      {value: 'bar', name: 'bar - danger', color: 'danger'},
      {value: 'zar', name: 'zar - warning', color: 'warning'},
    ];

    return (
      <div>
        <h5>1. ChangeableDropdown <i>color: success</i></h5>

        <ChangeableDropdown options={options}
                            value={this.state.value1}
                            color={'success'}
                            onChange={(value) => this.setState({ value1: value })}/>

        <h5>2. ChangeableDropdown <i>color: danger</i></h5>

        <ChangeableDropdown options={options}
                            value={this.state.value2}
                            color={'danger'}
                            onChange={(value) => this.setState({ value2: value })}/>

        <h5>3. ChangeableDropdown <i>color: success, className: btn-lg</i></h5>

        <ChangeableDropdown options={options}
                            value={this.state.value3}
                            color={'success'}
                            btnClassName={'btn-lg'}
                            onChange={(value) => this.setState({ value3: value })}/>

        <h5>4. ChangeableDropdown with change color</h5>

        <ChangeableDropdown options={options2}
                            value={this.state.value4}
                            color={options2.find(item => this.state.value4 === item.value).color}
                            onChange={(value) => this.setState({ value4: value })}/>

        <h5>5. ChangeableDropdownColor (the same as in point 4. but used less code)</h5>

        <ChangeableDropdownColor options={options2}
                                 value={this.state.value5}
                                 onChange={(value) => this.setState({ value5: value })}/>

        <h5>6. ChangeableDropdown render current value in dropdown menu</h5>

        <ChangeableDropdown options={options}
                            renderCurrentValue
                            value={this.state.value6}
                            onChange={(value) => this.setState({ value6: value })}/>

        <h5>7. TournamentStausDropdown</h5>

        <TournamentStatusDropdown value={this.state.value7}
                                  onChange={(value) => this.setState({ value7: value })}/>
      </div>
    );
  }
}

export default DropdownUI;
