import React from 'react';

import DeckSelect from '../../DeckSelect';

class DeckSelectUI extends React.Component {
  state = {
    value1: '',
    value2: '3',
    value3: '',
    value4: '',
  };

  render() {
    const options = [
      {
        name: 'Deck name',
        value: '1',
        valid: true,
        format: 'Standard',
      },
      {
        name: 'Another',
        value: '2',
        valid: false,
        format: 'Modern',
      },
      {
        name: 'Another Modern',
        value: '3',
        valid: false,
        format: 'Modern',
      }
    ];
    const optionsWithoutFormat = options.map(item => ({
      name: item.name,
      value: item.value,
      valid: item.valid,
    }));

    return (
      <div>
        <h5>DeckSelect</h5>

        <DeckSelect
          options={options}
          value={this.state.value1}
          onChange={(val) => this.setState({value1: val})}
        />

        <h5>DeckSelect with default value</h5>

        <DeckSelect
          options={options}
          value={this.state.value2}
          onChange={(val) => this.setState({value2: val})}
        />

        <h5>DeckSelect with custom placeholder</h5>

        <DeckSelect
          options={options}
          value={this.state.value3}
          onChange={(val) => this.setState({value3: val})}
          placeholder="Some custom placeholder..."
        />

        <h5>DeckSelect without format data</h5>

        <DeckSelect
          options={optionsWithoutFormat}
          value={this.state.value4}
          onChange={(val) => this.setState({value4: val})}
        />
      </div>
    );
  }
}

export default DeckSelectUI;
