import React from 'react';
import PropTypes from 'prop-types';

import './TournamentDetails.scss';

class TournamentDetails extends React.Component {
  render() {
    const {data} = this.props;

    return (
      <div className="tournament-details">
        <h5>Tournament details</h5>

        <ul>
          <li>
            <div className="label">Name</div>
            <div className="value">{data.name} <span className="details">(#{data.id.split('-')[0]})</span></div>
          </li>
          <li>
            <div className="label">Format</div>
            <div className="value">{data.format}</div>
          </li>
          <li>
            <div className="label">Type</div>
            <div className="value">{data.type}</div>
          </li>
          <li>
            <div className="label">City</div>
            <div className="value">{data.city}</div>
          </li>
          <li>
            <div className="label">State</div>
            <div className="value">{data.state}</div>
          </li>
          <li>
            <div className="label">Country</div>
            <div className="value">{data.country}</div>
          </li>
        </ul>
      </div>
    );
  }
}

TournamentDetails.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    type: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }),
};

export default TournamentDetails;
