import React from 'react';
import {Link} from 'react-router-dom';
import {Navbar} from 'reactstrap';

import logo from '../../assets/images/logo.svg';

class SimpleTopBar extends React.Component {
  render() {
    return (
      <div className="topbar">
        <div className="container">
          <Navbar light>
            <Link to="/" className="navbar-navbrand">
              <img src={logo} alt="cardboard live"/>
            </Link>
            <div className="topbar__user d-flex"/>
          </Navbar>
        </div>
      </div>
    );
  }
}

SimpleTopBar.propTypes = {

};

export default SimpleTopBar;
