import {createLogic} from 'redux-logic';
import {TOURNAMENT_TYPES} from '../actions/types';
import { getAvailableTypes } from '../services/TournamentType';

const createApi = (httpClient) => {
  return {
    getAllTypes: () => {
      return getAvailableTypes();
    },

    getTypeFormats: (tournamentType) => {
      return httpClient.get(`/api/v1/tournament_formats`, {
        params: {
          type: tournamentType,
        }
      })
        .then(res => res.data);
    }
  };
};

export const getTypes = createLogic({
  type: TOURNAMENT_TYPES.GET_TYPES.START,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_TYPES.GET_TYPES.SUCCESS,
    failType: TOURNAMENT_TYPES.GET_TYPES.ERROR,
  },

  process({httpClient}) {
    return createApi(httpClient).getAllTypes();
  }
});

export const getTypeFormats = createLogic({
  type: TOURNAMENT_TYPES.GET_FORMATS.START,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_TYPES.GET_FORMATS.SUCCESS,
    failType: TOURNAMENT_TYPES.GET_FORMATS.ERROR,
  },

  process({httpClient, action}) {
    const {tournamentType} = action.payload;

    return createApi(httpClient).getTypeFormats(tournamentType)
      .then(data => ({
        tournamentType,
        data,
      }))
      .catch(err => {
        return Promise.reject({
          tournamentType,
          data: err,
        });
      });
  }
});

export const getAllTypesData = createLogic({
  type: TOURNAMENT_TYPES.GET_ALL_DATA.START,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_TYPES.GET_ALL_DATA.SUCCESS,
    failType: TOURNAMENT_TYPES.GET_ALL_DATA.ERROR,
  },

  process({httpClient}) {
    const api = createApi(httpClient);

    return api.getAllTypes()
      .then(types => {
        return Promise.all(types.map(type => api.getTypeFormats(type)))
          .then(formats => ({
            types,
            formats,
          }));
      })
      .then(data => {
        return {
          types: data.types,
          formats: data.formats.map((item, index) => ({
            type: data.types[index],
            data: item
          }))
        };
      });
  }
});

export default [
  getTypes,
  getTypeFormats,
  getAllTypesData,
];
