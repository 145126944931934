import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormattedMessage, injectIntl } from 'react-intl';

import { create, getDecknames } from '../actions/deckActions';
import { fetchFormats } from '../actions/formatsActions';

import './CreateDeck.scss';

class CreateDeck extends React.Component {
  state = {
    customFormat: '',
    deck: '',
    customDeckName: '',
    options: [],
    selected: {}
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.formats.isLoad &&
      !nextProps.formats.isLoad &&
      !nextProps.formats.isError) {
        this.setFormatOptions(nextProps.formats.data);
    }

    if (this.props.createDeck.isLoad &&
      !nextProps.createDeck.isLoad &&
      !nextProps.createDeck.isError) {
        const { draftId } = nextProps.createDeck.data;

        this.props.history.push(`/decks/${draftId}`);
    }
  }

  componentDidMount() {
    const { data } = this.props.formats;

    if (data) {
      return this.setFormatOptions(data);
    }

    this.props.fetchFormats();
  }

  setFormatOptions(data) {
    const options = data.map(format => {
      return {
        label: format.name,
        value: format.id
      };
    });

    this.setState({ options });
  }

  onFormSubmit = e => {
    e.preventDefault();

    const { deck, customDeckName, customFormat, selected } = this.state;
    const id = selected && selected.value;
    const name = customDeckName ? customDeckName : deck;

    const data = {
      name,
      format: {
        id
      },
      notes: '',
      customFormat
    };

    this.props.create(data);
  }

  onValueChange = selected => {
    this.setState({ selected, deck: '' });

    if (!selected) {
      return;
    }

    this.props.getDecknames(selected.value);
  }

  onFormatChange = e => {
    this.setState({ customFormat: e.target.value });
  }

  changeDeckName(value) {
    const { deckNames } = this.props;

    if(!deckNames || !deckNames.data){
      return;
    }

    const select = deckNames.data.findIndex(item => item.name === value);
    const isCustom = select === -1;

    this.setState({
      deck: value,
      customDeckName: isCustom ? value : '',
    });
  }

  onDeckNameChange = (value) => {
    this.changeDeckName(value);
  };

  onCustomDeckNameChange = (e) => {
    const value = e.target.value;

    if(!value){
      return;
    }

    this.changeDeckName(value);
  };

  renderDeckNameSelect() {
    const { selected, deck, customDeckName } = this.state;
    const { deckNames } = this.props;
    const formatValue = selected && selected.value;
    const deckNamesFields = !!formatValue && deckNames && deckNames.data &&
      deckNames.data.map(deckName => ({ value: deckName.name, label: deckName.name }));
    let deckNameOptions = deckNamesFields ? deckNamesFields : [];

    if(customDeckName) {
      deckNameOptions = deckNamesFields ? [...deckNamesFields, { value: customDeckName, label: customDeckName }] : [];
    }

    return(
      <React.Fragment>
        <Select.Creatable
          value={deck}
          onBlurResetsInput={false}
          onBlur={this.onCustomDeckNameChange}
          options={deckNameOptions}
          onChange={this.onDeckNameChange}
          simpleValue
          showNewOptionAtTop={false}
          promptTextCreator={(label) => `${this.props.intl.messages['edit.deck.create_custom_name']} ${label}`}
          disabled={(!selected || !selected.value) && deckNameOptions.length === 0}
        />
      </React.Fragment>
    );
  }

  render() {
    const { selected, options, customFormat, deck } = this.state;

    const formatValue = selected && selected.value;
    const isButtonDisabled = !deck || !formatValue;

    return (
      <div className="add-deck mb-4">
        <div className="d-flex justify-content-between">
          <h2 className="section-header"><FormattedMessage id="create.deck.add.new"/></h2>
        </div>

        <form className="form add-deck__form" onSubmit={this.onFormSubmit}>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form add-deck__form-label"></label>
                <Select
                  value={formatValue}
                  options={options}
                  onChange={this.onValueChange}
                  searchable={false}
                />
              </div>
            </div>
            {selected && selected.label === 'Custom' && <div className="col-lg-6">
              <div className="form-group">
                <label className="form add-deck__form-label"><FormattedMessage id="create.deck.format"/></label>
                <input
                  className="form-control add-deck__form-input"
                  type="text"
                  value={customFormat}
                  onChange={this.onFormatChange}
                  autoComplete="nope" autofill="off" spellCheck="false" autoCorrect="off" autoCapitalize="off"
                />
              </div>
            </div>}
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form add-deck__form-label"><FormattedMessage id="edit.deck.name"/></label>
                {this.renderDeckNameSelect()}
              </div>
            </div>
          </div>
          <button
            className="btn btn-primary add-deck__form-btn"
            type="submit"
            disabled={isButtonDisabled}
            >
              <FormattedMessage id="create.deck.btn.create"/>
            </button>
        </form>
      </div>
    );
  }
}

CreateDeck.propTypes = {
  createDeck: PropTypes.object,
  formats: PropTypes.object,
  create: PropTypes.func.isRequired,
  fetchFormats: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  getDecknames: PropTypes.func.isRequired,
  deckNames: PropTypes.object,
  intl: PropTypes.object.isRequired,
};


export default injectIntl(connect(
  (state) => ({
    formats: state.formats,
    createDeck: state.createDeck,
    deckNames: state.deckNames.data,
  }),
  ({
    create,
    fetchFormats,
    getDecknames
  })
)(CreateDeck));
