import React from 'react';
import {withRouter} from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Spinner from '../../../Spinner';
import Pagination from '../../../Pagination/Pagination';
import DuoPlayersTable from './DuoPlayersTable';

import ModalDeleteTeam from './ModalDeleteTeam';
import ModalAddDuoPlayerContainer from './ModalAddDuoPlayerContainer';
import ModalEditTournamentPlayer from '../../ModalEditTournamentPlayer';
import TournamentDeckBuilderModal from '../Players/TournamentDeckBuilderModalContainer';
import ModalEditTeamContainer from './ModalEditTeamContainer';

import {getTournamentTeams} from '../../../../actions/tournamentTeams';
import {getTournamentTeamDeckFormats} from '../../../../actions/tournamentTeamDeckFormats';
import { updateTournamentTeamPlayer } from '../../../../actions/tournamentTeams';

import {getTournamentTeamsSelector} from '../../../../selectors/tournamentTeams';
import {getTournamentTeamDeckFormatsSelector} from '../../../../selectors/tournamentTeamDeckFormats';
import {removeTournamentTeam} from '../../../../actions/tournamentTeams';

import Prompt from '../../../Generic/Modals/Prompt';

const DECK_STATE_ONLINE = 'online';

class DuoPlayers extends React.Component {
  state = {
    bioLoader: false,
    deckWarningPrompt: {
      isOpen: false,
      data: null,
    },
    searchValue: '',
    modalAdd: {
      isOpen: false,
    },
    modalDelete: {
      isOpen: false,
      teamId: null,
    },
    modalDeckBuilder: {
      isOpen: false,
      userId: null,
    },
    modalEdit: {
      isOpen: false,
      data: {},
    },
    modalBio: {
      isOpen: false,
      data: null,
    }
  };

  componentDidMount() {
    const {tournament, getTournamentTeamDeckFormats} = this.props;

    this.getData();
    getTournamentTeamDeckFormats(tournament.format);
  }

  componentDidUpdate(prevProps) {
    const {match} = this.props;

    if (prevProps.match.params.page !== match.params.page) {
      this.getData();
    }
  }

  onChangeSearch = (e) => {
    const value = e.target.value;

    this.setState({
      searchValue: value,
    });
    this.getData(1, {
      name: value,
    });
  };

  onOpenAddModal = () => {
    this.setState({
      modalAdd: {
        isOpen: true,
      }
    });
  };

  onCloseAddModal = () => {
    this.setState({
      modalAdd: {
        isOpen: false,
      }
    });
  };

  onOpenEditModal = (data) => {
    this.setState({
      modalEdit: {
        isOpen: true,
        data,
      },
    });
  };

  onCloseEditModal = () => {
    this.setState({
      modalEdit: {
        isOpen: false,
        data: {},
      }
    });
  };

  onToggleDeleteModal = (teamId) => {
    if(!teamId) {
      return this.setState({
        modalDelete: {
          isOpen: false,
          teamId,
        }
      });
    }

    return this.setState({
      modalEdit: {
        isOpen: false,
        data: {},
      },
      modalDelete: {
        isOpen: true,
        teamId,
      }
    });
  };

  onSubmitDelete = () => {
    const {modalDelete} = this.state;
    const {removeTournamentTeam} = this.props;

    removeTournamentTeam(modalDelete.teamId);
  };

  onOpenBioModal = (data) => {
    this.setState({
      modalBio: {
        isOpen: true,
        data,
      },
    });
  };

  onCloseBioModal = () => {
    this.hideBioLoader();
    this.setState({
      modalBio: {
        isOpen: false,
        data: null
      },
    });
  };

  onClickBio = (data) => {
    this.onOpenBioModal(data);
  };

  onClickEdit = (teamData) => {
    this.onOpenEditModal({
      teamId: teamData.id,
      players: teamData.players.map(item => ({
        firstName: item.player.firstName,
        lastName: item.player.lastName,
        dciNumber: item.player.dciNumber,
        deckFormat: item.format.name,
      })),
    });
  };

  onChangeDeckState = ({playerId, userId, teamId}, actualValue, updateValue) => {
    if(actualValue === DECK_STATE_ONLINE) {
      this.openDeckWarningPrompt({
        playerId,
        teamId,
        value: updateValue,
      });

      return;
    }

    this.changeDeckState(playerId, userId, teamId, updateValue);
  };

  handleDeckBuilderClose = () => {
    this.toggleDeckBuilderModal(null);
    this.reloadTableData();
  };

  openDeckWarningPrompt = (data) => {
    this.setState({
      deckWarningPrompt: {
        isOpen: true,
        data: data,
      },
    });
  };

  toggleDeckBuilderModal = (id) => {
    this.setState((state) => ({
      modalDeckBuilder: {
        isOpen: !state.modalDeckBuilder.isOpen,
        playerId: id
      }
    }));
  };

  changeDeckState = (playerId, userId, teamId, value) => {
    const {updateTournamentTeamPlayer} = this.props;

    updateTournamentTeamPlayer(playerId, teamId, {
      deckState: value,
    });

    if(value === DECK_STATE_ONLINE) {
      this.toggleDeckBuilderModal(userId);
    }
  };

  closeDeckWarningPrompt = () => {
    this.setState({
      deckWarningPrompt: {
        isOpen: false,
        data: null,
      },
    });
  };

  submitDeckWarningPrompt = () => {
    const {deckWarningPrompt} = this.state;
    const {playerId, teamId, value} = deckWarningPrompt.data;

    this.changeDeckState(playerId, null, teamId, value);
    this.closeDeckWarningPrompt();
  };

  reloadTableData(filters = {}) {
    const {tournament, match, getTournamentTeams} = this.props;

    getTournamentTeams(tournament.id, {
      page: match.params.page,
      filters
    });
  }

  getData(page, filters = {}) {
    const {tournament, getTournamentTeams, match} = this.props;
    const per_page = 5;

    getTournamentTeams(tournament.id, {
      page: page || match.params.page,
      per_page,
      filters
    });
  }

  getPlayersToEdit() {
    return this.state.modalEdit.data.players;
  }

  showBioLoader = () => {
    this.setState({
      bioLoader: true
    });
  };

  hideBioLoader = () => {
    this.setState({
      bioLoader: false
    });
  };

  renderMainContent() {
    const {tournamentTeams} = this.props;
    const isData = tournamentTeams.data.length > 0;

    if(tournamentTeams.isLoading) {
      return <Spinner/>;
    }

    return (
      <div>
        {
          !isData &&
          <p className="text-center mt-3"><FormattedMessage id="tournament.duo-standard.noData"/></p>
        }

        {isData && this.renderTable()}
      </div>
    );
  }

  renderTable() {
    const {tournament, tournamentTeams} = this.props;
    const {searchValue} = this.state;

    const isShowPagination = searchValue.length === 0;

    return (
      <React.Fragment>
        <div className="tournament-list-table-wrapper">
          <DuoPlayersTable
            tournamentId={tournament.id}
            tournamentFormat={tournament.format}
            data={tournamentTeams.data}
            onClickBio={this.onClickBio}
            onClickEdit={this.onClickEdit}
            onChangeDeckState={this.onChangeDeckState}
            onEditDeckClick={this.toggleDeckBuilderModal}
          />
        </div>
        <div className="pagination-wrapper">
          {
            isShowPagination &&
            <Pagination totalPages={tournamentTeams.pagination.lastPage} url={`/tournaments/show/${tournament.id}/players_duo/:page`}/>
          }
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {intl, tournamentTeamDeckFormats, tournament} = this.props;
    const {searchValue, modalAdd, modalDelete, modalEdit, modalBio, modalDeckBuilder, bioLoader, deckWarningPrompt} = this.state;
    const deckFormats = tournamentTeamDeckFormats.byTournamentFormat[tournament.format];

    if(tournamentTeamDeckFormats.isLoading) {
      return <Spinner/>;
    }

    const tournamentDeckFormats = deckFormats ? deckFormats: null;

    return (
      <div>
        <h4 className="section-header mb-4">
          <FormattedMessage id="tournament.duo-standard.header"/>
          <button type="button" className="btn btn-primary float-right" onClick={this.onOpenAddModal}>
            <span className="d-none d-md-block"><FormattedMessage id="tournament.duo-standard.add"/></span>
            <i className="icon-plus d-md-none"/>
          </button>
        </h4>
        <div>
          <div className="form-row">
            <div className="mb-3 col-md-6">
              <div className="input-group">
                <input onChange={this.onChangeSearch}
                       type="text"
                       className="form-control"
                       placeholder={intl.formatMessage({id: 'tournament.duo-standard.search.placeholder'})}
                       value={searchValue}
                       autoComplete="nope" autofill="off" spellCheck="false" autoCorrect="off" autoCapitalize="off"/>
                <div className="input-group-append">
                  <button className="btn btn-light" type="button"><i className="icon-search"/></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.renderMainContent()}

        <Prompt
          isOpen={deckWarningPrompt.isOpen}
          onSubmit={this.submitDeckWarningPrompt}
          onClose={this.closeDeckWarningPrompt}
          title={<FormattedMessage id="deckStatusWarningPrompt.header"/>}
          submitClassName={'btn-danger'}
        >
          <FormattedMessage id="deckStatusWarningPrompt.text"/>
        </Prompt>

        {
          modalAdd.isOpen &&
          <ModalAddDuoPlayerContainer
            deckFormats={tournamentDeckFormats}
            tournamentId={tournament.id}
            onClose={this.onCloseAddModal}/>
        }

        {
          modalDelete.isOpen &&
          <ModalDeleteTeam
            isOpen={modalDelete.isOpen}
            onSubmit={this.onSubmitDelete}
            onClose={() => this.onToggleDeleteModal(null)}
          />
        }

        {
          modalEdit.isOpen &&
          <ModalEditTeamContainer
            players={this.getPlayersToEdit()}
            teamId={modalEdit.data.teamId}
            onClose={this.onCloseEditModal}
            onToggleDeleteModal={this.onToggleDeleteModal}
          />
        }

        {
          modalBio.isOpen &&
          <ModalEditTournamentPlayer
            isOpen={modalBio.isOpen}
            showBioLoader={this.showBioLoader}
            bioLoader={bioLoader}
            data={modalBio.data}
            bioType={tournament.bioSchema.name}
            onClose={this.onCloseBioModal}
          />
        }

        {
          modalDeckBuilder.isOpen &&
          <TournamentDeckBuilderModal
            isOpen={true}
            toggle={this.handleDeckBuilderClose}
            userId={modalDeckBuilder.playerId}
            tournamentId={tournament.id}
            supportCustomCards={tournament.customCards}
          />
        }
      </div>
    );
  }
}

DuoPlayers.propTypes = {
  tournament: PropTypes.object,

  intl: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,

  tournamentTeams: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    pagination: PropTypes.shape({
      lastPage: PropTypes.string,
      page: PropTypes.string,
      perPage: PropTypes.string,
      total: PropTypes.string,
    })
  }),
  tournamentTeamDeckFormats: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    byTournamentFormat: PropTypes.object.isRequired,
  }),
  getTournamentTeams: PropTypes.func.isRequired,
  getTournamentTeamDeckFormats: PropTypes.func.isRequired,
  removeTournamentTeam: PropTypes.func.isRequired,
  updateTournamentTeamPlayer: PropTypes.func.isRequired,
};


export default injectIntl(withRouter(connect(
  state => ({
    tournamentTeams: getTournamentTeamsSelector(state.tournamentTeams),
    tournamentTeamDeckFormats: getTournamentTeamDeckFormatsSelector(state.tournamentTeamDeckFormats),
  }),
  {
    updateTournamentTeamPlayer,
    getTournamentTeams,
    getTournamentTeamDeckFormats,
    removeTournamentTeam,
  }
)(DuoPlayers)));
