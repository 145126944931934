import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { getCountries, getStates } from '../../actions/politicalActions';
import { createStore, getStoreDetails, editStore } from '../../actions/storesActions';
import { add as addNotification } from '../../actions/notificationActions';
import AccessControl from '../Utils/AccessControl';
import { roles } from '../const';
import { FormattedMessage } from 'react-intl';
import Spinner from '../Spinner';

import './Store.scss';

class Store extends React.Component {
  state = {
    formData: {
      name: '',
      id: '',
      transactionId: '',
      url: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      phone: '',
    },
    updatedData: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { id } = nextProps.match.params;
    const { data: storeDetailsData } = nextProps.storeDetails;

    if (id && storeDetailsData && prevState.formData.id !== storeDetailsData.id) {
      return {
        formData: {
          name: storeDetailsData.name || '',
          id: storeDetailsData.id.split('-')[0],
          transactionId: storeDetailsData.transactionId || '',
          url: storeDetailsData.url || '',
          address1: storeDetailsData.address1 || '',
          address2: storeDetailsData.address2 || '',
          city: storeDetailsData.city || '',
          state: storeDetailsData.state || '',
          country: storeDetailsData.country || '',
          phone: storeDetailsData.phone || '',
        },
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      this.props.getStoreDetails(id);
    }

    if (this.props.countries.data) {
      return;
    }

    this.props.getCountries();
  }

  onChange(e, prop) {
    this.setState({
      formData: {
        ...this.state.formData,
        [prop]: e.target.value
      },
      updatedData: {
        ...this.state.updatedData,
        [prop]: e.target.value
      }
    });
  }

  onSelect(value, prop) {
    this.setState({
      formData: {
        ...this.state.formData,
        [prop]: value,
      },
      updatedData: {
        ...this.state.updatedData,
        [prop]: value,
      }
    }, () => {
      if (prop === 'country') {
        this.props.getStates(value);
      }
    });
  }

  save = (e) => {
    e.preventDefault();

    const { id } = this.props.match.params;
    const { updatedData } = this.state;
    const { name } = this.state.formData;

    if (!name) {
      return this.props.addNotification({
        text: <FormattedMessage id="store.message.error.name" />,
        type: 'danger',
        timeout: 4,
      });
    }

    if (id) {
      return this.props.editStore(id, updatedData);
    }

    this.props.createStore(updatedData);
  }

  render() {
    const {
      name,
      id,
      transactionId,
      url,
      address1,
      address2,
      city,
      state,
      country,
      phone,
    } = this.state.formData;

    if(this.props.match.params.id && (!this.props.storeDetails.data || this.props.storeDetails.isLoad)){
      // Only on route /stores/:id
      // Not on route /stores/create
      return <Spinner/>;
    }

    return (
      <AccessControl roles={[roles.organizer, roles.admin]} forbiddenRedirect={'/'}>
        <div className="container">
          <form className="store py-5">
            <div className="row store__top mb-lg-5">
              <div className="col-lg-4">
                <div className="form-group">
                  <label><FormattedMessage id="store.name"/></label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => this.onChange(e, 'name')}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label><FormattedMessage id="store.id"/></label>
                  <input
                    type="text"
                    className="form-control"
                    value={id}
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label><FormattedMessage id="store.transaction"/></label>
                  <input
                    type="text"
                    className="form-control"
                    value={transactionId}
                    onChange={(e) => this.onChange(e, 'transactionId')}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label><FormattedMessage id="store.url"/></label>
                  <input
                    type="text"
                    className="form-control"
                    value={url}
                    onChange={(e) => this.onChange(e, 'url')}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label><FormattedMessage id="store.address"/></label>
                  <input
                    type="text"
                    className="form-control"
                    value={address1}
                    onChange={(e) => this.onChange(e, 'address1')}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label><FormattedMessage id="store.address2"/></label>
                  <input
                    type="text"
                    className="form-control"
                    value={address2}
                    onChange={(e) => this.onChange(e, 'address2')}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label><FormattedMessage id="store.city"/></label>
                  <input
                    type="text"
                    className="form-control"
                    value={city}
                    onChange={(e) => this.onChange(e, 'city')}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label><FormattedMessage id="store.country"/></label>
                  <Select
                    id="country-select"
                    ref={ref => this.select = ref}
                    onBlurResetsInput={false}
                    onSelectResetsInput={false}
                    autoFocus
                    options={this.props.countries.data}
                    simpleValue
                    name="selected-country"
                    value={country}
                    onChange={(e) => this.onSelect(e, 'country')}
                    searchable={true}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label><FormattedMessage id="store.state"/></label>
                  <Select
                    id="state-select"
                    ref={ref => this.select = ref}
                    onBlurResetsInput={false}
                    onSelectResetsInput={false}
                    autoFocus
                    options={this.props.states.data}
                    simpleValue
                    name="selected-state"
                    disabled={!this.props.states.data || this.props.states.data.length < 1}
                    value={state}
                    onChange={(e) => this.onSelect(e, 'state')}
                    searchable={true}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label><FormattedMessage id="store.phone"/></label>
                  <input
                    type="text"
                    className="form-control"
                    value={phone}
                    onChange={(e) => this.onChange(e, 'phone')}
                  />
                </div>
              </div>
            </div>
            <div className="text-center mt-lg-5">
              <Link to="/stores">
                <button
                  type="button"
                  className="btn btn-lg btn-secondary mr-3"
                >
                  <FormattedMessage id="tournaments.btn.cancel"/>
                </button>
              </Link>
              <button
                disabled={!this.state.updatedData}
                type="submit"
                className="btn btn-lg btn-success"
                onClick={this.save}
              >
                <FormattedMessage id="tournaments.btn.save"/>
              </button>
            </div>
          </form>
        </div>
      </AccessControl>
    );
  }
}

Store.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  getCountries: PropTypes.func,
  countries: PropTypes.object,
  getStates: PropTypes.func,
  states: PropTypes.object,
  createStore: PropTypes.func,
  getStoreDetails: PropTypes.func,
  storeDetails: PropTypes.object,
  editStore: PropTypes.func,
  addNotification: PropTypes.func,
};

export default connect(
  (state) => ({
    countries: state.countries,
    states: state.states,
    storeDetails: state.storeDetails,
  }),
  ({
    createStore,
    getCountries,
    getStates,
    getStoreDetails,
    editStore,
    addNotification,
  })
)(Store);
