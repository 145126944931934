import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import MetaDataTable from '../../../MetaTable/MetaDataTable';

class MetaDataContent extends React.Component {
  render() {
    const { data, isNewRow } = this.props;

    if (!data) {
      return null;
    }

    if (data.length === 0 && isNewRow === false) {
      return <p className="text-center">
        <FormattedMessage id="metaData.noData" />
      </p>;
    }

    return (
      <MetaDataTable
        {...this.props}
      />
    );
  }
}

MetaDataContent.propTypes = {
  ...MetaDataTable.propTypes,
  data: PropTypes.oneOfType([
    MetaDataTable.propTypes.data,
    PropTypes.oneOf([null]),
  ])
};

export default MetaDataContent;
