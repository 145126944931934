import React from 'react';

import Tabs from '../../../components/Generic/Tabs/Tabs';

import TabsWrapper from '../../../components/Generic/Tabs/TabsWrapper';
import TabsContent from '../../../components/Generic/Tabs/TabsContent';


class TabUI extends React.Component {
  state = {
    tabs1: 1,
    tabs2: 1,
    tabs3: 1,
    tabs4: 1,
    tabs5: 1,
  };

  render() {
    const data1 = ['Foo', 'Bar', 'Zar'];
    const data2 = [
      {name: 'Foo', amount: 10, max: 20},
      {name: 'Bar', amount: 20, max: 30},
      {name: 'Zar', amount: 30, max: 10},
    ];
    const data3 = [
      {name: 'Foo', component: () => <i>Hello Foo</i>},
      {name: 'Bar', component: () => <i>Hello Bar</i>},
      {name: 'Zar', component: () => <i>Hello Zar</i>},
    ];
    const data4 = [
      {name: 'Foo', amount: 10, max: 20, component: () => <i>Hello Foo</i>},
      {name: 'Bar', amount: 20, max: 30, component: () => <i>Hello Bar</i>},
      {name: 'Zar', amount: 30, max: 10, component: () => <i>Hello Zar</i>},
    ];

    return (
      <div>
        <h5>1. Basic tabs</h5>

        <Tabs className={'mt-4 mb-4'}
              data={data1}
              index={this.state.tabs1}
              onChangeIndex={(index) => this.setState({tabs1: index})}/>

        <h5>2. Tabs with custom tab render</h5>

        <Tabs className={'mt-4 mb-4'}
              data={data2}
              index={this.state.tabs2}
              onChangeIndex={(index) => this.setState({tabs2: index})}
              renderTabItem={(item) => {
                return <span>{item.name} {item.amount} / {item.max}</span>;
              }}/>

        <h5>3. Tabs with contnent components</h5>

        <TabsWrapper data={data3}
                     index={this.state.tabs3}
                     onChangeIndex={(index) => this.setState({tabs3: index})}>
          <Tabs/>

          <div className={'alert-success'}>
            <p>Some other component</p>
          </div>

          <TabsContent/>
        </TabsWrapper>

        <h5>4. Tabs with contnent components and custom tab render</h5>

        <TabsWrapper data={data4}
                     index={this.state.tabs4}
                     onChangeIndex={(index) => this.setState({tabs3: index})}>
          <Tabs renderTabItem={(item) => {
            return <span>{item.name} {item.amount} / {item.max}</span>;
          }}/>

          <div className={'alert-success'}>
            <p>Some other component</p>
          </div>

          <TabsContent/>
        </TabsWrapper>

        <h5>5. Tabs with custom tab render - skip some tab</h5>

        <Tabs className={'mt-4 mb-4'}
              data={data2}
              index={this.state.tabs5}
              onChangeIndex={(index) => this.setState({tabs5: index})}
              renderTabItem={(item) => {
                if(item.max - item.amount < 0){
                  return false;
                }

                return <span>{item.name} {item.amount} / {item.max}</span>;
              }}/>
      </div>
    );
  }
}

export default TabUI;
