import React from 'react';
import PropTypes from 'prop-types';
import {ModalHeader, ModalBody} from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import Spinner from '../../../Spinner';
import ModalWithNotifier from '../../../Generic/Modals/ModalWithNotifier';
import CreateDeckListContainer from '../../CreateDeckListContainer';

class CreateDeckTeamModalContainer extends React.Component {
  renderModalBody() {
    const { deck, onClose, onSave } = this.props;
    if(!deck) {
      return <Spinner />;
    }

    return (
      <CreateDeckListContainer
        id={deck.id}
        disableFormat
        unregistered
        onSave={onSave}
        inModal={true}
        btnFixed={true}
        onClose={onClose}
      />
    );
  }

  render() {
    const {isOpen, onClose} = this.props;

    return (
      <ModalWithNotifier isOpen={isOpen} toggle={onClose} size="lg" backdrop={false} className="decklist-modal">
        <ModalHeader toggle={onClose}>
          <span className="section-header"><FormattedMessage id="tournament.players.deckbuilder.modal.title"/></span>
        </ModalHeader>
        <ModalBody>
          {this.renderModalBody()}
        </ModalBody>
      </ModalWithNotifier>
    );
  }
}

CreateDeckTeamModalContainer.propTypes = {
  deck: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default CreateDeckTeamModalContainer;
