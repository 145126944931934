import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

class TournamentUserData extends React.Component {
  getUrlParams() {
    return this.props.match.params;
  }

  render() {
    const {userData} = this.props;
    const {onFormSubmit, showSaveBtn, showBreadcrumb, error} = this.props;
    const tournamentId = this.getUrlParams().overviewId;
    const isEnableSaveBnt = !!userData.firstName && !!userData.lastName;

    const lastNameError = error && error.extras && error.extras.lastName;
    const firstNameError = error && error.extras && error.extras.firstName;
    const dciNumberError = error && error.extras && error.extras.dciNumber;
    const dciError = error && (error.error === 'DCIAlreadyRegistered') && error.error_description;

    return (
      <div className="mb-3 pb-2">
        {showBreadcrumb && <div className="user-page-options">
          <Link to={`/tournaments/show/${tournamentId}/users`} className="back-link">
            {'< Tournament'}
          </Link>
        </div>}

        <form onSubmit={(e) => onFormSubmit(e, userData)}>
          <div className="user-data">
            <div className="row">
              <div className="col-12 col-md-4">
                <label className="text-uppercase"><FormattedMessage id="tournament.players.modal.label.last.name"/></label>
                <input type="text"
                       autoComplete="nope" autofill="off" spellCheck="false" autoCorrect="off" autoCapitalize="off"
                       className={classnames('form-control user-disabled user-name', {
                         'is-invalid': lastNameError
                       })}
                       value={userData.lastName}
                       name={'lastName'}
                       onChange={(e) => this.props.changeUserData(e)}
                />
                {lastNameError && <span className="text-danger">{lastNameError}</span>}
              </div>
              <div className="col-12 col-md-4">
                <label className="text-uppercase"><FormattedMessage id="tournament.players.modal.label.first.name"/></label>
                <input type="text"
                       autoComplete="nope" autofill="off" spellCheck="false" autoCorrect="off" autoCapitalize="off"
                       className={classnames('form-control user-disabled user-name', {
                        'is-invalid': firstNameError
                        })}
                       value={userData.firstName}
                       name={'firstName'}
                       onChange={(e) => this.props.changeUserData(e)}
                />
                {firstNameError && <span className="text-danger">{firstNameError}</span>}
              </div>
              <div className="col-12 col-md-4">
                <label className="text-uppercase"><FormattedMessage id="tournament.players.modal.label.dci"/></label>
                <input type="text"
                       autoComplete="nope" autofill="off" spellCheck="false" autoCorrect="off" autoCapitalize="off"
                       className={classnames('form-control user-disabled user-dci', {
                        'is-invalid': dciNumberError || dciError
                        })}
                       value={userData.dciNumber}
                       name={'dciNumber'}
                      onChange={(e) => this.props.changeUserData(e)}
                />
                {(dciNumberError || dciError) && <span className="text-danger">{dciNumberError || dciError}</span>}
              </div>
            </div>
          </div>

          {showSaveBtn && <div className="d-flex justify-content-center mobile-btn-wrapper mt-5">
            <button type="submit" className="btn btn-success btn-lg flex-grow-1 flex-lg-grow-0" disabled={!isEnableSaveBnt}>
              <span>Save</span>
            </button>
          </div>}
        </form>

      </div>
    );
  }
}

TournamentUserData.propTypes = {
  userData: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func,
  showSaveBtn: PropTypes.bool,
  showBreadcrumb: PropTypes.bool,
  match: PropTypes.object.isRequired,
  changeUserData: PropTypes.func,
  error: PropTypes.object,
};

export default withRouter(TournamentUserData);
