import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import PopularDecks from './Boxes/PopularDecks';
import PopularCards from './Boxes/PopularCards';
import MetricBox from './MetricBox';

import {fetch} from '../../../actions/organizerStatistics';

import tournamentsImg from '../../../assets/images/metrics/tournaments.png';

import './OrganizerMetrics.scss';

class OrganizerMetrics extends React.Component {
  state = {
    data: {
      popularDecksFormat: '',
      popularCardsFormat: '',
    },
  };

  static getDerivedStateFromProps(props) {
    const {statistics} = props;

    return {
      data: {
        popularDecksFormat: statistics.deck_names.params.formatId,
        popularCardsFormat: statistics.cards.params.formatId,
      }
    };
  }

  changePopularDecksFormat = (selectedOption) => {
    const {statistics} = this.props;
    const value = selectedOption.value;

    this.props.fetch('deck_names', {
      ...statistics.deck_names.params,
      formatId: value
    }, false);
  };

  changePopularDecksFilterDate = (filterDate) => {
    const {statistics} = this.props;

    this.props.fetch('deck_names', {
      ...statistics.deck_names.params,
      ...filterDate
    }, false);
  };

  changePopularCardsFormat = (selectedOption) => {
    const {statistics} = this.props;
    const value = selectedOption.value;

    this.props.fetch('cards', {
      ...statistics.cards.params,
      formatId: value
    }, false);
  };

  changePopularCardsFilterDate = (filterDate) => {
    const {statistics} = this.props;

    this.props.fetch('cards', {
      ...statistics.deck_names.params,
      ...filterDate
    }, false);
  };

  render() {
    const {statistics, formats} = this.props;
    const {data} = this.state;

    const formatOptions = formats.map(item => ({value: item.id, label: item.name}));

    return (
      <div className="row metrics metrics-organizer">
        <div className="col-12 col-md-4">
          <MetricBox titleKey={'metrics.title.yourTournament'} bgImage={tournamentsImg}>
            <ul>
              <li>{statistics.tournaments.data.tournaments} Tournaments</li>
              <li>{statistics.tournaments.data.users} Users</li>
              <li>{statistics.tournaments.data.players} Players</li>
              <li>{statistics.tournaments.data.judges} Judges</li>
              <li>{statistics.tournaments.data.organizers} Organizers</li>
            </ul>
          </MetricBox>
        </div>

        <div className="col-12 col-md-8">
          <PopularCards formatOptions={formatOptions}
                        format={data.popularCardsFormat}
                        onChangeFormat={this.changePopularCardsFormat}
                        data={statistics.cards.data}
                        isLoading={statistics.cards.isLoad}
                        filterDate={{
                          since: statistics.cards.params.since,
                          until: statistics.cards.params.until,
                        }}
                        onChangeFilterDate={this.changePopularCardsFilterDate}/>
        </div>

        <div className="col-12">
          <PopularDecks formatOptions={formatOptions}
                        format={data.popularDecksFormat}
                        onChangeFormat={this.changePopularDecksFormat}
                        data={statistics.deck_names.data}
                        isLoading={statistics.deck_names.isLoad}
                        filterDate={{
                          since: statistics.deck_names.params.since,
                          until: statistics.deck_names.params.until,
                        }}
                        onChangeFilterDate={this.changePopularDecksFilterDate}/>
        </div>
      </div>
    );
  }
}

OrganizerMetrics.propTypes = {
  statistics: PropTypes.shape({
    deck_names: PropTypes.object.isRequired,
    tournaments: PropTypes.object.isRequired,
  }).isRequired,
  formats: PropTypes.array.isRequired,
  fetch: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    fetch,
  }
)(OrganizerMetrics);
