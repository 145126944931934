export const getPlayerDeckFormat = (deckFormats) => {
  const isSame = deckFormats.every(elem => {
    return elem === deckFormats[0];
  });

  if (isSame) {
    return deckFormats.map((elem, index) => {
      return `${elem} Seat ${String.fromCharCode(65 + index)}`;
    });
  }

  return deckFormats;
};
