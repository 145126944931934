import * as React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import Toggle from 'react-toggle';

import { fetch, addCard, deleteCard, deleteAllCards, changeQtyCard, changeQtyCardUpdate} from '../../actions/cardsAction';
import { cardImageShow } from '../../actions/cardSearchAction';
import { getArenaFormat } from '../../actions/deckActions';

import {FormattedMessage} from 'react-intl';

import Spinner from '../Spinner';
import DeckList from '../DeckBuilder/DeckList/DeckList';
import DeckListNav from '../DeckBuilder/DeckListNav/DeckListNav';
import DeckFromText from './DeckFromText/DeckFromText';
import DeckImport from './DeckImport/DeckImport';
import DeckToClipboardContainer from './DeckToClipboard/DeckToClipboardContainer';
import AddCustomCards from './AddCustomCards/AddCustomCards';
import * as ModalActions from '../../actions/modal';
import modalNames from '../modals/names';

import CardSearch from '../DeckBuilder/CardSearch/CardSearch';

import './Deck.scss';

class Deck extends React.Component {
  constructor() {
    super();

    this.state = {
      data: null,
      groupByTypes: true,
      activeNavIndex: 0,
      newCard: {},
      amount: 0,
    };
  }

  componentDidMount() {
    // Remove last card preview
    this.props.cardImageShow({
      value: null
    });

    this.props.fetch(this.props.id);
    this.props.getArenaFormat(this.props.id);
  }

  static getDerivedStateFromProps(nextProps, state){
    const nextState = {
      data: nextProps.cards,
    };

    const formatSectionAmount = nextProps.deckFormat.details.length;
    const activeNavIndex = state.activeNavIndex;

    if(activeNavIndex > formatSectionAmount - 1){
      return {
        ...nextState,
        activeNavIndex: 0,
      };
    }

    return nextState;
  }

  addCard = (card, amount, type, isNeedFullValid = false) => {
    const { id, deckFormat, inModal } = this.props;
    const { activeNavIndex } = this.state;

    this.props.addCard({
      deckId: id,
      format: deckFormat,
      formatSectionIndex: type ? deckFormat.details.findIndex(item => item.variable === type) : activeNavIndex,
      card,
      amount,
      isNeedFullValid,
      inModal: inModal
    });

    this.setState({
      newCard: card,
      amount,
    });
  }

  openDeleteConfirmation = () => {
    const onSubmit = this.deleteAllCards;

    this.props.openModal(modalNames.DELETE_CONFIRMATION, {
      onSubmit
    });
  }

  deleteAllCards = () => {
    const { deleteAllCards, id } = this.props;

    deleteAllCards({deckId: id});
  }

  onChangeData = (action /* = 'DELETE' | 'CHANGE_AMOUNT' | 'CHANGE_AMOUNT_PREPARE' */, changedCard, data) => {
    const { id, deckFormat } = this.props;
    const { activeNavIndex } = this.state;

    this.setState({
      data,
    });

    if(action.type === 'CHANGE_AMOUNT') {
      this.props.changeQtyCard({
        elementId: changedCard.id,
        deckId: id,
        format: deckFormat,
        formatSectionIndex: activeNavIndex,
        amount: action.data,
        card: changedCard.card,
      });
    }

    if(action.type === 'CHANGE_AMOUNT_PREPARE') {
      this.props.changeQtyCardUpdate({
        elementId: changedCard.id,
        deckId: id,
        format: deckFormat,
        formatSectionIndex: activeNavIndex,
        amount: action.data,
        card: changedCard.card,
      });
    }

    if(action.type === 'DELETE') {
      this.props.deleteCard({
        elementId: changedCard.id,
        deckId: id,
        format: deckFormat,
        formatSectionIndex: activeNavIndex,
        card: changedCard.card,
      });
    }
  }

  toggleView = () => {
    this.setState({
      groupByTypes: !this.state.groupByTypes
    });
  }

  onChangeNavIndex = (index) => {
    this.setState({
      activeNavIndex: index
    });
  }

  onSelectCardInDeckList = (cardData) => {
    this.props.cardImageShow({
      value: cardData.card.imageUrl
    });
  }

  onDeselectCardInDeckList = () => {
    this.props.cardImageShow({
      value: null
    });
  }

  getSearchColorsParam() {
    const {data, activeNavIndex} = this.state;
    const {deckFormat} = this.props;

    if(!data) {
      return null;
    }
    if(deckFormat.name !== 'Commander' && deckFormat.name !== 'Custom Commander') {
      return null;
    }
    if(deckFormat.details[activeNavIndex].variable === 'commander') {
      return null;
    }

    const commanderIndex = deckFormat.details.findIndex(item => item.variable === 'commander');
    const commanderSection = data[commanderIndex];

    if(commanderSection.length === 0){
      return null;
    }

    return commanderSection[0].card.colorIdentity;
  }

  render() {
    const {data, groupByTypes, activeNavIndex} = this.state;
    const {showSearch, showSwitch, disabled, deckFormat, deckValidation, inModal, supportCustomCards, id, userDeck, deck, arenaFormat} = this.props;
    const groupAmounts = data ? data.map((group) => group.reduce((prev, current) => prev + current.amount, 0)) : [];

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="d-block d-lg-none d-xl-none mb-4">
            <DeckListNav index={activeNavIndex}
                         onChangeIndex={this.onChangeNavIndex}
                         format={deckFormat}
                         groupAmounts={groupAmounts}/>
          </div>
          {showSearch && <CardSearch onAddClick={this.addCard}
                                     data={data}
                                     deckId={this.props.id}
                                     format={deckFormat}
                                     colors={this.getSearchColorsParam()}
                                     inModal={inModal}
                                     activeIndex={activeNavIndex}/>}

          <div className="pb-4">
            {supportCustomCards && <span className="cards-import-link">
              <AddCustomCards activeNavIndex={activeNavIndex} deckId={id} deckFormat={deckFormat} inModal={inModal} userDeck={userDeck}/>
            </span>}
          </div>
          <div className="font-weight-bold pb-2"><FormattedMessage id="import.options.text" /></div>
          <div className="cards-import-wrapper pb-4">
            <span className="cards-import-link pr-3">
              <DeckFromText
                addCard={this.addCard}
                deckId={this.props.id}
                format={deckFormat}
                inModal={this.props.inModal}
              />
            </span>
            <span className="cards-import-link import-from-file pr-3">
              <DeckImport
                addCard={this.addCard}
                deckId={this.props.id}
                format={deckFormat}
                inModal={this.props.inModal}
              />
            </span>
          </div>
        </div>

        <div className="col-lg-7">
          <div className="d-none d-lg-block d-xl-block">
            <DeckListNav index={activeNavIndex}
                         onChangeIndex={this.onChangeNavIndex}
                         format={deckFormat}
                         groupAmounts={groupAmounts}/>
          </div>
          { data ?
          <DeckList data={data}
                    onChangeData={this.onChangeData}
                    onDeleteAllCards={this.openDeleteConfirmation}
                    groupByTypes={groupByTypes}
                    activeIndex={activeNavIndex}
                    disabled={disabled}
                    onSelect={this.onSelectCardInDeckList}
                    onDeselect={this.onDeselectCardInDeckList}
                    validation={deckValidation}/>
          : <div><Spinner /></div>
          }
        </div>
        <div className="col-lg-5 deck-image__container justify-content-center d-none d-lg-block d-xl-block">
          {this.props.imageShow.value && <img className="img-fluid" src={this.props.imageShow.value} alt=""/>}
        </div>
        <div className="col-lg-12 d-flex">
          <div className="d-flex flex-column">
            <span className="cards-import-link pr-3">
              <DeckToClipboardContainer data={data} format={deckFormat} inModal={inModal} isArena={false} onlySideboard={false} />
            </span>
            <span className="cards-import-link pr-3">
              <DeckToClipboardContainer data={data} format={deckFormat} inModal={inModal} isArena={false} onlySideboard/>
            </span>
          </div>
          {
            arenaFormat.data && (
              <span className="cards-import-link pr-3">
                <DeckToClipboardContainer data={arenaFormat.data} format={deckFormat} inModal={inModal} isArena onlySideboard={false}/>
              </span>
            )
          }
          <span className="cards-import-link pr-3">
            {
              deck.lastModifiedAt && deck.parent &&
              <div className="custom-link">
                <a href={`/shared-deck/${deck.parent.id}`} target="_blank" className="link">Share deck URL</a>
              </div>
            }
          </span>
        </div>

        {/* <button onClick={this.toggleView}>Toggle view</button> */}
        {showSwitch && <label className="mt-3 d-none d-lg-flex d-xl-flex">
          <Toggle
            defaultChecked={groupByTypes}
            icons={false}
            onChange={this.toggleView}
          />
            <span className="pl-2">Headings by category</span>
        </label>}
      </div>
    );
  }
}

Deck.propTypes = {
  imageShow: propTypes.object,
  fetch: propTypes.func,
  id: propTypes.string,
  addCard: propTypes.func,
  deleteCard: propTypes.func,
  deleteAllCards: propTypes.func,
  changeQtyCard: propTypes.func,
  changeQtyCardUpdate: propTypes.func,
  postedCard: propTypes.object,
  showSearch: propTypes.bool.isRequired,
  showSwitch: propTypes.bool.isRequired,
  showCardAmountInNav: propTypes.bool,
  disabled: propTypes.bool,
  cardImageShow: propTypes.func,
  deckFormat: propTypes.object.isRequired,
  deckValidation: propTypes.object.isRequired,
  inModal: propTypes.bool,
  supportCustomCards: propTypes.bool,
  userDeck: propTypes.bool,
  deck: propTypes.object.isRequired,
  getArenaFormat: propTypes.func,
  arenaFormat: propTypes.object,
  openModal: propTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => ({
    imageShow: state.search.selectedImage,
    cards: state.deckCards.data,
    deck: state.fetchDeck.data,
    id: ownProps.id,
    postedCard: state.deckCards.postedCard,
    deckFormats: state.formats.data,
    deckValidation: state.deckValidation,
    arenaFormat: state.arenaFormat,
  }),
  ({
    fetch,
    addCard,
    deleteCard,
    deleteAllCards,
    changeQtyCard,
    changeQtyCardUpdate,
    cardImageShow,
    getArenaFormat,
    openModal: ModalActions.open,
  })
)(Deck);
