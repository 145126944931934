import {NOTIFICATION} from './types';

/**
 *
 * @param {Object} message
 * @param {String} [message.text]     - required if no param {intlKey}
 * @param {String} [message.intlKey]  - required if no param {text}
 * @param {String} [message.type]
 * @param {Number} [message.timeout=5]
 * @param {String} [message.key]
 * @param {String} [message.target='main']
 * @returns {{type: string, payload: *}}
 */
export const add = (message) => {
  const defaultMessage = {
    timeout: 5,
    target: 'main',
  };

  return {
    type: NOTIFICATION.ADD,
    payload: {...defaultMessage, ...message},
  };
};

export const remove = (id) => ({
  type: NOTIFICATION.REMOVE,
  payload: id,
});

export default {
  add,
  remove,
};
