import * as React from 'react';
import { createLogic } from 'redux-logic';
import { replace } from 'react-router-redux';

import {
  TOURNAMENT,
  TOURNAMENTS,
  TOURNAMENTS_ALL,
  SAVE_TOURNAMENT,
  TOURNAMENT_ROLES_JUDGE,
  TOURNAMENT_ROLES_ORGANIZER,
  TOURNAMENTS_PLAYER,
  TOURNAMENT_JOIN,
  TOURNAMENT_ASSOCIATIONS,
  TOURNAMENTS_JUDGE,
  DELETE_TOURNAMENT,
  CREATE_TOURNAMENT,
  SAVE_TOURNAMENT_AND_UPDATE,
  TOURNAMENT_JOIN_SIMPLE,
} from '../actions/types';

import { add as addNotification } from '../actions/notificationActions';
import {
  getTournamentsPlayer as getTournamentsPlayerAction,
  getTournaments as getTournamentsAction,
  setTournament as setTournamentAction,
  getTournamentsJudge as getTournamentsJudgeAction,
  getAllTournaments as getAllTournamentsAction,
  joinTournamentModalAction,
  joinIndividualTournament as joinIndividualTournamentAction,
  joinTeamTournament as joinTeamTournamentAction,
  openJoinTeamTournamentModal,
} from '../actions/tournamentActions';

import { fetch } from '../actions/deckActions';
import { tournamentRolesIndex } from '../components/const';
import { FormattedMessage } from 'react-intl';

export const getTournaments = createLogic({
  type: TOURNAMENTS.FETCH,
  // latest: true,

  processOptions: {
    successType: TOURNAMENTS.SUCCESS,
    failType: TOURNAMENTS.ERROR,
  },

  process({ httpClient, action }) {
    const types = action.filterByType && action.filterByType.length > 0 ? action.filterByType : null;
    const status = action.filterByStatus && action.filterByStatus.length > 0 ? action.filterByStatus : null;

    return httpClient.get('/api/v1/' + (action.userId ? `users/${action.userId}/tournaments` : `me/tournaments`), {
      params: {
        page: action.page,
        role: action.role,
        name: action.searchQuery,
        sort: action.sort,
        order: action.order,
        types,
        status,
      }
    })
      .then(res => res);
  }
});

export const getTournamentsPlayer = createLogic({
  type: TOURNAMENTS_PLAYER.FETCH,
  latest: true,

  processOptions: {
    successType: TOURNAMENTS_PLAYER.SUCCESS,
    failType: TOURNAMENTS_PLAYER.ERROR,
  },

  process({ httpClient, action }) {
    const types = action.filterByType && action.filterByType.length > 0 ? action.filterByType : null;
    const status = action.filterByStatus && action.filterByStatus.length > 0 ? action.filterByStatus : null;

    return httpClient.get('/api/v1/' + (action.userId ? `users/${action.userId}/player_tournaments` : `me/player_tournaments`), {
      params: {
        page: action.page,
        name: action.searchQuery,
        sort: action.sort,
        order: action.order,
        types,
        status,
      }
    })
      .then(res => res);
  }
});

export const getTournamentsJudge = createLogic({
  type: TOURNAMENTS_JUDGE.FETCH,
  latest: true,

  processOptions: {
    successType: TOURNAMENTS_JUDGE.SUCCESS,
    failType: TOURNAMENTS_JUDGE.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get('/api/v1/' + (action.userId ? `users/${action.userId}/tournaments` : `me/tournaments`), {
      params: {
        page: action.page,
        role: 2
      }
    })
      .then(res => res);
  }
});

export const getTournament = createLogic({
  type: TOURNAMENT.FETCH,
  latest: true,

  processOptions: {
    successType: TOURNAMENT.SUCCESS,
    failType: TOURNAMENT.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get('/api/v1/tournaments/' + action.id)
      .then(res => res.data);
  }
});

export const getTournamentByPasscode = createLogic({
  type: TOURNAMENT.FETCH_BY_PASSCODE,
  latest: true,

  processOptions: {
    successType: TOURNAMENT.SUCCESS,
    failType: TOURNAMENT.ERROR,
  },

  process({ httpClient, clientContextHttpClient, action }) {
    const {passcode, isClientContext} = action.payload;

    const httpInstance = isClientContext ? clientContextHttpClient : httpClient;

    return httpInstance.get(`/api/v1/tournaments/${passcode}/code`)
      .then(res => res.data);
  }
});

export const getAllTournaments = createLogic({
  type: TOURNAMENTS_ALL.FETCH,
  latest: true,

  processOptions: {
    successType: TOURNAMENTS_ALL.SUCCESS,
    failType: TOURNAMENTS_ALL.ERROR,
  },

  process({ httpClient, action }) {
    const { page, name, filterByType, filterByStatus, sort, order } = action.payload;
    const params = name ? { page, name } : { page };
    const types = filterByType && filterByType.length > 0 ? filterByType : null;
    const status = filterByStatus && filterByStatus.length > 0 ? filterByStatus : null;

    return httpClient.get('/api/v1/tournaments', {
        params: {
          ...params,
          types,
          status,
          sort: sort,
          order: order,
        }
    })
      .then(res => res);
  }
});

export const saveTournament = createLogic({
  type: SAVE_TOURNAMENT.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.patch('/api/v1/tournaments/'+action.id, action.data)
      .then(res => res.data)
      .then(data => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="tournament.save.success"/>
        }));
        dispatch(replace(`/tournaments/show/${action.id}/2`));

        dispatch({
          type: SAVE_TOURNAMENT.SUCCESS,
          payload: data
        });
      })
      .catch(err => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="tournament.save.error"/>
        }));
        dispatch({
          type: SAVE_TOURNAMENT.ERROR,
          payload: err
        });
      })
      .then(done);
  }
});

export const saveTournamentAndUpdate = createLogic({
  type: SAVE_TOURNAMENT_AND_UPDATE,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const payload = action.payload;

    return httpClient.patch(`/api/v1/tournaments/${payload.id}`, payload.data)
      .then(res => res.data)
      .then(data => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="tournament.save.success"/>
        }));

        dispatch(setTournamentAction(data));

        payload.nextActions.forEach((action) => dispatch(action));
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="tournament.save.error"/>
        }));
      })
      .then(done);
  }
});

export const createTournament = createLogic({
  type: CREATE_TOURNAMENT.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const payload = action.payload;

    return httpClient.post('/api/v1/tournaments', payload.data)
      .then(res => res.data)
      .then(data => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="tournament.created.success"/>
        }));

        payload.nextActions.forEach((getAction) => dispatch(getAction(data)));
      })
      .catch((err) => {
        dispatch(addNotification({
          type: 'danger',
          text: <FormattedMessage id="tournament.created.error"/>
        }));
        dispatch({
          type: SAVE_TOURNAMENT.ERROR,
          payload: err
        });
      })
      .then(() => done());
  }
});

export const deleteTournament = createLogic({
  type: DELETE_TOURNAMENT.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.delete('/api/v1/tournaments/'+action.id)
    .then(() => {
      dispatch(addNotification({
        type: 'success',
        text: <FormattedMessage id="tournament.delete.success"/>
      }));

      if(action.role === 'ADMIN'){
        dispatch(getAllTournamentsAction());
      }
      if(action.role === 'ORGANIZER'){
        dispatch(getTournamentsAction(1, 4));
      }
    })
    .catch(() => {
      dispatch(addNotification({
        type: 'danger',
        text: <FormattedMessage id="tournament.delete.error"/>
      }));
    })
    .then(() => done());
  }
});

export const saveTournamentClear = createLogic({
  type: SAVE_TOURNAMENT.CLEAR,
  latest: true,
});

export const getTournamentRolesJudge = createLogic({
  type: TOURNAMENT_ROLES_JUDGE.FETCH,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_ROLES_JUDGE.SUCCESS,
    failType: TOURNAMENT_ROLES_JUDGE.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get('/api/v1/me/tournaments', {
      params: { role: 2, tournament_id: action.payload }
    })
      .then(res => res.data);
  }
});

export const getTournamentRolesOrganizer = createLogic({
  type: TOURNAMENT_ROLES_ORGANIZER.FETCH,
  latest: true,

  processOptions: {
    successType: TOURNAMENT_ROLES_ORGANIZER.SUCCESS,
    failType: TOURNAMENT_ROLES_ORGANIZER.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get('/api/v1/me/tournaments', {
      params: { role: 4, tournament_id: action.payload }
    })
      .then(res => res.data);
  }
});

const getJoinTournamentErrorKey = (errorStatusCode, errorData) => {
  switch (errorStatusCode) {
    case 404:
      return 'tournament.joined.error.wrong.passcode';
    case 409:
      if(errorData === 'TournamentAlreadyStarted') {
        return 'tournament.joined.error.already.started';
      }
      if(errorData === 'DCIAlreadyRegistered') {
        return 'tournament.joined.error.already.dci';
      }
      return 'tournament.joined.error.already.joined';
    default:
      return 'tournament.joined.error.default';
  }
};

const getJoinTournamentErrorMessage = (errorStatusCode, errorData) => {
  return <FormattedMessage id={getJoinTournamentErrorKey(errorStatusCode, errorData)}/>;
};

export const joinTournament = createLogic({
  type: TOURNAMENT_JOIN.START,

  process({ httpClient, action }, dispatch, done) {
    const passCode = action.payload;

    return httpClient.get(`/api/v1/tournaments/${passCode}/code`)
      .then(res => res.data)
      .then(tournament => {
        const type = tournament.type;

        if(type === 'Team') {
          dispatch(joinTeamTournamentAction(tournament, passCode));
        }
        else {
          dispatch(joinIndividualTournamentAction(tournament));
        }
      })
      .then(done)
      .catch(err => {
        dispatch(addNotification({
          type: 'danger',
          text: getJoinTournamentErrorMessage(err.response.status, err.response.data.error)
        }));
      });
  }
});

export const joinIndividualTournament = createLogic({
  type: TOURNAMENT_JOIN.INDIVIDUAL.START,

  process({ httpClient, action }, dispatch, done) {
    const {tournament} = action.payload;

    return httpClient.put(`/api/v1/tournaments_register/${tournament.passCode}`)
      .then((res) => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="tournament.joined.success"/>
        }));

        // See: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/redux.md
        dispatch(replace('/tournaments/1'));
        dispatch(getTournamentsPlayerAction(1));
        dispatch(fetch());
        dispatch(joinTournamentModalAction(true, res.data.tournament.id, res.data.id));
      })
      .catch(err => {
        dispatch(addNotification({
          type: 'danger',
          text: getJoinTournamentErrorMessage(err.response.status, err.response.data.error)
        }));
      })
      .then(() => done());
  }
});

export const joinTeamTournament = createLogic({
  type: TOURNAMENT_JOIN.TEAM.START,

  process({httpClient, action, genericActions}, dispatch, done) {
    const {tournament, passCode} = action.payload;

    httpClient.get(`/api/v1/me/participations/${passCode}`)
      .then(() => true)
      .catch(err => err.response.status === 404 ? false : Promise.reject(err))
      .then(isParticipant => {
        if(isParticipant) {
          dispatch(genericActions.notification.add({
            type: 'danger',
            intlKey: 'tournament.joined.error.already.joined',
          }));
          done();

          return;
        }

        return Promise.all([
          httpClient.get(`/api/v1/team_decks?format=${tournament.format}`),
          httpClient.get(`/api/v1/me/decks`),
        ])
          .then(([resFormats, resDecks]) => [resFormats.data, resDecks.data])
          .then(([deckFormats, allDecks]) => {
            // API don't support filtering '/me/deck' by deck format so need to be done here
            const decks = deckFormats.reduce((prev, current) => {
              const deckWithFormat = allDecks
                .filter(deck => deck.format.name === current)
                .map(deck => ({
                  name: deck.name,
                  id: deck.id,
                  valid: deck.valid,
                }));

              return {
                ...prev,
                [current]: deckWithFormat,
              };
            }, {});

            const data = {
              tournamentId: tournament.id,
              deckFormats,
              decks,
              passCode,
            };

            dispatch(openJoinTeamTournamentModal(data));
            done();
          });
      });


  }
});

export const joinTournamentSimple = createLogic({
  type: TOURNAMENT_JOIN_SIMPLE.START,

  latest: true,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.put(`/api/v1/tournaments_register/${action.payload}`)
      .then(res => res.data)
      .then(data => {
        dispatch({
          type: TOURNAMENT_JOIN_SIMPLE.SUCCESS,
          payload: data,
        });
      })
      .catch(err => {
        dispatch({
          type: TOURNAMENT_JOIN_SIMPLE.ERROR,
          payload: getJoinTournamentErrorKey(err.response.status),
        });
      })
      .then(() => done());
  }
});

export const addAssociation = createLogic({
  type: TOURNAMENT_ASSOCIATIONS.START,

  process({ httpClient, action }, dispatch, done) {
    const promises = action.payload.map((data) => {
      return httpClient.put(`/api/v1/tournaments/${data.tournamentId}/${data.userId}/roles`, {
        roles: [
          tournamentRolesIndex[data.role.toLowerCase()]
        ]
      });
    });

    return Promise.all(promises)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          text: <FormattedMessage id="association.add.success"/>
        }));
        dispatch(getTournamentsPlayerAction(1, action.payload[0].userId));
        dispatch(getTournamentsAction(1, 4, action.payload[0].userId));
        dispatch(getTournamentsJudgeAction(1, action.payload[0].userId));
      })
      .catch((err) => {
        if(err.response.data.error_description) {
          dispatch(addNotification({
            type: 'danger',
            text: err.response.data.error_description
          }));
        } else {
          dispatch(addNotification({
            type: 'danger',
            text: <FormattedMessage id="association.add.error"/>
          }));
        }

        dispatch(getTournamentsPlayerAction(1, action.payload[0].userId));
        dispatch(getTournamentsAction(1, 4, action.payload[0].userId));
        dispatch(getTournamentsJudgeAction(1, action.payload[0].userId));
      })
      .then(() => done());
  }
});

export default {
  getTournaments,
  getAllTournaments,
  getTournamentsPlayer,
  getTournament,
  getTournamentByPasscode,
  getTournamentRolesJudge,
  getTournamentRolesOrganizer,
  saveTournament,
  saveTournamentClear,
  saveTournamentAndUpdate,
  joinTournament,
  joinIndividualTournament,
  joinTeamTournament,
  joinTournamentSimple,
  addAssociation,
  getTournamentsJudge,
  deleteTournament,
  createTournament,
};
