import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import ModalWithNotifier from '../../../Generic/Modals/ModalWithNotifier';
import {ModalHeader, ModalBody} from 'reactstrap';
import Spinner from '../../../Spinner';
import TournamentDeckBuilderModal from './TournamentDeckBuilderModal';

import {get, createDeck} from '../../../../actions/tournamentUserActions';

import './TournamentDeckBuilderModalContainer.scss';

class TournamentDeckBuilderModalContainer extends React.Component {
  componentDidMount() {
    const {userId, tournamentId} = this.props;
    this.props.get(tournamentId, userId);
  }

  createDeck = (masterDeckId) => {
    const {userId, tournamentId} = this.props;

    this.props.createDeck({
      masterDeckId,
      userId,
      tournamentId
    });
  };

  renderModalBody() {
    const {tournamentUser, tournamentId, userId, supportCustomCards} = this.props;


    const user = tournamentUser.data;
    const draft = tournamentUser.createDeck;

    if(tournamentUser.isLoad || !tournamentUser.data) {
      return <Spinner/>;
    }

    return(
      <TournamentDeckBuilderModal
        tournamentId={tournamentId}
        userId={userId}
        data={user}
        draft={draft}
        toggle={this.props.toggle}
        onCreateDeck={this.createDeck}
        supportCustomCards={supportCustomCards}
      />
    );
  }

  render() {
    const {isOpen, toggle} = this.props;

    return (
      <ModalWithNotifier isOpen={isOpen} toggle={toggle} size="lg" backdrop={false} className="decklist-modal">
        <ModalHeader toggle={toggle}>
          <span className="section-header"><FormattedMessage id="tournament.players.deckbuilder.modal.title"/></span>
        </ModalHeader>
        <ModalBody>
          {this.renderModalBody()}
        </ModalBody>
      </ModalWithNotifier>
    );
  }
}

TournamentDeckBuilderModalContainer.propTypes = {
  tournamentUser: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  createDeck: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  tournamentId: PropTypes.string.isRequired,
  supportCustomCards: PropTypes.bool
};

export default connect(
  (state) => ({
    tournamentUser: state.tournamentUser,
  }),
  {
    get,
    createDeck,
  }
)(TournamentDeckBuilderModalContainer);