import { DECK_CARDS, ADD_CARD, ADD_CUSTOM_CARD, DELETE_CARD, DELETE_ALL_CARDS, CHANGE_QTY } from './types';

export const fetch = (deckId) => ({
  type: DECK_CARDS.FETCH,
  id: deckId,
});

/**
 * @param data
 * @param data.deckId
 * @param data.amount
 * @param data.formantSectionIndex
 * @param data.format
 * @param data.card
 * @param data.isNeedFullValid
 */
export const addCard = (data) => ({
  type: ADD_CARD.START,
  payload: data,
});

/**
 * @param data
 * @param data.deckId
 * @param data.amount
 * @param data.formantSectionIndex
 * @param data.format
 * @param data.cardName
 * @param data.inModal
 * @param data.userDeck
 */
export const addCustomCard = (data) => ({
  type: ADD_CUSTOM_CARD.START,
  payload: data,
});

/**
 * @param responseData
 * @param data
 * @param data.deckId
 * @param data.amount
 * @param data.formantSectionIndex
 * @param data.format
 * @param data.card
 */
export const addCardSuccess = (responseData, data) => ({
  type: ADD_CARD.SUCCESS,
  payload: {
    responseData,
    data,
  },
});

/**
 *
 * @param data.deckId
 * @param data.elementId
 * @param data.card
 * @param data.amount
 * @param data.formantSectionIndex
 * @param data.format
 */
export const changeQtyCard = (data) => ({
  type: CHANGE_QTY.START,
  payload: data,
});

/**
 *
 * @param data.deckId
 * @param data.elementId
 * @param data.card
 * @param data.amount
 * @param data.formantSectionIndex
 * @param data.format
 */
export const changeQtyCardUpdate = (data) => ({
  type: CHANGE_QTY.UPDATE,
  payload: data,
});

/**
 *
 * @param data.deckId
 * @param data.elementId
 * @param data.card
 * @param data.formantSectionIndex
 * @param data.format
 */
export const deleteCard = (data) => ({
  type: DELETE_CARD.START,
  payload: data
});

/**
 *
 * @param data.deckId
 */
export const deleteAllCards = (data) => ({
  type: DELETE_ALL_CARDS.START,
  payload: data
});
