import ls from './LocalStorage';

export default class JoinTournamentService {
  static setTournamentData(data) {
    ls.set('tournamentData', data);
  }

  static getTournamentData() {
    return ls.get('tournamentData');
  }

  static removeTournamentData() {
    ls.remove('tournamentData');
  }
}
