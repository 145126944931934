import * as React from 'react';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {importDeck, clearImportedDeck, importDeckAllSuggestions, removeImportedCardSuggestion} from '../../../actions/deckActions';
import ExclamationMark from '../../../../src/assets/images/excl-warning.svg';
import Spinner from '../../Spinner';
import CardName from '../CardName/CardName';
import deckImport from '../../../assets/images/svg/import-file.svg';

import './DeckImport.scss';

class DeckImport extends React.Component {
  state = {
    isModalOpen: false,
  };

  toggleModal = (isOpen) => {
    this.setState({
      isModalOpen: isOpen
    });
  };

  uploadFile = (e) => {
    e.preventDefault();
    const {deckId, inModal} = this.props;
    const file = e.target.files[0];

    this.props.importDeck(deckId, file, inModal);
  };

  clearUnrecognized = () => {
    this.props.clearImportedDeck();
  }

  renderSuggestion = (suggestion) => {
    if(!suggestion) {
      return null;
    }

    const card = suggestion.card;

    return (
      <div className="suggestion-wrapper">
        <span>( did you mean  <CardName name={card.name} names={card.names}/>? )</span>
        <span className="ml-2">
          <button className="btn btn-success mr-1 btn-square" onClick={() => this.addCard(suggestion)}><i className="icon-check"/></button>
          <button className="btn btn-secondary btn-square" onClick={() => this.removeCardSuggestion(suggestion)}><i className="icon-x2"/></button>
        </span>
      </div>
    );
  }

  renderModalBody(unrecognized){
    if(!unrecognized) {
      return null;
    }
    return (
      <div>
        <p className="text-warning unrecognized-text">
          <img className="exclamation-mark mr-2"
            src={ExclamationMark}
            alt=""
          />
          We couldn&#39;t recognize some cards:</p>
        {
          unrecognized.map((item, id) => {
            return(
              <div key={id} className="unrecognize-card-wrapper"><b className="unrecognized-text unrecognized-text--name text-warning">- {item.suggestion && item.suggestion.amount} {item.name}</b> {this.renderSuggestion(item.suggestion)}</div>
            );
          })
        }
      </div>
    );
  }

  acceptAllSuggestions = () => {
    const {deckId, format, inModal} = this.props;

    this.props.importDeckAllSuggestions(this.props.importedDeck.data.unrecognizedCards, deckId, format, inModal);

    this.clearUnrecognized();
  }

  addCard = (suggestion) => {
    this.props.addCard(suggestion.card, suggestion.amount, suggestion.type, true);
    this.removeCardSuggestion(suggestion);
  }

  removeCardSuggestion = (suggestion) => {
    this.props.removeImportedCardSuggestion(suggestion);
  }

  render() {
    const {importedDeck, className} = this.props;

    if(importedDeck.isLoad) {
      return (
        <Spinner full={true} />
      );
    }
    const unrecognized = importedDeck && importedDeck.data && importedDeck.data.unrecognizedCards;
    const isOpen = unrecognized && unrecognized.length > 0;

    let isSuggestion = false;

    unrecognized && unrecognized.map( item => {
      if(item.suggestion) {
        isSuggestion = true;
      }
    });

    return (
      <div className={className}>
        <span className="deck-import custom-link">
          <img src={deckImport} className="icon"/>
          <span className="link">
            <FormattedMessage id="deck.import"/>
            <input type="file" className="file-upload" value="" onChange={this.uploadFile}/>
          </span>
        </span>
        <Modal isOpen={isOpen} size={'lg'} className="deck-from-text-modal">
          <ModalHeader>
            <span className="section-header"><FormattedMessage id="deck.import.file.modal.title"/></span>
          </ModalHeader>

          <ModalBody>
            {this.renderModalBody(unrecognized)}
          </ModalBody>

          <ModalFooter>
            {isSuggestion && <button disabled={!isSuggestion} className="btn btn-success mr-2" onClick={this.acceptAllSuggestions}><FormattedMessage id="deck.from.text.modal.btn.accept"/></button>}
            <button className="btn btn-success" onClick={this.clearUnrecognized}><FormattedMessage id="deck.from.text.modal.btn.ok"/></button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

DeckImport.propTypes = {
  importedDeck: propTypes.object,
  clearImportedDeck: propTypes.func,
  importDeck: propTypes.func,
  className: propTypes.string,
  removeImportedCardSuggestion: propTypes.func,
  deckId: propTypes.string,
  addCard: propTypes.func,
  importDeckAllSuggestions: propTypes.func,
  format: propTypes.object,
  inModal: propTypes.bool,
};

export default connect(
  (state) => ({
    importedDeck: state.importedDeck,
  }),
  ({
    importDeck,
    removeImportedCardSuggestion,
    clearImportedDeck,
    importDeckAllSuggestions
  })
)(DeckImport);
