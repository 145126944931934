import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ChangeableDropdown from './ChangeableDropdown';

class ChangeableDropdownColor extends React.Component {
  render() {
    const {className, ...props} = this.props;

    const currentItem = props.options.find(item => props.value === item.value);
    const currentColor = currentItem ? currentItem.color : null;

    return (
      <ChangeableDropdown {...props}
                          color={currentColor}
                          className={classnames(`changeable-dropdown-color-${currentColor}`, className)}/>
    );
  }
}

ChangeableDropdownColor.propTypes = {
  ...ChangeableDropdown.propTypes,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
    PropTypes.object,
  ])).isRequired,
};

export default ChangeableDropdownColor;
