import * as React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {connect} from 'react-redux';
import {fetchUserDecks, deleteDeck} from '../../actions/deckActions';
import DataTableWithMobileDropdown from '../DataTable/DataTableWithMobileDropdown';
import GoToEditDeckButton from '../Utils/GoToEditDeckButton';
import DeckProgressBar from '../Utils/DeckProgressBar';
import DeckCards from '../Utils/DeckCards';
import { FormattedMessage } from 'react-intl';
import Spinner from '../Spinner';

class UserDecks extends React.Component {
  state = {
    isOpen: false,
    deleteId: null,
    selectedTournamentId: null
  };

  componentDidMount() {
    this.props.fetchUserDecks(this.props.userId);
  }

  submit = () => {
    const {userId, deleteDeck, fetchUserDecks} = this.props;
    const {deleteId} = this.state;

    deleteDeck(deleteId, [
      fetchUserDecks(userId)
    ]);
    this.toggleModal(null, null);
  };

  toggleModal = (e, id) => {
    e && e.stopPropagation();

    this.setState({
      isOpen: !this.state.isOpen,
      deleteId: id
    });
  }

  renderDeckList = () => {
    const decks = this.props.decks.data;
    if (!decks || decks.length < 1) {
      return <div className="text-center"><FormattedMessage id="deck.no.decks"/></div>;
    }

    const columns = [
      {name: 'ID', flex: 1, key: 1},
      {name: 'DECK NAME', flex: 2, key: 2},
      {name: 'DECK CAPACITY', flex: 2, key: 3},
      {name: 'FORMAT', flex: 2, key: 4},
      {name: 'EDIT', flex: 2, key: 5, isVisibleOnMobile: false},
      {name: 'DELETE', flex: 2, key: 6, isVisibleOnMobile: false}
    ];


    const data = decks.map((item, index) => {
      return [
        `#${item.id}`,
        item.name,
        <div className="user-deck-progress" key={index + '-progress'}>
          <div className="w-100"><DeckProgressBar key={index} format={item.format && item.format.name} amount={item.amount}/></div>
          <span className="ml-2 user-deck-cards"><DeckCards key={index} format={item.format && item.format.name} amount={item.amount}/></span>
        </div>,
        item.format.name,
        <GoToEditDeckButton key={index + '-edit'} history={this.props.history} deckId={item.id}>
          <div className="tournament-link text-center">
            <i className="icon-edit"/>
          </div>
        </GoToEditDeckButton>,
        <div key={index + '-delete'} className="tournament-link text-center"
             onClick={(e) => this.toggleModal(e, item.id)}><i className="icon-delete"/></div>
      ];
    });

    const dropdownData = (dataRow, rowIndex) => [
      <GoToEditDeckButton key={rowIndex + '-edit'} history={this.props.history} deckId={decks[rowIndex].id}>
        <span className="tournament-link tournament-link-mobile"><FormattedMessage id="tournaments.btn.edit"/></span>
      </GoToEditDeckButton>,
      <span key={rowIndex + '-remove'} className="tournament-link tournament-link-mobile" onClick={(e) => this.toggleModal(e, decks[rowIndex].id)}><FormattedMessage id="btn.delete"/></span>,
    ];

    return (
      <DataTableWithMobileDropdown
        columns={columns}
        data={data}
        dropdownData={dropdownData}
      />
    );
  }

  render() {

    if(!this.props.decks.data || this.props.decks.isLoad){
      return <Spinner/>;
    }

    return (
      <div>
        <h4 className="section-header section-header--secondary mb-3 mt-5"><FormattedMessage id="deck.title"/></h4>

        <div className="tournament-list mt-3">
          <div className="tournament-list-table-wrapper">
            {this.renderDeckList()}
          </div>
        </div>
        <Modal isOpen={this.state.isOpen} toggle={(e) => this.toggleModal(e, null)} backdrop={this.state.backdrop}>
          <ModalHeader toggle={(e) => this.toggleModal(e, null)}><FormattedMessage id="deck.modal.delete"/></ModalHeader>
          <ModalBody>
            <FormattedMessage id="deck.modal.text"/>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-success" onClick={this.submit}><FormattedMessage id="tournament.delete.modal.submit"/></button>
            <button className="btn btn-outline-secondary" onClick={(e) => this.toggleModal(e, null)}><FormattedMessage id="tournament.delete.modal.cancel"/></button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

UserDecks.propTypes = {
  history: PropTypes.object,
  decks: PropTypes.object,
  fetchUserDecks: PropTypes.func,
  deleteDeck: PropTypes.func,
  userId: PropTypes.string.isRequired,
};

export default connect(
  (state) => ({
    decks: state.userDecks,
  }),
  ({
    fetchUserDecks,
    deleteDeck,
  })
)(UserDecks);
