import {SELECT_TOURNAMENT_PLAYER_BIO} from '../actions/types';

const initialState = {
  params: {
    tournamentId: null,
    playerId: null,
  },
  schema: {
    isPending: false,
    isError: false,
    data: null,
  },
  save: {
    isPending: false,
    isError: false,
    data: null,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_TOURNAMENT_PLAYER_BIO.GET_SCHEMA.START:
      return {
        ...state,
        params: {
          tournamentId: action.payload.tournamentId,
          playerId: action.payload.playerId,
        },
        schema: {
          isPending: true,
          isError: false,
          data: null,
        },
      };
    case SELECT_TOURNAMENT_PLAYER_BIO.GET_SCHEMA.SUCCESS:
      return {
        ...state,
        schema: {
          isPending: false,
          isError: false,
          data: action.payload,
        },
      };
    case SELECT_TOURNAMENT_PLAYER_BIO.GET_SCHEMA.ERROR:
      return {
        ...state,
        schema: {
          isPending: false,
          isError: true,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
