import {combineReducers} from 'redux';

import get from './get';
import update from './update';
import pagination from './pagination';
import extras from './extras';

export default combineReducers({
  get,
  update,
  pagination,
  extras
});
