import {SUBMIT_DECK_FOR_TOURNAMENT} from '../../actions/types';

const initialState = {
  isPending: false,
  isError: false,
  data: null
};

export default (state = initialState, action) => {
  switch(action.type) {
    case SUBMIT_DECK_FOR_TOURNAMENT.TOURNAMENTS.START:
      return {
        ...state,
        isPending: true,
      };
    case SUBMIT_DECK_FOR_TOURNAMENT.TOURNAMENTS.SUCCESS:
      return {
        isPending: false,
        isError: false,
        data: action.payload,
      };
    case SUBMIT_DECK_FOR_TOURNAMENT.TOURNAMENTS.ERROR:
      return {
        isPending: false,
        isError: true,
        data: action.payload,
      };
    default:
      return state;
  }
};
