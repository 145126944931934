import React from 'react';
import PropTypes from 'prop-types';

import CreateDeck from '../CreateDeck';
import DecksList from '../DecksList';

import AccessControl from '../Utils/AccessControl';
import { roles } from '../const';

class Decks extends React.Component {
  render() {
    return (
      <AccessControl roles={[roles.player]} forbiddenRedirect={'/'}>
        <div className="my-5 container">
          <CreateDeck history={this.props.history} />
          <DecksList history={this.props.history} />
        </div>
      </AccessControl>
    );
  }
}

Decks.propTypes = {
  history: PropTypes.object.isRequired
};

export default Decks;
