import {createLogic} from 'redux-logic';
import {TOURNAMENT_TEAM_DECK_FORMATS, TOURNAMENT_TEAM_DECK_FORMATS_NO_REGISTER} from '../actions/types';

export const get = createLogic({
  type: TOURNAMENT_TEAM_DECK_FORMATS.GET.START,

  processOptions: {
    successType: TOURNAMENT_TEAM_DECK_FORMATS.GET.SUCCESS,
    failType: TOURNAMENT_TEAM_DECK_FORMATS.GET.ERROR,
  },

  process({httpClient, action, getState}) {
    const {format} = action.payload;
    const state = getState();

    const fromState = state.tournamentTeamDeckFormats.byTournamentFormat[format];

    if(fromState) {
      return Promise.resolve({
        tournamentFormat: format,
        deckFormats: fromState,
      });
    }

    return httpClient.get('/api/v1/team_decks', {
      params: {
        format,
      }
    })
      .then((res) => ({
        tournamentFormat: format,
        deckFormats: res.data,
      }));
  }
});

export const getWithoutRegister = createLogic({
  type: TOURNAMENT_TEAM_DECK_FORMATS_NO_REGISTER.GET.START,

  processOptions: {
    successType: TOURNAMENT_TEAM_DECK_FORMATS_NO_REGISTER.GET.SUCCESS,
    failType: TOURNAMENT_TEAM_DECK_FORMATS_NO_REGISTER.GET.ERROR,
  },

  process({clientContextHttpClient, action, getState}) {
    const {format} = action.payload;
    const state = getState();

    const fromState = state.tournamentTeamDeckFormats.byTournamentFormat[format];

    if(fromState) {
      return Promise.resolve({
        tournamentFormat: format,
        deckFormats: fromState,
      });
    }

    return clientContextHttpClient.get('/api/v1/team_decks', {
      params: {
        format,
      }
    })
      .then((res) => ({
        tournamentFormat: format,
        deckFormats: res.data,
      }));
  }
});

export default [
  get,
  getWithoutRegister,
];
