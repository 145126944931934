import {EDIT_TOURNAMENT_PLAYER} from './types';

export const get = ({userId, playerId, tournamentId}) => ({
  type: EDIT_TOURNAMENT_PLAYER.GET.START,
  payload: {
    userId,
    playerId,
    tournamentId,
  }
});

export const getSuccess = (data) => ({
  type: EDIT_TOURNAMENT_PLAYER.GET.SUCCESS,
  payload: data,
});

export const getError = (data) => ({
  type: EDIT_TOURNAMENT_PLAYER.GET.ERROR,
  payload: data,
});

export const save = ({playerData, bioData, bioAvatar, onClose}) => ({
  type: EDIT_TOURNAMENT_PLAYER.SAVE.START,
  payload: {
    playerData,
    bioData,
    bioAvatar,
    onClose
  }
});

export const saveSuccess = (data) => ({
  type: EDIT_TOURNAMENT_PLAYER.SAVE.SUCCESS,
  payload: data,
});

export const saveError = (data) => ({
  type: EDIT_TOURNAMENT_PLAYER.SAVE.ERROR,
  payload: data,
});

export const clear = () => ({
  type: EDIT_TOURNAMENT_PLAYER.CLEAR,
});
