import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';

import MetricBox from './MetricBox';
import PopularDecks from './Boxes/PopularDecks';

import File from '../../../services/File';
import httpClient from '../../../services/httpClient';

import {fetch} from '../../../actions/adminStatistics';

import usersImg from '../../../assets/images/metrics/users.png';
import decksImg from '../../../assets/images/metrics/decklist.png';
import tournamentsImg from '../../../assets/images/metrics/tournaments.png';
import globeImg from '../../../assets/images/metrics/globe.png';
import storeImg from '../../../assets/images/metrics/store.png';
import cardImg from '../../../assets/images/metrics/card.png';

import './AdminMetrics.scss';

class AdminMetrics extends React.Component {
  state = {
    data: {
      popularCardsFormat: '',
      popularDecksFormat: '',
    }
  };

  static getDerivedStateFromProps(props) {
    const {statistics} = props;

    return {
      data: {
        popularCardsFormat: statistics.formats_cards.params.formatId,
        popularDecksFormat: statistics.deck_names.params.formatId,
      }
    };
  }

  changePopularCardsFormat = (selectedOption) => {
    const {statistics} = this.props;
    const value = selectedOption.value;

    this.props.fetch('formats_cards', {
      ...statistics.formats_cards.params,
      formatId: value
    }, false);
  };

  changePopularDecksFormat = (selectedOption) => {
    const {statistics} = this.props;
    const value = selectedOption.value;

    this.props.fetch('deck_names', {
      ...statistics.deck_names.params,
      formatId: value
    }, false);
  };

  changePopularDecksFilterDate = (filterDate) => {
    const {statistics} = this.props;

    this.props.fetch('deck_names', {
      ...statistics.deck_names.params,
      ...filterDate
    }, false);
  };

  exportTournamentLocations = () => {
    httpClient.get('/api/v1/current_stats/tournament_locations/csv')
      .then(res => File.saveAsCSV(res.data, 'tournament_locations'));
  };

  exportPopularCard = () => {
    const {data} = this.state;
    const {formats} = this.props;

    const format = formats.find(item => {
      return Number(item.id) === Number(data.popularCardsFormat);
    });
    const formatName = format.name.replace(' ', '_').toLowerCase();

    httpClient.get(`/api/v1/current_stats/formats/${data.popularCardsFormat}/cards/csv`)
      .then(res => File.saveAsCSV(res.data, `popular_cards_${formatName}`));
  };

  render() {
    const {statistics, formats} = this.props;
    const {data} = this.state;

    const playersPerTournament = Number(statistics.tournaments.data.playersPerTournament.toFixed(2));

    const formatOptions = formats.map(item => ({value: item.id, label: item.name}));

    return (
      <div className="row metrics">
        <div className="col-12 col-md-4">
          <MetricBox titleKey={'metrics.title.users'} bgImage={usersImg}>
            <ul>
              <li>Players {statistics.users.data.players}</li>
              <li>Judges {statistics.users.data.judges}</li>
              <li>Organizer {statistics.users.data.organizers}</li>
            </ul>
          </MetricBox>
        </div>

        <div className="col-12 col-md-4">
          <MetricBox titleKey={'metrics.title.decks'} bgImage={decksImg}>
            <ul>
              <li>{statistics.decks.data.master} Master Decklists</li>
              <li>{statistics.decks.data.tournament} Child Decklist</li>
            </ul>
          </MetricBox>
        </div>

        <div className="col-12 col-md-4">
          <MetricBox titleKey={'metrics.title.tournaments'} bgImage={tournamentsImg}>
            <ul>
              <li>{statistics.tournaments.data.amount} Tournaments</li>
              <li>{playersPerTournament} Registered users per tournament</li>
            </ul>
          </MetricBox>
        </div>

        <div className="col-12 col-md-8">
          <MetricBox titleKey={'metrics.title.tournamentsLocations'} bgImage={globeImg}>
            <table className="table">
              <tbody>
                {statistics.tournament_locations.data.map(item => {
                  return (
                    <tr key={`${item.city}${item.country}`}>
                      <td>{item.city || '-'}</td>
                      <td>{item.state || '-'}</td>
                      <td>{item.country || '-'}</td>
                      <td>{item.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="buttons">
              <button type="button" className="btn btn-primary" onClick={this.exportTournamentLocations}>
                <FormattedMessage id="metrics.btn.export"/>
              </button>
            </div>
          </MetricBox>
        </div>

        <div className="col-12 col-md-4">
          <MetricBox titleKey={'metrics.title.tournamentsFormats'} bgImage={tournamentsImg}>
            <ul>
              {statistics.tournament_formats.data.map(item => {
                return <li key={item.format}>{item.amount} {item.format}</li>;
              })}
            </ul>
          </MetricBox>
        </div>

        <div className="col-12 col-md-4">
          <MetricBox titleKey={'metrics.title.stores'} bgImage={storeImg}>
            <ul>
              <li>{statistics.stores.data.total} Stores</li>
            </ul>
          </MetricBox>
        </div>

        <div className="col-12 col-md-8">
          <MetricBox bgImage={cardImg}>
            <div className="header-select">
              <h4><FormattedMessage id={'metrics.title.formatCards'}/></h4>

              <Select
                options={formatOptions}
                value={data.popularCardsFormat}
                onChange={this.changePopularCardsFormat}
                searchable={false}
                clearable={false}
              />
            </div>

            <ul className={'ul-col'}>
              {statistics.formats_cards.data.map(item => {
                return <li key={item.cardGroup} title={item.names}>{item.amount} {item.names}</li>;
              })}
            </ul>

            <div className="buttons">
              <button type="button" className="btn btn-primary" onClick={this.exportPopularCard}>
                <FormattedMessage id="metrics.btn.export"/>
              </button>
            </div>
          </MetricBox>
        </div>

        <div className="col-12">
          <PopularDecks formatOptions={formatOptions}
                        format={data.popularDecksFormat}
                        data={statistics.deck_names.data}
                        filterDate={{
                          since: statistics.deck_names.params.since,
                          until: statistics.deck_names.params.until,
                        }}
                        isLoading={statistics.deck_names.isLoad}
                        onChangeFormat={this.changePopularDecksFormat}
                        onChangeFilterDate={this.changePopularDecksFilterDate}/>
        </div>
      </div>
    );
  }
}

AdminMetrics.propTypes = {
  statistics: PropTypes.shape({
    users: PropTypes.object.isRequired,
    decks: PropTypes.object.isRequired,
    tournaments: PropTypes.object.isRequired,
    tournament_locations: PropTypes.object.isRequired,
    tournament_formats: PropTypes.object.isRequired,
    formats_cards: PropTypes.object.isRequired,
  }).isRequired,
  formats: PropTypes.array.isRequired,
  fetch: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    fetch,
  }
)(AdminMetrics);
