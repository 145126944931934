import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import TournamentUserData from '../../../Tournament/Tabs/Users/TournamentUserData';

import actions from '../../../../actions/unregisteredPlayer';

class CreatePlayer extends React.Component {
  state = {
    userData: {
      firstName: '',
      lastName: '',
      dciNumber: '',
    }
  };

  onCreateUser = (e) => {
    e.preventDefault();

    const {createUser, passcode} = this.props;
    const {userData} = this.state;

    const dataToSend = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      dciNumber: userData.dciNumber ? userData.dciNumber : null,
    };

    const nextActions = [push('../create/deck')];

    createUser(passcode, dataToSend, nextActions);
  };

  changeUserData = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;

    this.setState((state) => ({
      userData: {
        ...state.userData,
        [fieldName]: value,
      },
    }));
  };

  render() {
    const {userData} = this.state;

    return (
      <TournamentUserData userData={userData}
                          onFormSubmit={this.onCreateUser}
                          showSaveBtn
                          changeUserData={this.changeUserData}/>
    );
  }
}

CreatePlayer.propTypes = {
  tournament: PropTypes.object.isRequired,
  passcode: PropTypes.string.isRequired,
  createUser: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    createUser: actions.create,
  }
)(CreatePlayer);
