import {SUBMIT_DECK_FOR_TOURNAMENT} from '../../actions/types';

export const registerDeck = ({tournamentId, deckId, onSuccess}) => ({
  type: SUBMIT_DECK_FOR_TOURNAMENT.REGISTER_DECK.START,
  payload: {
    tournamentId,
    deckId,
    onSuccess,
  }
});

export const registerDeckSuccess = (data) => ({
  type: SUBMIT_DECK_FOR_TOURNAMENT.REGISTER_DECK.SUCCESS,
  payload: data,
});

export const registerDeckError = (data) => ({
  type: SUBMIT_DECK_FOR_TOURNAMENT.REGISTER_DECK.ERROR,
  payload: data,
});

export const registerDeckClear = () => ({
  type: SUBMIT_DECK_FOR_TOURNAMENT.REGISTER_DECK.CLEAR
});

export default {
  registerDeck,
  registerDeckSuccess,
  registerDeckError,
  registerDeckClear,
};
