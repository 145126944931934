import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './Search.scss';

class Search extends React.Component {
  state = {
    searchQuery: ''
  };

  search = (e) => {
    const value = e.target.value;
    this.setState({
      searchQuery: value
    }, ()=>{
      if(value.length < 3) {
        this.props.onUpdate(null);
      } else {
        this.props.onUpdate(value);
      }

    });
  }

  render() {
    const {intl} = this.props;
    return (
        <div className="input-group">
          <input type="text" className="form-control" placeholder={intl.formatMessage({id: 'search.placeholder'})} value={this.state.searchQuery} onChange={this.search}/>
          <div className="input-group-append">
            <button className="btn btn-light" type="button"><i className="icon-search"/></button>
          </div>
        </div>
    );
  }
}

Search.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(Search);
