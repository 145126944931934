import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {
  getTournaments,
  joinTournament,
  joinTournamentClear,
  selectTournament,
  registerDeck,
  registerDeckClear,
} from '../../../actions/submitDeckForTournament/index';

import SubmitDeckModal from './SubmitDeckModal';

class SubmitDeckModalContainer extends React.Component {
  componentDidMount() {
    this.props.getTournaments();
  }

  getTournamentsData() {
    const {tournaments, deckFormat} = this.props;

    if(!tournaments.isError && tournaments.data){
      return tournaments.data.map((item) => ({
        name: item.name,
        value: item.id,
        valid: item.format === deckFormat,
        format: item.format,
      }));
    }

    return [];
  }

  getJoinErrorMessage() {
    const {joinTournamentResponse} = this.props;

    if(!joinTournamentResponse.isError){
      return null;
    }

    return <FormattedMessage id={joinTournamentResponse.data.messageKey}/>;
  }

  getRegisterDeckErrorMessage() {
    const {registerDeckResponse} = this.props;

    if(!registerDeckResponse.isError){
      return null;
    }

    if(registerDeckResponse.data.raw.response.data.error === 'CustomCardsNotAllowed') {
      return <FormattedMessage id="submitDeckModal.error.custom.cards"/>;
    }
    
    return <FormattedMessage id={registerDeckResponse.data.messageKey}/>;
  }

  clear() {
    const {joinTournamentClear, registerDeckClear, selectTournament} = this.props;

    joinTournamentClear();
    registerDeckClear();
    selectTournament(null);
  }

  onJoinTournament = (passCode) => {
    this.props.joinTournament(passCode);
  };

  onSubmit = (tournamentId) => {
    const {onSubmit, registerDeck, deckId} = this.props;

    registerDeck({
      tournamentId,
      deckId,
      onSuccess: () => {
        this.clear();
        onSubmit(tournamentId);
      },
    });
  };

  onClose = () => {
    const {onClose} = this.props;

    this.clear();
    onClose();
  };

  onChangeTournament = (tournament) => {
    const {selectTournament} = this.props;

    selectTournament(tournament);
  };

  render() {
    const {isOpen, selectedTournament, registerDeckResponse} = this.props;

    const tournamentsData = this.getTournamentsData();
    const joinErrorMessage = this.getJoinErrorMessage();
    const registerDeckErrorMessage = this.getRegisterDeckErrorMessage();

    return (
      <SubmitDeckModal isOpen={isOpen}
                       tournaments={tournamentsData}
                       selectedTournament={selectedTournament}
                       joinErrorMessage={joinErrorMessage}
                       registerDeckErrorMessage={registerDeckErrorMessage}
                       onClose={this.onClose}
                       onSubmit={this.onSubmit}
                       loading={registerDeckResponse.isPending}
                       onJoinTournament={this.onJoinTournament}
                       onChangeTournament={this.onChangeTournament}/>
    );
  }
}

SubmitDeckModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  deckFormat: PropTypes.string.isRequired,
  deckId: PropTypes.string.isRequired,

  tournaments: PropTypes.object.isRequired,
  joinTournamentResponse: PropTypes.object.isRequired,
  selectedTournament: PropTypes.string,
  registerDeckResponse: PropTypes.object.isRequired,

  getTournaments: PropTypes.func.isRequired,
  joinTournament: PropTypes.func.isRequired,
  joinTournamentClear: PropTypes.func.isRequired,
  selectTournament: PropTypes.func.isRequired,
  registerDeck: PropTypes.func.isRequired,
  registerDeckClear: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    tournaments: state.submitDeckForTournament.tournaments,
    joinTournamentResponse: state.submitDeckForTournament.joinTournament,
    selectedTournament: state.submitDeckForTournament.selectedTournament,
    registerDeckResponse: state.submitDeckForTournament.registerDeck,
  }),
  ({
    getTournaments,
    joinTournament,
    joinTournamentClear,
    selectTournament,
    registerDeck,
    registerDeckClear,
  })
)(SubmitDeckModalContainer);
