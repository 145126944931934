import {SUBMIT_DECK_FOR_TOURNAMENT} from '../../actions/types';

const initialState = {
  isPending: false,
  isError: false,
  data: null
};

const getErrorKey = (statusCode) => {
  switch (statusCode) {
    case 404:
      return 'tournament.joined.error.wrong.passcode';
    case 409:
      return 'tournament.joined.error.already.joined';
    default:
      return 'tournament.joined.error.default';
  }
};

export default (state = initialState, action) => {
  switch(action.type) {
    case SUBMIT_DECK_FOR_TOURNAMENT.JOIN_TOURNAMENT.START:
      return {
        ...state,
        isPending: true,
      };
    case SUBMIT_DECK_FOR_TOURNAMENT.JOIN_TOURNAMENT.SUCCESS:
      return {
        isPending: false,
        isError: false,
        data: action.payload,
      };
    case SUBMIT_DECK_FOR_TOURNAMENT.JOIN_TOURNAMENT.ERROR:
      return {
        isPending: false,
        isError: true,
        data: {
          raw: action.payload,
          messageKey: getErrorKey(action.payload.response.status)
        },
      };
    case SUBMIT_DECK_FOR_TOURNAMENT.JOIN_TOURNAMENT.CLEAR:
      return {...initialState};
    default:
      return state;
  }
};
