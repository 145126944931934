import * as React from 'react';
import PropTypes from 'prop-types';

class DeckProgressBar extends React.Component {
  renderCards() {
    if(this.props.format === 'Commander'){
      return `${this.props.amount.commander ? this.props.amount.commander : 0 }/${this.props.amount.maindeck ? this.props.amount.maindeck : 0}/${this.props.amount.sideboard ? this.props.amount.sideboard : 0}`;
    } else {
      return `${this.props.amount.maindeck ? this.props.amount.maindeck : 0}/${this.props.amount.sideboard ? this.props.amount.sideboard : 0}`;
    }
  }
  render() {
  return (
      <span>{this.renderCards()}</span>
  );
  }
}

DeckProgressBar.propTypes = {
  amount: PropTypes.any,
  format: PropTypes.string,
};

export default DeckProgressBar;
