import React from 'react';
import PropTypes from 'prop-types';
import {Pagination, PaginationItem} from 'reactstrap';
import {getPages} from '../../services/PaginationService';
import { withRouter } from 'react-router';
import './Pagination.scss';

class PaginationContainer extends React.Component {
  changePage = (page) => {
    const {onChangePage, url, history, paramName} = this.props;
    if(onChangePage) {
      onChangePage(Number(page));
    } else if(url) {
      const goTo = url.replace(paramName ? `:${paramName}` : ':page', page);
      history.push(goTo);
    }
  }

  renderPaginationItems(pagination, current) {
    const nCurrent = Number(current);
    
    return pagination.pages.map((item, id) => {
      const nItem = Number(item);
      const isActive = nCurrent === item;
      return (
        <PaginationItem active={isActive} key={id}>
          <span onClick={() => this.changePage(nItem)} className="page-link">{item}</span>
        </PaginationItem>);
    });
  }

  renderPagination() {
    const {totalPages, paramName} = this.props;
    const currentPage = this.props.currentPage ? this.props.currentPage : (this.props.match.params[paramName] || this.props.match.params.page);
    const pagination = getPages(currentPage, this.props.totalPages);

    const nCurrent = Number(currentPage);
    const nTotal = Number(totalPages);

    const isPrevVisible = nCurrent > 1;
    const isNextVisible = nCurrent < nTotal;

    if (!totalPages || (totalPages <= 1)) {
      return null;
    }

    return (
      <Pagination>
        {
          isPrevVisible &&
          <span onClick={() => this.changePage(nCurrent - 1)} className="page-prev">{'< Prev'}</span>
        }

        {
          pagination.isFirst &&
          <PaginationItem>
            <span onClick={() => this.changePage(1)} className="page-link">1</span><span>...</span>
          </PaginationItem>
        }

        {this.renderPaginationItems(pagination, currentPage)}

        {
          pagination.isLast &&
          <PaginationItem>
            <span>...</span>
            <span onClick={() => this.changePage(totalPages)} className="page-link">{totalPages}</span>
          </PaginationItem>
        }

        {
          isNextVisible &&
          <span onClick={() => this.changePage(nCurrent + 1)} className="page-next">{'Next >'}</span>
        }
      </Pagination>
    );
  }

  render() {
    return (
      <div> {this.renderPagination()}</div>
    );
  }
}

PaginationContainer.propTypes = {
  totalPages: PropTypes.string,
  url: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
  currentPage: PropTypes.string,
  onChangePage: PropTypes.func,
  paramName: PropTypes.string,
};

export default withRouter(PaginationContainer);
