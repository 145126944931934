import {mapValues} from 'lodash';

class CardImporter {
  static GROUP = {
    COMMANDER: 'commander',
    SIDEBOARD: 'sideboard',
    MAINDECK: 'maindeck',
  };

  static parseGroup(text) {
    if(/^\s*(sb|sideboard):?\s*$/i.test(text)) {
      return this.GROUP.SIDEBOARD;
    }
    if(/^\s*(\r\n)?\s*$/i.test(text)) {
      return this.GROUP.SIDEBOARD;
    }
    if(/^\s*(cmd|commander):?\s*$/i.test(text)) {
      return this.GROUP.COMMANDER;
    }
    if(/^\s*(md|maindeck):?\s*$/i.test(text)) {
      return this.GROUP.MAINDECK;
    }

    return false;
  }

  static stringifyGroup(groupName) {
    switch(groupName) {
      case this.GROUP.COMMANDER:
        return 'COMMANDER';
      case this.GROUP.SIDEBOARD:
        return 'SIDEBOARD';
      case this.GROUP.MAINDECK:
        return 'MAINDECK';
    }

    return false;
  }

  static parse(text, {toString = false, defaultGroup = this.GROUP.MAINDECK}) {
    const data = text.split('\n');

    let activeGroup = defaultGroup;
    const res = data
      .map((item) => item.trim())
      .reduce((prev, current) => {
        const group = this.parseGroup(current);

        if(group){
          activeGroup = group;
          return prev;
        }

        if(!prev[activeGroup]){
          prev[activeGroup] = [];
        }

        prev[activeGroup].push(current);

        return prev;
      }, {});

    if(!toString) {
      return res;
    }

    return mapValues(res, (item) => item.join('\n'));
  }

  static stringify(data) {
    return Object
      .keys(data)
      .reduce((prev, current) => {
        const value       = data[current];
        const groupValue  = Array.isArray(value) ? value.join('\n') : value;
        const groupName   = this.stringifyGroup(current);

        return `${prev}${groupName}\n${groupValue}\n`;
      }, '');
  }
}

export default CardImporter;
