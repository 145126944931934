import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import {
  getTournamentOrganizers,
  addTournamentOrganizer,
  deleteTournamentOrganizer
} from '../../actions/tournamentMembers';

import DataTableWithMobileDropdown from '../DataTable/DataTableWithMobileDropdown';
import ModalSelectUser from '../Users/ModalSelectUser';
import Prompt from '../Generic/Modals/Prompt';
import Spinner from '../Spinner';
import GenericTooltip from '../GenericTooltip';

import './Tournament.scss';

class Organizers extends React.Component {
  state = {
    isAddModalOpen: false,
    isDeleteModalOpen: false,
    deletedUserId: null,
  };

  componentDidMount(){
    this.props.getTournamentOrganizers(this.props.tournamentId);
  }

  onOpenDeleteModal = (id) => {
    this.setState({
      isDeleteModalOpen: true,
      deletedUserId: id
    });
  };

  onCloseDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: false,
      deletedUserId: null
    });
  };

  onSubmitDeleteModal = () => {
    const {deleteTournamentOrganizer, getTournamentOrganizers, tournamentId, user} = this.props;
    const {deletedUserId} = this.state;

    const isSelfDelete = user.data.id === deletedUserId;
    const nextActions = isSelfDelete
      ? [replace(`/tournaments`)]
      : [getTournamentOrganizers(tournamentId)];

    deleteTournamentOrganizer({
      tournamentId,
      userId: deletedUserId,
      nextActions
    });
  };

  onOpenAddModal = () => {
    this.setState({
      isAddModalOpen: true,
    });
  };

  onCloseAddModal = () => {
    this.setState({
      isAddModalOpen: false,
    });
  };

  onSubmitAddModal = (user) => {
    const {addTournamentOrganizer, tournamentId} = this.props;

    addTournamentOrganizer({
      tournamentId,
      userId: user.id,
    });
  };

  renderDeleteCol(member, index) {
    const {tournamentCreatorId} = this.props;

    if(tournamentCreatorId === member.id){
      return (
        <div className="text-center" style={{color: '#f5a623'}}>
          <GenericTooltip id={member.id} placement={'left'} message={'Master Organizer'}>
            <i className="icon-crown"/>
          </GenericTooltip>
        </div>
      );
    }

    return (
      <div key={`${index}-delete`}
           className="tournament-link text-center"
           onClick={() => this.onOpenDeleteModal(member.user.id)}>
        <i className="icon-delete" />
      </div>
    );
  }

  renderTable() {
    const {organizers, tournamentCreatorId} = this.props;

    const data = organizers.data.map((item,  index) => {
      return [
        item.user.dciNumber,
        item.user.lastName,
        item.user.firstName,
        <a key={`${index}-mail`} href={`mailto:${item.user.email}`}>{item.user.email}</a>,
        this.renderDeleteCol(item, index),
      ];
    });

    const columns = [
      {name: 'DCI', flex: 1},
      {name: 'LAST NAME', flex: 1},
      {name: 'FIRST NAME', flex: 1},
      {name: 'MAIL', flex: 2},
      {name: 'DELETE', flex: 1, isVisibleOnMobile: false},
    ];

    const dropdownData = (dataRow, rowIndex) => {
      const member = organizers.data[rowIndex];

      if(tournamentCreatorId === member.id){
        return [
          <span key={`${rowIndex}-remove`} className="tournament-link tournament-link-mobile">
            Master Organizer
          </span>
        ];
      }

      return [
        <span key={`${rowIndex}-remove`}
              className="tournament-link tournament-link-mobile"
              onClick={() => this.onOpenDeleteModal(member.user.id)}>
          Delete
        </span>,
      ];
    };

    return (
      <DataTableWithMobileDropdown
        data={data}
        columns={columns}
        dropdownData={dropdownData}
      />
    );
  }

  render() {
    const {organizers} = this.props;
    const {isAddModalOpen, isDeleteModalOpen} = this.state;

    if(organizers.isLoad || !organizers.data){
      return <Spinner/>;
    }

    return (
      <div>
        <h4 className="section-header mb-4">
          Organizers
          <button type="button" className="btn btn-primary float-right" onClick={this.onOpenAddModal}>
            <span className="d-none d-md-block">Add Organizer</span> <i className="icon-plus d-md-none"/>
          </button>
        </h4>
          <div className="tournament-table">
            {this.renderTable()}
          </div>

          <ModalSelectUser isOpen={isAddModalOpen}
                           title="Add organizer"
                           buttonText={{
                             submit: 'Add organizer',
                             cancel: 'Cancel'
                           }}
                           onClose={this.onCloseAddModal}
                           onSubmit={this.onSubmitAddModal}/>

          <Prompt isOpen={isDeleteModalOpen}
                  title="Delete organizer"
                  buttonText={{
                    submit: 'Yes',
                    cancel: 'No'
                  }}
                  onClose={this.onCloseDeleteModal}
                  onSubmit={this.onSubmitDeleteModal}>
            Judge will be removed from this tournament. Are you sure?
          </Prompt>
      </div>
    );
  }
}

Organizers.propTypes = {
  tournamentId: PropTypes.string.isRequired,
  tournamentCreatorId: PropTypes.string.isRequired,

  organizers: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,

  getTournamentOrganizers: PropTypes.func.isRequired,
  addTournamentOrganizer: PropTypes.func.isRequired,
  deleteTournamentOrganizer: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    organizers: state.tournamentMembers.organizers,
    user: state.user,
  }),
  ({
    getTournamentOrganizers,
    addTournamentOrganizer,
    deleteTournamentOrganizer
  })
)(Organizers);
