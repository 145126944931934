import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';

import httpClient from '../../../services/httpClient';

import Button from './PrintTournamentPlayerButton';
import View from './PrintTournamentPlayerView';

import './PrintTournamentPlayer.scss';

class PrintTournamentPlayer extends React.Component {

  static defaultProps = {
    renderContent: () => <i className="icon-print"/>
  };

  state = {
    data: null,
    disabled: false,
  };

  componentDidMount() {
    this.setState({
      disabled: !this.props.isOnline,
    });
  }

  fetchData = (onDone) => {
    const {tournamentId, userId} = this.props;

    this.setState({
      disabled: true,
    });

    httpClient.get(`/api/v1/tournaments/${tournamentId}/players/${userId}`)
      .then(res => res.data)
      .then(userData => {
        const deckId = userData.deck.id;

        return Promise.all([
          userData,
          httpClient.get(`/api/v1/tournaments/${tournamentId}`),
          httpClient.get(`/api/v1/decks/${deckId}`),
          httpClient.get(`/api/v1/decks/${deckId}/cards`),
        ]);
      })
      .then(([userData, resTournament, resDeck, resCards]) => [userData, resTournament.data, resDeck.data, resCards.data])
      .then(([userData, tournamentData, deckData, cardsData]) => {
        const data = {
          user: {
            firstName: userData.user.firstName,
            lastName: userData.user.lastName,
            dciNumber: userData.user.dciNumber,
          },
          deck: {
            name: deckData.name,
            format: deckData.format.name,
          },
          tournament: {
            name: tournamentData.name,
            startDate: tournamentData.startDate,
          },
          cards: cardsData
        };

        this.setState({
          data,
          disabled: false,
        });
        onDone();
      });
  };

  render() {
    const {data, disabled} = this.state;

    const printStyles = `
      body {
        font-size: 20px;
      }
      ul {
        list-style: none;
      }
      ul.card-list {
        display: grid;
      }
      .deck-list {
        column-width: 300px;
        padding-bottom: 20px;
      }
    `;

    return (
      <React.Fragment>
        <ReactToPrint
          copyStyles={false}
          pageStyle={printStyles}
          trigger={() => {
            return (
              <Button className="btn-icon" disabled={disabled} fetchData={this.fetchData} tag={this.props.tag}>
                {this.props.renderContent()}
              </Button>
            );
          }}
          content={() => this.printViewRef}
        />

        <View ref={(ref) => this.printViewRef = ref}
              className={'print-tournament-player-no-print'}
              data={data} />
      </React.Fragment>
    );
  }
}

PrintTournamentPlayer.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  tournamentId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  renderContent: PropTypes.func,
  tag: PropTypes.string,
};

export default PrintTournamentPlayer;
