import {USER_PERSONAL_TOKEN} from './types';

export const getAll = ({userId}) => ({
  type: USER_PERSONAL_TOKEN.GET_ALL.START,
  payload: {
    userId,
  }
});

export const generate = ({userId}) => ({
  type: USER_PERSONAL_TOKEN.GENERATE.START,
  payload: {
    userId,
  }
});

export const generateSuccess = (data) => ({
  type: USER_PERSONAL_TOKEN.GENERATE.SUCCESS,
  payload: data
});

export const generateError = (error) => ({
  type: USER_PERSONAL_TOKEN.GENERATE.ERROR,
  payload: error
});

export const regenerate = ({userId, tokenId}) => ({
  type: USER_PERSONAL_TOKEN.REGENERATE.START,
  payload: {
    userId,
    tokenId,
  }
});

export const regenerateSuccess = ({removedId, generateData}) => ({
  type: USER_PERSONAL_TOKEN.REGENERATE.SUCCESS,
  payload: {
    removedId,
    generateData,
  }
});

export const regenerateError = (error) => ({
  type: USER_PERSONAL_TOKEN.REGENERATE.ERROR,
  payload: error,
});

export const remove = ({userId, tokenId}) => ({
  type: USER_PERSONAL_TOKEN.REMOVE.START,
  payload: {
    userId,
    tokenId,
  }
});

export const removeSuccess = ({userId, tokenId}) => ({
  type: USER_PERSONAL_TOKEN.REMOVE.SUCCESS,
  payload: {
    userId,
    tokenId,
  }
});

export const removeError = (error) => ({
  type: USER_PERSONAL_TOKEN.REMOVE.ERROR,
  payload: error,
});

export default {
  getAll,

  generate,
  generateSuccess,
  generateError,

  remove,
  removeSuccess,
  removeError,

  regenerate,
  regenerateSuccess,
  regenerateError
};
