import {TOURNAMENT_TEAMS, UNREGISTERED_TEAM} from './types';

export const getTournamentTeams = (tournamentId, {page = 1, per_page = 5, filters = {}} = {}) => ({
  type: TOURNAMENT_TEAMS.GET.START,
  payload: {
    tournamentId,
    options: {
      page,
      per_page,
      filters,
    }
  }
});

export const addTournamentTeam = (tournamentId, team) => ({
  type: TOURNAMENT_TEAMS.ADD.START,
  payload: {
    tournamentId,
    team,
  }
});

export const clearAddTournamentTeam = () => ({
  type: TOURNAMENT_TEAMS.ADD.CLEAR
});

export const editTournamentTeam = (teamId, team) => ({
  type: TOURNAMENT_TEAMS.EDIT.START,
  payload: {
    teamId,
    team,
  }
});

export const clearEditTournamentTeam = () => ({
  type: TOURNAMENT_TEAMS.EDIT.CLEAR
});

export const removeTournamentTeam = (teamId) => ({
  type: TOURNAMENT_TEAMS.REMOVE.START,
  payload: {
    teamId,
  }
});

export const importTeams = (tournamentId, file) => ({
  type: TOURNAMENT_TEAMS.IMPORT.START,
  payload: {
    tournamentId,
    file
  }
});

export const updateTournamentTeamPlayer = (playerId, teamId, data) => ({
  type: TOURNAMENT_TEAMS.UPDATE_PLAYER.START,
  payload: {
    playerId,
    teamId,
    data,
  },
});

export const addUnregisterTournamentTeam = (passCode, team, nextActions) => ({
  type: UNREGISTERED_TEAM.CREATE.START,
  payload: {
    passCode,
    team,
    nextActions,
  }
});

export const getUnregisterTournamentTeam = (teamId) => ({
  type: UNREGISTERED_TEAM.GET.START,
  payload: {
    teamId,
  }
});

export const unregisteredTeamPlayerCreateDeck = (tournamentId, userId, masterDeckId) => ({
  type: UNREGISTERED_TEAM.DECK_CREATE.START,
  payload: {
    tournamentId,
    userId,
    masterDeckId,
  }
});

export const unregisteredTeamSend = (teamId, team, nextActions) => ({
  type: UNREGISTERED_TEAM.UPDATE.START,
  payload: {
    teamId,
    team,
    nextActions,
  }
});

export const unregisterTeamDeckModalCancel = () => ({
  type: UNREGISTERED_TEAM.DECK_CREATE.RESET,
});
