import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetPassword, resetPasswordReset } from '../../actions/authActions';
import TopBar from '../Layout/TopBar';
import { FormattedMessage } from 'react-intl';
import resetImg from '../../assets/images/reset.png';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      showValidation: false,
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.reset.success !== this.props.reset.success && !this.props.reset.isError) {
      this.props.resetPasswordReset();
      this.props.history.push('/check-email');
    }
  }

  validateEmail(email) {
    const reg = new RegExp([
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))/.source,
      /@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source,
    ].join(''));

    return email.length >= 2 && email.length <= 180 && reg.test(email);
  }

  submitResetPassword = (e) => {
    e.preventDefault();

    if(!this.validateEmail(this.state.email.trim())) {
      this.setState({
        showValidation: true,
      });
    } else {
      const data = {
        email: this.state.email,
      };

      this.props.resetPassword(data);
    }
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      showValidation: false,
    });
  }

  render() {
    const { reset } = this.props;
    const { email, showValidation } = this.state;
    return(
      <React.Fragment>
        <TopBar mobileBreakpoint={null} />
        <div className="container fix-topbar-padding">
            <div className="col-lg-12 text-center py-5">
              <img src={resetImg} alt=""/>
            </div>
            <p className="col-lg-12 mb-4 text-center font-weight-bold h5"><FormattedMessage id="reset.password.text"/></p>

          <div className="col-sm-8 col-md-6 col-lg-4 d-flex flex-column mx-auto text-center text-danger font-weight-bold">
            {
              showValidation &&
              !this.validateEmail(email) &&
              <p className="text-danger"><FormattedMessage id="reset.password.validation.error"/></p>
            }
            {
              reset.isError &&
              <div><FormattedMessage id="reset.password.error"/></div>
            }
          </div>

            <form className="login-form text-center mt-5" onSubmit={this.submitResetPassword}>
              <div className="form-row text-center">
                <div className="form-group col-sm-8 col-md-6 col-lg-4 d-flex flex-column mx-auto">
                  <label className="text-center" htmlFor="email" style={{letterSpacing: '1px'}}><FormattedMessage id="reset.password.email"/></label>
                  <input className="form-control" name="email" id="email" type="text" value={email} onChange={this.handleEmailChange}/>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-sm-8 col-md-6 col-lg-4 d-flex flex-column mx-auto">
                  <button className="btn btn-primary py-2"><FormattedMessage id="reset.password.btn"/></button>
                </div>
              </div>
            </form>
          </div>
      </React.Fragment>
    );
  }
}

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  resetPasswordReset: PropTypes.func.isRequired,
  reset: PropTypes.object,
  history: PropTypes.object,
};

export default connect(
  (state) => ({
    reset: state.reset,
  }),
  ({
    resetPassword,
    resetPasswordReset,
  }),
)(ResetPassword);
