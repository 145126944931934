import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Select from 'react-select';

import { fetchFormats } from '../../actions/formatsActions';
import {
  getDecknames,
  createDeckname,
  getDecknameDetails,
  updateDeckname,
  deleteDeckname,
  setDeckNameFormat
} from '../../actions/deckActions';
import AccessControl from '../Utils/AccessControl';
import { roles } from '../const';
import DeckNamesList from './DeckNamesList';
import Spinner from '../Spinner';

class DeckNames extends React.Component {
  state = {
    isAdding: false,
    selectedFormat: this.props.deckNameFormat,
    isOpen: false,
    deleteId: null,
    searchValue: ''
  };

  componentDidMount() {
    const { formats } = this.props;

    if (!formats.data) {
      this.props.fetchFormats();
    }

    if (!this.props.create &&
      (!this.props.match || !this.props.match.params.id) && !this.state.selectedFormat.value) {
      this.props.getDecknames(1);
    }

    if (this.state.selectedFormat.value) {
      this.props.getDecknames(this.state.selectedFormat.value);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match && (prevProps.match.params.page !== this.props.match.params.page)) {
      this.props.getDecknames(1, this.props.match.params.page);
    }
  }

  onFormatChange = selectedFormat => {
    this.setState({
      selectedFormat,
      searchValue: ''
    });
    this.props.setDeckNameFormat(selectedFormat);
    this.props.getDecknames(selectedFormat.value);
  }

  toggleAdd = () => {
    this.setState(prevState => ({ isAdding: !prevState.isAdding }));
  }

  toggleModal = (e, id) => {
    e && e.stopPropagation();

    this.setState({
      isOpen: !this.state.isOpen,
      deleteId: id
    });
  }

  submitDelete = () => {
    const { deleteId } = this.state;
    const formatId = this.state.selectedFormat.value;

    this.props.deleteDeckname(deleteId, formatId);
    this.toggleModal(null, null);
  }

  onSearchInputChange = (searchValue) => {
    this.setState({ searchValue });

    this.props.getDecknames(this.state.selectedFormat.value, 1, searchValue);
  }

  render() {
    const { selectedFormat, searchValue } = this.state;
    const { deckNames, create } = this.props;
    const total = deckNames && deckNames.data && deckNames.data.headers && deckNames.data.headers['x-last-page'];
    const formatValue = selectedFormat && selectedFormat.value;
    const formatOptions = this.props.formats.data && this.props.formats.data.map(format => {
      return {
        label: format.name,
        value: format.id
      };
    });

    return (
      <AccessControl roles={[roles.admin]} forbiddenRedirect={'/'}>
        <div className="container my-5">
          <h4 className="section-header mb-5">
            <FormattedMessage id="deck.name.title"/>
          </h4>
          <form className="deck-names pb-5">
            <div className="row mb-5">
              <div className="col-md-4">
                <Select
                  id="state-select"
                  options={formatOptions}
                  value={formatValue}
                  onChange={this.onFormatChange}
                  searchable={false}
                  clearable={false}
                />
              </div>
            </div>
            <div className="row">
              <div className="input-group col-md-6">
                <input
                  onChange={(e) => this.onSearchInputChange(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Deck Name..."
                  value={searchValue}
                />
                <div className="input-group-append">
                  <button className="btn btn-light" type="button"><i className="icon-search"/></button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-md-flex justify-content-end mt-3 mt-md-0">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => !create && this.props.history.push('/deck-names/add')}
                  >
                    <FormattedMessage id="deck.name.add"/>
                  </button>
                </div>
              </div>
            </div>
          </form>
          {deckNames.data && !deckNames.isLoad &&
            <DeckNamesList
              create={create}
              deckNames={deckNames.data}
              id={this.props.match && this.props.match.params.id}
              formatId={selectedFormat.value}
              currentPage={this.props.match && this.props.match.params.page}
              total={total}
              getDecknameDetails={this.props.getDecknameDetails}
              deckNameDetails={this.props.deckNameDetails.data}
              updateDeckname={this.props.updateDeckname}
              toggleModal={this.toggleModal}
              createDeckname={this.props.createDeckname}
            />
          }
          {(!deckNames.data || deckNames.isLoad) && <Spinner/>}
          <Modal isOpen={this.state.isOpen} toggle={(e) => this.toggleModal(e, null)}>
            <ModalHeader toggle={(e) => this.toggleModal(e, null)}>
              <FormattedMessage id="deck.delete.modal.header"/>
            </ModalHeader>
            <ModalBody>
              <FormattedMessage id="deck.delete.modal.body"/>
            </ModalBody>
            <ModalFooter>
              <button className="btn btn-success" onClick={this.submitDelete}>
                <FormattedMessage id="tournament.delete.modal.submit"/>
              </button>
              <button className="btn btn-outline-secondary" onClick={(e) => this.toggleModal(e, null)}>
                <FormattedMessage id="tournament.delete.modal.cancel"/>
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </AccessControl>
    );
  }
}

DeckNames.propTypes = {
  create: PropTypes.bool,
  addNotification: PropTypes.func,
  deckNames: PropTypes.object,
  getDecknames: PropTypes.func,
  fetchFormats: PropTypes.func,
  formats: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  createDeckname: PropTypes.func,
  getDecknameDetails: PropTypes.func,
  deckNameDetails: PropTypes.object,
  updateDeckname: PropTypes.func,
  deleteDeckname: PropTypes.func,
  setDeckNameFormat: PropTypes.func,
  deckNameFormat: PropTypes.object,
};

export default connect(
  (state) => ({
    deckNames: state.deckNames,
    deckNameDetails: state.deckNameDetails,
    formats: state.formats,
    deckNameFormat: state.deckNameFormat,
  }),
  ({
    createDeckname,
    getDecknames,
    getDecknameDetails,
    fetchFormats,
    updateDeckname,
    deleteDeckname,
    setDeckNameFormat
  })
)(DeckNames);
