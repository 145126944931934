import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Biography from '../../../ManageAccount/Biography';
import Spinner from '../../../Spinner';

import {getTournamentUserBiography, updateTournamentUserBiography} from '../../../../actions/biographyActions';

class TournamentBiographyContainer extends React.Component {
  componentDidMount() {
    this.props.getTournamentUserBiography(this.props.membershipId);
  }

  render() {
    const {biographyMember, redirect} = this.props;
    if(!biographyMember.data || biographyMember.isLoad){
      return <Spinner/>;
    }

    return (
      <Biography
        data={biographyMember.data}
        updateBiography={this.props.updateTournamentUserBiography}
        userId={this.props.membershipId}
        redirect={redirect}
        backText={'< User data'}
        field={'membersAnswers'}
      />
    );
  }
}

TournamentBiographyContainer.propTypes = {
  getTournamentUserBiography: PropTypes.func,
  updateTournamentUserBiography: PropTypes.func,
  biographyMember: PropTypes.object,
  userId: PropTypes.string,
  redirect: PropTypes.func,
  membershipId: PropTypes.string
};

export default connect(
  (state) => ({
    biographyMember: state.biographyMember,
    user: state.user.data,
  }),
  ({
    getTournamentUserBiography,
    updateTournamentUserBiography
  })
)(TournamentBiographyContainer);