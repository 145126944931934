import {TOURNAMENT_TEAM_DECK_FORMATS, TOURNAMENT_TEAM_DECK_FORMATS_NO_REGISTER} from '../../actions/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_TEAM_DECK_FORMATS.GET.SUCCESS: {
      const {tournamentFormat, deckFormats} = action.payload;

      return {
        ...state,
        [tournamentFormat]: deckFormats,
      };
    }
    case TOURNAMENT_TEAM_DECK_FORMATS_NO_REGISTER.GET.SUCCESS: {
      const {tournamentFormat, deckFormats} = action.payload;

      return {
        ...state,
        [tournamentFormat]: deckFormats,
      };
    }
    case TOURNAMENT_TEAM_DECK_FORMATS.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};
