import httpClient from './httpClient';

export function getAvailableTypes() {
  return httpClient.get(`/api/v1/tournament_types`).then(res => {
    return res.data;
  });
}

export const TOURNAMENT_TYPES = {
  INDIVIDUAL: 'Individual',
  TEAM: 'Team',
  DUO_STANDARD: 'Duo Standard'
};
