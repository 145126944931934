import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import TournamentUserData from '../Users/TournamentUserData';
import {createUserError} from '../../../../actions/tournamentUserActions';

class AddNewPlayerModal extends React.Component {
  state = {
    userData: {
      firstName: '',
      lastName: '',
      dciNumber: '',
  }};

  componentDidUpdate(nextProps) {
    if(!nextProps.isOpen && (nextProps.isOpen !== this.props.isOpen)) {
      return this.setState({
        userData: {
          firstName: '',
          lastName: '',
          dciNumber: '',
        }
      });
    }
    return;
  }

  onSave = () => {
    const {onSubmit} = this.props;
    const {userData} = this.state;

    const dataToSend = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      dciNumber: userData.dciNumber ? userData.dciNumber : null,
    };

    onSubmit(dataToSend);
  };

  onCancel = () => {
    const {toggle, createUserError} = this.props;
    this.setState({
      userData: {
        firstName: '',
        lastName: '',
        dciNumber: '',
    }}, ()=>{
      createUserError(null);
      toggle();
    });
  }

  changeUserData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      userData: {
        ...prevState.userData,
        [name]: value
      }
    }));
  };

  render() {
    const {isOpen, onSubmit, tournamentUser} = this.props;
    const {userData} = this.state;

    return (
      <Modal isOpen={isOpen} toggle={this.onCancel} size={'lg'}>
        <ModalHeader toggle={this.onCancel}>
          <FormattedMessage id="tournament.players.add"/>
        </ModalHeader>
        <ModalBody>
          <TournamentUserData
            userData={userData}
            onFormSubmit={onSubmit}
            changeUserData={this.changeUserData}
            error={tournamentUser.error}
          />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-success" onClick={this.onSave}>
            <FormattedMessage id="tournament.players.save"/>
          </button>
          <button className="btn btn-outline-secondary" onClick={this.onCancel}>
            <FormattedMessage id="tournament.players.cancel"/>
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

AddNewPlayerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  tournamentUser: PropTypes.object,
  createUserError: PropTypes.func,
};

export default connect(
  (state) => ({
    tournamentUser: state.tournamentUser,
  }),
  ({
    createUserError
  })
)(AddNewPlayerModal);
