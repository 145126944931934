import { createLogic } from 'redux-logic';

import { LINKED_ACCOUNTS } from '../actions/types';
import { add as addNotification } from '../actions/notificationActions';
import { getLinkedAccounts as getLinkedAccountsListAction } from '../actions/linkedAccountActions';
import { push } from 'react-router-redux';

export const getLinkedAccounts = createLogic({
  type: LINKED_ACCOUNTS.GET.START,
  latest: true,

  processOptions: {
    successType: LINKED_ACCOUNTS.GET.SUCCESS,
    failType: LINKED_ACCOUNTS.GET.ERROR,
  },

  process({ httpClient }) {
    return httpClient.get('/api/v1/me/connections')
      .then(res => res.data);
  }
});

export const linkAccount = createLogic({
  type: LINKED_ACCOUNTS.NEW.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { code, provider } = action.payload;

    return httpClient.post(`/api/v1/me/connections`, {
      code: code,
      provider: provider
    })
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          intlKey: 'linked.accounts.new.success',
        }));
        dispatch({ type: LINKED_ACCOUNTS.NEW.SUCCESS });
        dispatch(getLinkedAccountsListAction());
        dispatch(push('/manage-account/linked-accounts'));
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          intlKey: 'linked.accounts.new.error'
        }));
        dispatch({ type: LINKED_ACCOUNTS.NEW.ERROR });
      })
      .finally(() => done());
  }
});

export const unlinkAccount = createLogic({
  type: LINKED_ACCOUNTS.DELETE.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id } = action.payload;

    return httpClient.delete(`/api/v1/me/connections/${id}`)
      .then(() => {
        dispatch(getLinkedAccountsListAction());
        dispatch(addNotification({
          type: 'success',
          intlKey: 'linked.accounts.delete.success',
        }));
        dispatch({ type:  LINKED_ACCOUNTS.DELETE.SUCCESS });
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'danger',
          text: 'linked.accounts.delete.error',
        }));
        dispatch({ type:  LINKED_ACCOUNTS.DELETE.ERROR });
      })
      .then(() => done());
  }
});

export default [
  getLinkedAccounts,
  linkAccount,
  unlinkAccount
];
