import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Prompt from '../../../Generic/Modals/Prompt';

class ModalDeleteTeam extends React.Component {
  render() {
    return (
      <Prompt
        {...this.props}
        title={<FormattedMessage id="tournament.teams.delete" />}
        submitClassName={'btn-danger'}
        closeOnSubmit={true}
      >
        <FormattedMessage id="tournament.teams.remove.text" />
      </Prompt>
    );
  }
}

ModalDeleteTeam.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ModalDeleteTeam;
