import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import manaIcons from '../../DeckBuilder/CardSearch/manaIcons';
import { getStreamerDecks, deleteStreamerDeck, loadMoreStreamerDecks, editStreamerDeck } from '../../../actions/publicDecksActions';
import { getLinkedAccounts } from '../../../actions/linkedAccountActions';
import ConfirmModal from '../../Generic/Modals/ConfirmModal';

import './StreamedDecks.scss';

class StreamedDecks extends React.Component {
  state = {
    page: 1,
    deckName: '',
    editingDeckId: null,
    channelId: '',
    deletingDeckId: null,
    isModalOpen: false
  }

  componentDidMount() {
    const { linkedAccounts, getLinkedAccounts } = this.props;

    window.addEventListener('scroll', this.handleScroll);

    if (linkedAccounts.data === null) {
      getLinkedAccounts();
    } else {
      this.getChannelId();
    }
  }

  componentDidUpdate(prevProps) {
    const { linkedAccounts } = this.props;

    if (linkedAccounts.data !== prevProps.linkedAccounts.data) {
      this.getChannelId();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  getChannelId = () => {
    const { linkedAccounts } = this.props;

    linkedAccounts.data && linkedAccounts.data.map((item) => {
      if (item.provider === 'twitch') {
        this.setState({
          channelId: item.info.channelId,
        });
      }
      this.props.getStreamerDecks(item.info.channelId);
    });
  }

  handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
      return;
    }

    this.loadMore();
  };

  loadMore = () => {
    const { deckData } = this.props;
    const { page, channelId } = this.state;

    if (deckData.data.headers['x-last-page'] === deckData.data.headers['x-page']) {
      return;
    }

    if (page >= deckData.data.headers['x-last-page']) {
      return;
    }

    this.props.loadMoreStreamerDecks(channelId, page + 1);

    this.setState(state => ({
      page: state.page + 1
    }));
  }

  renderManaCost = (item) => {
    return (
      <span>
        <img className={classnames('mana-cost', {
          'visible': item.includes('W')
        })} src={manaIcons['W']} alt="" />

        <img className={classnames('mana-cost', {
          'visible': item.includes('U')
        })} src={manaIcons['U']} alt="" />

        <img className={classnames('mana-cost', {
          'visible': item.includes('B')
        })} src={manaIcons['B']} alt="" />

        <img className={classnames('mana-cost', {
          'visible': item.includes('R')
        })} src={manaIcons['R']} alt="" />

        <img className={classnames('mana-cost', {
          'visible': item.includes('G')
        })} src={manaIcons['G']} alt="" />
      </span>
    );
  }

  renderInfoBox = () => {
    return (
      <div className="info-box">
        <i className="icon-info" />
        <div>
          <FormattedMessage id="streamed.decks.info" />
          <p>Go to <Link to="/manage-account/linked-accounts">Manage account / Linked accounts</Link></p>
        </div>
      </div>
    );
  }

  onInputBlur = (deckId, displayName) => {
    const { editStreamerDeck } = this.props;

    this.setState({ editingDeckId: null });
    editStreamerDeck(deckId, displayName);
  }

  onDeckDelete = () => {
    const { deleteStreamerDeck } = this.props;
    const { deletingDeckId } = this.state;

    deleteStreamerDeck(deletingDeckId);
    this.onCloseModal();
  }

  onCloseModal = () => {
    this.setState({ isModalOpen: false, deletingDeckId: null });
  }

  renderDecks = () => {
    const { deckData, intl } = this.props;
    const { editingDeckId, isModalOpen } = this.state;

    if (!deckData.data || !deckData.data.data) {
      return null;
    }

    return (
      <Fragment>
        <ConfirmModal
          isOpen={isModalOpen}
          onClick={() => this.onDeckDelete()}
          onClose={() => this.onCloseModal()}
          headerText={intl.formatMessage({ id: 'streamed.decks.delete.header' })}
          bodyText={intl.formatMessage({ id: 'streamed.decks.delete.body' })}
        />
        {
          deckData.data.data.map((item, index) => {
            return (
              <div
                onClick={() => deckData.isLoad === false &&
                  window.open(`/shared-deck/${item.deck.id}?channel_id=${item.channel}`, '_blank')}
                className="deck-item"
                key={index}
              >
                <div className={classnames('deck-content', {
                  'active': index === 0
                })}>
                  <i
                    className="icon-delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ deletingDeckId: item.id, isModalOpen: true });
                    }}
                  />
                  <img
                    className="cover-image"
                    src={item.coverUrl}
                    alt=""
                  />
                  <span className="active-label">active</span>
                  <div className="deck-details">
                    <div className="deck-edit-wrapper">
                      {
                        index === editingDeckId
                          ?
                          <input
                            defaultValue={item.displayName}
                            onBlur={(e) => this.onInputBlur(item.id, e.target.value)}
                            ref={this.textInput}
                            autoFocus
                          />
                          :
                          <div className="deck-name">
                            {item.displayName}
                          </div>
                      }
                      <i className="icon-edit" onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ editingDeckId: index });
                      }} />
                    </div>
                    <div className="deck-info">
                      {moment(item.lastPlayedAt).fromNow()}
                    </div>
                    <div className="deck-mana">
                      {this.renderManaCost(item.colors)}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
      </Fragment >
    );
  }

  render() {
    const { deckData } = this.props;
    const { channelId } = this.state;

    return (
      <Fragment>
        <div className="container">
          <div className="streamed-deck">
            {channelId === '' ? this.renderInfoBox() : this.renderDecks()}
            {
              deckData.isLoad &&
              <div className="load-more">
                <span className="dots">...</span>
                <span>Loading</span>
              </div>
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

StreamedDecks.propTypes = {
  getStreamerDecks: PropTypes.func.isRequired,
  deleteStreamerDeck: PropTypes.func.isRequired,
  loadMoreStreamerDecks: PropTypes.func.isRequired,
  editStreamerDeck: PropTypes.func.isRequired,
  getLinkedAccounts: PropTypes.func.isRequired,
  intl: PropTypes.object,
  linkedAccounts: PropTypes.object,
  deckData: PropTypes.object,
  history: PropTypes.object,
};

export default injectIntl(connect(
  (state) => ({
    deckData: state.streamersDecks,
    linkedAccounts: state.linkedAccounts
  }),
  ({
    getStreamerDecks,
    deleteStreamerDeck,
    loadMoreStreamerDecks,
    getLinkedAccounts,
    editStreamerDeck
  })
)(StreamedDecks));
