import React from 'react';
import PropTypes from 'prop-types';

import AccessControl from '../../../Utils/AccessControl';
import MetaData from './MetaData';

import {roles} from '../../../const';

class TournamentMetaData extends React.Component {
  render() {
    const { tournament } = this.props;

    return <AccessControl roles={[roles.organizer, roles.admin]}>
      <MetaData
        tournamentId={tournament.id}
        isEditable={tournament.statsOverwrite}
        tournamentStatus={tournament.status}
        tournamentType={tournament.type}
        tournamentFormat={tournament.format}
      />
    </AccessControl>;
  }
}
TournamentMetaData.propTypes = {
  tournament: PropTypes.object,
};

export default TournamentMetaData;
