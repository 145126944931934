import React from 'react';
import classnames from 'classnames';
import {omit} from 'lodash';

import BioForm from './BioForm';

import './BioFormSCG.scss';

class BioFormSCG extends React.Component {
  render() {
    const fields = [
      [
        {
          type: 'img',
          name: '14',
          label: 'Upload Photo',
          data: {
            buttonText: 'Select file',
          }
        },
      ],
      [
        {
          type: 'input',
          name: '7',
          label: 'Age',
          data: {
            type: 'text'
          }
        },
        {
          type: 'input',
          name: '10',
          label: 'Open Wins',
          data: {
            type: 'text'
          }
        },
      ],
      [
        {
          type: 'input',
          name: '8',
          label: 'Hometown',
          data: {
            type: 'text'
          }
        },
        {
          type: 'input',
          name: '11',
          label: 'Invitational Top 8s',
          data: {
            type: 'text'
          }
        },
      ],
      [
        {
          type: 'input',
          name: '9',
          label: 'Open Top 8s',
          data: {
            type: 'text'
          }
        },
        {
          type: 'input',
          name: '12',
          label: 'Invitational Wins',
          data: {
            type: 'text'
          }
        },
      ],
      [
        {
          type: 'textarea',
          name: '13',
          label: 'More Info',
          data: {
            rows: 4,
          }
        },
      ],
    ];

    const {className, ...props} = this.props;

    return (
      <BioForm {...props} fields={fields} className={classnames('bio-form-scg', className)}/>
    );
  }
}

const parentPropTypes = omit(
  BioForm.propTypes,
  ['fields']
);

BioFormSCG.propTypes = {
  ...parentPropTypes,
};

export default BioFormSCG;
