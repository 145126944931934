import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {FormattedMessage} from 'react-intl';

import TournamentsSelect from '../../TournamentsSelect/TournamentsSelect';
import DividerTextLine from '../../Generic/DividerTextLine/DividerTextLine';

import './SubmitDeckModal.scss';

const defaultState = {
  passCode: '',
  selectErrorMessage: null,
};

class SubmitDeckModal extends React.Component {
  state = defaultState;

  selectTournament = (tournament) => {
    this.setState({
      selectErrorMessage: null,
    });

    this.props.onChangeTournament(tournament.value);
  };

  changePasscode = (e) => {
    this.setState({
      passCode: e.target.value,
    });
  };

  joinTournament = (e) => {
    const {onJoinTournament} = this.props;
    const {passCode} = this.state;

    e.preventDefault();
    onJoinTournament(passCode);

    this.setState({
      passCode: ''
    });
  };

  onClose = () => {
    const {onClose} = this.props;

    this.setState(defaultState);
    onClose();
  };

  onSubmit = () => {
    const {onSubmit, tournaments, selectedTournament} = this.props;
    const selectedTournamentObject = tournaments.find((item) => item.value === selectedTournament);

    if(!selectedTournamentObject){
      this.setState({
        selectErrorMessage: 'Choose tournament',
      });
      return;
    }

    if(!selectedTournamentObject.valid){
      this.setState({
        selectErrorMessage: 'Not valid tournament',
      });
      return;
    }

    this.setState(defaultState);
    onSubmit(selectedTournament);
  };

  render() {
    const {isOpen, tournaments, joinErrorMessage, registerDeckErrorMessage, selectedTournament, loading} = this.props;
    const {passCode, selectErrorMessage} = this.state;

    const selectedTournamentObject = tournaments.find((item) => item.value === selectedTournament);

    return (
      <Modal isOpen={isOpen} toggle={this.onClose} size={'lg'} className={'submit-deck-modal'}>
        <ModalHeader toggle={this.onClose}>Submit deck</ModalHeader>

        <ModalBody>
          <h3><FormattedMessage id="submitDeckModal.select.title"/></h3>

          <div className="row">
            <div className="col-md-12 mb-3">
              <TournamentsSelect options={tournaments}
                                 value={selectedTournamentObject}
                                 onChange={this.selectTournament}/>
            </div>
            <div className="col-md-12">
              <b className="join-error">{selectErrorMessage}</b>
            </div>
          </div>

          <DividerTextLine/>

          <h3><FormattedMessage id="submitDeckModal.join.title"/></h3>

          <form onSubmit={this.joinTournament}>
            <div className="row">
              <div className="col-md-7">
                <input type="text"
                       className="form-control mb-3"
                       placeholder="Tournament passcode"
                       value={passCode}
                       autoComplete="nope"
                       autofill="off"
                       spellCheck="false"
                       autoCorrect="off"
                       autoCapitalize="off"
                       onChange={this.changePasscode}/>
              </div>
              <div className="col-md-5">
                <button className="btn btn-form btn-success btn-block"
                        disabled={!passCode}
                        type="submit">
                  <FormattedMessage id="submitDeckModal.join.button"/>
                </button>
              </div>
              <div className="col-md-12">
                <b className="join-error">{joinErrorMessage}</b>
              </div>
            </div>
          </form>
        </ModalBody>

        <ModalFooter>
          <div className="flex-grow-1">
            <b className="join-error">{registerDeckErrorMessage}</b>
          </div>

          <button className="btn btn-success" disabled={loading} onClick={this.onSubmit}>
            <FormattedMessage id="submitDeckModal.submit"/>
          </button>
          <button className="btn btn-outline-secondary" onClick={this.onClose}>
            <FormattedMessage id="submitDeckModal.cancel"/>
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

SubmitDeckModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  tournaments: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    format: PropTypes.string.isRequired,
  })).isRequired,
  selectedTournament: PropTypes.string,
  joinErrorMessage: PropTypes.node,
  registerDeckErrorMessage: PropTypes.node,
  onJoinTournament: PropTypes.func.isRequired,
  onChangeTournament: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default SubmitDeckModal;
