import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import moment from 'moment';

class DateFormatted extends React.Component {
  static defaultProps = {
    dateFormat: 'short'
  };

  render() {
    const {intl, value, dateFormat} = this.props;

    return (
      <span>
        {moment(value).format(intl.messages.formatters.date[dateFormat])}
      </span>
    );
  }
}

DateFormatted.propTypes = {
  intl: PropTypes.object.isRequired,
  dateFormat: PropTypes.string,
  value: PropTypes.string
};

export default injectIntl(DateFormatted);
