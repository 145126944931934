import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Username from '../RegisterFormComponents/Username';
import Password from '../RegisterFormComponents/Password';
import ConfirmPassword from '../RegisterFormComponents/ConfirmPassword';
import Email from '../RegisterFormComponents/Email';
import Name from '../RegisterFormComponents/Name';
import DciNumber from '../RegisterFormComponents/DciNumber';
import httpClient from '../../services/clientContextHttpClient';
import TopBar from '../Layout/TopBar';
import Sidebar from '../Layout/Sidebar';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

const initialState = {
  submitFormError: null,
  formData: {
    username: {
      value: '',
      isValid: false,
    },
    password: {
      value: '',
      isValid: false,
    },
    passwordRepeat: {
      value: '',
      isValid: false,
    },
    email: {
      value: '',
      isValid: false,
    },
    dciNumber: {
      value: '',
      isValid: true,
    },
    firstName: {
      value: '',
      isValid: false,
    },
    lastName: {
      value: '',
      isValid: false,
    },
    judgeLevel: '1',
    roles: {
      player: false,
      organizer: false,
      judge: false,
    },
  },
};

class Register extends React.Component {
  constructor() {
    super();

    this.state = initialState;
  }

  update = (entity, property, value, subproperty) => {
    this.setState(prev => ({
      [entity]: {
        ...prev[entity],
        [property]: subproperty
          ? {...prev[entity][property], [subproperty]: value}
          : value,
      },
    }));
  };

  handleChangeCheckbox = (e, property, subproperty) => {
    this.update('formData', property, e.target.checked, subproperty);
  };

  handleChangeInput = (value, property) => {
    this.update('formData', property, value, 'value');
  };

  handleChangeSelect = (e, property) => {
    this.update('formData', property, e);
  };

  handleRegisterSubmit = e => {
    e.preventDefault();

    const {
      username,
      password,
      passwordRepeat,
      email,
      dciNumber,
      firstName,
      lastName,
      roles,
      judgeLevel,
    } = this.state.formData;

    if (
      !username.isValid ||
      !password.isValid ||
      !passwordRepeat.isValid ||
      !email.isValid ||
      !dciNumber.isValid ||
      !firstName.isValid ||
      !lastName.isValid ||
      (!roles.judge && !roles.organizer && !roles.player)
    ) {
      return this.setState({
        showValidationMessages: true,
      });
    }
    const rolesArray = [];

    if (roles.player) {
      rolesArray.push('PLAYER');
    }
    if (roles.organizer) {
      rolesArray.push('ORGANIZER');
    }
    if (roles.judge) {
      rolesArray.push('JUDGE');
    }

    return httpClient
      .post('/api/v1/register', {
        username: username.value,
        email: email.value,
        plainPassword: password.value,
        roles: rolesArray,
        firstName: firstName.value,
        lastName: lastName.value,
        dciNumber: dciNumber.value,
        judgeLevel: roles.judge ? parseInt(judgeLevel) : null,
      })
      .then(() => {
        this.setState({
          ...initialState,
        });
        this.props.history.push('/success-register');
      })
      .catch((err) => {
        this.setState({
          submitFormError: err.response.data.extras,
        });
      });
  };

  validationChanged(property, value) {
    this.update('formData', property, value, 'isValid');
  }

  renderJudgeLevels = () => {
    const {roles, judgeLevel} = this.state.formData;
    const options = [
      {label: <FormattedMessage id="register.label.level1"/>, value: 1},
      {label: <FormattedMessage id="register.label.level2"/>, value: 2},
      {label: <FormattedMessage id="register.label.level3"/>, value: 3},
    ];
    if (!roles.judge) {
      return;
    }

    return (
      <React.Fragment>
        <label htmlFor="judgeLevelSelect"><FormattedMessage id="register.label.judge.level"/></label>
        <Select
          id="judgeLevelSelect"
          options={options}
          value={judgeLevel}
          onChange={e => this.handleChangeSelect(e, 'judgeLevel')}
          searchable={false}
          clearable={false}
          simpleValue
        />
      </React.Fragment>
    );
  };

  render() {
    const {
      username,
      password,
      passwordRepeat,
      email,
      dciNumber,
      firstName,
      lastName,
      roles,
    } = this.state.formData;

    const {submitFormError} = this.state;

    const submitDisabled =
      !username.isValid ||
      !password.isValid ||
      !passwordRepeat.isValid ||
      !email.isValid ||
      !dciNumber.isValid ||
      !firstName.isValid ||
      !lastName.isValid ||
      (!roles.judge && !roles.organizer && !roles.player);

    return (
      <Fragment>
        <TopBar mobileBreakpoint={null} rightView={null} />
        <div className="fix-topbar-padding">
          <div className="full-height d-flex">
            <div className="sidebar-container d-none d-lg-block">
              <Sidebar />
            </div>
            <div className="main-container">
              <div className="my-5 container">
                <h2 className="text-info section-header mb-4"><FormattedMessage id="register.title"/></h2>
                <form className="register-form" onSubmit={this.handleRegisterSubmit}>
                <div className="d-none">
                  <input type="password" name="password" tabIndex="-1"/>
                  <input type="text" name="username" tabIndex="-1"/>
                </div>
                  <div className="form-row">
                    <div className="form-group col-md-5 form-field-wrapp">
                      <label htmlFor="user name"><FormattedMessage id="register.label.username"/></label>
                      <Username
                        value={username.value}
                        onChange={value => this.handleChangeInput(value, 'username')}
                        onValidationChanged={newValidationValue =>
                          this.validationChanged('username', newValidationValue)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-5 form-field-wrapp">
                      <label htmlFor="password"><FormattedMessage id="register.label.password"/></label>
                      <Password
                        value={password.value}
                        onChange={value => this.handleChangeInput(value, 'password')}
                        onValidationChanged={newValidationValue =>
                          this.validationChanged('password', newValidationValue)
                        }
                      />
                    </div>
                    <div className="form-group col-md-5 offset-md-1 form-field-wrapp">
                      <label htmlFor="password"><FormattedMessage id="register.label.confirm.password"/></label>
                      <ConfirmPassword
                        value={passwordRepeat.value}
                        onChange={value =>
                          this.handleChangeInput(value, 'passwordRepeat')
                        }
                        onValidationChanged={newValidationValue =>
                          this.validationChanged('passwordRepeat', newValidationValue)
                        }
                        passwordToRepeat={password.value}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-5 form-field-wrapp">
                      <label htmlFor="email"><FormattedMessage id="register.label.email"/></label>
                      <Email
                        value={email.value}
                        onChange={value => this.handleChangeInput(value, 'email')}
                        onValidationChanged={
                          newValidationValue => {
                            submitFormError && this.setState({
                              submitFormError: null
                            });
                            this.validationChanged('email', newValidationValue);
                          }
                        }
                        error={submitFormError && submitFormError.email}
                      />
                    </div>
                    <div className="form-group col-md-5 offset-md-1 form-field-wrapp">
                      <label htmlFor="dci number"><FormattedMessage id="register.label.dci"/></label>
                      <DciNumber
                        value={dciNumber.value}
                        onChange={value => this.handleChangeInput(value, 'dciNumber')}
                        onValidationChanged={newValidationValue =>
                          this.validationChanged('dciNumber', newValidationValue)
                        }
                        roles={roles}
                        isRequired={roles.player || roles.judge}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-5 form-field-wrapp">
                      <label htmlFor="first name"><FormattedMessage id="register.label.first.name"/></label>
                      <Name
                        value={firstName.value}
                        onChange={value => this.handleChangeInput(value, 'firstName')}
                        onValidationChanged={newValidationValue =>
                          this.validationChanged('firstName', newValidationValue)
                        }
                      />
                    </div>
                    <div className="form-group col-md-5 offset-md-1">
                      <label htmlFor="last name"><FormattedMessage id="register.label.last.name"/></label>
                      <Name
                        value={lastName.value}
                        onChange={value => this.handleChangeInput(value, 'lastName')}
                        onValidationChanged={newValidationValue =>
                          this.validationChanged('lastName', newValidationValue)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-5">
                      <label htmlFor="">
                        <FormattedMessage id="register.label.role"/>
                      </label>
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="form-check__player"
                        >
                          <input
                            className="form-check-input"
                            id="form-check__player"
                            type="checkbox"
                            checked={roles.player}
                            onChange={e =>
                              this.handleChangeCheckbox(e, 'roles', 'player')
                            }
                          />
                          <FormattedMessage id="register.label.role.player"/>
                        </label>
                      </div>
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="form-check__organizer"
                        >
                          <input
                            className="form-check-input"
                            id="form-check__organizer"
                            type="checkbox"
                            checked={roles.organizer}
                            onChange={e =>
                              this.handleChangeCheckbox(e, 'roles', 'organizer')
                            }
                          />
                          <FormattedMessage id="register.label.role.organizer"/>
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label" htmlFor="form-check__judge">
                          <input
                            className="form-check-input"
                            id="form-check__judge"
                            type="checkbox"
                            checked={roles.judge}
                            onChange={e =>
                              this.handleChangeCheckbox(e, 'roles', 'judge')
                            }
                          />
                          <FormattedMessage id="register.label.role.judge"/>
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-5 offset-md-1">{this.renderJudgeLevels()}</div>
                  </div>
                  <p><FormattedMessage id="register.label.obligatory"/></p>
                  {
                    submitFormError && <div className="text-danger font-weight-bold">
                      <FormattedMessage id="register.submit.error"/>
                    </div>
                  }
                  <div className="text-center">
                    <button
                      className="btn btn-success"
                      type="submit"
                      disabled={submitDisabled}
                    >
                      <FormattedMessage id="register.label.btn"/>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Register.propTypes = {
  history: PropTypes.object,
};

export default Register;
