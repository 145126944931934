import React from 'react';
import httpClient from '../../services/clientContextHttpClient';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Spinner from '../Spinner';
import registerSuccessImg from '../../assets/images/register-success.png';
import registerErrorImg from '../../assets/images/time.svg';
import AuthService from '../../services/AuthService';
import { FormattedMessage } from 'react-intl';

class EmailConfirmation extends React.Component {
  constructor() {
    super();

    this.state = {
      isVerified: false,
      verifying: true,
      errorMessage: undefined,
    };
  }

  componentDidMount() {
    const { match } = this.props;

    AuthService.logout();

    httpClient
      .put(`/api/v1/email_confirmations/${match.params.token}`)
      .then(() => {
        this.setState({
          isVerified: true,
          verifying: false,
        });
      })
      .catch(err => {
        this.setState({
          isVerified: false,
          verifying: false,
          errorMessage: err.response.data.error_description,
        });
      });
  }

  renderSuccessMessage() {
    return (
      <React.Fragment>
        <div className="confirmation-success">
          <img src={registerSuccessImg} alt="success" />
        </div>
        <h4 className="mt-3"><FormattedMessage id="mail.verified.title"/></h4>
        <h6 className="mt-3"><FormattedMessage id="mail.verified.text"/></h6>
        <Link to={'/login'} className="btn btn-primary mt-3">
          <FormattedMessage id="mail.btn.login"/>
        </Link>
      </React.Fragment>
    );
  }

  renderErrorMessage() {
    return (
      <React.Fragment>
        <div className="confirmation-error">
          <img src={registerErrorImg} alt="error" />
        </div>
        <h4 className="mt-3 font-weight-bold"><FormattedMessage id="registration.error.title"/></h4>
        <h6 className="mt-3 font-weight-bold"><FormattedMessage id="registration.error.text"/></h6>
        <Link to={'/login'} className="btn btn-primary mt-3">
          <FormattedMessage id="mail.btn.login"/>
        </Link>
      </React.Fragment>
    );
  }

  render() {
    const { isVerified, verifying } = this.state;
    return (
      <div className="email-confirmation text-center pt-5">
        {verifying && <Spinner />}
        {!verifying && isVerified && this.renderSuccessMessage()}
        {!verifying && !isVerified && this.renderErrorMessage()}
      </div>
    );
  }
}

EmailConfirmation.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EmailConfirmation;
