import { createLogic } from 'redux-logic';
import { replace } from 'react-router-redux';

import {
  STORES_ALL,
  STORES,
  STORE_DETAILS,
  EDIT_STORE,
  CREATE_STORE,
} from '../actions/types';

import { add as addNotification } from '../actions/notificationActions';

export const getAllStores = createLogic({
  type: STORES_ALL.FETCH,
  latest: true,

  processOptions: {
    successType: STORES_ALL.SUCCESS,
    failType: STORES_ALL.ERROR,
  },

  process({ httpClient, action }) {
    const { page, search } = action.payload;
    const params = search ? { page, searchPhrase: search } : { page };

    return httpClient.get('/api/v1/stores', { params })
      .then(res => res);
    }
});

export const getStores = createLogic({
  type: STORES.FETCH,
  latest: true,

  processOptions: {
    successType: STORES.SUCCESS,
    failType: STORES.ERROR,
  },

  process({ httpClient, action }) {
    const { page, search } = action.payload;
    const params = search ? { page, searchPhrase: search } : { page };

    return httpClient.get('/api/v1/me/stores', { params })
      .then(res => res);
    }
});

export const getStoreDetails = createLogic({
  type: STORE_DETAILS.START,
  latest: true,

  processOptions: {
    successType: STORE_DETAILS.SUCCESS,
    failType: STORE_DETAILS.ERROR,
  },

  process({ httpClient, action }) {
    return httpClient.get('/api/v1/stores/' + action.payload)
      .then(res => res.data);
    }
});

const createStoreErrorMessage = (error) => {
  const { data, status } = error;
  const extras = data && data.extras;
  const errorDetails = extras ? Object.keys(extras) : '';

  switch (status) {
    case 422:
      return `Invalid field(s): ${errorDetails}`;
    default:
      return 'Some error happend';
  }
};

export const createStore = createLogic({
  type: CREATE_STORE.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.post('/api/v1/stores', action.payload)
    .then(() => {
      dispatch(addNotification({
        type: 'success',
        text: 'Successfully created the store'
      }));
      dispatch(replace('/stores'));
    })
    .catch(err => {
      dispatch(addNotification({
        type: 'danger',
        text: createStoreErrorMessage(err.response)
      }));
    })
    .then(() => done());
}
});


export const editStore = createLogic({
  type: EDIT_STORE.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient.patch('/api/v1/stores/' + action.payload.id, action.payload.data)
    .then(() => {
      dispatch(addNotification({
        type: 'success',
        text: 'Successfully updated the store'
      }));
      dispatch(replace('/stores'));
    })
    .then(() => done());
    }
  });

export default {
  createStore,
  getAllStores,
  getStores,
  getStoreDetails,
  editStore,
};
