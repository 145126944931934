import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getStoreDetails } from '../../actions/storesActions';
import AccessControl from '../Utils/AccessControl';
import { roles } from '../const';
import { FormattedMessage } from 'react-intl';
import Spinner from '../Spinner';

import './StoreOverview.scss';

class StoreOverview extends React.Component {
  state = {
    name: '',
    id: '',
    transactionId: '',
    url: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    phone: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.storeDetails.isLoad &&
      !nextProps.storeDetails.isLoad &&
      !nextProps.storeDetails.isError &&
      !!nextProps.storeDetails.data) {
      const { data } = nextProps.storeDetails;

      this.setState({
        name: data.name || '-',
        id: `#${data.id.split('-')[0]}`,
        transactionId: data.transactionId || '-',
        url: data.url || '-',
        address1: data.address1 || '-',
        address2: data.address2 || '-',
        city: data.city || '-',
        state: data.state || '-',
        country: data.country || '-',
        phone: data.phone || '-',
      });
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getStoreDetails(id);
  }

  goToEdit = () => {
    const { id } = this.props.match.params;
    this.props.history.push('/stores/show/' + id);
  }

  render() {
    const {
      name,
      id,
      transactionId,
      url,
      address1,
      address2,
      city,
      state,
      country,
      phone,
    } = this.state;

    if(!this.props.storeDetails.data || this.props.storeDetails.isLoad){
      return <Spinner/>;
    }

    return (
      <AccessControl roles={[roles.organizer, roles.admin]}>
        <div className="container store-overview py-3 py-lg-5">
          <div className="row">
            <div className="col-12">
              <button type="button"
                className="btn float-right store-overview__btn"
                onClick={this.goToEdit}
              >
                <FormattedMessage id="store.btn.edit"/>
                <i className="icon-edit store-overview__btn-icon" />
              </button>
            </div>
          </div>
          <div className="store__top my-3 my-lg-5 px-3">
            <p className="store-overview__title"><FormattedMessage id="store.name"/></p>
            <p className="store-overview__subtitle">{name}</p>
          </div>
          <div className="row pt-lg-3">
            <div className="col-lg-4">
              <div className="store-overview__wrapper">
                <p className="store-overview__title"><FormattedMessage id="store.id"/></p>
                <p className="store-overview__info">{id}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="store-overview__wrapper">
                <p className="store-overview__title"><FormattedMessage id="store.transaction"/></p>
                <p className="store-overview__info">{transactionId}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="store-overview__wrapper">
                <p className="store-overview__title"><FormattedMessage id="store.url"/></p>
                <p className="store-overview__info">{url}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="store-overview__wrapper">
                <p className="store-overview__title"><FormattedMessage id="store.address"/></p>
                <p className="store-overview__info">{address1}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="store-overview__wrapper">
                <p className="store-overview__title"><FormattedMessage id="store.address2"/></p>
                <p className="store-overview__info">{address2}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="store-overview__wrapper">
                <p className="store-overview__title"><FormattedMessage id="store.city"/></p>
                <p className="store-overview__info">{city}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="store-overview__wrapper">
                <p className="store-overview__title"><FormattedMessage id="store.country"/></p>
                <p className="store-overview__info">{country}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="store-overview__wrapper">
                <p className="store-overview__title"><FormattedMessage id="store.state"/></p>
                <p className="store-overview__info">{state}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="store-overview__wrapper">
                <p className="store-overview__title"><FormattedMessage id="store.phone"/></p>
                <p className="store-overview__info">{phone}</p>
              </div>
            </div>
          </div>
        </div>
      </AccessControl>
    );
  }
}

StoreOverview.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  getStoreDetails: PropTypes.func,
  storeDetails: PropTypes.object,
};

export default connect(
  (state) => ({
    storeDetails: state.storeDetails,
  }),
  ({
    getStoreDetails,
  })
)(StoreOverview);
