import {TOURNAMENT_TEAMS_STANDINGS} from '../../actions/types';
import update from 'immutability-helper';

const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  error: null,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_TEAMS_STANDINGS.GET.START:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
        error: null,
        data: []
      };
    case TOURNAMENT_TEAMS_STANDINGS.GET.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action.payload,
        data: []
      };
    case TOURNAMENT_TEAMS_STANDINGS.GET.SUCCESS:
      return {
        isPending: false,
        isError: false,
        isSuccess: true,
        error: null,
        data: action.payload.teams.data
      };
    case TOURNAMENT_TEAMS_STANDINGS.UPDATE.SUCCESS: {
      const {teams: updatePlayers} = action.payload;

      return update(state, {
          data: {
            $apply: (data) => data.map(item => {

              const updatePlayer = updatePlayers[item.id];

              if (updatePlayer) {
                // API on update member request response different entry then
                // on get member request.
                //
                // To keep the same format od data in redux, don't merge this fields

                // eslint-disable-next-line no-unused-vars
                const {user, tournament, ...updatePlayerData} = updatePlayer;

                return {
                  ...item,
                  ...updatePlayerData
                };
              }

              return item;
            })
          }
      });
    }
    default:
      return state;
  }
};
