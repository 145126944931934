import React from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Biography from './Biography';
import Spinner from '../Spinner';

import {getBiography, updateBiography} from '../../actions/biographyActions';

class BiographyContainer extends React.Component {
  componentDidMount() {
    this.props.getBiography();
  }

  redirect = () => {
    this.props.history.push('/manage-account');
  }

  render() {
    const {biography} = this.props;
    if(!biography.data || biography.isLoad){
      return <Spinner/>;
    }

    return (
      <Biography
        data={biography.data}
        updateBiography={this.props.updateBiography}
        userId={this.props.user.id}
        redirect={this.redirect}
        field={'usersAnswers'}
      />
    );
  }
}

BiographyContainer.propTypes = {
  getBiography: PropTypes.func,
  updateBiography: PropTypes.func,
  biography: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(connect(
  (state) => ({
    biography: state.biography,
    user: state.user.data,
  }),
  ({
    getBiography,
    updateBiography
  })
)(BiographyContainer));