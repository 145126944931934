import {
  RESET_PASSWORD,
} from '../../actions/types';

const initialState = {
  isLoad: false,
  isError: false,
  error: null,
  success: false,
};

export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_PASSWORD.START:
      return {
        ...state,
        isLoad: true,
      };
    case RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        isLoad: false,
        success: true,
      };
    case RESET_PASSWORD.ERROR:
      return {
        ...state,
        isLoad: false,
        isError: true,
        error: action.payload,
      };
    case RESET_PASSWORD.RESET:
      return initialState;
    default: 
      return state;
  }
};