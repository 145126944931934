import { IMPORT_DECK } from '../actions/types';

const initialState = {
  data: null,
  isLoad: false,
  isError: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IMPORT_DECK.START:
      return {
        ...state,
        isLoad: true,
        isError: false,
        error: null,
      };
    case IMPORT_DECK.ERROR:
      return {
        ...state,
        isLoad: false,
        isError: true,
        error: action.payload
      };
    case IMPORT_DECK.SUCCESS:
      return {
        ...state,
        isLoad: false,
        isError: false,
        error: null,
        data: action.payload,
      };
    case IMPORT_DECK.CLEAR:
      return {
        ...state,
        isLoad: false,
        isError: false,
        error: null,
        data: null,
      };
    case IMPORT_DECK.REMOVE_CARD:
      return {
        ...state,
        isLoad:false,
        isError:false,
        error: null,
        data: {
          ...state.data,
          unrecognizedCards: state.data.unrecognizedCards.filter(
            item => item.suggestion !== action.card)
        }
      };
    default:
      return state;
  }
};