import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {replace} from 'react-router-redux';
import {connect} from 'react-redux';
import {Switch, Route, Redirect} from 'react-router-dom';

import TopBar from './TopBar';
import Sidebar from './Sidebar';

import Decks from '../pages/Decks';
import CreateDeckList from '../pages/CreateDeckListContainer';
import TournamentList from '../Tournament/TournamentList/TournamentList';
import TournamentOverview from '../Tournament/TournamentOverview';
import TournamentAdd from '../Tournament/TournamentAdd';
import MobileDetector from '../Utils/MobileDetector';
import NotifierMain from '../Notifier/NotifierMain';
import ManageAccountContainer from '../ManageAccount/ManageAccountContainer';
import BiographyContainer from '../ManageAccount/BiographyContainer';
import AccessControl from '../Utils/AccessControl';
import StoresList from '../Stores/StoresList';
import StoreOverview from '../Stores/StoreOverview';
import Store from '../Stores/Store';
import UsersList from '../Users/UsersList';
import User from '../Users/User';
import JoinTournament from '../pages/JoinTournament';
import MetricsContainer from '../pages/Metrics/MetricsContainer';
import DeckNames from '../DeckNames/DeckNames';
import StreamersContainer from '../Streamers/StreamersContainer';
import StreamedDecks from '../pages/StreamedDecks/StreamedDecks';

import EditTeam from '../Tournament/EditTeam';
import EditTeamDeck from '../Tournament/EditTeamDeck';

import {setUserRole} from '../../actions/userActions';
import {logout} from '../../actions/authActions';

import {roles} from '../const';

import './LoggedLayout.scss';

class LoggedLayout extends React.PureComponent {
  setUserRole = (role) => {
    const {setUserRole, replace} = this.props;

    setUserRole(role);
    replace('/');
  };

  render() {
    const {user, userRole, logout} = this.props;

    return (
      <MobileDetector render={(isMobile) => (
        <Fragment>
          <TopBar userData={user}
                  userRole={userRole}
                  isMobile={isMobile}
                  setUserRole={this.setUserRole}
                  logout={logout}/>
          <NotifierMain className="fix-topbar-padding"/>
          <div className="logged-layout">
            <div className="full-height d-flex">
              <div className="sidebar-container d-none d-lg-block d-xl-block">
                <Sidebar userData={user}/>
              </div>
              <div className="main-container">
                <Switch>
                  <Route exact path="/users/show/:userId" component={User}/>
                  <Route exact path="/users/:page" component={UsersList}/>
                  <Route exact path="/users" component={()=>(
                    <Redirect to="/users/1"/>
                  )}/>

                  <Route exact path="/decks" component={Decks}/>
                  <Route exact path="/decks/:id" component={(props) => {
                    return <CreateDeckList id={props.match.params.id} history={props.history} userDeck={true} />;
                  }}/>
                  <Route exact path="/deck-names/add" component={() => <DeckNames create />} />
                  <Route exact path="/deck-names/:page" component={DeckNames} />
                  <Route exact path="/deck-names/:page/edit/:id" component={DeckNames} />
                  <Route path="/deck-names" component={()=>(
                    <Redirect to="/deck-names/1"/>
                  )}/>

                  <Route exact path="/streamers" component={StreamersContainer}/>

                  <Route exact path="/streamed-decks" component={StreamedDecks}/>

                  <Route path="/tournaments/create" component={TournamentAdd} />
                  <Route path="/tournaments/show/:tournamentId" component={TournamentOverview} />
                  <Route exact path="/tournaments/:page" component={TournamentList}/>

                  <Route path="/team/edit/:teamId/decks/:id" component={EditTeamDeck}/>
                  <Route path="/team/edit/:teamId" component={EditTeam} />

                  <Route path="/tournaments" component={()=>(
                    <Redirect to="/tournaments/1"/>
                  )}/>

                  <Route exact path="/stores/create" component={Store}/>
                  <Route exact path="/stores/overview/:id" component={StoreOverview}/>
                  <Route exact path="/stores/show/:id" component={Store} />
                  <Route exact path="/stores/:page" component={StoresList}/>
                  <Route exact path="/stores" component={()=>(
                    <Redirect to="/stores/1"/>
                  )}/>

                  <Route exact path="/metrics" component={MetricsContainer}/>
                  <Route exact path="/manage-account/biography" component={BiographyContainer}/>
                  <Route path="/manage-account" component={ManageAccountContainer}/>
                  <Route exact path="/t/:passcode" component={JoinTournament}/>

                  <Route path="/" component={() => (
                    <React.Fragment>
                      <AccessControl roles={[roles.player]}>
                        <Redirect to="/decks"/>
                      </AccessControl>
                      <AccessControl roles={[roles.organizer, roles.judge, roles.admin]}>
                        <Redirect to="/tournaments/1"/>
                      </AccessControl>
                      <AccessControl roles={[roles.admin]}>
                        <Redirect to="/users"/>
                      </AccessControl>
                    </React.Fragment>
                  )}/>
                </Switch>
              </div>
            </div>
          </div>
      </Fragment>
      )}/>
    );
  }
}

LoggedLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  user: PropTypes.object,
  userRole: PropTypes.string,
  setUserRole: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    user: state.user.data,
    userRole: state.user.userRole
  }),
  ({
    setUserRole,
    logout,
    replace
  })
)(LoggedLayout);
