import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import { cardSearch, cardImageShow } from '../../../actions/cardSearchAction';
import SelectValue from './SelectValue';
import { FormattedMessage } from 'react-intl';
import { renderManaCost } from './ManaCost';
import MobileDetector from '../../Utils/MobileDetector';

import './CardSearch.scss';

class CardSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectValue: '',
      inputValue: '',
      isDisabled: true,
      isModalOpen: false,
      qty: 1,
      modalQty: 1
    };
  }

  handleChange = (selectValue) => {
    this.setState({
      selectValue,
    }, () => {
      const isUnlimited = selectValue && selectValue.cardGroup && selectValue.cardGroup.unlimitedAmount;
      if(isUnlimited) {
        this.toggleModal(true);
      }

    });
  }

  handleQtyChange = (e, prop) => {
    this.setState({
      [prop]: e.target.value
    });
  }

  handleInputChange = (newValue) => {
    const {activeIndex, format, colors} = this.props;
    const searchAlias = format.details[activeIndex].alias;

    this.setState({ inputValue: newValue });

    const data = {
      name: newValue,
      format: searchAlias,
      colors,
    };

    this.props.cardSearch(data);
  }

  handleAddClick = (e, qty=this.state.qty) => {
    e && e.preventDefault();
    const { selectValue } = this.state;
    this.props.onAddClick(selectValue, parseInt(qty));
    this.setState({
      qty: 1,
      selectValue: '',
    });
    if(this.state.isModalOpen) {
      this.toggleModal(false);
    }
  }

  toggleModal = (value) => {
    this.setState({
      isModalOpen: value,
      modalQty: 1
    });
  }

  addModalCart = () => {
    this.handleAddClick(null, this.state.modalQty);
    this.toggleModal(false);
  }

  render() {
    const { selectValue, isDisabled, qty, modalQty } = this.state;
    const loadOptions = this.props.searchCards.data.map(elem=>{
      return {
        ...elem,
        label: elem.name,
        value: elem.cardId,
      };
    });

    return(
      <div className="card-search">
        <form autoComplete="off">
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-7">
              <p className="create-decklist__form-label"><FormattedMessage id="card.title"/></p>
              <div className="pb-3">
                <Select
                    options={loadOptions}
                    onInputChange={this.handleInputChange}
                    value={selectValue}
                    onChange={this.handleChange}
                    optionComponent={SelectValue}
                    onFocus={this.handleFocus}
                    inputProps={{ autoComplete: 'off', autofill: 'off', spellCheck: 'false', autoCorrect: 'off', autoCapitalize: 'off' }}
                    onSelectResetsInput={true}
                    onBlurResetsInput={true}
                  />
              </div>

              <div className="card-search__add-cards pb-3">
                <button
                  className="btn btn-form btn-primary add-cards__btn"
                  type="button" onClick={e => this.handleAddClick(e, 1)}
                  disabled={selectValue ? !isDisabled : isDisabled}
                >+1</button>
                <button
                  className="btn btn-form btn-primary add-cards__btn"
                  type="button" onClick={e => this.handleAddClick(e, 2)}
                  disabled={selectValue ? !isDisabled : isDisabled}
                >+2</button>
                <button
                  className="btn btn-form btn-primary add-cards__btn"
                  type="button"
                  onClick={e => this.handleAddClick(e, 3)}
                  disabled={selectValue ? !isDisabled : isDisabled}
                >+3</button>
                <button
                  className="btn btn-form btn-primary add-cards__btn"
                  type="button"
                  onClick={e => this.handleAddClick(e, 4)}
                  disabled={selectValue ? !isDisabled : isDisabled}
                >+4</button>
                <button
                  className="btn btn-form btn-primary add-cards__btn add-cards__btn--modal d-lg-none d-xl-none"
                  type="button" disabled={selectValue ? !isDisabled : isDisabled}
                  onClick={() => this.toggleModal(true)}>
                  <i className="icon-options"/>
                </button>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-lg-5">
              <div className="row">
                <div className="col-12">
                  <label className="create-decklist__form-label d-none d-lg-block">Qty</label>
                </div>
              </div>
              <div className="row">
                  <div className="col-4">
                    <div className="form-group d-none d-lg-block">
                      <input
                        onChange={e => this.handleQtyChange(e, 'qty')}
                        value={qty}
                        min={1}
                        className="form-control col-lg-12 add-deck__form-input text-center text-lg-left" type="number"
                        />
                    </div>
                  </div>
                  <div className="col-8">
                    <button
                      onClick={this.handleAddClick}
                      className="col-lg-12 btn btn-lg card-search__btn d-none d-lg-block"
                      disabled={selectValue && qty ? !isDisabled : isDisabled}
                      >
                      <FormattedMessage id="card.add"/>
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </form>
        <div className="d-lg-none d-xl-none">
          <MobileDetector render={(isMobile) => (
            <Modal isOpen={this.state.isModalOpen && isMobile} toggle={() => this.toggleModal(false)}>
              <ModalHeader toggle={() => this.toggleModal(false)}>
              </ModalHeader>
              <ModalBody>
                <p className="add-cart-text"><b>{selectValue && selectValue.name}</b> <span className="add-cart-mana">
                  {selectValue && selectValue.manaCost && renderManaCost(selectValue)}</span>
                </p>
                <div className="add-cart-modal pb-4">
                  <input
                    type="number"
                    className="form-control add-cart-input"
                    value={this.state.modalQty}
                    onChange={e => this.handleQtyChange(e, 'modalQty')}
                  />
                  <button
                    disabled={selectValue && (modalQty>0) ? !isDisabled : isDisabled}
                    className="btn btn-primary add-cart-btn ml-3"
                    onClick={this.addModalCart}
                    type="button"
                  >ADD</button>
                </div>
              </ModalBody>
            </Modal>
          )}/>
        </div>
      </div>
    );
  }
}

CardSearch.propTypes = {
  cardSearch: PropTypes.func.isRequired,
  searchCards: PropTypes.object,
  cardImageShow: PropTypes.func,
  onAddClick: PropTypes.func,
  format: PropTypes.object.isRequired,
  activeIndex: PropTypes.number.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  deckId: PropTypes.string,
  data: PropTypes.array,
  inModal: PropTypes.bool,
};

export default connect(
  (state) => ({
    searchCards: state.search,
  }),
  ({
    cardSearch,
    cardImageShow
  })
)(CardSearch);
