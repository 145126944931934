import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

import TabsWrapperContext from './TabsWrapperContext';

import './Tabs.scss';

class Tabs extends React.Component {
  filterData(data) {
    return data.map(item => {
      /* eslint-disable no-unused-vars */
      const {component, ...rest} = item;
      /* eslint-enable no-unused-vars */

      const keyAmount = Object.keys(rest).length;

      if(keyAmount === 1 && 'name' in rest){
        return rest.name;
      }
      return {...rest};
    });
  }

  getPropsBasedOnContext(contextData) {
    const {index, data, onChangeIndex} = contextData;
    const {...props} = this.props;

    const filteredData = this.filterData(data);

    return {
      ...props,
      index,
      data: filteredData,
      onChangeIndex
    };
  }

  onChangeIndex = (fn, index) => {
    if(!fn) {
      return;
    }

    fn(index);
  };

  renderNavItemBasic(item) {
    return <span>{item}</span>;
  }

  renderTabsContext() {
    return (
      <TabsWrapperContext.Consumer>
        {contextData => this.renderTabs(this.getPropsBasedOnContext(contextData))}
      </TabsWrapperContext.Consumer>
    );
  }

  renderTabs(props) {
    const {index, data, renderTabItem, className, onChangeIndex} = props;

    return (
      <div className={classnames('tabs nav nav-justified', className)}>
        {data.map((item, i) => {
          const isActive = i === index;
          const isRender = typeof item !== 'string';
          const renderResult = isRender ? renderTabItem(item, i) : null;

          if(renderResult === false){
            return null;
          }

          return (
            <div className={classnames('nav-link', {active: isActive})}
                 key={i}
                 onClick={() => this.onChangeIndex(onChangeIndex, i)}>
              {
                isRender
                  ? renderResult
                  : this.renderNavItemBasic(item, i)
              }
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const {index} = this.props;

    if(index === undefined){
      return this.renderTabsContext();
    }

    return this.renderTabs(this.props);
  }
}

Tabs.propTypes = {
  data: propTypes.arrayOf(
    propTypes.oneOfType([
      propTypes.string,
      propTypes.object,
    ])
  ),
  index: propTypes.number,
  onChangeIndex: propTypes.func,
  renderTabItem: propTypes.func,
  className: propTypes.string,
};

export default Tabs;
