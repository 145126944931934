import {SUBMIT_DECK_FOR_TOURNAMENT} from '../../actions/types';

export const joinTournament = (passcode) => ({
  type: SUBMIT_DECK_FOR_TOURNAMENT.JOIN_TOURNAMENT.START,
  payload: passcode,
});
export const joinTournamentSuccess = (data) => ({
  type: SUBMIT_DECK_FOR_TOURNAMENT.JOIN_TOURNAMENT.SUCCESS,
  payload: data,
});
export const joinTournamentError = (data) => ({
  type: SUBMIT_DECK_FOR_TOURNAMENT.JOIN_TOURNAMENT.ERROR,
  payload: data,
});
export const joinTournamentClear = () => ({
  type: SUBMIT_DECK_FOR_TOURNAMENT.JOIN_TOURNAMENT.CLEAR
});

export default {
  joinTournament,
  joinTournamentSuccess,
  joinTournamentError,
  joinTournamentClear,
};
