import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from 'react-intl';

import ModalConnector from '../Generic/Modals/ModalConnector';
import Prompt from '../Generic/Modals/Prompt';

import names from './names';

class DeleteConfirmationModal extends React.Component {
  onSubmit = (data) => {
    data.onSubmit(data.deleteId);
  };

  render() {
    const {intl} = this.props;

    return (
      <ModalConnector name={names.DELETE_CONFIRMATION}>
        {
          ({isOpen, data, onClose}) => {
            return (
              <Prompt
                isOpen={isOpen}
                title={intl.formatMessage({id: 'modal.delete.title'})}
                submitClassName="btn-danger"
                closeOnSubmit={true}
                onClose={onClose}
                onSubmit={() => this.onSubmit(data, onClose)}
              >
                <FormattedMessage id="modal.delete.confirmation"/>
              </Prompt>
            );
          }
        }
      </ModalConnector>
    );
  }
}

DeleteConfirmationModal.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(DeleteConfirmationModal);
