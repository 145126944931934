import React from 'react';
import ModalConnector from '../Generic/Modals/ModalConnector';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import names from './names';

import './Howto.scss';

import exampleCSV from '../../assets/sampleFiles/players.csv';

class HowToPlayers extends React.Component {
  render() {
    return (
      <ModalConnector name={names.HOW_TO_IMPORT_PLAYERS}>
        {
          ({isOpen, onClose}) => {
            return (
              <Modal isOpen={isOpen} toggle={onClose} size="lg" centered>
                <ModalHeader toggle={onClose}>
                  <FormattedMessage id="modal.how.to.players.header" />
                </ModalHeader>
                <ModalBody>
                  <ul className="howto-list">
                    <li>
                      <FormattedMessage id="modal.how.to.players.p1" />
                      <ul className="howto-nested-list">
                        <li>
                          <span>
                            <span className="howto-nested-list-order">a.</span>
                            <a href={exampleCSV} download="players_sample_as_csv.csv">
                              <FormattedHTMLMessage id="modal.how.to.players.p2" />
                            </a>
                          </span>
                          <p>
                            <FormattedMessage id="modal.how.to.players.p3" />
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </ModalBody>
                <ModalFooter>
                  <button className="btn btn-success" onClick={onClose}>
                    <FormattedMessage id="modal.how.to.players.btn" />
                  </button>
                </ModalFooter>
              </Modal>
            );
          }
        }
      </ModalConnector>
    );
  }
}

export default HowToPlayers;
