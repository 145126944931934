import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {injectIntl} from 'react-intl';
import { push } from 'react-router-redux';
import {withRouter} from 'react-router-dom';

import ModifyTeam from '../../../ModifyTeam/ModifyTeam';

import {getTournamentTeamDeckFormatsNoRegister} from '../../../../actions/tournamentTeamDeckFormats';
import { addUnregisterTournamentTeam } from '../../../../actions/tournamentTeams';

import {getTournamentTeamDeckFormatsSelector} from '../../../../selectors/tournamentTeamDeckFormats';

import './CreateTeamContainer.scss';

class CreateTeamContainer extends React.Component {
  componentDidMount() {
    const { tournament, getTournamentTeamDeckFormatsNoRegister } = this.props;

    getTournamentTeamDeckFormatsNoRegister(tournament.format);
  }

  onProceed = (team) => {
    const { addUnregisterTournamentTeam, tournament } = this.props;

    const nextActions = [push('../create/team-details')];

    addUnregisterTournamentTeam(tournament.passCode, team, nextActions);
  };

  onCancel = () => {
    this.props.history.push('/login');
  };

  getPlayers() {
    const { tournamentTeamDeckFormats, tournament } = this.props;
    const deckFormats = tournamentTeamDeckFormats.byTournamentFormat;

    if (!deckFormats[tournament.format]) {
      return [];
    }

    return deckFormats[tournament.format].map( elem => {
      return {
        firstName: '',
        lastName: '',
        dciNumber: '',
        deckFormat: elem,
      };
    });
  }

  render() {
    const { intl ,error} = this.props;
    const errors = error ? error.extras : null;

    return (
      <div className="create-team-container">
        <div className="create-team-form">
          <ModifyTeam
            players={this.getPlayers()}
            title={intl.formatMessage({id: 'tournament.teams.add'})}
            submitText={intl.formatMessage({id: 'tournament.teams.btn.proceed'})}
            onSubmit={this.onProceed}
            onCancel={this.onCancel}
            errors={errors}
          />
        </div>
      </div>
    );
  }
}

CreateTeamContainer.propTypes = {
  tournament: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  error: PropTypes.object,
  history: PropTypes.object,

  tournamentTeamDeckFormats: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  addUnregisterTournamentTeam: PropTypes.func.isRequired,
  getTournamentTeamDeckFormatsNoRegister: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    data: state.tournamentTeams.add,
    tournamentTeamDeckFormats: getTournamentTeamDeckFormatsSelector(state.tournamentTeamDeckFormats),
    error: state.tournamentTeams.unregisterTeam.error,
  }),
  {
    addUnregisterTournamentTeam,
    getTournamentTeamDeckFormatsNoRegister,
  }
)(injectIntl(withRouter(CreateTeamContainer)));
