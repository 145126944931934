import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

import ChangeableDropdownColor from '../Generic/ChangeableDropdown/ChangeableDropdownColor';

import './TournamentStatusDropdown.scss';

class TournamentStatusDropdown extends React.Component {
  render() {
    const {className, disabled, ...props} = this.props;

    const options = [
      {value: 'not_started', name: 'Not started', color: 'danger'},
      {value: 'in_progress', name: 'In progress', color: 'warning'},
      {value: 'completed', name: 'Completed', color: 'success'},
    ];

    return (
      <ChangeableDropdownColor {...props}
                               className={classname(className, 'tournament-status-dropdown')}
                               options={options}
                               disabled={disabled}
                               renderCurrentValue/>
    );
  }
}

TournamentStatusDropdown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOf([
    'not_started',
    'in_progress',
    'completed']
  ).isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default TournamentStatusDropdown;
