import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import ModalConnector from '../Generic/Modals/ModalConnector';
import ModalAddTeam from '../ModifyTeam/ModalAddTeam';

import names from './names';

import {joinTeamTournament, clearJoinTeamTournament} from '../../actions/joinTeamTournament';

class JoinTeamTournamentModal extends React.Component {
  onSubmit = (passCode, team) => {
    this.props.joinTeamTournament({
      passCode,
      team,
    });
  };

  onClose = (onClose) => {
    this.props.clearJoinTeamTournament();

    onClose();
  };

  render() {
    return (
      <ModalConnector name={names.JOIN_TEAM_TOURNAMENT}>
        {
          ({isOpen, data, onClose}) => {
            const {error} = this.props;

            const players = data.deckFormats.map(deckFormat => ({
              firstName: '',
              lastName: '',
              dciNumber: '',
              deckId: null,
              deckFormat,
            }));

            return (
              <ModalAddTeam
                isOpen={isOpen}
                players={players}
                decks={data.decks}
                onCancel={() => this.onClose(onClose)}
                onSubmit={(submitData) => this.onSubmit(data.passCode, submitData)}
                errors={error}
              />
            );
          }
        }
      </ModalConnector>
    );
  }
}

JoinTeamTournamentModal.propTypes = {
  error: PropTypes.object,
  joinTeamTournament: PropTypes.func.isRequired,
  clearJoinTeamTournament: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    error: state.joinTeamTournament.error,
  }),
  ({
    joinTeamTournament,
    clearJoinTeamTournament,
  })
)(JoinTeamTournamentModal);
