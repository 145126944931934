import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {omit} from 'lodash';

import ModifyTeam, {propTypes, defaultProps} from './ModifyTeam';

import './ModalModifyTeam.scss';

class ModalModifyTeam extends React.Component {
  static defaultProps = {
    isOpen: true,
    title: defaultProps.title,
  };

  render() {
    const {isOpen, ...props} = this.props;

    return (
      <Modal isOpen={isOpen} toggle={props.onCancel} size="lg" className="modal-modify-team">
        <ModalHeader toggle={props.onCancel}>{props.title}</ModalHeader>
        <ModifyTeam
          {...props}
          title={false}
          render={(title, content, buttons) => {
            return (
              <React.Fragment>
                <ModalBody>
                  {content}
                </ModalBody>
                <ModalFooter>
                  {buttons}
                </ModalFooter>
              </React.Fragment>
            );
          }}
        />
      </Modal>
    );
  }
}

ModalModifyTeam.propTypes = {
  ...omit(propTypes, ['intl']),
  isOpen: PropTypes.bool,
};

export default ModalModifyTeam;
