import * as React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {connect} from 'react-redux';
import {getTournaments, deleteTournament} from '../../../actions/tournamentActions';
import classnames from 'classnames';
import Pagination from '../../Pagination/Pagination';
import Spinner from '../../Spinner';
import Search from '../../Search';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import DataTableWithMobileDropdown from '../../DataTable/DataTableWithMobileDropdown';
import { filterOptions } from '../../const';
import DateFormatted from '../../DateFormatted/DateFormatted';
import {getSortingState} from '../../../services/Sorting';

import './TournamentList.scss';
import StatusDot from '../StatusDot';

class TournamentListOrganizer extends React.Component {
  state = {
    isOpen: false,
    deleteId: null,
    selectedFilter: null,
    searchQuery: null,
    sorting: null
  };

  componentDidMount() {
    this.getMyTournaments(this.props.currentPage);
  }

  componentDidUpdate(prevProps, prevState) {
    const {sorting} = this.state;

    const sortColumn = sorting && sorting.actualSortedKey;
    const sortType = sorting && sorting.type;

    if (prevProps.currentPage !== this.props.currentPage && !prevState.selectedFilter) {
      this.getMyTournaments(this.props.currentPage);
    }

    if (prevProps.currentPage !== this.props.currentPage && prevState.selectedFilter) {
      const typeArray = this.getFiltersByGroup('type', prevState.selectedFilter);
      const statusArray = this.getFiltersByGroup('status', prevState.selectedFilter);

      this.props.getTournaments(this.props.currentPage, 4, null, this.state.searchQuery, typeArray, statusArray, sortColumn, sortType);
    }
  }

  getMyTournaments(page, searchQuery=this.state.searchQuery, sorting=this.state.sorting) {
    const sortColumn = sorting && sorting.actualSortedKey;
    const sortType = sorting && sorting.type;

    const typeArray = this.getFiltersByGroup('type', this.state.selectedFilter);
    const statusArray = this.getFiltersByGroup('status', this.state.selectedFilter);

    this.props.getTournaments(page, 4, null, searchQuery, typeArray, statusArray, sortColumn, sortType);
  }

  addTournament = () => {
    this.props.history.push('/tournaments/create');
  }

  submit = () => {
    this.props.deleteTournament(this.state.deleteId, 'ORGANIZER');
    this.toggleModal(null, null);
  }

  toggleModal = (e, id) => {
    e && e.stopPropagation();

    this.setState({
      isOpen: !this.state.isOpen,
      deleteId: id
    });
  }

  goToEdit(e, id) {
    e && e.stopPropagation();
    this.props.history.push(`/tournaments/show/${id}/info/edit`);
  }

  goToOverview(id) {
    this.props.history.push(`/tournaments/show/${id}/info`);
  }

  onSort = (sortedKey) => {
    const {sorting, searchQuery} = this.state;
    const sort = getSortingState(sorting, sortedKey);

    this.setState({
      sorting: sort
    });

    this.getMyTournaments(this.props.currentPage, searchQuery, sort);
  };

  renderTournamentsList = () => {
    const {sorting} = this.state;
    const tournaments = this.props.tournaments.data;

    if(!tournaments || this.props.tournaments.isLoad){
      return <Spinner/>;
    }

    if (!tournaments || tournaments.data.length < 1) {
      return <div className="text-center"><FormattedMessage id="tournaments.no_tournaments"/></div>;
    }

    const columns = [
      {name: 'ID', flex: 1, key: 1},
      {name: 'NAME', sortedKey: 'name', flex: 2, key: 2},
      {name: 'TYPE', flex: 1, key: 3},
      {name: 'DATE', sortedKey: 'startDate', flex: 1, key: 4},
      {name: 'CREATED AT', sortedKey: 'createdAt', flex: 2, key: 5},
      {name: 'STATUS', flex: 1, key: 6},
      {name: 'EDIT', flex: 2, key: 7, isVisibleOnMobile: false},
      {name: 'DELETE', flex: 2, key: 8, isVisibleOnMobile: false}
    ];

    const data = tournaments.data.map((item, index) => {
      return [
        `#${item.id.split('-')[0]}`,
        item.name,
        item.type ? <FormattedMessage id={`tournament_type.${item.type}`}/> : '-',
        <DateFormatted key={index + '-date'} value={item.startDate}/>,
        <DateFormatted key={index + '-created'} value={item.createdAt}/>,
        <div key={index + '-status'} className="text-center"><StatusDot status={item.status}/></div>,
        <div key={index + '-edit'} className="tournament-link text-center" onClick={(e) => this.goToEdit(e, item.id)}><i
          className="icon-edit"/></div>,
        <div key={index + '-delete'} className="tournament-link text-center"
             onClick={(e) => this.toggleModal(e, item.id)}><i className="icon-delete"/></div>
      ];
    });

    const dropdownData = (dataRow, rowIndex) => [
      <span key={rowIndex + '-remove'} className="tournament-link tournament-link-mobile" onClick={(e) => this.goToEdit(e, tournaments.data[rowIndex].id)}>Edit</span>,
      <span key={rowIndex + '-remove'} className="tournament-link tournament-link-mobile" onClick={(e) => this.toggleModal(e, tournaments.data[rowIndex].id)}>Delete</span>,
    ];

    return (
      <DataTableWithMobileDropdown
        columns={columns}
        data={data}
        onRowClick={(nr) => this.goToOverview(tournaments.data[nr].id)}
        dropdownData={dropdownData}
        onSort={this.onSort}
        sortedOptions={sorting}
      />
    );
  }

  renderFilterOption(option) {
    return (
      <div className={classnames('react-select-group__item',
        { 'react-select__group__item--is-disabled': option.disabled }
      )}>
        {option.group === 'status' &&
          <span className={`tournament-status-dot mr-2 tournament-status-dot--${option.status}`}></span>}
        {option.label}
      </div>
    );
  }

  renderFilterValue(option) {
    return (
      <div>
        {option.group === 'status' &&
          <span className={`tournament-status-dot mr-2 tournament-status-dot--${option.status}`}></span>}
        {option.label}
      </div>
    );
  }

  getFiltersByGroup(group, values) {
    if (!values || values.length === 0) {
      return null;
    }

    return values
      .filter(item => item.group === group)
      .map(item => item.value);
  }

  selectFilter = (selected) => {
    this.setState({
      selectedFilter: selected
    });

    const typeArray = this.getFiltersByGroup('type', selected);
    const statusArray = this.getFiltersByGroup('status', selected);

    this.props.history.push('/tournaments/1');
    this.props.getTournaments(1, 4, null, this.state.searchQuery, typeArray, statusArray);
  }

  searchUser = (searchQuery) => {
    const page = searchQuery && searchQuery.length >= 3 ? 1 : this.props.currentPage;
    if(searchQuery !== this.state.searchQuery){
      this.getMyTournaments(page, searchQuery);
    }
    this.setState({
      searchQuery
    });
  }

  renderPagination() {
    const {tournaments} = this.props;
    const {searchQuery} = this.state;

    if(!tournaments.data || searchQuery){
      return null;
    }
    return <Pagination totalPages={this.props.tournaments.data.headers['x-last-page']} currentPage={this.props.currentPage} url={'/tournaments/:page'}/>;
  }

  render() {
    const {tournaments} = this.props;
    const { selectedFilter } = this.state;

    if(!tournaments){
      return null;
    }

    return (
      <div>
        <h4 className="section-header mb-5">
          <span><FormattedMessage id="tournaments.header.your_tournaments"/></span>
          <button type="button" className="btn btn-primary float-right" onClick={this.addTournament}>
            <span className="d-none d-md-block"><FormattedMessage id="tournaments.btn.add_tournament"/></span>
            <i className="icon-plus d-md-none"/>
          </button>
        </h4>
        <div className="row">
          <div className="mb-3 col-md-6">
            <Search onUpdate={this.searchUser} />
          </div>
          <div className="col-md-3 offset-md-3">
            <Select
              className="react-select-group"
              value={selectedFilter}
              options={filterOptions}
              onChange={this.selectFilter}
              optionRenderer={this.renderFilterOption}
              valueRenderer={this.renderFilterValue}
              multi
              placeholder="Default"
            />
          </div>
        </div>
        <div className="tournament-list mt-3">
          <div className="tournament-list-table-wrapper">
            {this.renderTournamentsList()}
          </div>
          {this.renderPagination()}

        </div>
        <Modal isOpen={this.state.isOpen} toggle={(e) => this.toggleModal(e, null)}>
          <ModalHeader toggle={(e) => this.toggleModal(e, null)}><FormattedMessage id="tournament.delete.modal.header"/></ModalHeader>
          <ModalBody>
            <FormattedMessage id="tournament.delete.modal.body"/>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-success" onClick={this.submit}><FormattedMessage id="tournament.delete.modal.submit"/></button>
            <button className="btn btn-outline-secondary" onClick={(e) => this.toggleModal(e, null)}><FormattedMessage id="tournament.delete.modal.cancel"/></button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

TournamentListOrganizer.propTypes = {
  getTournaments: PropTypes.func.isRequired,
  tournaments: PropTypes.object,
  history: PropTypes.object,
  currentPage: PropTypes.string,
  deleteTournament : PropTypes.func,
};

export default connect(
  (state) => ({
    tournaments: state.tournaments,
  }),
  ({
    getTournaments,
    deleteTournament,
  })
)(TournamentListOrganizer);
