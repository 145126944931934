import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

import { roles } from '../../../const';
import {getCountries, getStates} from '../../../../actions/politicalActions';

import TournamentShow from '../../TournamentShow';
import Spinner from '../../../Spinner';
import AccessControl from '../../../Utils/AccessControl';

class TournamentLocationShow extends React.Component {
  componentDidMount(){
    const {states, countries, getStates, getCountries} = this.props;

    if(!countries.data){
      getCountries();
    }
    if(!states.data){
      getStates();
    }
  }

  renderButton = () => {
    const {match} = this.props;

    return (
      <AccessControl roles={[roles.organizer, roles.admin]}>
        <Link to={`${match.url}/edit`} className="float-right">
          <button type="button" className="btn btn-warning float-right">
            <span>
              <span className="d-none d-md-inline-block mr-2">
                <FormattedMessage  id="tournament.edit.btn"/>
              </span>
              <i className="icon-edit"/>
            </span>
          </button>
        </Link>
      </AccessControl>
    );
  }

  render() {
    const {tournament, states, countries} = this.props;

    if(!states.data || !countries.data) {
      return <Spinner/>;
    }

    return (
      <TournamentShow tournament={tournament}
                      states={states.data}
                      countries={countries.data}
                      renderButton={this.renderButton}/>
    );
  }
}

TournamentLocationShow.propTypes = {
  tournament: PropTypes.object.isRequired,
  getCountries: PropTypes.func.isRequired,
  countries: PropTypes.object.isRequired,
  getStates: PropTypes.func.isRequired,
  states: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(connect(
  (state) => ({
    countries: state.countries,
    states: state.states,
  }),
  ({
    getCountries,
    getStates,
  })
)(TournamentLocationShow));
