import {createLogic} from 'redux-logic';

import AuthService from '../../services/AuthService';

import {UNREGISTERED_PLAYER} from '../../actions/types';
import actions from '../../actions/unregisteredPlayer';
import {add as addNotification} from '../../actions/notificationActions';

export const create = createLogic({
  type: UNREGISTERED_PLAYER.CREATE.START,

  process({clientContextHttpClient, action}, dispatch, done) {
    const {passcode, userData, nextActions} = action.payload;

    return clientContextHttpClient.put(`/api/v1/public_register/${passcode}`, userData)
      .then(res => res.data)
      .then(data => {
        dispatch(actions.createSuccess(data));

        nextActions.forEach((action) => dispatch(action));
      })
      .catch(error => {
        dispatch(actions.createError(error));
        dispatch(addNotification({
          type: 'danger',
          text: error.response.data.error_description,
        }));
      })
      .then(done);
  }
});

export const createSuccess = createLogic({
  type: UNREGISTERED_PLAYER.CREATE.SUCCESS,

  process({action}) {
    const data = action.payload;

    AuthService.saveAuth(data.token);
  }
});

export const update = createLogic({
  type: UNREGISTERED_PLAYER.UPDATE.START,

  process({httpClient, action}, dispatch, done) {
    const {userData, userId} = action.payload;

    return httpClient.patch(`/api/v1/users/${userId}`, userData)
      .then(res => res.data)
      .then(data => {
        dispatch(actions.setPlayer(data));
        dispatch(actions.updateSuccess(data));

        dispatch(addNotification({
          type: 'success',
          text: 'User was updated',
        }));
      })
      .catch(err => {
        dispatch(actions.updateError(err));

        dispatch(addNotification({
          type: 'danger',
          text: 'Some error',
        }));
      })
      .then(done);
  }
});

export default [
  create,
  createSuccess,
  update,
];
