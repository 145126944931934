import React from 'react';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';
import PropTypes from 'prop-types';

class Biography extends React.Component {
  state = {
    questions: []
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      questions: nextProps.data
    };
  }

  save = () => {
    const {userId, updateBiography } = this.props;
    updateBiography(userId, this.state.questions, this.props.redirect);
  }

  onQuestionChange = (e, item) => {
    const { field } = this.props;
    const { questions } = this.state;
    const index = questions.indexOf(item);

    if(!this.state.questions[index][field][0]) {
      return this.setState({
        questions: update(this.state.questions, {[index]: {[field]: {$push: [{answer: e.target.value}]}}})
      });
    }

    this.setState({
      questions: update(this.state.questions, {[index]: {[field]: {0: {answer: {$set: e.target.value}}}}})
    });
  }

  renderBio(){
    const { field } = this.props;
    return this.state.questions.map((item, id) => {
      return(
        <div key={id} className="mt-4">
          <label>{`${id+1}. ${item.question}`}</label>
          <textarea className="form-control" onChange={(e) => this.onQuestionChange(e, item)} value={item[field][0] ? item[field][0].answer : ''}/>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="container mt-4">
        <span onClick={this.props.redirect} className="link back-link">{this.props.backText ? this.props.backText : '< Manage account'}</span>
        <h2 className="text-info section-header mt-3 mb-4"><FormattedMessage id="biography.title"/></h2>
        <div>
          {this.renderBio()}
        </div>
        <div className="text-center mt-4">
            <span onClick={this.props.redirect} className="btn btn-lg btn-secondary mr-3">Cancel</span>
            <button className="btn btn-lg btn-success" onClick={this.save}>Proceed</button>
        </div>
      </div>
    );
  }
}

Biography.propTypes = {
  data: PropTypes.array,
  updateBiography: PropTypes.func,
  userId: PropTypes.string,
  redirect: PropTypes.func,
  backText: PropTypes.string,
  field: PropTypes.string
};

export default Biography;