import React from 'react';

import image from './test.jpg';
import image2 from './test2.jpg';

import ImageCropper from '../../ImageCropper/ImageCropper';

class ImageCropperUI extends React.Component {
  state = {
    uploadedFileUrl: null,
    croppedFileUrl: null,
  };

  onSubmit = (image) => {
    // eslint-disable-next-line no-console
    console.log('submit', image);

    this.setState({
      uploadedFileUrl: null,
      croppedFileUrl: image.url,
    });
  };

  onCancel = () => {
    // eslint-disable-next-line no-console
    console.log('cancel');

    this.setState({
      uploadedFileUrl: null,
    });
  };

  onSelectFile = (e) => {
    const files = e.target.files;

    if(!files || files.length === 0) {
      return;
    }

    const fileUrl = (window.URL || window.webkitURL).createObjectURL(files[0]);

    this.setState({
      uploadedFileUrl: fileUrl,
      croppedFileUrl: null,
    });
  };

  render() {
    const {uploadedFileUrl, croppedFileUrl} = this.state;
    const style = {
      width: 500
    };

    return (
      <div>
        <h5>1. ImageCropper</h5>

        <div>
          <div style={style}>
            <ImageCropper src={image}
                          onSubmit={this.onSubmit}
                          onCancel={this.onCancel}/>
          </div>

          <div style={{width: 300}}>
            <ImageCropper src={image2}
                          onSubmit={this.onSubmit}
                          onCancel={this.onCancel}/>
          </div>
        </div>

        <h5>2. ImageCropper width <code>bestFit</code></h5>

        <div>
          <div style={style}>
            <ImageCropper src={image}
                          bestFit
                          onSubmit={this.onSubmit}
                          onCancel={this.onCancel}/>
          </div>

          <div style={{width: 300}}>
            <ImageCropper src={image2}
                          bestFit
                          onSubmit={this.onSubmit}
                          onCancel={this.onCancel}/>
          </div>
        </div>

        <h5>3. ImageCropper width defined <code>initialCrop</code></h5>

        <div style={style}>
          <ImageCropper src={image}
                        initialCrop={{
                          x: 0,
                          y: 0,
                          width: 100,
                          height: 100,
                        }}
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel}/>
        </div>

        <h5>4. ImageCropper width selecting file</h5>

        <div style={style}>
          <input type="file" onChange={this.onSelectFile}/>

          <div>
            {
              uploadedFileUrl &&
              <ImageCropper src={uploadedFileUrl}
                            onSubmit={this.onSubmit}
                            onCancel={this.onCancel}/>
            }

            {
              croppedFileUrl &&
              <img src={croppedFileUrl} style={{width: 200}}/>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ImageCropperUI;
