import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import BioModalEdit from '../Bio/BioModalEdit';

import * as selectTournamentPlayerBioActions from '.././../actions/selectTournamentPlayerBio';

class ModalSelectTournamentPlayerBio extends React.Component {
  state = {
    showLoader: false
  }

  componentDidMount() {
    const {getSchema, tournamentId, playerId} = this.props;

    getSchema({
      tournamentId,
      playerId,
    });
  }

  showLoader = () => {
    this.setState({
      showLoader: true
    });
  }

  hideLoader = () => {
    this.setState({
      showLoader: false
    });
  }

  onSubmit = (bioData, bioFields) => {
    const {save} = this.props;
    this.showLoader();
    const formattedBio = Object.keys(bioData)
      .reduce((prev, current) => {
        const field     = bioFields.find(item => parseInt(item.name, 10) === parseInt(current, 10));
        const isImage   = field.type === 'img';
        const res       = {...prev};

        if(!isImage) {
          res.data.push({
            questionId: current,
            value: bioData[current],
          });
        }
        else {
          res.avatar = bioData[current];
        }

        return res;
      }, {
        data: [],
      });

    if(formattedBio.avatar !== undefined) {
      save({
        bioData: formattedBio.data,
        bioAvatar: formattedBio.avatar,
        onClose: this.onClose
      });
    }
    else {
      save({
        bioData: formattedBio.data,
        onClose: this.onClose
      });
    }
  };

  onClose = () => {
    const {onClose} = this.props;
    this.hideLoader();
    onClose();
  };

  render() {
    const {isOpen, data} = this.props;

    if(data.schema.isPending || !data.schema.data) {
      return null;
    }

    return (
      <BioModalEdit isOpen={isOpen}
                    showLoader={this.state.showLoader}
                    data={{}}
                    title={'Select bio'}
                    bioType={data.schema.data.name}
                    onClose={this.onClose}
                    onSubmit={this.onSubmit}/>
    );
  }
}

ModalSelectTournamentPlayerBio.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  tournamentId: PropTypes.string.isRequired,
  playerId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,

  data: PropTypes.object.isRequired,

  getSchema: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    data: state.selectTournamentPlayerBio,
  }),
  ({
    getSchema: selectTournamentPlayerBioActions.getSchema,
    save: selectTournamentPlayerBioActions.save,
  })
)(ModalSelectTournamentPlayerBio);
