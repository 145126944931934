import B2 from '../../../assets/images/mana/2B.png';
import G2 from '../../../assets/images/mana/2G.png';
import R2 from '../../../assets/images/mana/2R.png';
import U2 from '../../../assets/images/mana/2U.png';
import W2 from '../../../assets/images/mana/2W.png';
import B from '../../../assets/images/mana/B.png';
import BG from '../../../assets/images/mana/BG.png';
import BP from '../../../assets/images/mana/BP.png';
import BR from '../../../assets/images/mana/BR.png';
import C from '../../../assets/images/mana/C.png';
import G from '../../../assets/images/mana/G.png';
import GP from '../../../assets/images/mana/GP.png';
import GU from '../../../assets/images/mana/GU.png';
import GW from '../../../assets/images/mana/GW.png';
import R from '../../../assets/images/mana/R.png';
import RG from '../../../assets/images/mana/RG.png';
import RP from '../../../assets/images/mana/RP.png';
import RW from '../../../assets/images/mana/RW.png';
import S from '../../../assets/images/mana/S.png';
import U from '../../../assets/images/mana/U.png';
import UB from '../../../assets/images/mana/UB.png';
import UP from '../../../assets/images/mana/UP.png';
import UR from '../../../assets/images/mana/UR.png';
import W from '../../../assets/images/mana/W.png';
import WB from '../../../assets/images/mana/WB.png';
import WP from '../../../assets/images/mana/WP.png';
import WU from '../../../assets/images/mana/WU.png';
import X from '../../../assets/images/mana/X.png';

export default { 
  B2,
  G2,
  R2,
  U2,
  W2,
  B,
  BG,
  BP,
  BR,
  C,
  G,
  GP,
  GU,
  GW,
  R,
  RG,
  RP,
  RW,
  S,
  U,
  UB,
  UP,
  UR,
  W,
  WB,
  WP,
  WU,
  X,
};