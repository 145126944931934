import * as React from 'react';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {saveTextDeck, clearTextDeck, removeCardSuggestion, saveTextDeckAllSuggestions} from '../../../actions/deckActions';
import ExclamationMark from '../../../../src/assets/images/excl-warning.svg';
import {FormattedMessage, injectIntl} from 'react-intl';
import {forEach} from 'lodash';

import Tabs from '../../Generic/Tabs/Tabs';
import CardName from '../CardName/CardName';
import CardImporter from '../../../services/CardImporter';
import textImport from '../../../assets/images/svg/import-text.svg';

import './DeckFromText.scss';

class DeckFromText extends React.Component {
  state = {
    isModalOpen: false,
    navIndex: 0,
    data: {},
  };

  componentDidUpdate(){
    if(this.props.savedTextDeck.data && this.props.savedTextDeck.data.unrecognizedCards) {
      if(this.props.savedTextDeck.data.unrecognizedCards.length === 0) {
        this.toggleModal(false);
      }
    }
  }

  toggleModal = (isOpen) => {
    const {format} = this.props;
    const mainDeckIndex = format.details.findIndex(item => item.variable === 'maindeck');

    const state = isOpen
      ? {isModalOpen: isOpen, navIndex: mainDeckIndex}
      : {isModalOpen: isOpen, data: {}};

    this.setState(state);
    if(!isOpen) {
      this.props.clearTextDeck();
    }
  };

  saveDeck = () => {
    const {deckId, inModal} = this.props;
    const {data} = this.state;

    this.props.saveTextDeck(deckId, CardImporter.stringify(data), inModal);
  };

  onNavChangeIndex = (index) => {
    this.setState({
      navIndex: index
    });
  };

  onPaste = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const text      = e.clipboardData.getData('Text');

    const pasteData = CardImporter.parse(text, {toString: true, defaultGroup: this.getActiveIndexName()});
    const oldData   = this.state.data;

    const mergedData = {...oldData};
    forEach(pasteData, (value, key) => {
      if(mergedData[key]) {
        const lastChar = mergedData[key][mergedData[key].length - 1];

        mergedData[key] += (lastChar === '\n') ? value : `\n${value}`;
      }
      else {
        mergedData[key] = value;
      }
    });

    this.setState({
      data: mergedData,
    });
  };

  onChangeData = (e) => {
    const groupName = this.getActiveIndexName();
    const value = e.target.value;

    this.setState((state) => ({
      data: {
        ...state.data,
        [groupName]: value,
      }
    }));
  };

  getNavData() {
    const {format, intl} = this.props;
    const {data} = this.state;

    return format.details.map(item => {
      const name = item.variable;

      return {
        name: intl.messages[`deckGroup.${name}`],
        className: data[name] ? 'fill' : 'empty',
      };
    });
  }

  getActiveIndexName() {
    const {format} = this.props;
    const {navIndex} = this.state;

    return format.details[navIndex].variable;
  }

  getData() {
    const {data} = this.state;
    const groupName = this.getActiveIndexName();

    return data[groupName] || [];
  }

  addCard = (suggestion) => {
    this.props.addCard(suggestion.card, suggestion.amount, suggestion.type, true);
    this.removeCardSuggestion(suggestion);
  }

  acceptAllSuggestions = () => {
    const {deckId, format, inModal} = this.props;

    this.props.saveTextDeckAllSuggestions(this.props.savedTextDeck.data.unrecognizedCards, deckId, format, inModal);

    this.toggleModal(false);
  }

  removeCardSuggestion = (suggestion) => {
    this.props.removeCardSuggestion(suggestion);
  }

  renderSuggestion = (suggestion) => {
    if(!suggestion) {
      return null;
    }

    const card = suggestion.card;

    return (
      <div className="suggestion-wrapper">
        <span>( did you mean  <CardName name={card.name} names={card.names}/>? )</span>
        <span className="ml-2">
          <button className="btn btn-success mr-1 btn-square" onClick={() => this.addCard(suggestion)}><i className="icon-check"/></button>
          <button className="btn btn-secondary btn-square" onClick={() => this.removeCardSuggestion(suggestion)}><i className="icon-x2"/></button>
        </span>
      </div>
    );
  }

  renderModalBody = () => {
    const {savedTextDeck} = this.props;
    const {navIndex} = this.state;
    const unrecognized = savedTextDeck && savedTextDeck.data && savedTextDeck.data.unrecognizedCards;

    const navData = this.getNavData();
    const data = this.getData();

    if(unrecognized){
      return(
        <div>
          <p className="text-warning unrecognized-text">
            <img className="exclamation-mark mr-2"
              src={ExclamationMark}
              alt=""
            />
            We couldn&#39;t recognize some cards:</p>
          {
            unrecognized.map((item, id) => {
              return(
                <div key={id} className="unrecognize-card-wrapper"><b className="unrecognized-text unrecognized-text--name text-warning">- {item.suggestion && item.suggestion.amount} {item.name}</b> {this.renderSuggestion(item.suggestion)}</div>
              );
            })
          }
        </div>
      );
    }

    return (
      <div>
        <p>
          <FormattedMessage id="deck.from.text.modal.description"/><br />
          <small className="multi-line"><FormattedMessage id="deck.from.text.modal.example" /></small>
        </p>
        <Tabs index={navIndex}
              data={navData}
              onChangeIndex={this.onNavChangeIndex}
              renderTabItem={(item) => {
                return <span className={item.className}>{item.name}</span>;
              }}/>
        <textarea className="form-control modal-textarea"
                  onPaste={this.onPaste}
                  value={data}
                  rows={7}
                  onChange={this.onChangeData}/>
      </div>
    );
  }

  renderModalButtons = () => {
    const {savedTextDeck} = this.props;
    const unrecognized = savedTextDeck && savedTextDeck.data && savedTextDeck.data.unrecognizedCards;

    let isSuggestion = false;

    unrecognized && unrecognized.map( item => {
      if(item.suggestion) {
        isSuggestion = true;
      }
    });

    if(unrecognized && isSuggestion){
      return(
        <div>
          <button disabled={!isSuggestion} className="btn btn-success mr-2" onClick={this.acceptAllSuggestions}><FormattedMessage id="deck.from.text.modal.btn.accept"/></button>
          <button className="btn btn-secondary" onClick={() => this.toggleModal(false)}><FormattedMessage id="deck.from.text.modal.btn.skip"/></button>

        </div>
      );
    }

    if(unrecognized && !isSuggestion){
      return(
        <div>
          <button className="btn btn-success" onClick={() => this.toggleModal(false)}><FormattedMessage id="deck.from.text.modal.btn.ok"/></button>

        </div>
      );
    }

    return(
      <div>
        <button className="btn btn-success mr-2" onClick={this.saveDeck}>
          <FormattedMessage id="deck.from.text.modal.btn.save"/>
        </button>
        <button className="btn btn-secondary" onClick={() => this.toggleModal(false)}>
          <FormattedMessage id="deck.from.text.modal.btn.cancel"/>
        </button>
      </div>
    );
  }

  render() {
    const {className} = this.props;
    const {isModalOpen} = this.state;

    return (
      <div className={className}>
        <span className="deck-from-text custom-link" onClick={() => this.toggleModal(true)}>
          <img src={textImport} className="icon" />
          <span className="link">
            <FormattedMessage id="deck.from.text"/>
          </span>
        </span>
        <Modal isOpen={isModalOpen} size={'lg'} toggle={() => this.toggleModal(false)} className="deck-from-text-modal">
          <ModalHeader toggle={() => this.toggleModal(false)}>
            <span className="section-header"><FormattedMessage id="deck.from.text.modal.title"/></span>
          </ModalHeader>

          <ModalBody>
            {this.renderModalBody()}
          </ModalBody>

          <ModalFooter>
            {this.renderModalButtons()}

          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

DeckFromText.propTypes = {
  saveTextDeck: propTypes.func,
  clearTextDeck: propTypes.func,
  removeCardSuggestion: propTypes.func,
  saveTextDeckAllSuggestions: propTypes.func,
  savedTextDeck: propTypes.object,
  match: propTypes.object,
  className: propTypes.string,
  addCard: propTypes.func,
  deckId: propTypes.string,
  intl: propTypes.object,
  format: propTypes.object.isRequired,
  inModal: propTypes.bool,
};

export default injectIntl(withRouter(connect(
  (state) => ({
    savedTextDeck: state.savedTextDeck,
  }),
  ({
    saveTextDeck,
    clearTextDeck,
    removeCardSuggestion,
    saveTextDeckAllSuggestions
  })
)(DeckFromText)));
