import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';

import Pagination from '../Pagination/Pagination';
import DataTableWithMobileDropdown from '../DataTable/DataTableWithMobileDropdown';

import './DeckNamesList.scss';

class DeckNamesList extends React.Component {
  state = { deckName: '', newDeckName: '' };

  static getDerivedStateFromProps(props, state) {
    const name = props.deckNameDetails && props.deckNameDetails.name;

    if (name !== state.deckName && name) {
      return { deckName: name };
    }

    return null;
  }

  componentDidMount() {
    const { id, deckNameDetails } = this.props;

    if (!id || deckNameDetails) {
      return;
    }

    this.props.getDecknameDetails(id);
  }

  goToEdit(e, id) {
    const { currentPage } = this.props;
    const redirectUrl = `/deck-names/${currentPage}/edit/${id}`;
    e && e.stopPropagation();
    this.props.getDecknameDetails(id, redirectUrl);
  }

  onDeckNameChange = (e) => {
    this.setState({ deckName: e.target.value });
  }

  onNewDeckNameChange = (e) => {
    this.setState({ newDeckName: e.target.value });
  }

  onSave = () => {
    const { deckName } = this.state;
    const { id, formatId, currentPage } = this.props;

    if (!deckName || !id) {
      return;
    }

    this.props.updateDeckname(id, { name: deckName }, formatId, currentPage);
  }

  onNewSave = () => {
    const { newDeckName } = this.state;
    const { formatId } = this.props;

    if (!newDeckName || !formatId) {
      return;
    }

    this.props.createDeckname(newDeckName, formatId);
  }


  renderContent(columns, data, dropdownData) {
    const { deckNames, total, id, create } = this.props;
    const mobileEditContent = id
      ? <div className="text-center mobile-btn-wrapper">
        <Link to={'/deck-names'} className="mobile-btn">
          <button type="button" className="btn btn-lg btn-secondary mr-3 btn-tournament">
            <FormattedMessage id="tournaments.btn.cancel"/>
          </button>
        </Link>
        <span className="mobile-btn">
          <button
            type="button"
            className="btn btn-lg btn-success"
            onClick={this.onSave}
          >
            <FormattedMessage id="tournaments.btn.save"/>
          </button>
        </span>
      </div>
    : null;

    if (!create && (!deckNames || !deckNames.data || deckNames.data.length === 0)) {
      return (
        <div className="text-center mt-5">
          <FormattedMessage id="deck.name.empty"/>
        </div>
      );
    }

    return (
      <Fragment>
        <DataTableWithMobileDropdown
          className={'data-table-deck-names deck-names-list'}
          columns={columns}
          data={data}
          dropdownData={dropdownData}
          disableHover={!!id || !!create}
        />
        <div className="pagination-wrapper">
          {this.props.deckNames && <Pagination totalPages={total} url={'/deck-names/:page'}/>}
        </div>
        {mobileEditContent}
      </Fragment>
    );
  }

  render() {
    const { deckNames, currentPage, toggleModal, create } = this.props;
    const { deckName } = this.state;

    const columns = [
      {name: 'NAME'},
      {name: 'EDIT', isVisibleOnMobile: false, className: 'stores-list__fixed-width-col'},
      {name: 'DELETE', isVisibleOnMobile: false, className: 'stores-list__fixed-width-col'}
    ];

    const newData = [
      <div key="input" className="deck-names-input-wrapper">
        <input
          type="text"
          className="form-control"
          value={this.state.newDeckName}
          onChange={this.onNewDeckNameChange}
        />
        <button
          key="cancel"
          className="btn btn-secondary"
          onClick={() => this.props.history.push('/deck-names')}
        >
          <i className="icon-x2"></i>
        </button>
        <button
          key="save"
          className="btn btn-primary"
          onClick={this.onNewSave}
        >
          <i className="icon-check"></i>
        </button>
      </div>,
      <button
        key="cancel"
        className="btn btn-secondary"
        onClick={() => this.props.history.push('/deck-names')}
      >
        Cancel
      </button>,
      <button
        key="save"
        className="btn btn-primary"
        onClick={this.onNewSave}
      >
        Save
      </button>
    ];

    const data = deckNames.data.map((item, index) => {
      const { id } = this.props;
      const listData = [
        item.name,
        <div
          key={index + '-edit'}
          className="text-center clickableIcon"
          onClick={(e) => this.goToEdit(e, item.id)}
        >
          <i className="icon-edit"/>
        </div>,
        <div
          key={index + '-delete'}
          className="text-center clickableIcon"
          onClick={(e) => toggleModal(e, item.id)}
        >
          <i className="icon-delete"/>
        </div>
      ];

      const editData = [
        <input
          key={'input-' + index}
          type="text"
          className="form-control"
          value={deckName}
          onChange={this.onDeckNameChange}
      />,
        <button
          key="cancel"
          className="btn btn-secondary"
          onClick={() => this.props.history.push('/deck-names/' + currentPage)}
        >
          Cancel
        </button>,
        <button
          key="save"
          className="btn btn-success"
          onClick={this.onSave}
        >
          Save
        </button>
      ];

      if (id !== (item.id).toString()) {
        return listData;
      }

      return editData;
    });

    const dropdownData = (dataRow, rowIndex) => {
      
      if(dataRow[0].key === 'input') {
        return [];
      }

      return [
        <span
          key={`${rowIndex}-edit`}
          className="tournament-link tournament-link-mobile"
          onClick={(e) => this.goToEdit(e, deckNames.data[rowIndex].id)}
        >
          <FormattedMessage id="tournaments.btn.edit"/>
        </span>,
        <span
          key={rowIndex + '-delete'}
          className="tournament-link tournament-link-mobile"
            onClick={(e) => toggleModal(e, deckNames.data[rowIndex].id)}
        >
          <FormattedMessage id="btn.delete"/>
        </span>,
      ];
    };

    const tableData = create
      ? [newData, ...data]
      : data;

    return (
        this.renderContent(columns, tableData, dropdownData)
    );
  }
}

DeckNamesList.propTypes = {
  create: PropTypes.bool,
  getDecknameDetails: PropTypes.func,
  deckNames: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  id: PropTypes.string,
  formatId: PropTypes.number,
  total: PropTypes.string,
  deckNameDetails: PropTypes.object,
  updateDeckname: PropTypes.func,
  currentPage: PropTypes.string,
  toggleModal: PropTypes.func,
  createDeckname: PropTypes.func,
};

export default withRouter(DeckNamesList);
