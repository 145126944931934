import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

class Prompt extends React.Component {
  static defaultProps = {
    buttonText: {
      submit: 'Yes',
      cancel: 'No',
    },
    submitClassName: 'btn-success',
    cancelClassName: 'btn-outline-secondary',
    closeOnSubmit: true,
  };

  onSubmit = () => {
    const {onSubmit, onClose, closeOnSubmit} = this.props;

    onSubmit();

    if(closeOnSubmit){
      onClose();
    }
  };

  render() {
    const {isOpen, onClose, title, children, buttonText, submitClassName, cancelClassName} = this.props;

    return (
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>{title}</ModalHeader>

        <ModalBody>
          {children}
        </ModalBody>

        <ModalFooter>
          <button className={classnames('btn', submitClassName)} onClick={this.onSubmit}>
            {buttonText.submit}
          </button>
          <button className={classnames('btn', cancelClassName)} onClick={onClose}>
            {buttonText.cancel}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

Prompt.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.shape({
    submit: PropTypes.string,
    cancel: PropTypes.string,
  }),
  closeOnSubmit: PropTypes.bool,
  submitClassName: PropTypes.string,
  cancelClassName: PropTypes.string,
};

export default Prompt;
