export const statuses = [
  {value: 'completed', label: 'Completed'},
  {value: 'in_progress', label: 'In progress'},
  {value: 'not_started', label: 'Not started'}
];

export const roles = {
  player: 'PLAYER',
  organizer: 'ORGANIZER',
  judge: 'JUDGE',
  admin: 'ADMIN'
};

export const tournamentRolesIndex = {
  player: 1,
  organizer: 4,
  judge: 2,
};

export const rolesShortcut = {
  PLAYER: 'P',
  ORGANIZER: 'TO',
  JUDGE: 'J',
  ADMIN: 'A'
};

export const filterOptions = [
  {
    label: 'Filter by:',
    value: '',
    disabled: true
  },
  {
    label: 'Type:',
    value: '',
    disabled: true
  },
  {
    value: 'Individual',
    label: 'Individual',
    group: 'type'
  },
  {
    value: 'Team',
    label: 'Team',
    group: 'type'
  },
  {
    value: 'Duo Standard',
    label: 'Duo',
    group: 'type'
  },
  {
    label: 'Status:',
    value: '',
    disabled: true
  },
  {
    label: 'Completed',
    value: 'completed',
    group: 'status',
    status: 'completed'
  },
  {
    label: 'In progress',
    value: 'in_progress',
    group: 'status',
    status: 'in-progress'
  },
  {
    label: 'Not started',
    value: 'not_started',
    group: 'status',
    status: 'not-started'
  },
];

export const cardSizing = {
  marginTop: 25,
  sidedeckMarginTop: 40,
  height: 216
};

export const customCards = [
  {
    value: false,
    label: 'No'
  },
  {
    value: true,
    label: 'Yes'
  }
];

export const hiddenSideboard = [
  {
    value: false,
    label: 'No'
  },
  {
    value: true,
    label: 'Yes'
  }
];
