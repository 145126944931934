import update from 'immutability-helper';
import {TOURNAMENT_TYPES} from '../actions/types';

const initialState = {
  isGettingAllPending: false,
  isGettingAllError: false,
  isGettingAllSuccess: false,
  types: [],
  formats: {
    data: null,
    pending: [],
    error: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_TYPES.GET_TYPES.SUCCESS:
      return {
        ...state,
        types: action.payload,
      };
    case TOURNAMENT_TYPES.GET_FORMATS.START:
      return update(state, {
        formats: {
          pending: {$push: action.payload.tournamentType}
        }
      });
    case TOURNAMENT_TYPES.GET_FORMATS.SUCCESS: {
      const {data, tournamentType} = action.payload;

      return update(state, {
        formats: {
          data: {
            [tournamentType]: data,
          },
          pending: {
            $splice: [[state.pendings.findIndex(item => item === tournamentType), 1]]
          }
        }
      });
    }
    case TOURNAMENT_TYPES.GET_FORMATS.ERROR: {
      const {data, tournamentType} = action.payload;

      return update(state, {
        formats: {
          error: {
            [tournamentType]: data,
          },
          pending: {
            $splice: [[state.pendings.findIndex(item => item === tournamentType), 1]]
          }
        }
      });
    }
    case TOURNAMENT_TYPES.GET_ALL_DATA.START: {
      return update(state, {
        isGettingAllPending: {$set: true},
      });
    }
    case TOURNAMENT_TYPES.GET_ALL_DATA.SUCCESS: {
      const {types, formats} = action.payload;

      return update(state, {
        isGettingAllPending: {$set: false},
        isGettingAllError: {$set: false},
        isGettingAllSuccess: {$set: true},
        types: {$set: types},
        formats: {
          error: {$set: {}},
          data: {
            $set: formats.reduce((prev, current) => {
              return {
                ...prev,
                [current.type]: current.data,
              };
            }, {})
          }
        }
      });
    }
    case TOURNAMENT_TYPES.GET_ALL_DATA.ERROR: {
      return update(state, {
        isGettingAllPending: {$set: false},
        isGettingAllError: {$set: true},
        isGettingAllSuccess: {$set: false},
      });
    }
    default:
      return state;
  }
};
