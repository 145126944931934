import {
  BIOGRAPHY,
  BIOGRAPHY_UPDATE,
  BIOGRAPHY_UPDATE_MEMBER,
  BIOGRAPHY_MEMBER,
  BIOSCHEMAS,
} from './types';

export const getBiography = () => ({
  type: BIOGRAPHY.FETCH,
});

export const updateBiography = (userId, data, redirectUrl) => ({
  type: BIOGRAPHY_UPDATE.START,
  userId: userId,
  data: data,
  redirectUrl: redirectUrl
});

export const getTournamentUserBiography = (membershipId) => ({
  type: BIOGRAPHY_MEMBER.FETCH,
  membershipId: membershipId
});

export const updateTournamentUserBiography = (membershipId, data, redirect) => ({
  
  type: BIOGRAPHY_UPDATE_MEMBER.START,
  membershipId: membershipId,
  data: data,
  redirect: redirect
});

export const getBioSchemas = () => ({
  type: BIOSCHEMAS.GET,
});