import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';
import moment from 'moment';

import DecksPieChart, {ColorSchema} from '../../../Charts/DecksPieChart/DecksPieChart';
import MetricBox from '../MetricBox';
import Spinner from '../../../Spinner';
import DatePicker from '../../../DatePickerFormatted/DatePickerFormatted';

import cardImg from '../../../../assets/images/metrics/card.png';

import './PopularDecks.scss';

class PopularDecks extends React.Component {
  static defaultProps = {
    isLoading: false,
  };

  state = {
    filterDate: {
      since: '',
      until: ''
    },
    containerWidth: null,
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);

    setTimeout(this.onResize, 300);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  static getDerivedStateFromProps(props) {
    const {filterDate} = props;

    return {
      filterDate: {
        since: filterDate.since ? moment.unix(filterDate.since) : '',
        until: filterDate.until ? moment.unix(filterDate.until) : '',
      }
    };
  }

  onResize = () => {
    if(!this.contentWrapper){
      return;
    }

    const size = this.contentWrapper.getBoundingClientRect();

    this.setState({
      containerWidth: size.width,
    });
  };

  changeFilterDateSince = (date) => {
    if(!date) {
      return;
    }

    const timestamp = Number(date.startOf('day').format('X'));

    this.changeFilterDate('since', timestamp);
  };

  changeFilterDateUntil = (date) => {
    if(!date) {
      return;
    }

    const timestamp = Number(date.endOf('day').format('X'));

    this.changeFilterDate('until', timestamp);
  };

  changeFilterDate = (type, timestamp) => {
    this.props.onChangeFilterDate({
      [type]: timestamp,
    });
  };

  renderContent() {
    const {data, isLoading} = this.props;
    const allDecks = data.reduce((prev, current) => prev + current.amount, 0);

    if(isLoading){
      return this.renderLoading();
    }
    if(data.length > 0 && allDecks > 0){
      return this.renderData();
    }

    return this.renderNoData();
  }

  renderLoading() {
    return (
      <div className="no-data-wrapper">
        <Spinner size={80}/>
      </div>
    );
  }

  renderNoData() {
    return (
      <div className="no-data-wrapper">
        <p>No data</p>
      </div>
    );
  }

  renderData() {
    const {data} = this.props;
    const {containerWidth} = this.state;

    const allDecks    = data.reduce((prev, current) => prev + current.amount, 0);
    const chartData   = data
      .map((item) => ({
        name: item.name,
        value: item.amount,
      }))
      .sort((a, b) => a.value < b.value);


    return (
      <div className="chart-wrapper">
        {containerWidth && this.renderChart(chartData)}

        <ul>
          {
            chartData.map((item, index) => {
              return (
                <li key={index}>
                  <span className="dot" style={{backgroundColor: ColorSchema.getColor(ColorSchema.default, index)}}/>
                  {((item.value / allDecks) * 100).toFixed(0)}% {item.name}
                </li>
              );
            })
          }
        </ul>
      </div>
    );
  }

  renderChart(chartData) {
    const {containerWidth} = this.state;

    const w = containerWidth > 769 ? containerWidth / 2 : containerWidth;
    const h = w / 2;

    return (
      <DecksPieChart colors={ColorSchema.default}
                     data={chartData}
                     fullDisplayAmount={5}
                     size={{w, h}}/>
    );
  }

  render() {
    const {formatOptions, format, onChangeFormat, isLoading} = this.props;
    const {filterDate} = this.state;

    return (
      <MetricBox bgImage={cardImg} className="popular-decks">
        <div>
        <div className="header-select">
          <h4><FormattedMessage id={'metrics.title.popularDecks'}/></h4>

          <div className={'dates'}>
            <DatePicker
              className="form-control"
              selected={moment(filterDate.since)}
              disabled={isLoading}
              onChange={this.changeFilterDateSince}/>

            <DatePicker
              className="form-control"
              selected={moment(filterDate.until)}
              disabled={isLoading}
              onChange={this.changeFilterDateUntil}/>
          </div>

          <Select
            options={formatOptions}
            value={format}
            onChange={onChangeFormat}
            searchable={false}
            clearable={false}
          />
        </div>
        </div>
        <div ref={(node) => this.contentWrapper = node} className="content-wrapper">
          {this.renderContent()}
        </div>
      </MetricBox>
    );
  }
}

PopularDecks.propTypes = {
  format: PropTypes.number.isRequired,
  formatOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  })),
  onChangeFormat: PropTypes.func,
  onChangeFilterDate: PropTypes.func,
  filterDate: PropTypes.shape({
    since: PropTypes.number,
    until: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
};

export default PopularDecks;
