import React from 'react';
import PropTypes from 'prop-types';

class PrintTournamentPlayerButton extends React.Component {

  static defaultProps = {
    tag: 'button'
  };

  onClick = (e) => {
    const {fetchData, onClick} = this.props;

    e.stopPropagation();
    fetchData(onClick);
  };

  render() {
    /* eslint-disable no-unused-vars */
    const {children, fetchData, tag: Tag, ...rest} = this.props;
    /* eslint-enable no-unused-vars */

    return (
      <Tag {...rest} onClick={this.onClick}>
        {children}
      </Tag>
    );
  }
}

PrintTournamentPlayerButton.propTypes = {
  fetchData: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  tag: PropTypes.string
};

export default PrintTournamentPlayerButton;
