import { createLogic } from 'redux-logic';

import { USERS } from '../actions/types';

export const getUsers = createLogic({
  type: USERS.FETCH,
  latest: true,

  processOptions: {
    successType: USERS.SUCCESS,
    failType: USERS.ERROR,
  },

  process({ httpClient, action }) {
    const {filter} = action;
    const roles = filter && filter.length > 0 ? filter : null;

    return httpClient.get('/api/v1/users', {
      params: {
        name: action.name,
        page: action.page,
        roles
      }
    })
      .then(res => res);
  }
});

export default {
  getUsers
};
